@import url(https://fonts.googleapis.com/css?family=Material+Icons);
@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* Common */
/* Navbar */
/* Radius */
/* Dropdown */
/* Alert */
/* Buttons */
.ng-select.ng-select-opened>.ng-select-container{background:#fff;border-color:#b3b3b3 #ccc #d9d9d9}
.ng-select.ng-select-opened>.ng-select-container:hover{box-shadow:none}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{top:-2px;border-color:transparent transparent #999;border-width:0 5px 5px}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{border-color:transparent transparent #333}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{border-bottom-right-radius:0;border-bottom-left-radius:0}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{border-top-right-radius:0;border-top-left-radius:0}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{border-color:#007eff;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)}
.ng-select.ng-select-disabled>.ng-select-container{background-color:#f9f9f9}
.ng-select .ng-has-value .ng-placeholder{display:none}
.ng-select .ng-select-container{color:#333;background-color:#fff;border-radius:4px;border:1px solid #ccc;min-height:36px;align-items:center}
.ng-select .ng-select-container:hover{box-shadow:0 1px 0 rgba(0,0,0,0.06)}
.ng-select .ng-select-container .ng-value-container{align-items:center;padding-left:10px}
[dir="rtl"] .ng-select .ng-select-container .ng-value-container{padding-right:10px;padding-left:0}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{color:#999}
.ng-select.ng-select-single .ng-select-container{height:36px}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{top:5px;left:0;padding-left:10px;padding-right:50px}
[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{padding-right:10px;padding-left:50px}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{background-color:#f9f9f9;border:1px solid #e6e6e6}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{padding:0 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-top:5px;padding-left:7px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-right:7px;padding-left:0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{font-size:.9em;margin-bottom:5px;background-color:#ebf5ff;border-radius:2px;margin-right:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{margin-right:0;margin-left:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{background-color:#f9f9f9}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:0;padding-right:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{background-color:#d1e8ff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-right:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-left:1px solid #b8dbff;border-right:none}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:0;border-right:1px solid #b8dbff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 0 3px 3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 3px 3px 0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{top:5px;padding-bottom:5px;padding-left:3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{padding-right:3px;padding-left:0}
.ng-select .ng-clear-wrapper{color:#999}
.ng-select .ng-clear-wrapper:hover .ng-clear{color:#D0021B}
.ng-select .ng-spinner-zone{padding:5px 5px 0 0}
[dir="rtl"] .ng-select .ng-spinner-zone{padding:5px 0 0 5px}
.ng-select .ng-arrow-wrapper{width:25px;padding-right:5px}
[dir="rtl"] .ng-select .ng-arrow-wrapper{padding-left:5px;padding-right:0}
.ng-select .ng-arrow-wrapper:hover .ng-arrow{border-top-color:#666}
.ng-select .ng-arrow-wrapper .ng-arrow{border-color:#999 transparent transparent;border-style:solid;border-width:5px 5px 2.5px}
.ng-dropdown-panel{background-color:#fff;border:1px solid #ccc;box-shadow:0 1px 0 rgba(0,0,0,0.06);left:0}
.ng-dropdown-panel.ng-select-bottom{top:100%;border-bottom-right-radius:4px;border-bottom-left-radius:4px;border-top-color:#e6e6e6;margin-top:-1px}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{border-bottom-right-radius:4px;border-bottom-left-radius:4px}
.ng-dropdown-panel.ng-select-top{bottom:100%;border-top-right-radius:4px;border-top-left-radius:4px;border-bottom-color:#e6e6e6;margin-bottom:-1px}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{border-top-right-radius:4px;border-top-left-radius:4px}
.ng-dropdown-panel .ng-dropdown-header{border-bottom:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-footer{border-top:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{-webkit-user-select:none;user-select:none;padding:8px 10px;font-weight:500;color:rgba(0,0,0,0.54);cursor:pointer}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{cursor:default}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{background-color:#f5faff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{background-color:#ebf5ff;font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{background-color:#fff;color:rgba(0,0,0,0.87);padding:8px 10px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{color:#333;background-color:#ebf5ff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background-color:#f5faff;color:#333}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color:#ccc}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-left:22px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-right:22px;padding-left:0}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{font-size:80%;font-weight:400;padding-right:5px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-left:5px;padding-right:0}
[dir="rtl"] .ng-dropdown-panel{direction:rtl;text-align:right}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
/* Input */
/* Input group */
/* Table */
/* Modal */
/* Bootstrap */
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #EE724B;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #EE4C4C;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Gilroy, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
body {
  margin: 0;
  font-family: Gilroy, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: 900;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #EE724B;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #d84314;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #B9B9B9;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role=button] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}
h1, .h1 {
  font-size: 2.5rem;
}
h2, .h2 {
  font-size: 2rem;
}
h3, .h3 {
  font-size: 1.75rem;
}
h4, .h4 {
  font-size: 1.5rem;
}
h5, .h5 {
  font-size: 1.25rem;
}
h6, .h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 500;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.3333333333%;
}
.offset-2 {
  margin-left: 16.6666666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.3333333333%;
}
.offset-5 {
  margin-left: 41.6666666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.3333333333%;
}
.offset-8 {
  margin-left: 66.6666666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.3333333333%;
}
.offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #666666;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #666666;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fad8cd;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f6b6a1;
}
.table-hover .table-primary:hover {
  background-color: #f8c6b6;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #f8c6b6;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #facdcd;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f6a2a2;
}
.table-hover .table-danger:hover {
  background-color: #f8b6b6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f8b6b6;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 7px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f9cdbf;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.form-control::placeholder {
  color: #B9B9B9;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[size], select.form-control[multiple] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #B9B9B9;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.2rem;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #EE4C4C;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(238, 76, 76, 0.9);
  border-radius: 0.2rem;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #EE4C4C;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EE4C4C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EE4C4C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #EE4C4C;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #EE4C4C;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EE4C4C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EE4C4C' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #EE4C4C;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #EE4C4C;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #EE4C4C;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #EE4C4C;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f27b7b;
  background-color: #f27b7b;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #EE4C4C;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #EE4C4C;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #EE4C4C;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 500;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
  box-shadow: false;
}
.btn-primary:hover {
  color: #fff;
  background-color: #eb5728;
  border-color: #ea4d1c;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #eb5728;
  border-color: #ea4d1c;
  box-shadow: false, 0 0 0 0.2rem rgba(241, 135, 102, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ea4d1c;
  border-color: #e44716;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 135, 102, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: false;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: false, 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: false;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: false, 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: false;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: false, 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: false;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: false, 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
  box-shadow: false;
}
.btn-danger:hover {
  color: #fff;
  background-color: #eb2929;
  border-color: #ea1d1d;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #eb2929;
  border-color: #ea1d1d;
  box-shadow: false, 0 0 0 0.2rem rgba(241, 103, 103, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1d1d;
  border-color: #e51616;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 103, 103, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: false;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: false, 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: false;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: false, 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #EE724B;
  border-color: #EE724B;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #EE724B;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(238, 114, 75, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #EE4C4C;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(238, 76, 76, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 500;
  color: #EE724B;
  text-decoration: none;
}
.btn-link:hover {
  color: #d84314;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 500;
  color: #666666;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #EE724B;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #666666;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #B9B9B9;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 7px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #EE724B;
  background-color: #EE724B;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f9cdbf;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fdf2ee;
  border-color: #fdf2ee;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #EE724B;
  background-color: #EE724B;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #f9cdbf;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #f9cdbf;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 7px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: white;
  border-left: inherit;
  border-radius: 0 7px 7px 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #EE724B;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #fdf2ee;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #EE724B;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #fdf2ee;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #EE724B;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #fdf2ee;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.2rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #EE724B;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: #787878;
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.2rem - 1px) calc(0.2rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.2rem - 1px) calc(0.2rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.2rem - 1px);
}
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.2rem;
}
.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.2rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #EE724B;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #d84314;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #EE724B;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #ea4d1c;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #EE4C4C;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #ea1d1d;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border: 0 solid transparent;
  border-radius: 0.2rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #7c3b27;
  background-color: #fce3db;
  border-color: #fad8cd;
}
.alert-primary hr {
  border-top-color: #f8c6b6;
}
.alert-primary .alert-link {
  color: #55291b;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #7c2828;
  background-color: #fcdbdb;
  border-color: #facdcd;
}
.alert-danger hr {
  border-top-color: #f8b6b6;
}
.alert-danger .alert-link {
  color: #551c1c;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.2rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #EE724B;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.2rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.2rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.2rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #7c3b27;
  background-color: #fad8cd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #7c3b27;
  background-color: #f8c6b6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #7c3b27;
  border-color: #7c3b27;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #7c2828;
  background-color: #facdcd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c2828;
  background-color: #f8b6b6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7c2828;
  border-color: #7c2828;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 3rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 3rem;
  margin: -1rem -3rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 3rem !important;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem !important-0.25rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.2rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #EE724B !important;
}
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ea4d1c !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #EE4C4C !important;
}
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1d1d !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #EE724B !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #EE4C4C !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.2rem !important;
}
.rounded-top {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}
.rounded-right {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}
.rounded-left {
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 400 !important;
}
.font-weight-lighter {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: 900 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #EE724B !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #d84314 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}
.text-danger {
  color: #EE4C4C !important;
}
a.text-danger:hover, a.text-danger:focus {
  color: #d91515 !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #B9B9B9 !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@font-face {
  font-family: "Gilroy light";
  src: url('Gilroy-Light.5ac188463a0bf78b3351.otf') format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url('Gilroy-Regular.86bc2a5a8350170447c9.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy, Medium";
  src: url('Gilroy-Medium.9f1092d036076c580041.otf') format("opentype");
}
@font-face {
  font-family: "Gilroy semi";
  src: url('Gilroy-Semi-Bold.017ff10cc8aae0832650.otf') format("opentype");
}
@font-face {
  font-family: "Gilroy, Bold";
  src: url('Gilroy-Bold.a64a9abefbbe02db74ef.otf') format("opentype");
}
@font-face {
  font-family: "Gilroy, Black";
  src: url('Gilroy-Black.6b9111993e1056a5a92c.otf') format("opentype");
}
@font-face {
  font-family: "Gilroy, Heavy";
  src: url('Gilroy-Heavy.401eb83230d5d306f671.otf') format("opentype");
}
@font-face {
  font-family: "Gilroy, ExtraBold";
  src: url('Gilroy-Extra-Bold.f0feeaf3468f1ac2b9cd.otf') format("opentype");
}
.btn {
  font-weight: 600 !important;
}
label {
  font-weight: 600;
}
/* Custom modal */
.modal-header {
  padding-right: 0 !important;
  align-items: center !important;
}
.modal-header button.close {
  margin: 0 !important;
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  background-color: #f6f6f6 !important;
}
.modal-header .close {
  padding: 1rem 2rem 1rem 1rem !important;
}
.modal-header .close {
  margin: 0rem -3rem 0rem auto !important;
}
/* left/right Modal */
.modal-slideout {
  padding-top: 62px;
  padding-right: 0 !important;
}
.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  transform: translate(100%, 0) scale(1);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-dialog-slideout .modal-content {
  border: 0;
}
.modal-dialog-slideout .modal-footer {
  padding: 1rem;
}
.modal-dialog-slideout .modal-header h5 {
  float: left;
}
.bg-primary-light {
  background-color: #fbe0d7 !important;
}
.navbar-brand {
  padding-top: 0 !important;
}
a.list-group-item-action:hover {
  background-color: #fff !important;
}
@media (hover: hover) {
  a.list-group-item-action:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
.carousel.slide {
  height: 100%;
}
.carousel.slide:focus {
  border: none !important;
  outline-color: transparent !important;
}
.carousel-indicators li {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
}
.carousel-indicators li:not(.active) {
  background-color: #787878 !important;
}
.carousel-indicators li.active {
  background-color: #EE724B;
}
.text-danger {
  font-size: 16px;
  font-family: "Gilroy, Medium";
}
b, strong, .font-bold {
  font-family: "Gilroy, Bold";
}
html, body {
  height: 100%;
}
body {
  --body-color: #F8F8F8;
  background-color: var(--body-color) !important;
  position: relative;
}
.male {
  color: #287FD0;
}
.female {
  color: #EE724B;
}
.macartevacances {
  color: #287fd0;
  font-family: Gilroy, sans-serif;
  font-weight: 700;
}
.bg-back {
  background-color: #F8F8F8;
}
.text-primary-alt {
  color: #287FD0 !important;
}
/* Nabar */
.navbar {
  font-weight: 700;
}
hr {
  height: 8px;
  background-image: linear-gradient(180deg, rgba(174, 174, 174, 0.05), transparent);
  border: 0;
}
.shadowed {
  filter: drop-shadow(5px 5px 5px #eee);
}
.small-shadow {
  box-shadow: 0 7px 17px 0 #00000012;
}
.table-no-header tr th {
  border: 0;
}
.table-no-header tr:first-child td {
  border: 0;
}
small {
  font-size: 1rem;
  font-weight: 500;
  color: #aeaeae;
}
.text-grey {
  color: #666666;
}
.table-bigrow {
  border-collapse: separate;
  border-spacing: 0 4px;
}
.table-bigrow td, .table-bigrow th {
  border: 2px solid #fafafa;
  vertical-align: middle;
}
.table-bigrow td {
  border-left: none;
  border-right: none;
}
.table-bigrow tbody tr td:last-child {
  border-right: 2px solid #fafafa;
}
.table-bigrow th {
  border-right: none;
}
.selected-bigrow th {
  color: #287FD0;
}
.table-bigrow tbody tr.selected-bigrow {
  background-color: #f5fafc;
  border: 2px solid #dbf5ff;
}
.square-48 {
  width: 48px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  line-height: 48px;
}
.square-30 {
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}
.rounded-1 {
  border-radius: 1rem !important;
}
.credit {
  background-color: rgba(40, 167, 69, 0.25);
  padding: 0.25em;
  border-radius: 0.25em;
}
val-errors {
  color: #EE4C4C;
}
input.ng-invalid.ng-touched {
  border-color: #EE4C4C;
}
.rounded-2 {
  border-radius: 0.5em;
}
.rounded-top-20 {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}
.rounded-bottom-20 {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
input.InputElement {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px #00000029;
  border-radius: 7px;
}
.cursor-pointer {
  cursor: pointer;
}
/* Navbar */
/* Radius */
/* Dropdown */
/* Alert */
/* Buttons */
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
/* Input */
/* Input group */
/* Table */
/* Modal */
/* Bootstrap */
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #EE724B;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #EE4C4C;
  --light: #f8f9fa;
  --dark: #343a40;
  --blue: #427DCA;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Gilroy, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
body {
  margin: 0;
  font-family: Gilroy, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: 900;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #EE724B;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #d84314;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #B9B9B9;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role=button] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}
h1, .h1 {
  font-size: 2.5rem;
}
h2, .h2 {
  font-size: 2rem;
}
h3, .h3 {
  font-size: 1.75rem;
}
h4, .h4 {
  font-size: 1.5rem;
}
h5, .h5 {
  font-size: 1.25rem;
}
h6, .h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 500;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.3333333333%;
}
.offset-2 {
  margin-left: 16.6666666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.3333333333%;
}
.offset-5 {
  margin-left: 41.6666666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.3333333333%;
}
.offset-8 {
  margin-left: 66.6666666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.3333333333%;
}
.offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #666666;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #666666;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fad8cd;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f6b6a1;
}
.table-hover .table-primary:hover {
  background-color: #f8c6b6;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #f8c6b6;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #facdcd;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f6a2a2;
}
.table-hover .table-danger:hover {
  background-color: #f8b6b6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f8b6b6;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #cadbf0;
}
.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #9dbbe3;
}
.table-hover .table-blue:hover {
  background-color: #b6ceea;
}
.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #b6ceea;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 7px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f9cdbf;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.form-control::placeholder {
  color: #B9B9B9;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[size], select.form-control[multiple] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #B9B9B9;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.2rem;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #EE4C4C;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(238, 76, 76, 0.9);
  border-radius: 0.2rem;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #EE4C4C;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EE4C4C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EE4C4C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #EE4C4C;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #EE4C4C;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EE4C4C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EE4C4C' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #EE4C4C;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #EE4C4C;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #EE4C4C;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #EE4C4C;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f27b7b;
  background-color: #f27b7b;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #EE4C4C;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #EE4C4C;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #EE4C4C;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 500;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
  box-shadow: false;
}
.btn-primary:hover {
  color: #fff;
  background-color: #eb5728;
  border-color: #ea4d1c;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #eb5728;
  border-color: #ea4d1c;
  box-shadow: false, 0 0 0 0.2rem rgba(241, 135, 102, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ea4d1c;
  border-color: #e44716;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 135, 102, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: false;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: false, 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: false;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: false, 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: false;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: false, 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: false;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: false, 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
  box-shadow: false;
}
.btn-danger:hover {
  color: #fff;
  background-color: #eb2929;
  border-color: #ea1d1d;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #eb2929;
  border-color: #ea1d1d;
  box-shadow: false, 0 0 0 0.2rem rgba(241, 103, 103, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1d1d;
  border-color: #e51616;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 103, 103, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: false;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: false, 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: false;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: false, 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-blue {
  color: #fff;
  background-color: #427DCA;
  border-color: #427DCA;
  box-shadow: false;
}
.btn-blue:hover {
  color: #fff;
  background-color: #326ab3;
  border-color: #3064a9;
}
.btn-blue:focus, .btn-blue.focus {
  color: #fff;
  background-color: #326ab3;
  border-color: #3064a9;
  box-shadow: false, 0 0 0 0.2rem rgba(94, 145, 210, 0.5);
}
.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #427DCA;
  border-color: #427DCA;
}
.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #3064a9;
  border-color: #2d5fa0;
}
.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(94, 145, 210, 0.5);
}
.btn-outline-primary {
  color: #EE724B;
  border-color: #EE724B;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #EE724B;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(238, 114, 75, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #EE4C4C;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #EE4C4C;
  border-color: #EE4C4C;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(238, 76, 76, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-blue {
  color: #427DCA;
  border-color: #427DCA;
}
.btn-outline-blue:hover {
  color: #fff;
  background-color: #427DCA;
  border-color: #427DCA;
}
.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 125, 202, 0.5);
}
.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #427DCA;
  background-color: transparent;
}
.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #427DCA;
  border-color: #427DCA;
}
.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(66, 125, 202, 0.5);
}
.btn-link {
  font-weight: 500;
  color: #EE724B;
  text-decoration: none;
}
.btn-link:hover {
  color: #d84314;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 500;
  color: #666666;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #EE724B;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #666666;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #B9B9B9;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 7px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #EE724B;
  background-color: #EE724B;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f9cdbf;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fdf2ee;
  border-color: #fdf2ee;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #EE724B;
  background-color: #EE724B;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(238, 114, 75, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #f9cdbf;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #f9cdbf;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 7px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: white;
  border-left: inherit;
  border-radius: 0 7px 7px 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #EE724B;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #fdf2ee;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #EE724B;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #fdf2ee;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #EE724B;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #fdf2ee;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.2rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #EE724B;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: #787878;
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.2rem - 1px) calc(0.2rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.2rem - 1px) calc(0.2rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.2rem - 1px);
}
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.2rem;
}
.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.2rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #EE724B;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #d84314;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #EE724B;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #ea4d1c;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 114, 75, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #EE4C4C;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #ea1d1d;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 76, 76, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.badge-blue {
  color: #fff;
  background-color: #427DCA;
}
a.badge-blue:hover, a.badge-blue:focus {
  color: #fff;
  background-color: #3064a9;
}
a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(66, 125, 202, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border: 0 solid transparent;
  border-radius: 0.2rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #7c3b27;
  background-color: #fce3db;
  border-color: #fad8cd;
}
.alert-primary hr {
  border-top-color: #f8c6b6;
}
.alert-primary .alert-link {
  color: #55291b;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #7c2828;
  background-color: #fcdbdb;
  border-color: #facdcd;
}
.alert-danger hr {
  border-top-color: #f8b6b6;
}
.alert-danger .alert-link {
  color: #551c1c;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
.alert-blue {
  color: #224169;
  background-color: #d9e5f4;
  border-color: #cadbf0;
}
.alert-blue hr {
  border-top-color: #b6ceea;
}
.alert-blue .alert-link {
  color: #162942;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.2rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #EE724B;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.2rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #EE724B;
  border-color: #EE724B;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.2rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.2rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #7c3b27;
  background-color: #fad8cd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #7c3b27;
  background-color: #f8c6b6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #7c3b27;
  border-color: #7c3b27;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #7c2828;
  background-color: #facdcd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c2828;
  background-color: #f8b6b6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7c2828;
  border-color: #7c2828;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.list-group-item-blue {
  color: #224169;
  background-color: #cadbf0;
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #224169;
  background-color: #b6ceea;
}
.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #224169;
  border-color: #224169;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 3rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 3rem;
  margin: -1rem -3rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 3rem !important;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem !important-0.25rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.2rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #EE724B !important;
}
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ea4d1c !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #EE4C4C !important;
}
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1d1d !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-blue {
  background-color: #427DCA !important;
}
a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #3064a9 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #EE724B !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #EE4C4C !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-blue {
  border-color: #427DCA !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.2rem !important;
}
.rounded-top {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}
.rounded-right {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}
.rounded-left {
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 400 !important;
}
.font-weight-lighter {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: 900 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #EE724B !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #d84314 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}
.text-danger {
  color: #EE4C4C !important;
}
a.text-danger:hover, a.text-danger:focus {
  color: #d91515 !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}
.text-blue {
  color: #427DCA !important;
}
a.text-blue:hover, a.text-blue:focus {
  color: #2a5996 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #B9B9B9 !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #EE724B;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.pace .pace-progress-inner {
  display: block;
  position: absolute;
  left: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #EE724B, 0 0 5px #EE724B;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}
.pace .pace-activity {
  display: none;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #EE724B;
  border-left-color: #EE724B;
  border-radius: 10px;
  animation: pace-spinner 400ms linear infinite;
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}
.mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Gilroy;
  letter-spacing: 0em;
  margin: 0 0 16px;
}
.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Gilroy;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}
.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Gilroy;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}
.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 15px/24px Gilroy;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}
.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Gilroy;
  margin: 0 0 12px;
}
.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Gilroy;
  margin: 0 0 12px;
}
.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Gilroy;
  letter-spacing: 0.0179em;
}
.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Gilroy;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}
.mat-small, .mat-caption {
  font: 400 12px/20px Gilroy;
  letter-spacing: 0.0333em;
}
.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Gilroy;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}
.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Gilroy;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}
.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Gilroy;
  letter-spacing: 0em;
  margin: 0 0 64px;
}
.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Gilroy;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}
.mat-bottom-sheet-container {
  font: 400 14px/20px Gilroy;
  letter-spacing: 0.0179em;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
}
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.mat-card-header .mat-card-title {
  font-size: 20px;
}
.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.mat-cell, .mat-footer-cell {
  font-size: 14px;
}
.mat-calendar-body {
  font-size: 13px;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.mat-dialog-title {
  font: 500 20px/32px Gilroy;
  letter-spacing: 0.0075em;
}
.mat-expansion-panel-header {
  font-family: Gilroy;
  font-size: 15px;
  font-weight: 500;
}
.mat-expansion-panel-content {
  font: 400 14px/20px Gilroy;
  letter-spacing: 0.0179em;
}
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Gilroy;
  letter-spacing: 1.5px;
}
.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.mat-form-field-label {
  top: 1.34375em;
}
.mat-form-field-underline {
  bottom: 1.34375em;
}
.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
input.mat-input-element {
  margin-top: -0.0625em;
}
.mat-menu-item {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Gilroy;
  font-size: 12px;
}
.mat-select-trigger {
  height: 1.125em;
}
.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}
.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.mat-step-sub-label-error {
  font-weight: normal;
}
.mat-step-label-error {
  font-size: 14px;
}
.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.mat-tab-label, .mat-tab-link {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Gilroy;
  letter-spacing: 0.0075em;
  margin: 0;
}
.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
}
.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}
.mat-option {
  font-size: 16px;
}
.mat-optgroup-label {
  font: 500 14px/24px Gilroy;
  letter-spacing: 0.0179em;
}
.mat-simple-snackbar {
  font-family: Gilroy;
  font-size: 14px;
}
.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
textarea.cdk-textarea-autosize {
  resize: none;
}
textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
.mat-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator {
  position: relative;
}
body {
  --primary-color: #ee724b;
  --primary-lighter-color: #fad5c9;
  --primary-darker-color: #e75533;
  --text-primary-color: rgba(255, 255, 255, 0.87);
  --text-primary-lighter-color: rgba(255, 255, 255, 0.87);
  --text-primary-darker-color: #ffffff;
}
body {
  --accent-color: #797979;
  --accent-lighter-color: #d7d7d7;
  --accent-darker-color: #5c5c5c;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(255, 255, 255, 0.87);
  --text-accent-darker-color: #ffffff;
}
body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(255, 255, 255, 0.87);
  --text-warn-darker-color: #ffffff;
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-option {
  color: rgba(255, 255, 255, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(255, 255, 255, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.38);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ee724b;
}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #797979;
}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.mat-optgroup-label {
  color: rgba(255, 255, 255, 0.54);
}
.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.38);
}
.mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}
.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ee724b;
}
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #797979;
}
.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.mat-app-background {
  background-color: #fafafa;
  color: rgba(255, 255, 255, 0.87);
}
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}
.mat-autocomplete-panel {
  background: white;
  color: rgba(255, 255, 255, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(255, 255, 255, 0.87);
}
.mat-badge-content {
  color: rgba(255, 255, 255, 0.87);
  background: #ee724b;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}
.mat-badge-accent .mat-badge-content {
  background: #797979;
  color: white;
}
.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.mat-badge {
  position: relative;
}
.mat-badge-hidden .mat-badge-content {
  display: none;
}
.mat-badge-disabled .mat-badge-content {
  background: #fbfbfb;
  color: rgba(255, 255, 255, 0.38);
}
.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}
.mat-badge-content.mat-badge-active {
  transform: none;
}
.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(255, 255, 255, 0.87);
}
.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #ee724b;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #797979;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ee724b;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #797979;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.mat-button-focus-overlay {
  background: black;
}
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(255, 255, 255, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: rgba(255, 255, 255, 0.87);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #ee724b;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #797979;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(44, 44, 44, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.mat-button-toggle {
  color: rgba(255, 255, 255, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-button-toggle-appearance-standard {
  color: rgba(255, 255, 255, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.mat-button-toggle-checked {
  background-color: #c7c7c7;
  color: rgba(255, 255, 255, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(255, 255, 255, 0.87);
}
.mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.26);
  background-color: #e1e1e1;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #aeaeae;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.mat-card {
  background: white;
  color: rgba(255, 255, 255, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-card-subtitle {
  color: rgba(255, 255, 255, 0.54);
}
.mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.54);
}
.mat-checkbox-checkmark {
  fill: #fafafa;
}
.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ee724b;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #797979;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.54);
}
.mat-checkbox .mat-ripple-element {
  background-color: black;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ee724b;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #797979;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.mat-chip.mat-standard-chip {
  background-color: #e1e1e1;
  color: rgba(255, 255, 255, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #797979;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-table {
  background: white;
}
.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}
mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.mat-header-cell {
  color: rgba(255, 255, 255, 0.54);
}
.mat-cell, .mat-footer-cell {
  color: rgba(255, 255, 255, 0.87);
}
.mat-calendar-arrow {
  border-top-color: rgba(255, 255, 255, 0.54);
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(255, 255, 255, 0.54);
}
.mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.38);
}
.mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.54);
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.87);
  border-color: transparent;
}
.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.38);
}
.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.38);
}
.mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.38);
}
.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.18);
}
.mat-calendar-body-in-range::before {
  background: rgba(238, 114, 75, 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 114, 75, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 114, 75, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-calendar-body-selected {
  background-color: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 114, 75, 0.4);
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 114, 75, 0.3);
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(255, 255, 255, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(121, 121, 121, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(121, 121, 121, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(121, 121, 121, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #797979;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(121, 121, 121, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(121, 121, 121, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 0, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 0, 0, 0.3);
}
.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-datepicker-toggle-active {
  color: #ee724b;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #797979;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.38);
}
.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(255, 255, 255, 0.87);
}
.mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.mat-expansion-panel {
  background: white;
  color: rgba(255, 255, 255, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(255, 255, 255, 0.87);
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.54);
}
.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.mat-form-field-label {
  color: rgba(255, 255, 255, 0.6);
}
.mat-hint {
  color: rgba(255, 255, 255, 0.6);
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #ee724b;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #797979;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.mat-focused .mat-form-field-required-marker {
  color: #797979;
}
.mat-form-field-ripple {
  background-color: rgba(255, 255, 255, 0.87);
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ee724b;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #797979;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ee724b;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #797979;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.mat-error {
  color: red;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(255, 255, 255, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ee724b;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #797979;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.06);
}
.mat-icon.mat-primary {
  color: #ee724b;
}
.mat-icon.mat-accent {
  color: #797979;
}
.mat-icon.mat-warn {
  color: red;
}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.mat-input-element {
  caret-color: #ee724b;
}
.mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.42);
}
.mat-form-field.mat-accent .mat-input-element {
  caret-color: #797979;
}
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.mat-list-base .mat-list-item {
  color: rgba(255, 255, 255, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(255, 255, 255, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.54);
}
.mat-list-item-disabled {
  background-color: #e1e1e1;
}
.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}
.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}
.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-menu-item {
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.38);
}
.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(255, 255, 255, 0.54);
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-paginator {
  background: white;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.54);
}
.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}
.mat-progress-bar-background {
  fill: #fad5c9;
}
.mat-progress-bar-buffer {
  background-color: #fad5c9;
}
.mat-progress-bar-fill::after {
  background-color: #ee724b;
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d7d7d7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d7d7d7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #797979;
}
.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ee724b;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #797979;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}
.mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.54);
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ee724b;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ee724b;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #797979;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #797979;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}
.mat-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.mat-select-placeholder {
  color: rgba(255, 255, 255, 0.42);
}
.mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.mat-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ee724b;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #797979;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(255, 255, 255, 0.87);
}
.mat-drawer {
  background-color: white;
  color: rgba(255, 255, 255, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #797979;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(121, 121, 121, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #797979;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ee724b;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(238, 114, 75, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ee724b;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.38);
}
.mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.26);
}
.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #ee724b;
}
.mat-primary .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(238, 114, 75, 0.2);
}
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #797979;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(121, 121, 121, 0.2);
}
.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 0, 0, 0.2);
}
.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.38);
}
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.26);
}
.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.26);
}
.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.54);
  color: rgba(255, 255, 255, 0.87);
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #797979;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: red;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(255, 255, 255, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}
.mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}
.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.mat-sort-header-arrow {
  color: white;
}
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.mat-tab-label, .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.38);
}
.mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.87);
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.38);
}
.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 213, 201, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ee724b;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(215, 215, 215, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #797979;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 213, 201, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ee724b;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(215, 215, 215, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #797979;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-toolbar {
  background: #ededed;
  color: rgba(255, 255, 255, 0.87);
}
.mat-toolbar.mat-primary {
  background: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.mat-toolbar.mat-accent {
  background: #797979;
  color: white;
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.mat-tree {
  background: white;
}
.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(255, 255, 255, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}
.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-simple-snackbar-action {
  color: #797979;
}
.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(250, 250, 250, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(250, 250, 250, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(250, 250, 250, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ee724b;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #797979;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ee724b;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #797979;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}
.theme-alternate .mat-autocomplete-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #393939;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: rgba(255, 255, 255, 0.87);
  background: #ee724b;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}
.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #797979;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.theme-alternate .mat-badge {
  position: relative;
}
.theme-alternate .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-alternate .ng-animate-disabled .mat-badge-content,
.theme-alternate .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-alternate .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-alternate .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-alternate .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-alternate .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-alternate .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-alternate .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-alternate .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #ee724b;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #797979;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: red;
}
.theme-alternate .mat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-icon-button.mat-primary.mat-button-disabled, .theme-alternate .mat-icon-button.mat-accent.mat-button-disabled, .theme-alternate .mat-icon-button.mat-warn.mat-button-disabled, .theme-alternate .mat-icon-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-primary.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-accent.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-warn.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ee724b;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #797979;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.theme-alternate .mat-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: #ffffff;
}
.theme-alternate .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #393939;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: white;
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #ee724b;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #797979;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: red;
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(250, 250, 250, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone,
.theme-alternate .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #393939;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #464646;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #393939;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-card {
  background: #393939;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #2c2c2c;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ee724b;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #797979;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ee724b;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #797979;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #797979;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #393939;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  border-top-color: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content,
.theme-alternate .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-in-range::before {
  background: rgba(238, 114, 75, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 114, 75, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 114, 75, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 114, 75, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 114, 75, 0.3);
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(121, 121, 121, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(121, 121, 121, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(121, 121, 121, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #797979;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(121, 121, 121, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(121, 121, 121, 0.3);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 0, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 0, 0, 0.3);
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #ee724b;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #797979;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.theme-alternate .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(250, 250, 250, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #393939;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #ee724b;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #797979;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #797979;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ee724b;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #797979;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ee724b;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #797979;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.theme-alternate .mat-error {
  color: red;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ee724b;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #797979;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #ee724b;
}
.theme-alternate .mat-icon.mat-accent {
  color: #797979;
}
.theme-alternate .mat-icon.mat-warn {
  color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #ee724b;
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element option {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-input-element option:disabled {
  color: rgba(255, 255, 255, 0.38);
}
.theme-alternate .mat-form-field.mat-accent .mat-input-element {
  caret-color: #797979;
}
.theme-alternate .mat-form-field.mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-item-disabled {
  background-color: #464646;
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(250, 250, 250, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(250, 250, 250, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #393939;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled], .theme-alternate .mat-menu-item[disabled]::after,
.theme-alternate .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-item-submenu-trigger::after {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(250, 250, 250, 0.04);
}
.theme-alternate .mat-paginator {
  background: #393939;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #fad5c9;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #fad5c9;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #ee724b;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d7d7d7;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d7d7d7;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #797979;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #ee724b;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #797979;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: red;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ee724b;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ee724b;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #797979;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #797979;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #393939;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(250, 250, 250, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ee724b;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #797979;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #393939;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(198, 198, 198, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #797979;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(121, 121, 121, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #797979;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ee724b;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(238, 114, 75, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ee724b;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-primary .mat-slider-track-fill,
.theme-alternate .mat-primary .mat-slider-thumb,
.theme-alternate .mat-primary .mat-slider-thumb-label {
  background-color: #ee724b;
}
.theme-alternate .mat-primary .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-primary .mat-slider-focus-ring {
  background-color: rgba(238, 114, 75, 0.2);
}
.theme-alternate .mat-accent .mat-slider-track-fill,
.theme-alternate .mat-accent .mat-slider-thumb,
.theme-alternate .mat-accent .mat-slider-thumb-label {
  background-color: #797979;
}
.theme-alternate .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-accent .mat-slider-focus-ring {
  background-color: rgba(121, 121, 121, 0.2);
}
.theme-alternate .mat-warn .mat-slider-track-fill,
.theme-alternate .mat-warn .mat-slider-thumb,
.theme-alternate .mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.theme-alternate .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 0, 0, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover {
  background-color: rgba(250, 250, 250, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #797979;
  color: white;
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: red;
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #393939;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 213, 201, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ee724b;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(215, 215, 215, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #797979;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 213, 201, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ee724b;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(215, 215, 215, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #797979;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-toolbar {
  background: #393939;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #ee724b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #797979;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.theme-alternate .mat-tree {
  background: #393939;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}
.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}
.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Gilroy";
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.mat-tab-label, .mat-tab-link {
  color: #ee724b;
  font-size: 18px;
  font-weight: 800;
  font-family: "Gilroy";
}
button:not(.is-loading) > .fa-spinner {
  display: none;
}
.btn-radius {
  border-radius: 23px;
}
.no-modal > div.modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.modal-backdrop {
  background-color: #3C3C3C !important;
}
.modal-backdrop.show {
  opacity: 0.85 !important;
}
mat-dialog-container {
  padding: 0 !important;
}
@media (max-width: 400px) {
  .mat-dialog-container {
    border-radius: 0 !important;
    height: 100vh !important;
  }

  .modal-content {
    border-radius: 0 !important;
    height: 100% !important;
    width: 100% !important;
  }
}
@media (min-width: 400px) {
  .mat-dialog-container {
    border-radius: 23px !important;
    max-height: 693px;
  }

  .modal-content {
    border-radius: 23px !important;
  }
}
@media (max-width: 400px) {
  .welcome--container__size {
    width: 100vw;
    height: 100vh;
  }
}
@media (min-width: 400px) {
  .welcome--container__size {
    width: 100%;
    height: 100%;
    max-width: 375px;
    max-height: 667px;
  }
}
.notifier__container * {
  box-sizing: border-box;
}
.notifier__container-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.notifier__notification {
  display: block;
  position: fixed;
  visibility: hidden;
  z-index: 10000;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.notifier__notification--material {
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: default;
  padding-top: 11px;
  padding-right: 26px;
  padding-bottom: 10px;
  padding-left: 26px;
}
.notifier__notification--material .notifier__notification-message {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
  line-height: 32px;
  font-size: 15px;
}
.notifier__notification--material .notifier__notification-button {
  display: inline-block;
  transition: opacity 0.2s ease;
  opacity: 0.5;
  margin-right: -10px;
  margin-left: 10px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.notifier__notification--material .notifier__notification-button:hover, .notifier__notification--material .notifier__notification-button:focus {
  opacity: 1;
}
.notifier__notification--material .notifier__notification-button:active {
  transform: scale(0.82);
  opacity: 1;
}
.notifier__notification--default {
  background-color: #444;
  color: #FFF;
}
.notifier__notification--default .notifier__notification-button-icon {
  fill: #FFF;
}
.notifier__notification--error {
  background-color: #D9534F;
  color: #FFF;
}
.notifier__notification--error .notifier__notification-button-icon {
  fill: #FFF;
}
.notifier__notification--info {
  background-color: #5BC0DE;
  color: #FFF;
}
.notifier__notification--info .notifier__notification-button-icon {
  fill: #FFF;
}
.notifier__notification--success {
  background-color: #5CB85C;
  color: #FFF;
}
.notifier__notification--success .notifier__notification-button-icon {
  fill: #FFF;
}
.notifier__notification--warning {
  background-color: #F0AD4E;
  color: #FFF;
}
.notifier__notification--warning .notifier__notification-button-icon {
  fill: #FFF;
}
.switch.switch-small {
  min-width: 33px;
}
.switch.switch-medium {
  min-width: 50px;
}
.switch.switch-large {
  min-width: 60px;
}
.switch.switch-small > .switch-pane > span {
  font-size: 9px;
}
.switch.switch-medium > .switch-pane > span {
  font-size: 16px;
}
.switch.switch-large > .switch-pane > span {
  font-size: 16px;
}
.switch {
  border: 1px solid #dfdfdf;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  cursor: pointer;
  box-shadow: #dfdfdf 0 0 0 0 inset;
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  white-space: nowrap;
}
.switch small {
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: calc(100% - 30px);
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch.switch-small {
  height: 20px;
  border-radius: 20px;
}
.switch.switch-small small {
  width: 20px;
  height: 20px;
  right: calc(100% - 20px);
}
.switch.switch-small > .switch-pane > span {
  line-height: 20px;
}
.switch.switch-small > .switch-pane .switch-label-checked {
  padding-right: 25px;
  padding-left: 10px;
}
.switch.switch-small > .switch-pane .switch-label-unchecked {
  padding-left: 25px;
  padding-right: 10px;
}
.switch.switch-medium {
  height: 30px;
  border-radius: 30px;
}
.switch.switch-medium small {
  width: 30px;
  height: 30px;
  right: calc(100% - 30px);
}
.switch.switch-medium > .switch-pane > span {
  line-height: 30px;
}
.switch.switch-medium > .switch-pane .switch-label-checked {
  padding-right: 35px;
  padding-left: 15px;
}
.switch.switch-medium > .switch-pane .switch-label-unchecked {
  padding-left: 35px;
  padding-right: 15px;
}
.switch.switch-large {
  height: 40px;
  border-radius: 40px;
}
.switch.switch-large small {
  width: 40px;
  height: 40px;
  right: calc(100% - 40px);
}
.switch.switch-large > .switch-pane > span {
  line-height: 40px;
}
.switch.switch-large > .switch-pane .switch-label-checked {
  padding-right: 45px;
  padding-left: 20px;
}
.switch.switch-large > .switch-pane .switch-label-unchecked {
  padding-left: 45px;
  padding-right: 20px;
}
.switch.checked {
  background: #64bd63;
}
.switch.checked small {
  right: 0;
  left: auto;
}
.switch.checked .switch-pane {
  top: 0;
}
.switch.checked .switch-pane .switch-label-checked {
  display: block;
}
.switch.checked .switch-pane .switch-label-unchecked {
  display: none;
}
.switch.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.switch .switch-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  position: relative;
  pointer-events: none;
}
.switch .switch-pane > span {
  display: block;
  min-height: 100%;
}
.switch .switch-pane .switch-label {
  color: black;
}
.switch .switch-pane .switch-label-checked {
  display: none;
}
.switch .switch-pane .switch-label-unchecked {
  display: block;
}
.switch.loading {
  background-color: #f1f1f1;
}
.switch.loading small {
  background-color: transparent;
  border: none;
  box-shadow: none;
  right: 50%;
  transform: translateX(50%);
}
.iti {
  position: relative;
  display: inline-block; }
  .iti * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .iti__hide {
    display: none; }
  .iti__v-hide {
    visibility: hidden; }
  .iti input, .iti input[type=text], .iti input[type=tel] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0; }
  .iti__flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px; }
  .iti__selected-flag {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 6px 0 8px; }
  .iti__arrow {
    margin-left: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555; }
  .iti__arrow--up {
      border-top: none;
      border-bottom: 4px solid #555; }
  .iti__country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #CCC;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
  .iti__country-list--dropup {
      bottom: 100%;
      margin-bottom: -1px; }
  @media (max-width: 500px) {
      .iti__country-list {
        white-space: normal; } }
  .iti__flag-box {
    display: inline-block;
    width: 20px; }
  .iti__divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #CCC; }
  .iti__country {
    padding: 5px 10px;
    outline: none; }
  .iti__dial-code {
    color: #999; }
  .iti__country.iti__highlight {
    background-color: rgba(0, 0, 0, 0.05); }
  .iti__flag-box, .iti__country-name, .iti__dial-code {
    vertical-align: middle; }
  .iti__flag-box, .iti__country-name {
    margin-right: 6px; }
  .iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0; }
  .iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
    right: auto;
    left: 0; }
  .iti--allow-dropdown .iti__flag-container:hover {
    cursor: pointer; }
  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
      background-color: rgba(0, 0, 0, 0.05); }
  .iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
  .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
    cursor: default; }
  .iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
    .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
      background-color: transparent; }
  .iti--separate-dial-code .iti__selected-flag {
    background-color: rgba(0, 0, 0, 0.05); }
  .iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 6px; }
  .iti--container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px; }
  .iti--container:hover {
      cursor: pointer; }
  .iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }
  .iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%; }
  .iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em; }
  .iti__flag {
  width: 20px; }
  .iti__flag.iti__be {
    width: 18px; }
  .iti__flag.iti__ch {
    width: 15px; }
  .iti__flag.iti__mc {
    width: 19px; }
  .iti__flag.iti__ne {
    width: 18px; }
  .iti__flag.iti__np {
    width: 13px; }
  .iti__flag.iti__va {
    width: 15px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-size: 5652px 15px; } }
  .iti__flag.iti__ac {
    height: 10px;
    background-position: 0px 0px; }
  .iti__flag.iti__ad {
    height: 14px;
    background-position: -22px 0px; }
  .iti__flag.iti__ae {
    height: 10px;
    background-position: -44px 0px; }
  .iti__flag.iti__af {
    height: 14px;
    background-position: -66px 0px; }
  .iti__flag.iti__ag {
    height: 14px;
    background-position: -88px 0px; }
  .iti__flag.iti__ai {
    height: 10px;
    background-position: -110px 0px; }
  .iti__flag.iti__al {
    height: 15px;
    background-position: -132px 0px; }
  .iti__flag.iti__am {
    height: 10px;
    background-position: -154px 0px; }
  .iti__flag.iti__ao {
    height: 14px;
    background-position: -176px 0px; }
  .iti__flag.iti__aq {
    height: 14px;
    background-position: -198px 0px; }
  .iti__flag.iti__ar {
    height: 13px;
    background-position: -220px 0px; }
  .iti__flag.iti__as {
    height: 10px;
    background-position: -242px 0px; }
  .iti__flag.iti__at {
    height: 14px;
    background-position: -264px 0px; }
  .iti__flag.iti__au {
    height: 10px;
    background-position: -286px 0px; }
  .iti__flag.iti__aw {
    height: 14px;
    background-position: -308px 0px; }
  .iti__flag.iti__ax {
    height: 13px;
    background-position: -330px 0px; }
  .iti__flag.iti__az {
    height: 10px;
    background-position: -352px 0px; }
  .iti__flag.iti__ba {
    height: 10px;
    background-position: -374px 0px; }
  .iti__flag.iti__bb {
    height: 14px;
    background-position: -396px 0px; }
  .iti__flag.iti__bd {
    height: 12px;
    background-position: -418px 0px; }
  .iti__flag.iti__be {
    height: 15px;
    background-position: -440px 0px; }
  .iti__flag.iti__bf {
    height: 14px;
    background-position: -460px 0px; }
  .iti__flag.iti__bg {
    height: 12px;
    background-position: -482px 0px; }
  .iti__flag.iti__bh {
    height: 12px;
    background-position: -504px 0px; }
  .iti__flag.iti__bi {
    height: 12px;
    background-position: -526px 0px; }
  .iti__flag.iti__bj {
    height: 14px;
    background-position: -548px 0px; }
  .iti__flag.iti__bl {
    height: 14px;
    background-position: -570px 0px; }
  .iti__flag.iti__bm {
    height: 10px;
    background-position: -592px 0px; }
  .iti__flag.iti__bn {
    height: 10px;
    background-position: -614px 0px; }
  .iti__flag.iti__bo {
    height: 14px;
    background-position: -636px 0px; }
  .iti__flag.iti__bq {
    height: 14px;
    background-position: -658px 0px; }
  .iti__flag.iti__br {
    height: 14px;
    background-position: -680px 0px; }
  .iti__flag.iti__bs {
    height: 10px;
    background-position: -702px 0px; }
  .iti__flag.iti__bt {
    height: 14px;
    background-position: -724px 0px; }
  .iti__flag.iti__bv {
    height: 15px;
    background-position: -746px 0px; }
  .iti__flag.iti__bw {
    height: 14px;
    background-position: -768px 0px; }
  .iti__flag.iti__by {
    height: 10px;
    background-position: -790px 0px; }
  .iti__flag.iti__bz {
    height: 14px;
    background-position: -812px 0px; }
  .iti__flag.iti__ca {
    height: 10px;
    background-position: -834px 0px; }
  .iti__flag.iti__cc {
    height: 10px;
    background-position: -856px 0px; }
  .iti__flag.iti__cd {
    height: 15px;
    background-position: -878px 0px; }
  .iti__flag.iti__cf {
    height: 14px;
    background-position: -900px 0px; }
  .iti__flag.iti__cg {
    height: 14px;
    background-position: -922px 0px; }
  .iti__flag.iti__ch {
    height: 15px;
    background-position: -944px 0px; }
  .iti__flag.iti__ci {
    height: 14px;
    background-position: -961px 0px; }
  .iti__flag.iti__ck {
    height: 10px;
    background-position: -983px 0px; }
  .iti__flag.iti__cl {
    height: 14px;
    background-position: -1005px 0px; }
  .iti__flag.iti__cm {
    height: 14px;
    background-position: -1027px 0px; }
  .iti__flag.iti__cn {
    height: 14px;
    background-position: -1049px 0px; }
  .iti__flag.iti__co {
    height: 14px;
    background-position: -1071px 0px; }
  .iti__flag.iti__cp {
    height: 14px;
    background-position: -1093px 0px; }
  .iti__flag.iti__cr {
    height: 12px;
    background-position: -1115px 0px; }
  .iti__flag.iti__cu {
    height: 10px;
    background-position: -1137px 0px; }
  .iti__flag.iti__cv {
    height: 12px;
    background-position: -1159px 0px; }
  .iti__flag.iti__cw {
    height: 14px;
    background-position: -1181px 0px; }
  .iti__flag.iti__cx {
    height: 10px;
    background-position: -1203px 0px; }
  .iti__flag.iti__cy {
    height: 14px;
    background-position: -1225px 0px; }
  .iti__flag.iti__cz {
    height: 14px;
    background-position: -1247px 0px; }
  .iti__flag.iti__de {
    height: 12px;
    background-position: -1269px 0px; }
  .iti__flag.iti__dg {
    height: 10px;
    background-position: -1291px 0px; }
  .iti__flag.iti__dj {
    height: 14px;
    background-position: -1313px 0px; }
  .iti__flag.iti__dk {
    height: 15px;
    background-position: -1335px 0px; }
  .iti__flag.iti__dm {
    height: 10px;
    background-position: -1357px 0px; }
  .iti__flag.iti__do {
    height: 14px;
    background-position: -1379px 0px; }
  .iti__flag.iti__dz {
    height: 14px;
    background-position: -1401px 0px; }
  .iti__flag.iti__ea {
    height: 14px;
    background-position: -1423px 0px; }
  .iti__flag.iti__ec {
    height: 14px;
    background-position: -1445px 0px; }
  .iti__flag.iti__ee {
    height: 13px;
    background-position: -1467px 0px; }
  .iti__flag.iti__eg {
    height: 14px;
    background-position: -1489px 0px; }
  .iti__flag.iti__eh {
    height: 10px;
    background-position: -1511px 0px; }
  .iti__flag.iti__er {
    height: 10px;
    background-position: -1533px 0px; }
  .iti__flag.iti__es {
    height: 14px;
    background-position: -1555px 0px; }
  .iti__flag.iti__et {
    height: 10px;
    background-position: -1577px 0px; }
  .iti__flag.iti__eu {
    height: 14px;
    background-position: -1599px 0px; }
  .iti__flag.iti__fi {
    height: 12px;
    background-position: -1621px 0px; }
  .iti__flag.iti__fj {
    height: 10px;
    background-position: -1643px 0px; }
  .iti__flag.iti__fk {
    height: 10px;
    background-position: -1665px 0px; }
  .iti__flag.iti__fm {
    height: 11px;
    background-position: -1687px 0px; }
  .iti__flag.iti__fo {
    height: 15px;
    background-position: -1709px 0px; }
  .iti__flag.iti__fr {
    height: 14px;
    background-position: -1731px 0px; }
  .iti__flag.iti__ga {
    height: 15px;
    background-position: -1753px 0px; }
  .iti__flag.iti__gb {
    height: 10px;
    background-position: -1775px 0px; }
  .iti__flag.iti__gd {
    height: 12px;
    background-position: -1797px 0px; }
  .iti__flag.iti__ge {
    height: 14px;
    background-position: -1819px 0px; }
  .iti__flag.iti__gf {
    height: 14px;
    background-position: -1841px 0px; }
  .iti__flag.iti__gg {
    height: 14px;
    background-position: -1863px 0px; }
  .iti__flag.iti__gh {
    height: 14px;
    background-position: -1885px 0px; }
  .iti__flag.iti__gi {
    height: 10px;
    background-position: -1907px 0px; }
  .iti__flag.iti__gl {
    height: 14px;
    background-position: -1929px 0px; }
  .iti__flag.iti__gm {
    height: 14px;
    background-position: -1951px 0px; }
  .iti__flag.iti__gn {
    height: 14px;
    background-position: -1973px 0px; }
  .iti__flag.iti__gp {
    height: 14px;
    background-position: -1995px 0px; }
  .iti__flag.iti__gq {
    height: 14px;
    background-position: -2017px 0px; }
  .iti__flag.iti__gr {
    height: 14px;
    background-position: -2039px 0px; }
  .iti__flag.iti__gs {
    height: 10px;
    background-position: -2061px 0px; }
  .iti__flag.iti__gt {
    height: 13px;
    background-position: -2083px 0px; }
  .iti__flag.iti__gu {
    height: 11px;
    background-position: -2105px 0px; }
  .iti__flag.iti__gw {
    height: 10px;
    background-position: -2127px 0px; }
  .iti__flag.iti__gy {
    height: 12px;
    background-position: -2149px 0px; }
  .iti__flag.iti__hk {
    height: 14px;
    background-position: -2171px 0px; }
  .iti__flag.iti__hm {
    height: 10px;
    background-position: -2193px 0px; }
  .iti__flag.iti__hn {
    height: 10px;
    background-position: -2215px 0px; }
  .iti__flag.iti__hr {
    height: 10px;
    background-position: -2237px 0px; }
  .iti__flag.iti__ht {
    height: 12px;
    background-position: -2259px 0px; }
  .iti__flag.iti__hu {
    height: 10px;
    background-position: -2281px 0px; }
  .iti__flag.iti__ic {
    height: 14px;
    background-position: -2303px 0px; }
  .iti__flag.iti__id {
    height: 14px;
    background-position: -2325px 0px; }
  .iti__flag.iti__ie {
    height: 10px;
    background-position: -2347px 0px; }
  .iti__flag.iti__il {
    height: 15px;
    background-position: -2369px 0px; }
  .iti__flag.iti__im {
    height: 10px;
    background-position: -2391px 0px; }
  .iti__flag.iti__in {
    height: 14px;
    background-position: -2413px 0px; }
  .iti__flag.iti__io {
    height: 10px;
    background-position: -2435px 0px; }
  .iti__flag.iti__iq {
    height: 14px;
    background-position: -2457px 0px; }
  .iti__flag.iti__ir {
    height: 12px;
    background-position: -2479px 0px; }
  .iti__flag.iti__is {
    height: 15px;
    background-position: -2501px 0px; }
  .iti__flag.iti__it {
    height: 14px;
    background-position: -2523px 0px; }
  .iti__flag.iti__je {
    height: 12px;
    background-position: -2545px 0px; }
  .iti__flag.iti__jm {
    height: 10px;
    background-position: -2567px 0px; }
  .iti__flag.iti__jo {
    height: 10px;
    background-position: -2589px 0px; }
  .iti__flag.iti__jp {
    height: 14px;
    background-position: -2611px 0px; }
  .iti__flag.iti__ke {
    height: 14px;
    background-position: -2633px 0px; }
  .iti__flag.iti__kg {
    height: 12px;
    background-position: -2655px 0px; }
  .iti__flag.iti__kh {
    height: 13px;
    background-position: -2677px 0px; }
  .iti__flag.iti__ki {
    height: 10px;
    background-position: -2699px 0px; }
  .iti__flag.iti__km {
    height: 12px;
    background-position: -2721px 0px; }
  .iti__flag.iti__kn {
    height: 14px;
    background-position: -2743px 0px; }
  .iti__flag.iti__kp {
    height: 10px;
    background-position: -2765px 0px; }
  .iti__flag.iti__kr {
    height: 14px;
    background-position: -2787px 0px; }
  .iti__flag.iti__kw {
    height: 10px;
    background-position: -2809px 0px; }
  .iti__flag.iti__ky {
    height: 10px;
    background-position: -2831px 0px; }
  .iti__flag.iti__kz {
    height: 10px;
    background-position: -2853px 0px; }
  .iti__flag.iti__la {
    height: 14px;
    background-position: -2875px 0px; }
  .iti__flag.iti__lb {
    height: 14px;
    background-position: -2897px 0px; }
  .iti__flag.iti__lc {
    height: 10px;
    background-position: -2919px 0px; }
  .iti__flag.iti__li {
    height: 12px;
    background-position: -2941px 0px; }
  .iti__flag.iti__lk {
    height: 10px;
    background-position: -2963px 0px; }
  .iti__flag.iti__lr {
    height: 11px;
    background-position: -2985px 0px; }
  .iti__flag.iti__ls {
    height: 14px;
    background-position: -3007px 0px; }
  .iti__flag.iti__lt {
    height: 12px;
    background-position: -3029px 0px; }
  .iti__flag.iti__lu {
    height: 12px;
    background-position: -3051px 0px; }
  .iti__flag.iti__lv {
    height: 10px;
    background-position: -3073px 0px; }
  .iti__flag.iti__ly {
    height: 10px;
    background-position: -3095px 0px; }
  .iti__flag.iti__ma {
    height: 14px;
    background-position: -3117px 0px; }
  .iti__flag.iti__mc {
    height: 15px;
    background-position: -3139px 0px; }
  .iti__flag.iti__md {
    height: 10px;
    background-position: -3160px 0px; }
  .iti__flag.iti__me {
    height: 10px;
    background-position: -3182px 0px; }
  .iti__flag.iti__mf {
    height: 14px;
    background-position: -3204px 0px; }
  .iti__flag.iti__mg {
    height: 14px;
    background-position: -3226px 0px; }
  .iti__flag.iti__mh {
    height: 11px;
    background-position: -3248px 0px; }
  .iti__flag.iti__mk {
    height: 10px;
    background-position: -3270px 0px; }
  .iti__flag.iti__ml {
    height: 14px;
    background-position: -3292px 0px; }
  .iti__flag.iti__mm {
    height: 14px;
    background-position: -3314px 0px; }
  .iti__flag.iti__mn {
    height: 10px;
    background-position: -3336px 0px; }
  .iti__flag.iti__mo {
    height: 14px;
    background-position: -3358px 0px; }
  .iti__flag.iti__mp {
    height: 10px;
    background-position: -3380px 0px; }
  .iti__flag.iti__mq {
    height: 14px;
    background-position: -3402px 0px; }
  .iti__flag.iti__mr {
    height: 14px;
    background-position: -3424px 0px; }
  .iti__flag.iti__ms {
    height: 10px;
    background-position: -3446px 0px; }
  .iti__flag.iti__mt {
    height: 14px;
    background-position: -3468px 0px; }
  .iti__flag.iti__mu {
    height: 14px;
    background-position: -3490px 0px; }
  .iti__flag.iti__mv {
    height: 14px;
    background-position: -3512px 0px; }
  .iti__flag.iti__mw {
    height: 14px;
    background-position: -3534px 0px; }
  .iti__flag.iti__mx {
    height: 12px;
    background-position: -3556px 0px; }
  .iti__flag.iti__my {
    height: 10px;
    background-position: -3578px 0px; }
  .iti__flag.iti__mz {
    height: 14px;
    background-position: -3600px 0px; }
  .iti__flag.iti__na {
    height: 14px;
    background-position: -3622px 0px; }
  .iti__flag.iti__nc {
    height: 10px;
    background-position: -3644px 0px; }
  .iti__flag.iti__ne {
    height: 15px;
    background-position: -3666px 0px; }
  .iti__flag.iti__nf {
    height: 10px;
    background-position: -3686px 0px; }
  .iti__flag.iti__ng {
    height: 10px;
    background-position: -3708px 0px; }
  .iti__flag.iti__ni {
    height: 12px;
    background-position: -3730px 0px; }
  .iti__flag.iti__nl {
    height: 14px;
    background-position: -3752px 0px; }
  .iti__flag.iti__no {
    height: 15px;
    background-position: -3774px 0px; }
  .iti__flag.iti__np {
    height: 15px;
    background-position: -3796px 0px; }
  .iti__flag.iti__nr {
    height: 10px;
    background-position: -3811px 0px; }
  .iti__flag.iti__nu {
    height: 10px;
    background-position: -3833px 0px; }
  .iti__flag.iti__nz {
    height: 10px;
    background-position: -3855px 0px; }
  .iti__flag.iti__om {
    height: 10px;
    background-position: -3877px 0px; }
  .iti__flag.iti__pa {
    height: 14px;
    background-position: -3899px 0px; }
  .iti__flag.iti__pe {
    height: 14px;
    background-position: -3921px 0px; }
  .iti__flag.iti__pf {
    height: 14px;
    background-position: -3943px 0px; }
  .iti__flag.iti__pg {
    height: 15px;
    background-position: -3965px 0px; }
  .iti__flag.iti__ph {
    height: 10px;
    background-position: -3987px 0px; }
  .iti__flag.iti__pk {
    height: 14px;
    background-position: -4009px 0px; }
  .iti__flag.iti__pl {
    height: 13px;
    background-position: -4031px 0px; }
  .iti__flag.iti__pm {
    height: 14px;
    background-position: -4053px 0px; }
  .iti__flag.iti__pn {
    height: 10px;
    background-position: -4075px 0px; }
  .iti__flag.iti__pr {
    height: 14px;
    background-position: -4097px 0px; }
  .iti__flag.iti__ps {
    height: 10px;
    background-position: -4119px 0px; }
  .iti__flag.iti__pt {
    height: 14px;
    background-position: -4141px 0px; }
  .iti__flag.iti__pw {
    height: 13px;
    background-position: -4163px 0px; }
  .iti__flag.iti__py {
    height: 11px;
    background-position: -4185px 0px; }
  .iti__flag.iti__qa {
    height: 8px;
    background-position: -4207px 0px; }
  .iti__flag.iti__re {
    height: 14px;
    background-position: -4229px 0px; }
  .iti__flag.iti__ro {
    height: 14px;
    background-position: -4251px 0px; }
  .iti__flag.iti__rs {
    height: 14px;
    background-position: -4273px 0px; }
  .iti__flag.iti__ru {
    height: 14px;
    background-position: -4295px 0px; }
  .iti__flag.iti__rw {
    height: 14px;
    background-position: -4317px 0px; }
  .iti__flag.iti__sa {
    height: 14px;
    background-position: -4339px 0px; }
  .iti__flag.iti__sb {
    height: 10px;
    background-position: -4361px 0px; }
  .iti__flag.iti__sc {
    height: 10px;
    background-position: -4383px 0px; }
  .iti__flag.iti__sd {
    height: 10px;
    background-position: -4405px 0px; }
  .iti__flag.iti__se {
    height: 13px;
    background-position: -4427px 0px; }
  .iti__flag.iti__sg {
    height: 14px;
    background-position: -4449px 0px; }
  .iti__flag.iti__sh {
    height: 10px;
    background-position: -4471px 0px; }
  .iti__flag.iti__si {
    height: 10px;
    background-position: -4493px 0px; }
  .iti__flag.iti__sj {
    height: 15px;
    background-position: -4515px 0px; }
  .iti__flag.iti__sk {
    height: 14px;
    background-position: -4537px 0px; }
  .iti__flag.iti__sl {
    height: 14px;
    background-position: -4559px 0px; }
  .iti__flag.iti__sm {
    height: 15px;
    background-position: -4581px 0px; }
  .iti__flag.iti__sn {
    height: 14px;
    background-position: -4603px 0px; }
  .iti__flag.iti__so {
    height: 14px;
    background-position: -4625px 0px; }
  .iti__flag.iti__sr {
    height: 14px;
    background-position: -4647px 0px; }
  .iti__flag.iti__ss {
    height: 10px;
    background-position: -4669px 0px; }
  .iti__flag.iti__st {
    height: 10px;
    background-position: -4691px 0px; }
  .iti__flag.iti__sv {
    height: 12px;
    background-position: -4713px 0px; }
  .iti__flag.iti__sx {
    height: 14px;
    background-position: -4735px 0px; }
  .iti__flag.iti__sy {
    height: 14px;
    background-position: -4757px 0px; }
  .iti__flag.iti__sz {
    height: 14px;
    background-position: -4779px 0px; }
  .iti__flag.iti__ta {
    height: 10px;
    background-position: -4801px 0px; }
  .iti__flag.iti__tc {
    height: 10px;
    background-position: -4823px 0px; }
  .iti__flag.iti__td {
    height: 14px;
    background-position: -4845px 0px; }
  .iti__flag.iti__tf {
    height: 14px;
    background-position: -4867px 0px; }
  .iti__flag.iti__tg {
    height: 13px;
    background-position: -4889px 0px; }
  .iti__flag.iti__th {
    height: 14px;
    background-position: -4911px 0px; }
  .iti__flag.iti__tj {
    height: 10px;
    background-position: -4933px 0px; }
  .iti__flag.iti__tk {
    height: 10px;
    background-position: -4955px 0px; }
  .iti__flag.iti__tl {
    height: 10px;
    background-position: -4977px 0px; }
  .iti__flag.iti__tm {
    height: 14px;
    background-position: -4999px 0px; }
  .iti__flag.iti__tn {
    height: 14px;
    background-position: -5021px 0px; }
  .iti__flag.iti__to {
    height: 10px;
    background-position: -5043px 0px; }
  .iti__flag.iti__tr {
    height: 14px;
    background-position: -5065px 0px; }
  .iti__flag.iti__tt {
    height: 12px;
    background-position: -5087px 0px; }
  .iti__flag.iti__tv {
    height: 10px;
    background-position: -5109px 0px; }
  .iti__flag.iti__tw {
    height: 14px;
    background-position: -5131px 0px; }
  .iti__flag.iti__tz {
    height: 14px;
    background-position: -5153px 0px; }
  .iti__flag.iti__ua {
    height: 14px;
    background-position: -5175px 0px; }
  .iti__flag.iti__ug {
    height: 14px;
    background-position: -5197px 0px; }
  .iti__flag.iti__um {
    height: 11px;
    background-position: -5219px 0px; }
  .iti__flag.iti__un {
    height: 14px;
    background-position: -5241px 0px; }
  .iti__flag.iti__us {
    height: 11px;
    background-position: -5263px 0px; }
  .iti__flag.iti__uy {
    height: 14px;
    background-position: -5285px 0px; }
  .iti__flag.iti__uz {
    height: 10px;
    background-position: -5307px 0px; }
  .iti__flag.iti__va {
    height: 15px;
    background-position: -5329px 0px; }
  .iti__flag.iti__vc {
    height: 14px;
    background-position: -5346px 0px; }
  .iti__flag.iti__ve {
    height: 14px;
    background-position: -5368px 0px; }
  .iti__flag.iti__vg {
    height: 10px;
    background-position: -5390px 0px; }
  .iti__flag.iti__vi {
    height: 14px;
    background-position: -5412px 0px; }
  .iti__flag.iti__vn {
    height: 14px;
    background-position: -5434px 0px; }
  .iti__flag.iti__vu {
    height: 12px;
    background-position: -5456px 0px; }
  .iti__flag.iti__wf {
    height: 14px;
    background-position: -5478px 0px; }
  .iti__flag.iti__ws {
    height: 10px;
    background-position: -5500px 0px; }
  .iti__flag.iti__xk {
    height: 15px;
    background-position: -5522px 0px; }
  .iti__flag.iti__ye {
    height: 14px;
    background-position: -5544px 0px; }
  .iti__flag.iti__yt {
    height: 14px;
    background-position: -5566px 0px; }
  .iti__flag.iti__za {
    height: 14px;
    background-position: -5588px 0px; }
  .iti__flag.iti__zm {
    height: 14px;
    background-position: -5610px 0px; }
  .iti__flag.iti__zw {
    height: 10px;
    background-position: -5632px 0px; }
  .iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url('flags.007b2705c0a8f69dfdf6.png');
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-image: url('flags@2x.9d5328fb490cddd43f66.png'); } }
  .iti__flag.iti__np {
  background-color: transparent; }

/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .bs-datepicker-buttons */ }
.bs-datepicker:after {
    clear: both;
    content: '';
    display: block; }
.bs-datepicker bs-day-picker {
    float: left; }
.bs-datepicker button:hover,
  .bs-datepicker button:focus,
  .bs-datepicker button:active,
  .bs-datepicker input:hover,
  .bs-datepicker input:focus,
  .bs-datepicker input:active,
  .bs-datepicker-btns button:hover,
  .bs-datepicker-btns button:focus,
  .bs-datepicker-btns button:active,
  .bs-datepicker-predefined-btns button:active,
  .bs-datepicker-predefined-btns button:focus {
    outline: none; }
.bs-datepicker-head {
    min-width: 270px;
    height: 50px;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    text-align: justify;
    /* .bs-datepicker-head button */ }
.bs-datepicker-head:after {
      content: "";
      display: inline-block;
      vertical-align: top;
      width: 100%; }
.bs-datepicker-head button {
      display: inline-block;
      vertical-align: top;
      padding: 0;
      height: 30px;
      line-height: 30px;
      border: 0;
      background: transparent;
      text-align: center;
      cursor: pointer;
      color: #fff;
      transition: 0.3s; }
.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
        background: rgba(221, 221, 221, 0.3);
        color: #f5f5f5;
        cursor: not-allowed; }
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
        border-radius: 50%;
        width: 30px;
        height: 30px; }
.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
          font-size: 28px;
          line-height: 1;
          display: inline-block;
          position: relative;
          height: 100%;
          width: 100%;
          border-radius: 50%; }
.bs-datepicker-head button.current {
        border-radius: 15px;
        max-width: 155px;
        padding: 0 13px; }
.bs-datepicker-head button:hover {
    background-color: rgba(0, 0, 0, 0.1); }
.bs-datepicker-head button:active {
    background-color: rgba(0, 0, 0, 0.2); }
.bs-datepicker-body {
    padding: 10px;
    border-radius: 0 0 3px 3px;
    min-height: 232px;
    min-width: 278px;
    border: 1px solid #e9edf0;
    /* .bs-datepicker-body table */ }
.bs-datepicker-body .days.weeks {
      position: relative;
      z-index: 1; }
.bs-datepicker-body table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      /* .bs-datepicker-body table.days */
      /* .bs-datepicker-body table.weeks */ }
.bs-datepicker-body table th {
        font-size: 13px;
        color: #9aaec1;
        font-weight: 400;
        text-align: center; }
.bs-datepicker-body table td {
        color: #54708b;
        text-align: center;
        position: relative;
        padding: 0; }
.bs-datepicker-body table td span {
          display: block;
          margin: 0 auto;
          font-size: 13px;
          border-radius: 50%;
          /*z-index: 1;*/
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none; }
.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
          cursor: pointer; }
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
        .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
          background-color: #e9edf0;
          transition: 0s; }
.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
        .bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
          background-color: #e9edf0;
          transition: 0s;
          cursor: pointer; }
.bs-datepicker-body table td span.disabled,
        .bs-datepicker-body table td.disabled span {
          color: #9aaec1; }
.bs-datepicker-body table td span.selected,
        .bs-datepicker-body table td.selected span {
          color: #fff; }
.bs-datepicker-body table td span.is-other-month,
        .bs-datepicker-body table td.is-other-month span {
          color: rgba(0, 0, 0, 0.25); }
.bs-datepicker-body table td.active {
          position: relative; }
.bs-datepicker-body table td.active.select-start:before {
            left: 35%; }
.bs-datepicker-body table td.active.select-end:before {
            left: -85%; }
.bs-datepicker-body table td span.active.select-start:after,
        .bs-datepicker-body table td span.active.select-end:after,
        .bs-datepicker-body table td.active.select-start span:after,
        .bs-datepicker-body table td.active.select-end span:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          top: 0;
          border-radius: 50%; }
.bs-datepicker-body table td:before,
        .bs-datepicker-body table td span:before {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          top: 6px;
          bottom: 6px;
          left: -3px;
          right: -2px;
          box-sizing: content-box;
          background: transparent; }
.bs-datepicker-body table td.active.select-start + td.active:before {
          left: -20%; }
.bs-datepicker-body table td:last-child.active:before {
          border-radius: 0 3px 3px 0;
          width: 125%;
          left: -25%; }
.bs-datepicker-body table td span[class*="select-"],
        .bs-datepicker-body table td[class*="select-"] span {
          border-radius: 50%;
          color: #fff; }
.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
      .bs-datepicker-body table.days span.active:not(.select-start):before,
      .bs-datepicker-body table.days span.in-range:not(.select-start):before {
        background: #e9edf0; }
.bs-datepicker-body table.days span {
        width: 32px;
        height: 32px;
        line-height: 32px; }
.bs-datepicker-body table.days span.select-start {
          z-index: 2; }
.bs-datepicker-body table.days span.is-highlighted.in-range:before {
          right: 3px;
          left: 0; }
.bs-datepicker-body table.days span.in-range.select-end:before {
          right: 4px;
          left: 0; }
.bs-datepicker-body table.days td.select-start + td.select-end:before,
      .bs-datepicker-body table.days td.select-start + td.is-highlighted:before,
      .bs-datepicker-body table.days td.active + td.is-highlighted:before,
      .bs-datepicker-body table.days td.active + td.select-end:before,
      .bs-datepicker-body table.days td.in-range + td.is-highlighted:before,
      .bs-datepicker-body table.days td.in-range + td.select-end:before {
        background: #e9edf0;
        width: 100%; }
.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
        border-radius: 3px 0 0 3px;
        left: 0;
        width: 100%; }
.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
        border-radius: 3px 0 0 3px; }
.bs-datepicker-body table.years td span {
        width: 46px;
        height: 46px;
        line-height: 45px;
        margin: 0 auto; }
.bs-datepicker-body table.years tr:not(:last-child) td span {
        margin-bottom: 8px; }
.bs-datepicker-body table.months td {
        height: 52px; }
.bs-datepicker-body table.months td span {
          padding: 6px;
          border-radius: 15px; }
.bs-datepicker .current-timedate {
    color: #54708b;
    font-size: 15px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    border: 1px solid #e9edf0;
    margin-bottom: 10px;
    cursor: pointer;
    text-transform: uppercase;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; }
.bs-datepicker .current-timedate span:not(:empty):before {
      content: "";
      width: 15px;
      height: 16px;
      display: inline-block;
      margin-right: 4px;
      vertical-align: text-bottom;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC); }
.bs-datepicker-multiple {
    border-radius: 4px 0 0 4px; }
.bs-datepicker-multiple + .bs-datepicker-multiple {
      margin-left: 10px; }
.bs-datepicker-multiple .bs-datepicker {
      box-shadow: none;
      position: relative; }
.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
        padding-right: 10px; }
.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
        content: "";
        display: block;
        width: 14px;
        height: 10px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
        position: absolute;
        top: 25px;
        left: -8px; }
.bs-datepicker-multiple .bs-datepicker .left {
        float: left; }
.bs-datepicker-multiple .bs-datepicker .right {
        float: right; }
.bs-datepicker-container {
    padding: 15px; }
.bs-datepicker .bs-media-container {
    display: flex; }
@media (max-width: 768px) {
      .bs-datepicker .bs-media-container {
        flex-direction: column; } }
.bs-datepicker-custom-range {
    padding: 15px;
    background: #eee; }
.bs-datepicker-predefined-btns button {
    width: 100%;
    display: block;
    height: 30px;
    background-color: #9aaec1;
    border-radius: 4px;
    color: #fff;
    border: 0;
    margin-bottom: 10px;
    padding: 0 18px;
    text-align: left;
    transition: 0.3s; }
.bs-datepicker-predefined-btns button:hover {
      background-color: #54708b; }
.bs-datepicker-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid #e9edf0; }
.bs-datepicker-buttons .btn-default {
      margin-left: 10px; }
.bs-datepicker-buttons .btn-today-wrapper {
      display: flex;
      flex-flow: row wrap; }
.bs-datepicker-buttons .clear-right,
    .bs-datepicker-buttons .today-right {
      flex-grow: 0; }
.bs-datepicker-buttons .clear-left,
    .bs-datepicker-buttons .today-left {
      flex-grow: 1; }
.bs-datepicker-buttons .clear-center,
    .bs-datepicker-buttons .today-center {
      flex-grow: 0.5; }
/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0; }
.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px; }
.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px; }
.bs-timepicker-controls button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 0;
    background-color: #e9edf0;
    color: #54708b;
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
    line-height: 0;
    padding: 0;
    transition: 0.3s; }
.bs-timepicker-controls button:hover {
      background-color: #d5dadd; }
.bs-timepicker-controls input {
    width: 35px;
    height: 25px;
    border-radius: 13px;
    text-align: center;
    border: 1px solid #e9edf0; }
.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 13px; }
.bs-timepicker .switch-time-format img {
    vertical-align: initial;
    margin-left: 4px; }
bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080; }
/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker-multiple {
    display: flex; }
    .bs-datepicker-multiple + .bs-datepicker-multiple {
      margin-top: 10px;
      margin-left: 0; } }
/* theming */
.theme-default .bs-datepicker-head {
  background-color: #777; }
.theme-default .btn-today-wrapper .btn-success, .theme-default .btn-clear-wrapper .btn-success {
  background-color: #777;
  border-color: #777; }
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none; }
.theme-default .btn-today-wrapper .btn-success:focus, .theme-default .btn-clear-wrapper .btn-success:focus {
  box-shadow: none; }
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #616161;
  border-color: #616161; }
.theme-default .btn-today-wrapper .btn-success:hover, .theme-default .btn-clear-wrapper .btn-success:hover {
  background-color: #6F6E6E;
  border-color: #6F6E6E; }
.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #777; }
.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*="select-"]:after,
.theme-default .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #777; }
.theme-default .bs-datepicker-body table td.week span {
  color: #777; }
.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  opacity: 0.5;
  transition: 0s; }
.theme-green .bs-datepicker-head {
  background-color: #5cb85c; }
.theme-green .btn-today-wrapper .btn-success, .theme-green .btn-clear-wrapper .btn-success {
  background-color: #5cb85c;
  border-color: #5cb85c; }
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none; }
.theme-green .btn-today-wrapper .btn-success:focus, .theme-green .btn-clear-wrapper .btn-success:focus {
  box-shadow: none; }
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #1e7e34;
  border-color: #1e7e34; }
.theme-green .btn-today-wrapper .btn-success:hover, .theme-green .btn-clear-wrapper .btn-success:hover {
  background-color: #218838;
  border-color: #218838; }
.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #5cb85c; }
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #5cb85c; }
.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c; }
.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5cb85c;
  color: #fff;
  opacity: 0.5;
  transition: 0s; }
.theme-blue .bs-datepicker-head {
  background-color: #5bc0de; }
.theme-blue .btn-today-wrapper .btn-success, .theme-blue .btn-clear-wrapper .btn-success {
  background-color: #5bc0de;
  border-color: #5bc0de; }
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none; }
.theme-blue .btn-today-wrapper .btn-success:focus, .theme-blue .btn-clear-wrapper .btn-success:focus {
  box-shadow: none; }
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #2AA8CD;
  border-color: #2AA8CD; }
.theme-blue .btn-today-wrapper .btn-success:hover, .theme-blue .btn-clear-wrapper .btn-success:hover {
  background-color: #3AB3D7;
  border-color: #3AB3D7; }
.theme-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #5bc0de; }
.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-blue .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #5bc0de; }
.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de; }
.theme-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5bc0de;
  color: #fff;
  opacity: 0.5;
  transition: 0s; }
.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7; }
.theme-dark-blue .btn-today-wrapper .btn-success, .theme-dark-blue .btn-clear-wrapper .btn-success {
  background-color: #337ab7;
  border-color: #337ab7; }
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none; }
.theme-dark-blue .btn-today-wrapper .btn-success:focus, .theme-dark-blue .btn-clear-wrapper .btn-success:focus {
  box-shadow: none; }
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #266498;
  border-color: #266498; }
.theme-dark-blue .btn-today-wrapper .btn-success:hover, .theme-dark-blue .btn-clear-wrapper .btn-success:hover {
  background-color: #2C6FA9;
  border-color: #2C6FA9; }
.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #337ab7; }
.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-dark-blue .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #337ab7; }
.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7; }
.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #337ab7;
  color: #fff;
  opacity: 0.5;
  transition: 0s; }
.theme-red .bs-datepicker-head {
  background-color: #d9534f; }
.theme-red .btn-today-wrapper .btn-success, .theme-red .btn-clear-wrapper .btn-success {
  background-color: #d9534f;
  border-color: #d9534f; }
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none; }
.theme-red .btn-today-wrapper .btn-success:focus, .theme-red .btn-clear-wrapper .btn-success:focus {
  box-shadow: none; }
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #D23531;
  border-color: #D23531; }
.theme-red .btn-today-wrapper .btn-success:hover, .theme-red .btn-clear-wrapper .btn-success:hover {
  background-color: #E33732;
  border-color: #E33732; }
.theme-red .bs-datepicker-predefined-btns button.selected {
  background-color: #d9534f; }
.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*="select-"]:after,
.theme-red .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #d9534f; }
.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f; }
.theme-red .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  opacity: 0.5;
  transition: 0s; }
.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e; }
.theme-orange .btn-today-wrapper .btn-success, .theme-orange .btn-clear-wrapper .btn-success {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none; }
.theme-orange .btn-today-wrapper .btn-success:focus, .theme-orange .btn-clear-wrapper .btn-success:focus {
  box-shadow: none; }
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #ED9C29;
  border-color: #ED9C29; }
.theme-orange .btn-today-wrapper .btn-success:hover, .theme-orange .btn-clear-wrapper .btn-success:hover {
  background-color: #FFAC35;
  border-color: #FFAC35; }
.theme-orange .bs-datepicker-predefined-btns button.selected {
  background-color: #f0ad4e; }
.theme-orange .bs-datepicker-body table td span.selected,
.theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*="select-"]:after,
.theme-orange .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #f0ad4e; }
.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e; }
.theme-orange .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #f0ad4e;
  color: #fff;
  opacity: 0.5;
  transition: 0s; }
/*# sourceMappingURL=bs-datepicker.css.map */
/*!
 * Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }
.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }
.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro'; }
.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands'; }
.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: 'Font Awesome 6 Duotone'; }
.fass,
.fa-sharp {
  font-family: 'Font Awesome 6 Sharp';
  font-weight: 900; }
.fa-1x {
  font-size: 1em; }
.fa-2x {
  font-size: 2em; }
.fa-3x {
  font-size: 3em; }
.fa-4x {
  font-size: 4em; }
.fa-5x {
  font-size: 5em; }
.fa-6x {
  font-size: 6em; }
.fa-7x {
  font-size: 7em; }
.fa-8x {
  font-size: 8em; }
.fa-9x {
  font-size: 9em; }
.fa-10x {
  font-size: 10em; }
.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }
.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }
.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }
.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }
.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }
.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }
.fa-fw {
  text-align: center;
  width: 1.25em; }
.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
.fa-ul > li {
    position: relative; }
.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }
.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }
.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }
.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }
.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }
.fa-bounce {
  -webkit-animation-name: fa-bounce;
          animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }
.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }
.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
          animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }
.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }
.fa-shake {
  -webkit-animation-name: fa-shake;
          animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }
.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }
.fa-spin-reverse {
  --fa-animation-direction: reverse; }
.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8)); }
@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }
@-webkit-keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }
@-webkit-keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }
@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }
@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }
@-webkit-keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }
@-webkit-keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }
@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
.fa-rotate-90 {
  transform: rotate(90deg); }
.fa-rotate-180 {
  transform: rotate(180deg); }
.fa-rotate-270 {
  transform: rotate(270deg); }
.fa-flip-horizontal {
  transform: scale(-1, 1); }
.fa-flip-vertical {
  transform: scale(1, -1); }
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }
.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none)); }
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto); }
.fa-stack-1x {
  line-height: inherit; }
.fa-stack-2x {
  font-size: 2em; }
.fa-inverse {
  color: var(--fa-inverse, #fff); }
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30"; }
.fa-1::before {
  content: "\31"; }
.fa-2::before {
  content: "\32"; }
.fa-3::before {
  content: "\33"; }
.fa-4::before {
  content: "\34"; }
.fa-5::before {
  content: "\35"; }
.fa-6::before {
  content: "\36"; }
.fa-7::before {
  content: "\37"; }
.fa-8::before {
  content: "\38"; }
.fa-9::before {
  content: "\39"; }
.fa-fill-drip::before {
  content: "\f576"; }
.fa-arrows-to-circle::before {
  content: "\e4bd"; }
.fa-circle-chevron-right::before {
  content: "\f138"; }
.fa-chevron-circle-right::before {
  content: "\f138"; }
.fa-wagon-covered::before {
  content: "\f8ee"; }
.fa-line-height::before {
  content: "\f871"; }
.fa-bagel::before {
  content: "\e3d7"; }
.fa-transporter-7::before {
  content: "\e2a8"; }
.fa-at::before {
  content: "\40"; }
.fa-rectangles-mixed::before {
  content: "\e323"; }
.fa-phone-arrow-up-right::before {
  content: "\e224"; }
.fa-phone-arrow-up::before {
  content: "\e224"; }
.fa-phone-outgoing::before {
  content: "\e224"; }
.fa-trash-can::before {
  content: "\f2ed"; }
.fa-trash-alt::before {
  content: "\f2ed"; }
.fa-circle-l::before {
  content: "\e114"; }
.fa-head-side-goggles::before {
  content: "\f6ea"; }
.fa-head-vr::before {
  content: "\f6ea"; }
.fa-text-height::before {
  content: "\f034"; }
.fa-user-xmark::before {
  content: "\f235"; }
.fa-user-times::before {
  content: "\f235"; }
.fa-face-hand-yawn::before {
  content: "\e379"; }
.fa-gauge-simple-min::before {
  content: "\f62d"; }
.fa-tachometer-slowest::before {
  content: "\f62d"; }
.fa-stethoscope::before {
  content: "\f0f1"; }
.fa-coffin::before {
  content: "\f6c6"; }
.fa-message::before {
  content: "\f27a"; }
.fa-comment-alt::before {
  content: "\f27a"; }
.fa-salad::before {
  content: "\f81e"; }
.fa-bowl-salad::before {
  content: "\f81e"; }
.fa-info::before {
  content: "\f129"; }
.fa-robot-astromech::before {
  content: "\e2d2"; }
.fa-ring-diamond::before {
  content: "\e5ab"; }
.fa-fondue-pot::before {
  content: "\e40d"; }
.fa-theta::before {
  content: "\f69e"; }
.fa-face-hand-peeking::before {
  content: "\e481"; }
.fa-square-user::before {
  content: "\e283"; }
.fa-down-left-and-up-right-to-center::before {
  content: "\f422"; }
.fa-compress-alt::before {
  content: "\f422"; }
.fa-explosion::before {
  content: "\e4e9"; }
.fa-file-lines::before {
  content: "\f15c"; }
.fa-file-alt::before {
  content: "\f15c"; }
.fa-file-text::before {
  content: "\f15c"; }
.fa-wave-square::before {
  content: "\f83e"; }
.fa-ring::before {
  content: "\f70b"; }
.fa-building-un::before {
  content: "\e4d9"; }
.fa-dice-three::before {
  content: "\f527"; }
.fa-tire-pressure-warning::before {
  content: "\f633"; }
.fa-wifi-fair::before {
  content: "\f6ab"; }
.fa-wifi-2::before {
  content: "\f6ab"; }
.fa-calendar-days::before {
  content: "\f073"; }
.fa-calendar-alt::before {
  content: "\f073"; }
.fa-mp3-player::before {
  content: "\f8ce"; }
.fa-anchor-circle-check::before {
  content: "\e4aa"; }
.fa-tally-4::before {
  content: "\e297"; }
.fa-rectangle-history::before {
  content: "\e4a2"; }
.fa-building-circle-arrow-right::before {
  content: "\e4d1"; }
.fa-volleyball::before {
  content: "\f45f"; }
.fa-volleyball-ball::before {
  content: "\f45f"; }
.fa-sun-haze::before {
  content: "\f765"; }
.fa-text-size::before {
  content: "\f894"; }
.fa-ufo::before {
  content: "\e047"; }
.fa-fork::before {
  content: "\f2e3"; }
.fa-utensil-fork::before {
  content: "\f2e3"; }
.fa-arrows-up-to-line::before {
  content: "\e4c2"; }
.fa-mobile-signal::before {
  content: "\e1ef"; }
.fa-barcode-scan::before {
  content: "\f465"; }
.fa-sort-down::before {
  content: "\f0dd"; }
.fa-sort-desc::before {
  content: "\f0dd"; }
.fa-folder-arrow-down::before {
  content: "\e053"; }
.fa-folder-download::before {
  content: "\e053"; }
.fa-circle-minus::before {
  content: "\f056"; }
.fa-minus-circle::before {
  content: "\f056"; }
.fa-face-icicles::before {
  content: "\e37c"; }
.fa-shovel::before {
  content: "\f713"; }
.fa-door-open::before {
  content: "\f52b"; }
.fa-films::before {
  content: "\e17a"; }
.fa-right-from-bracket::before {
  content: "\f2f5"; }
.fa-sign-out-alt::before {
  content: "\f2f5"; }
.fa-face-glasses::before {
  content: "\e377"; }
.fa-nfc::before {
  content: "\e1f7"; }
.fa-atom::before {
  content: "\f5d2"; }
.fa-soap::before {
  content: "\e06e"; }
.fa-icons::before {
  content: "\f86d"; }
.fa-heart-music-camera-bolt::before {
  content: "\f86d"; }
.fa-microphone-lines-slash::before {
  content: "\f539"; }
.fa-microphone-alt-slash::before {
  content: "\f539"; }
.fa-closed-captioning-slash::before {
  content: "\e135"; }
.fa-calculator-simple::before {
  content: "\f64c"; }
.fa-calculator-alt::before {
  content: "\f64c"; }
.fa-bridge-circle-check::before {
  content: "\e4c9"; }
.fa-sliders-up::before {
  content: "\f3f1"; }
.fa-sliders-v::before {
  content: "\f3f1"; }
.fa-location-minus::before {
  content: "\f609"; }
.fa-map-marker-minus::before {
  content: "\f609"; }
.fa-pump-medical::before {
  content: "\e06a"; }
.fa-fingerprint::before {
  content: "\f577"; }
.fa-ski-boot::before {
  content: "\e3cc"; }
.fa-standard-definition::before {
  content: "\e28a"; }
.fa-rectangle-sd::before {
  content: "\e28a"; }
.fa-h1::before {
  content: "\f313"; }
.fa-hand-point-right::before {
  content: "\f0a4"; }
.fa-magnifying-glass-location::before {
  content: "\f689"; }
.fa-search-location::before {
  content: "\f689"; }
.fa-message-bot::before {
  content: "\e3b8"; }
.fa-forward-step::before {
  content: "\f051"; }
.fa-step-forward::before {
  content: "\f051"; }
.fa-face-smile-beam::before {
  content: "\f5b8"; }
.fa-smile-beam::before {
  content: "\f5b8"; }
.fa-light-ceiling::before {
  content: "\e016"; }
.fa-message-exclamation::before {
  content: "\f4a5"; }
.fa-comment-alt-exclamation::before {
  content: "\f4a5"; }
.fa-bowl-scoop::before {
  content: "\e3de"; }
.fa-bowl-shaved-ice::before {
  content: "\e3de"; }
.fa-square-x::before {
  content: "\e286"; }
.fa-utility-pole-double::before {
  content: "\e2c4"; }
.fa-flag-checkered::before {
  content: "\f11e"; }
.fa-chevrons-up::before {
  content: "\f325"; }
.fa-chevron-double-up::before {
  content: "\f325"; }
.fa-football::before {
  content: "\f44e"; }
.fa-football-ball::before {
  content: "\f44e"; }
.fa-user-vneck::before {
  content: "\e461"; }
.fa-school-circle-exclamation::before {
  content: "\e56c"; }
.fa-crop::before {
  content: "\f125"; }
.fa-angles-down::before {
  content: "\f103"; }
.fa-angle-double-down::before {
  content: "\f103"; }
.fa-users-rectangle::before {
  content: "\e594"; }
.fa-people-roof::before {
  content: "\e537"; }
.fa-square-arrow-right::before {
  content: "\f33b"; }
.fa-arrow-square-right::before {
  content: "\f33b"; }
.fa-location-plus::before {
  content: "\f60a"; }
.fa-map-marker-plus::before {
  content: "\f60a"; }
.fa-lightbulb-exclamation-on::before {
  content: "\e1ca"; }
.fa-people-line::before {
  content: "\e534"; }
.fa-beer-mug-empty::before {
  content: "\f0fc"; }
.fa-beer::before {
  content: "\f0fc"; }
.fa-crate-empty::before {
  content: "\e151"; }
.fa-diagram-predecessor::before {
  content: "\e477"; }
.fa-transporter::before {
  content: "\e042"; }
.fa-calendar-circle-user::before {
  content: "\e471"; }
.fa-arrow-up-long::before {
  content: "\f176"; }
.fa-long-arrow-up::before {
  content: "\f176"; }
.fa-person-carry-box::before {
  content: "\f4cf"; }
.fa-person-carry::before {
  content: "\f4cf"; }
.fa-fire-flame-simple::before {
  content: "\f46a"; }
.fa-burn::before {
  content: "\f46a"; }
.fa-person::before {
  content: "\f183"; }
.fa-male::before {
  content: "\f183"; }
.fa-laptop::before {
  content: "\f109"; }
.fa-file-csv::before {
  content: "\f6dd"; }
.fa-menorah::before {
  content: "\f676"; }
.fa-union::before {
  content: "\f6a2"; }
.fa-chevrons-left::before {
  content: "\f323"; }
.fa-chevron-double-left::before {
  content: "\f323"; }
.fa-circle-heart::before {
  content: "\f4c7"; }
.fa-heart-circle::before {
  content: "\f4c7"; }
.fa-truck-plane::before {
  content: "\e58f"; }
.fa-record-vinyl::before {
  content: "\f8d9"; }
.fa-bring-forward::before {
  content: "\f856"; }
.fa-square-p::before {
  content: "\e279"; }
.fa-face-grin-stars::before {
  content: "\f587"; }
.fa-grin-stars::before {
  content: "\f587"; }
.fa-sigma::before {
  content: "\f68b"; }
.fa-camera-movie::before {
  content: "\f8a9"; }
.fa-bong::before {
  content: "\f55c"; }
.fa-clarinet::before {
  content: "\f8ad"; }
.fa-truck-flatbed::before {
  content: "\e2b6"; }
.fa-spaghetti-monster-flying::before {
  content: "\f67b"; }
.fa-pastafarianism::before {
  content: "\f67b"; }
.fa-arrow-down-up-across-line::before {
  content: "\e4af"; }
.fa-leaf-heart::before {
  content: "\f4cb"; }
.fa-house-building::before {
  content: "\e1b1"; }
.fa-cheese-swiss::before {
  content: "\f7f0"; }
.fa-spoon::before {
  content: "\f2e5"; }
.fa-utensil-spoon::before {
  content: "\f2e5"; }
.fa-jar-wheat::before {
  content: "\e517"; }
.fa-envelopes-bulk::before {
  content: "\f674"; }
.fa-mail-bulk::before {
  content: "\f674"; }
.fa-file-circle-exclamation::before {
  content: "\e4eb"; }
.fa-bow-arrow::before {
  content: "\f6b9"; }
.fa-cart-xmark::before {
  content: "\e0dd"; }
.fa-hexagon-xmark::before {
  content: "\f2ee"; }
.fa-times-hexagon::before {
  content: "\f2ee"; }
.fa-xmark-hexagon::before {
  content: "\f2ee"; }
.fa-circle-h::before {
  content: "\f47e"; }
.fa-hospital-symbol::before {
  content: "\f47e"; }
.fa-merge::before {
  content: "\e526"; }
.fa-pager::before {
  content: "\f815"; }
.fa-cart-minus::before {
  content: "\e0db"; }
.fa-address-book::before {
  content: "\f2b9"; }
.fa-contact-book::before {
  content: "\f2b9"; }
.fa-pan-frying::before {
  content: "\e42c"; }
.fa-grid::before {
  content: "\e195"; }
.fa-grid-3::before {
  content: "\e195"; }
.fa-football-helmet::before {
  content: "\f44f"; }
.fa-hand-love::before {
  content: "\e1a5"; }
.fa-trees::before {
  content: "\f724"; }
.fa-strikethrough::before {
  content: "\f0cc"; }
.fa-page::before {
  content: "\e428"; }
.fa-k::before {
  content: "\4b"; }
.fa-diagram-previous::before {
  content: "\e478"; }
.fa-gauge-min::before {
  content: "\f628"; }
.fa-tachometer-alt-slowest::before {
  content: "\f628"; }
.fa-folder-grid::before {
  content: "\e188"; }
.fa-eggplant::before {
  content: "\e16c"; }
.fa-ram::before {
  content: "\f70a"; }
.fa-landmark-flag::before {
  content: "\e51c"; }
.fa-lips::before {
  content: "\f600"; }
.fa-pencil::before {
  content: "\f303"; }
.fa-pencil-alt::before {
  content: "\f303"; }
.fa-backward::before {
  content: "\f04a"; }
.fa-caret-right::before {
  content: "\f0da"; }
.fa-comments::before {
  content: "\f086"; }
.fa-paste::before {
  content: "\f0ea"; }
.fa-file-clipboard::before {
  content: "\f0ea"; }
.fa-desktop-arrow-down::before {
  content: "\e155"; }
.fa-code-pull-request::before {
  content: "\e13c"; }
.fa-pumpkin::before {
  content: "\f707"; }
.fa-clipboard-list::before {
  content: "\f46d"; }
.fa-pen-field::before {
  content: "\e211"; }
.fa-blueberries::before {
  content: "\e2e8"; }
.fa-truck-ramp-box::before {
  content: "\f4de"; }
.fa-truck-loading::before {
  content: "\f4de"; }
.fa-note::before {
  content: "\e1ff"; }
.fa-arrow-down-to-square::before {
  content: "\e096"; }
.fa-user-check::before {
  content: "\f4fc"; }
.fa-cloud-xmark::before {
  content: "\e35f"; }
.fa-vial-virus::before {
  content: "\e597"; }
.fa-book-blank::before {
  content: "\f5d9"; }
.fa-book-alt::before {
  content: "\f5d9"; }
.fa-golf-flag-hole::before {
  content: "\e3ac"; }
.fa-message-arrow-down::before {
  content: "\e1db"; }
.fa-comment-alt-arrow-down::before {
  content: "\e1db"; }
.fa-face-unamused::before {
  content: "\e39f"; }
.fa-sheet-plastic::before {
  content: "\e571"; }
.fa-circle-9::before {
  content: "\e0f6"; }
.fa-blog::before {
  content: "\f781"; }
.fa-user-ninja::before {
  content: "\f504"; }
.fa-pencil-slash::before {
  content: "\e215"; }
.fa-bowling-pins::before {
  content: "\f437"; }
.fa-person-arrow-up-from-line::before {
  content: "\e539"; }
.fa-down-right::before {
  content: "\e16b"; }
.fa-scroll-torah::before {
  content: "\f6a0"; }
.fa-torah::before {
  content: "\f6a0"; }
.fa-blinds-open::before {
  content: "\f8fc"; }
.fa-fence::before {
  content: "\e303"; }
.fa-up::before {
  content: "\f357"; }
.fa-arrow-alt-up::before {
  content: "\f357"; }
.fa-broom-ball::before {
  content: "\f458"; }
.fa-quidditch::before {
  content: "\f458"; }
.fa-quidditch-broom-ball::before {
  content: "\f458"; }
.fa-drumstick::before {
  content: "\f6d6"; }
.fa-square-v::before {
  content: "\e284"; }
.fa-face-awesome::before {
  content: "\e409"; }
.fa-gave-dandy::before {
  content: "\e409"; }
.fa-dial-off::before {
  content: "\e162"; }
.fa-toggle-off::before {
  content: "\f204"; }
.fa-face-smile-horns::before {
  content: "\e391"; }
.fa-box-archive::before {
  content: "\f187"; }
.fa-archive::before {
  content: "\f187"; }
.fa-grapes::before {
  content: "\e306"; }
.fa-person-drowning::before {
  content: "\e545"; }
.fa-dial-max::before {
  content: "\e15e"; }
.fa-circle-m::before {
  content: "\e115"; }
.fa-calendar-image::before {
  content: "\e0d4"; }
.fa-circle-caret-down::before {
  content: "\f32d"; }
.fa-caret-circle-down::before {
  content: "\f32d"; }
.fa-arrow-down-9-1::before {
  content: "\f886"; }
.fa-sort-numeric-desc::before {
  content: "\f886"; }
.fa-sort-numeric-down-alt::before {
  content: "\f886"; }
.fa-face-grin-tongue-squint::before {
  content: "\f58a"; }
.fa-grin-tongue-squint::before {
  content: "\f58a"; }
.fa-shish-kebab::before {
  content: "\f821"; }
.fa-spray-can::before {
  content: "\f5bd"; }
.fa-alarm-snooze::before {
  content: "\f845"; }
.fa-scarecrow::before {
  content: "\f70d"; }
.fa-truck-monster::before {
  content: "\f63b"; }
.fa-gift-card::before {
  content: "\f663"; }
.fa-w::before {
  content: "\57"; }
.fa-code-pull-request-draft::before {
  content: "\e3fa"; }
.fa-square-b::before {
  content: "\e264"; }
.fa-elephant::before {
  content: "\f6da"; }
.fa-earth-africa::before {
  content: "\f57c"; }
.fa-globe-africa::before {
  content: "\f57c"; }
.fa-rainbow::before {
  content: "\f75b"; }
.fa-circle-notch::before {
  content: "\f1ce"; }
.fa-tablet-screen-button::before {
  content: "\f3fa"; }
.fa-tablet-alt::before {
  content: "\f3fa"; }
.fa-paw::before {
  content: "\f1b0"; }
.fa-message-question::before {
  content: "\e1e3"; }
.fa-cloud::before {
  content: "\f0c2"; }
.fa-trowel-bricks::before {
  content: "\e58a"; }
.fa-square-3::before {
  content: "\e258"; }
.fa-face-flushed::before {
  content: "\f579"; }
.fa-flushed::before {
  content: "\f579"; }
.fa-hospital-user::before {
  content: "\f80d"; }
.fa-microwave::before {
  content: "\e01b"; }
.fa-tent-arrow-left-right::before {
  content: "\e57f"; }
.fa-cart-circle-arrow-up::before {
  content: "\e3f0"; }
.fa-trash-clock::before {
  content: "\e2b0"; }
.fa-gavel::before {
  content: "\f0e3"; }
.fa-legal::before {
  content: "\f0e3"; }
.fa-sprinkler-ceiling::before {
  content: "\e44c"; }
.fa-browsers::before {
  content: "\e0cb"; }
.fa-trillium::before {
  content: "\e588"; }
.fa-music-slash::before {
  content: "\f8d1"; }
.fa-truck-ramp::before {
  content: "\f4e0"; }
.fa-binoculars::before {
  content: "\f1e5"; }
.fa-microphone-slash::before {
  content: "\f131"; }
.fa-box-tissue::before {
  content: "\e05b"; }
.fa-circle-c::before {
  content: "\e101"; }
.fa-star-christmas::before {
  content: "\f7d4"; }
.fa-chart-bullet::before {
  content: "\e0e1"; }
.fa-motorcycle::before {
  content: "\f21c"; }
.fa-tree-christmas::before {
  content: "\f7db"; }
.fa-tire-flat::before {
  content: "\f632"; }
.fa-sunglasses::before {
  content: "\f892"; }
.fa-badge::before {
  content: "\f335"; }
.fa-message-pen::before {
  content: "\f4a4"; }
.fa-comment-alt-edit::before {
  content: "\f4a4"; }
.fa-message-edit::before {
  content: "\f4a4"; }
.fa-bell-concierge::before {
  content: "\f562"; }
.fa-concierge-bell::before {
  content: "\f562"; }
.fa-pen-ruler::before {
  content: "\f5ae"; }
.fa-pencil-ruler::before {
  content: "\f5ae"; }
.fa-chess-rook-piece::before {
  content: "\f448"; }
.fa-chess-rook-alt::before {
  content: "\f448"; }
.fa-square-root::before {
  content: "\f697"; }
.fa-album-collection-circle-plus::before {
  content: "\e48e"; }
.fa-people-arrows::before {
  content: "\e068"; }
.fa-people-arrows-left-right::before {
  content: "\e068"; }
.fa-face-angry-horns::before {
  content: "\e368"; }
.fa-mars-and-venus-burst::before {
  content: "\e523"; }
.fa-tombstone::before {
  content: "\f720"; }
.fa-square-caret-right::before {
  content: "\f152"; }
.fa-caret-square-right::before {
  content: "\f152"; }
.fa-scissors::before {
  content: "\f0c4"; }
.fa-cut::before {
  content: "\f0c4"; }
.fa-list-music::before {
  content: "\f8c9"; }
.fa-sun-plant-wilt::before {
  content: "\e57a"; }
.fa-toilets-portable::before {
  content: "\e584"; }
.fa-hockey-puck::before {
  content: "\f453"; }
.fa-mustache::before {
  content: "\e5bc"; }
.fa-hyphen::before {
  content: "\2d"; }
.fa-table::before {
  content: "\f0ce"; }
.fa-user-chef::before {
  content: "\e3d2"; }
.fa-message-image::before {
  content: "\e1e0"; }
.fa-comment-alt-image::before {
  content: "\e1e0"; }
.fa-users-medical::before {
  content: "\f830"; }
.fa-sensor-triangle-exclamation::before {
  content: "\e029"; }
.fa-sensor-alert::before {
  content: "\e029"; }
.fa-magnifying-glass-arrow-right::before {
  content: "\e521"; }
.fa-tachograph-digital::before {
  content: "\f566"; }
.fa-digital-tachograph::before {
  content: "\f566"; }
.fa-face-mask::before {
  content: "\e37f"; }
.fa-pickleball::before {
  content: "\e435"; }
.fa-star-sharp-half::before {
  content: "\e28c"; }
.fa-users-slash::before {
  content: "\e073"; }
.fa-clover::before {
  content: "\e139"; }
.fa-meat::before {
  content: "\f814"; }
.fa-reply::before {
  content: "\f3e5"; }
.fa-mail-reply::before {
  content: "\f3e5"; }
.fa-star-and-crescent::before {
  content: "\f699"; }
.fa-empty-set::before {
  content: "\f656"; }
.fa-house-fire::before {
  content: "\e50c"; }
.fa-square-minus::before {
  content: "\f146"; }
.fa-minus-square::before {
  content: "\f146"; }
.fa-helicopter::before {
  content: "\f533"; }
.fa-bird::before {
  content: "\e469"; }
.fa-compass::before {
  content: "\f14e"; }
.fa-square-caret-down::before {
  content: "\f150"; }
.fa-caret-square-down::before {
  content: "\f150"; }
.fa-heart-half-stroke::before {
  content: "\e1ac"; }
.fa-heart-half-alt::before {
  content: "\e1ac"; }
.fa-file-circle-question::before {
  content: "\e4ef"; }
.fa-laptop-code::before {
  content: "\f5fc"; }
.fa-joystick::before {
  content: "\f8c5"; }
.fa-grill-fire::before {
  content: "\e5a4"; }
.fa-rectangle-vertical-history::before {
  content: "\e237"; }
.fa-swatchbook::before {
  content: "\f5c3"; }
.fa-prescription-bottle::before {
  content: "\f485"; }
.fa-bars::before {
  content: "\f0c9"; }
.fa-navicon::before {
  content: "\f0c9"; }
.fa-keyboard-left::before {
  content: "\e1c3"; }
.fa-people-group::before {
  content: "\e533"; }
.fa-hourglass-end::before {
  content: "\f253"; }
.fa-hourglass-3::before {
  content: "\f253"; }
.fa-heart-crack::before {
  content: "\f7a9"; }
.fa-heart-broken::before {
  content: "\f7a9"; }
.fa-face-beam-hand-over-mouth::before {
  content: "\e47c"; }
.fa-droplet-percent::before {
  content: "\f750"; }
.fa-humidity::before {
  content: "\f750"; }
.fa-square-up-right::before {
  content: "\f360"; }
.fa-external-link-square-alt::before {
  content: "\f360"; }
.fa-face-kiss-beam::before {
  content: "\f597"; }
.fa-kiss-beam::before {
  content: "\f597"; }
.fa-corn::before {
  content: "\f6c7"; }
.fa-roller-coaster::before {
  content: "\e324"; }
.fa-photo-film-music::before {
  content: "\e228"; }
.fa-radar::before {
  content: "\e024"; }
.fa-sickle::before {
  content: "\f822"; }
.fa-film::before {
  content: "\f008"; }
.fa-coconut::before {
  content: "\e2f6"; }
.fa-ruler-horizontal::before {
  content: "\f547"; }
.fa-shield-cross::before {
  content: "\f712"; }
.fa-cassette-tape::before {
  content: "\f8ab"; }
.fa-square-terminal::before {
  content: "\e32a"; }
.fa-people-robbery::before {
  content: "\e536"; }
.fa-lightbulb::before {
  content: "\f0eb"; }
.fa-caret-left::before {
  content: "\f0d9"; }
.fa-comment-middle::before {
  content: "\e149"; }
.fa-trash-can-list::before {
  content: "\e2ab"; }
.fa-block::before {
  content: "\e46a"; }
.fa-circle-exclamation::before {
  content: "\f06a"; }
.fa-exclamation-circle::before {
  content: "\f06a"; }
.fa-school-circle-xmark::before {
  content: "\e56d"; }
.fa-arrow-right-from-bracket::before {
  content: "\f08b"; }
.fa-sign-out::before {
  content: "\f08b"; }
.fa-face-frown-slight::before {
  content: "\e376"; }
.fa-circle-chevron-down::before {
  content: "\f13a"; }
.fa-chevron-circle-down::before {
  content: "\f13a"; }
.fa-sidebar-flip::before {
  content: "\e24f"; }
.fa-unlock-keyhole::before {
  content: "\f13e"; }
.fa-unlock-alt::before {
  content: "\f13e"; }
.fa-temperature-list::before {
  content: "\e299"; }
.fa-cloud-showers-heavy::before {
  content: "\f740"; }
.fa-headphones-simple::before {
  content: "\f58f"; }
.fa-headphones-alt::before {
  content: "\f58f"; }
.fa-sitemap::before {
  content: "\f0e8"; }
.fa-pipe-section::before {
  content: "\e438"; }
.fa-space-station-moon-construction::before {
  content: "\e034"; }
.fa-space-station-moon-alt::before {
  content: "\e034"; }
.fa-circle-dollar-to-slot::before {
  content: "\f4b9"; }
.fa-donate::before {
  content: "\f4b9"; }
.fa-memory::before {
  content: "\f538"; }
.fa-face-sleeping::before {
  content: "\e38d"; }
.fa-road-spikes::before {
  content: "\e568"; }
.fa-fire-burner::before {
  content: "\e4f1"; }
.fa-squirrel::before {
  content: "\f71a"; }
.fa-arrow-up-to-line::before {
  content: "\f341"; }
.fa-arrow-to-top::before {
  content: "\f341"; }
.fa-flag::before {
  content: "\f024"; }
.fa-face-cowboy-hat::before {
  content: "\e36e"; }
.fa-hanukiah::before {
  content: "\f6e6"; }
.fa-chart-scatter-3d::before {
  content: "\e0e8"; }
.fa-square-code::before {
  content: "\e267"; }
.fa-feather::before {
  content: "\f52d"; }
.fa-volume-low::before {
  content: "\f027"; }
.fa-volume-down::before {
  content: "\f027"; }
.fa-xmark-to-slot::before {
  content: "\f771"; }
.fa-times-to-slot::before {
  content: "\f771"; }
.fa-vote-nay::before {
  content: "\f771"; }
.fa-box-taped::before {
  content: "\f49a"; }
.fa-box-alt::before {
  content: "\f49a"; }
.fa-comment-slash::before {
  content: "\f4b3"; }
.fa-swords::before {
  content: "\f71d"; }
.fa-cloud-sun-rain::before {
  content: "\f743"; }
.fa-album::before {
  content: "\f89f"; }
.fa-circle-n::before {
  content: "\e118"; }
.fa-compress::before {
  content: "\f066"; }
.fa-wheat-awn::before {
  content: "\e2cd"; }
.fa-wheat-alt::before {
  content: "\e2cd"; }
.fa-ankh::before {
  content: "\f644"; }
.fa-hands-holding-child::before {
  content: "\e4fa"; }
.fa-asterisk::before {
  content: "\2a"; }
.fa-key-skeleton-left-right::before {
  content: "\e3b4"; }
.fa-comment-lines::before {
  content: "\f4b0"; }
.fa-luchador-mask::before {
  content: "\f455"; }
.fa-luchador::before {
  content: "\f455"; }
.fa-mask-luchador::before {
  content: "\f455"; }
.fa-square-check::before {
  content: "\f14a"; }
.fa-check-square::before {
  content: "\f14a"; }
.fa-shredder::before {
  content: "\f68a"; }
.fa-book-open-cover::before {
  content: "\e0c0"; }
.fa-book-open-alt::before {
  content: "\e0c0"; }
.fa-sandwich::before {
  content: "\f81f"; }
.fa-peseta-sign::before {
  content: "\e221"; }
.fa-square-parking-slash::before {
  content: "\f617"; }
.fa-parking-slash::before {
  content: "\f617"; }
.fa-train-tunnel::before {
  content: "\e454"; }
.fa-heading::before {
  content: "\f1dc"; }
.fa-header::before {
  content: "\f1dc"; }
.fa-ghost::before {
  content: "\f6e2"; }
.fa-face-anguished::before {
  content: "\e369"; }
.fa-hockey-sticks::before {
  content: "\f454"; }
.fa-abacus::before {
  content: "\f640"; }
.fa-film-simple::before {
  content: "\f3a0"; }
.fa-film-alt::before {
  content: "\f3a0"; }
.fa-list::before {
  content: "\f03a"; }
.fa-list-squares::before {
  content: "\f03a"; }
.fa-tree-palm::before {
  content: "\f82b"; }
.fa-square-phone-flip::before {
  content: "\f87b"; }
.fa-phone-square-alt::before {
  content: "\f87b"; }
.fa-cart-plus::before {
  content: "\f217"; }
.fa-gamepad::before {
  content: "\f11b"; }
.fa-border-center-v::before {
  content: "\f89d"; }
.fa-circle-dot::before {
  content: "\f192"; }
.fa-dot-circle::before {
  content: "\f192"; }
.fa-clipboard-medical::before {
  content: "\e133"; }
.fa-face-dizzy::before {
  content: "\f567"; }
.fa-dizzy::before {
  content: "\f567"; }
.fa-egg::before {
  content: "\f7fb"; }
.fa-up-to-line::before {
  content: "\f34d"; }
.fa-arrow-alt-to-top::before {
  content: "\f34d"; }
.fa-house-medical-circle-xmark::before {
  content: "\e513"; }
.fa-watch-fitness::before {
  content: "\f63e"; }
.fa-clock-nine-thirty::before {
  content: "\e34d"; }
.fa-campground::before {
  content: "\f6bb"; }
.fa-folder-plus::before {
  content: "\f65e"; }
.fa-jug::before {
  content: "\f8c6"; }
.fa-futbol::before {
  content: "\f1e3"; }
.fa-futbol-ball::before {
  content: "\f1e3"; }
.fa-soccer-ball::before {
  content: "\f1e3"; }
.fa-snow-blowing::before {
  content: "\f761"; }
.fa-paintbrush::before {
  content: "\f1fc"; }
.fa-paint-brush::before {
  content: "\f1fc"; }
.fa-lock::before {
  content: "\f023"; }
.fa-arrow-down-from-line::before {
  content: "\f345"; }
.fa-arrow-from-top::before {
  content: "\f345"; }
.fa-gas-pump::before {
  content: "\f52f"; }
.fa-signal-bars-slash::before {
  content: "\f694"; }
.fa-signal-alt-slash::before {
  content: "\f694"; }
.fa-monkey::before {
  content: "\f6fb"; }
.fa-rectangle-pro::before {
  content: "\e235"; }
.fa-pro::before {
  content: "\e235"; }
.fa-house-night::before {
  content: "\e010"; }
.fa-hot-tub-person::before {
  content: "\f593"; }
.fa-hot-tub::before {
  content: "\f593"; }
.fa-blanket::before {
  content: "\f498"; }
.fa-map-location::before {
  content: "\f59f"; }
.fa-map-marked::before {
  content: "\f59f"; }
.fa-house-flood-water::before {
  content: "\e50e"; }
.fa-comments-question-check::before {
  content: "\e14f"; }
.fa-tree::before {
  content: "\f1bb"; }
.fa-arrows-cross::before {
  content: "\e0a2"; }
.fa-backpack::before {
  content: "\f5d4"; }
.fa-square-small::before {
  content: "\e27e"; }
.fa-folder-arrow-up::before {
  content: "\e054"; }
.fa-folder-upload::before {
  content: "\e054"; }
.fa-bridge-lock::before {
  content: "\e4cc"; }
.fa-crosshairs-simple::before {
  content: "\e59f"; }
.fa-sack-dollar::before {
  content: "\f81d"; }
.fa-pen-to-square::before {
  content: "\f044"; }
.fa-edit::before {
  content: "\f044"; }
.fa-square-sliders::before {
  content: "\f3f0"; }
.fa-sliders-h-square::before {
  content: "\f3f0"; }
.fa-car-side::before {
  content: "\f5e4"; }
.fa-message-middle-top::before {
  content: "\e1e2"; }
.fa-comment-middle-top-alt::before {
  content: "\e1e2"; }
.fa-lightbulb-on::before {
  content: "\f672"; }
.fa-knife::before {
  content: "\f2e4"; }
.fa-utensil-knife::before {
  content: "\f2e4"; }
.fa-share-nodes::before {
  content: "\f1e0"; }
.fa-share-alt::before {
  content: "\f1e0"; }
.fa-wave-sine::before {
  content: "\f899"; }
.fa-heart-circle-minus::before {
  content: "\e4ff"; }
.fa-circle-w::before {
  content: "\e12c"; }
.fa-circle-calendar::before {
  content: "\e102"; }
.fa-calendar-circle::before {
  content: "\e102"; }
.fa-hourglass-half::before {
  content: "\f252"; }
.fa-hourglass-2::before {
  content: "\f252"; }
.fa-microscope::before {
  content: "\f610"; }
.fa-sunset::before {
  content: "\f767"; }
.fa-sink::before {
  content: "\e06d"; }
.fa-calendar-exclamation::before {
  content: "\f334"; }
.fa-truck-container-empty::before {
  content: "\e2b5"; }
.fa-hand-heart::before {
  content: "\f4bc"; }
.fa-bag-shopping::before {
  content: "\f290"; }
.fa-shopping-bag::before {
  content: "\f290"; }
.fa-arrow-down-z-a::before {
  content: "\f881"; }
.fa-sort-alpha-desc::before {
  content: "\f881"; }
.fa-sort-alpha-down-alt::before {
  content: "\f881"; }
.fa-mitten::before {
  content: "\f7b5"; }
.fa-reply-clock::before {
  content: "\e239"; }
.fa-reply-time::before {
  content: "\e239"; }
.fa-person-rays::before {
  content: "\e54d"; }
.fa-right::before {
  content: "\f356"; }
.fa-arrow-alt-right::before {
  content: "\f356"; }
.fa-circle-f::before {
  content: "\e10e"; }
.fa-users::before {
  content: "\f0c0"; }
.fa-face-pleading::before {
  content: "\e386"; }
.fa-eye-slash::before {
  content: "\f070"; }
.fa-flask-vial::before {
  content: "\e4f3"; }
.fa-police-box::before {
  content: "\e021"; }
.fa-cucumber::before {
  content: "\e401"; }
.fa-head-side-brain::before {
  content: "\f808"; }
.fa-hand::before {
  content: "\f256"; }
.fa-hand-paper::before {
  content: "\f256"; }
.fa-person-biking-mountain::before {
  content: "\f84b"; }
.fa-biking-mountain::before {
  content: "\f84b"; }
.fa-utensils-slash::before {
  content: "\e464"; }
.fa-print-magnifying-glass::before {
  content: "\f81a"; }
.fa-print-search::before {
  content: "\f81a"; }
.fa-folder-bookmark::before {
  content: "\e186"; }
.fa-om::before {
  content: "\f679"; }
.fa-pi::before {
  content: "\f67e"; }
.fa-flask-round-potion::before {
  content: "\f6e1"; }
.fa-flask-potion::before {
  content: "\f6e1"; }
.fa-face-shush::before {
  content: "\e38c"; }
.fa-worm::before {
  content: "\e599"; }
.fa-house-circle-xmark::before {
  content: "\e50b"; }
.fa-plug::before {
  content: "\f1e6"; }
.fa-calendar-circle-exclamation::before {
  content: "\e46e"; }
.fa-square-i::before {
  content: "\e272"; }
.fa-chevron-up::before {
  content: "\f077"; }
.fa-face-saluting::before {
  content: "\e484"; }
.fa-gauge-simple-low::before {
  content: "\f62c"; }
.fa-tachometer-slow::before {
  content: "\f62c"; }
.fa-face-persevering::before {
  content: "\e385"; }
.fa-circle-camera::before {
  content: "\e103"; }
.fa-camera-circle::before {
  content: "\e103"; }
.fa-hand-spock::before {
  content: "\f259"; }
.fa-spider-web::before {
  content: "\f719"; }
.fa-circle-microphone::before {
  content: "\e116"; }
.fa-microphone-circle::before {
  content: "\e116"; }
.fa-book-arrow-up::before {
  content: "\e0ba"; }
.fa-popsicle::before {
  content: "\e43e"; }
.fa-command::before {
  content: "\e142"; }
.fa-blinds::before {
  content: "\f8fb"; }
.fa-stopwatch::before {
  content: "\f2f2"; }
.fa-saxophone::before {
  content: "\f8dc"; }
.fa-square-2::before {
  content: "\e257"; }
.fa-field-hockey-stick-ball::before {
  content: "\f44c"; }
.fa-field-hockey::before {
  content: "\f44c"; }
.fa-arrow-up-square-triangle::before {
  content: "\f88b"; }
.fa-sort-shapes-up-alt::before {
  content: "\f88b"; }
.fa-face-scream::before {
  content: "\e38b"; }
.fa-square-m::before {
  content: "\e276"; }
.fa-camera-web::before {
  content: "\f832"; }
.fa-webcam::before {
  content: "\f832"; }
.fa-comment-arrow-down::before {
  content: "\e143"; }
.fa-lightbulb-cfl::before {
  content: "\e5a6"; }
.fa-window-frame-open::before {
  content: "\e050"; }
.fa-face-kiss::before {
  content: "\f596"; }
.fa-kiss::before {
  content: "\f596"; }
.fa-bridge-circle-xmark::before {
  content: "\e4cb"; }
.fa-period::before {
  content: "\2e"; }
.fa-face-grin-tongue::before {
  content: "\f589"; }
.fa-grin-tongue::before {
  content: "\f589"; }
.fa-up-to-dotted-line::before {
  content: "\e457"; }
.fa-thought-bubble::before {
  content: "\e32e"; }
.fa-raygun::before {
  content: "\e025"; }
.fa-flute::before {
  content: "\f8b9"; }
.fa-acorn::before {
  content: "\f6ae"; }
.fa-video-arrow-up-right::before {
  content: "\e2c9"; }
.fa-grate-droplet::before {
  content: "\e194"; }
.fa-seal-exclamation::before {
  content: "\e242"; }
.fa-chess-bishop::before {
  content: "\f43a"; }
.fa-message-sms::before {
  content: "\e1e5"; }
.fa-coffee-beans::before {
  content: "\e13f"; }
.fa-hat-witch::before {
  content: "\f6e7"; }
.fa-face-grin-wink::before {
  content: "\f58c"; }
.fa-grin-wink::before {
  content: "\f58c"; }
.fa-clock-three-thirty::before {
  content: "\e357"; }
.fa-ear-deaf::before {
  content: "\f2a4"; }
.fa-deaf::before {
  content: "\f2a4"; }
.fa-deafness::before {
  content: "\f2a4"; }
.fa-hard-of-hearing::before {
  content: "\f2a4"; }
.fa-alarm-clock::before {
  content: "\f34e"; }
.fa-eclipse::before {
  content: "\f749"; }
.fa-face-relieved::before {
  content: "\e389"; }
.fa-road-circle-check::before {
  content: "\e564"; }
.fa-dice-five::before {
  content: "\f523"; }
.fa-octagon-minus::before {
  content: "\f308"; }
.fa-minus-octagon::before {
  content: "\f308"; }
.fa-square-rss::before {
  content: "\f143"; }
.fa-rss-square::before {
  content: "\f143"; }
.fa-face-zany::before {
  content: "\e3a4"; }
.fa-tricycle::before {
  content: "\e5c3"; }
.fa-land-mine-on::before {
  content: "\e51b"; }
.fa-square-arrow-up-left::before {
  content: "\e263"; }
.fa-i-cursor::before {
  content: "\f246"; }
.fa-salt-shaker::before {
  content: "\e446"; }
.fa-stamp::before {
  content: "\f5bf"; }
.fa-file-plus::before {
  content: "\f319"; }
.fa-draw-square::before {
  content: "\f5ef"; }
.fa-toilet-paper-under-slash::before {
  content: "\e2a1"; }
.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1"; }
.fa-stairs::before {
  content: "\e289"; }
.fa-drone-front::before {
  content: "\f860"; }
.fa-drone-alt::before {
  content: "\f860"; }
.fa-glass-empty::before {
  content: "\e191"; }
.fa-dial-high::before {
  content: "\e15c"; }
.fa-user-helmet-safety::before {
  content: "\f82c"; }
.fa-user-construction::before {
  content: "\f82c"; }
.fa-user-hard-hat::before {
  content: "\f82c"; }
.fa-i::before {
  content: "\49"; }
.fa-hryvnia-sign::before {
  content: "\f6f2"; }
.fa-hryvnia::before {
  content: "\f6f2"; }
.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092"; }
.fa-pills::before {
  content: "\f484"; }
.fa-face-grin-wide::before {
  content: "\f581"; }
.fa-grin-alt::before {
  content: "\f581"; }
.fa-tooth::before {
  content: "\f5c9"; }
.fa-basketball-hoop::before {
  content: "\f435"; }
.fa-objects-align-bottom::before {
  content: "\e3bb"; }
.fa-v::before {
  content: "\56"; }
.fa-sparkles::before {
  content: "\f890"; }
.fa-squid::before {
  content: "\e450"; }
.fa-leafy-green::before {
  content: "\e41d"; }
.fa-circle-arrow-up-right::before {
  content: "\e0fc"; }
.fa-calendars::before {
  content: "\e0d7"; }
.fa-bangladeshi-taka-sign::before {
  content: "\e2e6"; }
.fa-bicycle::before {
  content: "\f206"; }
.fa-hammer-war::before {
  content: "\f6e4"; }
.fa-circle-d::before {
  content: "\e104"; }
.fa-spider-black-widow::before {
  content: "\f718"; }
.fa-staff-snake::before {
  content: "\e579"; }
.fa-rod-asclepius::before {
  content: "\e579"; }
.fa-rod-snake::before {
  content: "\e579"; }
.fa-staff-aesculapius::before {
  content: "\e579"; }
.fa-pear::before {
  content: "\e20c"; }
.fa-head-side-cough-slash::before {
  content: "\e062"; }
.fa-triangle::before {
  content: "\f2ec"; }
.fa-apartment::before {
  content: "\e468"; }
.fa-truck-medical::before {
  content: "\f0f9"; }
.fa-ambulance::before {
  content: "\f0f9"; }
.fa-pepper::before {
  content: "\e432"; }
.fa-piano::before {
  content: "\f8d4"; }
.fa-gun-squirt::before {
  content: "\e19d"; }
.fa-wheat-awn-circle-exclamation::before {
  content: "\e598"; }
.fa-snowman::before {
  content: "\f7d0"; }
.fa-user-alien::before {
  content: "\e04a"; }
.fa-shield-check::before {
  content: "\f2f7"; }
.fa-mortar-pestle::before {
  content: "\f5a7"; }
.fa-road-barrier::before {
  content: "\e562"; }
.fa-chart-candlestick::before {
  content: "\e0e2"; }
.fa-briefcase-blank::before {
  content: "\e0c8"; }
.fa-school::before {
  content: "\f549"; }
.fa-igloo::before {
  content: "\f7ae"; }
.fa-bracket-round::before {
  content: "\28"; }
.fa-parenthesis::before {
  content: "\28"; }
.fa-joint::before {
  content: "\f595"; }
.fa-horse-saddle::before {
  content: "\f8c3"; }
.fa-mug-marshmallows::before {
  content: "\f7b7"; }
.fa-filters::before {
  content: "\e17e"; }
.fa-bell-on::before {
  content: "\f8fa"; }
.fa-angle-right::before {
  content: "\f105"; }
.fa-dial-med::before {
  content: "\e15f"; }
.fa-horse::before {
  content: "\f6f0"; }
.fa-q::before {
  content: "\51"; }
.fa-monitor-waveform::before {
  content: "\f611"; }
.fa-monitor-heart-rate::before {
  content: "\f611"; }
.fa-link-simple::before {
  content: "\e1cd"; }
.fa-whistle::before {
  content: "\f460"; }
.fa-g::before {
  content: "\47"; }
.fa-wine-glass-crack::before {
  content: "\f4bb"; }
.fa-fragile::before {
  content: "\f4bb"; }
.fa-slot-machine::before {
  content: "\e3ce"; }
.fa-notes-medical::before {
  content: "\f481"; }
.fa-car-wash::before {
  content: "\f5e6"; }
.fa-escalator::before {
  content: "\e171"; }
.fa-comment-image::before {
  content: "\e148"; }
.fa-temperature-half::before {
  content: "\f2c9"; }
.fa-temperature-2::before {
  content: "\f2c9"; }
.fa-thermometer-2::before {
  content: "\f2c9"; }
.fa-thermometer-half::before {
  content: "\f2c9"; }
.fa-dong-sign::before {
  content: "\e169"; }
.fa-donut::before {
  content: "\e406"; }
.fa-doughnut::before {
  content: "\e406"; }
.fa-capsules::before {
  content: "\f46b"; }
.fa-poo-storm::before {
  content: "\f75a"; }
.fa-poo-bolt::before {
  content: "\f75a"; }
.fa-tally-1::before {
  content: "\e294"; }
.fa-face-frown-open::before {
  content: "\f57a"; }
.fa-frown-open::before {
  content: "\f57a"; }
.fa-square-dashed::before {
  content: "\e269"; }
.fa-square-j::before {
  content: "\e273"; }
.fa-hand-point-up::before {
  content: "\f0a6"; }
.fa-money-bill::before {
  content: "\f0d6"; }
.fa-arrow-up-big-small::before {
  content: "\f88e"; }
.fa-sort-size-up::before {
  content: "\f88e"; }
.fa-barcode-read::before {
  content: "\f464"; }
.fa-baguette::before {
  content: "\e3d8"; }
.fa-bowl-soft-serve::before {
  content: "\e46b"; }
.fa-face-holding-back-tears::before {
  content: "\e482"; }
.fa-square-up::before {
  content: "\f353"; }
.fa-arrow-alt-square-up::before {
  content: "\f353"; }
.fa-train-subway-tunnel::before {
  content: "\e2a3"; }
.fa-subway-tunnel::before {
  content: "\e2a3"; }
.fa-square-exclamation::before {
  content: "\f321"; }
.fa-exclamation-square::before {
  content: "\f321"; }
.fa-semicolon::before {
  content: "\3b"; }
.fa-bookmark::before {
  content: "\f02e"; }
.fa-fan-table::before {
  content: "\e004"; }
.fa-align-justify::before {
  content: "\f039"; }
.fa-battery-low::before {
  content: "\e0b1"; }
.fa-battery-1::before {
  content: "\e0b1"; }
.fa-credit-card-front::before {
  content: "\f38a"; }
.fa-brain-arrow-curved-right::before {
  content: "\f677"; }
.fa-mind-share::before {
  content: "\f677"; }
.fa-umbrella-beach::before {
  content: "\f5ca"; }
.fa-helmet-un::before {
  content: "\e503"; }
.fa-location-smile::before {
  content: "\f60d"; }
.fa-map-marker-smile::before {
  content: "\f60d"; }
.fa-arrow-left-to-line::before {
  content: "\f33e"; }
.fa-arrow-to-left::before {
  content: "\f33e"; }
.fa-bullseye::before {
  content: "\f140"; }
.fa-sushi::before {
  content: "\e48a"; }
.fa-nigiri::before {
  content: "\e48a"; }
.fa-message-captions::before {
  content: "\e1de"; }
.fa-comment-alt-captions::before {
  content: "\e1de"; }
.fa-trash-list::before {
  content: "\e2b1"; }
.fa-bacon::before {
  content: "\f7e5"; }
.fa-option::before {
  content: "\e318"; }
.fa-hand-point-down::before {
  content: "\f0a7"; }
.fa-arrow-up-from-bracket::before {
  content: "\e09a"; }
.fa-trash-plus::before {
  content: "\e2b2"; }
.fa-objects-align-top::before {
  content: "\e3c0"; }
.fa-folder::before {
  content: "\f07b"; }
.fa-folder-blank::before {
  content: "\f07b"; }
.fa-face-anxious-sweat::before {
  content: "\e36a"; }
.fa-credit-card-blank::before {
  content: "\f389"; }
.fa-file-waveform::before {
  content: "\f478"; }
.fa-file-medical-alt::before {
  content: "\f478"; }
.fa-microchip-ai::before {
  content: "\e1ec"; }
.fa-mug::before {
  content: "\f874"; }
.fa-plane-up-slash::before {
  content: "\e22e"; }
.fa-radiation::before {
  content: "\f7b9"; }
.fa-pen-circle::before {
  content: "\e20e"; }
.fa-chart-simple::before {
  content: "\e473"; }
.fa-crutches::before {
  content: "\f7f8"; }
.fa-circle-parking::before {
  content: "\f615"; }
.fa-parking-circle::before {
  content: "\f615"; }
.fa-mars-stroke::before {
  content: "\f229"; }
.fa-leaf-oak::before {
  content: "\f6f7"; }
.fa-square-bolt::before {
  content: "\e265"; }
.fa-vial::before {
  content: "\f492"; }
.fa-gauge::before {
  content: "\f624"; }
.fa-dashboard::before {
  content: "\f624"; }
.fa-gauge-med::before {
  content: "\f624"; }
.fa-tachometer-alt-average::before {
  content: "\f624"; }
.fa-wand-magic-sparkles::before {
  content: "\e2ca"; }
.fa-magic-wand-sparkles::before {
  content: "\e2ca"; }
.fa-lambda::before {
  content: "\f66e"; }
.fa-e::before {
  content: "\45"; }
.fa-pizza::before {
  content: "\f817"; }
.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea"; }
.fa-h3::before {
  content: "\f315"; }
.fa-pen-clip::before {
  content: "\f305"; }
.fa-pen-alt::before {
  content: "\f305"; }
.fa-bridge-circle-exclamation::before {
  content: "\e4ca"; }
.fa-badge-percent::before {
  content: "\f646"; }
.fa-user::before {
  content: "\f007"; }
.fa-sensor::before {
  content: "\e028"; }
.fa-comma::before {
  content: "\2c"; }
.fa-school-circle-check::before {
  content: "\e56b"; }
.fa-toilet-paper-under::before {
  content: "\e2a0"; }
.fa-toilet-paper-reverse::before {
  content: "\e2a0"; }
.fa-light-emergency::before {
  content: "\e41f"; }
.fa-arrow-down-to-arc::before {
  content: "\e4ae"; }
.fa-dumpster::before {
  content: "\f793"; }
.fa-van-shuttle::before {
  content: "\f5b6"; }
.fa-shuttle-van::before {
  content: "\f5b6"; }
.fa-building-user::before {
  content: "\e4da"; }
.fa-light-switch::before {
  content: "\e017"; }
.fa-square-caret-left::before {
  content: "\f191"; }
.fa-caret-square-left::before {
  content: "\f191"; }
.fa-highlighter::before {
  content: "\f591"; }
.fa-wave-pulse::before {
  content: "\f5f8"; }
.fa-heart-rate::before {
  content: "\f5f8"; }
.fa-key::before {
  content: "\f084"; }
.fa-hat-santa::before {
  content: "\f7a7"; }
.fa-tamale::before {
  content: "\e451"; }
.fa-box-check::before {
  content: "\f467"; }
.fa-bullhorn::before {
  content: "\f0a1"; }
.fa-steak::before {
  content: "\f824"; }
.fa-location-crosshairs-slash::before {
  content: "\f603"; }
.fa-location-slash::before {
  content: "\f603"; }
.fa-person-dolly::before {
  content: "\f4d0"; }
.fa-globe::before {
  content: "\f0ac"; }
.fa-synagogue::before {
  content: "\f69b"; }
.fa-file-chart-column::before {
  content: "\f659"; }
.fa-file-chart-line::before {
  content: "\f659"; }
.fa-person-half-dress::before {
  content: "\e548"; }
.fa-folder-image::before {
  content: "\e18a"; }
.fa-calendar-pen::before {
  content: "\f333"; }
.fa-calendar-edit::before {
  content: "\f333"; }
.fa-road-bridge::before {
  content: "\e563"; }
.fa-face-smile-tear::before {
  content: "\e393"; }
.fa-message-plus::before {
  content: "\f4a8"; }
.fa-comment-alt-plus::before {
  content: "\f4a8"; }
.fa-location-arrow::before {
  content: "\f124"; }
.fa-c::before {
  content: "\43"; }
.fa-tablet-button::before {
  content: "\f10a"; }
.fa-rectangle-history-circle-user::before {
  content: "\e4a4"; }
.fa-building-lock::before {
  content: "\e4d6"; }
.fa-chart-line-up::before {
  content: "\e0e5"; }
.fa-mailbox::before {
  content: "\f813"; }
.fa-truck-bolt::before {
  content: "\e3d0"; }
.fa-pizza-slice::before {
  content: "\f818"; }
.fa-money-bill-wave::before {
  content: "\f53a"; }
.fa-chart-area::before {
  content: "\f1fe"; }
.fa-area-chart::before {
  content: "\f1fe"; }
.fa-house-flag::before {
  content: "\e50d"; }
.fa-person-circle-minus::before {
  content: "\e540"; }
.fa-scalpel::before {
  content: "\f61d"; }
.fa-ban::before {
  content: "\f05e"; }
.fa-cancel::before {
  content: "\f05e"; }
.fa-bell-exclamation::before {
  content: "\f848"; }
.fa-circle-bookmark::before {
  content: "\e100"; }
.fa-bookmark-circle::before {
  content: "\e100"; }
.fa-egg-fried::before {
  content: "\f7fc"; }
.fa-face-weary::before {
  content: "\e3a1"; }
.fa-uniform-martial-arts::before {
  content: "\e3d1"; }
.fa-camera-rotate::before {
  content: "\e0d8"; }
.fa-sun-dust::before {
  content: "\f764"; }
.fa-comment-text::before {
  content: "\e14d"; }
.fa-spray-can-sparkles::before {
  content: "\f5d0"; }
.fa-air-freshener::before {
  content: "\f5d0"; }
.fa-signal-bars::before {
  content: "\f690"; }
.fa-signal-alt::before {
  content: "\f690"; }
.fa-signal-alt-4::before {
  content: "\f690"; }
.fa-signal-bars-strong::before {
  content: "\f690"; }
.fa-diamond-exclamation::before {
  content: "\e405"; }
.fa-star::before {
  content: "\f005"; }
.fa-dial-min::before {
  content: "\e161"; }
.fa-repeat::before {
  content: "\f363"; }
.fa-cross::before {
  content: "\f654"; }
.fa-page-caret-down::before {
  content: "\e429"; }
.fa-file-caret-down::before {
  content: "\e429"; }
.fa-box::before {
  content: "\f466"; }
.fa-venus-mars::before {
  content: "\f228"; }
.fa-clock-seven-thirty::before {
  content: "\e351"; }
.fa-arrow-pointer::before {
  content: "\f245"; }
.fa-mouse-pointer::before {
  content: "\f245"; }
.fa-clock-four-thirty::before {
  content: "\e34b"; }
.fa-signal-bars-good::before {
  content: "\f693"; }
.fa-signal-alt-3::before {
  content: "\f693"; }
.fa-cactus::before {
  content: "\f8a7"; }
.fa-maximize::before {
  content: "\f31e"; }
.fa-expand-arrows-alt::before {
  content: "\f31e"; }
.fa-charging-station::before {
  content: "\f5e7"; }
.fa-shapes::before {
  content: "\f61f"; }
.fa-triangle-circle-square::before {
  content: "\f61f"; }
.fa-plane-tail::before {
  content: "\e22c"; }
.fa-gauge-simple-max::before {
  content: "\f62b"; }
.fa-tachometer-fastest::before {
  content: "\f62b"; }
.fa-circle-u::before {
  content: "\e127"; }
.fa-shield-slash::before {
  content: "\e24b"; }
.fa-square-phone-hangup::before {
  content: "\e27a"; }
.fa-phone-square-down::before {
  content: "\e27a"; }
.fa-arrow-up-left::before {
  content: "\e09d"; }
.fa-transporter-1::before {
  content: "\e043"; }
.fa-peanuts::before {
  content: "\e431"; }
.fa-shuffle::before {
  content: "\f074"; }
.fa-random::before {
  content: "\f074"; }
.fa-person-running::before {
  content: "\f70c"; }
.fa-running::before {
  content: "\f70c"; }
.fa-mobile-retro::before {
  content: "\e527"; }
.fa-grip-lines-vertical::before {
  content: "\f7a5"; }
.fa-arrow-up-from-square::before {
  content: "\e09c"; }
.fa-file-dashed-line::before {
  content: "\f877"; }
.fa-page-break::before {
  content: "\f877"; }
.fa-bracket-curly-right::before {
  content: "\7d"; }
.fa-spider::before {
  content: "\f717"; }
.fa-clock-three::before {
  content: "\e356"; }
.fa-hands-bound::before {
  content: "\e4f9"; }
.fa-scalpel-line-dashed::before {
  content: "\f61e"; }
.fa-scalpel-path::before {
  content: "\f61e"; }
.fa-file-invoice-dollar::before {
  content: "\f571"; }
.fa-pipe-smoking::before {
  content: "\e3c4"; }
.fa-face-astonished::before {
  content: "\e36b"; }
.fa-window::before {
  content: "\f40e"; }
.fa-plane-circle-exclamation::before {
  content: "\e556"; }
.fa-ear::before {
  content: "\f5f0"; }
.fa-file-lock::before {
  content: "\e3a6"; }
.fa-diagram-venn::before {
  content: "\e15a"; }
.fa-x-ray::before {
  content: "\f497"; }
.fa-goal-net::before {
  content: "\e3ab"; }
.fa-coffin-cross::before {
  content: "\e051"; }
.fa-spell-check::before {
  content: "\f891"; }
.fa-location-xmark::before {
  content: "\f60e"; }
.fa-map-marker-times::before {
  content: "\f60e"; }
.fa-map-marker-xmark::before {
  content: "\f60e"; }
.fa-lasso::before {
  content: "\f8c8"; }
.fa-slash::before {
  content: "\f715"; }
.fa-person-to-portal::before {
  content: "\e022"; }
.fa-portal-enter::before {
  content: "\e022"; }
.fa-calendar-star::before {
  content: "\f736"; }
.fa-computer-mouse::before {
  content: "\f8cc"; }
.fa-mouse::before {
  content: "\f8cc"; }
.fa-arrow-right-to-bracket::before {
  content: "\f090"; }
.fa-sign-in::before {
  content: "\f090"; }
.fa-pegasus::before {
  content: "\f703"; }
.fa-files-medical::before {
  content: "\f7fd"; }
.fa-nfc-lock::before {
  content: "\e1f8"; }
.fa-person-ski-lift::before {
  content: "\f7c8"; }
.fa-ski-lift::before {
  content: "\f7c8"; }
.fa-square-6::before {
  content: "\e25b"; }
.fa-shop-slash::before {
  content: "\e070"; }
.fa-store-alt-slash::before {
  content: "\e070"; }
.fa-wind-turbine::before {
  content: "\f89b"; }
.fa-sliders-simple::before {
  content: "\e253"; }
.fa-badge-sheriff::before {
  content: "\f8a2"; }
.fa-server::before {
  content: "\f233"; }
.fa-virus-covid-slash::before {
  content: "\e4a9"; }
.fa-intersection::before {
  content: "\f668"; }
.fa-shop-lock::before {
  content: "\e4a5"; }
.fa-family::before {
  content: "\e300"; }
.fa-hourglass-start::before {
  content: "\f251"; }
.fa-hourglass-1::before {
  content: "\f251"; }
.fa-user-hair-buns::before {
  content: "\e3d3"; }
.fa-blender-phone::before {
  content: "\f6b6"; }
.fa-hourglass-clock::before {
  content: "\e41b"; }
.fa-person-seat-reclined::before {
  content: "\e21f"; }
.fa-paper-plane-top::before {
  content: "\e20a"; }
.fa-paper-plane-alt::before {
  content: "\e20a"; }
.fa-send::before {
  content: "\e20a"; }
.fa-message-arrow-up::before {
  content: "\e1dc"; }
.fa-comment-alt-arrow-up::before {
  content: "\e1dc"; }
.fa-lightbulb-exclamation::before {
  content: "\f671"; }
.fa-layer-minus::before {
  content: "\f5fe"; }
.fa-layer-group-minus::before {
  content: "\f5fe"; }
.fa-circle-e::before {
  content: "\e109"; }
.fa-building-wheat::before {
  content: "\e4db"; }
.fa-gauge-max::before {
  content: "\f626"; }
.fa-tachometer-alt-fastest::before {
  content: "\f626"; }
.fa-person-breastfeeding::before {
  content: "\e53a"; }
.fa-apostrophe::before {
  content: "\27"; }
.fa-fire-hydrant::before {
  content: "\e17f"; }
.fa-right-to-bracket::before {
  content: "\f2f6"; }
.fa-sign-in-alt::before {
  content: "\f2f6"; }
.fa-video-plus::before {
  content: "\f4e1"; }
.fa-square-right::before {
  content: "\f352"; }
.fa-arrow-alt-square-right::before {
  content: "\f352"; }
.fa-comment-smile::before {
  content: "\f4b4"; }
.fa-venus::before {
  content: "\f221"; }
.fa-passport::before {
  content: "\f5ab"; }
.fa-inbox-in::before {
  content: "\f310"; }
.fa-inbox-arrow-down::before {
  content: "\f310"; }
.fa-heart-pulse::before {
  content: "\f21e"; }
.fa-heartbeat::before {
  content: "\f21e"; }
.fa-circle-8::before {
  content: "\e0f5"; }
.fa-clouds-moon::before {
  content: "\f745"; }
.fa-clock-ten-thirty::before {
  content: "\e355"; }
.fa-people-carry-box::before {
  content: "\f4ce"; }
.fa-people-carry::before {
  content: "\f4ce"; }
.fa-folder-user::before {
  content: "\e18e"; }
.fa-trash-can-xmark::before {
  content: "\e2ae"; }
.fa-temperature-high::before {
  content: "\f769"; }
.fa-microchip::before {
  content: "\f2db"; }
.fa-left-long-to-line::before {
  content: "\e41e"; }
.fa-crown::before {
  content: "\f521"; }
.fa-weight-hanging::before {
  content: "\f5cd"; }
.fa-xmarks-lines::before {
  content: "\e59a"; }
.fa-file-prescription::before {
  content: "\f572"; }
.fa-calendar-range::before {
  content: "\e0d6"; }
.fa-flower-daffodil::before {
  content: "\f800"; }
.fa-hand-back-point-up::before {
  content: "\e1a2"; }
.fa-weight-scale::before {
  content: "\f496"; }
.fa-weight::before {
  content: "\f496"; }
.fa-star-exclamation::before {
  content: "\f2f3"; }
.fa-books::before {
  content: "\f5db"; }
.fa-user-group::before {
  content: "\f500"; }
.fa-user-friends::before {
  content: "\f500"; }
.fa-arrow-up-a-z::before {
  content: "\f15e"; }
.fa-sort-alpha-up::before {
  content: "\f15e"; }
.fa-layer-plus::before {
  content: "\f5ff"; }
.fa-layer-group-plus::before {
  content: "\f5ff"; }
.fa-play-pause::before {
  content: "\e22f"; }
.fa-block-question::before {
  content: "\e3dd"; }
.fa-snooze::before {
  content: "\f880"; }
.fa-zzz::before {
  content: "\f880"; }
.fa-scanner-image::before {
  content: "\f8f3"; }
.fa-tv-retro::before {
  content: "\f401"; }
.fa-square-t::before {
  content: "\e280"; }
.fa-farm::before {
  content: "\f864"; }
.fa-barn-silo::before {
  content: "\f864"; }
.fa-chess-knight::before {
  content: "\f441"; }
.fa-bars-sort::before {
  content: "\e0ae"; }
.fa-pallet-boxes::before {
  content: "\f483"; }
.fa-palette-boxes::before {
  content: "\f483"; }
.fa-pallet-alt::before {
  content: "\f483"; }
.fa-face-laugh-squint::before {
  content: "\f59b"; }
.fa-laugh-squint::before {
  content: "\f59b"; }
.fa-code-simple::before {
  content: "\e13d"; }
.fa-bolt-slash::before {
  content: "\e0b8"; }
.fa-panel-fire::before {
  content: "\e42f"; }
.fa-binary-circle-check::before {
  content: "\e33c"; }
.fa-comment-minus::before {
  content: "\f4b1"; }
.fa-burrito::before {
  content: "\f7ed"; }
.fa-violin::before {
  content: "\f8ed"; }
.fa-objects-column::before {
  content: "\e3c1"; }
.fa-square-chevron-down::before {
  content: "\f329"; }
.fa-chevron-square-down::before {
  content: "\f329"; }
.fa-comment-plus::before {
  content: "\f4b2"; }
.fa-triangle-instrument::before {
  content: "\f8e2"; }
.fa-triangle-music::before {
  content: "\f8e2"; }
.fa-wheelchair::before {
  content: "\f193"; }
.fa-user-pilot-tie::before {
  content: "\e2c1"; }
.fa-piano-keyboard::before {
  content: "\f8d5"; }
.fa-bed-empty::before {
  content: "\f8f9"; }
.fa-circle-arrow-up::before {
  content: "\f0aa"; }
.fa-arrow-circle-up::before {
  content: "\f0aa"; }
.fa-toggle-on::before {
  content: "\f205"; }
.fa-rectangle-vertical::before {
  content: "\f2fb"; }
.fa-rectangle-portrait::before {
  content: "\f2fb"; }
.fa-person-walking::before {
  content: "\f554"; }
.fa-walking::before {
  content: "\f554"; }
.fa-l::before {
  content: "\4c"; }
.fa-signal-stream::before {
  content: "\f8dd"; }
.fa-down-to-bracket::before {
  content: "\e4e7"; }
.fa-circle-z::before {
  content: "\e130"; }
.fa-stars::before {
  content: "\f762"; }
.fa-fire::before {
  content: "\f06d"; }
.fa-bed-pulse::before {
  content: "\f487"; }
.fa-procedures::before {
  content: "\f487"; }
.fa-house-day::before {
  content: "\e00e"; }
.fa-shuttle-space::before {
  content: "\f197"; }
.fa-space-shuttle::before {
  content: "\f197"; }
.fa-shirt-long-sleeve::before {
  content: "\e3c7"; }
.fa-chart-pie-simple::before {
  content: "\f64e"; }
.fa-chart-pie-alt::before {
  content: "\f64e"; }
.fa-face-laugh::before {
  content: "\f599"; }
.fa-laugh::before {
  content: "\f599"; }
.fa-folder-open::before {
  content: "\f07c"; }
.fa-album-collection-circle-user::before {
  content: "\e48f"; }
.fa-candy::before {
  content: "\e3e7"; }
.fa-bowl-hot::before {
  content: "\f823"; }
.fa-soup::before {
  content: "\f823"; }
.fa-flatbread::before {
  content: "\e40b"; }
.fa-heart-circle-plus::before {
  content: "\e500"; }
.fa-code-fork::before {
  content: "\e13b"; }
.fa-city::before {
  content: "\f64f"; }
.fa-signal-bars-weak::before {
  content: "\f691"; }
.fa-signal-alt-1::before {
  content: "\f691"; }
.fa-microphone-lines::before {
  content: "\f3c9"; }
.fa-microphone-alt::before {
  content: "\f3c9"; }
.fa-clock-twelve::before {
  content: "\e358"; }
.fa-pepper-hot::before {
  content: "\f816"; }
.fa-citrus-slice::before {
  content: "\e2f5"; }
.fa-sheep::before {
  content: "\f711"; }
.fa-unlock::before {
  content: "\f09c"; }
.fa-colon-sign::before {
  content: "\e140"; }
.fa-headset::before {
  content: "\f590"; }
.fa-badger-honey::before {
  content: "\f6b4"; }
.fa-h4::before {
  content: "\f86a"; }
.fa-store-slash::before {
  content: "\e071"; }
.fa-road-circle-xmark::before {
  content: "\e566"; }
.fa-signal-slash::before {
  content: "\f695"; }
.fa-user-minus::before {
  content: "\f503"; }
.fa-mars-stroke-up::before {
  content: "\f22a"; }
.fa-mars-stroke-v::before {
  content: "\f22a"; }
.fa-champagne-glasses::before {
  content: "\f79f"; }
.fa-glass-cheers::before {
  content: "\f79f"; }
.fa-taco::before {
  content: "\f826"; }
.fa-hexagon-plus::before {
  content: "\f300"; }
.fa-plus-hexagon::before {
  content: "\f300"; }
.fa-clipboard::before {
  content: "\f328"; }
.fa-house-circle-exclamation::before {
  content: "\e50a"; }
.fa-file-arrow-up::before {
  content: "\f574"; }
.fa-file-upload::before {
  content: "\f574"; }
.fa-wifi::before {
  content: "\f1eb"; }
.fa-wifi-3::before {
  content: "\f1eb"; }
.fa-wifi-strong::before {
  content: "\f1eb"; }
.fa-messages::before {
  content: "\f4b6"; }
.fa-comments-alt::before {
  content: "\f4b6"; }
.fa-bath::before {
  content: "\f2cd"; }
.fa-bathtub::before {
  content: "\f2cd"; }
.fa-umbrella-simple::before {
  content: "\e2bc"; }
.fa-umbrella-alt::before {
  content: "\e2bc"; }
.fa-rectangle-history-circle-plus::before {
  content: "\e4a3"; }
.fa-underline::before {
  content: "\f0cd"; }
.fa-prescription-bottle-pill::before {
  content: "\e5c0"; }
.fa-user-pen::before {
  content: "\f4ff"; }
.fa-user-edit::before {
  content: "\f4ff"; }
.fa-binary-slash::before {
  content: "\e33e"; }
.fa-square-o::before {
  content: "\e278"; }
.fa-signature::before {
  content: "\f5b7"; }
.fa-stroopwafel::before {
  content: "\f551"; }
.fa-bold::before {
  content: "\f032"; }
.fa-anchor-lock::before {
  content: "\e4ad"; }
.fa-building-ngo::before {
  content: "\e4d7"; }
.fa-transporter-3::before {
  content: "\e045"; }
.fa-engine-warning::before {
  content: "\f5f2"; }
.fa-engine-exclamation::before {
  content: "\f5f2"; }
.fa-circle-down-right::before {
  content: "\e108"; }
.fa-square-k::before {
  content: "\e274"; }
.fa-manat-sign::before {
  content: "\e1d5"; }
.fa-money-check-pen::before {
  content: "\f872"; }
.fa-money-check-edit::before {
  content: "\f872"; }
.fa-not-equal::before {
  content: "\f53e"; }
.fa-border-top-left::before {
  content: "\f853"; }
.fa-border-style::before {
  content: "\f853"; }
.fa-map-location-dot::before {
  content: "\f5a0"; }
.fa-map-marked-alt::before {
  content: "\f5a0"; }
.fa-tilde::before {
  content: "\7e"; }
.fa-jedi::before {
  content: "\f669"; }
.fa-square-poll-vertical::before {
  content: "\f681"; }
.fa-poll::before {
  content: "\f681"; }
.fa-arrow-down-square-triangle::before {
  content: "\f889"; }
.fa-sort-shapes-down-alt::before {
  content: "\f889"; }
.fa-mug-hot::before {
  content: "\f7b6"; }
.fa-dog-leashed::before {
  content: "\f6d4"; }
.fa-car-battery::before {
  content: "\f5df"; }
.fa-battery-car::before {
  content: "\f5df"; }
.fa-face-downcast-sweat::before {
  content: "\e371"; }
.fa-mailbox-flag-up::before {
  content: "\e5bb"; }
.fa-memo-circle-info::before {
  content: "\e49a"; }
.fa-gift::before {
  content: "\f06b"; }
.fa-dice-two::before {
  content: "\f528"; }
.fa-volume::before {
  content: "\f6a8"; }
.fa-volume-medium::before {
  content: "\f6a8"; }
.fa-transporter-5::before {
  content: "\e2a6"; }
.fa-gauge-circle-bolt::before {
  content: "\e496"; }
.fa-coin-front::before {
  content: "\e3fc"; }
.fa-file-slash::before {
  content: "\e3a7"; }
.fa-message-arrow-up-right::before {
  content: "\e1dd"; }
.fa-treasure-chest::before {
  content: "\f723"; }
.fa-chess-queen::before {
  content: "\f445"; }
.fa-paintbrush-fine::before {
  content: "\f5a9"; }
.fa-paint-brush-alt::before {
  content: "\f5a9"; }
.fa-paint-brush-fine::before {
  content: "\f5a9"; }
.fa-paintbrush-alt::before {
  content: "\f5a9"; }
.fa-glasses::before {
  content: "\f530"; }
.fa-hood-cloak::before {
  content: "\f6ef"; }
.fa-square-quote::before {
  content: "\e329"; }
.fa-up-left::before {
  content: "\e2bd"; }
.fa-bring-front::before {
  content: "\f857"; }
.fa-chess-board::before {
  content: "\f43c"; }
.fa-burger-cheese::before {
  content: "\f7f1"; }
.fa-cheeseburger::before {
  content: "\f7f1"; }
.fa-building-circle-check::before {
  content: "\e4d2"; }
.fa-repeat-1::before {
  content: "\f365"; }
.fa-arrow-down-to-line::before {
  content: "\f33d"; }
.fa-arrow-to-bottom::before {
  content: "\f33d"; }
.fa-grid-5::before {
  content: "\e199"; }
.fa-right-long-to-line::before {
  content: "\e444"; }
.fa-person-chalkboard::before {
  content: "\e53d"; }
.fa-mars-stroke-right::before {
  content: "\f22b"; }
.fa-mars-stroke-h::before {
  content: "\f22b"; }
.fa-hand-back-fist::before {
  content: "\f255"; }
.fa-hand-rock::before {
  content: "\f255"; }
.fa-tally::before {
  content: "\f69c"; }
.fa-tally-5::before {
  content: "\f69c"; }
.fa-square-caret-up::before {
  content: "\f151"; }
.fa-caret-square-up::before {
  content: "\f151"; }
.fa-cloud-showers-water::before {
  content: "\e4e4"; }
.fa-chart-bar::before {
  content: "\f080"; }
.fa-bar-chart::before {
  content: "\f080"; }
.fa-hands-bubbles::before {
  content: "\e05e"; }
.fa-hands-wash::before {
  content: "\e05e"; }
.fa-less-than-equal::before {
  content: "\f537"; }
.fa-train::before {
  content: "\f238"; }
.fa-up-from-dotted-line::before {
  content: "\e456"; }
.fa-eye-low-vision::before {
  content: "\f2a8"; }
.fa-low-vision::before {
  content: "\f2a8"; }
.fa-traffic-light-go::before {
  content: "\f638"; }
.fa-face-exhaling::before {
  content: "\e480"; }
.fa-sensor-fire::before {
  content: "\e02a"; }
.fa-user-unlock::before {
  content: "\e058"; }
.fa-hexagon-divide::before {
  content: "\e1ad"; }
.fa-00::before {
  content: "\e467"; }
.fa-crow::before {
  content: "\f520"; }
.fa-cassette-betamax::before {
  content: "\f8a4"; }
.fa-betamax::before {
  content: "\f8a4"; }
.fa-sailboat::before {
  content: "\e445"; }
.fa-window-restore::before {
  content: "\f2d2"; }
.fa-nfc-magnifying-glass::before {
  content: "\e1f9"; }
.fa-file-binary::before {
  content: "\e175"; }
.fa-circle-v::before {
  content: "\e12a"; }
.fa-square-plus::before {
  content: "\f0fe"; }
.fa-plus-square::before {
  content: "\f0fe"; }
.fa-bowl-scoops::before {
  content: "\e3df"; }
.fa-mistletoe::before {
  content: "\f7b4"; }
.fa-custard::before {
  content: "\e403"; }
.fa-lacrosse-stick::before {
  content: "\e3b5"; }
.fa-hockey-mask::before {
  content: "\f6ee"; }
.fa-sunrise::before {
  content: "\f766"; }
.fa-panel-ews::before {
  content: "\e42e"; }
.fa-torii-gate::before {
  content: "\f6a1"; }
.fa-cloud-exclamation::before {
  content: "\e491"; }
.fa-message-lines::before {
  content: "\f4a6"; }
.fa-comment-alt-lines::before {
  content: "\f4a6"; }
.fa-frog::before {
  content: "\f52e"; }
.fa-bucket::before {
  content: "\e4cf"; }
.fa-floppy-disk-pen::before {
  content: "\e182"; }
.fa-image::before {
  content: "\f03e"; }
.fa-window-frame::before {
  content: "\e04f"; }
.fa-microphone::before {
  content: "\f130"; }
.fa-cow::before {
  content: "\f6c8"; }
.fa-square-ring::before {
  content: "\e44f"; }
.fa-down-from-line::before {
  content: "\f349"; }
.fa-arrow-alt-from-top::before {
  content: "\f349"; }
.fa-caret-up::before {
  content: "\f0d8"; }
.fa-shield-xmark::before {
  content: "\e24c"; }
.fa-shield-times::before {
  content: "\e24c"; }
.fa-screwdriver::before {
  content: "\f54a"; }
.fa-circle-sort-down::before {
  content: "\e031"; }
.fa-sort-circle-down::before {
  content: "\e031"; }
.fa-folder-closed::before {
  content: "\e185"; }
.fa-house-tsunami::before {
  content: "\e515"; }
.fa-square-nfi::before {
  content: "\e576"; }
.fa-forklift::before {
  content: "\f47a"; }
.fa-arrow-up-from-ground-water::before {
  content: "\e4b5"; }
.fa-bracket-square-right::before {
  content: "\5d"; }
.fa-martini-glass::before {
  content: "\f57b"; }
.fa-glass-martini-alt::before {
  content: "\f57b"; }
.fa-rotate-left::before {
  content: "\f2ea"; }
.fa-rotate-back::before {
  content: "\f2ea"; }
.fa-rotate-backward::before {
  content: "\f2ea"; }
.fa-undo-alt::before {
  content: "\f2ea"; }
.fa-table-columns::before {
  content: "\f0db"; }
.fa-columns::before {
  content: "\f0db"; }
.fa-square-a::before {
  content: "\e25f"; }
.fa-tick::before {
  content: "\e32f"; }
.fa-lemon::before {
  content: "\f094"; }
.fa-head-side-mask::before {
  content: "\e063"; }
.fa-handshake::before {
  content: "\f2b5"; }
.fa-gem::before {
  content: "\f3a5"; }
.fa-dolly::before {
  content: "\f472"; }
.fa-dolly-box::before {
  content: "\f472"; }
.fa-smoking::before {
  content: "\f48d"; }
.fa-minimize::before {
  content: "\f78c"; }
.fa-compress-arrows-alt::before {
  content: "\f78c"; }
.fa-refrigerator::before {
  content: "\e026"; }
.fa-monument::before {
  content: "\f5a6"; }
.fa-octagon-xmark::before {
  content: "\f2f0"; }
.fa-times-octagon::before {
  content: "\f2f0"; }
.fa-xmark-octagon::before {
  content: "\f2f0"; }
.fa-align-slash::before {
  content: "\f846"; }
.fa-snowplow::before {
  content: "\f7d2"; }
.fa-angles-right::before {
  content: "\f101"; }
.fa-angle-double-right::before {
  content: "\f101"; }
.fa-truck-ramp-couch::before {
  content: "\f4dd"; }
.fa-truck-couch::before {
  content: "\f4dd"; }
.fa-cannabis::before {
  content: "\f55f"; }
.fa-circle-play::before {
  content: "\f144"; }
.fa-play-circle::before {
  content: "\f144"; }
.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0"; }
.fa-tablets::before {
  content: "\f490"; }
.fa-360-degrees::before {
  content: "\e2dc"; }
.fa-ethernet::before {
  content: "\f796"; }
.fa-euro-sign::before {
  content: "\f153"; }
.fa-eur::before {
  content: "\f153"; }
.fa-euro::before {
  content: "\f153"; }
.fa-chair::before {
  content: "\f6c0"; }
.fa-circle-check::before {
  content: "\f058"; }
.fa-check-circle::before {
  content: "\f058"; }
.fa-square-dashed-circle-plus::before {
  content: "\e5c2"; }
.fa-money-simple-from-bracket::before {
  content: "\e313"; }
.fa-bat::before {
  content: "\f6b5"; }
.fa-circle-stop::before {
  content: "\f28d"; }
.fa-stop-circle::before {
  content: "\f28d"; }
.fa-head-side-headphones::before {
  content: "\f8c2"; }
.fa-phone-rotary::before {
  content: "\f8d3"; }
.fa-compass-drafting::before {
  content: "\f568"; }
.fa-drafting-compass::before {
  content: "\f568"; }
.fa-plate-wheat::before {
  content: "\e55a"; }
.fa-calendar-circle-minus::before {
  content: "\e46f"; }
.fa-chopsticks::before {
  content: "\e3f7"; }
.fa-car-wrench::before {
  content: "\f5e3"; }
.fa-car-mechanic::before {
  content: "\f5e3"; }
.fa-icicles::before {
  content: "\f7ad"; }
.fa-person-shelter::before {
  content: "\e54f"; }
.fa-neuter::before {
  content: "\f22c"; }
.fa-id-badge::before {
  content: "\f2c1"; }
.fa-kazoo::before {
  content: "\f8c7"; }
.fa-marker::before {
  content: "\f5a1"; }
.fa-face-laugh-beam::before {
  content: "\f59a"; }
.fa-laugh-beam::before {
  content: "\f59a"; }
.fa-square-arrow-down-left::before {
  content: "\e261"; }
.fa-battery-bolt::before {
  content: "\f376"; }
.fa-tree-large::before {
  content: "\f7dd"; }
.fa-helicopter-symbol::before {
  content: "\e502"; }
.fa-aperture::before {
  content: "\e2df"; }
.fa-universal-access::before {
  content: "\f29a"; }
.fa-file-magnifying-glass::before {
  content: "\f865"; }
.fa-file-search::before {
  content: "\f865"; }
.fa-up-right::before {
  content: "\e2be"; }
.fa-circle-chevron-up::before {
  content: "\f139"; }
.fa-chevron-circle-up::before {
  content: "\f139"; }
.fa-user-police::before {
  content: "\e333"; }
.fa-lari-sign::before {
  content: "\e1c8"; }
.fa-volcano::before {
  content: "\f770"; }
.fa-teddy-bear::before {
  content: "\e3cf"; }
.fa-stocking::before {
  content: "\f7d5"; }
.fa-person-walking-dashed-line-arrow-right::before {
  content: "\e553"; }
.fa-image-slash::before {
  content: "\e1b7"; }
.fa-mask-snorkel::before {
  content: "\e3b7"; }
.fa-smoke::before {
  content: "\f760"; }
.fa-sterling-sign::before {
  content: "\f154"; }
.fa-gbp::before {
  content: "\f154"; }
.fa-pound-sign::before {
  content: "\f154"; }
.fa-battery-exclamation::before {
  content: "\e0b0"; }
.fa-viruses::before {
  content: "\e076"; }
.fa-square-person-confined::before {
  content: "\e577"; }
.fa-user-tie::before {
  content: "\f508"; }
.fa-arrow-down-long::before {
  content: "\f175"; }
.fa-long-arrow-down::before {
  content: "\f175"; }
.fa-tent-arrow-down-to-line::before {
  content: "\e57e"; }
.fa-certificate::before {
  content: "\f0a3"; }
.fa-crystal-ball::before {
  content: "\e362"; }
.fa-reply-all::before {
  content: "\f122"; }
.fa-mail-reply-all::before {
  content: "\f122"; }
.fa-suitcase::before {
  content: "\f0f2"; }
.fa-person-skating::before {
  content: "\f7c5"; }
.fa-skating::before {
  content: "\f7c5"; }
.fa-star-shooting::before {
  content: "\e036"; }
.fa-binary-lock::before {
  content: "\e33d"; }
.fa-filter-circle-dollar::before {
  content: "\f662"; }
.fa-funnel-dollar::before {
  content: "\f662"; }
.fa-camera-retro::before {
  content: "\f083"; }
.fa-circle-arrow-down::before {
  content: "\f0ab"; }
.fa-arrow-circle-down::before {
  content: "\f0ab"; }
.fa-comment-pen::before {
  content: "\f4ae"; }
.fa-comment-edit::before {
  content: "\f4ae"; }
.fa-file-import::before {
  content: "\f56f"; }
.fa-arrow-right-to-file::before {
  content: "\f56f"; }
.fa-banjo::before {
  content: "\f8a3"; }
.fa-square-arrow-up-right::before {
  content: "\f14c"; }
.fa-external-link-square::before {
  content: "\f14c"; }
.fa-light-emergency-on::before {
  content: "\e420"; }
.fa-kerning::before {
  content: "\f86f"; }
.fa-box-open::before {
  content: "\f49e"; }
.fa-square-f::before {
  content: "\e270"; }
.fa-scroll::before {
  content: "\f70e"; }
.fa-spa::before {
  content: "\f5bb"; }
.fa-arrow-left-from-line::before {
  content: "\f344"; }
.fa-arrow-from-right::before {
  content: "\f344"; }
.fa-strawberry::before {
  content: "\e32b"; }
.fa-location-pin-lock::before {
  content: "\e51f"; }
.fa-pause::before {
  content: "\f04c"; }
.fa-clock-eight-thirty::before {
  content: "\e346"; }
.fa-plane-engines::before {
  content: "\f3de"; }
.fa-plane-alt::before {
  content: "\f3de"; }
.fa-hill-avalanche::before {
  content: "\e507"; }
.fa-temperature-empty::before {
  content: "\f2cb"; }
.fa-temperature-0::before {
  content: "\f2cb"; }
.fa-thermometer-0::before {
  content: "\f2cb"; }
.fa-thermometer-empty::before {
  content: "\f2cb"; }
.fa-bomb::before {
  content: "\f1e2"; }
.fa-gauge-low::before {
  content: "\f627"; }
.fa-tachometer-alt-slow::before {
  content: "\f627"; }
.fa-registered::before {
  content: "\f25d"; }
.fa-trash-can-plus::before {
  content: "\e2ac"; }
.fa-address-card::before {
  content: "\f2bb"; }
.fa-contact-card::before {
  content: "\f2bb"; }
.fa-vcard::before {
  content: "\f2bb"; }
.fa-scale-unbalanced-flip::before {
  content: "\f516"; }
.fa-balance-scale-right::before {
  content: "\f516"; }
.fa-globe-snow::before {
  content: "\f7a3"; }
.fa-subscript::before {
  content: "\f12c"; }
.fa-diamond-turn-right::before {
  content: "\f5eb"; }
.fa-directions::before {
  content: "\f5eb"; }
.fa-integral::before {
  content: "\f667"; }
.fa-burst::before {
  content: "\e4dc"; }
.fa-house-laptop::before {
  content: "\e066"; }
.fa-laptop-house::before {
  content: "\e066"; }
.fa-face-tired::before {
  content: "\f5c8"; }
.fa-tired::before {
  content: "\f5c8"; }
.fa-money-bills::before {
  content: "\e1f3"; }
.fa-blinds-raised::before {
  content: "\f8fd"; }
.fa-smog::before {
  content: "\f75f"; }
.fa-ufo-beam::before {
  content: "\e048"; }
.fa-circle-caret-up::before {
  content: "\f331"; }
.fa-caret-circle-up::before {
  content: "\f331"; }
.fa-user-vneck-hair-long::before {
  content: "\e463"; }
.fa-square-a-lock::before {
  content: "\e44d"; }
.fa-crutch::before {
  content: "\f7f7"; }
.fa-gas-pump-slash::before {
  content: "\f5f4"; }
.fa-cloud-arrow-up::before {
  content: "\f0ee"; }
.fa-cloud-upload::before {
  content: "\f0ee"; }
.fa-cloud-upload-alt::before {
  content: "\f0ee"; }
.fa-palette::before {
  content: "\f53f"; }
.fa-transporter-4::before {
  content: "\e2a5"; }
.fa-objects-align-right::before {
  content: "\e3bf"; }
.fa-arrows-turn-right::before {
  content: "\e4c0"; }
.fa-vest::before {
  content: "\e085"; }
.fa-pig::before {
  content: "\f706"; }
.fa-inbox-full::before {
  content: "\e1ba"; }
.fa-circle-envelope::before {
  content: "\e10c"; }
.fa-envelope-circle::before {
  content: "\e10c"; }
.fa-triangle-person-digging::before {
  content: "\f85d"; }
.fa-construction::before {
  content: "\f85d"; }
.fa-ferry::before {
  content: "\e4ea"; }
.fa-bullseye-arrow::before {
  content: "\f648"; }
.fa-arrows-down-to-people::before {
  content: "\e4b9"; }
.fa-seedling::before {
  content: "\f4d8"; }
.fa-sprout::before {
  content: "\f4d8"; }
.fa-clock-seven::before {
  content: "\e350"; }
.fa-left-right::before {
  content: "\f337"; }
.fa-arrows-alt-h::before {
  content: "\f337"; }
.fa-boxes-packing::before {
  content: "\e4c7"; }
.fa-circle-arrow-left::before {
  content: "\f0a8"; }
.fa-arrow-circle-left::before {
  content: "\f0a8"; }
.fa-flashlight::before {
  content: "\f8b8"; }
.fa-group-arrows-rotate::before {
  content: "\e4f6"; }
.fa-bowl-food::before {
  content: "\e4c6"; }
.fa-square-9::before {
  content: "\e25e"; }
.fa-candy-cane::before {
  content: "\f786"; }
.fa-arrow-down-wide-short::before {
  content: "\f160"; }
.fa-sort-amount-asc::before {
  content: "\f160"; }
.fa-sort-amount-down::before {
  content: "\f160"; }
.fa-square-dollar::before {
  content: "\f2e9"; }
.fa-dollar-square::before {
  content: "\f2e9"; }
.fa-usd-square::before {
  content: "\f2e9"; }
.fa-phone-arrow-right::before {
  content: "\e5be"; }
.fa-hand-holding-seedling::before {
  content: "\f4bf"; }
.fa-message-check::before {
  content: "\f4a2"; }
.fa-comment-alt-check::before {
  content: "\f4a2"; }
.fa-cloud-bolt::before {
  content: "\f76c"; }
.fa-thunderstorm::before {
  content: "\f76c"; }
.fa-text-slash::before {
  content: "\f87d"; }
.fa-remove-format::before {
  content: "\f87d"; }
.fa-watch::before {
  content: "\f2e1"; }
.fa-circle-down-left::before {
  content: "\e107"; }
.fa-text::before {
  content: "\f893"; }
.fa-projector::before {
  content: "\f8d6"; }
.fa-face-smile-wink::before {
  content: "\f4da"; }
.fa-smile-wink::before {
  content: "\f4da"; }
.fa-tombstone-blank::before {
  content: "\f721"; }
.fa-tombstone-alt::before {
  content: "\f721"; }
.fa-chess-king-piece::before {
  content: "\f440"; }
.fa-chess-king-alt::before {
  content: "\f440"; }
.fa-circle-6::before {
  content: "\e0f3"; }
.fa-left::before {
  content: "\f355"; }
.fa-arrow-alt-left::before {
  content: "\f355"; }
.fa-file-word::before {
  content: "\f1c2"; }
.fa-file-powerpoint::before {
  content: "\f1c4"; }
.fa-square-down::before {
  content: "\f350"; }
.fa-arrow-alt-square-down::before {
  content: "\f350"; }
.fa-objects-align-center-vertical::before {
  content: "\e3bd"; }
.fa-arrows-left-right::before {
  content: "\f07e"; }
.fa-arrows-h::before {
  content: "\f07e"; }
.fa-house-lock::before {
  content: "\e510"; }
.fa-cloud-arrow-down::before {
  content: "\f0ed"; }
.fa-cloud-download::before {
  content: "\f0ed"; }
.fa-cloud-download-alt::before {
  content: "\f0ed"; }
.fa-wreath::before {
  content: "\f7e2"; }
.fa-children::before {
  content: "\e4e1"; }
.fa-meter-droplet::before {
  content: "\e1ea"; }
.fa-chalkboard::before {
  content: "\f51b"; }
.fa-blackboard::before {
  content: "\f51b"; }
.fa-user-large-slash::before {
  content: "\f4fa"; }
.fa-user-alt-slash::before {
  content: "\f4fa"; }
.fa-signal-strong::before {
  content: "\f68f"; }
.fa-signal-4::before {
  content: "\f68f"; }
.fa-lollipop::before {
  content: "\e424"; }
.fa-lollypop::before {
  content: "\e424"; }
.fa-list-tree::before {
  content: "\e1d2"; }
.fa-envelope-open::before {
  content: "\f2b6"; }
.fa-draw-circle::before {
  content: "\f5ed"; }
.fa-cat-space::before {
  content: "\e001"; }
.fa-handshake-simple-slash::before {
  content: "\e05f"; }
.fa-handshake-alt-slash::before {
  content: "\e05f"; }
.fa-rabbit-running::before {
  content: "\f709"; }
.fa-rabbit-fast::before {
  content: "\f709"; }
.fa-memo-pad::before {
  content: "\e1da"; }
.fa-mattress-pillow::before {
  content: "\e525"; }
.fa-alarm-plus::before {
  content: "\f844"; }
.fa-alicorn::before {
  content: "\f6b0"; }
.fa-comment-question::before {
  content: "\e14b"; }
.fa-gingerbread-man::before {
  content: "\f79d"; }
.fa-guarani-sign::before {
  content: "\e19a"; }
.fa-burger-fries::before {
  content: "\e0cd"; }
.fa-mug-tea::before {
  content: "\f875"; }
.fa-border-top::before {
  content: "\f855"; }
.fa-arrows-rotate::before {
  content: "\f021"; }
.fa-refresh::before {
  content: "\f021"; }
.fa-sync::before {
  content: "\f021"; }
.fa-circle-book-open::before {
  content: "\e0ff"; }
.fa-book-circle::before {
  content: "\e0ff"; }
.fa-arrows-to-dotted-line::before {
  content: "\e0a6"; }
.fa-fire-extinguisher::before {
  content: "\f134"; }
.fa-garage-open::before {
  content: "\e00b"; }
.fa-shelves-empty::before {
  content: "\e246"; }
.fa-cruzeiro-sign::before {
  content: "\e152"; }
.fa-watch-apple::before {
  content: "\e2cb"; }
.fa-watch-calculator::before {
  content: "\f8f0"; }
.fa-list-dropdown::before {
  content: "\e1cf"; }
.fa-cabinet-filing::before {
  content: "\f64b"; }
.fa-burger-soda::before {
  content: "\f858"; }
.fa-square-arrow-up::before {
  content: "\f33c"; }
.fa-arrow-square-up::before {
  content: "\f33c"; }
.fa-greater-than-equal::before {
  content: "\f532"; }
.fa-pallet-box::before {
  content: "\e208"; }
.fa-face-confounded::before {
  content: "\e36c"; }
.fa-shield-halved::before {
  content: "\f3ed"; }
.fa-shield-alt::before {
  content: "\f3ed"; }
.fa-truck-plow::before {
  content: "\f7de"; }
.fa-book-atlas::before {
  content: "\f558"; }
.fa-atlas::before {
  content: "\f558"; }
.fa-virus::before {
  content: "\e074"; }
.fa-comment-middle-top::before {
  content: "\e14a"; }
.fa-envelope-circle-check::before {
  content: "\e4e8"; }
.fa-layer-group::before {
  content: "\f5fd"; }
.fa-restroom-simple::before {
  content: "\e23a"; }
.fa-arrows-to-dot::before {
  content: "\e4be"; }
.fa-border-outer::before {
  content: "\f851"; }
.fa-hashtag-lock::before {
  content: "\e415"; }
.fa-clock-two-thirty::before {
  content: "\e35b"; }
.fa-archway::before {
  content: "\f557"; }
.fa-heart-circle-check::before {
  content: "\e4fd"; }
.fa-house-chimney-crack::before {
  content: "\f6f1"; }
.fa-house-damage::before {
  content: "\f6f1"; }
.fa-file-zipper::before {
  content: "\f1c6"; }
.fa-file-archive::before {
  content: "\f1c6"; }
.fa-heart-half::before {
  content: "\e1ab"; }
.fa-comment-check::before {
  content: "\f4ac"; }
.fa-square::before {
  content: "\f0c8"; }
.fa-memo::before {
  content: "\e1d8"; }
.fa-martini-glass-empty::before {
  content: "\f000"; }
.fa-glass-martini::before {
  content: "\f000"; }
.fa-couch::before {
  content: "\f4b8"; }
.fa-cedi-sign::before {
  content: "\e0df"; }
.fa-italic::before {
  content: "\f033"; }
.fa-glass-citrus::before {
  content: "\f869"; }
.fa-calendar-lines-pen::before {
  content: "\e472"; }
.fa-church::before {
  content: "\f51d"; }
.fa-person-snowmobiling::before {
  content: "\f7d1"; }
.fa-snowmobile::before {
  content: "\f7d1"; }
.fa-face-hushed::before {
  content: "\e37b"; }
.fa-comments-dollar::before {
  content: "\f653"; }
.fa-pickaxe::before {
  content: "\e5bf"; }
.fa-link-simple-slash::before {
  content: "\e1ce"; }
.fa-democrat::before {
  content: "\f747"; }
.fa-face-confused::before {
  content: "\e36d"; }
.fa-pinball::before {
  content: "\e229"; }
.fa-z::before {
  content: "\5a"; }
.fa-person-skiing::before {
  content: "\f7c9"; }
.fa-skiing::before {
  content: "\f7c9"; }
.fa-deer::before {
  content: "\f78e"; }
.fa-input-pipe::before {
  content: "\e1be"; }
.fa-road-lock::before {
  content: "\e567"; }
.fa-a::before {
  content: "\41"; }
.fa-bookmark-slash::before {
  content: "\e0c2"; }
.fa-temperature-arrow-down::before {
  content: "\e03f"; }
.fa-temperature-down::before {
  content: "\e03f"; }
.fa-mace::before {
  content: "\f6f8"; }
.fa-feather-pointed::before {
  content: "\f56b"; }
.fa-feather-alt::before {
  content: "\f56b"; }
.fa-sausage::before {
  content: "\f820"; }
.fa-trash-can-clock::before {
  content: "\e2aa"; }
.fa-p::before {
  content: "\50"; }
.fa-snowflake::before {
  content: "\f2dc"; }
.fa-stomach::before {
  content: "\f623"; }
.fa-newspaper::before {
  content: "\f1ea"; }
.fa-rectangle-ad::before {
  content: "\f641"; }
.fa-ad::before {
  content: "\f641"; }
.fa-guitar-electric::before {
  content: "\f8be"; }
.fa-arrow-turn-down-right::before {
  content: "\e3d6"; }
.fa-moon-cloud::before {
  content: "\f754"; }
.fa-bread-slice-butter::before {
  content: "\e3e1"; }
.fa-circle-arrow-right::before {
  content: "\f0a9"; }
.fa-arrow-circle-right::before {
  content: "\f0a9"; }
.fa-user-group-crown::before {
  content: "\f6a5"; }
.fa-users-crown::before {
  content: "\f6a5"; }
.fa-circle-i::before {
  content: "\e111"; }
.fa-toilet-paper-check::before {
  content: "\e5b2"; }
.fa-filter-circle-xmark::before {
  content: "\e17b"; }
.fa-locust::before {
  content: "\e520"; }
.fa-sort::before {
  content: "\f0dc"; }
.fa-unsorted::before {
  content: "\f0dc"; }
.fa-list-ol::before {
  content: "\f0cb"; }
.fa-list-1-2::before {
  content: "\f0cb"; }
.fa-list-numeric::before {
  content: "\f0cb"; }
.fa-chart-waterfall::before {
  content: "\e0eb"; }
.fa-face-party::before {
  content: "\e383"; }
.fa-kidneys::before {
  content: "\f5fb"; }
.fa-wifi-exclamation::before {
  content: "\e2cf"; }
.fa-chart-network::before {
  content: "\f78a"; }
.fa-person-dress-burst::before {
  content: "\e544"; }
.fa-dice-d4::before {
  content: "\f6d0"; }
.fa-money-check-dollar::before {
  content: "\f53d"; }
.fa-money-check-alt::before {
  content: "\f53d"; }
.fa-vector-square::before {
  content: "\f5cb"; }
.fa-bread-slice::before {
  content: "\f7ec"; }
.fa-language::before {
  content: "\f1ab"; }
.fa-wheat-awn-slash::before {
  content: "\e338"; }
.fa-face-kiss-wink-heart::before {
  content: "\f598"; }
.fa-kiss-wink-heart::before {
  content: "\f598"; }
.fa-dagger::before {
  content: "\f6cb"; }
.fa-podium::before {
  content: "\f680"; }
.fa-memo-circle-check::before {
  content: "\e1d9"; }
.fa-route-highway::before {
  content: "\f61a"; }
.fa-down-to-line::before {
  content: "\f34a"; }
.fa-arrow-alt-to-bottom::before {
  content: "\f34a"; }
.fa-filter::before {
  content: "\f0b0"; }
.fa-square-g::before {
  content: "\e271"; }
.fa-circle-phone::before {
  content: "\e11b"; }
.fa-phone-circle::before {
  content: "\e11b"; }
.fa-clipboard-prescription::before {
  content: "\f5e8"; }
.fa-user-nurse-hair::before {
  content: "\e45d"; }
.fa-question::before {
  content: "\3f"; }
.fa-file-signature::before {
  content: "\f573"; }
.fa-toggle-large-on::before {
  content: "\e5b1"; }
.fa-up-down-left-right::before {
  content: "\f0b2"; }
.fa-arrows-alt::before {
  content: "\f0b2"; }
.fa-dryer-heat::before {
  content: "\f862"; }
.fa-dryer-alt::before {
  content: "\f862"; }
.fa-house-chimney-user::before {
  content: "\e065"; }
.fa-hand-holding-heart::before {
  content: "\f4be"; }
.fa-arrow-up-small-big::before {
  content: "\f88f"; }
.fa-sort-size-up-alt::before {
  content: "\f88f"; }
.fa-train-track::before {
  content: "\e453"; }
.fa-puzzle-piece::before {
  content: "\f12e"; }
.fa-money-check::before {
  content: "\f53c"; }
.fa-star-half-stroke::before {
  content: "\f5c0"; }
.fa-star-half-alt::before {
  content: "\f5c0"; }
.fa-file-exclamation::before {
  content: "\f31a"; }
.fa-code::before {
  content: "\f121"; }
.fa-whiskey-glass::before {
  content: "\f7a0"; }
.fa-glass-whiskey::before {
  content: "\f7a0"; }
.fa-moon-stars::before {
  content: "\f755"; }
.fa-building-circle-exclamation::before {
  content: "\e4d3"; }
.fa-clothes-hanger::before {
  content: "\e136"; }
.fa-mobile-notch::before {
  content: "\e1ee"; }
.fa-mobile-iphone::before {
  content: "\e1ee"; }
.fa-magnifying-glass-chart::before {
  content: "\e522"; }
.fa-arrow-up-right-from-square::before {
  content: "\f08e"; }
.fa-external-link::before {
  content: "\f08e"; }
.fa-cubes-stacked::before {
  content: "\e4e6"; }
.fa-images-user::before {
  content: "\e1b9"; }
.fa-won-sign::before {
  content: "\f159"; }
.fa-krw::before {
  content: "\f159"; }
.fa-won::before {
  content: "\f159"; }
.fa-image-polaroid-user::before {
  content: "\e1b6"; }
.fa-virus-covid::before {
  content: "\e4a8"; }
.fa-square-ellipsis::before {
  content: "\e26e"; }
.fa-pie::before {
  content: "\f705"; }
.fa-chess-knight-piece::before {
  content: "\f442"; }
.fa-chess-knight-alt::before {
  content: "\f442"; }
.fa-austral-sign::before {
  content: "\e0a9"; }
.fa-cloud-plus::before {
  content: "\e35e"; }
.fa-f::before {
  content: "\46"; }
.fa-leaf::before {
  content: "\f06c"; }
.fa-bed-bunk::before {
  content: "\f8f8"; }
.fa-road::before {
  content: "\f018"; }
.fa-taxi::before {
  content: "\f1ba"; }
.fa-cab::before {
  content: "\f1ba"; }
.fa-person-circle-plus::before {
  content: "\e541"; }
.fa-chart-pie::before {
  content: "\f200"; }
.fa-pie-chart::before {
  content: "\f200"; }
.fa-bolt-lightning::before {
  content: "\e0b7"; }
.fa-clock-eight::before {
  content: "\e345"; }
.fa-sack-xmark::before {
  content: "\e56a"; }
.fa-file-excel::before {
  content: "\f1c3"; }
.fa-file-contract::before {
  content: "\f56c"; }
.fa-fish-fins::before {
  content: "\e4f2"; }
.fa-circle-q::before {
  content: "\e11e"; }
.fa-building-flag::before {
  content: "\e4d5"; }
.fa-face-grin-beam::before {
  content: "\f582"; }
.fa-grin-beam::before {
  content: "\f582"; }
.fa-object-ungroup::before {
  content: "\f248"; }
.fa-face-disguise::before {
  content: "\e370"; }
.fa-circle-arrow-down-right::before {
  content: "\e0fa"; }
.fa-alien-8bit::before {
  content: "\f8f6"; }
.fa-alien-monster::before {
  content: "\f8f6"; }
.fa-hand-point-ribbon::before {
  content: "\e1a6"; }
.fa-poop::before {
  content: "\f619"; }
.fa-object-exclude::before {
  content: "\e49c"; }
.fa-telescope::before {
  content: "\e03e"; }
.fa-location-pin::before {
  content: "\f041"; }
.fa-map-marker::before {
  content: "\f041"; }
.fa-square-list::before {
  content: "\e489"; }
.fa-kaaba::before {
  content: "\f66b"; }
.fa-toilet-paper::before {
  content: "\f71e"; }
.fa-helmet-safety::before {
  content: "\f807"; }
.fa-hard-hat::before {
  content: "\f807"; }
.fa-hat-hard::before {
  content: "\f807"; }
.fa-comment-code::before {
  content: "\e147"; }
.fa-sim-cards::before {
  content: "\e251"; }
.fa-starship::before {
  content: "\e039"; }
.fa-eject::before {
  content: "\f052"; }
.fa-circle-right::before {
  content: "\f35a"; }
.fa-arrow-alt-circle-right::before {
  content: "\f35a"; }
.fa-plane-circle-check::before {
  content: "\e555"; }
.fa-seal::before {
  content: "\e241"; }
.fa-user-cowboy::before {
  content: "\f8ea"; }
.fa-hexagon-vertical-nft::before {
  content: "\e505"; }
.fa-face-rolling-eyes::before {
  content: "\f5a5"; }
.fa-meh-rolling-eyes::before {
  content: "\f5a5"; }
.fa-bread-loaf::before {
  content: "\f7eb"; }
.fa-rings-wedding::before {
  content: "\f81b"; }
.fa-object-group::before {
  content: "\f247"; }
.fa-french-fries::before {
  content: "\f803"; }
.fa-chart-line::before {
  content: "\f201"; }
.fa-line-chart::before {
  content: "\f201"; }
.fa-calendar-arrow-down::before {
  content: "\e0d0"; }
.fa-calendar-download::before {
  content: "\e0d0"; }
.fa-send-back::before {
  content: "\f87e"; }
.fa-mask-ventilator::before {
  content: "\e524"; }
.fa-signature-lock::before {
  content: "\e3ca"; }
.fa-arrow-right::before {
  content: "\f061"; }
.fa-signs-post::before {
  content: "\f277"; }
.fa-map-signs::before {
  content: "\f277"; }
.fa-octagon-plus::before {
  content: "\f301"; }
.fa-plus-octagon::before {
  content: "\f301"; }
.fa-cash-register::before {
  content: "\f788"; }
.fa-person-circle-question::before {
  content: "\e542"; }
.fa-melon-slice::before {
  content: "\e311"; }
.fa-space-station-moon::before {
  content: "\e033"; }
.fa-message-smile::before {
  content: "\f4aa"; }
.fa-comment-alt-smile::before {
  content: "\f4aa"; }
.fa-cup-straw::before {
  content: "\e363"; }
.fa-left-from-line::before {
  content: "\f348"; }
.fa-arrow-alt-from-right::before {
  content: "\f348"; }
.fa-h::before {
  content: "\48"; }
.fa-basket-shopping-simple::before {
  content: "\e0af"; }
.fa-shopping-basket-alt::before {
  content: "\e0af"; }
.fa-hands-holding-heart::before {
  content: "\f4c3"; }
.fa-hands-heart::before {
  content: "\f4c3"; }
.fa-clock-nine::before {
  content: "\e34c"; }
.fa-tarp::before {
  content: "\e57b"; }
.fa-face-sleepy::before {
  content: "\e38e"; }
.fa-hand-horns::before {
  content: "\e1a9"; }
.fa-screwdriver-wrench::before {
  content: "\f7d9"; }
.fa-tools::before {
  content: "\f7d9"; }
.fa-arrows-to-eye::before {
  content: "\e4bf"; }
.fa-circle-three-quarters::before {
  content: "\e125"; }
.fa-trophy-star::before {
  content: "\f2eb"; }
.fa-trophy-alt::before {
  content: "\f2eb"; }
.fa-plug-circle-bolt::before {
  content: "\e55b"; }
.fa-face-thermometer::before {
  content: "\e39a"; }
.fa-shirt-running::before {
  content: "\e3c8"; }
.fa-book-circle-arrow-up::before {
  content: "\e0bd"; }
.fa-face-nauseated::before {
  content: "\e381"; }
.fa-heart::before {
  content: "\f004"; }
.fa-file-chart-pie::before {
  content: "\f65a"; }
.fa-mars-and-venus::before {
  content: "\f224"; }
.fa-house-user::before {
  content: "\e1b0"; }
.fa-home-user::before {
  content: "\e1b0"; }
.fa-circle-arrow-down-left::before {
  content: "\e0f9"; }
.fa-dumpster-fire::before {
  content: "\f794"; }
.fa-hexagon-minus::before {
  content: "\f307"; }
.fa-minus-hexagon::before {
  content: "\f307"; }
.fa-left-to-line::before {
  content: "\f34b"; }
.fa-arrow-alt-to-left::before {
  content: "\f34b"; }
.fa-house-crack::before {
  content: "\e3b1"; }
.fa-paw-simple::before {
  content: "\f701"; }
.fa-paw-alt::before {
  content: "\f701"; }
.fa-arrow-left-long-to-line::before {
  content: "\e3d4"; }
.fa-brackets-round::before {
  content: "\e0c5"; }
.fa-parentheses::before {
  content: "\e0c5"; }
.fa-martini-glass-citrus::before {
  content: "\f561"; }
.fa-cocktail::before {
  content: "\f561"; }
.fa-user-shakespeare::before {
  content: "\e2c2"; }
.fa-arrow-right-to-arc::before {
  content: "\e4b2"; }
.fa-face-surprise::before {
  content: "\f5c2"; }
.fa-surprise::before {
  content: "\f5c2"; }
.fa-bottle-water::before {
  content: "\e4c5"; }
.fa-circle-pause::before {
  content: "\f28b"; }
.fa-pause-circle::before {
  content: "\f28b"; }
.fa-gauge-circle-plus::before {
  content: "\e498"; }
.fa-folders::before {
  content: "\f660"; }
.fa-angel::before {
  content: "\f779"; }
.fa-value-absolute::before {
  content: "\f6a6"; }
.fa-rabbit::before {
  content: "\f708"; }
.fa-toilet-paper-slash::before {
  content: "\e072"; }
.fa-apple-whole::before {
  content: "\f5d1"; }
.fa-apple-alt::before {
  content: "\f5d1"; }
.fa-kitchen-set::before {
  content: "\e51a"; }
.fa-diamond-half::before {
  content: "\e5b7"; }
.fa-lock-keyhole::before {
  content: "\f30d"; }
.fa-lock-alt::before {
  content: "\f30d"; }
.fa-r::before {
  content: "\52"; }
.fa-temperature-quarter::before {
  content: "\f2ca"; }
.fa-temperature-1::before {
  content: "\f2ca"; }
.fa-thermometer-1::before {
  content: "\f2ca"; }
.fa-thermometer-quarter::before {
  content: "\f2ca"; }
.fa-square-info::before {
  content: "\f30f"; }
.fa-info-square::before {
  content: "\f30f"; }
.fa-wifi-slash::before {
  content: "\f6ac"; }
.fa-toilet-paper-xmark::before {
  content: "\e5b3"; }
.fa-hands-holding-dollar::before {
  content: "\f4c5"; }
.fa-hands-usd::before {
  content: "\f4c5"; }
.fa-cube::before {
  content: "\f1b2"; }
.fa-arrow-down-triangle-square::before {
  content: "\f888"; }
.fa-sort-shapes-down::before {
  content: "\f888"; }
.fa-bitcoin-sign::before {
  content: "\e0b4"; }
.fa-shutters::before {
  content: "\e449"; }
.fa-shield-dog::before {
  content: "\e573"; }
.fa-solar-panel::before {
  content: "\f5ba"; }
.fa-lock-open::before {
  content: "\f3c1"; }
.fa-table-tree::before {
  content: "\e293"; }
.fa-house-chimney-heart::before {
  content: "\e1b2"; }
.fa-tally-3::before {
  content: "\e296"; }
.fa-elevator::before {
  content: "\e16d"; }
.fa-money-bill-transfer::before {
  content: "\e528"; }
.fa-money-bill-trend-up::before {
  content: "\e529"; }
.fa-house-flood-water-circle-arrow-right::before {
  content: "\e50f"; }
.fa-square-poll-horizontal::before {
  content: "\f682"; }
.fa-poll-h::before {
  content: "\f682"; }
.fa-circle::before {
  content: "\f111"; }
.fa-cart-circle-exclamation::before {
  content: "\e3f2"; }
.fa-sword::before {
  content: "\f71c"; }
.fa-backward-fast::before {
  content: "\f049"; }
.fa-fast-backward::before {
  content: "\f049"; }
.fa-recycle::before {
  content: "\f1b8"; }
.fa-user-astronaut::before {
  content: "\f4fb"; }
.fa-interrobang::before {
  content: "\e5ba"; }
.fa-plane-slash::before {
  content: "\e069"; }
.fa-circle-dashed::before {
  content: "\e105"; }
.fa-trademark::before {
  content: "\f25c"; }
.fa-basketball::before {
  content: "\f434"; }
.fa-basketball-ball::before {
  content: "\f434"; }
.fa-fork-knife::before {
  content: "\f2e6"; }
.fa-utensils-alt::before {
  content: "\f2e6"; }
.fa-satellite-dish::before {
  content: "\f7c0"; }
.fa-badge-check::before {
  content: "\f336"; }
.fa-circle-up::before {
  content: "\f35b"; }
.fa-arrow-alt-circle-up::before {
  content: "\f35b"; }
.fa-slider::before {
  content: "\e252"; }
.fa-mobile-screen-button::before {
  content: "\f3cd"; }
.fa-mobile-alt::before {
  content: "\f3cd"; }
.fa-clock-one-thirty::before {
  content: "\e34f"; }
.fa-inbox-out::before {
  content: "\f311"; }
.fa-inbox-arrow-up::before {
  content: "\f311"; }
.fa-cloud-slash::before {
  content: "\e137"; }
.fa-volume-high::before {
  content: "\f028"; }
.fa-volume-up::before {
  content: "\f028"; }
.fa-users-rays::before {
  content: "\e593"; }
.fa-wallet::before {
  content: "\f555"; }
.fa-octagon-check::before {
  content: "\e426"; }
.fa-flatbread-stuffed::before {
  content: "\e40c"; }
.fa-clipboard-check::before {
  content: "\f46c"; }
.fa-cart-circle-plus::before {
  content: "\e3f3"; }
.fa-truck-clock::before {
  content: "\f48c"; }
.fa-shipping-timed::before {
  content: "\f48c"; }
.fa-pool-8-ball::before {
  content: "\e3c5"; }
.fa-file-audio::before {
  content: "\f1c7"; }
.fa-turn-down-left::before {
  content: "\e331"; }
.fa-lock-hashtag::before {
  content: "\e423"; }
.fa-chart-radar::before {
  content: "\e0e7"; }
.fa-staff::before {
  content: "\f71b"; }
.fa-burger::before {
  content: "\f805"; }
.fa-hamburger::before {
  content: "\f805"; }
.fa-utility-pole::before {
  content: "\e2c3"; }
.fa-transporter-6::before {
  content: "\e2a7"; }
.fa-wrench::before {
  content: "\f0ad"; }
.fa-bugs::before {
  content: "\e4d0"; }
.fa-vector-polygon::before {
  content: "\e2c7"; }
.fa-diagram-nested::before {
  content: "\e157"; }
.fa-rupee-sign::before {
  content: "\f156"; }
.fa-rupee::before {
  content: "\f156"; }
.fa-file-image::before {
  content: "\f1c5"; }
.fa-circle-question::before {
  content: "\f059"; }
.fa-question-circle::before {
  content: "\f059"; }
.fa-image-user::before {
  content: "\e1b8"; }
.fa-buoy::before {
  content: "\e5b5"; }
.fa-plane-departure::before {
  content: "\f5b0"; }
.fa-handshake-slash::before {
  content: "\e060"; }
.fa-book-bookmark::before {
  content: "\e0bb"; }
.fa-border-center-h::before {
  content: "\f89c"; }
.fa-can-food::before {
  content: "\e3e6"; }
.fa-typewriter::before {
  content: "\f8e7"; }
.fa-arrow-right-from-arc::before {
  content: "\e4b1"; }
.fa-circle-k::before {
  content: "\e113"; }
.fa-face-hand-over-mouth::before {
  content: "\e378"; }
.fa-popcorn::before {
  content: "\f819"; }
.fa-house-water::before {
  content: "\f74f"; }
.fa-house-flood::before {
  content: "\f74f"; }
.fa-object-subtract::before {
  content: "\e49e"; }
.fa-code-branch::before {
  content: "\f126"; }
.fa-warehouse-full::before {
  content: "\f495"; }
.fa-warehouse-alt::before {
  content: "\f495"; }
.fa-hat-cowboy::before {
  content: "\f8c0"; }
.fa-bridge::before {
  content: "\e4c8"; }
.fa-phone-flip::before {
  content: "\f879"; }
.fa-phone-alt::before {
  content: "\f879"; }
.fa-arrow-down-from-dotted-line::before {
  content: "\e090"; }
.fa-square-quarters::before {
  content: "\e44e"; }
.fa-truck-front::before {
  content: "\e2b7"; }
.fa-cat::before {
  content: "\f6be"; }
.fa-trash-xmark::before {
  content: "\e2b4"; }
.fa-circle-caret-left::before {
  content: "\f32e"; }
.fa-caret-circle-left::before {
  content: "\f32e"; }
.fa-files::before {
  content: "\e178"; }
.fa-anchor-circle-exclamation::before {
  content: "\e4ab"; }
.fa-face-clouds::before {
  content: "\e47d"; }
.fa-user-crown::before {
  content: "\f6a4"; }
.fa-truck-field::before {
  content: "\e58d"; }
.fa-route::before {
  content: "\f4d7"; }
.fa-cart-circle-check::before {
  content: "\e3f1"; }
.fa-clipboard-question::before {
  content: "\e4e3"; }
.fa-panorama::before {
  content: "\e209"; }
.fa-comment-medical::before {
  content: "\f7f5"; }
.fa-teeth-open::before {
  content: "\f62f"; }
.fa-user-tie-hair-long::before {
  content: "\e460"; }
.fa-file-circle-minus::before {
  content: "\e4ed"; }
.fa-head-side-medical::before {
  content: "\f809"; }
.fa-tags::before {
  content: "\f02c"; }
.fa-wine-glass::before {
  content: "\f4e3"; }
.fa-forward-fast::before {
  content: "\f050"; }
.fa-fast-forward::before {
  content: "\f050"; }
.fa-face-meh-blank::before {
  content: "\f5a4"; }
.fa-meh-blank::before {
  content: "\f5a4"; }
.fa-user-robot::before {
  content: "\e04b"; }
.fa-square-parking::before {
  content: "\f540"; }
.fa-parking::before {
  content: "\f540"; }
.fa-card-diamond::before {
  content: "\e3ea"; }
.fa-face-zipper::before {
  content: "\e3a5"; }
.fa-face-raised-eyebrow::before {
  content: "\e388"; }
.fa-house-signal::before {
  content: "\e012"; }
.fa-square-chevron-up::before {
  content: "\f32c"; }
.fa-chevron-square-up::before {
  content: "\f32c"; }
.fa-bars-progress::before {
  content: "\f828"; }
.fa-tasks-alt::before {
  content: "\f828"; }
.fa-faucet-drip::before {
  content: "\e006"; }
.fa-arrows-to-line::before {
  content: "\e0a7"; }
.fa-dolphin::before {
  content: "\e168"; }
.fa-arrow-up-right::before {
  content: "\e09f"; }
.fa-circle-r::before {
  content: "\e120"; }
.fa-cart-flatbed::before {
  content: "\f474"; }
.fa-dolly-flatbed::before {
  content: "\f474"; }
.fa-ban-smoking::before {
  content: "\f54d"; }
.fa-smoking-ban::before {
  content: "\f54d"; }
.fa-circle-sort-up::before {
  content: "\e032"; }
.fa-sort-circle-up::before {
  content: "\e032"; }
.fa-terminal::before {
  content: "\f120"; }
.fa-mobile-button::before {
  content: "\f10b"; }
.fa-house-medical-flag::before {
  content: "\e514"; }
.fa-basket-shopping::before {
  content: "\f291"; }
.fa-shopping-basket::before {
  content: "\f291"; }
.fa-tape::before {
  content: "\f4db"; }
.fa-chestnut::before {
  content: "\e3f6"; }
.fa-bus-simple::before {
  content: "\f55e"; }
.fa-bus-alt::before {
  content: "\f55e"; }
.fa-eye::before {
  content: "\f06e"; }
.fa-face-sad-cry::before {
  content: "\f5b3"; }
.fa-sad-cry::before {
  content: "\f5b3"; }
.fa-heat::before {
  content: "\e00c"; }
.fa-ticket-airline::before {
  content: "\e29a"; }
.fa-boot-heeled::before {
  content: "\e33f"; }
.fa-arrows-minimize::before {
  content: "\e0a5"; }
.fa-compress-arrows::before {
  content: "\e0a5"; }
.fa-audio-description::before {
  content: "\f29e"; }
.fa-person-military-to-person::before {
  content: "\e54c"; }
.fa-file-shield::before {
  content: "\e4f0"; }
.fa-hexagon::before {
  content: "\f312"; }
.fa-manhole::before {
  content: "\e1d6"; }
.fa-user-slash::before {
  content: "\f506"; }
.fa-pen::before {
  content: "\f304"; }
.fa-tower-observation::before {
  content: "\e586"; }
.fa-floppy-disks::before {
  content: "\e183"; }
.fa-toilet-paper-blank-under::before {
  content: "\e29f"; }
.fa-toilet-paper-reverse-alt::before {
  content: "\e29f"; }
.fa-file-code::before {
  content: "\f1c9"; }
.fa-signal::before {
  content: "\f012"; }
.fa-signal-5::before {
  content: "\f012"; }
.fa-signal-perfect::before {
  content: "\f012"; }
.fa-pump::before {
  content: "\e442"; }
.fa-bus::before {
  content: "\f207"; }
.fa-heart-circle-xmark::before {
  content: "\e501"; }
.fa-arrow-up-left-from-circle::before {
  content: "\e09e"; }
.fa-house-chimney::before {
  content: "\e3af"; }
.fa-home-lg::before {
  content: "\e3af"; }
.fa-window-maximize::before {
  content: "\f2d0"; }
.fa-dryer::before {
  content: "\f861"; }
.fa-face-frown::before {
  content: "\f119"; }
.fa-frown::before {
  content: "\f119"; }
.fa-chess-bishop-piece::before {
  content: "\f43b"; }
.fa-chess-bishop-alt::before {
  content: "\f43b"; }
.fa-shirt-tank-top::before {
  content: "\e3c9"; }
.fa-diploma::before {
  content: "\f5ea"; }
.fa-scroll-ribbon::before {
  content: "\f5ea"; }
.fa-screencast::before {
  content: "\e23e"; }
.fa-walker::before {
  content: "\f831"; }
.fa-prescription::before {
  content: "\f5b1"; }
.fa-shop::before {
  content: "\f54f"; }
.fa-store-alt::before {
  content: "\f54f"; }
.fa-floppy-disk::before {
  content: "\f0c7"; }
.fa-save::before {
  content: "\f0c7"; }
.fa-vihara::before {
  content: "\f6a7"; }
.fa-face-kiss-closed-eyes::before {
  content: "\e37d"; }
.fa-scale-unbalanced::before {
  content: "\f515"; }
.fa-balance-scale-left::before {
  content: "\f515"; }
.fa-file-user::before {
  content: "\f65c"; }
.fa-user-police-tie::before {
  content: "\e334"; }
.fa-face-tongue-money::before {
  content: "\e39d"; }
.fa-tennis-ball::before {
  content: "\f45e"; }
.fa-square-l::before {
  content: "\e275"; }
.fa-sort-up::before {
  content: "\f0de"; }
.fa-sort-asc::before {
  content: "\f0de"; }
.fa-calendar-arrow-up::before {
  content: "\e0d1"; }
.fa-calendar-upload::before {
  content: "\e0d1"; }
.fa-comment-dots::before {
  content: "\f4ad"; }
.fa-commenting::before {
  content: "\f4ad"; }
.fa-plant-wilt::before {
  content: "\e5aa"; }
.fa-scarf::before {
  content: "\f7c1"; }
.fa-album-circle-plus::before {
  content: "\e48c"; }
.fa-user-nurse-hair-long::before {
  content: "\e45e"; }
.fa-diamond::before {
  content: "\f219"; }
.fa-square-left::before {
  content: "\f351"; }
.fa-arrow-alt-square-left::before {
  content: "\f351"; }
.fa-face-grin-squint::before {
  content: "\f585"; }
.fa-grin-squint::before {
  content: "\f585"; }
.fa-circle-ellipsis-vertical::before {
  content: "\e10b"; }
.fa-hand-holding-dollar::before {
  content: "\f4c0"; }
.fa-hand-holding-usd::before {
  content: "\f4c0"; }
.fa-grid-dividers::before {
  content: "\e3ad"; }
.fa-bacterium::before {
  content: "\e05a"; }
.fa-hand-pointer::before {
  content: "\f25a"; }
.fa-drum-steelpan::before {
  content: "\f56a"; }
.fa-hand-scissors::before {
  content: "\f257"; }
.fa-hands-praying::before {
  content: "\f684"; }
.fa-praying-hands::before {
  content: "\f684"; }
.fa-face-pensive::before {
  content: "\e384"; }
.fa-user-music::before {
  content: "\f8eb"; }
.fa-arrow-rotate-right::before {
  content: "\f01e"; }
.fa-arrow-right-rotate::before {
  content: "\f01e"; }
.fa-arrow-rotate-forward::before {
  content: "\f01e"; }
.fa-redo::before {
  content: "\f01e"; }
.fa-messages-dollar::before {
  content: "\f652"; }
.fa-comments-alt-dollar::before {
  content: "\f652"; }
.fa-sensor-on::before {
  content: "\e02b"; }
.fa-balloon::before {
  content: "\e2e3"; }
.fa-biohazard::before {
  content: "\f780"; }
.fa-chess-queen-piece::before {
  content: "\f446"; }
.fa-chess-queen-alt::before {
  content: "\f446"; }
.fa-location-crosshairs::before {
  content: "\f601"; }
.fa-location::before {
  content: "\f601"; }
.fa-mars-double::before {
  content: "\f227"; }
.fa-house-person-leave::before {
  content: "\e00f"; }
.fa-house-leave::before {
  content: "\e00f"; }
.fa-house-person-depart::before {
  content: "\e00f"; }
.fa-ruler-triangle::before {
  content: "\f61c"; }
.fa-card-club::before {
  content: "\e3e9"; }
.fa-child-dress::before {
  content: "\e59c"; }
.fa-users-between-lines::before {
  content: "\e591"; }
.fa-lungs-virus::before {
  content: "\e067"; }
.fa-spinner-third::before {
  content: "\f3f4"; }
.fa-face-grin-tears::before {
  content: "\f588"; }
.fa-grin-tears::before {
  content: "\f588"; }
.fa-phone::before {
  content: "\f095"; }
.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd"; }
.fa-mouse-alt::before {
  content: "\f8cd"; }
.fa-calendar-xmark::before {
  content: "\f273"; }
.fa-calendar-times::before {
  content: "\f273"; }
.fa-child-reaching::before {
  content: "\e59d"; }
.fa-table-layout::before {
  content: "\e290"; }
.fa-narwhal::before {
  content: "\f6fe"; }
.fa-ramp-loading::before {
  content: "\f4d4"; }
.fa-calendar-circle-plus::before {
  content: "\e470"; }
.fa-toothbrush::before {
  content: "\f635"; }
.fa-border-inner::before {
  content: "\f84e"; }
.fa-paw-claws::before {
  content: "\f702"; }
.fa-kiwi-fruit::before {
  content: "\e30c"; }
.fa-traffic-light-slow::before {
  content: "\f639"; }
.fa-rectangle-code::before {
  content: "\e322"; }
.fa-head-side-virus::before {
  content: "\e064"; }
.fa-keyboard-brightness::before {
  content: "\e1c0"; }
.fa-books-medical::before {
  content: "\f7e8"; }
.fa-lightbulb-slash::before {
  content: "\f673"; }
.fa-house-blank::before {
  content: "\e487"; }
.fa-home-blank::before {
  content: "\e487"; }
.fa-square-5::before {
  content: "\e25a"; }
.fa-square-heart::before {
  content: "\f4c8"; }
.fa-heart-square::before {
  content: "\f4c8"; }
.fa-puzzle::before {
  content: "\e443"; }
.fa-user-gear::before {
  content: "\f4fe"; }
.fa-user-cog::before {
  content: "\f4fe"; }
.fa-pipe-circle-check::before {
  content: "\e436"; }
.fa-arrow-up-1-9::before {
  content: "\f163"; }
.fa-sort-numeric-up::before {
  content: "\f163"; }
.fa-octagon-exclamation::before {
  content: "\e204"; }
.fa-dial-low::before {
  content: "\e15d"; }
.fa-door-closed::before {
  content: "\f52a"; }
.fa-laptop-mobile::before {
  content: "\f87a"; }
.fa-phone-laptop::before {
  content: "\f87a"; }
.fa-conveyor-belt-boxes::before {
  content: "\f46f"; }
.fa-conveyor-belt-alt::before {
  content: "\f46f"; }
.fa-shield-virus::before {
  content: "\e06c"; }
.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e"; }
.fa-starfighter-alt-advanced::before {
  content: "\e28e"; }
.fa-dice-six::before {
  content: "\f526"; }
.fa-starfighter-twin-ion-engine::before {
  content: "\e038"; }
.fa-starfighter-alt::before {
  content: "\e038"; }
.fa-rocket-launch::before {
  content: "\e027"; }
.fa-mosquito-net::before {
  content: "\e52c"; }
.fa-vent-damper::before {
  content: "\e465"; }
.fa-bridge-water::before {
  content: "\e4ce"; }
.fa-ban-bug::before {
  content: "\f7f9"; }
.fa-debug::before {
  content: "\f7f9"; }
.fa-person-booth::before {
  content: "\f756"; }
.fa-text-width::before {
  content: "\f035"; }
.fa-garage-car::before {
  content: "\e00a"; }
.fa-square-kanban::before {
  content: "\e488"; }
.fa-hat-wizard::before {
  content: "\f6e8"; }
.fa-pen-fancy::before {
  content: "\f5ac"; }
.fa-coffee-pot::before {
  content: "\e002"; }
.fa-mouse-field::before {
  content: "\e5a8"; }
.fa-person-digging::before {
  content: "\f85e"; }
.fa-digging::before {
  content: "\f85e"; }
.fa-shower-down::before {
  content: "\e24d"; }
.fa-shower-alt::before {
  content: "\e24d"; }
.fa-box-circle-check::before {
  content: "\e0c4"; }
.fa-brightness::before {
  content: "\e0c9"; }
.fa-car-side-bolt::before {
  content: "\e344"; }
.fa-ornament::before {
  content: "\f7b8"; }
.fa-phone-arrow-down-left::before {
  content: "\e223"; }
.fa-phone-arrow-down::before {
  content: "\e223"; }
.fa-phone-incoming::before {
  content: "\e223"; }
.fa-cloud-word::before {
  content: "\e138"; }
.fa-hand-fingers-crossed::before {
  content: "\e1a3"; }
.fa-trash::before {
  content: "\f1f8"; }
.fa-gauge-simple::before {
  content: "\f629"; }
.fa-gauge-simple-med::before {
  content: "\f629"; }
.fa-tachometer-average::before {
  content: "\f629"; }
.fa-arrow-down-small-big::before {
  content: "\f88d"; }
.fa-sort-size-down-alt::before {
  content: "\f88d"; }
.fa-book-medical::before {
  content: "\f7e6"; }
.fa-face-melting::before {
  content: "\e483"; }
.fa-poo::before {
  content: "\f2fe"; }
.fa-pen-clip-slash::before {
  content: "\e20f"; }
.fa-pen-alt-slash::before {
  content: "\e20f"; }
.fa-quote-right::before {
  content: "\f10e"; }
.fa-quote-right-alt::before {
  content: "\f10e"; }
.fa-scroll-old::before {
  content: "\f70f"; }
.fa-guitars::before {
  content: "\f8bf"; }
.fa-phone-xmark::before {
  content: "\e227"; }
.fa-hose::before {
  content: "\e419"; }
.fa-clock-six::before {
  content: "\e352"; }
.fa-shirt::before {
  content: "\f553"; }
.fa-t-shirt::before {
  content: "\f553"; }
.fa-tshirt::before {
  content: "\f553"; }
.fa-square-r::before {
  content: "\e27c"; }
.fa-cubes::before {
  content: "\f1b3"; }
.fa-envelope-open-dollar::before {
  content: "\f657"; }
.fa-divide::before {
  content: "\f529"; }
.fa-sun-cloud::before {
  content: "\f763"; }
.fa-lamp-floor::before {
  content: "\e015"; }
.fa-square-7::before {
  content: "\e25c"; }
.fa-tenge-sign::before {
  content: "\f7d7"; }
.fa-tenge::before {
  content: "\f7d7"; }
.fa-headphones::before {
  content: "\f025"; }
.fa-hands-holding::before {
  content: "\f4c2"; }
.fa-campfire::before {
  content: "\f6ba"; }
.fa-circle-ampersand::before {
  content: "\e0f8"; }
.fa-snowflakes::before {
  content: "\f7cf"; }
.fa-hands-clapping::before {
  content: "\e1a8"; }
.fa-republican::before {
  content: "\f75e"; }
.fa-leaf-maple::before {
  content: "\f6f6"; }
.fa-arrow-left::before {
  content: "\f060"; }
.fa-person-circle-xmark::before {
  content: "\e543"; }
.fa-ruler::before {
  content: "\f545"; }
.fa-cup-straw-swoosh::before {
  content: "\e364"; }
.fa-temperature-sun::before {
  content: "\f76a"; }
.fa-temperature-hot::before {
  content: "\f76a"; }
.fa-align-left::before {
  content: "\f036"; }
.fa-dice-d6::before {
  content: "\f6d1"; }
.fa-restroom::before {
  content: "\f7bd"; }
.fa-high-definition::before {
  content: "\e1ae"; }
.fa-rectangle-hd::before {
  content: "\e1ae"; }
.fa-j::before {
  content: "\4a"; }
.fa-galaxy::before {
  content: "\e008"; }
.fa-users-viewfinder::before {
  content: "\e595"; }
.fa-file-video::before {
  content: "\f1c8"; }
.fa-cherries::before {
  content: "\e0ec"; }
.fa-up-right-from-square::before {
  content: "\f35d"; }
.fa-external-link-alt::before {
  content: "\f35d"; }
.fa-circle-sort::before {
  content: "\e030"; }
.fa-sort-circle::before {
  content: "\e030"; }
.fa-table-cells::before {
  content: "\f00a"; }
.fa-th::before {
  content: "\f00a"; }
.fa-file-pdf::before {
  content: "\f1c1"; }
.fa-siren::before {
  content: "\e02d"; }
.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1"; }
.fa-image-landscape::before {
  content: "\e1b5"; }
.fa-landscape::before {
  content: "\e1b5"; }
.fa-tank-water::before {
  content: "\e452"; }
.fa-curling-stone::before {
  content: "\f44a"; }
.fa-curling::before {
  content: "\f44a"; }
.fa-gamepad-modern::before {
  content: "\e5a2"; }
.fa-gamepad-alt::before {
  content: "\e5a2"; }
.fa-messages-question::before {
  content: "\e1e7"; }
.fa-book-bible::before {
  content: "\f647"; }
.fa-bible::before {
  content: "\f647"; }
.fa-o::before {
  content: "\4f"; }
.fa-suitcase-medical::before {
  content: "\f0fa"; }
.fa-medkit::before {
  content: "\f0fa"; }
.fa-briefcase-arrow-right::before {
  content: "\e2f2"; }
.fa-expand-wide::before {
  content: "\f320"; }
.fa-clock-eleven-thirty::before {
  content: "\e348"; }
.fa-rv::before {
  content: "\f7be"; }
.fa-user-secret::before {
  content: "\f21b"; }
.fa-otter::before {
  content: "\f700"; }
.fa-dreidel::before {
  content: "\f792"; }
.fa-person-dress::before {
  content: "\f182"; }
.fa-female::before {
  content: "\f182"; }
.fa-comment-dollar::before {
  content: "\f651"; }
.fa-business-time::before {
  content: "\f64a"; }
.fa-briefcase-clock::before {
  content: "\f64a"; }
.fa-flower-tulip::before {
  content: "\f801"; }
.fa-people-pants-simple::before {
  content: "\e21a"; }
.fa-cloud-drizzle::before {
  content: "\f738"; }
.fa-table-cells-large::before {
  content: "\f009"; }
.fa-th-large::before {
  content: "\f009"; }
.fa-book-tanakh::before {
  content: "\f827"; }
.fa-tanakh::before {
  content: "\f827"; }
.fa-solar-system::before {
  content: "\e02f"; }
.fa-seal-question::before {
  content: "\e243"; }
.fa-phone-volume::before {
  content: "\f2a0"; }
.fa-volume-control-phone::before {
  content: "\f2a0"; }
.fa-disc-drive::before {
  content: "\f8b5"; }
.fa-hat-cowboy-side::before {
  content: "\f8c1"; }
.fa-table-rows::before {
  content: "\e292"; }
.fa-rows::before {
  content: "\e292"; }
.fa-location-exclamation::before {
  content: "\f608"; }
.fa-map-marker-exclamation::before {
  content: "\f608"; }
.fa-face-fearful::before {
  content: "\e375"; }
.fa-clipboard-user::before {
  content: "\f7f3"; }
.fa-bus-school::before {
  content: "\f5dd"; }
.fa-film-slash::before {
  content: "\e179"; }
.fa-square-arrow-down-right::before {
  content: "\e262"; }
.fa-book-sparkles::before {
  content: "\f6b8"; }
.fa-book-spells::before {
  content: "\f6b8"; }
.fa-washing-machine::before {
  content: "\f898"; }
.fa-washer::before {
  content: "\f898"; }
.fa-child::before {
  content: "\f1ae"; }
.fa-lira-sign::before {
  content: "\f195"; }
.fa-user-visor::before {
  content: "\e04c"; }
.fa-file-plus-minus::before {
  content: "\e177"; }
.fa-chess-clock-flip::before {
  content: "\f43e"; }
.fa-chess-clock-alt::before {
  content: "\f43e"; }
.fa-satellite::before {
  content: "\f7bf"; }
.fa-plane-lock::before {
  content: "\e558"; }
.fa-steering-wheel::before {
  content: "\f622"; }
.fa-tag::before {
  content: "\f02b"; }
.fa-stretcher::before {
  content: "\f825"; }
.fa-book-section::before {
  content: "\e0c1"; }
.fa-book-law::before {
  content: "\e0c1"; }
.fa-inboxes::before {
  content: "\e1bb"; }
.fa-coffee-bean::before {
  content: "\e13e"; }
.fa-brackets-curly::before {
  content: "\f7ea"; }
.fa-ellipsis-stroke-vertical::before {
  content: "\f39c"; }
.fa-ellipsis-v-alt::before {
  content: "\f39c"; }
.fa-comment::before {
  content: "\f075"; }
.fa-square-1::before {
  content: "\e256"; }
.fa-cake-candles::before {
  content: "\f1fd"; }
.fa-birthday-cake::before {
  content: "\f1fd"; }
.fa-cake::before {
  content: "\f1fd"; }
.fa-head-side::before {
  content: "\f6e9"; }
.fa-envelope::before {
  content: "\f0e0"; }
.fa-dolly-empty::before {
  content: "\f473"; }
.fa-face-tissue::before {
  content: "\e39c"; }
.fa-angles-up::before {
  content: "\f102"; }
.fa-angle-double-up::before {
  content: "\f102"; }
.fa-paperclip::before {
  content: "\f0c6"; }
.fa-chart-line-down::before {
  content: "\f64d"; }
.fa-arrow-right-to-city::before {
  content: "\e4b3"; }
.fa-lock-a::before {
  content: "\e422"; }
.fa-ribbon::before {
  content: "\f4d6"; }
.fa-lungs::before {
  content: "\f604"; }
.fa-person-pinball::before {
  content: "\e21d"; }
.fa-arrow-up-9-1::before {
  content: "\f887"; }
.fa-sort-numeric-up-alt::before {
  content: "\f887"; }
.fa-apple-core::before {
  content: "\e08f"; }
.fa-circle-y::before {
  content: "\e12f"; }
.fa-h6::before {
  content: "\e413"; }
.fa-litecoin-sign::before {
  content: "\e1d3"; }
.fa-circle-small::before {
  content: "\e122"; }
.fa-border-none::before {
  content: "\f850"; }
.fa-arrow-turn-down-left::before {
  content: "\e2e1"; }
.fa-circle-nodes::before {
  content: "\e4e2"; }
.fa-parachute-box::before {
  content: "\f4cd"; }
.fa-message-medical::before {
  content: "\f7f4"; }
.fa-comment-alt-medical::before {
  content: "\f7f4"; }
.fa-rugby-ball::before {
  content: "\e3c6"; }
.fa-comment-music::before {
  content: "\f8b0"; }
.fa-indent::before {
  content: "\f03c"; }
.fa-tree-deciduous::before {
  content: "\f400"; }
.fa-tree-alt::before {
  content: "\f400"; }
.fa-puzzle-piece-simple::before {
  content: "\e231"; }
.fa-puzzle-piece-alt::before {
  content: "\e231"; }
.fa-truck-field-un::before {
  content: "\e58e"; }
.fa-nfc-trash::before {
  content: "\e1fd"; }
.fa-hourglass::before {
  content: "\f254"; }
.fa-hourglass-empty::before {
  content: "\f254"; }
.fa-mountain::before {
  content: "\f6fc"; }
.fa-file-xmark::before {
  content: "\f317"; }
.fa-file-times::before {
  content: "\f317"; }
.fa-house-heart::before {
  content: "\f4c9"; }
.fa-home-heart::before {
  content: "\f4c9"; }
.fa-house-chimney-blank::before {
  content: "\e3b0"; }
.fa-meter-bolt::before {
  content: "\e1e9"; }
.fa-user-doctor::before {
  content: "\f0f0"; }
.fa-user-md::before {
  content: "\f0f0"; }
.fa-slash-back::before {
  content: "\5c"; }
.fa-circle-info::before {
  content: "\f05a"; }
.fa-info-circle::before {
  content: "\f05a"; }
.fa-fishing-rod::before {
  content: "\e3a8"; }
.fa-hammer-crash::before {
  content: "\e414"; }
.fa-message-heart::before {
  content: "\e5c9"; }
.fa-cloud-meatball::before {
  content: "\f73b"; }
.fa-camera-polaroid::before {
  content: "\f8aa"; }
.fa-camera::before {
  content: "\f030"; }
.fa-camera-alt::before {
  content: "\f030"; }
.fa-square-virus::before {
  content: "\e578"; }
.fa-cart-arrow-up::before {
  content: "\e3ee"; }
.fa-meteor::before {
  content: "\f753"; }
.fa-car-on::before {
  content: "\e4dd"; }
.fa-sleigh::before {
  content: "\f7cc"; }
.fa-arrow-down-1-9::before {
  content: "\f162"; }
.fa-sort-numeric-asc::before {
  content: "\f162"; }
.fa-sort-numeric-down::before {
  content: "\f162"; }
.fa-buoy-mooring::before {
  content: "\e5b6"; }
.fa-square-4::before {
  content: "\e259"; }
.fa-hand-holding-droplet::before {
  content: "\f4c1"; }
.fa-hand-holding-water::before {
  content: "\f4c1"; }
.fa-tricycle-adult::before {
  content: "\e5c4"; }
.fa-waveform::before {
  content: "\f8f1"; }
.fa-water::before {
  content: "\f773"; }
.fa-star-sharp-half-stroke::before {
  content: "\e28d"; }
.fa-star-sharp-half-alt::before {
  content: "\e28d"; }
.fa-nfc-signal::before {
  content: "\e1fb"; }
.fa-plane-prop::before {
  content: "\e22b"; }
.fa-calendar-check::before {
  content: "\f274"; }
.fa-clock-desk::before {
  content: "\e134"; }
.fa-calendar-clock::before {
  content: "\e0d2"; }
.fa-calendar-time::before {
  content: "\e0d2"; }
.fa-braille::before {
  content: "\f2a1"; }
.fa-prescription-bottle-medical::before {
  content: "\f486"; }
.fa-prescription-bottle-alt::before {
  content: "\f486"; }
.fa-plate-utensils::before {
  content: "\e43b"; }
.fa-family-pants::before {
  content: "\e302"; }
.fa-hose-reel::before {
  content: "\e41a"; }
.fa-house-window::before {
  content: "\e3b3"; }
.fa-landmark::before {
  content: "\f66f"; }
.fa-truck::before {
  content: "\f0d1"; }
.fa-crosshairs::before {
  content: "\f05b"; }
.fa-cloud-rainbow::before {
  content: "\f73e"; }
.fa-person-cane::before {
  content: "\e53c"; }
.fa-alien::before {
  content: "\f8f5"; }
.fa-tent::before {
  content: "\e57d"; }
.fa-vest-patches::before {
  content: "\e086"; }
.fa-people-dress-simple::before {
  content: "\e218"; }
.fa-check-double::before {
  content: "\f560"; }
.fa-arrow-down-a-z::before {
  content: "\f15d"; }
.fa-sort-alpha-asc::before {
  content: "\f15d"; }
.fa-sort-alpha-down::before {
  content: "\f15d"; }
.fa-bowling-ball-pin::before {
  content: "\e0c3"; }
.fa-bell-school-slash::before {
  content: "\f5d6"; }
.fa-plus-large::before {
  content: "\e59e"; }
.fa-money-bill-wheat::before {
  content: "\e52a"; }
.fa-camera-viewfinder::before {
  content: "\e0da"; }
.fa-screenshot::before {
  content: "\e0da"; }
.fa-message-music::before {
  content: "\f8af"; }
.fa-comment-alt-music::before {
  content: "\f8af"; }
.fa-car-building::before {
  content: "\f859"; }
.fa-border-bottom-right::before {
  content: "\f854"; }
.fa-border-style-alt::before {
  content: "\f854"; }
.fa-octagon::before {
  content: "\f306"; }
.fa-comment-arrow-up-right::before {
  content: "\e145"; }
.fa-octagon-divide::before {
  content: "\e203"; }
.fa-cookie::before {
  content: "\f563"; }
.fa-arrow-rotate-left::before {
  content: "\f0e2"; }
.fa-arrow-left-rotate::before {
  content: "\f0e2"; }
.fa-arrow-rotate-back::before {
  content: "\f0e2"; }
.fa-arrow-rotate-backward::before {
  content: "\f0e2"; }
.fa-undo::before {
  content: "\f0e2"; }
.fa-tv-music::before {
  content: "\f8e6"; }
.fa-hard-drive::before {
  content: "\f0a0"; }
.fa-hdd::before {
  content: "\f0a0"; }
.fa-reel::before {
  content: "\e238"; }
.fa-face-grin-squint-tears::before {
  content: "\f586"; }
.fa-grin-squint-tears::before {
  content: "\f586"; }
.fa-dumbbell::before {
  content: "\f44b"; }
.fa-rectangle-list::before {
  content: "\f022"; }
.fa-list-alt::before {
  content: "\f022"; }
.fa-tarp-droplet::before {
  content: "\e57c"; }
.fa-alarm-exclamation::before {
  content: "\f843"; }
.fa-house-medical-circle-check::before {
  content: "\e511"; }
.fa-traffic-cone::before {
  content: "\f636"; }
.fa-grate::before {
  content: "\e193"; }
.fa-arrow-down-right::before {
  content: "\e093"; }
.fa-person-skiing-nordic::before {
  content: "\f7ca"; }
.fa-skiing-nordic::before {
  content: "\f7ca"; }
.fa-calendar-plus::before {
  content: "\f271"; }
.fa-person-from-portal::before {
  content: "\e023"; }
.fa-portal-exit::before {
  content: "\e023"; }
.fa-plane-arrival::before {
  content: "\f5af"; }
.fa-cowbell-circle-plus::before {
  content: "\f8b4"; }
.fa-cowbell-more::before {
  content: "\f8b4"; }
.fa-circle-left::before {
  content: "\f359"; }
.fa-arrow-alt-circle-left::before {
  content: "\f359"; }
.fa-distribute-spacing-vertical::before {
  content: "\e366"; }
.fa-signal-bars-fair::before {
  content: "\f692"; }
.fa-signal-alt-2::before {
  content: "\f692"; }
.fa-sportsball::before {
  content: "\e44b"; }
.fa-game-console-handheld-crank::before {
  content: "\e5b9"; }
.fa-train-subway::before {
  content: "\f239"; }
.fa-subway::before {
  content: "\f239"; }
.fa-chart-gantt::before {
  content: "\e0e4"; }
.fa-face-smile-upside-down::before {
  content: "\e395"; }
.fa-ball-pile::before {
  content: "\f77e"; }
.fa-badge-dollar::before {
  content: "\f645"; }
.fa-money-bills-simple::before {
  content: "\e1f4"; }
.fa-money-bills-alt::before {
  content: "\e1f4"; }
.fa-list-timeline::before {
  content: "\e1d1"; }
.fa-indian-rupee-sign::before {
  content: "\e1bc"; }
.fa-indian-rupee::before {
  content: "\e1bc"; }
.fa-inr::before {
  content: "\e1bc"; }
.fa-crop-simple::before {
  content: "\f565"; }
.fa-crop-alt::before {
  content: "\f565"; }
.fa-money-bill-1::before {
  content: "\f3d1"; }
.fa-money-bill-alt::before {
  content: "\f3d1"; }
.fa-left-long::before {
  content: "\f30a"; }
.fa-long-arrow-alt-left::before {
  content: "\f30a"; }
.fa-keyboard-down::before {
  content: "\e1c2"; }
.fa-circle-up-right::before {
  content: "\e129"; }
.fa-cloud-bolt-moon::before {
  content: "\f76d"; }
.fa-thunderstorm-moon::before {
  content: "\f76d"; }
.fa-dna::before {
  content: "\f471"; }
.fa-virus-slash::before {
  content: "\e075"; }
.fa-bracket-round-right::before {
  content: "\29"; }
.fa-circle-5::before {
  content: "\e0f2"; }
.fa-minus::before {
  content: "\f068"; }
.fa-subtract::before {
  content: "\f068"; }
.fa-fire-flame::before {
  content: "\f6df"; }
.fa-flame::before {
  content: "\f6df"; }
.fa-right-to-line::before {
  content: "\f34c"; }
.fa-arrow-alt-to-right::before {
  content: "\f34c"; }
.fa-gif::before {
  content: "\e190"; }
.fa-chess::before {
  content: "\f439"; }
.fa-trash-slash::before {
  content: "\e2b3"; }
.fa-arrow-left-long::before {
  content: "\f177"; }
.fa-long-arrow-left::before {
  content: "\f177"; }
.fa-plug-circle-check::before {
  content: "\e55c"; }
.fa-font-case::before {
  content: "\f866"; }
.fa-street-view::before {
  content: "\f21d"; }
.fa-arrow-down-left::before {
  content: "\e091"; }
.fa-franc-sign::before {
  content: "\e18f"; }
.fa-flask-round-poison::before {
  content: "\f6e0"; }
.fa-flask-poison::before {
  content: "\f6e0"; }
.fa-volume-off::before {
  content: "\f026"; }
.fa-book-circle-arrow-right::before {
  content: "\e0bc"; }
.fa-chart-user::before {
  content: "\f6a3"; }
.fa-user-chart::before {
  content: "\f6a3"; }
.fa-hands-asl-interpreting::before {
  content: "\f2a3"; }
.fa-american-sign-language-interpreting::before {
  content: "\f2a3"; }
.fa-asl-interpreting::before {
  content: "\f2a3"; }
.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3"; }
.fa-presentation-screen::before {
  content: "\f685"; }
.fa-presentation::before {
  content: "\f685"; }
.fa-circle-bolt::before {
  content: "\e0fe"; }
.fa-face-smile-halo::before {
  content: "\e38f"; }
.fa-cart-circle-arrow-down::before {
  content: "\e3ef"; }
.fa-house-person-return::before {
  content: "\e011"; }
.fa-house-person-arrive::before {
  content: "\e011"; }
.fa-house-return::before {
  content: "\e011"; }
.fa-message-xmark::before {
  content: "\f4ab"; }
.fa-comment-alt-times::before {
  content: "\f4ab"; }
.fa-message-times::before {
  content: "\f4ab"; }
.fa-file-certificate::before {
  content: "\f5f3"; }
.fa-file-award::before {
  content: "\f5f3"; }
.fa-user-doctor-hair-long::before {
  content: "\e459"; }
.fa-camera-security::before {
  content: "\f8fe"; }
.fa-camera-home::before {
  content: "\f8fe"; }
.fa-gear::before {
  content: "\f013"; }
.fa-cog::before {
  content: "\f013"; }
.fa-droplet-slash::before {
  content: "\f5c7"; }
.fa-tint-slash::before {
  content: "\f5c7"; }
.fa-book-heart::before {
  content: "\f499"; }
.fa-mosque::before {
  content: "\f678"; }
.fa-duck::before {
  content: "\f6d8"; }
.fa-mosquito::before {
  content: "\e52b"; }
.fa-star-of-david::before {
  content: "\f69a"; }
.fa-flag-swallowtail::before {
  content: "\f74c"; }
.fa-flag-alt::before {
  content: "\f74c"; }
.fa-person-military-rifle::before {
  content: "\e54b"; }
.fa-car-garage::before {
  content: "\f5e2"; }
.fa-cart-shopping::before {
  content: "\f07a"; }
.fa-shopping-cart::before {
  content: "\f07a"; }
.fa-book-font::before {
  content: "\e0bf"; }
.fa-shield-plus::before {
  content: "\e24a"; }
.fa-vials::before {
  content: "\f493"; }
.fa-eye-dropper-full::before {
  content: "\e172"; }
.fa-distribute-spacing-horizontal::before {
  content: "\e365"; }
.fa-tablet-rugged::before {
  content: "\f48f"; }
.fa-temperature-snow::before {
  content: "\f768"; }
.fa-temperature-frigid::before {
  content: "\f768"; }
.fa-moped::before {
  content: "\e3b9"; }
.fa-face-smile-plus::before {
  content: "\f5b9"; }
.fa-smile-plus::before {
  content: "\f5b9"; }
.fa-radio-tuner::before {
  content: "\f8d8"; }
.fa-radio-alt::before {
  content: "\f8d8"; }
.fa-face-swear::before {
  content: "\e399"; }
.fa-water-arrow-down::before {
  content: "\f774"; }
.fa-water-lower::before {
  content: "\f774"; }
.fa-scanner-touchscreen::before {
  content: "\f48a"; }
.fa-circle-7::before {
  content: "\e0f4"; }
.fa-plug-circle-plus::before {
  content: "\e55f"; }
.fa-person-ski-jumping::before {
  content: "\f7c7"; }
.fa-ski-jump::before {
  content: "\f7c7"; }
.fa-place-of-worship::before {
  content: "\f67f"; }
.fa-water-arrow-up::before {
  content: "\f775"; }
.fa-water-rise::before {
  content: "\f775"; }
.fa-waveform-lines::before {
  content: "\f8f2"; }
.fa-waveform-path::before {
  content: "\f8f2"; }
.fa-split::before {
  content: "\e254"; }
.fa-film-canister::before {
  content: "\f8b7"; }
.fa-film-cannister::before {
  content: "\f8b7"; }
.fa-folder-xmark::before {
  content: "\f65f"; }
.fa-folder-times::before {
  content: "\f65f"; }
.fa-toilet-paper-blank::before {
  content: "\f71f"; }
.fa-toilet-paper-alt::before {
  content: "\f71f"; }
.fa-tablet-screen::before {
  content: "\f3fc"; }
.fa-tablet-android-alt::before {
  content: "\f3fc"; }
.fa-hexagon-vertical-nft-slanted::before {
  content: "\e506"; }
.fa-folder-music::before {
  content: "\e18d"; }
.fa-display-medical::before {
  content: "\e166"; }
.fa-desktop-medical::before {
  content: "\e166"; }
.fa-share-all::before {
  content: "\f367"; }
.fa-peapod::before {
  content: "\e31c"; }
.fa-chess-clock::before {
  content: "\f43d"; }
.fa-axe::before {
  content: "\f6b2"; }
.fa-square-d::before {
  content: "\e268"; }
.fa-grip-vertical::before {
  content: "\f58e"; }
.fa-mobile-signal-out::before {
  content: "\e1f0"; }
.fa-arrow-turn-up::before {
  content: "\f148"; }
.fa-level-up::before {
  content: "\f148"; }
.fa-u::before {
  content: "\55"; }
.fa-arrow-up-from-dotted-line::before {
  content: "\e09b"; }
.fa-square-root-variable::before {
  content: "\f698"; }
.fa-square-root-alt::before {
  content: "\f698"; }
.fa-light-switch-on::before {
  content: "\e019"; }
.fa-arrow-down-arrow-up::before {
  content: "\f883"; }
.fa-sort-alt::before {
  content: "\f883"; }
.fa-raindrops::before {
  content: "\f75c"; }
.fa-dash::before {
  content: "\e404"; }
.fa-minus-large::before {
  content: "\e404"; }
.fa-clock::before {
  content: "\f017"; }
.fa-clock-four::before {
  content: "\f017"; }
.fa-input-numeric::before {
  content: "\e1bd"; }
.fa-truck-tow::before {
  content: "\e2b8"; }
.fa-backward-step::before {
  content: "\f048"; }
.fa-step-backward::before {
  content: "\f048"; }
.fa-pallet::before {
  content: "\f482"; }
.fa-car-bolt::before {
  content: "\e341"; }
.fa-arrows-maximize::before {
  content: "\f31d"; }
.fa-expand-arrows::before {
  content: "\f31d"; }
.fa-faucet::before {
  content: "\e005"; }
.fa-cloud-sleet::before {
  content: "\f741"; }
.fa-lamp-street::before {
  content: "\e1c5"; }
.fa-list-radio::before {
  content: "\e1d0"; }
.fa-pen-nib-slash::before {
  content: "\e4a1"; }
.fa-baseball-bat-ball::before {
  content: "\f432"; }
.fa-square-up-left::before {
  content: "\e282"; }
.fa-overline::before {
  content: "\f876"; }
.fa-s::before {
  content: "\53"; }
.fa-timeline::before {
  content: "\e29c"; }
.fa-keyboard::before {
  content: "\f11c"; }
.fa-arrows-from-dotted-line::before {
  content: "\e0a3"; }
.fa-usb-drive::before {
  content: "\f8e9"; }
.fa-ballot::before {
  content: "\f732"; }
.fa-caret-down::before {
  content: "\f0d7"; }
.fa-location-dot-slash::before {
  content: "\f605"; }
.fa-map-marker-alt-slash::before {
  content: "\f605"; }
.fa-cards::before {
  content: "\e3ed"; }
.fa-house-chimney-medical::before {
  content: "\f7f2"; }
.fa-clinic-medical::before {
  content: "\f7f2"; }
.fa-boxing-glove::before {
  content: "\f438"; }
.fa-glove-boxing::before {
  content: "\f438"; }
.fa-temperature-three-quarters::before {
  content: "\f2c8"; }
.fa-temperature-3::before {
  content: "\f2c8"; }
.fa-thermometer-3::before {
  content: "\f2c8"; }
.fa-thermometer-three-quarters::before {
  content: "\f2c8"; }
.fa-bell-school::before {
  content: "\f5d5"; }
.fa-mobile-screen::before {
  content: "\f3cf"; }
.fa-mobile-android-alt::before {
  content: "\f3cf"; }
.fa-plane-up::before {
  content: "\e22d"; }
.fa-folder-heart::before {
  content: "\e189"; }
.fa-circle-location-arrow::before {
  content: "\f602"; }
.fa-location-circle::before {
  content: "\f602"; }
.fa-face-head-bandage::before {
  content: "\e37a"; }
.fa-sushi-roll::before {
  content: "\e48b"; }
.fa-maki-roll::before {
  content: "\e48b"; }
.fa-makizushi::before {
  content: "\e48b"; }
.fa-car-bump::before {
  content: "\f5e0"; }
.fa-piggy-bank::before {
  content: "\f4d3"; }
.fa-racquet::before {
  content: "\f45a"; }
.fa-car-mirrors::before {
  content: "\e343"; }
.fa-industry-windows::before {
  content: "\f3b3"; }
.fa-industry-alt::before {
  content: "\f3b3"; }
.fa-bolt-auto::before {
  content: "\e0b6"; }
.fa-battery-half::before {
  content: "\f242"; }
.fa-battery-3::before {
  content: "\f242"; }
.fa-flux-capacitor::before {
  content: "\f8ba"; }
.fa-mountain-city::before {
  content: "\e52e"; }
.fa-coins::before {
  content: "\f51e"; }
.fa-honey-pot::before {
  content: "\e418"; }
.fa-olive::before {
  content: "\e316"; }
.fa-khanda::before {
  content: "\f66d"; }
.fa-filter-list::before {
  content: "\e17c"; }
.fa-outlet::before {
  content: "\e01c"; }
.fa-sliders::before {
  content: "\f1de"; }
.fa-sliders-h::before {
  content: "\f1de"; }
.fa-cauldron::before {
  content: "\f6bf"; }
.fa-people::before {
  content: "\e216"; }
.fa-folder-tree::before {
  content: "\f802"; }
.fa-network-wired::before {
  content: "\f6ff"; }
.fa-croissant::before {
  content: "\f7f6"; }
.fa-map-pin::before {
  content: "\f276"; }
.fa-hamsa::before {
  content: "\f665"; }
.fa-cent-sign::before {
  content: "\e3f5"; }
.fa-swords-laser::before {
  content: "\e03d"; }
.fa-flask::before {
  content: "\f0c3"; }
.fa-person-pregnant::before {
  content: "\e31e"; }
.fa-square-u::before {
  content: "\e281"; }
.fa-wand-sparkles::before {
  content: "\f72b"; }
.fa-router::before {
  content: "\f8da"; }
.fa-ellipsis-vertical::before {
  content: "\f142"; }
.fa-ellipsis-v::before {
  content: "\f142"; }
.fa-sword-laser-alt::before {
  content: "\e03c"; }
.fa-ticket::before {
  content: "\f145"; }
.fa-power-off::before {
  content: "\f011"; }
.fa-coin::before {
  content: "\f85c"; }
.fa-laptop-slash::before {
  content: "\e1c7"; }
.fa-right-long::before {
  content: "\f30b"; }
.fa-long-arrow-alt-right::before {
  content: "\f30b"; }
.fa-circle-b::before {
  content: "\e0fd"; }
.fa-person-dress-simple::before {
  content: "\e21c"; }
.fa-pipe-collar::before {
  content: "\e437"; }
.fa-lights-holiday::before {
  content: "\f7b2"; }
.fa-citrus::before {
  content: "\e2f4"; }
.fa-flag-usa::before {
  content: "\f74d"; }
.fa-laptop-file::before {
  content: "\e51d"; }
.fa-tty::before {
  content: "\f1e4"; }
.fa-teletype::before {
  content: "\f1e4"; }
.fa-chart-tree-map::before {
  content: "\e0ea"; }
.fa-diagram-next::before {
  content: "\e476"; }
.fa-person-rifle::before {
  content: "\e54e"; }
.fa-clock-five-thirty::before {
  content: "\e34a"; }
.fa-pipe-valve::before {
  content: "\e439"; }
.fa-arrow-up-from-arc::before {
  content: "\e4b4"; }
.fa-face-spiral-eyes::before {
  content: "\e485"; }
.fa-compress-wide::before {
  content: "\f326"; }
.fa-circle-phone-hangup::before {
  content: "\e11d"; }
.fa-phone-circle-down::before {
  content: "\e11d"; }
.fa-house-medical-circle-exclamation::before {
  content: "\e512"; }
.fa-badminton::before {
  content: "\e33a"; }
.fa-closed-captioning::before {
  content: "\f20a"; }
.fa-person-hiking::before {
  content: "\f6ec"; }
.fa-hiking::before {
  content: "\f6ec"; }
.fa-right-from-line::before {
  content: "\f347"; }
.fa-arrow-alt-from-left::before {
  content: "\f347"; }
.fa-venus-double::before {
  content: "\f226"; }
.fa-images::before {
  content: "\f302"; }
.fa-calculator::before {
  content: "\f1ec"; }
.fa-shuttlecock::before {
  content: "\f45b"; }
.fa-user-hair::before {
  content: "\e45a"; }
.fa-eye-evil::before {
  content: "\f6db"; }
.fa-people-pulling::before {
  content: "\e535"; }
.fa-n::before {
  content: "\4e"; }
.fa-garage::before {
  content: "\e009"; }
.fa-cable-car::before {
  content: "\f7da"; }
.fa-tram::before {
  content: "\f7da"; }
.fa-shovel-snow::before {
  content: "\f7c3"; }
.fa-cloud-rain::before {
  content: "\f73d"; }
.fa-face-lying::before {
  content: "\e37e"; }
.fa-sprinkler::before {
  content: "\e035"; }
.fa-building-circle-xmark::before {
  content: "\e4d4"; }
.fa-person-sledding::before {
  content: "\f7cb"; }
.fa-sledding::before {
  content: "\f7cb"; }
.fa-game-console-handheld::before {
  content: "\f8bb"; }
.fa-ship::before {
  content: "\f21a"; }
.fa-clock-six-thirty::before {
  content: "\e353"; }
.fa-battery-slash::before {
  content: "\f377"; }
.fa-tugrik-sign::before {
  content: "\e2ba"; }
.fa-arrows-down-to-line::before {
  content: "\e4b8"; }
.fa-download::before {
  content: "\f019"; }
.fa-shelves::before {
  content: "\f480"; }
.fa-inventory::before {
  content: "\f480"; }
.fa-cloud-snow::before {
  content: "\f742"; }
.fa-face-grin::before {
  content: "\f580"; }
.fa-grin::before {
  content: "\f580"; }
.fa-delete-left::before {
  content: "\f55a"; }
.fa-backspace::before {
  content: "\f55a"; }
.fa-oven::before {
  content: "\e01d"; }
.fa-eye-dropper::before {
  content: "\f1fb"; }
.fa-eye-dropper-empty::before {
  content: "\f1fb"; }
.fa-eyedropper::before {
  content: "\f1fb"; }
.fa-comment-captions::before {
  content: "\e146"; }
.fa-comments-question::before {
  content: "\e14e"; }
.fa-scribble::before {
  content: "\e23f"; }
.fa-rotate-exclamation::before {
  content: "\e23c"; }
.fa-file-circle-check::before {
  content: "\e5a0"; }
.fa-glass::before {
  content: "\f804"; }
.fa-loader::before {
  content: "\e1d4"; }
.fa-forward::before {
  content: "\f04e"; }
.fa-user-pilot::before {
  content: "\e2c0"; }
.fa-mobile::before {
  content: "\f3ce"; }
.fa-mobile-android::before {
  content: "\f3ce"; }
.fa-mobile-phone::before {
  content: "\f3ce"; }
.fa-code-pull-request-closed::before {
  content: "\e3f9"; }
.fa-face-meh::before {
  content: "\f11a"; }
.fa-meh::before {
  content: "\f11a"; }
.fa-align-center::before {
  content: "\f037"; }
.fa-book-skull::before {
  content: "\f6b7"; }
.fa-book-dead::before {
  content: "\f6b7"; }
.fa-id-card::before {
  content: "\f2c2"; }
.fa-drivers-license::before {
  content: "\f2c2"; }
.fa-face-dotted::before {
  content: "\e47f"; }
.fa-face-worried::before {
  content: "\e3a3"; }
.fa-outdent::before {
  content: "\f03b"; }
.fa-dedent::before {
  content: "\f03b"; }
.fa-heart-circle-exclamation::before {
  content: "\e4fe"; }
.fa-house::before {
  content: "\f015"; }
.fa-home::before {
  content: "\f015"; }
.fa-home-alt::before {
  content: "\f015"; }
.fa-home-lg-alt::before {
  content: "\f015"; }
.fa-vector-circle::before {
  content: "\e2c6"; }
.fa-car-circle-bolt::before {
  content: "\e342"; }
.fa-calendar-week::before {
  content: "\f784"; }
.fa-flying-disc::before {
  content: "\e3a9"; }
.fa-laptop-medical::before {
  content: "\f812"; }
.fa-square-down-right::before {
  content: "\e26c"; }
.fa-b::before {
  content: "\42"; }
.fa-seat-airline::before {
  content: "\e244"; }
.fa-moon-over-sun::before {
  content: "\f74a"; }
.fa-eclipse-alt::before {
  content: "\f74a"; }
.fa-pipe::before {
  content: "\7c"; }
.fa-file-medical::before {
  content: "\f477"; }
.fa-potato::before {
  content: "\e440"; }
.fa-dice-one::before {
  content: "\f525"; }
.fa-circle-a::before {
  content: "\e0f7"; }
.fa-helmet-battle::before {
  content: "\f6eb"; }
.fa-butter::before {
  content: "\e3e4"; }
.fa-blanket-fire::before {
  content: "\e3da"; }
.fa-kiwi-bird::before {
  content: "\f535"; }
.fa-castle::before {
  content: "\e0de"; }
.fa-golf-club::before {
  content: "\f451"; }
.fa-arrow-right-arrow-left::before {
  content: "\f0ec"; }
.fa-exchange::before {
  content: "\f0ec"; }
.fa-rotate-right::before {
  content: "\f2f9"; }
.fa-redo-alt::before {
  content: "\f2f9"; }
.fa-rotate-forward::before {
  content: "\f2f9"; }
.fa-utensils::before {
  content: "\f2e7"; }
.fa-cutlery::before {
  content: "\f2e7"; }
.fa-arrow-up-wide-short::before {
  content: "\f161"; }
.fa-sort-amount-up::before {
  content: "\f161"; }
.fa-balloons::before {
  content: "\e2e4"; }
.fa-mill-sign::before {
  content: "\e1ed"; }
.fa-bowl-rice::before {
  content: "\e2eb"; }
.fa-timeline-arrow::before {
  content: "\e29d"; }
.fa-skull::before {
  content: "\f54c"; }
.fa-game-board-simple::before {
  content: "\f868"; }
.fa-game-board-alt::before {
  content: "\f868"; }
.fa-circle-video::before {
  content: "\e12b"; }
.fa-video-circle::before {
  content: "\e12b"; }
.fa-chart-scatter-bubble::before {
  content: "\e0e9"; }
.fa-house-turret::before {
  content: "\e1b4"; }
.fa-banana::before {
  content: "\e2e5"; }
.fa-hand-holding-skull::before {
  content: "\e1a4"; }
.fa-people-dress::before {
  content: "\e217"; }
.fa-loveseat::before {
  content: "\f4cc"; }
.fa-couch-small::before {
  content: "\f4cc"; }
.fa-tower-broadcast::before {
  content: "\f519"; }
.fa-broadcast-tower::before {
  content: "\f519"; }
.fa-truck-pickup::before {
  content: "\f63c"; }
.fa-block-quote::before {
  content: "\e0b5"; }
.fa-up-long::before {
  content: "\f30c"; }
.fa-long-arrow-alt-up::before {
  content: "\f30c"; }
.fa-stop::before {
  content: "\f04d"; }
.fa-code-merge::before {
  content: "\f387"; }
.fa-money-check-dollar-pen::before {
  content: "\f873"; }
.fa-money-check-edit-alt::before {
  content: "\f873"; }
.fa-up-from-line::before {
  content: "\f346"; }
.fa-arrow-alt-from-bottom::before {
  content: "\f346"; }
.fa-upload::before {
  content: "\f093"; }
.fa-hurricane::before {
  content: "\f751"; }
.fa-people-pants::before {
  content: "\e219"; }
.fa-mound::before {
  content: "\e52d"; }
.fa-windsock::before {
  content: "\f777"; }
.fa-circle-half::before {
  content: "\e110"; }
.fa-brake-warning::before {
  content: "\e0c7"; }
.fa-toilet-portable::before {
  content: "\e583"; }
.fa-compact-disc::before {
  content: "\f51f"; }
.fa-file-arrow-down::before {
  content: "\f56d"; }
.fa-file-download::before {
  content: "\f56d"; }
.fa-saxophone-fire::before {
  content: "\f8db"; }
.fa-sax-hot::before {
  content: "\f8db"; }
.fa-camera-web-slash::before {
  content: "\f833"; }
.fa-webcam-slash::before {
  content: "\f833"; }
.fa-folder-medical::before {
  content: "\e18c"; }
.fa-folder-gear::before {
  content: "\e187"; }
.fa-folder-cog::before {
  content: "\e187"; }
.fa-hand-wave::before {
  content: "\e1a7"; }
.fa-arrow-up-arrow-down::before {
  content: "\e099"; }
.fa-sort-up-down::before {
  content: "\e099"; }
.fa-caravan::before {
  content: "\f8ff"; }
.fa-shield-cat::before {
  content: "\e572"; }
.fa-message-slash::before {
  content: "\f4a9"; }
.fa-comment-alt-slash::before {
  content: "\f4a9"; }
.fa-bolt::before {
  content: "\f0e7"; }
.fa-zap::before {
  content: "\f0e7"; }
.fa-trash-can-check::before {
  content: "\e2a9"; }
.fa-glass-water::before {
  content: "\e4f4"; }
.fa-oil-well::before {
  content: "\e532"; }
.fa-person-simple::before {
  content: "\e220"; }
.fa-vault::before {
  content: "\e2c5"; }
.fa-mars::before {
  content: "\f222"; }
.fa-toilet::before {
  content: "\f7d8"; }
.fa-plane-circle-xmark::before {
  content: "\e557"; }
.fa-yen-sign::before {
  content: "\f157"; }
.fa-cny::before {
  content: "\f157"; }
.fa-jpy::before {
  content: "\f157"; }
.fa-rmb::before {
  content: "\f157"; }
.fa-yen::before {
  content: "\f157"; }
.fa-notes::before {
  content: "\e202"; }
.fa-ruble-sign::before {
  content: "\f158"; }
.fa-rouble::before {
  content: "\f158"; }
.fa-rub::before {
  content: "\f158"; }
.fa-ruble::before {
  content: "\f158"; }
.fa-trash-undo::before {
  content: "\f895"; }
.fa-trash-arrow-turn-left::before {
  content: "\f895"; }
.fa-champagne-glass::before {
  content: "\f79e"; }
.fa-glass-champagne::before {
  content: "\f79e"; }
.fa-objects-align-center-horizontal::before {
  content: "\e3bc"; }
.fa-sun::before {
  content: "\f185"; }
.fa-trash-can-slash::before {
  content: "\e2ad"; }
.fa-trash-alt-slash::before {
  content: "\e2ad"; }
.fa-screen-users::before {
  content: "\f63d"; }
.fa-users-class::before {
  content: "\f63d"; }
.fa-guitar::before {
  content: "\f7a6"; }
.fa-square-arrow-left::before {
  content: "\f33a"; }
.fa-arrow-square-left::before {
  content: "\f33a"; }
.fa-square-8::before {
  content: "\e25d"; }
.fa-face-smile-hearts::before {
  content: "\e390"; }
.fa-brackets-square::before {
  content: "\f7e9"; }
.fa-brackets::before {
  content: "\f7e9"; }
.fa-laptop-arrow-down::before {
  content: "\e1c6"; }
.fa-hockey-stick-puck::before {
  content: "\e3ae"; }
.fa-house-tree::before {
  content: "\e1b3"; }
.fa-signal-fair::before {
  content: "\f68d"; }
.fa-signal-2::before {
  content: "\f68d"; }
.fa-face-laugh-wink::before {
  content: "\f59c"; }
.fa-laugh-wink::before {
  content: "\f59c"; }
.fa-circle-dollar::before {
  content: "\f2e8"; }
.fa-dollar-circle::before {
  content: "\f2e8"; }
.fa-usd-circle::before {
  content: "\f2e8"; }
.fa-horse-head::before {
  content: "\f7ab"; }
.fa-arrows-repeat::before {
  content: "\f364"; }
.fa-repeat-alt::before {
  content: "\f364"; }
.fa-bore-hole::before {
  content: "\e4c3"; }
.fa-industry::before {
  content: "\f275"; }
.fa-image-polaroid::before {
  content: "\f8c4"; }
.fa-wave-triangle::before {
  content: "\f89a"; }
.fa-circle-down::before {
  content: "\f358"; }
.fa-arrow-alt-circle-down::before {
  content: "\f358"; }
.fa-grill::before {
  content: "\e5a3"; }
.fa-arrows-turn-to-dots::before {
  content: "\e4c1"; }
.fa-chart-mixed::before {
  content: "\f643"; }
.fa-analytics::before {
  content: "\f643"; }
.fa-florin-sign::before {
  content: "\e184"; }
.fa-arrow-down-short-wide::before {
  content: "\f884"; }
.fa-sort-amount-desc::before {
  content: "\f884"; }
.fa-sort-amount-down-alt::before {
  content: "\f884"; }
.fa-less-than::before {
  content: "\3c"; }
.fa-display-code::before {
  content: "\e165"; }
.fa-desktop-code::before {
  content: "\e165"; }
.fa-face-drooling::before {
  content: "\e372"; }
.fa-oil-temperature::before {
  content: "\f614"; }
.fa-oil-temp::before {
  content: "\f614"; }
.fa-square-question::before {
  content: "\f2fd"; }
.fa-question-square::before {
  content: "\f2fd"; }
.fa-air-conditioner::before {
  content: "\f8f4"; }
.fa-angle-down::before {
  content: "\f107"; }
.fa-mountains::before {
  content: "\f6fd"; }
.fa-omega::before {
  content: "\f67a"; }
.fa-car-tunnel::before {
  content: "\e4de"; }
.fa-person-dolly-empty::before {
  content: "\f4d1"; }
.fa-pan-food::before {
  content: "\e42b"; }
.fa-head-side-cough::before {
  content: "\e061"; }
.fa-grip-lines::before {
  content: "\f7a4"; }
.fa-thumbs-down::before {
  content: "\f165"; }
.fa-user-lock::before {
  content: "\f502"; }
.fa-arrow-right-long::before {
  content: "\f178"; }
.fa-long-arrow-right::before {
  content: "\f178"; }
.fa-tickets-airline::before {
  content: "\e29b"; }
.fa-anchor-circle-xmark::before {
  content: "\e4ac"; }
.fa-ellipsis::before {
  content: "\f141"; }
.fa-ellipsis-h::before {
  content: "\f141"; }
.fa-nfc-slash::before {
  content: "\e1fc"; }
.fa-chess-pawn::before {
  content: "\f443"; }
.fa-kit-medical::before {
  content: "\f479"; }
.fa-first-aid::before {
  content: "\f479"; }
.fa-grid-2-plus::before {
  content: "\e197"; }
.fa-bells::before {
  content: "\f77f"; }
.fa-person-through-window::before {
  content: "\e5a9"; }
.fa-toolbox::before {
  content: "\f552"; }
.fa-envelope-dot::before {
  content: "\e16f"; }
.fa-envelope-badge::before {
  content: "\e16f"; }
.fa-hands-holding-circle::before {
  content: "\e4fb"; }
.fa-bug::before {
  content: "\f188"; }
.fa-bowl-chopsticks::before {
  content: "\e2e9"; }
.fa-credit-card::before {
  content: "\f09d"; }
.fa-credit-card-alt::before {
  content: "\f09d"; }
.fa-circle-s::before {
  content: "\e121"; }
.fa-box-ballot::before {
  content: "\f735"; }
.fa-car::before {
  content: "\f1b9"; }
.fa-automobile::before {
  content: "\f1b9"; }
.fa-hand-holding-hand::before {
  content: "\e4f7"; }
.fa-user-tie-hair::before {
  content: "\e45f"; }
.fa-podium-star::before {
  content: "\f758"; }
.fa-user-hair-mullet::before {
  content: "\e45c"; }
.fa-business-front::before {
  content: "\e45c"; }
.fa-party-back::before {
  content: "\e45c"; }
.fa-trian-balbot::before {
  content: "\e45c"; }
.fa-microphone-stand::before {
  content: "\f8cb"; }
.fa-book-open-reader::before {
  content: "\f5da"; }
.fa-book-reader::before {
  content: "\f5da"; }
.fa-family-dress::before {
  content: "\e301"; }
.fa-circle-x::before {
  content: "\e12e"; }
.fa-cabin::before {
  content: "\e46d"; }
.fa-mountain-sun::before {
  content: "\e52f"; }
.fa-chart-simple-horizontal::before {
  content: "\e474"; }
.fa-arrows-left-right-to-line::before {
  content: "\e4ba"; }
.fa-hand-back-point-left::before {
  content: "\e19f"; }
.fa-message-dots::before {
  content: "\f4a3"; }
.fa-comment-alt-dots::before {
  content: "\f4a3"; }
.fa-messaging::before {
  content: "\f4a3"; }
.fa-file-heart::before {
  content: "\e176"; }
.fa-beer-mug::before {
  content: "\e0b3"; }
.fa-beer-foam::before {
  content: "\e0b3"; }
.fa-dice-d20::before {
  content: "\f6cf"; }
.fa-drone::before {
  content: "\f85f"; }
.fa-truck-droplet::before {
  content: "\e58c"; }
.fa-file-circle-xmark::before {
  content: "\e5a1"; }
.fa-temperature-arrow-up::before {
  content: "\e040"; }
.fa-temperature-up::before {
  content: "\e040"; }
.fa-medal::before {
  content: "\f5a2"; }
.fa-bed::before {
  content: "\f236"; }
.fa-book-copy::before {
  content: "\e0be"; }
.fa-square-h::before {
  content: "\f0fd"; }
.fa-h-square::before {
  content: "\f0fd"; }
.fa-square-c::before {
  content: "\e266"; }
.fa-clock-two::before {
  content: "\e35a"; }
.fa-square-ellipsis-vertical::before {
  content: "\e26f"; }
.fa-podcast::before {
  content: "\f2ce"; }
.fa-bee::before {
  content: "\e0b2"; }
.fa-temperature-full::before {
  content: "\f2c7"; }
.fa-temperature-4::before {
  content: "\f2c7"; }
.fa-thermometer-4::before {
  content: "\f2c7"; }
.fa-thermometer-full::before {
  content: "\f2c7"; }
.fa-bell::before {
  content: "\f0f3"; }
.fa-candy-bar::before {
  content: "\e3e8"; }
.fa-chocolate-bar::before {
  content: "\e3e8"; }
.fa-xmark-large::before {
  content: "\e59b"; }
.fa-pinata::before {
  content: "\e3c3"; }
.fa-arrows-from-line::before {
  content: "\e0a4"; }
.fa-superscript::before {
  content: "\f12b"; }
.fa-bowl-spoon::before {
  content: "\e3e0"; }
.fa-hexagon-check::before {
  content: "\e416"; }
.fa-plug-circle-xmark::before {
  content: "\e560"; }
.fa-star-of-life::before {
  content: "\f621"; }
.fa-phone-slash::before {
  content: "\f3dd"; }
.fa-traffic-light-stop::before {
  content: "\f63a"; }
.fa-paint-roller::before {
  content: "\f5aa"; }
.fa-accent-grave::before {
  content: "\60"; }
.fa-handshake-angle::before {
  content: "\f4c4"; }
.fa-hands-helping::before {
  content: "\f4c4"; }
.fa-circle-0::before {
  content: "\e0ed"; }
.fa-dial-med-low::before {
  content: "\e160"; }
.fa-location-dot::before {
  content: "\f3c5"; }
.fa-map-marker-alt::before {
  content: "\f3c5"; }
.fa-crab::before {
  content: "\e3ff"; }
.fa-box-open-full::before {
  content: "\f49c"; }
.fa-box-full::before {
  content: "\f49c"; }
.fa-file::before {
  content: "\f15b"; }
.fa-greater-than::before {
  content: "\3e"; }
.fa-quotes::before {
  content: "\e234"; }
.fa-pretzel::before {
  content: "\e441"; }
.fa-person-swimming::before {
  content: "\f5c4"; }
.fa-swimmer::before {
  content: "\f5c4"; }
.fa-arrow-down::before {
  content: "\f063"; }
.fa-user-robot-xmarks::before {
  content: "\e4a7"; }
.fa-message-quote::before {
  content: "\e1e4"; }
.fa-comment-alt-quote::before {
  content: "\e1e4"; }
.fa-candy-corn::before {
  content: "\f6bd"; }
.fa-folder-magnifying-glass::before {
  content: "\e18b"; }
.fa-folder-search::before {
  content: "\e18b"; }
.fa-notebook::before {
  content: "\e201"; }
.fa-droplet::before {
  content: "\f043"; }
.fa-tint::before {
  content: "\f043"; }
.fa-bullseye-pointer::before {
  content: "\f649"; }
.fa-eraser::before {
  content: "\f12d"; }
.fa-hexagon-image::before {
  content: "\e504"; }
.fa-earth-americas::before {
  content: "\f57d"; }
.fa-earth::before {
  content: "\f57d"; }
.fa-earth-america::before {
  content: "\f57d"; }
.fa-globe-americas::before {
  content: "\f57d"; }
.fa-crate-apple::before {
  content: "\f6b1"; }
.fa-apple-crate::before {
  content: "\f6b1"; }
.fa-person-burst::before {
  content: "\e53b"; }
.fa-game-board::before {
  content: "\f867"; }
.fa-hat-chef::before {
  content: "\f86b"; }
.fa-hand-back-point-right::before {
  content: "\e1a1"; }
.fa-dove::before {
  content: "\f4ba"; }
.fa-snowflake-droplets::before {
  content: "\e5c1"; }
.fa-battery-empty::before {
  content: "\f244"; }
.fa-battery-0::before {
  content: "\f244"; }
.fa-grid-4::before {
  content: "\e198"; }
.fa-socks::before {
  content: "\f696"; }
.fa-face-sunglasses::before {
  content: "\e398"; }
.fa-inbox::before {
  content: "\f01c"; }
.fa-square-0::before {
  content: "\e255"; }
.fa-section::before {
  content: "\e447"; }
.fa-square-this-way-up::before {
  content: "\f49f"; }
.fa-box-up::before {
  content: "\f49f"; }
.fa-gauge-high::before {
  content: "\f625"; }
.fa-tachometer-alt::before {
  content: "\f625"; }
.fa-tachometer-alt-fast::before {
  content: "\f625"; }
.fa-square-ampersand::before {
  content: "\e260"; }
.fa-envelope-open-text::before {
  content: "\f658"; }
.fa-lamp-desk::before {
  content: "\e014"; }
.fa-hospital::before {
  content: "\f0f8"; }
.fa-hospital-alt::before {
  content: "\f0f8"; }
.fa-hospital-wide::before {
  content: "\f0f8"; }
.fa-poll-people::before {
  content: "\f759"; }
.fa-whiskey-glass-ice::before {
  content: "\f7a1"; }
.fa-glass-whiskey-rocks::before {
  content: "\f7a1"; }
.fa-wine-bottle::before {
  content: "\f72f"; }
.fa-chess-rook::before {
  content: "\f447"; }
.fa-user-bounty-hunter::before {
  content: "\e2bf"; }
.fa-bars-staggered::before {
  content: "\f550"; }
.fa-reorder::before {
  content: "\f550"; }
.fa-stream::before {
  content: "\f550"; }
.fa-diagram-sankey::before {
  content: "\e158"; }
.fa-cloud-hail-mixed::before {
  content: "\f73a"; }
.fa-circle-up-left::before {
  content: "\e128"; }
.fa-dharmachakra::before {
  content: "\f655"; }
.fa-objects-align-left::before {
  content: "\e3be"; }
.fa-oil-can-drip::before {
  content: "\e205"; }
.fa-face-smiling-hands::before {
  content: "\e396"; }
.fa-broccoli::before {
  content: "\e3e2"; }
.fa-route-interstate::before {
  content: "\f61b"; }
.fa-ear-muffs::before {
  content: "\f795"; }
.fa-hotdog::before {
  content: "\f80f"; }
.fa-transporter-empty::before {
  content: "\e046"; }
.fa-person-walking-with-cane::before {
  content: "\f29d"; }
.fa-blind::before {
  content: "\f29d"; }
.fa-angle-90::before {
  content: "\e08d"; }
.fa-rectangle-terminal::before {
  content: "\e236"; }
.fa-kite::before {
  content: "\f6f4"; }
.fa-drum::before {
  content: "\f569"; }
.fa-scrubber::before {
  content: "\f2f8"; }
.fa-ice-cream::before {
  content: "\f810"; }
.fa-heart-circle-bolt::before {
  content: "\e4fc"; }
.fa-fish-bones::before {
  content: "\e304"; }
.fa-deer-rudolph::before {
  content: "\f78f"; }
.fa-fax::before {
  content: "\f1ac"; }
.fa-paragraph::before {
  content: "\f1dd"; }
.fa-head-side-heart::before {
  content: "\e1aa"; }
.fa-square-e::before {
  content: "\e26d"; }
.fa-meter-fire::before {
  content: "\e1eb"; }
.fa-cloud-hail::before {
  content: "\f739"; }
.fa-check-to-slot::before {
  content: "\f772"; }
.fa-vote-yea::before {
  content: "\f772"; }
.fa-money-from-bracket::before {
  content: "\e312"; }
.fa-star-half::before {
  content: "\f089"; }
.fa-car-bus::before {
  content: "\f85a"; }
.fa-speaker::before {
  content: "\f8df"; }
.fa-timer::before {
  content: "\e29e"; }
.fa-boxes-stacked::before {
  content: "\f468"; }
.fa-boxes::before {
  content: "\f468"; }
.fa-boxes-alt::before {
  content: "\f468"; }
.fa-grill-hot::before {
  content: "\e5a5"; }
.fa-ballot-check::before {
  content: "\f733"; }
.fa-link::before {
  content: "\f0c1"; }
.fa-chain::before {
  content: "\f0c1"; }
.fa-ear-listen::before {
  content: "\f2a2"; }
.fa-assistive-listening-systems::before {
  content: "\f2a2"; }
.fa-file-minus::before {
  content: "\f318"; }
.fa-tree-city::before {
  content: "\e587"; }
.fa-play::before {
  content: "\f04b"; }
.fa-font::before {
  content: "\f031"; }
.fa-cup-togo::before {
  content: "\f6c5"; }
.fa-coffee-togo::before {
  content: "\f6c5"; }
.fa-square-down-left::before {
  content: "\e26b"; }
.fa-burger-lettuce::before {
  content: "\e3e3"; }
.fa-rupiah-sign::before {
  content: "\e23d"; }
.fa-magnifying-glass::before {
  content: "\f002"; }
.fa-search::before {
  content: "\f002"; }
.fa-table-tennis-paddle-ball::before {
  content: "\f45d"; }
.fa-ping-pong-paddle-ball::before {
  content: "\f45d"; }
.fa-table-tennis::before {
  content: "\f45d"; }
.fa-person-dots-from-line::before {
  content: "\f470"; }
.fa-diagnoses::before {
  content: "\f470"; }
.fa-chevrons-down::before {
  content: "\f322"; }
.fa-chevron-double-down::before {
  content: "\f322"; }
.fa-trash-can-arrow-up::before {
  content: "\f82a"; }
.fa-trash-restore-alt::before {
  content: "\f82a"; }
.fa-signal-good::before {
  content: "\f68e"; }
.fa-signal-3::before {
  content: "\f68e"; }
.fa-location-question::before {
  content: "\f60b"; }
.fa-map-marker-question::before {
  content: "\f60b"; }
.fa-floppy-disk-circle-xmark::before {
  content: "\e181"; }
.fa-floppy-disk-times::before {
  content: "\e181"; }
.fa-save-circle-xmark::before {
  content: "\e181"; }
.fa-save-times::before {
  content: "\e181"; }
.fa-naira-sign::before {
  content: "\e1f6"; }
.fa-peach::before {
  content: "\e20b"; }
.fa-taxi-bus::before {
  content: "\e298"; }
.fa-bracket-curly::before {
  content: "\7b"; }
.fa-bracket-curly-left::before {
  content: "\7b"; }
.fa-lobster::before {
  content: "\e421"; }
.fa-cart-flatbed-empty::before {
  content: "\f476"; }
.fa-dolly-flatbed-empty::before {
  content: "\f476"; }
.fa-colon::before {
  content: "\3a"; }
.fa-cart-arrow-down::before {
  content: "\f218"; }
.fa-wand::before {
  content: "\f72a"; }
.fa-walkie-talkie::before {
  content: "\f8ef"; }
.fa-file-pen::before {
  content: "\f31c"; }
.fa-file-edit::before {
  content: "\f31c"; }
.fa-receipt::before {
  content: "\f543"; }
.fa-table-picnic::before {
  content: "\e32d"; }
.fa-square-pen::before {
  content: "\f14b"; }
.fa-pen-square::before {
  content: "\f14b"; }
.fa-pencil-square::before {
  content: "\f14b"; }
.fa-circle-microphone-lines::before {
  content: "\e117"; }
.fa-microphone-circle-alt::before {
  content: "\e117"; }
.fa-display-slash::before {
  content: "\e2fa"; }
.fa-desktop-slash::before {
  content: "\e2fa"; }
.fa-suitcase-rolling::before {
  content: "\f5c1"; }
.fa-person-circle-exclamation::before {
  content: "\e53f"; }
.fa-transporter-2::before {
  content: "\e044"; }
.fa-hands-holding-diamond::before {
  content: "\f47c"; }
.fa-hand-receiving::before {
  content: "\f47c"; }
.fa-money-bill-simple-wave::before {
  content: "\e1f2"; }
.fa-chevron-down::before {
  content: "\f078"; }
.fa-battery-full::before {
  content: "\f240"; }
.fa-battery::before {
  content: "\f240"; }
.fa-battery-5::before {
  content: "\f240"; }
.fa-bell-plus::before {
  content: "\f849"; }
.fa-book-arrow-right::before {
  content: "\e0b9"; }
.fa-hospitals::before {
  content: "\f80e"; }
.fa-club::before {
  content: "\f327"; }
.fa-skull-crossbones::before {
  content: "\f714"; }
.fa-droplet-degree::before {
  content: "\f748"; }
.fa-dewpoint::before {
  content: "\f748"; }
.fa-code-compare::before {
  content: "\e13a"; }
.fa-list-ul::before {
  content: "\f0ca"; }
.fa-list-dots::before {
  content: "\f0ca"; }
.fa-hand-holding-magic::before {
  content: "\f6e5"; }
.fa-watermelon-slice::before {
  content: "\e337"; }
.fa-circle-ellipsis::before {
  content: "\e10a"; }
.fa-school-lock::before {
  content: "\e56f"; }
.fa-tower-cell::before {
  content: "\e585"; }
.fa-sd-cards::before {
  content: "\e240"; }
.fa-down-long::before {
  content: "\f309"; }
.fa-long-arrow-alt-down::before {
  content: "\f309"; }
.fa-envelopes::before {
  content: "\e170"; }
.fa-phone-office::before {
  content: "\f67d"; }
.fa-ranking-star::before {
  content: "\e561"; }
.fa-chess-king::before {
  content: "\f43f"; }
.fa-nfc-pen::before {
  content: "\e1fa"; }
.fa-person-harassing::before {
  content: "\e549"; }
.fa-hat-winter::before {
  content: "\f7a8"; }
.fa-brazilian-real-sign::before {
  content: "\e46c"; }
.fa-landmark-dome::before {
  content: "\f752"; }
.fa-landmark-alt::before {
  content: "\f752"; }
.fa-bone-break::before {
  content: "\f5d8"; }
.fa-arrow-up::before {
  content: "\f062"; }
.fa-down-from-dotted-line::before {
  content: "\e407"; }
.fa-tv::before {
  content: "\f26c"; }
.fa-television::before {
  content: "\f26c"; }
.fa-tv-alt::before {
  content: "\f26c"; }
.fa-border-left::before {
  content: "\f84f"; }
.fa-circle-divide::before {
  content: "\e106"; }
.fa-shrimp::before {
  content: "\e448"; }
.fa-list-check::before {
  content: "\f0ae"; }
.fa-tasks::before {
  content: "\f0ae"; }
.fa-diagram-subtask::before {
  content: "\e479"; }
.fa-jug-detergent::before {
  content: "\e519"; }
.fa-circle-user::before {
  content: "\f2bd"; }
.fa-user-circle::before {
  content: "\f2bd"; }
.fa-square-y::before {
  content: "\e287"; }
.fa-user-doctor-hair::before {
  content: "\e458"; }
.fa-planet-ringed::before {
  content: "\e020"; }
.fa-mushroom::before {
  content: "\e425"; }
.fa-user-shield::before {
  content: "\f505"; }
.fa-megaphone::before {
  content: "\f675"; }
.fa-circle-exclamation-check::before {
  content: "\e10d"; }
.fa-wind::before {
  content: "\f72e"; }
.fa-box-dollar::before {
  content: "\f4a0"; }
.fa-box-usd::before {
  content: "\f4a0"; }
.fa-car-burst::before {
  content: "\f5e1"; }
.fa-car-crash::before {
  content: "\f5e1"; }
.fa-y::before {
  content: "\59"; }
.fa-user-headset::before {
  content: "\f82d"; }
.fa-arrows-retweet::before {
  content: "\f361"; }
.fa-retweet-alt::before {
  content: "\f361"; }
.fa-person-snowboarding::before {
  content: "\f7ce"; }
.fa-snowboarding::before {
  content: "\f7ce"; }
.fa-square-chevron-right::before {
  content: "\f32b"; }
.fa-chevron-square-right::before {
  content: "\f32b"; }
.fa-lacrosse-stick-ball::before {
  content: "\e3b6"; }
.fa-truck-fast::before {
  content: "\f48b"; }
.fa-shipping-fast::before {
  content: "\f48b"; }
.fa-user-magnifying-glass::before {
  content: "\e5c5"; }
.fa-star-sharp::before {
  content: "\e28b"; }
.fa-comment-heart::before {
  content: "\e5c8"; }
.fa-circle-1::before {
  content: "\e0ee"; }
.fa-circle-star::before {
  content: "\e123"; }
.fa-star-circle::before {
  content: "\e123"; }
.fa-fish::before {
  content: "\f578"; }
.fa-cloud-fog::before {
  content: "\f74e"; }
.fa-fog::before {
  content: "\f74e"; }
.fa-waffle::before {
  content: "\e466"; }
.fa-music-note::before {
  content: "\f8cf"; }
.fa-music-alt::before {
  content: "\f8cf"; }
.fa-hexagon-exclamation::before {
  content: "\e417"; }
.fa-cart-shopping-fast::before {
  content: "\e0dc"; }
.fa-object-union::before {
  content: "\e49f"; }
.fa-user-graduate::before {
  content: "\f501"; }
.fa-starfighter::before {
  content: "\e037"; }
.fa-circle-half-stroke::before {
  content: "\f042"; }
.fa-adjust::before {
  content: "\f042"; }
.fa-arrow-right-long-to-line::before {
  content: "\e3d5"; }
.fa-square-arrow-down::before {
  content: "\f339"; }
.fa-arrow-square-down::before {
  content: "\f339"; }
.fa-diamond-half-stroke::before {
  content: "\e5b8"; }
.fa-clapperboard::before {
  content: "\e131"; }
.fa-square-chevron-left::before {
  content: "\f32a"; }
.fa-chevron-square-left::before {
  content: "\f32a"; }
.fa-phone-intercom::before {
  content: "\e434"; }
.fa-link-horizontal::before {
  content: "\e1cb"; }
.fa-chain-horizontal::before {
  content: "\e1cb"; }
.fa-mango::before {
  content: "\e30f"; }
.fa-music-note-slash::before {
  content: "\f8d0"; }
.fa-music-alt-slash::before {
  content: "\f8d0"; }
.fa-circle-radiation::before {
  content: "\f7ba"; }
.fa-radiation-alt::before {
  content: "\f7ba"; }
.fa-face-tongue-sweat::before {
  content: "\e39e"; }
.fa-globe-stand::before {
  content: "\f5f6"; }
.fa-baseball::before {
  content: "\f433"; }
.fa-baseball-ball::before {
  content: "\f433"; }
.fa-circle-p::before {
  content: "\e11a"; }
.fa-award-simple::before {
  content: "\e0ab"; }
.fa-jet-fighter-up::before {
  content: "\e518"; }
.fa-diagram-project::before {
  content: "\f542"; }
.fa-project-diagram::before {
  content: "\f542"; }
.fa-pedestal::before {
  content: "\e20d"; }
.fa-chart-pyramid::before {
  content: "\e0e6"; }
.fa-sidebar::before {
  content: "\e24e"; }
.fa-snowman-head::before {
  content: "\f79b"; }
.fa-frosty-head::before {
  content: "\f79b"; }
.fa-copy::before {
  content: "\f0c5"; }
.fa-burger-glass::before {
  content: "\e0ce"; }
.fa-volume-xmark::before {
  content: "\f6a9"; }
.fa-volume-mute::before {
  content: "\f6a9"; }
.fa-volume-times::before {
  content: "\f6a9"; }
.fa-hand-sparkles::before {
  content: "\e05d"; }
.fa-bars-filter::before {
  content: "\e0ad"; }
.fa-paintbrush-pencil::before {
  content: "\e206"; }
.fa-party-bell::before {
  content: "\e31a"; }
.fa-user-vneck-hair::before {
  content: "\e462"; }
.fa-jack-o-lantern::before {
  content: "\f30e"; }
.fa-grip::before {
  content: "\f58d"; }
.fa-grip-horizontal::before {
  content: "\f58d"; }
.fa-share-from-square::before {
  content: "\f14d"; }
.fa-share-square::before {
  content: "\f14d"; }
.fa-keynote::before {
  content: "\f66c"; }
.fa-child-combatant::before {
  content: "\e4e0"; }
.fa-child-rifle::before {
  content: "\e4e0"; }
.fa-gun::before {
  content: "\e19b"; }
.fa-square-phone::before {
  content: "\f098"; }
.fa-phone-square::before {
  content: "\f098"; }
.fa-plus::before {
  content: "\2b"; }
.fa-add::before {
  content: "\2b"; }
.fa-expand::before {
  content: "\f065"; }
.fa-computer::before {
  content: "\e4e5"; }
.fa-fort::before {
  content: "\e486"; }
.fa-cloud-check::before {
  content: "\e35c"; }
.fa-xmark::before {
  content: "\f00d"; }
.fa-close::before {
  content: "\f00d"; }
.fa-multiply::before {
  content: "\f00d"; }
.fa-remove::before {
  content: "\f00d"; }
.fa-times::before {
  content: "\f00d"; }
.fa-face-smirking::before {
  content: "\e397"; }
.fa-arrows-up-down-left-right::before {
  content: "\f047"; }
.fa-arrows::before {
  content: "\f047"; }
.fa-chalkboard-user::before {
  content: "\f51c"; }
.fa-chalkboard-teacher::before {
  content: "\f51c"; }
.fa-rhombus::before {
  content: "\e23b"; }
.fa-claw-marks::before {
  content: "\f6c2"; }
.fa-peso-sign::before {
  content: "\e222"; }
.fa-face-smile-tongue::before {
  content: "\e394"; }
.fa-cart-circle-xmark::before {
  content: "\e3f4"; }
.fa-building-shield::before {
  content: "\e4d8"; }
.fa-circle-phone-flip::before {
  content: "\e11c"; }
.fa-phone-circle-alt::before {
  content: "\e11c"; }
.fa-baby::before {
  content: "\f77c"; }
.fa-users-line::before {
  content: "\e592"; }
.fa-quote-left::before {
  content: "\f10d"; }
.fa-quote-left-alt::before {
  content: "\f10d"; }
.fa-tractor::before {
  content: "\f722"; }
.fa-key-skeleton::before {
  content: "\f6f3"; }
.fa-trash-arrow-up::before {
  content: "\f829"; }
.fa-trash-restore::before {
  content: "\f829"; }
.fa-arrow-down-up-lock::before {
  content: "\e4b0"; }
.fa-arrow-down-to-bracket::before {
  content: "\e094"; }
.fa-lines-leaning::before {
  content: "\e51e"; }
.fa-square-q::before {
  content: "\e27b"; }
.fa-ruler-combined::before {
  content: "\f546"; }
.fa-symbols::before {
  content: "\f86e"; }
.fa-icons-alt::before {
  content: "\f86e"; }
.fa-copyright::before {
  content: "\f1f9"; }
.fa-highlighter-line::before {
  content: "\e1af"; }
.fa-bracket-square::before {
  content: "\5b"; }
.fa-bracket::before {
  content: "\5b"; }
.fa-bracket-left::before {
  content: "\5b"; }
.fa-island-tropical::before {
  content: "\f811"; }
.fa-island-tree-palm::before {
  content: "\f811"; }
.fa-arrow-right-from-line::before {
  content: "\f343"; }
.fa-arrow-from-left::before {
  content: "\f343"; }
.fa-h2::before {
  content: "\f314"; }
.fa-equals::before {
  content: "\3d"; }
.fa-cake-slice::before {
  content: "\e3e5"; }
.fa-shortcake::before {
  content: "\e3e5"; }
.fa-peanut::before {
  content: "\e430"; }
.fa-wrench-simple::before {
  content: "\e2d1"; }
.fa-blender::before {
  content: "\f517"; }
.fa-teeth::before {
  content: "\f62e"; }
.fa-tally-2::before {
  content: "\e295"; }
.fa-shekel-sign::before {
  content: "\f20b"; }
.fa-ils::before {
  content: "\f20b"; }
.fa-shekel::before {
  content: "\f20b"; }
.fa-sheqel::before {
  content: "\f20b"; }
.fa-sheqel-sign::before {
  content: "\f20b"; }
.fa-cars::before {
  content: "\f85b"; }
.fa-axe-battle::before {
  content: "\f6b3"; }
.fa-user-hair-long::before {
  content: "\e45b"; }
.fa-map::before {
  content: "\f279"; }
.fa-file-circle-info::before {
  content: "\e493"; }
.fa-face-disappointed::before {
  content: "\e36f"; }
.fa-lasso-sparkles::before {
  content: "\e1c9"; }
.fa-clock-eleven::before {
  content: "\e347"; }
.fa-rocket::before {
  content: "\f135"; }
.fa-siren-on::before {
  content: "\e02e"; }
.fa-clock-ten::before {
  content: "\e354"; }
.fa-candle-holder::before {
  content: "\f6bc"; }
.fa-video-arrow-down-left::before {
  content: "\e2c8"; }
.fa-photo-film::before {
  content: "\f87c"; }
.fa-photo-video::before {
  content: "\f87c"; }
.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180"; }
.fa-save-circle-arrow-right::before {
  content: "\e180"; }
.fa-folder-minus::before {
  content: "\f65d"; }
.fa-planet-moon::before {
  content: "\e01f"; }
.fa-face-eyes-xmarks::before {
  content: "\e374"; }
.fa-chart-scatter::before {
  content: "\f7ee"; }
.fa-display-arrow-down::before {
  content: "\e164"; }
.fa-store::before {
  content: "\f54e"; }
.fa-arrow-trend-up::before {
  content: "\e098"; }
.fa-plug-circle-minus::before {
  content: "\e55e"; }
.fa-olive-branch::before {
  content: "\e317"; }
.fa-angle::before {
  content: "\e08c"; }
.fa-vacuum-robot::before {
  content: "\e04e"; }
.fa-sign-hanging::before {
  content: "\f4d9"; }
.fa-sign::before {
  content: "\f4d9"; }
.fa-square-divide::before {
  content: "\e26a"; }
.fa-signal-stream-slash::before {
  content: "\e250"; }
.fa-bezier-curve::before {
  content: "\f55b"; }
.fa-eye-dropper-half::before {
  content: "\e173"; }
.fa-store-lock::before {
  content: "\e4a6"; }
.fa-bell-slash::before {
  content: "\f1f6"; }
.fa-cloud-bolt-sun::before {
  content: "\f76e"; }
.fa-thunderstorm-sun::before {
  content: "\f76e"; }
.fa-camera-slash::before {
  content: "\e0d9"; }
.fa-comment-quote::before {
  content: "\e14c"; }
.fa-tablet::before {
  content: "\f3fb"; }
.fa-tablet-android::before {
  content: "\f3fb"; }
.fa-school-flag::before {
  content: "\e56e"; }
.fa-message-code::before {
  content: "\e1df"; }
.fa-glass-half::before {
  content: "\e192"; }
.fa-glass-half-empty::before {
  content: "\e192"; }
.fa-glass-half-full::before {
  content: "\e192"; }
.fa-fill::before {
  content: "\f575"; }
.fa-message-minus::before {
  content: "\f4a7"; }
.fa-comment-alt-minus::before {
  content: "\f4a7"; }
.fa-angle-up::before {
  content: "\f106"; }
.fa-drumstick-bite::before {
  content: "\f6d7"; }
.fa-link-horizontal-slash::before {
  content: "\e1cc"; }
.fa-chain-horizontal-slash::before {
  content: "\e1cc"; }
.fa-holly-berry::before {
  content: "\f7aa"; }
.fa-nose::before {
  content: "\e5bd"; }
.fa-chevron-left::before {
  content: "\f053"; }
.fa-bacteria::before {
  content: "\e059"; }
.fa-clouds::before {
  content: "\f744"; }
.fa-money-bill-simple::before {
  content: "\e1f1"; }
.fa-hand-lizard::before {
  content: "\f258"; }
.fa-table-pivot::before {
  content: "\e291"; }
.fa-filter-slash::before {
  content: "\e17d"; }
.fa-trash-can-undo::before {
  content: "\f896"; }
.fa-trash-can-arrow-turn-left::before {
  content: "\f896"; }
.fa-trash-undo-alt::before {
  content: "\f896"; }
.fa-notdef::before {
  content: "\e1fe"; }
.fa-disease::before {
  content: "\f7fa"; }
.fa-person-to-door::before {
  content: "\e433"; }
.fa-turntable::before {
  content: "\f8e4"; }
.fa-briefcase-medical::before {
  content: "\f469"; }
.fa-genderless::before {
  content: "\f22d"; }
.fa-chevron-right::before {
  content: "\f054"; }
.fa-signal-weak::before {
  content: "\f68c"; }
.fa-signal-1::before {
  content: "\f68c"; }
.fa-clock-five::before {
  content: "\e349"; }
.fa-retweet::before {
  content: "\f079"; }
.fa-car-rear::before {
  content: "\f5de"; }
.fa-car-alt::before {
  content: "\f5de"; }
.fa-pump-soap::before {
  content: "\e06b"; }
.fa-computer-classic::before {
  content: "\f8b1"; }
.fa-frame::before {
  content: "\e495"; }
.fa-video-slash::before {
  content: "\f4e2"; }
.fa-battery-quarter::before {
  content: "\f243"; }
.fa-battery-2::before {
  content: "\f243"; }
.fa-ellipsis-stroke::before {
  content: "\f39b"; }
.fa-ellipsis-h-alt::before {
  content: "\f39b"; }
.fa-radio::before {
  content: "\f8d7"; }
.fa-baby-carriage::before {
  content: "\f77d"; }
.fa-carriage-baby::before {
  content: "\f77d"; }
.fa-face-expressionless::before {
  content: "\e373"; }
.fa-down-to-dotted-line::before {
  content: "\e408"; }
.fa-cloud-music::before {
  content: "\f8ae"; }
.fa-traffic-light::before {
  content: "\f637"; }
.fa-cloud-minus::before {
  content: "\e35d"; }
.fa-thermometer::before {
  content: "\f491"; }
.fa-shield-minus::before {
  content: "\e249"; }
.fa-vr-cardboard::before {
  content: "\f729"; }
.fa-car-tilt::before {
  content: "\f5e5"; }
.fa-gauge-circle-minus::before {
  content: "\e497"; }
.fa-brightness-low::before {
  content: "\e0ca"; }
.fa-hand-middle-finger::before {
  content: "\f806"; }
.fa-percent::before {
  content: "\25"; }
.fa-percentage::before {
  content: "\25"; }
.fa-truck-moving::before {
  content: "\f4df"; }
.fa-glass-water-droplet::before {
  content: "\e4f5"; }
.fa-conveyor-belt::before {
  content: "\f46e"; }
.fa-location-check::before {
  content: "\f606"; }
.fa-map-marker-check::before {
  content: "\f606"; }
.fa-coin-vertical::before {
  content: "\e3fd"; }
.fa-display::before {
  content: "\e163"; }
.fa-person-sign::before {
  content: "\f757"; }
.fa-face-smile::before {
  content: "\f118"; }
.fa-smile::before {
  content: "\f118"; }
.fa-phone-hangup::before {
  content: "\e225"; }
.fa-signature-slash::before {
  content: "\e3cb"; }
.fa-thumbtack::before {
  content: "\f08d"; }
.fa-thumb-tack::before {
  content: "\f08d"; }
.fa-wheat-slash::before {
  content: "\e339"; }
.fa-trophy::before {
  content: "\f091"; }
.fa-clouds-sun::before {
  content: "\f746"; }
.fa-person-praying::before {
  content: "\f683"; }
.fa-pray::before {
  content: "\f683"; }
.fa-hammer::before {
  content: "\f6e3"; }
.fa-face-vomit::before {
  content: "\e3a0"; }
.fa-speakers::before {
  content: "\f8e0"; }
.fa-tty-answer::before {
  content: "\e2b9"; }
.fa-teletype-answer::before {
  content: "\e2b9"; }
.fa-mug-tea-saucer::before {
  content: "\e1f5"; }
.fa-diagram-lean-canvas::before {
  content: "\e156"; }
.fa-alt::before {
  content: "\e08a"; }
.fa-dial::before {
  content: "\e15b"; }
.fa-dial-med-high::before {
  content: "\e15b"; }
.fa-hand-peace::before {
  content: "\f25b"; }
.fa-circle-trash::before {
  content: "\e126"; }
.fa-trash-circle::before {
  content: "\e126"; }
.fa-rotate::before {
  content: "\f2f1"; }
.fa-sync-alt::before {
  content: "\f2f1"; }
.fa-circle-quarters::before {
  content: "\e3f8"; }
.fa-spinner::before {
  content: "\f110"; }
.fa-tower-control::before {
  content: "\e2a2"; }
.fa-arrow-up-triangle-square::before {
  content: "\f88a"; }
.fa-sort-shapes-up::before {
  content: "\f88a"; }
.fa-whale::before {
  content: "\f72c"; }
.fa-robot::before {
  content: "\f544"; }
.fa-peace::before {
  content: "\f67c"; }
.fa-party-horn::before {
  content: "\e31b"; }
.fa-gears::before {
  content: "\f085"; }
.fa-cogs::before {
  content: "\f085"; }
.fa-sun-bright::before {
  content: "\e28f"; }
.fa-sun-alt::before {
  content: "\e28f"; }
.fa-warehouse::before {
  content: "\f494"; }
.fa-lock-keyhole-open::before {
  content: "\f3c2"; }
.fa-lock-open-alt::before {
  content: "\f3c2"; }
.fa-square-fragile::before {
  content: "\f49b"; }
.fa-box-fragile::before {
  content: "\f49b"; }
.fa-square-wine-glass-crack::before {
  content: "\f49b"; }
.fa-arrow-up-right-dots::before {
  content: "\e4b7"; }
.fa-square-n::before {
  content: "\e277"; }
.fa-splotch::before {
  content: "\f5bc"; }
.fa-face-grin-hearts::before {
  content: "\f584"; }
.fa-grin-hearts::before {
  content: "\f584"; }
.fa-meter::before {
  content: "\e1e8"; }
.fa-mandolin::before {
  content: "\f6f9"; }
.fa-dice-four::before {
  content: "\f524"; }
.fa-sim-card::before {
  content: "\f7c4"; }
.fa-transgender::before {
  content: "\f225"; }
.fa-transgender-alt::before {
  content: "\f225"; }
.fa-mercury::before {
  content: "\f223"; }
.fa-up-from-bracket::before {
  content: "\e590"; }
.fa-knife-kitchen::before {
  content: "\f6f5"; }
.fa-border-right::before {
  content: "\f852"; }
.fa-arrow-turn-down::before {
  content: "\f149"; }
.fa-level-down::before {
  content: "\f149"; }
.fa-spade::before {
  content: "\f2f4"; }
.fa-card-spade::before {
  content: "\e3ec"; }
.fa-line-columns::before {
  content: "\f870"; }
.fa-arrow-right-to-line::before {
  content: "\f340"; }
.fa-arrow-to-right::before {
  content: "\f340"; }
.fa-person-falling-burst::before {
  content: "\e547"; }
.fa-flag-pennant::before {
  content: "\f456"; }
.fa-pennant::before {
  content: "\f456"; }
.fa-conveyor-belt-empty::before {
  content: "\e150"; }
.fa-award::before {
  content: "\f559"; }
.fa-ticket-simple::before {
  content: "\f3ff"; }
.fa-ticket-alt::before {
  content: "\f3ff"; }
.fa-building::before {
  content: "\f1ad"; }
.fa-angles-left::before {
  content: "\f100"; }
.fa-angle-double-left::before {
  content: "\f100"; }
.fa-camcorder::before {
  content: "\f8a8"; }
.fa-video-handheld::before {
  content: "\f8a8"; }
.fa-pancakes::before {
  content: "\e42d"; }
.fa-album-circle-user::before {
  content: "\e48d"; }
.fa-qrcode::before {
  content: "\f029"; }
.fa-dice-d10::before {
  content: "\f6cd"; }
.fa-fireplace::before {
  content: "\f79a"; }
.fa-browser::before {
  content: "\f37e"; }
.fa-pen-paintbrush::before {
  content: "\f618"; }
.fa-pencil-paintbrush::before {
  content: "\f618"; }
.fa-fish-cooked::before {
  content: "\f7fe"; }
.fa-chair-office::before {
  content: "\f6c1"; }
.fa-nesting-dolls::before {
  content: "\e3ba"; }
.fa-clock-rotate-left::before {
  content: "\f1da"; }
.fa-history::before {
  content: "\f1da"; }
.fa-trumpet::before {
  content: "\f8e3"; }
.fa-face-grin-beam-sweat::before {
  content: "\f583"; }
.fa-grin-beam-sweat::before {
  content: "\f583"; }
.fa-fire-smoke::before {
  content: "\f74b"; }
.fa-phone-missed::before {
  content: "\e226"; }
.fa-file-export::before {
  content: "\f56e"; }
.fa-arrow-right-from-file::before {
  content: "\f56e"; }
.fa-shield::before {
  content: "\f132"; }
.fa-shield-blank::before {
  content: "\f132"; }
.fa-arrow-up-short-wide::before {
  content: "\f885"; }
.fa-sort-amount-up-alt::before {
  content: "\f885"; }
.fa-arrows-repeat-1::before {
  content: "\f366"; }
.fa-repeat-1-alt::before {
  content: "\f366"; }
.fa-gun-slash::before {
  content: "\e19c"; }
.fa-avocado::before {
  content: "\e0aa"; }
.fa-binary::before {
  content: "\e33b"; }
.fa-glasses-round::before {
  content: "\f5f5"; }
.fa-glasses-alt::before {
  content: "\f5f5"; }
.fa-phone-plus::before {
  content: "\f4d2"; }
.fa-ditto::before {
  content: "\22"; }
.fa-person-seat::before {
  content: "\e21e"; }
.fa-house-medical::before {
  content: "\e3b2"; }
.fa-golf-ball-tee::before {
  content: "\f450"; }
.fa-golf-ball::before {
  content: "\f450"; }
.fa-circle-chevron-left::before {
  content: "\f137"; }
.fa-chevron-circle-left::before {
  content: "\f137"; }
.fa-house-chimney-window::before {
  content: "\e00d"; }
.fa-scythe::before {
  content: "\f710"; }
.fa-pen-nib::before {
  content: "\f5ad"; }
.fa-ban-parking::before {
  content: "\f616"; }
.fa-parking-circle-slash::before {
  content: "\f616"; }
.fa-tent-arrow-turn-left::before {
  content: "\e580"; }
.fa-face-diagonal-mouth::before {
  content: "\e47e"; }
.fa-diagram-cells::before {
  content: "\e475"; }
.fa-cricket-bat-ball::before {
  content: "\f449"; }
.fa-cricket::before {
  content: "\f449"; }
.fa-tents::before {
  content: "\e582"; }
.fa-wand-magic::before {
  content: "\f0d0"; }
.fa-magic::before {
  content: "\f0d0"; }
.fa-dog::before {
  content: "\f6d3"; }
.fa-pen-line::before {
  content: "\e212"; }
.fa-atom-simple::before {
  content: "\f5d3"; }
.fa-atom-alt::before {
  content: "\f5d3"; }
.fa-ampersand::before {
  content: "\26"; }
.fa-carrot::before {
  content: "\f787"; }
.fa-arrow-up-from-line::before {
  content: "\f342"; }
.fa-arrow-from-bottom::before {
  content: "\f342"; }
.fa-moon::before {
  content: "\f186"; }
.fa-pen-slash::before {
  content: "\e213"; }
.fa-wine-glass-empty::before {
  content: "\f5ce"; }
.fa-wine-glass-alt::before {
  content: "\f5ce"; }
.fa-square-star::before {
  content: "\e27f"; }
.fa-cheese::before {
  content: "\f7ef"; }
.fa-send-backward::before {
  content: "\f87f"; }
.fa-yin-yang::before {
  content: "\f6ad"; }
.fa-music::before {
  content: "\f001"; }
.fa-compass-slash::before {
  content: "\f5e9"; }
.fa-clock-one::before {
  content: "\e34e"; }
.fa-file-music::before {
  content: "\f8b6"; }
.fa-code-commit::before {
  content: "\f386"; }
.fa-temperature-low::before {
  content: "\f76b"; }
.fa-person-biking::before {
  content: "\f84a"; }
.fa-biking::before {
  content: "\f84a"; }
.fa-skeleton::before {
  content: "\f620"; }
.fa-circle-g::before {
  content: "\e10f"; }
.fa-circle-arrow-up-left::before {
  content: "\e0fb"; }
.fa-coin-blank::before {
  content: "\e3fb"; }
.fa-broom::before {
  content: "\f51a"; }
.fa-vacuum::before {
  content: "\e04d"; }
.fa-shield-heart::before {
  content: "\e574"; }
.fa-card-heart::before {
  content: "\e3eb"; }
.fa-lightbulb-cfl-on::before {
  content: "\e5a7"; }
.fa-melon::before {
  content: "\e310"; }
.fa-gopuram::before {
  content: "\f664"; }
.fa-earth-oceania::before {
  content: "\e47b"; }
.fa-globe-oceania::before {
  content: "\e47b"; }
.fa-container-storage::before {
  content: "\f4b7"; }
.fa-face-pouting::before {
  content: "\e387"; }
.fa-square-xmark::before {
  content: "\f2d3"; }
.fa-times-square::before {
  content: "\f2d3"; }
.fa-xmark-square::before {
  content: "\f2d3"; }
.fa-face-explode::before {
  content: "\e2fe"; }
.fa-exploding-head::before {
  content: "\e2fe"; }
.fa-hashtag::before {
  content: "\23"; }
.fa-up-right-and-down-left-from-center::before {
  content: "\f424"; }
.fa-expand-alt::before {
  content: "\f424"; }
.fa-oil-can::before {
  content: "\f613"; }
.fa-t::before {
  content: "\54"; }
.fa-transformer-bolt::before {
  content: "\e2a4"; }
.fa-hippo::before {
  content: "\f6ed"; }
.fa-chart-column::before {
  content: "\e0e3"; }
.fa-cassette-vhs::before {
  content: "\f8ec"; }
.fa-vhs::before {
  content: "\f8ec"; }
.fa-infinity::before {
  content: "\f534"; }
.fa-vial-circle-check::before {
  content: "\e596"; }
.fa-chimney::before {
  content: "\f78b"; }
.fa-object-intersect::before {
  content: "\e49d"; }
.fa-person-arrow-down-to-line::before {
  content: "\e538"; }
.fa-voicemail::before {
  content: "\f897"; }
.fa-block-brick::before {
  content: "\e3db"; }
.fa-wall-brick::before {
  content: "\e3db"; }
.fa-fan::before {
  content: "\f863"; }
.fa-bags-shopping::before {
  content: "\f847"; }
.fa-paragraph-left::before {
  content: "\f878"; }
.fa-paragraph-rtl::before {
  content: "\f878"; }
.fa-person-walking-luggage::before {
  content: "\e554"; }
.fa-caravan-simple::before {
  content: "\e000"; }
.fa-caravan-alt::before {
  content: "\e000"; }
.fa-turtle::before {
  content: "\f726"; }
.fa-up-down::before {
  content: "\f338"; }
.fa-arrows-alt-v::before {
  content: "\f338"; }
.fa-cloud-moon-rain::before {
  content: "\f73c"; }
.fa-booth-curtain::before {
  content: "\f734"; }
.fa-calendar::before {
  content: "\f133"; }
.fa-box-heart::before {
  content: "\f49d"; }
.fa-trailer::before {
  content: "\e041"; }
.fa-user-doctor-message::before {
  content: "\f82e"; }
.fa-user-md-chat::before {
  content: "\f82e"; }
.fa-bahai::before {
  content: "\f666"; }
.fa-haykal::before {
  content: "\f666"; }
.fa-amp-guitar::before {
  content: "\f8a1"; }
.fa-sd-card::before {
  content: "\f7c2"; }
.fa-volume-slash::before {
  content: "\f2e2"; }
.fa-border-bottom::before {
  content: "\f84d"; }
.fa-wifi-weak::before {
  content: "\f6aa"; }
.fa-wifi-1::before {
  content: "\f6aa"; }
.fa-dragon::before {
  content: "\f6d5"; }
.fa-shoe-prints::before {
  content: "\f54b"; }
.fa-circle-plus::before {
  content: "\f055"; }
.fa-plus-circle::before {
  content: "\f055"; }
.fa-face-grin-tongue-wink::before {
  content: "\f58b"; }
.fa-grin-tongue-wink::before {
  content: "\f58b"; }
.fa-hand-holding::before {
  content: "\f4bd"; }
.fa-plug-circle-exclamation::before {
  content: "\e55d"; }
.fa-link-slash::before {
  content: "\f127"; }
.fa-chain-broken::before {
  content: "\f127"; }
.fa-chain-slash::before {
  content: "\f127"; }
.fa-unlink::before {
  content: "\f127"; }
.fa-clone::before {
  content: "\f24d"; }
.fa-person-walking-arrow-loop-left::before {
  content: "\e551"; }
.fa-arrow-up-z-a::before {
  content: "\f882"; }
.fa-sort-alpha-up-alt::before {
  content: "\f882"; }
.fa-fire-flame-curved::before {
  content: "\f7e4"; }
.fa-fire-alt::before {
  content: "\f7e4"; }
.fa-tornado::before {
  content: "\f76f"; }
.fa-file-circle-plus::before {
  content: "\e494"; }
.fa-delete-right::before {
  content: "\e154"; }
.fa-book-quran::before {
  content: "\f687"; }
.fa-quran::before {
  content: "\f687"; }
.fa-circle-quarter::before {
  content: "\e11f"; }
.fa-anchor::before {
  content: "\f13d"; }
.fa-border-all::before {
  content: "\f84c"; }
.fa-function::before {
  content: "\f661"; }
.fa-face-angry::before {
  content: "\f556"; }
.fa-angry::before {
  content: "\f556"; }
.fa-people-simple::before {
  content: "\e21b"; }
.fa-cookie-bite::before {
  content: "\f564"; }
.fa-arrow-trend-down::before {
  content: "\e097"; }
.fa-rss::before {
  content: "\f09e"; }
.fa-feed::before {
  content: "\f09e"; }
.fa-face-monocle::before {
  content: "\e380"; }
.fa-draw-polygon::before {
  content: "\f5ee"; }
.fa-scale-balanced::before {
  content: "\f24e"; }
.fa-balance-scale::before {
  content: "\f24e"; }
.fa-calendar-lines::before {
  content: "\e0d5"; }
.fa-calendar-note::before {
  content: "\e0d5"; }
.fa-arrow-down-big-small::before {
  content: "\f88c"; }
.fa-sort-size-down::before {
  content: "\f88c"; }
.fa-gauge-simple-high::before {
  content: "\f62a"; }
.fa-tachometer::before {
  content: "\f62a"; }
.fa-tachometer-fast::before {
  content: "\f62a"; }
.fa-do-not-enter::before {
  content: "\f5ec"; }
.fa-shower::before {
  content: "\f2cc"; }
.fa-dice-d8::before {
  content: "\f6d2"; }
.fa-desktop::before {
  content: "\f390"; }
.fa-desktop-alt::before {
  content: "\f390"; }
.fa-m::before {
  content: "\4d"; }
.fa-grip-dots-vertical::before {
  content: "\e411"; }
.fa-face-viewfinder::before {
  content: "\e2ff"; }
.fa-soft-serve::before {
  content: "\e400"; }
.fa-creemee::before {
  content: "\e400"; }
.fa-h5::before {
  content: "\e412"; }
.fa-hand-back-point-down::before {
  content: "\e19e"; }
.fa-table-list::before {
  content: "\f00b"; }
.fa-th-list::before {
  content: "\f00b"; }
.fa-comment-sms::before {
  content: "\f7cd"; }
.fa-sms::before {
  content: "\f7cd"; }
.fa-rectangle::before {
  content: "\f2fa"; }
.fa-rectangle-landscape::before {
  content: "\f2fa"; }
.fa-clipboard-list-check::before {
  content: "\f737"; }
.fa-turkey::before {
  content: "\f725"; }
.fa-book::before {
  content: "\f02d"; }
.fa-user-plus::before {
  content: "\f234"; }
.fa-ice-skate::before {
  content: "\f7ac"; }
.fa-check::before {
  content: "\f00c"; }
.fa-battery-three-quarters::before {
  content: "\f241"; }
.fa-battery-4::before {
  content: "\f241"; }
.fa-tomato::before {
  content: "\e330"; }
.fa-sword-laser::before {
  content: "\e03b"; }
.fa-house-circle-check::before {
  content: "\e509"; }
.fa-buildings::before {
  content: "\e0cc"; }
.fa-angle-left::before {
  content: "\f104"; }
.fa-cart-flatbed-boxes::before {
  content: "\f475"; }
.fa-dolly-flatbed-alt::before {
  content: "\f475"; }
.fa-diagram-successor::before {
  content: "\e47a"; }
.fa-truck-arrow-right::before {
  content: "\e58b"; }
.fa-square-w::before {
  content: "\e285"; }
.fa-arrows-split-up-and-left::before {
  content: "\e4bc"; }
.fa-lamp::before {
  content: "\f4ca"; }
.fa-airplay::before {
  content: "\e089"; }
.fa-hand-fist::before {
  content: "\f6de"; }
.fa-fist-raised::before {
  content: "\f6de"; }
.fa-shield-quartered::before {
  content: "\e575"; }
.fa-slash-forward::before {
  content: "\2f"; }
.fa-location-pen::before {
  content: "\f607"; }
.fa-map-marker-edit::before {
  content: "\f607"; }
.fa-cloud-moon::before {
  content: "\f6c3"; }
.fa-pot-food::before {
  content: "\e43f"; }
.fa-briefcase::before {
  content: "\f0b1"; }
.fa-person-falling::before {
  content: "\e546"; }
.fa-image-portrait::before {
  content: "\f3e0"; }
.fa-portrait::before {
  content: "\f3e0"; }
.fa-user-tag::before {
  content: "\f507"; }
.fa-rug::before {
  content: "\e569"; }
.fa-print-slash::before {
  content: "\f686"; }
.fa-earth-europe::before {
  content: "\f7a2"; }
.fa-globe-europe::before {
  content: "\f7a2"; }
.fa-cart-flatbed-suitcase::before {
  content: "\f59d"; }
.fa-luggage-cart::before {
  content: "\f59d"; }
.fa-hand-back-point-ribbon::before {
  content: "\e1a0"; }
.fa-rectangle-xmark::before {
  content: "\f410"; }
.fa-rectangle-times::before {
  content: "\f410"; }
.fa-times-rectangle::before {
  content: "\f410"; }
.fa-window-close::before {
  content: "\f410"; }
.fa-tire-rugged::before {
  content: "\f634"; }
.fa-lightbulb-dollar::before {
  content: "\f670"; }
.fa-cowbell::before {
  content: "\f8b3"; }
.fa-baht-sign::before {
  content: "\e0ac"; }
.fa-corner::before {
  content: "\e3fe"; }
.fa-chevrons-right::before {
  content: "\f324"; }
.fa-chevron-double-right::before {
  content: "\f324"; }
.fa-book-open::before {
  content: "\f518"; }
.fa-book-journal-whills::before {
  content: "\f66a"; }
.fa-journal-whills::before {
  content: "\f66a"; }
.fa-inhaler::before {
  content: "\f5f9"; }
.fa-handcuffs::before {
  content: "\e4f8"; }
.fa-snake::before {
  content: "\f716"; }
.fa-triangle-exclamation::before {
  content: "\f071"; }
.fa-exclamation-triangle::before {
  content: "\f071"; }
.fa-warning::before {
  content: "\f071"; }
.fa-note-medical::before {
  content: "\e200"; }
.fa-database::before {
  content: "\f1c0"; }
.fa-down-left::before {
  content: "\e16a"; }
.fa-share::before {
  content: "\f064"; }
.fa-arrow-turn-right::before {
  content: "\f064"; }
.fa-mail-forward::before {
  content: "\f064"; }
.fa-face-thinking::before {
  content: "\e39b"; }
.fa-turn-down-right::before {
  content: "\e455"; }
.fa-bottle-droplet::before {
  content: "\e4c4"; }
.fa-mask-face::before {
  content: "\e1d7"; }
.fa-hill-rockslide::before {
  content: "\e508"; }
.fa-scanner-keyboard::before {
  content: "\f489"; }
.fa-circle-o::before {
  content: "\e119"; }
.fa-grid-horizontal::before {
  content: "\e307"; }
.fa-message-dollar::before {
  content: "\f650"; }
.fa-comment-alt-dollar::before {
  content: "\f650"; }
.fa-right-left::before {
  content: "\f362"; }
.fa-exchange-alt::before {
  content: "\f362"; }
.fa-columns-3::before {
  content: "\e361"; }
.fa-paper-plane::before {
  content: "\f1d8"; }
.fa-road-circle-exclamation::before {
  content: "\e565"; }
.fa-dungeon::before {
  content: "\f6d9"; }
.fa-hand-holding-box::before {
  content: "\f47b"; }
.fa-input-text::before {
  content: "\e1bf"; }
.fa-window-flip::before {
  content: "\f40f"; }
.fa-window-alt::before {
  content: "\f40f"; }
.fa-align-right::before {
  content: "\f038"; }
.fa-scanner-gun::before {
  content: "\f488"; }
.fa-scanner::before {
  content: "\f488"; }
.fa-tire::before {
  content: "\f631"; }
.fa-engine::before {
  content: "\e16e"; }
.fa-money-bill-1-wave::before {
  content: "\f53b"; }
.fa-money-bill-wave-alt::before {
  content: "\f53b"; }
.fa-life-ring::before {
  content: "\f1cd"; }
.fa-hands::before {
  content: "\f2a7"; }
.fa-sign-language::before {
  content: "\f2a7"; }
.fa-signing::before {
  content: "\f2a7"; }
.fa-circle-caret-right::before {
  content: "\f330"; }
.fa-caret-circle-right::before {
  content: "\f330"; }
.fa-wheat::before {
  content: "\f72d"; }
.fa-file-spreadsheet::before {
  content: "\f65b"; }
.fa-audio-description-slash::before {
  content: "\e0a8"; }
.fa-calendar-day::before {
  content: "\f783"; }
.fa-water-ladder::before {
  content: "\f5c5"; }
.fa-ladder-water::before {
  content: "\f5c5"; }
.fa-swimming-pool::before {
  content: "\f5c5"; }
.fa-arrows-up-down::before {
  content: "\f07d"; }
.fa-arrows-v::before {
  content: "\f07d"; }
.fa-chess-pawn-piece::before {
  content: "\f444"; }
.fa-chess-pawn-alt::before {
  content: "\f444"; }
.fa-face-grimace::before {
  content: "\f57f"; }
.fa-grimace::before {
  content: "\f57f"; }
.fa-wheelchair-move::before {
  content: "\e2ce"; }
.fa-wheelchair-alt::before {
  content: "\e2ce"; }
.fa-turn-down::before {
  content: "\f3be"; }
.fa-level-down-alt::before {
  content: "\f3be"; }
.fa-square-s::before {
  content: "\e27d"; }
.fa-rectangle-barcode::before {
  content: "\f463"; }
.fa-barcode-alt::before {
  content: "\f463"; }
.fa-person-walking-arrow-right::before {
  content: "\e552"; }
.fa-square-envelope::before {
  content: "\f199"; }
.fa-envelope-square::before {
  content: "\f199"; }
.fa-dice::before {
  content: "\f522"; }
.fa-unicorn::before {
  content: "\f727"; }
.fa-bowling-ball::before {
  content: "\f436"; }
.fa-pompebled::before {
  content: "\e43d"; }
.fa-brain::before {
  content: "\f5dc"; }
.fa-watch-smart::before {
  content: "\e2cc"; }
.fa-book-user::before {
  content: "\f7e7"; }
.fa-sensor-cloud::before {
  content: "\e02c"; }
.fa-sensor-smoke::before {
  content: "\e02c"; }
.fa-clapperboard-play::before {
  content: "\e132"; }
.fa-bandage::before {
  content: "\f462"; }
.fa-band-aid::before {
  content: "\f462"; }
.fa-calendar-minus::before {
  content: "\f272"; }
.fa-circle-xmark::before {
  content: "\f057"; }
.fa-times-circle::before {
  content: "\f057"; }
.fa-xmark-circle::before {
  content: "\f057"; }
.fa-circle-4::before {
  content: "\e0f1"; }
.fa-gifts::before {
  content: "\f79c"; }
.fa-album-collection::before {
  content: "\f8a0"; }
.fa-hotel::before {
  content: "\f594"; }
.fa-earth-asia::before {
  content: "\f57e"; }
.fa-globe-asia::before {
  content: "\f57e"; }
.fa-id-card-clip::before {
  content: "\f47f"; }
.fa-id-card-alt::before {
  content: "\f47f"; }
.fa-magnifying-glass-plus::before {
  content: "\f00e"; }
.fa-search-plus::before {
  content: "\f00e"; }
.fa-thumbs-up::before {
  content: "\f164"; }
.fa-cloud-showers::before {
  content: "\f73f"; }
.fa-user-clock::before {
  content: "\f4fd"; }
.fa-onion::before {
  content: "\e427"; }
.fa-clock-twelve-thirty::before {
  content: "\e359"; }
.fa-arrow-down-to-dotted-line::before {
  content: "\e095"; }
.fa-hand-dots::before {
  content: "\f461"; }
.fa-allergies::before {
  content: "\f461"; }
.fa-file-invoice::before {
  content: "\f570"; }
.fa-window-minimize::before {
  content: "\f2d1"; }
.fa-rectangle-wide::before {
  content: "\f2fc"; }
.fa-comment-arrow-up::before {
  content: "\e144"; }
.fa-garlic::before {
  content: "\e40e"; }
.fa-mug-saucer::before {
  content: "\f0f4"; }
.fa-coffee::before {
  content: "\f0f4"; }
.fa-brush::before {
  content: "\f55d"; }
.fa-tree-decorated::before {
  content: "\f7dc"; }
.fa-mask::before {
  content: "\f6fa"; }
.fa-calendar-heart::before {
  content: "\e0d3"; }
.fa-magnifying-glass-minus::before {
  content: "\f010"; }
.fa-search-minus::before {
  content: "\f010"; }
.fa-flower::before {
  content: "\f7ff"; }
.fa-ruler-vertical::before {
  content: "\f548"; }
.fa-user-large::before {
  content: "\f406"; }
.fa-user-alt::before {
  content: "\f406"; }
.fa-starship-freighter::before {
  content: "\e03a"; }
.fa-train-tram::before {
  content: "\e5b4"; }
.fa-bridge-suspension::before {
  content: "\e4cd"; }
.fa-trash-check::before {
  content: "\e2af"; }
.fa-user-nurse::before {
  content: "\f82f"; }
.fa-boombox::before {
  content: "\f8a5"; }
.fa-syringe::before {
  content: "\f48e"; }
.fa-cloud-sun::before {
  content: "\f6c4"; }
.fa-shield-exclamation::before {
  content: "\e247"; }
.fa-stopwatch-20::before {
  content: "\e06f"; }
.fa-square-full::before {
  content: "\f45c"; }
.fa-grip-dots::before {
  content: "\e410"; }
.fa-comment-exclamation::before {
  content: "\f4af"; }
.fa-pen-swirl::before {
  content: "\e214"; }
.fa-falafel::before {
  content: "\e40a"; }
.fa-circle-2::before {
  content: "\e0ef"; }
.fa-magnet::before {
  content: "\f076"; }
.fa-jar::before {
  content: "\e516"; }
.fa-gramophone::before {
  content: "\f8bd"; }
.fa-dice-d12::before {
  content: "\f6ce"; }
.fa-note-sticky::before {
  content: "\f249"; }
.fa-sticky-note::before {
  content: "\f249"; }
.fa-down::before {
  content: "\f354"; }
.fa-arrow-alt-down::before {
  content: "\f354"; }
.fa-hundred-points::before {
  content: "\e41c"; }
.fa-100::before {
  content: "\e41c"; }
.fa-paperclip-vertical::before {
  content: "\e3c2"; }
.fa-wind-warning::before {
  content: "\f776"; }
.fa-wind-circle-exclamation::before {
  content: "\f776"; }
.fa-location-pin-slash::before {
  content: "\f60c"; }
.fa-map-marker-slash::before {
  content: "\f60c"; }
.fa-face-sad-sweat::before {
  content: "\e38a"; }
.fa-bug-slash::before {
  content: "\e490"; }
.fa-cupcake::before {
  content: "\e402"; }
.fa-light-switch-off::before {
  content: "\e018"; }
.fa-toggle-large-off::before {
  content: "\e5b0"; }
.fa-pen-fancy-slash::before {
  content: "\e210"; }
.fa-truck-container::before {
  content: "\f4dc"; }
.fa-boot::before {
  content: "\f782"; }
.fa-arrow-up-from-water-pump::before {
  content: "\e4b6"; }
.fa-file-check::before {
  content: "\f316"; }
.fa-bone::before {
  content: "\f5d7"; }
.fa-cards-blank::before {
  content: "\e4df"; }
.fa-circle-3::before {
  content: "\e0f0"; }
.fa-bench-tree::before {
  content: "\e2e7"; }
.fa-keyboard-brightness-low::before {
  content: "\e1c1"; }
.fa-ski-boot-ski::before {
  content: "\e3cd"; }
.fa-brain-circuit::before {
  content: "\e0c6"; }
.fa-user-injured::before {
  content: "\f728"; }
.fa-block-brick-fire::before {
  content: "\e3dc"; }
.fa-firewall::before {
  content: "\e3dc"; }
.fa-face-sad-tear::before {
  content: "\f5b4"; }
.fa-sad-tear::before {
  content: "\f5b4"; }
.fa-plane::before {
  content: "\f072"; }
.fa-tent-arrows-down::before {
  content: "\e581"; }
.fa-exclamation::before {
  content: "\21"; }
.fa-arrows-spin::before {
  content: "\e4bb"; }
.fa-face-smile-relaxed::before {
  content: "\e392"; }
.fa-comment-xmark::before {
  content: "\f4b5"; }
.fa-comment-times::before {
  content: "\f4b5"; }
.fa-print::before {
  content: "\f02f"; }
.fa-turkish-lira-sign::before {
  content: "\e2bb"; }
.fa-try::before {
  content: "\e2bb"; }
.fa-turkish-lira::before {
  content: "\e2bb"; }
.fa-face-nose-steam::before {
  content: "\e382"; }
.fa-circle-waveform-lines::before {
  content: "\e12d"; }
.fa-waveform-circle::before {
  content: "\e12d"; }
.fa-dollar-sign::before {
  content: "\24"; }
.fa-dollar::before {
  content: "\24"; }
.fa-usd::before {
  content: "\24"; }
.fa-ferris-wheel::before {
  content: "\e174"; }
.fa-computer-speaker::before {
  content: "\f8b2"; }
.fa-skull-cow::before {
  content: "\f8de"; }
.fa-x::before {
  content: "\58"; }
.fa-magnifying-glass-dollar::before {
  content: "\f688"; }
.fa-search-dollar::before {
  content: "\f688"; }
.fa-users-gear::before {
  content: "\f509"; }
.fa-users-cog::before {
  content: "\f509"; }
.fa-person-military-pointing::before {
  content: "\e54a"; }
.fa-building-columns::before {
  content: "\f19c"; }
.fa-bank::before {
  content: "\f19c"; }
.fa-institution::before {
  content: "\f19c"; }
.fa-museum::before {
  content: "\f19c"; }
.fa-university::before {
  content: "\f19c"; }
.fa-circle-t::before {
  content: "\e124"; }
.fa-sack::before {
  content: "\f81c"; }
.fa-grid-2::before {
  content: "\e196"; }
.fa-camera-cctv::before {
  content: "\f8ac"; }
.fa-cctv::before {
  content: "\f8ac"; }
.fa-umbrella::before {
  content: "\f0e9"; }
.fa-trowel::before {
  content: "\e589"; }
.fa-horizontal-rule::before {
  content: "\f86c"; }
.fa-bed-front::before {
  content: "\f8f7"; }
.fa-bed-alt::before {
  content: "\f8f7"; }
.fa-d::before {
  content: "\44"; }
.fa-stapler::before {
  content: "\e5af"; }
.fa-masks-theater::before {
  content: "\f630"; }
.fa-theater-masks::before {
  content: "\f630"; }
.fa-kip-sign::before {
  content: "\e1c4"; }
.fa-face-woozy::before {
  content: "\e3a2"; }
.fa-cloud-question::before {
  content: "\e492"; }
.fa-pineapple::before {
  content: "\e31f"; }
.fa-hand-point-left::before {
  content: "\f0a5"; }
.fa-gallery-thumbnails::before {
  content: "\e3aa"; }
.fa-circle-j::before {
  content: "\e112"; }
.fa-eyes::before {
  content: "\e367"; }
.fa-handshake-simple::before {
  content: "\f4c6"; }
.fa-handshake-alt::before {
  content: "\f4c6"; }
.fa-page-caret-up::before {
  content: "\e42a"; }
.fa-file-caret-up::before {
  content: "\e42a"; }
.fa-jet-fighter::before {
  content: "\f0fb"; }
.fa-fighter-jet::before {
  content: "\f0fb"; }
.fa-comet::before {
  content: "\e003"; }
.fa-square-share-nodes::before {
  content: "\f1e1"; }
.fa-share-alt-square::before {
  content: "\f1e1"; }
.fa-shield-keyhole::before {
  content: "\e248"; }
.fa-barcode::before {
  content: "\f02a"; }
.fa-plus-minus::before {
  content: "\e43c"; }
.fa-square-sliders-vertical::before {
  content: "\f3f2"; }
.fa-sliders-v-square::before {
  content: "\f3f2"; }
.fa-video::before {
  content: "\f03d"; }
.fa-video-camera::before {
  content: "\f03d"; }
.fa-message-middle::before {
  content: "\e1e1"; }
.fa-comment-middle-alt::before {
  content: "\e1e1"; }
.fa-graduation-cap::before {
  content: "\f19d"; }
.fa-mortar-board::before {
  content: "\f19d"; }
.fa-hand-holding-medical::before {
  content: "\e05c"; }
.fa-person-circle-check::before {
  content: "\e53e"; }
.fa-square-z::before {
  content: "\e288"; }
.fa-message-text::before {
  content: "\e1e6"; }
.fa-comment-alt-text::before {
  content: "\e1e6"; }
.fa-turn-up::before {
  content: "\f3bf"; }
.fa-level-up-alt::before {
  content: "\f3bf"; }
.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }
.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }
:root, :host {
  --fa-style-family-brands: 'Font Awesome 6 Brands';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }
@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('fa-brands-400.e0685453384dbe061fe9.woff2') format("woff2"), url('fa-brands-400.dbcb0917dd88a81503b3.ttf') format("truetype"); }
.fab,
.fa-brands {
  font-weight: 400; }
.fa-monero:before {
  content: "\f3d0"; }
.fa-hooli:before {
  content: "\f427"; }
.fa-yelp:before {
  content: "\f1e9"; }
.fa-cc-visa:before {
  content: "\f1f0"; }
.fa-lastfm:before {
  content: "\f202"; }
.fa-shopware:before {
  content: "\f5b5"; }
.fa-creative-commons-nc:before {
  content: "\f4e8"; }
.fa-aws:before {
  content: "\f375"; }
.fa-redhat:before {
  content: "\f7bc"; }
.fa-yoast:before {
  content: "\f2b1"; }
.fa-cloudflare:before {
  content: "\e07d"; }
.fa-ups:before {
  content: "\f7e0"; }
.fa-wpexplorer:before {
  content: "\f2de"; }
.fa-dyalog:before {
  content: "\f399"; }
.fa-bity:before {
  content: "\f37a"; }
.fa-stackpath:before {
  content: "\f842"; }
.fa-buysellads:before {
  content: "\f20d"; }
.fa-first-order:before {
  content: "\f2b0"; }
.fa-modx:before {
  content: "\f285"; }
.fa-guilded:before {
  content: "\e07e"; }
.fa-vnv:before {
  content: "\f40b"; }
.fa-square-js:before {
  content: "\f3b9"; }
.fa-js-square:before {
  content: "\f3b9"; }
.fa-microsoft:before {
  content: "\f3ca"; }
.fa-qq:before {
  content: "\f1d6"; }
.fa-orcid:before {
  content: "\f8d2"; }
.fa-java:before {
  content: "\f4e4"; }
.fa-invision:before {
  content: "\f7b0"; }
.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }
.fa-centercode:before {
  content: "\f380"; }
.fa-glide-g:before {
  content: "\f2a6"; }
.fa-drupal:before {
  content: "\f1a9"; }
.fa-hire-a-helper:before {
  content: "\f3b0"; }
.fa-creative-commons-by:before {
  content: "\f4e7"; }
.fa-unity:before {
  content: "\e049"; }
.fa-whmcs:before {
  content: "\f40d"; }
.fa-rocketchat:before {
  content: "\f3e8"; }
.fa-vk:before {
  content: "\f189"; }
.fa-untappd:before {
  content: "\f405"; }
.fa-mailchimp:before {
  content: "\f59e"; }
.fa-css3-alt:before {
  content: "\f38b"; }
.fa-square-reddit:before {
  content: "\f1a2"; }
.fa-reddit-square:before {
  content: "\f1a2"; }
.fa-vimeo-v:before {
  content: "\f27d"; }
.fa-contao:before {
  content: "\f26d"; }
.fa-square-font-awesome:before {
  content: "\e5ad"; }
.fa-deskpro:before {
  content: "\f38f"; }
.fa-sistrix:before {
  content: "\f3ee"; }
.fa-square-instagram:before {
  content: "\e055"; }
.fa-instagram-square:before {
  content: "\e055"; }
.fa-battle-net:before {
  content: "\f835"; }
.fa-the-red-yeti:before {
  content: "\f69d"; }
.fa-square-hacker-news:before {
  content: "\f3af"; }
.fa-hacker-news-square:before {
  content: "\f3af"; }
.fa-edge:before {
  content: "\f282"; }
.fa-napster:before {
  content: "\f3d2"; }
.fa-square-snapchat:before {
  content: "\f2ad"; }
.fa-snapchat-square:before {
  content: "\f2ad"; }
.fa-google-plus-g:before {
  content: "\f0d5"; }
.fa-artstation:before {
  content: "\f77a"; }
.fa-markdown:before {
  content: "\f60f"; }
.fa-sourcetree:before {
  content: "\f7d3"; }
.fa-google-plus:before {
  content: "\f2b3"; }
.fa-diaspora:before {
  content: "\f791"; }
.fa-foursquare:before {
  content: "\f180"; }
.fa-stack-overflow:before {
  content: "\f16c"; }
.fa-github-alt:before {
  content: "\f113"; }
.fa-phoenix-squadron:before {
  content: "\f511"; }
.fa-pagelines:before {
  content: "\f18c"; }
.fa-algolia:before {
  content: "\f36c"; }
.fa-red-river:before {
  content: "\f3e3"; }
.fa-creative-commons-sa:before {
  content: "\f4ef"; }
.fa-safari:before {
  content: "\f267"; }
.fa-google:before {
  content: "\f1a0"; }
.fa-square-font-awesome-stroke:before {
  content: "\f35c"; }
.fa-font-awesome-alt:before {
  content: "\f35c"; }
.fa-atlassian:before {
  content: "\f77b"; }
.fa-linkedin-in:before {
  content: "\f0e1"; }
.fa-digital-ocean:before {
  content: "\f391"; }
.fa-nimblr:before {
  content: "\f5a8"; }
.fa-chromecast:before {
  content: "\f838"; }
.fa-evernote:before {
  content: "\f839"; }
.fa-hacker-news:before {
  content: "\f1d4"; }
.fa-creative-commons-sampling:before {
  content: "\f4f0"; }
.fa-adversal:before {
  content: "\f36a"; }
.fa-creative-commons:before {
  content: "\f25e"; }
.fa-watchman-monitoring:before {
  content: "\e087"; }
.fa-fonticons:before {
  content: "\f280"; }
.fa-weixin:before {
  content: "\f1d7"; }
.fa-shirtsinbulk:before {
  content: "\f214"; }
.fa-codepen:before {
  content: "\f1cb"; }
.fa-git-alt:before {
  content: "\f841"; }
.fa-lyft:before {
  content: "\f3c3"; }
.fa-rev:before {
  content: "\f5b2"; }
.fa-windows:before {
  content: "\f17a"; }
.fa-wizards-of-the-coast:before {
  content: "\f730"; }
.fa-square-viadeo:before {
  content: "\f2aa"; }
.fa-viadeo-square:before {
  content: "\f2aa"; }
.fa-meetup:before {
  content: "\f2e0"; }
.fa-centos:before {
  content: "\f789"; }
.fa-adn:before {
  content: "\f170"; }
.fa-cloudsmith:before {
  content: "\f384"; }
.fa-pied-piper-alt:before {
  content: "\f1a8"; }
.fa-square-dribbble:before {
  content: "\f397"; }
.fa-dribbble-square:before {
  content: "\f397"; }
.fa-codiepie:before {
  content: "\f284"; }
.fa-node:before {
  content: "\f419"; }
.fa-mix:before {
  content: "\f3cb"; }
.fa-steam:before {
  content: "\f1b6"; }
.fa-cc-apple-pay:before {
  content: "\f416"; }
.fa-scribd:before {
  content: "\f28a"; }
.fa-openid:before {
  content: "\f19b"; }
.fa-instalod:before {
  content: "\e081"; }
.fa-expeditedssl:before {
  content: "\f23e"; }
.fa-sellcast:before {
  content: "\f2da"; }
.fa-square-twitter:before {
  content: "\f081"; }
.fa-twitter-square:before {
  content: "\f081"; }
.fa-r-project:before {
  content: "\f4f7"; }
.fa-delicious:before {
  content: "\f1a5"; }
.fa-freebsd:before {
  content: "\f3a4"; }
.fa-vuejs:before {
  content: "\f41f"; }
.fa-accusoft:before {
  content: "\f369"; }
.fa-ioxhost:before {
  content: "\f208"; }
.fa-fonticons-fi:before {
  content: "\f3a2"; }
.fa-app-store:before {
  content: "\f36f"; }
.fa-cc-mastercard:before {
  content: "\f1f1"; }
.fa-itunes-note:before {
  content: "\f3b5"; }
.fa-golang:before {
  content: "\e40f"; }
.fa-kickstarter:before {
  content: "\f3bb"; }
.fa-grav:before {
  content: "\f2d6"; }
.fa-weibo:before {
  content: "\f18a"; }
.fa-uncharted:before {
  content: "\e084"; }
.fa-firstdraft:before {
  content: "\f3a1"; }
.fa-square-youtube:before {
  content: "\f431"; }
.fa-youtube-square:before {
  content: "\f431"; }
.fa-wikipedia-w:before {
  content: "\f266"; }
.fa-wpressr:before {
  content: "\f3e4"; }
.fa-rendact:before {
  content: "\f3e4"; }
.fa-angellist:before {
  content: "\f209"; }
.fa-galactic-republic:before {
  content: "\f50c"; }
.fa-nfc-directional:before {
  content: "\e530"; }
.fa-skype:before {
  content: "\f17e"; }
.fa-joget:before {
  content: "\f3b7"; }
.fa-fedora:before {
  content: "\f798"; }
.fa-stripe-s:before {
  content: "\f42a"; }
.fa-meta:before {
  content: "\e49b"; }
.fa-laravel:before {
  content: "\f3bd"; }
.fa-hotjar:before {
  content: "\f3b1"; }
.fa-bluetooth-b:before {
  content: "\f294"; }
.fa-sticker-mule:before {
  content: "\f3f7"; }
.fa-creative-commons-zero:before {
  content: "\f4f3"; }
.fa-hips:before {
  content: "\f452"; }
.fa-behance:before {
  content: "\f1b4"; }
.fa-reddit:before {
  content: "\f1a1"; }
.fa-discord:before {
  content: "\f392"; }
.fa-chrome:before {
  content: "\f268"; }
.fa-app-store-ios:before {
  content: "\f370"; }
.fa-cc-discover:before {
  content: "\f1f2"; }
.fa-wpbeginner:before {
  content: "\f297"; }
.fa-confluence:before {
  content: "\f78d"; }
.fa-mdb:before {
  content: "\f8ca"; }
.fa-dochub:before {
  content: "\f394"; }
.fa-accessible-icon:before {
  content: "\f368"; }
.fa-ebay:before {
  content: "\f4f4"; }
.fa-amazon:before {
  content: "\f270"; }
.fa-unsplash:before {
  content: "\e07c"; }
.fa-yarn:before {
  content: "\f7e3"; }
.fa-square-steam:before {
  content: "\f1b7"; }
.fa-steam-square:before {
  content: "\f1b7"; }
.fa-500px:before {
  content: "\f26e"; }
.fa-square-vimeo:before {
  content: "\f194"; }
.fa-vimeo-square:before {
  content: "\f194"; }
.fa-asymmetrik:before {
  content: "\f372"; }
.fa-font-awesome:before {
  content: "\f2b4"; }
.fa-font-awesome-flag:before {
  content: "\f2b4"; }
.fa-font-awesome-logo-full:before {
  content: "\f2b4"; }
.fa-gratipay:before {
  content: "\f184"; }
.fa-apple:before {
  content: "\f179"; }
.fa-hive:before {
  content: "\e07f"; }
.fa-gitkraken:before {
  content: "\f3a6"; }
.fa-keybase:before {
  content: "\f4f5"; }
.fa-apple-pay:before {
  content: "\f415"; }
.fa-padlet:before {
  content: "\e4a0"; }
.fa-amazon-pay:before {
  content: "\f42c"; }
.fa-square-github:before {
  content: "\f092"; }
.fa-github-square:before {
  content: "\f092"; }
.fa-stumbleupon:before {
  content: "\f1a4"; }
.fa-fedex:before {
  content: "\f797"; }
.fa-phoenix-framework:before {
  content: "\f3dc"; }
.fa-shopify:before {
  content: "\e057"; }
.fa-neos:before {
  content: "\f612"; }
.fa-hackerrank:before {
  content: "\f5f7"; }
.fa-researchgate:before {
  content: "\f4f8"; }
.fa-swift:before {
  content: "\f8e1"; }
.fa-angular:before {
  content: "\f420"; }
.fa-speakap:before {
  content: "\f3f3"; }
.fa-angrycreative:before {
  content: "\f36e"; }
.fa-y-combinator:before {
  content: "\f23b"; }
.fa-empire:before {
  content: "\f1d1"; }
.fa-envira:before {
  content: "\f299"; }
.fa-square-gitlab:before {
  content: "\e5ae"; }
.fa-gitlab-square:before {
  content: "\e5ae"; }
.fa-studiovinari:before {
  content: "\f3f8"; }
.fa-pied-piper:before {
  content: "\f2ae"; }
.fa-wordpress:before {
  content: "\f19a"; }
.fa-product-hunt:before {
  content: "\f288"; }
.fa-firefox:before {
  content: "\f269"; }
.fa-linode:before {
  content: "\f2b8"; }
.fa-goodreads:before {
  content: "\f3a8"; }
.fa-square-odnoklassniki:before {
  content: "\f264"; }
.fa-odnoklassniki-square:before {
  content: "\f264"; }
.fa-jsfiddle:before {
  content: "\f1cc"; }
.fa-sith:before {
  content: "\f512"; }
.fa-themeisle:before {
  content: "\f2b2"; }
.fa-page4:before {
  content: "\f3d7"; }
.fa-hashnode:before {
  content: "\e499"; }
.fa-react:before {
  content: "\f41b"; }
.fa-cc-paypal:before {
  content: "\f1f4"; }
.fa-squarespace:before {
  content: "\f5be"; }
.fa-cc-stripe:before {
  content: "\f1f5"; }
.fa-creative-commons-share:before {
  content: "\f4f2"; }
.fa-bitcoin:before {
  content: "\f379"; }
.fa-keycdn:before {
  content: "\f3ba"; }
.fa-opera:before {
  content: "\f26a"; }
.fa-itch-io:before {
  content: "\f83a"; }
.fa-umbraco:before {
  content: "\f8e8"; }
.fa-galactic-senate:before {
  content: "\f50d"; }
.fa-ubuntu:before {
  content: "\f7df"; }
.fa-draft2digital:before {
  content: "\f396"; }
.fa-stripe:before {
  content: "\f429"; }
.fa-houzz:before {
  content: "\f27c"; }
.fa-gg:before {
  content: "\f260"; }
.fa-dhl:before {
  content: "\f790"; }
.fa-square-pinterest:before {
  content: "\f0d3"; }
.fa-pinterest-square:before {
  content: "\f0d3"; }
.fa-xing:before {
  content: "\f168"; }
.fa-blackberry:before {
  content: "\f37b"; }
.fa-creative-commons-pd:before {
  content: "\f4ec"; }
.fa-playstation:before {
  content: "\f3df"; }
.fa-quinscape:before {
  content: "\f459"; }
.fa-less:before {
  content: "\f41d"; }
.fa-blogger-b:before {
  content: "\f37d"; }
.fa-opencart:before {
  content: "\f23d"; }
.fa-vine:before {
  content: "\f1ca"; }
.fa-paypal:before {
  content: "\f1ed"; }
.fa-gitlab:before {
  content: "\f296"; }
.fa-typo3:before {
  content: "\f42b"; }
.fa-reddit-alien:before {
  content: "\f281"; }
.fa-yahoo:before {
  content: "\f19e"; }
.fa-dailymotion:before {
  content: "\e052"; }
.fa-affiliatetheme:before {
  content: "\f36b"; }
.fa-pied-piper-pp:before {
  content: "\f1a7"; }
.fa-bootstrap:before {
  content: "\f836"; }
.fa-odnoklassniki:before {
  content: "\f263"; }
.fa-nfc-symbol:before {
  content: "\e531"; }
.fa-ethereum:before {
  content: "\f42e"; }
.fa-speaker-deck:before {
  content: "\f83c"; }
.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }
.fa-patreon:before {
  content: "\f3d9"; }
.fa-avianex:before {
  content: "\f374"; }
.fa-ello:before {
  content: "\f5f1"; }
.fa-gofore:before {
  content: "\f3a7"; }
.fa-bimobject:before {
  content: "\f378"; }
.fa-facebook-f:before {
  content: "\f39e"; }
.fa-square-google-plus:before {
  content: "\f0d4"; }
.fa-google-plus-square:before {
  content: "\f0d4"; }
.fa-mandalorian:before {
  content: "\f50f"; }
.fa-first-order-alt:before {
  content: "\f50a"; }
.fa-osi:before {
  content: "\f41a"; }
.fa-google-wallet:before {
  content: "\f1ee"; }
.fa-d-and-d-beyond:before {
  content: "\f6ca"; }
.fa-periscope:before {
  content: "\f3da"; }
.fa-fulcrum:before {
  content: "\f50b"; }
.fa-cloudscale:before {
  content: "\f383"; }
.fa-forumbee:before {
  content: "\f211"; }
.fa-mizuni:before {
  content: "\f3cc"; }
.fa-schlix:before {
  content: "\f3ea"; }
.fa-square-xing:before {
  content: "\f169"; }
.fa-xing-square:before {
  content: "\f169"; }
.fa-bandcamp:before {
  content: "\f2d5"; }
.fa-wpforms:before {
  content: "\f298"; }
.fa-cloudversify:before {
  content: "\f385"; }
.fa-usps:before {
  content: "\f7e1"; }
.fa-megaport:before {
  content: "\f5a3"; }
.fa-magento:before {
  content: "\f3c4"; }
.fa-spotify:before {
  content: "\f1bc"; }
.fa-optin-monster:before {
  content: "\f23c"; }
.fa-fly:before {
  content: "\f417"; }
.fa-aviato:before {
  content: "\f421"; }
.fa-itunes:before {
  content: "\f3b4"; }
.fa-cuttlefish:before {
  content: "\f38c"; }
.fa-blogger:before {
  content: "\f37c"; }
.fa-flickr:before {
  content: "\f16e"; }
.fa-viber:before {
  content: "\f409"; }
.fa-soundcloud:before {
  content: "\f1be"; }
.fa-digg:before {
  content: "\f1a6"; }
.fa-tencent-weibo:before {
  content: "\f1d5"; }
.fa-symfony:before {
  content: "\f83d"; }
.fa-maxcdn:before {
  content: "\f136"; }
.fa-etsy:before {
  content: "\f2d7"; }
.fa-facebook-messenger:before {
  content: "\f39f"; }
.fa-audible:before {
  content: "\f373"; }
.fa-think-peaks:before {
  content: "\f731"; }
.fa-bilibili:before {
  content: "\e3d9"; }
.fa-erlang:before {
  content: "\f39d"; }
.fa-cotton-bureau:before {
  content: "\f89e"; }
.fa-dashcube:before {
  content: "\f210"; }
.fa-42-group:before {
  content: "\e080"; }
.fa-innosoft:before {
  content: "\e080"; }
.fa-stack-exchange:before {
  content: "\f18d"; }
.fa-elementor:before {
  content: "\f430"; }
.fa-square-pied-piper:before {
  content: "\e01e"; }
.fa-pied-piper-square:before {
  content: "\e01e"; }
.fa-creative-commons-nd:before {
  content: "\f4eb"; }
.fa-palfed:before {
  content: "\f3d8"; }
.fa-superpowers:before {
  content: "\f2dd"; }
.fa-resolving:before {
  content: "\f3e7"; }
.fa-xbox:before {
  content: "\f412"; }
.fa-searchengin:before {
  content: "\f3eb"; }
.fa-tiktok:before {
  content: "\e07b"; }
.fa-square-facebook:before {
  content: "\f082"; }
.fa-facebook-square:before {
  content: "\f082"; }
.fa-renren:before {
  content: "\f18b"; }
.fa-linux:before {
  content: "\f17c"; }
.fa-glide:before {
  content: "\f2a5"; }
.fa-linkedin:before {
  content: "\f08c"; }
.fa-hubspot:before {
  content: "\f3b2"; }
.fa-deploydog:before {
  content: "\f38e"; }
.fa-twitch:before {
  content: "\f1e8"; }
.fa-ravelry:before {
  content: "\f2d9"; }
.fa-mixer:before {
  content: "\e056"; }
.fa-square-lastfm:before {
  content: "\f203"; }
.fa-lastfm-square:before {
  content: "\f203"; }
.fa-vimeo:before {
  content: "\f40a"; }
.fa-mendeley:before {
  content: "\f7b3"; }
.fa-uniregistry:before {
  content: "\f404"; }
.fa-figma:before {
  content: "\f799"; }
.fa-creative-commons-remix:before {
  content: "\f4ee"; }
.fa-cc-amazon-pay:before {
  content: "\f42d"; }
.fa-dropbox:before {
  content: "\f16b"; }
.fa-instagram:before {
  content: "\f16d"; }
.fa-cmplid:before {
  content: "\e360"; }
.fa-facebook:before {
  content: "\f09a"; }
.fa-gripfire:before {
  content: "\f3ac"; }
.fa-jedi-order:before {
  content: "\f50e"; }
.fa-uikit:before {
  content: "\f403"; }
.fa-fort-awesome-alt:before {
  content: "\f3a3"; }
.fa-phabricator:before {
  content: "\f3db"; }
.fa-ussunnah:before {
  content: "\f407"; }
.fa-earlybirds:before {
  content: "\f39a"; }
.fa-trade-federation:before {
  content: "\f513"; }
.fa-autoprefixer:before {
  content: "\f41c"; }
.fa-whatsapp:before {
  content: "\f232"; }
.fa-slideshare:before {
  content: "\f1e7"; }
.fa-google-play:before {
  content: "\f3ab"; }
.fa-viadeo:before {
  content: "\f2a9"; }
.fa-line:before {
  content: "\f3c0"; }
.fa-google-drive:before {
  content: "\f3aa"; }
.fa-servicestack:before {
  content: "\f3ec"; }
.fa-simplybuilt:before {
  content: "\f215"; }
.fa-bitbucket:before {
  content: "\f171"; }
.fa-imdb:before {
  content: "\f2d8"; }
.fa-deezer:before {
  content: "\e077"; }
.fa-raspberry-pi:before {
  content: "\f7bb"; }
.fa-jira:before {
  content: "\f7b1"; }
.fa-docker:before {
  content: "\f395"; }
.fa-screenpal:before {
  content: "\e570"; }
.fa-bluetooth:before {
  content: "\f293"; }
.fa-gitter:before {
  content: "\f426"; }
.fa-d-and-d:before {
  content: "\f38d"; }
.fa-microblog:before {
  content: "\e01a"; }
.fa-cc-diners-club:before {
  content: "\f24c"; }
.fa-gg-circle:before {
  content: "\f261"; }
.fa-pied-piper-hat:before {
  content: "\f4e5"; }
.fa-kickstarter-k:before {
  content: "\f3bc"; }
.fa-yandex:before {
  content: "\f413"; }
.fa-readme:before {
  content: "\f4d5"; }
.fa-html5:before {
  content: "\f13b"; }
.fa-sellsy:before {
  content: "\f213"; }
.fa-sass:before {
  content: "\f41e"; }
.fa-wirsindhandwerk:before {
  content: "\e2d0"; }
.fa-wsh:before {
  content: "\e2d0"; }
.fa-buromobelexperte:before {
  content: "\f37f"; }
.fa-salesforce:before {
  content: "\f83b"; }
.fa-octopus-deploy:before {
  content: "\e082"; }
.fa-medapps:before {
  content: "\f3c6"; }
.fa-ns8:before {
  content: "\f3d5"; }
.fa-pinterest-p:before {
  content: "\f231"; }
.fa-apper:before {
  content: "\f371"; }
.fa-fort-awesome:before {
  content: "\f286"; }
.fa-waze:before {
  content: "\f83f"; }
.fa-cc-jcb:before {
  content: "\f24b"; }
.fa-snapchat:before {
  content: "\f2ab"; }
.fa-snapchat-ghost:before {
  content: "\f2ab"; }
.fa-fantasy-flight-games:before {
  content: "\f6dc"; }
.fa-rust:before {
  content: "\e07a"; }
.fa-wix:before {
  content: "\f5cf"; }
.fa-square-behance:before {
  content: "\f1b5"; }
.fa-behance-square:before {
  content: "\f1b5"; }
.fa-supple:before {
  content: "\f3f9"; }
.fa-rebel:before {
  content: "\f1d0"; }
.fa-css3:before {
  content: "\f13c"; }
.fa-staylinked:before {
  content: "\f3f5"; }
.fa-kaggle:before {
  content: "\f5fa"; }
.fa-space-awesome:before {
  content: "\e5ac"; }
.fa-deviantart:before {
  content: "\f1bd"; }
.fa-cpanel:before {
  content: "\f388"; }
.fa-goodreads-g:before {
  content: "\f3a9"; }
.fa-square-git:before {
  content: "\f1d2"; }
.fa-git-square:before {
  content: "\f1d2"; }
.fa-square-tumblr:before {
  content: "\f174"; }
.fa-tumblr-square:before {
  content: "\f174"; }
.fa-trello:before {
  content: "\f181"; }
.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }
.fa-get-pocket:before {
  content: "\f265"; }
.fa-perbyte:before {
  content: "\e083"; }
.fa-grunt:before {
  content: "\f3ad"; }
.fa-weebly:before {
  content: "\f5cc"; }
.fa-connectdevelop:before {
  content: "\f20e"; }
.fa-leanpub:before {
  content: "\f212"; }
.fa-black-tie:before {
  content: "\f27e"; }
.fa-themeco:before {
  content: "\f5c6"; }
.fa-python:before {
  content: "\f3e2"; }
.fa-android:before {
  content: "\f17b"; }
.fa-bots:before {
  content: "\e340"; }
.fa-free-code-camp:before {
  content: "\f2c5"; }
.fa-hornbill:before {
  content: "\f592"; }
.fa-js:before {
  content: "\f3b8"; }
.fa-ideal:before {
  content: "\e013"; }
.fa-git:before {
  content: "\f1d3"; }
.fa-dev:before {
  content: "\f6cc"; }
.fa-sketch:before {
  content: "\f7c6"; }
.fa-yandex-international:before {
  content: "\f414"; }
.fa-cc-amex:before {
  content: "\f1f3"; }
.fa-uber:before {
  content: "\f402"; }
.fa-github:before {
  content: "\f09b"; }
.fa-php:before {
  content: "\f457"; }
.fa-alipay:before {
  content: "\f642"; }
.fa-youtube:before {
  content: "\f167"; }
.fa-skyatlas:before {
  content: "\f216"; }
.fa-firefox-browser:before {
  content: "\e007"; }
.fa-replyd:before {
  content: "\f3e6"; }
.fa-suse:before {
  content: "\f7d6"; }
.fa-jenkins:before {
  content: "\f3b6"; }
.fa-twitter:before {
  content: "\f099"; }
.fa-rockrms:before {
  content: "\f3e9"; }
.fa-pinterest:before {
  content: "\f0d2"; }
.fa-buffer:before {
  content: "\f837"; }
.fa-npm:before {
  content: "\f3d4"; }
.fa-yammer:before {
  content: "\f840"; }
.fa-btc:before {
  content: "\f15a"; }
.fa-dribbble:before {
  content: "\f17d"; }
.fa-stumbleupon-circle:before {
  content: "\f1a3"; }
.fa-internet-explorer:before {
  content: "\f26b"; }
.fa-stubber:before {
  content: "\e5c7"; }
.fa-telegram:before {
  content: "\f2c6"; }
.fa-telegram-plane:before {
  content: "\f2c6"; }
.fa-old-republic:before {
  content: "\f510"; }
.fa-odysee:before {
  content: "\e5c6"; }
.fa-square-whatsapp:before {
  content: "\f40c"; }
.fa-whatsapp-square:before {
  content: "\f40c"; }
.fa-node-js:before {
  content: "\f3d3"; }
.fa-edge-legacy:before {
  content: "\e078"; }
.fa-slack:before {
  content: "\f198"; }
.fa-slack-hash:before {
  content: "\f198"; }
.fa-medrt:before {
  content: "\f3c8"; }
.fa-usb:before {
  content: "\f287"; }
.fa-tumblr:before {
  content: "\f173"; }
.fa-vaadin:before {
  content: "\f408"; }
.fa-quora:before {
  content: "\f2c4"; }
.fa-reacteurope:before {
  content: "\f75d"; }
.fa-medium:before {
  content: "\f23a"; }
.fa-medium-m:before {
  content: "\f23a"; }
.fa-amilia:before {
  content: "\f36d"; }
.fa-mixcloud:before {
  content: "\f289"; }
.fa-flipboard:before {
  content: "\f44d"; }
.fa-viacoin:before {
  content: "\f237"; }
.fa-critical-role:before {
  content: "\f6c9"; }
.fa-sitrox:before {
  content: "\e44a"; }
.fa-discourse:before {
  content: "\f393"; }
.fa-joomla:before {
  content: "\f1aa"; }
.fa-mastodon:before {
  content: "\f4f6"; }
.fa-airbnb:before {
  content: "\f834"; }
.fa-wolf-pack-battalion:before {
  content: "\f514"; }
.fa-buy-n-large:before {
  content: "\f8a6"; }
.fa-gulp:before {
  content: "\f3ae"; }
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }
.fa-strava:before {
  content: "\f428"; }
.fa-ember:before {
  content: "\f423"; }
.fa-canadian-maple-leaf:before {
  content: "\f785"; }
.fa-teamspeak:before {
  content: "\f4f9"; }
.fa-pushed:before {
  content: "\f3e1"; }
.fa-wordpress-simple:before {
  content: "\f411"; }
.fa-nutritionix:before {
  content: "\f3d6"; }
.fa-wodu:before {
  content: "\e088"; }
.fa-google-pay:before {
  content: "\e079"; }
.fa-intercom:before {
  content: "\f7af"; }
.fa-zhihu:before {
  content: "\f63f"; }
.fa-korvue:before {
  content: "\f42f"; }
.fa-pix:before {
  content: "\e43a"; }
.fa-steam-symbol:before {
  content: "\f3f6"; }
:root, :host {
  --fa-style-family-duotone: 'Font Awesome 6 Duotone';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone'; }
@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('fa-duotone-900.d87ee5c7c02d2920c131.woff2') format("woff2"), url('fa-duotone-900.9a9b1affef09fa1b6ed1.ttf') format("truetype"); }
.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal; }
.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1); }
.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4); }
.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4); }
.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1); }
.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff); }
.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute; }
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen readers do not read off random characters that represent icons */
.fad.fa-0::after, .fa-duotone.fa-0::after {
  content: "\30\30"; }
.fad.fa-1::after, .fa-duotone.fa-1::after {
  content: "\31\31"; }
.fad.fa-2::after, .fa-duotone.fa-2::after {
  content: "\32\32"; }
.fad.fa-3::after, .fa-duotone.fa-3::after {
  content: "\33\33"; }
.fad.fa-4::after, .fa-duotone.fa-4::after {
  content: "\34\34"; }
.fad.fa-5::after, .fa-duotone.fa-5::after {
  content: "\35\35"; }
.fad.fa-6::after, .fa-duotone.fa-6::after {
  content: "\36\36"; }
.fad.fa-7::after, .fa-duotone.fa-7::after {
  content: "\37\37"; }
.fad.fa-8::after, .fa-duotone.fa-8::after {
  content: "\38\38"; }
.fad.fa-9::after, .fa-duotone.fa-9::after {
  content: "\39\39"; }
.fad.fa-fill-drip::after, .fa-duotone.fa-fill-drip::after {
  content: "\f576\f576"; }
.fad.fa-arrows-to-circle::after, .fa-duotone.fa-arrows-to-circle::after {
  content: "\e4bd\e4bd"; }
.fad.fa-circle-chevron-right::after, .fa-duotone.fa-circle-chevron-right::after {
  content: "\f138\f138"; }
.fad.fa-chevron-circle-right::after, .fa-duotone.fa-chevron-circle-right::after {
  content: "\f138\f138"; }
.fad.fa-wagon-covered::after, .fa-duotone.fa-wagon-covered::after {
  content: "\f8ee\f8ee"; }
.fad.fa-line-height::after, .fa-duotone.fa-line-height::after {
  content: "\f871\f871"; }
.fad.fa-bagel::after, .fa-duotone.fa-bagel::after {
  content: "\e3d7\e3d7"; }
.fad.fa-transporter-7::after, .fa-duotone.fa-transporter-7::after {
  content: "\e2a8\e2a8"; }
.fad.fa-at::after, .fa-duotone.fa-at::after {
  content: "\40\40"; }
.fad.fa-rectangles-mixed::after, .fa-duotone.fa-rectangles-mixed::after {
  content: "\e323\e323"; }
.fad.fa-phone-arrow-up-right::after, .fa-duotone.fa-phone-arrow-up-right::after {
  content: "\e224\e224"; }
.fad.fa-phone-arrow-up::after, .fa-duotone.fa-phone-arrow-up::after {
  content: "\e224\e224"; }
.fad.fa-phone-outgoing::after, .fa-duotone.fa-phone-outgoing::after {
  content: "\e224\e224"; }
.fad.fa-trash-can::after, .fa-duotone.fa-trash-can::after {
  content: "\f2ed\f2ed"; }
.fad.fa-trash-alt::after, .fa-duotone.fa-trash-alt::after {
  content: "\f2ed\f2ed"; }
.fad.fa-circle-l::after, .fa-duotone.fa-circle-l::after {
  content: "\e114\e114"; }
.fad.fa-head-side-goggles::after, .fa-duotone.fa-head-side-goggles::after {
  content: "\f6ea\f6ea"; }
.fad.fa-head-vr::after, .fa-duotone.fa-head-vr::after {
  content: "\f6ea\f6ea"; }
.fad.fa-text-height::after, .fa-duotone.fa-text-height::after {
  content: "\f034\f034"; }
.fad.fa-user-xmark::after, .fa-duotone.fa-user-xmark::after {
  content: "\f235\f235"; }
.fad.fa-user-times::after, .fa-duotone.fa-user-times::after {
  content: "\f235\f235"; }
.fad.fa-face-hand-yawn::after, .fa-duotone.fa-face-hand-yawn::after {
  content: "\e379\e379"; }
.fad.fa-gauge-simple-min::after, .fa-duotone.fa-gauge-simple-min::after {
  content: "\f62d\f62d"; }
.fad.fa-tachometer-slowest::after, .fa-duotone.fa-tachometer-slowest::after {
  content: "\f62d\f62d"; }
.fad.fa-stethoscope::after, .fa-duotone.fa-stethoscope::after {
  content: "\f0f1\f0f1"; }
.fad.fa-coffin::after, .fa-duotone.fa-coffin::after {
  content: "\f6c6\f6c6"; }
.fad.fa-message::after, .fa-duotone.fa-message::after {
  content: "\f27a\f27a"; }
.fad.fa-comment-alt::after, .fa-duotone.fa-comment-alt::after {
  content: "\f27a\f27a"; }
.fad.fa-salad::after, .fa-duotone.fa-salad::after {
  content: "\f81e\f81e"; }
.fad.fa-bowl-salad::after, .fa-duotone.fa-bowl-salad::after {
  content: "\f81e\f81e"; }
.fad.fa-info::after, .fa-duotone.fa-info::after {
  content: "\f129\f129"; }
.fad.fa-robot-astromech::after, .fa-duotone.fa-robot-astromech::after {
  content: "\e2d2\e2d2"; }
.fad.fa-ring-diamond::after, .fa-duotone.fa-ring-diamond::after {
  content: "\e5ab\e5ab"; }
.fad.fa-fondue-pot::after, .fa-duotone.fa-fondue-pot::after {
  content: "\e40d\e40d"; }
.fad.fa-theta::after, .fa-duotone.fa-theta::after {
  content: "\f69e\f69e"; }
.fad.fa-face-hand-peeking::after, .fa-duotone.fa-face-hand-peeking::after {
  content: "\e481\e481"; }
.fad.fa-square-user::after, .fa-duotone.fa-square-user::after {
  content: "\e283\e283"; }
.fad.fa-down-left-and-up-right-to-center::after, .fa-duotone.fa-down-left-and-up-right-to-center::after {
  content: "\f422\f422"; }
.fad.fa-compress-alt::after, .fa-duotone.fa-compress-alt::after {
  content: "\f422\f422"; }
.fad.fa-explosion::after, .fa-duotone.fa-explosion::after {
  content: "\e4e9\e4e9"; }
.fad.fa-file-lines::after, .fa-duotone.fa-file-lines::after {
  content: "\f15c\f15c"; }
.fad.fa-file-alt::after, .fa-duotone.fa-file-alt::after {
  content: "\f15c\f15c"; }
.fad.fa-file-text::after, .fa-duotone.fa-file-text::after {
  content: "\f15c\f15c"; }
.fad.fa-wave-square::after, .fa-duotone.fa-wave-square::after {
  content: "\f83e\f83e"; }
.fad.fa-ring::after, .fa-duotone.fa-ring::after {
  content: "\f70b\f70b"; }
.fad.fa-building-un::after, .fa-duotone.fa-building-un::after {
  content: "\e4d9\e4d9"; }
.fad.fa-dice-three::after, .fa-duotone.fa-dice-three::after {
  content: "\f527\f527"; }
.fad.fa-tire-pressure-warning::after, .fa-duotone.fa-tire-pressure-warning::after {
  content: "\f633\f633"; }
.fad.fa-wifi-fair::after, .fa-duotone.fa-wifi-fair::after {
  content: "\f6ab\f6ab"; }
.fad.fa-wifi-2::after, .fa-duotone.fa-wifi-2::after {
  content: "\f6ab\f6ab"; }
.fad.fa-calendar-days::after, .fa-duotone.fa-calendar-days::after {
  content: "\f073\f073"; }
.fad.fa-calendar-alt::after, .fa-duotone.fa-calendar-alt::after {
  content: "\f073\f073"; }
.fad.fa-mp3-player::after, .fa-duotone.fa-mp3-player::after {
  content: "\f8ce\f8ce"; }
.fad.fa-anchor-circle-check::after, .fa-duotone.fa-anchor-circle-check::after {
  content: "\e4aa\e4aa"; }
.fad.fa-tally-4::after, .fa-duotone.fa-tally-4::after {
  content: "\e297\e297"; }
.fad.fa-rectangle-history::after, .fa-duotone.fa-rectangle-history::after {
  content: "\e4a2\e4a2"; }
.fad.fa-building-circle-arrow-right::after, .fa-duotone.fa-building-circle-arrow-right::after {
  content: "\e4d1\e4d1"; }
.fad.fa-volleyball::after, .fa-duotone.fa-volleyball::after {
  content: "\f45f\f45f"; }
.fad.fa-volleyball-ball::after, .fa-duotone.fa-volleyball-ball::after {
  content: "\f45f\f45f"; }
.fad.fa-sun-haze::after, .fa-duotone.fa-sun-haze::after {
  content: "\f765\f765"; }
.fad.fa-text-size::after, .fa-duotone.fa-text-size::after {
  content: "\f894\f894"; }
.fad.fa-ufo::after, .fa-duotone.fa-ufo::after {
  content: "\e047\e047"; }
.fad.fa-fork::after, .fa-duotone.fa-fork::after {
  content: "\f2e3\f2e3"; }
.fad.fa-utensil-fork::after, .fa-duotone.fa-utensil-fork::after {
  content: "\f2e3\f2e3"; }
.fad.fa-arrows-up-to-line::after, .fa-duotone.fa-arrows-up-to-line::after {
  content: "\e4c2\e4c2"; }
.fad.fa-mobile-signal::after, .fa-duotone.fa-mobile-signal::after {
  content: "\e1ef\e1ef"; }
.fad.fa-barcode-scan::after, .fa-duotone.fa-barcode-scan::after {
  content: "\f465\f465"; }
.fad.fa-sort-down::after, .fa-duotone.fa-sort-down::after {
  content: "\f0dd\f0dd"; }
.fad.fa-sort-desc::after, .fa-duotone.fa-sort-desc::after {
  content: "\f0dd\f0dd"; }
.fad.fa-folder-arrow-down::after, .fa-duotone.fa-folder-arrow-down::after {
  content: "\e053\e053"; }
.fad.fa-folder-download::after, .fa-duotone.fa-folder-download::after {
  content: "\e053\e053"; }
.fad.fa-circle-minus::after, .fa-duotone.fa-circle-minus::after {
  content: "\f056\f056"; }
.fad.fa-minus-circle::after, .fa-duotone.fa-minus-circle::after {
  content: "\f056\f056"; }
.fad.fa-face-icicles::after, .fa-duotone.fa-face-icicles::after {
  content: "\e37c\e37c"; }
.fad.fa-shovel::after, .fa-duotone.fa-shovel::after {
  content: "\f713\f713"; }
.fad.fa-door-open::after, .fa-duotone.fa-door-open::after {
  content: "\f52b\f52b"; }
.fad.fa-films::after, .fa-duotone.fa-films::after {
  content: "\e17a\e17a"; }
.fad.fa-right-from-bracket::after, .fa-duotone.fa-right-from-bracket::after {
  content: "\f2f5\f2f5"; }
.fad.fa-sign-out-alt::after, .fa-duotone.fa-sign-out-alt::after {
  content: "\f2f5\f2f5"; }
.fad.fa-face-glasses::after, .fa-duotone.fa-face-glasses::after {
  content: "\e377\e377"; }
.fad.fa-nfc::after, .fa-duotone.fa-nfc::after {
  content: "\e1f7\e1f7"; }
.fad.fa-atom::after, .fa-duotone.fa-atom::after {
  content: "\f5d2\f5d2"; }
.fad.fa-soap::after, .fa-duotone.fa-soap::after {
  content: "\e06e\e06e"; }
.fad.fa-icons::after, .fa-duotone.fa-icons::after {
  content: "\f86d\f86d"; }
.fad.fa-heart-music-camera-bolt::after, .fa-duotone.fa-heart-music-camera-bolt::after {
  content: "\f86d\f86d"; }
.fad.fa-microphone-lines-slash::after, .fa-duotone.fa-microphone-lines-slash::after {
  content: "\f539\f539"; }
.fad.fa-microphone-alt-slash::after, .fa-duotone.fa-microphone-alt-slash::after {
  content: "\f539\f539"; }
.fad.fa-closed-captioning-slash::after, .fa-duotone.fa-closed-captioning-slash::after {
  content: "\e135\e135"; }
.fad.fa-calculator-simple::after, .fa-duotone.fa-calculator-simple::after {
  content: "\f64c\f64c"; }
.fad.fa-calculator-alt::after, .fa-duotone.fa-calculator-alt::after {
  content: "\f64c\f64c"; }
.fad.fa-bridge-circle-check::after, .fa-duotone.fa-bridge-circle-check::after {
  content: "\e4c9\e4c9"; }
.fad.fa-sliders-up::after, .fa-duotone.fa-sliders-up::after {
  content: "\f3f1\f3f1"; }
.fad.fa-sliders-v::after, .fa-duotone.fa-sliders-v::after {
  content: "\f3f1\f3f1"; }
.fad.fa-location-minus::after, .fa-duotone.fa-location-minus::after {
  content: "\f609\f609"; }
.fad.fa-map-marker-minus::after, .fa-duotone.fa-map-marker-minus::after {
  content: "\f609\f609"; }
.fad.fa-pump-medical::after, .fa-duotone.fa-pump-medical::after {
  content: "\e06a\e06a"; }
.fad.fa-fingerprint::after, .fa-duotone.fa-fingerprint::after {
  content: "\f577\f577"; }
.fad.fa-ski-boot::after, .fa-duotone.fa-ski-boot::after {
  content: "\e3cc\e3cc"; }
.fad.fa-standard-definition::after, .fa-duotone.fa-standard-definition::after {
  content: "\e28a\e28a"; }
.fad.fa-rectangle-sd::after, .fa-duotone.fa-rectangle-sd::after {
  content: "\e28a\e28a"; }
.fad.fa-h1::after, .fa-duotone.fa-h1::after {
  content: "\f313\f313"; }
.fad.fa-hand-point-right::after, .fa-duotone.fa-hand-point-right::after {
  content: "\f0a4\f0a4"; }
.fad.fa-magnifying-glass-location::after, .fa-duotone.fa-magnifying-glass-location::after {
  content: "\f689\f689"; }
.fad.fa-search-location::after, .fa-duotone.fa-search-location::after {
  content: "\f689\f689"; }
.fad.fa-message-bot::after, .fa-duotone.fa-message-bot::after {
  content: "\e3b8\e3b8"; }
.fad.fa-forward-step::after, .fa-duotone.fa-forward-step::after {
  content: "\f051\f051"; }
.fad.fa-step-forward::after, .fa-duotone.fa-step-forward::after {
  content: "\f051\f051"; }
.fad.fa-face-smile-beam::after, .fa-duotone.fa-face-smile-beam::after {
  content: "\f5b8\f5b8"; }
.fad.fa-smile-beam::after, .fa-duotone.fa-smile-beam::after {
  content: "\f5b8\f5b8"; }
.fad.fa-light-ceiling::after, .fa-duotone.fa-light-ceiling::after {
  content: "\e016\e016"; }
.fad.fa-message-exclamation::after, .fa-duotone.fa-message-exclamation::after {
  content: "\f4a5\f4a5"; }
.fad.fa-comment-alt-exclamation::after, .fa-duotone.fa-comment-alt-exclamation::after {
  content: "\f4a5\f4a5"; }
.fad.fa-bowl-scoop::after, .fa-duotone.fa-bowl-scoop::after {
  content: "\e3de\e3de"; }
.fad.fa-bowl-shaved-ice::after, .fa-duotone.fa-bowl-shaved-ice::after {
  content: "\e3de\e3de"; }
.fad.fa-square-x::after, .fa-duotone.fa-square-x::after {
  content: "\e286\e286"; }
.fad.fa-utility-pole-double::after, .fa-duotone.fa-utility-pole-double::after {
  content: "\e2c4\e2c4"; }
.fad.fa-flag-checkered::after, .fa-duotone.fa-flag-checkered::after {
  content: "\f11e\f11e"; }
.fad.fa-chevrons-up::after, .fa-duotone.fa-chevrons-up::after {
  content: "\f325\f325"; }
.fad.fa-chevron-double-up::after, .fa-duotone.fa-chevron-double-up::after {
  content: "\f325\f325"; }
.fad.fa-football::after, .fa-duotone.fa-football::after {
  content: "\f44e\f44e"; }
.fad.fa-football-ball::after, .fa-duotone.fa-football-ball::after {
  content: "\f44e\f44e"; }
.fad.fa-user-vneck::after, .fa-duotone.fa-user-vneck::after {
  content: "\e461\e461"; }
.fad.fa-school-circle-exclamation::after, .fa-duotone.fa-school-circle-exclamation::after {
  content: "\e56c\e56c"; }
.fad.fa-crop::after, .fa-duotone.fa-crop::after {
  content: "\f125\f125"; }
.fad.fa-angles-down::after, .fa-duotone.fa-angles-down::after {
  content: "\f103\f103"; }
.fad.fa-angle-double-down::after, .fa-duotone.fa-angle-double-down::after {
  content: "\f103\f103"; }
.fad.fa-users-rectangle::after, .fa-duotone.fa-users-rectangle::after {
  content: "\e594\e594"; }
.fad.fa-people-roof::after, .fa-duotone.fa-people-roof::after {
  content: "\e537\e537"; }
.fad.fa-square-arrow-right::after, .fa-duotone.fa-square-arrow-right::after {
  content: "\f33b\f33b"; }
.fad.fa-arrow-square-right::after, .fa-duotone.fa-arrow-square-right::after {
  content: "\f33b\f33b"; }
.fad.fa-location-plus::after, .fa-duotone.fa-location-plus::after {
  content: "\f60a\f60a"; }
.fad.fa-map-marker-plus::after, .fa-duotone.fa-map-marker-plus::after {
  content: "\f60a\f60a"; }
.fad.fa-lightbulb-exclamation-on::after, .fa-duotone.fa-lightbulb-exclamation-on::after {
  content: "\e1ca\e1ca"; }
.fad.fa-people-line::after, .fa-duotone.fa-people-line::after {
  content: "\e534\e534"; }
.fad.fa-beer-mug-empty::after, .fa-duotone.fa-beer-mug-empty::after {
  content: "\f0fc\f0fc"; }
.fad.fa-beer::after, .fa-duotone.fa-beer::after {
  content: "\f0fc\f0fc"; }
.fad.fa-crate-empty::after, .fa-duotone.fa-crate-empty::after {
  content: "\e151\e151"; }
.fad.fa-diagram-predecessor::after, .fa-duotone.fa-diagram-predecessor::after {
  content: "\e477\e477"; }
.fad.fa-transporter::after, .fa-duotone.fa-transporter::after {
  content: "\e042\e042"; }
.fad.fa-calendar-circle-user::after, .fa-duotone.fa-calendar-circle-user::after {
  content: "\e471\e471"; }
.fad.fa-arrow-up-long::after, .fa-duotone.fa-arrow-up-long::after {
  content: "\f176\f176"; }
.fad.fa-long-arrow-up::after, .fa-duotone.fa-long-arrow-up::after {
  content: "\f176\f176"; }
.fad.fa-person-carry-box::after, .fa-duotone.fa-person-carry-box::after {
  content: "\f4cf\f4cf"; }
.fad.fa-person-carry::after, .fa-duotone.fa-person-carry::after {
  content: "\f4cf\f4cf"; }
.fad.fa-fire-flame-simple::after, .fa-duotone.fa-fire-flame-simple::after {
  content: "\f46a\f46a"; }
.fad.fa-burn::after, .fa-duotone.fa-burn::after {
  content: "\f46a\f46a"; }
.fad.fa-person::after, .fa-duotone.fa-person::after {
  content: "\f183\f183"; }
.fad.fa-male::after, .fa-duotone.fa-male::after {
  content: "\f183\f183"; }
.fad.fa-laptop::after, .fa-duotone.fa-laptop::after {
  content: "\f109\f109"; }
.fad.fa-file-csv::after, .fa-duotone.fa-file-csv::after {
  content: "\f6dd\f6dd"; }
.fad.fa-menorah::after, .fa-duotone.fa-menorah::after {
  content: "\f676\f676"; }
.fad.fa-union::after, .fa-duotone.fa-union::after {
  content: "\f6a2\f6a2"; }
.fad.fa-chevrons-left::after, .fa-duotone.fa-chevrons-left::after {
  content: "\f323\f323"; }
.fad.fa-chevron-double-left::after, .fa-duotone.fa-chevron-double-left::after {
  content: "\f323\f323"; }
.fad.fa-circle-heart::after, .fa-duotone.fa-circle-heart::after {
  content: "\f4c7\f4c7"; }
.fad.fa-heart-circle::after, .fa-duotone.fa-heart-circle::after {
  content: "\f4c7\f4c7"; }
.fad.fa-truck-plane::after, .fa-duotone.fa-truck-plane::after {
  content: "\e58f\e58f"; }
.fad.fa-record-vinyl::after, .fa-duotone.fa-record-vinyl::after {
  content: "\f8d9\f8d9"; }
.fad.fa-bring-forward::after, .fa-duotone.fa-bring-forward::after {
  content: "\f856\f856"; }
.fad.fa-square-p::after, .fa-duotone.fa-square-p::after {
  content: "\e279\e279"; }
.fad.fa-face-grin-stars::after, .fa-duotone.fa-face-grin-stars::after {
  content: "\f587\f587"; }
.fad.fa-grin-stars::after, .fa-duotone.fa-grin-stars::after {
  content: "\f587\f587"; }
.fad.fa-sigma::after, .fa-duotone.fa-sigma::after {
  content: "\f68b\f68b"; }
.fad.fa-camera-movie::after, .fa-duotone.fa-camera-movie::after {
  content: "\f8a9\f8a9"; }
.fad.fa-bong::after, .fa-duotone.fa-bong::after {
  content: "\f55c\f55c"; }
.fad.fa-clarinet::after, .fa-duotone.fa-clarinet::after {
  content: "\f8ad\f8ad"; }
.fad.fa-truck-flatbed::after, .fa-duotone.fa-truck-flatbed::after {
  content: "\e2b6\e2b6"; }
.fad.fa-spaghetti-monster-flying::after, .fa-duotone.fa-spaghetti-monster-flying::after {
  content: "\f67b\f67b"; }
.fad.fa-pastafarianism::after, .fa-duotone.fa-pastafarianism::after {
  content: "\f67b\f67b"; }
.fad.fa-arrow-down-up-across-line::after, .fa-duotone.fa-arrow-down-up-across-line::after {
  content: "\e4af\e4af"; }
.fad.fa-leaf-heart::after, .fa-duotone.fa-leaf-heart::after {
  content: "\f4cb\f4cb"; }
.fad.fa-house-building::after, .fa-duotone.fa-house-building::after {
  content: "\e1b1\e1b1"; }
.fad.fa-cheese-swiss::after, .fa-duotone.fa-cheese-swiss::after {
  content: "\f7f0\f7f0"; }
.fad.fa-spoon::after, .fa-duotone.fa-spoon::after {
  content: "\f2e5\f2e5"; }
.fad.fa-utensil-spoon::after, .fa-duotone.fa-utensil-spoon::after {
  content: "\f2e5\f2e5"; }
.fad.fa-jar-wheat::after, .fa-duotone.fa-jar-wheat::after {
  content: "\e517\e517"; }
.fad.fa-envelopes-bulk::after, .fa-duotone.fa-envelopes-bulk::after {
  content: "\f674\f674"; }
.fad.fa-mail-bulk::after, .fa-duotone.fa-mail-bulk::after {
  content: "\f674\f674"; }
.fad.fa-file-circle-exclamation::after, .fa-duotone.fa-file-circle-exclamation::after {
  content: "\e4eb\e4eb"; }
.fad.fa-bow-arrow::after, .fa-duotone.fa-bow-arrow::after {
  content: "\f6b9\f6b9"; }
.fad.fa-cart-xmark::after, .fa-duotone.fa-cart-xmark::after {
  content: "\e0dd\e0dd"; }
.fad.fa-hexagon-xmark::after, .fa-duotone.fa-hexagon-xmark::after {
  content: "\f2ee\f2ee"; }
.fad.fa-times-hexagon::after, .fa-duotone.fa-times-hexagon::after {
  content: "\f2ee\f2ee"; }
.fad.fa-xmark-hexagon::after, .fa-duotone.fa-xmark-hexagon::after {
  content: "\f2ee\f2ee"; }
.fad.fa-circle-h::after, .fa-duotone.fa-circle-h::after {
  content: "\f47e\f47e"; }
.fad.fa-hospital-symbol::after, .fa-duotone.fa-hospital-symbol::after {
  content: "\f47e\f47e"; }
.fad.fa-merge::after, .fa-duotone.fa-merge::after {
  content: "\e526\e526"; }
.fad.fa-pager::after, .fa-duotone.fa-pager::after {
  content: "\f815\f815"; }
.fad.fa-cart-minus::after, .fa-duotone.fa-cart-minus::after {
  content: "\e0db\e0db"; }
.fad.fa-address-book::after, .fa-duotone.fa-address-book::after {
  content: "\f2b9\f2b9"; }
.fad.fa-contact-book::after, .fa-duotone.fa-contact-book::after {
  content: "\f2b9\f2b9"; }
.fad.fa-pan-frying::after, .fa-duotone.fa-pan-frying::after {
  content: "\e42c\e42c"; }
.fad.fa-grid::after, .fa-duotone.fa-grid::after {
  content: "\e195\e195"; }
.fad.fa-grid-3::after, .fa-duotone.fa-grid-3::after {
  content: "\e195\e195"; }
.fad.fa-football-helmet::after, .fa-duotone.fa-football-helmet::after {
  content: "\f44f\f44f"; }
.fad.fa-hand-love::after, .fa-duotone.fa-hand-love::after {
  content: "\e1a5\e1a5"; }
.fad.fa-trees::after, .fa-duotone.fa-trees::after {
  content: "\f724\f724"; }
.fad.fa-strikethrough::after, .fa-duotone.fa-strikethrough::after {
  content: "\f0cc\f0cc"; }
.fad.fa-page::after, .fa-duotone.fa-page::after {
  content: "\e428\e428"; }
.fad.fa-k::after, .fa-duotone.fa-k::after {
  content: "\4b\4b"; }
.fad.fa-diagram-previous::after, .fa-duotone.fa-diagram-previous::after {
  content: "\e478\e478"; }
.fad.fa-gauge-min::after, .fa-duotone.fa-gauge-min::after {
  content: "\f628\f628"; }
.fad.fa-tachometer-alt-slowest::after, .fa-duotone.fa-tachometer-alt-slowest::after {
  content: "\f628\f628"; }
.fad.fa-folder-grid::after, .fa-duotone.fa-folder-grid::after {
  content: "\e188\e188"; }
.fad.fa-eggplant::after, .fa-duotone.fa-eggplant::after {
  content: "\e16c\e16c"; }
.fad.fa-ram::after, .fa-duotone.fa-ram::after {
  content: "\f70a\f70a"; }
.fad.fa-landmark-flag::after, .fa-duotone.fa-landmark-flag::after {
  content: "\e51c\e51c"; }
.fad.fa-lips::after, .fa-duotone.fa-lips::after {
  content: "\f600\f600"; }
.fad.fa-pencil::after, .fa-duotone.fa-pencil::after {
  content: "\f303\f303"; }
.fad.fa-pencil-alt::after, .fa-duotone.fa-pencil-alt::after {
  content: "\f303\f303"; }
.fad.fa-backward::after, .fa-duotone.fa-backward::after {
  content: "\f04a\f04a"; }
.fad.fa-caret-right::after, .fa-duotone.fa-caret-right::after {
  content: "\f0da\f0da"; }
.fad.fa-comments::after, .fa-duotone.fa-comments::after {
  content: "\f086\f086"; }
.fad.fa-paste::after, .fa-duotone.fa-paste::after {
  content: "\f0ea\f0ea"; }
.fad.fa-file-clipboard::after, .fa-duotone.fa-file-clipboard::after {
  content: "\f0ea\f0ea"; }
.fad.fa-desktop-arrow-down::after, .fa-duotone.fa-desktop-arrow-down::after {
  content: "\e155\e155"; }
.fad.fa-code-pull-request::after, .fa-duotone.fa-code-pull-request::after {
  content: "\e13c\e13c"; }
.fad.fa-pumpkin::after, .fa-duotone.fa-pumpkin::after {
  content: "\f707\f707"; }
.fad.fa-clipboard-list::after, .fa-duotone.fa-clipboard-list::after {
  content: "\f46d\f46d"; }
.fad.fa-pen-field::after, .fa-duotone.fa-pen-field::after {
  content: "\e211\e211"; }
.fad.fa-blueberries::after, .fa-duotone.fa-blueberries::after {
  content: "\e2e8\e2e8"; }
.fad.fa-truck-ramp-box::after, .fa-duotone.fa-truck-ramp-box::after {
  content: "\f4de\f4de"; }
.fad.fa-truck-loading::after, .fa-duotone.fa-truck-loading::after {
  content: "\f4de\f4de"; }
.fad.fa-note::after, .fa-duotone.fa-note::after {
  content: "\e1ff\e1ff"; }
.fad.fa-arrow-down-to-square::after, .fa-duotone.fa-arrow-down-to-square::after {
  content: "\e096\e096"; }
.fad.fa-user-check::after, .fa-duotone.fa-user-check::after {
  content: "\f4fc\f4fc"; }
.fad.fa-cloud-xmark::after, .fa-duotone.fa-cloud-xmark::after {
  content: "\e35f\e35f"; }
.fad.fa-vial-virus::after, .fa-duotone.fa-vial-virus::after {
  content: "\e597\e597"; }
.fad.fa-book-blank::after, .fa-duotone.fa-book-blank::after {
  content: "\f5d9\f5d9"; }
.fad.fa-book-alt::after, .fa-duotone.fa-book-alt::after {
  content: "\f5d9\f5d9"; }
.fad.fa-golf-flag-hole::after, .fa-duotone.fa-golf-flag-hole::after {
  content: "\e3ac\e3ac"; }
.fad.fa-message-arrow-down::after, .fa-duotone.fa-message-arrow-down::after {
  content: "\e1db\e1db"; }
.fad.fa-comment-alt-arrow-down::after, .fa-duotone.fa-comment-alt-arrow-down::after {
  content: "\e1db\e1db"; }
.fad.fa-face-unamused::after, .fa-duotone.fa-face-unamused::after {
  content: "\e39f\e39f"; }
.fad.fa-sheet-plastic::after, .fa-duotone.fa-sheet-plastic::after {
  content: "\e571\e571"; }
.fad.fa-circle-9::after, .fa-duotone.fa-circle-9::after {
  content: "\e0f6\e0f6"; }
.fad.fa-blog::after, .fa-duotone.fa-blog::after {
  content: "\f781\f781"; }
.fad.fa-user-ninja::after, .fa-duotone.fa-user-ninja::after {
  content: "\f504\f504"; }
.fad.fa-pencil-slash::after, .fa-duotone.fa-pencil-slash::after {
  content: "\e215\e215"; }
.fad.fa-bowling-pins::after, .fa-duotone.fa-bowling-pins::after {
  content: "\f437\f437"; }
.fad.fa-person-arrow-up-from-line::after, .fa-duotone.fa-person-arrow-up-from-line::after {
  content: "\e539\e539"; }
.fad.fa-down-right::after, .fa-duotone.fa-down-right::after {
  content: "\e16b\e16b"; }
.fad.fa-scroll-torah::after, .fa-duotone.fa-scroll-torah::after {
  content: "\f6a0\f6a0"; }
.fad.fa-torah::after, .fa-duotone.fa-torah::after {
  content: "\f6a0\f6a0"; }
.fad.fa-blinds-open::after, .fa-duotone.fa-blinds-open::after {
  content: "\f8fc\f8fc"; }
.fad.fa-fence::after, .fa-duotone.fa-fence::after {
  content: "\e303\e303"; }
.fad.fa-up::after, .fa-duotone.fa-up::after {
  content: "\f357\f357"; }
.fad.fa-arrow-alt-up::after, .fa-duotone.fa-arrow-alt-up::after {
  content: "\f357\f357"; }
.fad.fa-broom-ball::after, .fa-duotone.fa-broom-ball::after {
  content: "\f458\f458"; }
.fad.fa-quidditch::after, .fa-duotone.fa-quidditch::after {
  content: "\f458\f458"; }
.fad.fa-quidditch-broom-ball::after, .fa-duotone.fa-quidditch-broom-ball::after {
  content: "\f458\f458"; }
.fad.fa-drumstick::after, .fa-duotone.fa-drumstick::after {
  content: "\f6d6\f6d6"; }
.fad.fa-square-v::after, .fa-duotone.fa-square-v::after {
  content: "\e284\e284"; }
.fad.fa-face-awesome::after, .fa-duotone.fa-face-awesome::after {
  content: "\e409\e409"; }
.fad.fa-gave-dandy::after, .fa-duotone.fa-gave-dandy::after {
  content: "\e409\e409"; }
.fad.fa-dial-off::after, .fa-duotone.fa-dial-off::after {
  content: "\e162\e162"; }
.fad.fa-toggle-off::after, .fa-duotone.fa-toggle-off::after {
  content: "\f204\f204"; }
.fad.fa-face-smile-horns::after, .fa-duotone.fa-face-smile-horns::after {
  content: "\e391\e391"; }
.fad.fa-box-archive::after, .fa-duotone.fa-box-archive::after {
  content: "\f187\f187"; }
.fad.fa-archive::after, .fa-duotone.fa-archive::after {
  content: "\f187\f187"; }
.fad.fa-grapes::after, .fa-duotone.fa-grapes::after {
  content: "\e306\e306"; }
.fad.fa-person-drowning::after, .fa-duotone.fa-person-drowning::after {
  content: "\e545\e545"; }
.fad.fa-dial-max::after, .fa-duotone.fa-dial-max::after {
  content: "\e15e\e15e"; }
.fad.fa-circle-m::after, .fa-duotone.fa-circle-m::after {
  content: "\e115\e115"; }
.fad.fa-calendar-image::after, .fa-duotone.fa-calendar-image::after {
  content: "\e0d4\e0d4"; }
.fad.fa-circle-caret-down::after, .fa-duotone.fa-circle-caret-down::after {
  content: "\f32d\f32d"; }
.fad.fa-caret-circle-down::after, .fa-duotone.fa-caret-circle-down::after {
  content: "\f32d\f32d"; }
.fad.fa-arrow-down-9-1::after, .fa-duotone.fa-arrow-down-9-1::after {
  content: "\f886\f886"; }
.fad.fa-sort-numeric-desc::after, .fa-duotone.fa-sort-numeric-desc::after {
  content: "\f886\f886"; }
.fad.fa-sort-numeric-down-alt::after, .fa-duotone.fa-sort-numeric-down-alt::after {
  content: "\f886\f886"; }
.fad.fa-face-grin-tongue-squint::after, .fa-duotone.fa-face-grin-tongue-squint::after {
  content: "\f58a\f58a"; }
.fad.fa-grin-tongue-squint::after, .fa-duotone.fa-grin-tongue-squint::after {
  content: "\f58a\f58a"; }
.fad.fa-shish-kebab::after, .fa-duotone.fa-shish-kebab::after {
  content: "\f821\f821"; }
.fad.fa-spray-can::after, .fa-duotone.fa-spray-can::after {
  content: "\f5bd\f5bd"; }
.fad.fa-alarm-snooze::after, .fa-duotone.fa-alarm-snooze::after {
  content: "\f845\f845"; }
.fad.fa-scarecrow::after, .fa-duotone.fa-scarecrow::after {
  content: "\f70d\f70d"; }
.fad.fa-truck-monster::after, .fa-duotone.fa-truck-monster::after {
  content: "\f63b\f63b"; }
.fad.fa-gift-card::after, .fa-duotone.fa-gift-card::after {
  content: "\f663\f663"; }
.fad.fa-w::after, .fa-duotone.fa-w::after {
  content: "\57\57"; }
.fad.fa-code-pull-request-draft::after, .fa-duotone.fa-code-pull-request-draft::after {
  content: "\e3fa\e3fa"; }
.fad.fa-square-b::after, .fa-duotone.fa-square-b::after {
  content: "\e264\e264"; }
.fad.fa-elephant::after, .fa-duotone.fa-elephant::after {
  content: "\f6da\f6da"; }
.fad.fa-earth-africa::after, .fa-duotone.fa-earth-africa::after {
  content: "\f57c\f57c"; }
.fad.fa-globe-africa::after, .fa-duotone.fa-globe-africa::after {
  content: "\f57c\f57c"; }
.fad.fa-rainbow::after, .fa-duotone.fa-rainbow::after {
  content: "\f75b\f75b"; }
.fad.fa-circle-notch::after, .fa-duotone.fa-circle-notch::after {
  content: "\f1ce\f1ce"; }
.fad.fa-tablet-screen-button::after, .fa-duotone.fa-tablet-screen-button::after {
  content: "\f3fa\f3fa"; }
.fad.fa-tablet-alt::after, .fa-duotone.fa-tablet-alt::after {
  content: "\f3fa\f3fa"; }
.fad.fa-paw::after, .fa-duotone.fa-paw::after {
  content: "\f1b0\f1b0"; }
.fad.fa-message-question::after, .fa-duotone.fa-message-question::after {
  content: "\e1e3\e1e3"; }
.fad.fa-cloud::after, .fa-duotone.fa-cloud::after {
  content: "\f0c2\f0c2"; }
.fad.fa-trowel-bricks::after, .fa-duotone.fa-trowel-bricks::after {
  content: "\e58a\e58a"; }
.fad.fa-square-3::after, .fa-duotone.fa-square-3::after {
  content: "\e258\e258"; }
.fad.fa-face-flushed::after, .fa-duotone.fa-face-flushed::after {
  content: "\f579\f579"; }
.fad.fa-flushed::after, .fa-duotone.fa-flushed::after {
  content: "\f579\f579"; }
.fad.fa-hospital-user::after, .fa-duotone.fa-hospital-user::after {
  content: "\f80d\f80d"; }
.fad.fa-microwave::after, .fa-duotone.fa-microwave::after {
  content: "\e01b\e01b"; }
.fad.fa-tent-arrow-left-right::after, .fa-duotone.fa-tent-arrow-left-right::after {
  content: "\e57f\e57f"; }
.fad.fa-cart-circle-arrow-up::after, .fa-duotone.fa-cart-circle-arrow-up::after {
  content: "\e3f0\e3f0"; }
.fad.fa-trash-clock::after, .fa-duotone.fa-trash-clock::after {
  content: "\e2b0\e2b0"; }
.fad.fa-gavel::after, .fa-duotone.fa-gavel::after {
  content: "\f0e3\f0e3"; }
.fad.fa-legal::after, .fa-duotone.fa-legal::after {
  content: "\f0e3\f0e3"; }
.fad.fa-sprinkler-ceiling::after, .fa-duotone.fa-sprinkler-ceiling::after {
  content: "\e44c\e44c"; }
.fad.fa-browsers::after, .fa-duotone.fa-browsers::after {
  content: "\e0cb\e0cb"; }
.fad.fa-trillium::after, .fa-duotone.fa-trillium::after {
  content: "\e588\e588"; }
.fad.fa-music-slash::after, .fa-duotone.fa-music-slash::after {
  content: "\f8d1\f8d1"; }
.fad.fa-truck-ramp::after, .fa-duotone.fa-truck-ramp::after {
  content: "\f4e0\f4e0"; }
.fad.fa-binoculars::after, .fa-duotone.fa-binoculars::after {
  content: "\f1e5\f1e5"; }
.fad.fa-microphone-slash::after, .fa-duotone.fa-microphone-slash::after {
  content: "\f131\f131"; }
.fad.fa-box-tissue::after, .fa-duotone.fa-box-tissue::after {
  content: "\e05b\e05b"; }
.fad.fa-circle-c::after, .fa-duotone.fa-circle-c::after {
  content: "\e101\e101"; }
.fad.fa-star-christmas::after, .fa-duotone.fa-star-christmas::after {
  content: "\f7d4\f7d4"; }
.fad.fa-chart-bullet::after, .fa-duotone.fa-chart-bullet::after {
  content: "\e0e1\e0e1"; }
.fad.fa-motorcycle::after, .fa-duotone.fa-motorcycle::after {
  content: "\f21c\f21c"; }
.fad.fa-tree-christmas::after, .fa-duotone.fa-tree-christmas::after {
  content: "\f7db\f7db"; }
.fad.fa-tire-flat::after, .fa-duotone.fa-tire-flat::after {
  content: "\f632\f632"; }
.fad.fa-sunglasses::after, .fa-duotone.fa-sunglasses::after {
  content: "\f892\f892"; }
.fad.fa-badge::after, .fa-duotone.fa-badge::after {
  content: "\f335\f335"; }
.fad.fa-message-pen::after, .fa-duotone.fa-message-pen::after {
  content: "\f4a4\f4a4"; }
.fad.fa-comment-alt-edit::after, .fa-duotone.fa-comment-alt-edit::after {
  content: "\f4a4\f4a4"; }
.fad.fa-message-edit::after, .fa-duotone.fa-message-edit::after {
  content: "\f4a4\f4a4"; }
.fad.fa-bell-concierge::after, .fa-duotone.fa-bell-concierge::after {
  content: "\f562\f562"; }
.fad.fa-concierge-bell::after, .fa-duotone.fa-concierge-bell::after {
  content: "\f562\f562"; }
.fad.fa-pen-ruler::after, .fa-duotone.fa-pen-ruler::after {
  content: "\f5ae\f5ae"; }
.fad.fa-pencil-ruler::after, .fa-duotone.fa-pencil-ruler::after {
  content: "\f5ae\f5ae"; }
.fad.fa-chess-rook-piece::after, .fa-duotone.fa-chess-rook-piece::after {
  content: "\f448\f448"; }
.fad.fa-chess-rook-alt::after, .fa-duotone.fa-chess-rook-alt::after {
  content: "\f448\f448"; }
.fad.fa-square-root::after, .fa-duotone.fa-square-root::after {
  content: "\f697\f697"; }
.fad.fa-album-collection-circle-plus::after, .fa-duotone.fa-album-collection-circle-plus::after {
  content: "\e48e\e48e"; }
.fad.fa-people-arrows::after, .fa-duotone.fa-people-arrows::after {
  content: "\e068\e068"; }
.fad.fa-people-arrows-left-right::after, .fa-duotone.fa-people-arrows-left-right::after {
  content: "\e068\e068"; }
.fad.fa-face-angry-horns::after, .fa-duotone.fa-face-angry-horns::after {
  content: "\e368\e368"; }
.fad.fa-mars-and-venus-burst::after, .fa-duotone.fa-mars-and-venus-burst::after {
  content: "\e523\e523"; }
.fad.fa-tombstone::after, .fa-duotone.fa-tombstone::after {
  content: "\f720\f720"; }
.fad.fa-square-caret-right::after, .fa-duotone.fa-square-caret-right::after {
  content: "\f152\f152"; }
.fad.fa-caret-square-right::after, .fa-duotone.fa-caret-square-right::after {
  content: "\f152\f152"; }
.fad.fa-scissors::after, .fa-duotone.fa-scissors::after {
  content: "\f0c4\f0c4"; }
.fad.fa-cut::after, .fa-duotone.fa-cut::after {
  content: "\f0c4\f0c4"; }
.fad.fa-list-music::after, .fa-duotone.fa-list-music::after {
  content: "\f8c9\f8c9"; }
.fad.fa-sun-plant-wilt::after, .fa-duotone.fa-sun-plant-wilt::after {
  content: "\e57a\e57a"; }
.fad.fa-toilets-portable::after, .fa-duotone.fa-toilets-portable::after {
  content: "\e584\e584"; }
.fad.fa-hockey-puck::after, .fa-duotone.fa-hockey-puck::after {
  content: "\f453\f453"; }
.fad.fa-mustache::after, .fa-duotone.fa-mustache::after {
  content: "\e5bc\e5bc"; }
.fad.fa-hyphen::after, .fa-duotone.fa-hyphen::after {
  content: "\2d\2d"; }
.fad.fa-table::after, .fa-duotone.fa-table::after {
  content: "\f0ce\f0ce"; }
.fad.fa-user-chef::after, .fa-duotone.fa-user-chef::after {
  content: "\e3d2\e3d2"; }
.fad.fa-message-image::after, .fa-duotone.fa-message-image::after {
  content: "\e1e0\e1e0"; }
.fad.fa-comment-alt-image::after, .fa-duotone.fa-comment-alt-image::after {
  content: "\e1e0\e1e0"; }
.fad.fa-users-medical::after, .fa-duotone.fa-users-medical::after {
  content: "\f830\f830"; }
.fad.fa-sensor-triangle-exclamation::after, .fa-duotone.fa-sensor-triangle-exclamation::after {
  content: "\e029\e029"; }
.fad.fa-sensor-alert::after, .fa-duotone.fa-sensor-alert::after {
  content: "\e029\e029"; }
.fad.fa-magnifying-glass-arrow-right::after, .fa-duotone.fa-magnifying-glass-arrow-right::after {
  content: "\e521\e521"; }
.fad.fa-tachograph-digital::after, .fa-duotone.fa-tachograph-digital::after {
  content: "\f566\f566"; }
.fad.fa-digital-tachograph::after, .fa-duotone.fa-digital-tachograph::after {
  content: "\f566\f566"; }
.fad.fa-face-mask::after, .fa-duotone.fa-face-mask::after {
  content: "\e37f\e37f"; }
.fad.fa-pickleball::after, .fa-duotone.fa-pickleball::after {
  content: "\e435\e435"; }
.fad.fa-star-sharp-half::after, .fa-duotone.fa-star-sharp-half::after {
  content: "\e28c\e28c"; }
.fad.fa-users-slash::after, .fa-duotone.fa-users-slash::after {
  content: "\e073\e073"; }
.fad.fa-clover::after, .fa-duotone.fa-clover::after {
  content: "\e139\e139"; }
.fad.fa-meat::after, .fa-duotone.fa-meat::after {
  content: "\f814\f814"; }
.fad.fa-reply::after, .fa-duotone.fa-reply::after {
  content: "\f3e5\f3e5"; }
.fad.fa-mail-reply::after, .fa-duotone.fa-mail-reply::after {
  content: "\f3e5\f3e5"; }
.fad.fa-star-and-crescent::after, .fa-duotone.fa-star-and-crescent::after {
  content: "\f699\f699"; }
.fad.fa-empty-set::after, .fa-duotone.fa-empty-set::after {
  content: "\f656\f656"; }
.fad.fa-house-fire::after, .fa-duotone.fa-house-fire::after {
  content: "\e50c\e50c"; }
.fad.fa-square-minus::after, .fa-duotone.fa-square-minus::after {
  content: "\f146\f146"; }
.fad.fa-minus-square::after, .fa-duotone.fa-minus-square::after {
  content: "\f146\f146"; }
.fad.fa-helicopter::after, .fa-duotone.fa-helicopter::after {
  content: "\f533\f533"; }
.fad.fa-bird::after, .fa-duotone.fa-bird::after {
  content: "\e469\e469"; }
.fad.fa-compass::after, .fa-duotone.fa-compass::after {
  content: "\f14e\f14e"; }
.fad.fa-square-caret-down::after, .fa-duotone.fa-square-caret-down::after {
  content: "\f150\f150"; }
.fad.fa-caret-square-down::after, .fa-duotone.fa-caret-square-down::after {
  content: "\f150\f150"; }
.fad.fa-heart-half-stroke::after, .fa-duotone.fa-heart-half-stroke::after {
  content: "\e1ac\e1ac"; }
.fad.fa-heart-half-alt::after, .fa-duotone.fa-heart-half-alt::after {
  content: "\e1ac\e1ac"; }
.fad.fa-file-circle-question::after, .fa-duotone.fa-file-circle-question::after {
  content: "\e4ef\e4ef"; }
.fad.fa-laptop-code::after, .fa-duotone.fa-laptop-code::after {
  content: "\f5fc\f5fc"; }
.fad.fa-joystick::after, .fa-duotone.fa-joystick::after {
  content: "\f8c5\f8c5"; }
.fad.fa-grill-fire::after, .fa-duotone.fa-grill-fire::after {
  content: "\e5a4\e5a4"; }
.fad.fa-rectangle-vertical-history::after, .fa-duotone.fa-rectangle-vertical-history::after {
  content: "\e237\e237"; }
.fad.fa-swatchbook::after, .fa-duotone.fa-swatchbook::after {
  content: "\f5c3\f5c3"; }
.fad.fa-prescription-bottle::after, .fa-duotone.fa-prescription-bottle::after {
  content: "\f485\f485"; }
.fad.fa-bars::after, .fa-duotone.fa-bars::after {
  content: "\f0c9\f0c9"; }
.fad.fa-navicon::after, .fa-duotone.fa-navicon::after {
  content: "\f0c9\f0c9"; }
.fad.fa-keyboard-left::after, .fa-duotone.fa-keyboard-left::after {
  content: "\e1c3\e1c3"; }
.fad.fa-people-group::after, .fa-duotone.fa-people-group::after {
  content: "\e533\e533"; }
.fad.fa-hourglass-end::after, .fa-duotone.fa-hourglass-end::after {
  content: "\f253\f253"; }
.fad.fa-hourglass-3::after, .fa-duotone.fa-hourglass-3::after {
  content: "\f253\f253"; }
.fad.fa-heart-crack::after, .fa-duotone.fa-heart-crack::after {
  content: "\f7a9\f7a9"; }
.fad.fa-heart-broken::after, .fa-duotone.fa-heart-broken::after {
  content: "\f7a9\f7a9"; }
.fad.fa-face-beam-hand-over-mouth::after, .fa-duotone.fa-face-beam-hand-over-mouth::after {
  content: "\e47c\e47c"; }
.fad.fa-droplet-percent::after, .fa-duotone.fa-droplet-percent::after {
  content: "\f750\f750"; }
.fad.fa-humidity::after, .fa-duotone.fa-humidity::after {
  content: "\f750\f750"; }
.fad.fa-square-up-right::after, .fa-duotone.fa-square-up-right::after {
  content: "\f360\f360"; }
.fad.fa-external-link-square-alt::after, .fa-duotone.fa-external-link-square-alt::after {
  content: "\f360\f360"; }
.fad.fa-face-kiss-beam::after, .fa-duotone.fa-face-kiss-beam::after {
  content: "\f597\f597"; }
.fad.fa-kiss-beam::after, .fa-duotone.fa-kiss-beam::after {
  content: "\f597\f597"; }
.fad.fa-corn::after, .fa-duotone.fa-corn::after {
  content: "\f6c7\f6c7"; }
.fad.fa-roller-coaster::after, .fa-duotone.fa-roller-coaster::after {
  content: "\e324\e324"; }
.fad.fa-photo-film-music::after, .fa-duotone.fa-photo-film-music::after {
  content: "\e228\e228"; }
.fad.fa-radar::after, .fa-duotone.fa-radar::after {
  content: "\e024\e024"; }
.fad.fa-sickle::after, .fa-duotone.fa-sickle::after {
  content: "\f822\f822"; }
.fad.fa-film::after, .fa-duotone.fa-film::after {
  content: "\f008\f008"; }
.fad.fa-coconut::after, .fa-duotone.fa-coconut::after {
  content: "\e2f6\e2f6"; }
.fad.fa-ruler-horizontal::after, .fa-duotone.fa-ruler-horizontal::after {
  content: "\f547\f547"; }
.fad.fa-shield-cross::after, .fa-duotone.fa-shield-cross::after {
  content: "\f712\f712"; }
.fad.fa-cassette-tape::after, .fa-duotone.fa-cassette-tape::after {
  content: "\f8ab\f8ab"; }
.fad.fa-square-terminal::after, .fa-duotone.fa-square-terminal::after {
  content: "\e32a\e32a"; }
.fad.fa-people-robbery::after, .fa-duotone.fa-people-robbery::after {
  content: "\e536\e536"; }
.fad.fa-lightbulb::after, .fa-duotone.fa-lightbulb::after {
  content: "\f0eb\f0eb"; }
.fad.fa-caret-left::after, .fa-duotone.fa-caret-left::after {
  content: "\f0d9\f0d9"; }
.fad.fa-comment-middle::after, .fa-duotone.fa-comment-middle::after {
  content: "\e149\e149"; }
.fad.fa-trash-can-list::after, .fa-duotone.fa-trash-can-list::after {
  content: "\e2ab\e2ab"; }
.fad.fa-block::after, .fa-duotone.fa-block::after {
  content: "\e46a\e46a"; }
.fad.fa-circle-exclamation::after, .fa-duotone.fa-circle-exclamation::after {
  content: "\f06a\f06a"; }
.fad.fa-exclamation-circle::after, .fa-duotone.fa-exclamation-circle::after {
  content: "\f06a\f06a"; }
.fad.fa-school-circle-xmark::after, .fa-duotone.fa-school-circle-xmark::after {
  content: "\e56d\e56d"; }
.fad.fa-arrow-right-from-bracket::after, .fa-duotone.fa-arrow-right-from-bracket::after {
  content: "\f08b\f08b"; }
.fad.fa-sign-out::after, .fa-duotone.fa-sign-out::after {
  content: "\f08b\f08b"; }
.fad.fa-face-frown-slight::after, .fa-duotone.fa-face-frown-slight::after {
  content: "\e376\e376"; }
.fad.fa-circle-chevron-down::after, .fa-duotone.fa-circle-chevron-down::after {
  content: "\f13a\f13a"; }
.fad.fa-chevron-circle-down::after, .fa-duotone.fa-chevron-circle-down::after {
  content: "\f13a\f13a"; }
.fad.fa-sidebar-flip::after, .fa-duotone.fa-sidebar-flip::after {
  content: "\e24f\e24f"; }
.fad.fa-unlock-keyhole::after, .fa-duotone.fa-unlock-keyhole::after {
  content: "\f13e\f13e"; }
.fad.fa-unlock-alt::after, .fa-duotone.fa-unlock-alt::after {
  content: "\f13e\f13e"; }
.fad.fa-temperature-list::after, .fa-duotone.fa-temperature-list::after {
  content: "\e299\e299"; }
.fad.fa-cloud-showers-heavy::after, .fa-duotone.fa-cloud-showers-heavy::after {
  content: "\f740\f740"; }
.fad.fa-headphones-simple::after, .fa-duotone.fa-headphones-simple::after {
  content: "\f58f\f58f"; }
.fad.fa-headphones-alt::after, .fa-duotone.fa-headphones-alt::after {
  content: "\f58f\f58f"; }
.fad.fa-sitemap::after, .fa-duotone.fa-sitemap::after {
  content: "\f0e8\f0e8"; }
.fad.fa-pipe-section::after, .fa-duotone.fa-pipe-section::after {
  content: "\e438\e438"; }
.fad.fa-space-station-moon-construction::after, .fa-duotone.fa-space-station-moon-construction::after {
  content: "\e034\e034"; }
.fad.fa-space-station-moon-alt::after, .fa-duotone.fa-space-station-moon-alt::after {
  content: "\e034\e034"; }
.fad.fa-circle-dollar-to-slot::after, .fa-duotone.fa-circle-dollar-to-slot::after {
  content: "\f4b9\f4b9"; }
.fad.fa-donate::after, .fa-duotone.fa-donate::after {
  content: "\f4b9\f4b9"; }
.fad.fa-memory::after, .fa-duotone.fa-memory::after {
  content: "\f538\f538"; }
.fad.fa-face-sleeping::after, .fa-duotone.fa-face-sleeping::after {
  content: "\e38d\e38d"; }
.fad.fa-road-spikes::after, .fa-duotone.fa-road-spikes::after {
  content: "\e568\e568"; }
.fad.fa-fire-burner::after, .fa-duotone.fa-fire-burner::after {
  content: "\e4f1\e4f1"; }
.fad.fa-squirrel::after, .fa-duotone.fa-squirrel::after {
  content: "\f71a\f71a"; }
.fad.fa-arrow-up-to-line::after, .fa-duotone.fa-arrow-up-to-line::after {
  content: "\f341\f341"; }
.fad.fa-arrow-to-top::after, .fa-duotone.fa-arrow-to-top::after {
  content: "\f341\f341"; }
.fad.fa-flag::after, .fa-duotone.fa-flag::after {
  content: "\f024\f024"; }
.fad.fa-face-cowboy-hat::after, .fa-duotone.fa-face-cowboy-hat::after {
  content: "\e36e\e36e"; }
.fad.fa-hanukiah::after, .fa-duotone.fa-hanukiah::after {
  content: "\f6e6\f6e6"; }
.fad.fa-chart-scatter-3d::after, .fa-duotone.fa-chart-scatter-3d::after {
  content: "\e0e8\e0e8"; }
.fad.fa-square-code::after, .fa-duotone.fa-square-code::after {
  content: "\e267\e267"; }
.fad.fa-feather::after, .fa-duotone.fa-feather::after {
  content: "\f52d\f52d"; }
.fad.fa-volume-low::after, .fa-duotone.fa-volume-low::after {
  content: "\f027\f027"; }
.fad.fa-volume-down::after, .fa-duotone.fa-volume-down::after {
  content: "\f027\f027"; }
.fad.fa-xmark-to-slot::after, .fa-duotone.fa-xmark-to-slot::after {
  content: "\f771\f771"; }
.fad.fa-times-to-slot::after, .fa-duotone.fa-times-to-slot::after {
  content: "\f771\f771"; }
.fad.fa-vote-nay::after, .fa-duotone.fa-vote-nay::after {
  content: "\f771\f771"; }
.fad.fa-box-taped::after, .fa-duotone.fa-box-taped::after {
  content: "\f49a\f49a"; }
.fad.fa-box-alt::after, .fa-duotone.fa-box-alt::after {
  content: "\f49a\f49a"; }
.fad.fa-comment-slash::after, .fa-duotone.fa-comment-slash::after {
  content: "\f4b3\f4b3"; }
.fad.fa-swords::after, .fa-duotone.fa-swords::after {
  content: "\f71d\f71d"; }
.fad.fa-cloud-sun-rain::after, .fa-duotone.fa-cloud-sun-rain::after {
  content: "\f743\f743"; }
.fad.fa-album::after, .fa-duotone.fa-album::after {
  content: "\f89f\f89f"; }
.fad.fa-circle-n::after, .fa-duotone.fa-circle-n::after {
  content: "\e118\e118"; }
.fad.fa-compress::after, .fa-duotone.fa-compress::after {
  content: "\f066\f066"; }
.fad.fa-wheat-awn::after, .fa-duotone.fa-wheat-awn::after {
  content: "\e2cd\e2cd"; }
.fad.fa-wheat-alt::after, .fa-duotone.fa-wheat-alt::after {
  content: "\e2cd\e2cd"; }
.fad.fa-ankh::after, .fa-duotone.fa-ankh::after {
  content: "\f644\f644"; }
.fad.fa-hands-holding-child::after, .fa-duotone.fa-hands-holding-child::after {
  content: "\e4fa\e4fa"; }
.fad.fa-asterisk::after, .fa-duotone.fa-asterisk::after {
  content: "\2a\2a"; }
.fad.fa-key-skeleton-left-right::after, .fa-duotone.fa-key-skeleton-left-right::after {
  content: "\e3b4\e3b4"; }
.fad.fa-comment-lines::after, .fa-duotone.fa-comment-lines::after {
  content: "\f4b0\f4b0"; }
.fad.fa-luchador-mask::after, .fa-duotone.fa-luchador-mask::after {
  content: "\f455\f455"; }
.fad.fa-luchador::after, .fa-duotone.fa-luchador::after {
  content: "\f455\f455"; }
.fad.fa-mask-luchador::after, .fa-duotone.fa-mask-luchador::after {
  content: "\f455\f455"; }
.fad.fa-square-check::after, .fa-duotone.fa-square-check::after {
  content: "\f14a\f14a"; }
.fad.fa-check-square::after, .fa-duotone.fa-check-square::after {
  content: "\f14a\f14a"; }
.fad.fa-shredder::after, .fa-duotone.fa-shredder::after {
  content: "\f68a\f68a"; }
.fad.fa-book-open-cover::after, .fa-duotone.fa-book-open-cover::after {
  content: "\e0c0\e0c0"; }
.fad.fa-book-open-alt::after, .fa-duotone.fa-book-open-alt::after {
  content: "\e0c0\e0c0"; }
.fad.fa-sandwich::after, .fa-duotone.fa-sandwich::after {
  content: "\f81f\f81f"; }
.fad.fa-peseta-sign::after, .fa-duotone.fa-peseta-sign::after {
  content: "\e221\e221"; }
.fad.fa-square-parking-slash::after, .fa-duotone.fa-square-parking-slash::after {
  content: "\f617\f617"; }
.fad.fa-parking-slash::after, .fa-duotone.fa-parking-slash::after {
  content: "\f617\f617"; }
.fad.fa-train-tunnel::after, .fa-duotone.fa-train-tunnel::after {
  content: "\e454\e454"; }
.fad.fa-heading::after, .fa-duotone.fa-heading::after {
  content: "\f1dc\f1dc"; }
.fad.fa-header::after, .fa-duotone.fa-header::after {
  content: "\f1dc\f1dc"; }
.fad.fa-ghost::after, .fa-duotone.fa-ghost::after {
  content: "\f6e2\f6e2"; }
.fad.fa-face-anguished::after, .fa-duotone.fa-face-anguished::after {
  content: "\e369\e369"; }
.fad.fa-hockey-sticks::after, .fa-duotone.fa-hockey-sticks::after {
  content: "\f454\f454"; }
.fad.fa-abacus::after, .fa-duotone.fa-abacus::after {
  content: "\f640\f640"; }
.fad.fa-film-simple::after, .fa-duotone.fa-film-simple::after {
  content: "\f3a0\f3a0"; }
.fad.fa-film-alt::after, .fa-duotone.fa-film-alt::after {
  content: "\f3a0\f3a0"; }
.fad.fa-list::after, .fa-duotone.fa-list::after {
  content: "\f03a\f03a"; }
.fad.fa-list-squares::after, .fa-duotone.fa-list-squares::after {
  content: "\f03a\f03a"; }
.fad.fa-tree-palm::after, .fa-duotone.fa-tree-palm::after {
  content: "\f82b\f82b"; }
.fad.fa-square-phone-flip::after, .fa-duotone.fa-square-phone-flip::after {
  content: "\f87b\f87b"; }
.fad.fa-phone-square-alt::after, .fa-duotone.fa-phone-square-alt::after {
  content: "\f87b\f87b"; }
.fad.fa-cart-plus::after, .fa-duotone.fa-cart-plus::after {
  content: "\f217\f217"; }
.fad.fa-gamepad::after, .fa-duotone.fa-gamepad::after {
  content: "\f11b\f11b"; }
.fad.fa-border-center-v::after, .fa-duotone.fa-border-center-v::after {
  content: "\f89d\f89d"; }
.fad.fa-circle-dot::after, .fa-duotone.fa-circle-dot::after {
  content: "\f192\f192"; }
.fad.fa-dot-circle::after, .fa-duotone.fa-dot-circle::after {
  content: "\f192\f192"; }
.fad.fa-clipboard-medical::after, .fa-duotone.fa-clipboard-medical::after {
  content: "\e133\e133"; }
.fad.fa-face-dizzy::after, .fa-duotone.fa-face-dizzy::after {
  content: "\f567\f567"; }
.fad.fa-dizzy::after, .fa-duotone.fa-dizzy::after {
  content: "\f567\f567"; }
.fad.fa-egg::after, .fa-duotone.fa-egg::after {
  content: "\f7fb\f7fb"; }
.fad.fa-up-to-line::after, .fa-duotone.fa-up-to-line::after {
  content: "\f34d\f34d"; }
.fad.fa-arrow-alt-to-top::after, .fa-duotone.fa-arrow-alt-to-top::after {
  content: "\f34d\f34d"; }
.fad.fa-house-medical-circle-xmark::after, .fa-duotone.fa-house-medical-circle-xmark::after {
  content: "\e513\e513"; }
.fad.fa-watch-fitness::after, .fa-duotone.fa-watch-fitness::after {
  content: "\f63e\f63e"; }
.fad.fa-clock-nine-thirty::after, .fa-duotone.fa-clock-nine-thirty::after {
  content: "\e34d\e34d"; }
.fad.fa-campground::after, .fa-duotone.fa-campground::after {
  content: "\f6bb\f6bb"; }
.fad.fa-folder-plus::after, .fa-duotone.fa-folder-plus::after {
  content: "\f65e\f65e"; }
.fad.fa-jug::after, .fa-duotone.fa-jug::after {
  content: "\f8c6\f8c6"; }
.fad.fa-futbol::after, .fa-duotone.fa-futbol::after {
  content: "\f1e3\f1e3"; }
.fad.fa-futbol-ball::after, .fa-duotone.fa-futbol-ball::after {
  content: "\f1e3\f1e3"; }
.fad.fa-soccer-ball::after, .fa-duotone.fa-soccer-ball::after {
  content: "\f1e3\f1e3"; }
.fad.fa-snow-blowing::after, .fa-duotone.fa-snow-blowing::after {
  content: "\f761\f761"; }
.fad.fa-paintbrush::after, .fa-duotone.fa-paintbrush::after {
  content: "\f1fc\f1fc"; }
.fad.fa-paint-brush::after, .fa-duotone.fa-paint-brush::after {
  content: "\f1fc\f1fc"; }
.fad.fa-lock::after, .fa-duotone.fa-lock::after {
  content: "\f023\f023"; }
.fad.fa-arrow-down-from-line::after, .fa-duotone.fa-arrow-down-from-line::after {
  content: "\f345\f345"; }
.fad.fa-arrow-from-top::after, .fa-duotone.fa-arrow-from-top::after {
  content: "\f345\f345"; }
.fad.fa-gas-pump::after, .fa-duotone.fa-gas-pump::after {
  content: "\f52f\f52f"; }
.fad.fa-signal-bars-slash::after, .fa-duotone.fa-signal-bars-slash::after {
  content: "\f694\f694"; }
.fad.fa-signal-alt-slash::after, .fa-duotone.fa-signal-alt-slash::after {
  content: "\f694\f694"; }
.fad.fa-monkey::after, .fa-duotone.fa-monkey::after {
  content: "\f6fb\f6fb"; }
.fad.fa-rectangle-pro::after, .fa-duotone.fa-rectangle-pro::after {
  content: "\e235\e235"; }
.fad.fa-pro::after, .fa-duotone.fa-pro::after {
  content: "\e235\e235"; }
.fad.fa-house-night::after, .fa-duotone.fa-house-night::after {
  content: "\e010\e010"; }
.fad.fa-hot-tub-person::after, .fa-duotone.fa-hot-tub-person::after {
  content: "\f593\f593"; }
.fad.fa-hot-tub::after, .fa-duotone.fa-hot-tub::after {
  content: "\f593\f593"; }
.fad.fa-blanket::after, .fa-duotone.fa-blanket::after {
  content: "\f498\f498"; }
.fad.fa-map-location::after, .fa-duotone.fa-map-location::after {
  content: "\f59f\f59f"; }
.fad.fa-map-marked::after, .fa-duotone.fa-map-marked::after {
  content: "\f59f\f59f"; }
.fad.fa-house-flood-water::after, .fa-duotone.fa-house-flood-water::after {
  content: "\e50e\e50e"; }
.fad.fa-comments-question-check::after, .fa-duotone.fa-comments-question-check::after {
  content: "\e14f\e14f"; }
.fad.fa-tree::after, .fa-duotone.fa-tree::after {
  content: "\f1bb\f1bb"; }
.fad.fa-arrows-cross::after, .fa-duotone.fa-arrows-cross::after {
  content: "\e0a2\e0a2"; }
.fad.fa-backpack::after, .fa-duotone.fa-backpack::after {
  content: "\f5d4\f5d4"; }
.fad.fa-square-small::after, .fa-duotone.fa-square-small::after {
  content: "\e27e\e27e"; }
.fad.fa-folder-arrow-up::after, .fa-duotone.fa-folder-arrow-up::after {
  content: "\e054\e054"; }
.fad.fa-folder-upload::after, .fa-duotone.fa-folder-upload::after {
  content: "\e054\e054"; }
.fad.fa-bridge-lock::after, .fa-duotone.fa-bridge-lock::after {
  content: "\e4cc\e4cc"; }
.fad.fa-crosshairs-simple::after, .fa-duotone.fa-crosshairs-simple::after {
  content: "\e59f\e59f"; }
.fad.fa-sack-dollar::after, .fa-duotone.fa-sack-dollar::after {
  content: "\f81d\f81d"; }
.fad.fa-pen-to-square::after, .fa-duotone.fa-pen-to-square::after {
  content: "\f044\f044"; }
.fad.fa-edit::after, .fa-duotone.fa-edit::after {
  content: "\f044\f044"; }
.fad.fa-square-sliders::after, .fa-duotone.fa-square-sliders::after {
  content: "\f3f0\f3f0"; }
.fad.fa-sliders-h-square::after, .fa-duotone.fa-sliders-h-square::after {
  content: "\f3f0\f3f0"; }
.fad.fa-car-side::after, .fa-duotone.fa-car-side::after {
  content: "\f5e4\f5e4"; }
.fad.fa-message-middle-top::after, .fa-duotone.fa-message-middle-top::after {
  content: "\e1e2\e1e2"; }
.fad.fa-comment-middle-top-alt::after, .fa-duotone.fa-comment-middle-top-alt::after {
  content: "\e1e2\e1e2"; }
.fad.fa-lightbulb-on::after, .fa-duotone.fa-lightbulb-on::after {
  content: "\f672\f672"; }
.fad.fa-knife::after, .fa-duotone.fa-knife::after {
  content: "\f2e4\f2e4"; }
.fad.fa-utensil-knife::after, .fa-duotone.fa-utensil-knife::after {
  content: "\f2e4\f2e4"; }
.fad.fa-share-nodes::after, .fa-duotone.fa-share-nodes::after {
  content: "\f1e0\f1e0"; }
.fad.fa-share-alt::after, .fa-duotone.fa-share-alt::after {
  content: "\f1e0\f1e0"; }
.fad.fa-wave-sine::after, .fa-duotone.fa-wave-sine::after {
  content: "\f899\f899"; }
.fad.fa-heart-circle-minus::after, .fa-duotone.fa-heart-circle-minus::after {
  content: "\e4ff\e4ff"; }
.fad.fa-circle-w::after, .fa-duotone.fa-circle-w::after {
  content: "\e12c\e12c"; }
.fad.fa-circle-calendar::after, .fa-duotone.fa-circle-calendar::after {
  content: "\e102\e102"; }
.fad.fa-calendar-circle::after, .fa-duotone.fa-calendar-circle::after {
  content: "\e102\e102"; }
.fad.fa-hourglass-half::after, .fa-duotone.fa-hourglass-half::after {
  content: "\f252\f252"; }
.fad.fa-hourglass-2::after, .fa-duotone.fa-hourglass-2::after {
  content: "\f252\f252"; }
.fad.fa-microscope::after, .fa-duotone.fa-microscope::after {
  content: "\f610\f610"; }
.fad.fa-sunset::after, .fa-duotone.fa-sunset::after {
  content: "\f767\f767"; }
.fad.fa-sink::after, .fa-duotone.fa-sink::after {
  content: "\e06d\e06d"; }
.fad.fa-calendar-exclamation::after, .fa-duotone.fa-calendar-exclamation::after {
  content: "\f334\f334"; }
.fad.fa-truck-container-empty::after, .fa-duotone.fa-truck-container-empty::after {
  content: "\e2b5\e2b5"; }
.fad.fa-hand-heart::after, .fa-duotone.fa-hand-heart::after {
  content: "\f4bc\f4bc"; }
.fad.fa-bag-shopping::after, .fa-duotone.fa-bag-shopping::after {
  content: "\f290\f290"; }
.fad.fa-shopping-bag::after, .fa-duotone.fa-shopping-bag::after {
  content: "\f290\f290"; }
.fad.fa-arrow-down-z-a::after, .fa-duotone.fa-arrow-down-z-a::after {
  content: "\f881\f881"; }
.fad.fa-sort-alpha-desc::after, .fa-duotone.fa-sort-alpha-desc::after {
  content: "\f881\f881"; }
.fad.fa-sort-alpha-down-alt::after, .fa-duotone.fa-sort-alpha-down-alt::after {
  content: "\f881\f881"; }
.fad.fa-mitten::after, .fa-duotone.fa-mitten::after {
  content: "\f7b5\f7b5"; }
.fad.fa-reply-clock::after, .fa-duotone.fa-reply-clock::after {
  content: "\e239\e239"; }
.fad.fa-reply-time::after, .fa-duotone.fa-reply-time::after {
  content: "\e239\e239"; }
.fad.fa-person-rays::after, .fa-duotone.fa-person-rays::after {
  content: "\e54d\e54d"; }
.fad.fa-right::after, .fa-duotone.fa-right::after {
  content: "\f356\f356"; }
.fad.fa-arrow-alt-right::after, .fa-duotone.fa-arrow-alt-right::after {
  content: "\f356\f356"; }
.fad.fa-circle-f::after, .fa-duotone.fa-circle-f::after {
  content: "\e10e\e10e"; }
.fad.fa-users::after, .fa-duotone.fa-users::after {
  content: "\f0c0\f0c0"; }
.fad.fa-face-pleading::after, .fa-duotone.fa-face-pleading::after {
  content: "\e386\e386"; }
.fad.fa-eye-slash::after, .fa-duotone.fa-eye-slash::after {
  content: "\f070\f070"; }
.fad.fa-flask-vial::after, .fa-duotone.fa-flask-vial::after {
  content: "\e4f3\e4f3"; }
.fad.fa-police-box::after, .fa-duotone.fa-police-box::after {
  content: "\e021\e021"; }
.fad.fa-cucumber::after, .fa-duotone.fa-cucumber::after {
  content: "\e401\e401"; }
.fad.fa-head-side-brain::after, .fa-duotone.fa-head-side-brain::after {
  content: "\f808\f808"; }
.fad.fa-hand::after, .fa-duotone.fa-hand::after {
  content: "\f256\f256"; }
.fad.fa-hand-paper::after, .fa-duotone.fa-hand-paper::after {
  content: "\f256\f256"; }
.fad.fa-person-biking-mountain::after, .fa-duotone.fa-person-biking-mountain::after {
  content: "\f84b\f84b"; }
.fad.fa-biking-mountain::after, .fa-duotone.fa-biking-mountain::after {
  content: "\f84b\f84b"; }
.fad.fa-utensils-slash::after, .fa-duotone.fa-utensils-slash::after {
  content: "\e464\e464"; }
.fad.fa-print-magnifying-glass::after, .fa-duotone.fa-print-magnifying-glass::after {
  content: "\f81a\f81a"; }
.fad.fa-print-search::after, .fa-duotone.fa-print-search::after {
  content: "\f81a\f81a"; }
.fad.fa-folder-bookmark::after, .fa-duotone.fa-folder-bookmark::after {
  content: "\e186\e186"; }
.fad.fa-om::after, .fa-duotone.fa-om::after {
  content: "\f679\f679"; }
.fad.fa-pi::after, .fa-duotone.fa-pi::after {
  content: "\f67e\f67e"; }
.fad.fa-flask-round-potion::after, .fa-duotone.fa-flask-round-potion::after {
  content: "\f6e1\f6e1"; }
.fad.fa-flask-potion::after, .fa-duotone.fa-flask-potion::after {
  content: "\f6e1\f6e1"; }
.fad.fa-face-shush::after, .fa-duotone.fa-face-shush::after {
  content: "\e38c\e38c"; }
.fad.fa-worm::after, .fa-duotone.fa-worm::after {
  content: "\e599\e599"; }
.fad.fa-house-circle-xmark::after, .fa-duotone.fa-house-circle-xmark::after {
  content: "\e50b\e50b"; }
.fad.fa-plug::after, .fa-duotone.fa-plug::after {
  content: "\f1e6\f1e6"; }
.fad.fa-calendar-circle-exclamation::after, .fa-duotone.fa-calendar-circle-exclamation::after {
  content: "\e46e\e46e"; }
.fad.fa-square-i::after, .fa-duotone.fa-square-i::after {
  content: "\e272\e272"; }
.fad.fa-chevron-up::after, .fa-duotone.fa-chevron-up::after {
  content: "\f077\f077"; }
.fad.fa-face-saluting::after, .fa-duotone.fa-face-saluting::after {
  content: "\e484\e484"; }
.fad.fa-gauge-simple-low::after, .fa-duotone.fa-gauge-simple-low::after {
  content: "\f62c\f62c"; }
.fad.fa-tachometer-slow::after, .fa-duotone.fa-tachometer-slow::after {
  content: "\f62c\f62c"; }
.fad.fa-face-persevering::after, .fa-duotone.fa-face-persevering::after {
  content: "\e385\e385"; }
.fad.fa-circle-camera::after, .fa-duotone.fa-circle-camera::after {
  content: "\e103\e103"; }
.fad.fa-camera-circle::after, .fa-duotone.fa-camera-circle::after {
  content: "\e103\e103"; }
.fad.fa-hand-spock::after, .fa-duotone.fa-hand-spock::after {
  content: "\f259\f259"; }
.fad.fa-spider-web::after, .fa-duotone.fa-spider-web::after {
  content: "\f719\f719"; }
.fad.fa-circle-microphone::after, .fa-duotone.fa-circle-microphone::after {
  content: "\e116\e116"; }
.fad.fa-microphone-circle::after, .fa-duotone.fa-microphone-circle::after {
  content: "\e116\e116"; }
.fad.fa-book-arrow-up::after, .fa-duotone.fa-book-arrow-up::after {
  content: "\e0ba\e0ba"; }
.fad.fa-popsicle::after, .fa-duotone.fa-popsicle::after {
  content: "\e43e\e43e"; }
.fad.fa-command::after, .fa-duotone.fa-command::after {
  content: "\e142\e142"; }
.fad.fa-blinds::after, .fa-duotone.fa-blinds::after {
  content: "\f8fb\f8fb"; }
.fad.fa-stopwatch::after, .fa-duotone.fa-stopwatch::after {
  content: "\f2f2\f2f2"; }
.fad.fa-saxophone::after, .fa-duotone.fa-saxophone::after {
  content: "\f8dc\f8dc"; }
.fad.fa-square-2::after, .fa-duotone.fa-square-2::after {
  content: "\e257\e257"; }
.fad.fa-field-hockey-stick-ball::after, .fa-duotone.fa-field-hockey-stick-ball::after {
  content: "\f44c\f44c"; }
.fad.fa-field-hockey::after, .fa-duotone.fa-field-hockey::after {
  content: "\f44c\f44c"; }
.fad.fa-arrow-up-square-triangle::after, .fa-duotone.fa-arrow-up-square-triangle::after {
  content: "\f88b\f88b"; }
.fad.fa-sort-shapes-up-alt::after, .fa-duotone.fa-sort-shapes-up-alt::after {
  content: "\f88b\f88b"; }
.fad.fa-face-scream::after, .fa-duotone.fa-face-scream::after {
  content: "\e38b\e38b"; }
.fad.fa-square-m::after, .fa-duotone.fa-square-m::after {
  content: "\e276\e276"; }
.fad.fa-camera-web::after, .fa-duotone.fa-camera-web::after {
  content: "\f832\f832"; }
.fad.fa-webcam::after, .fa-duotone.fa-webcam::after {
  content: "\f832\f832"; }
.fad.fa-comment-arrow-down::after, .fa-duotone.fa-comment-arrow-down::after {
  content: "\e143\e143"; }
.fad.fa-lightbulb-cfl::after, .fa-duotone.fa-lightbulb-cfl::after {
  content: "\e5a6\e5a6"; }
.fad.fa-window-frame-open::after, .fa-duotone.fa-window-frame-open::after {
  content: "\e050\e050"; }
.fad.fa-face-kiss::after, .fa-duotone.fa-face-kiss::after {
  content: "\f596\f596"; }
.fad.fa-kiss::after, .fa-duotone.fa-kiss::after {
  content: "\f596\f596"; }
.fad.fa-bridge-circle-xmark::after, .fa-duotone.fa-bridge-circle-xmark::after {
  content: "\e4cb\e4cb"; }
.fad.fa-period::after, .fa-duotone.fa-period::after {
  content: "\2e\2e"; }
.fad.fa-face-grin-tongue::after, .fa-duotone.fa-face-grin-tongue::after {
  content: "\f589\f589"; }
.fad.fa-grin-tongue::after, .fa-duotone.fa-grin-tongue::after {
  content: "\f589\f589"; }
.fad.fa-up-to-dotted-line::after, .fa-duotone.fa-up-to-dotted-line::after {
  content: "\e457\e457"; }
.fad.fa-thought-bubble::after, .fa-duotone.fa-thought-bubble::after {
  content: "\e32e\e32e"; }
.fad.fa-raygun::after, .fa-duotone.fa-raygun::after {
  content: "\e025\e025"; }
.fad.fa-flute::after, .fa-duotone.fa-flute::after {
  content: "\f8b9\f8b9"; }
.fad.fa-acorn::after, .fa-duotone.fa-acorn::after {
  content: "\f6ae\f6ae"; }
.fad.fa-video-arrow-up-right::after, .fa-duotone.fa-video-arrow-up-right::after {
  content: "\e2c9\e2c9"; }
.fad.fa-grate-droplet::after, .fa-duotone.fa-grate-droplet::after {
  content: "\e194\e194"; }
.fad.fa-seal-exclamation::after, .fa-duotone.fa-seal-exclamation::after {
  content: "\e242\e242"; }
.fad.fa-chess-bishop::after, .fa-duotone.fa-chess-bishop::after {
  content: "\f43a\f43a"; }
.fad.fa-message-sms::after, .fa-duotone.fa-message-sms::after {
  content: "\e1e5\e1e5"; }
.fad.fa-coffee-beans::after, .fa-duotone.fa-coffee-beans::after {
  content: "\e13f\e13f"; }
.fad.fa-hat-witch::after, .fa-duotone.fa-hat-witch::after {
  content: "\f6e7\f6e7"; }
.fad.fa-face-grin-wink::after, .fa-duotone.fa-face-grin-wink::after {
  content: "\f58c\f58c"; }
.fad.fa-grin-wink::after, .fa-duotone.fa-grin-wink::after {
  content: "\f58c\f58c"; }
.fad.fa-clock-three-thirty::after, .fa-duotone.fa-clock-three-thirty::after {
  content: "\e357\e357"; }
.fad.fa-ear-deaf::after, .fa-duotone.fa-ear-deaf::after {
  content: "\f2a4\f2a4"; }
.fad.fa-deaf::after, .fa-duotone.fa-deaf::after {
  content: "\f2a4\f2a4"; }
.fad.fa-deafness::after, .fa-duotone.fa-deafness::after {
  content: "\f2a4\f2a4"; }
.fad.fa-hard-of-hearing::after, .fa-duotone.fa-hard-of-hearing::after {
  content: "\f2a4\f2a4"; }
.fad.fa-alarm-clock::after, .fa-duotone.fa-alarm-clock::after {
  content: "\f34e\f34e"; }
.fad.fa-eclipse::after, .fa-duotone.fa-eclipse::after {
  content: "\f749\f749"; }
.fad.fa-face-relieved::after, .fa-duotone.fa-face-relieved::after {
  content: "\e389\e389"; }
.fad.fa-road-circle-check::after, .fa-duotone.fa-road-circle-check::after {
  content: "\e564\e564"; }
.fad.fa-dice-five::after, .fa-duotone.fa-dice-five::after {
  content: "\f523\f523"; }
.fad.fa-octagon-minus::after, .fa-duotone.fa-octagon-minus::after {
  content: "\f308\f308"; }
.fad.fa-minus-octagon::after, .fa-duotone.fa-minus-octagon::after {
  content: "\f308\f308"; }
.fad.fa-square-rss::after, .fa-duotone.fa-square-rss::after {
  content: "\f143\f143"; }
.fad.fa-rss-square::after, .fa-duotone.fa-rss-square::after {
  content: "\f143\f143"; }
.fad.fa-face-zany::after, .fa-duotone.fa-face-zany::after {
  content: "\e3a4\e3a4"; }
.fad.fa-tricycle::after, .fa-duotone.fa-tricycle::after {
  content: "\e5c3\e5c3"; }
.fad.fa-land-mine-on::after, .fa-duotone.fa-land-mine-on::after {
  content: "\e51b\e51b"; }
.fad.fa-square-arrow-up-left::after, .fa-duotone.fa-square-arrow-up-left::after {
  content: "\e263\e263"; }
.fad.fa-i-cursor::after, .fa-duotone.fa-i-cursor::after {
  content: "\f246\f246"; }
.fad.fa-salt-shaker::after, .fa-duotone.fa-salt-shaker::after {
  content: "\e446\e446"; }
.fad.fa-stamp::after, .fa-duotone.fa-stamp::after {
  content: "\f5bf\f5bf"; }
.fad.fa-file-plus::after, .fa-duotone.fa-file-plus::after {
  content: "\f319\f319"; }
.fad.fa-draw-square::after, .fa-duotone.fa-draw-square::after {
  content: "\f5ef\f5ef"; }
.fad.fa-toilet-paper-under-slash::after, .fa-duotone.fa-toilet-paper-under-slash::after {
  content: "\e2a1\e2a1"; }
.fad.fa-toilet-paper-reverse-slash::after, .fa-duotone.fa-toilet-paper-reverse-slash::after {
  content: "\e2a1\e2a1"; }
.fad.fa-stairs::after, .fa-duotone.fa-stairs::after {
  content: "\e289\e289"; }
.fad.fa-drone-front::after, .fa-duotone.fa-drone-front::after {
  content: "\f860\f860"; }
.fad.fa-drone-alt::after, .fa-duotone.fa-drone-alt::after {
  content: "\f860\f860"; }
.fad.fa-glass-empty::after, .fa-duotone.fa-glass-empty::after {
  content: "\e191\e191"; }
.fad.fa-dial-high::after, .fa-duotone.fa-dial-high::after {
  content: "\e15c\e15c"; }
.fad.fa-user-helmet-safety::after, .fa-duotone.fa-user-helmet-safety::after {
  content: "\f82c\f82c"; }
.fad.fa-user-construction::after, .fa-duotone.fa-user-construction::after {
  content: "\f82c\f82c"; }
.fad.fa-user-hard-hat::after, .fa-duotone.fa-user-hard-hat::after {
  content: "\f82c\f82c"; }
.fad.fa-i::after, .fa-duotone.fa-i::after {
  content: "\49\49"; }
.fad.fa-hryvnia-sign::after, .fa-duotone.fa-hryvnia-sign::after {
  content: "\f6f2\f6f2"; }
.fad.fa-hryvnia::after, .fa-duotone.fa-hryvnia::after {
  content: "\f6f2\f6f2"; }
.fad.fa-arrow-down-left-and-arrow-up-right-to-center::after, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center::after {
  content: "\e092\e092"; }
.fad.fa-pills::after, .fa-duotone.fa-pills::after {
  content: "\f484\f484"; }
.fad.fa-face-grin-wide::after, .fa-duotone.fa-face-grin-wide::after {
  content: "\f581\f581"; }
.fad.fa-grin-alt::after, .fa-duotone.fa-grin-alt::after {
  content: "\f581\f581"; }
.fad.fa-tooth::after, .fa-duotone.fa-tooth::after {
  content: "\f5c9\f5c9"; }
.fad.fa-basketball-hoop::after, .fa-duotone.fa-basketball-hoop::after {
  content: "\f435\f435"; }
.fad.fa-objects-align-bottom::after, .fa-duotone.fa-objects-align-bottom::after {
  content: "\e3bb\e3bb"; }
.fad.fa-v::after, .fa-duotone.fa-v::after {
  content: "\56\56"; }
.fad.fa-sparkles::after, .fa-duotone.fa-sparkles::after {
  content: "\f890\f890"; }
.fad.fa-squid::after, .fa-duotone.fa-squid::after {
  content: "\e450\e450"; }
.fad.fa-leafy-green::after, .fa-duotone.fa-leafy-green::after {
  content: "\e41d\e41d"; }
.fad.fa-circle-arrow-up-right::after, .fa-duotone.fa-circle-arrow-up-right::after {
  content: "\e0fc\e0fc"; }
.fad.fa-calendars::after, .fa-duotone.fa-calendars::after {
  content: "\e0d7\e0d7"; }
.fad.fa-bangladeshi-taka-sign::after, .fa-duotone.fa-bangladeshi-taka-sign::after {
  content: "\e2e6\e2e6"; }
.fad.fa-bicycle::after, .fa-duotone.fa-bicycle::after {
  content: "\f206\f206"; }
.fad.fa-hammer-war::after, .fa-duotone.fa-hammer-war::after {
  content: "\f6e4\f6e4"; }
.fad.fa-circle-d::after, .fa-duotone.fa-circle-d::after {
  content: "\e104\e104"; }
.fad.fa-spider-black-widow::after, .fa-duotone.fa-spider-black-widow::after {
  content: "\f718\f718"; }
.fad.fa-staff-snake::after, .fa-duotone.fa-staff-snake::after {
  content: "\e579\e579"; }
.fad.fa-rod-asclepius::after, .fa-duotone.fa-rod-asclepius::after {
  content: "\e579\e579"; }
.fad.fa-rod-snake::after, .fa-duotone.fa-rod-snake::after {
  content: "\e579\e579"; }
.fad.fa-staff-aesculapius::after, .fa-duotone.fa-staff-aesculapius::after {
  content: "\e579\e579"; }
.fad.fa-pear::after, .fa-duotone.fa-pear::after {
  content: "\e20c\e20c"; }
.fad.fa-head-side-cough-slash::after, .fa-duotone.fa-head-side-cough-slash::after {
  content: "\e062\e062"; }
.fad.fa-triangle::after, .fa-duotone.fa-triangle::after {
  content: "\f2ec\f2ec"; }
.fad.fa-apartment::after, .fa-duotone.fa-apartment::after {
  content: "\e468\e468"; }
.fad.fa-truck-medical::after, .fa-duotone.fa-truck-medical::after {
  content: "\f0f9\f0f9"; }
.fad.fa-ambulance::after, .fa-duotone.fa-ambulance::after {
  content: "\f0f9\f0f9"; }
.fad.fa-pepper::after, .fa-duotone.fa-pepper::after {
  content: "\e432\e432"; }
.fad.fa-piano::after, .fa-duotone.fa-piano::after {
  content: "\f8d4\f8d4"; }
.fad.fa-gun-squirt::after, .fa-duotone.fa-gun-squirt::after {
  content: "\e19d\e19d"; }
.fad.fa-wheat-awn-circle-exclamation::after, .fa-duotone.fa-wheat-awn-circle-exclamation::after {
  content: "\e598\e598"; }
.fad.fa-snowman::after, .fa-duotone.fa-snowman::after {
  content: "\f7d0\f7d0"; }
.fad.fa-user-alien::after, .fa-duotone.fa-user-alien::after {
  content: "\e04a\e04a"; }
.fad.fa-shield-check::after, .fa-duotone.fa-shield-check::after {
  content: "\f2f7\f2f7"; }
.fad.fa-mortar-pestle::after, .fa-duotone.fa-mortar-pestle::after {
  content: "\f5a7\f5a7"; }
.fad.fa-road-barrier::after, .fa-duotone.fa-road-barrier::after {
  content: "\e562\e562"; }
.fad.fa-chart-candlestick::after, .fa-duotone.fa-chart-candlestick::after {
  content: "\e0e2\e0e2"; }
.fad.fa-briefcase-blank::after, .fa-duotone.fa-briefcase-blank::after {
  content: "\e0c8\e0c8"; }
.fad.fa-school::after, .fa-duotone.fa-school::after {
  content: "\f549\f549"; }
.fad.fa-igloo::after, .fa-duotone.fa-igloo::after {
  content: "\f7ae\f7ae"; }
.fad.fa-bracket-round::after, .fa-duotone.fa-bracket-round::after {
  content: "\28\28"; }
.fad.fa-parenthesis::after, .fa-duotone.fa-parenthesis::after {
  content: "\28\28"; }
.fad.fa-joint::after, .fa-duotone.fa-joint::after {
  content: "\f595\f595"; }
.fad.fa-horse-saddle::after, .fa-duotone.fa-horse-saddle::after {
  content: "\f8c3\f8c3"; }
.fad.fa-mug-marshmallows::after, .fa-duotone.fa-mug-marshmallows::after {
  content: "\f7b7\f7b7"; }
.fad.fa-filters::after, .fa-duotone.fa-filters::after {
  content: "\e17e\e17e"; }
.fad.fa-bell-on::after, .fa-duotone.fa-bell-on::after {
  content: "\f8fa\f8fa"; }
.fad.fa-angle-right::after, .fa-duotone.fa-angle-right::after {
  content: "\f105\f105"; }
.fad.fa-dial-med::after, .fa-duotone.fa-dial-med::after {
  content: "\e15f\e15f"; }
.fad.fa-horse::after, .fa-duotone.fa-horse::after {
  content: "\f6f0\f6f0"; }
.fad.fa-q::after, .fa-duotone.fa-q::after {
  content: "\51\51"; }
.fad.fa-monitor-waveform::after, .fa-duotone.fa-monitor-waveform::after {
  content: "\f611\f611"; }
.fad.fa-monitor-heart-rate::after, .fa-duotone.fa-monitor-heart-rate::after {
  content: "\f611\f611"; }
.fad.fa-link-simple::after, .fa-duotone.fa-link-simple::after {
  content: "\e1cd\e1cd"; }
.fad.fa-whistle::after, .fa-duotone.fa-whistle::after {
  content: "\f460\f460"; }
.fad.fa-g::after, .fa-duotone.fa-g::after {
  content: "\47\47"; }
.fad.fa-wine-glass-crack::after, .fa-duotone.fa-wine-glass-crack::after {
  content: "\f4bb\f4bb"; }
.fad.fa-fragile::after, .fa-duotone.fa-fragile::after {
  content: "\f4bb\f4bb"; }
.fad.fa-slot-machine::after, .fa-duotone.fa-slot-machine::after {
  content: "\e3ce\e3ce"; }
.fad.fa-notes-medical::after, .fa-duotone.fa-notes-medical::after {
  content: "\f481\f481"; }
.fad.fa-car-wash::after, .fa-duotone.fa-car-wash::after {
  content: "\f5e6\f5e6"; }
.fad.fa-escalator::after, .fa-duotone.fa-escalator::after {
  content: "\e171\e171"; }
.fad.fa-comment-image::after, .fa-duotone.fa-comment-image::after {
  content: "\e148\e148"; }
.fad.fa-temperature-half::after, .fa-duotone.fa-temperature-half::after {
  content: "\f2c9\f2c9"; }
.fad.fa-temperature-2::after, .fa-duotone.fa-temperature-2::after {
  content: "\f2c9\f2c9"; }
.fad.fa-thermometer-2::after, .fa-duotone.fa-thermometer-2::after {
  content: "\f2c9\f2c9"; }
.fad.fa-thermometer-half::after, .fa-duotone.fa-thermometer-half::after {
  content: "\f2c9\f2c9"; }
.fad.fa-dong-sign::after, .fa-duotone.fa-dong-sign::after {
  content: "\e169\e169"; }
.fad.fa-donut::after, .fa-duotone.fa-donut::after {
  content: "\e406\e406"; }
.fad.fa-doughnut::after, .fa-duotone.fa-doughnut::after {
  content: "\e406\e406"; }
.fad.fa-capsules::after, .fa-duotone.fa-capsules::after {
  content: "\f46b\f46b"; }
.fad.fa-poo-storm::after, .fa-duotone.fa-poo-storm::after {
  content: "\f75a\f75a"; }
.fad.fa-poo-bolt::after, .fa-duotone.fa-poo-bolt::after {
  content: "\f75a\f75a"; }
.fad.fa-tally-1::after, .fa-duotone.fa-tally-1::after {
  content: "\e294\e294"; }
.fad.fa-face-frown-open::after, .fa-duotone.fa-face-frown-open::after {
  content: "\f57a\f57a"; }
.fad.fa-frown-open::after, .fa-duotone.fa-frown-open::after {
  content: "\f57a\f57a"; }
.fad.fa-square-dashed::after, .fa-duotone.fa-square-dashed::after {
  content: "\e269\e269"; }
.fad.fa-square-j::after, .fa-duotone.fa-square-j::after {
  content: "\e273\e273"; }
.fad.fa-hand-point-up::after, .fa-duotone.fa-hand-point-up::after {
  content: "\f0a6\f0a6"; }
.fad.fa-money-bill::after, .fa-duotone.fa-money-bill::after {
  content: "\f0d6\f0d6"; }
.fad.fa-arrow-up-big-small::after, .fa-duotone.fa-arrow-up-big-small::after {
  content: "\f88e\f88e"; }
.fad.fa-sort-size-up::after, .fa-duotone.fa-sort-size-up::after {
  content: "\f88e\f88e"; }
.fad.fa-barcode-read::after, .fa-duotone.fa-barcode-read::after {
  content: "\f464\f464"; }
.fad.fa-baguette::after, .fa-duotone.fa-baguette::after {
  content: "\e3d8\e3d8"; }
.fad.fa-bowl-soft-serve::after, .fa-duotone.fa-bowl-soft-serve::after {
  content: "\e46b\e46b"; }
.fad.fa-face-holding-back-tears::after, .fa-duotone.fa-face-holding-back-tears::after {
  content: "\e482\e482"; }
.fad.fa-square-up::after, .fa-duotone.fa-square-up::after {
  content: "\f353\f353"; }
.fad.fa-arrow-alt-square-up::after, .fa-duotone.fa-arrow-alt-square-up::after {
  content: "\f353\f353"; }
.fad.fa-train-subway-tunnel::after, .fa-duotone.fa-train-subway-tunnel::after {
  content: "\e2a3\e2a3"; }
.fad.fa-subway-tunnel::after, .fa-duotone.fa-subway-tunnel::after {
  content: "\e2a3\e2a3"; }
.fad.fa-square-exclamation::after, .fa-duotone.fa-square-exclamation::after {
  content: "\f321\f321"; }
.fad.fa-exclamation-square::after, .fa-duotone.fa-exclamation-square::after {
  content: "\f321\f321"; }
.fad.fa-semicolon::after, .fa-duotone.fa-semicolon::after {
  content: "\3b\3b"; }
.fad.fa-bookmark::after, .fa-duotone.fa-bookmark::after {
  content: "\f02e\f02e"; }
.fad.fa-fan-table::after, .fa-duotone.fa-fan-table::after {
  content: "\e004\e004"; }
.fad.fa-align-justify::after, .fa-duotone.fa-align-justify::after {
  content: "\f039\f039"; }
.fad.fa-battery-low::after, .fa-duotone.fa-battery-low::after {
  content: "\e0b1\e0b1"; }
.fad.fa-battery-1::after, .fa-duotone.fa-battery-1::after {
  content: "\e0b1\e0b1"; }
.fad.fa-credit-card-front::after, .fa-duotone.fa-credit-card-front::after {
  content: "\f38a\f38a"; }
.fad.fa-brain-arrow-curved-right::after, .fa-duotone.fa-brain-arrow-curved-right::after {
  content: "\f677\f677"; }
.fad.fa-mind-share::after, .fa-duotone.fa-mind-share::after {
  content: "\f677\f677"; }
.fad.fa-umbrella-beach::after, .fa-duotone.fa-umbrella-beach::after {
  content: "\f5ca\f5ca"; }
.fad.fa-helmet-un::after, .fa-duotone.fa-helmet-un::after {
  content: "\e503\e503"; }
.fad.fa-location-smile::after, .fa-duotone.fa-location-smile::after {
  content: "\f60d\f60d"; }
.fad.fa-map-marker-smile::after, .fa-duotone.fa-map-marker-smile::after {
  content: "\f60d\f60d"; }
.fad.fa-arrow-left-to-line::after, .fa-duotone.fa-arrow-left-to-line::after {
  content: "\f33e\f33e"; }
.fad.fa-arrow-to-left::after, .fa-duotone.fa-arrow-to-left::after {
  content: "\f33e\f33e"; }
.fad.fa-bullseye::after, .fa-duotone.fa-bullseye::after {
  content: "\f140\f140"; }
.fad.fa-sushi::after, .fa-duotone.fa-sushi::after {
  content: "\e48a\e48a"; }
.fad.fa-nigiri::after, .fa-duotone.fa-nigiri::after {
  content: "\e48a\e48a"; }
.fad.fa-message-captions::after, .fa-duotone.fa-message-captions::after {
  content: "\e1de\e1de"; }
.fad.fa-comment-alt-captions::after, .fa-duotone.fa-comment-alt-captions::after {
  content: "\e1de\e1de"; }
.fad.fa-trash-list::after, .fa-duotone.fa-trash-list::after {
  content: "\e2b1\e2b1"; }
.fad.fa-bacon::after, .fa-duotone.fa-bacon::after {
  content: "\f7e5\f7e5"; }
.fad.fa-option::after, .fa-duotone.fa-option::after {
  content: "\e318\e318"; }
.fad.fa-hand-point-down::after, .fa-duotone.fa-hand-point-down::after {
  content: "\f0a7\f0a7"; }
.fad.fa-arrow-up-from-bracket::after, .fa-duotone.fa-arrow-up-from-bracket::after {
  content: "\e09a\e09a"; }
.fad.fa-trash-plus::after, .fa-duotone.fa-trash-plus::after {
  content: "\e2b2\e2b2"; }
.fad.fa-objects-align-top::after, .fa-duotone.fa-objects-align-top::after {
  content: "\e3c0\e3c0"; }
.fad.fa-folder::after, .fa-duotone.fa-folder::after {
  content: "\f07b\f07b"; }
.fad.fa-folder-blank::after, .fa-duotone.fa-folder-blank::after {
  content: "\f07b\f07b"; }
.fad.fa-face-anxious-sweat::after, .fa-duotone.fa-face-anxious-sweat::after {
  content: "\e36a\e36a"; }
.fad.fa-credit-card-blank::after, .fa-duotone.fa-credit-card-blank::after {
  content: "\f389\f389"; }
.fad.fa-file-waveform::after, .fa-duotone.fa-file-waveform::after {
  content: "\f478\f478"; }
.fad.fa-file-medical-alt::after, .fa-duotone.fa-file-medical-alt::after {
  content: "\f478\f478"; }
.fad.fa-microchip-ai::after, .fa-duotone.fa-microchip-ai::after {
  content: "\e1ec\e1ec"; }
.fad.fa-mug::after, .fa-duotone.fa-mug::after {
  content: "\f874\f874"; }
.fad.fa-plane-up-slash::after, .fa-duotone.fa-plane-up-slash::after {
  content: "\e22e\e22e"; }
.fad.fa-radiation::after, .fa-duotone.fa-radiation::after {
  content: "\f7b9\f7b9"; }
.fad.fa-pen-circle::after, .fa-duotone.fa-pen-circle::after {
  content: "\e20e\e20e"; }
.fad.fa-chart-simple::after, .fa-duotone.fa-chart-simple::after {
  content: "\e473\e473"; }
.fad.fa-crutches::after, .fa-duotone.fa-crutches::after {
  content: "\f7f8\f7f8"; }
.fad.fa-circle-parking::after, .fa-duotone.fa-circle-parking::after {
  content: "\f615\f615"; }
.fad.fa-parking-circle::after, .fa-duotone.fa-parking-circle::after {
  content: "\f615\f615"; }
.fad.fa-mars-stroke::after, .fa-duotone.fa-mars-stroke::after {
  content: "\f229\f229"; }
.fad.fa-leaf-oak::after, .fa-duotone.fa-leaf-oak::after {
  content: "\f6f7\f6f7"; }
.fad.fa-square-bolt::after, .fa-duotone.fa-square-bolt::after {
  content: "\e265\e265"; }
.fad.fa-vial::after, .fa-duotone.fa-vial::after {
  content: "\f492\f492"; }
.fad.fa-gauge::after, .fa-duotone.fa-gauge::after {
  content: "\f624\f624"; }
.fad.fa-dashboard::after, .fa-duotone.fa-dashboard::after {
  content: "\f624\f624"; }
.fad.fa-gauge-med::after, .fa-duotone.fa-gauge-med::after {
  content: "\f624\f624"; }
.fad.fa-tachometer-alt-average::after, .fa-duotone.fa-tachometer-alt-average::after {
  content: "\f624\f624"; }
.fad.fa-wand-magic-sparkles::after, .fa-duotone.fa-wand-magic-sparkles::after {
  content: "\e2ca\e2ca"; }
.fad.fa-magic-wand-sparkles::after, .fa-duotone.fa-magic-wand-sparkles::after {
  content: "\e2ca\e2ca"; }
.fad.fa-lambda::after, .fa-duotone.fa-lambda::after {
  content: "\f66e\f66e"; }
.fad.fa-e::after, .fa-duotone.fa-e::after {
  content: "\45\45"; }
.fad.fa-pizza::after, .fa-duotone.fa-pizza::after {
  content: "\f817\f817"; }
.fad.fa-bowl-chopsticks-noodles::after, .fa-duotone.fa-bowl-chopsticks-noodles::after {
  content: "\e2ea\e2ea"; }
.fad.fa-h3::after, .fa-duotone.fa-h3::after {
  content: "\f315\f315"; }
.fad.fa-pen-clip::after, .fa-duotone.fa-pen-clip::after {
  content: "\f305\f305"; }
.fad.fa-pen-alt::after, .fa-duotone.fa-pen-alt::after {
  content: "\f305\f305"; }
.fad.fa-bridge-circle-exclamation::after, .fa-duotone.fa-bridge-circle-exclamation::after {
  content: "\e4ca\e4ca"; }
.fad.fa-badge-percent::after, .fa-duotone.fa-badge-percent::after {
  content: "\f646\f646"; }
.fad.fa-user::after, .fa-duotone.fa-user::after {
  content: "\f007\f007"; }
.fad.fa-sensor::after, .fa-duotone.fa-sensor::after {
  content: "\e028\e028"; }
.fad.fa-comma::after, .fa-duotone.fa-comma::after {
  content: "\2c\2c"; }
.fad.fa-school-circle-check::after, .fa-duotone.fa-school-circle-check::after {
  content: "\e56b\e56b"; }
.fad.fa-toilet-paper-under::after, .fa-duotone.fa-toilet-paper-under::after {
  content: "\e2a0\e2a0"; }
.fad.fa-toilet-paper-reverse::after, .fa-duotone.fa-toilet-paper-reverse::after {
  content: "\e2a0\e2a0"; }
.fad.fa-light-emergency::after, .fa-duotone.fa-light-emergency::after {
  content: "\e41f\e41f"; }
.fad.fa-arrow-down-to-arc::after, .fa-duotone.fa-arrow-down-to-arc::after {
  content: "\e4ae\e4ae"; }
.fad.fa-dumpster::after, .fa-duotone.fa-dumpster::after {
  content: "\f793\f793"; }
.fad.fa-van-shuttle::after, .fa-duotone.fa-van-shuttle::after {
  content: "\f5b6\f5b6"; }
.fad.fa-shuttle-van::after, .fa-duotone.fa-shuttle-van::after {
  content: "\f5b6\f5b6"; }
.fad.fa-building-user::after, .fa-duotone.fa-building-user::after {
  content: "\e4da\e4da"; }
.fad.fa-light-switch::after, .fa-duotone.fa-light-switch::after {
  content: "\e017\e017"; }
.fad.fa-square-caret-left::after, .fa-duotone.fa-square-caret-left::after {
  content: "\f191\f191"; }
.fad.fa-caret-square-left::after, .fa-duotone.fa-caret-square-left::after {
  content: "\f191\f191"; }
.fad.fa-highlighter::after, .fa-duotone.fa-highlighter::after {
  content: "\f591\f591"; }
.fad.fa-wave-pulse::after, .fa-duotone.fa-wave-pulse::after {
  content: "\f5f8\f5f8"; }
.fad.fa-heart-rate::after, .fa-duotone.fa-heart-rate::after {
  content: "\f5f8\f5f8"; }
.fad.fa-key::after, .fa-duotone.fa-key::after {
  content: "\f084\f084"; }
.fad.fa-hat-santa::after, .fa-duotone.fa-hat-santa::after {
  content: "\f7a7\f7a7"; }
.fad.fa-tamale::after, .fa-duotone.fa-tamale::after {
  content: "\e451\e451"; }
.fad.fa-box-check::after, .fa-duotone.fa-box-check::after {
  content: "\f467\f467"; }
.fad.fa-bullhorn::after, .fa-duotone.fa-bullhorn::after {
  content: "\f0a1\f0a1"; }
.fad.fa-steak::after, .fa-duotone.fa-steak::after {
  content: "\f824\f824"; }
.fad.fa-location-crosshairs-slash::after, .fa-duotone.fa-location-crosshairs-slash::after {
  content: "\f603\f603"; }
.fad.fa-location-slash::after, .fa-duotone.fa-location-slash::after {
  content: "\f603\f603"; }
.fad.fa-person-dolly::after, .fa-duotone.fa-person-dolly::after {
  content: "\f4d0\f4d0"; }
.fad.fa-globe::after, .fa-duotone.fa-globe::after {
  content: "\f0ac\f0ac"; }
.fad.fa-synagogue::after, .fa-duotone.fa-synagogue::after {
  content: "\f69b\f69b"; }
.fad.fa-file-chart-column::after, .fa-duotone.fa-file-chart-column::after {
  content: "\f659\f659"; }
.fad.fa-file-chart-line::after, .fa-duotone.fa-file-chart-line::after {
  content: "\f659\f659"; }
.fad.fa-person-half-dress::after, .fa-duotone.fa-person-half-dress::after {
  content: "\e548\e548"; }
.fad.fa-folder-image::after, .fa-duotone.fa-folder-image::after {
  content: "\e18a\e18a"; }
.fad.fa-calendar-pen::after, .fa-duotone.fa-calendar-pen::after {
  content: "\f333\f333"; }
.fad.fa-calendar-edit::after, .fa-duotone.fa-calendar-edit::after {
  content: "\f333\f333"; }
.fad.fa-road-bridge::after, .fa-duotone.fa-road-bridge::after {
  content: "\e563\e563"; }
.fad.fa-face-smile-tear::after, .fa-duotone.fa-face-smile-tear::after {
  content: "\e393\e393"; }
.fad.fa-message-plus::after, .fa-duotone.fa-message-plus::after {
  content: "\f4a8\f4a8"; }
.fad.fa-comment-alt-plus::after, .fa-duotone.fa-comment-alt-plus::after {
  content: "\f4a8\f4a8"; }
.fad.fa-location-arrow::after, .fa-duotone.fa-location-arrow::after {
  content: "\f124\f124"; }
.fad.fa-c::after, .fa-duotone.fa-c::after {
  content: "\43\43"; }
.fad.fa-tablet-button::after, .fa-duotone.fa-tablet-button::after {
  content: "\f10a\f10a"; }
.fad.fa-rectangle-history-circle-user::after, .fa-duotone.fa-rectangle-history-circle-user::after {
  content: "\e4a4\e4a4"; }
.fad.fa-building-lock::after, .fa-duotone.fa-building-lock::after {
  content: "\e4d6\e4d6"; }
.fad.fa-chart-line-up::after, .fa-duotone.fa-chart-line-up::after {
  content: "\e0e5\e0e5"; }
.fad.fa-mailbox::after, .fa-duotone.fa-mailbox::after {
  content: "\f813\f813"; }
.fad.fa-truck-bolt::after, .fa-duotone.fa-truck-bolt::after {
  content: "\e3d0\e3d0"; }
.fad.fa-pizza-slice::after, .fa-duotone.fa-pizza-slice::after {
  content: "\f818\f818"; }
.fad.fa-money-bill-wave::after, .fa-duotone.fa-money-bill-wave::after {
  content: "\f53a\f53a"; }
.fad.fa-chart-area::after, .fa-duotone.fa-chart-area::after {
  content: "\f1fe\f1fe"; }
.fad.fa-area-chart::after, .fa-duotone.fa-area-chart::after {
  content: "\f1fe\f1fe"; }
.fad.fa-house-flag::after, .fa-duotone.fa-house-flag::after {
  content: "\e50d\e50d"; }
.fad.fa-person-circle-minus::after, .fa-duotone.fa-person-circle-minus::after {
  content: "\e540\e540"; }
.fad.fa-scalpel::after, .fa-duotone.fa-scalpel::after {
  content: "\f61d\f61d"; }
.fad.fa-ban::after, .fa-duotone.fa-ban::after {
  content: "\f05e\f05e"; }
.fad.fa-cancel::after, .fa-duotone.fa-cancel::after {
  content: "\f05e\f05e"; }
.fad.fa-bell-exclamation::after, .fa-duotone.fa-bell-exclamation::after {
  content: "\f848\f848"; }
.fad.fa-circle-bookmark::after, .fa-duotone.fa-circle-bookmark::after {
  content: "\e100\e100"; }
.fad.fa-bookmark-circle::after, .fa-duotone.fa-bookmark-circle::after {
  content: "\e100\e100"; }
.fad.fa-egg-fried::after, .fa-duotone.fa-egg-fried::after {
  content: "\f7fc\f7fc"; }
.fad.fa-face-weary::after, .fa-duotone.fa-face-weary::after {
  content: "\e3a1\e3a1"; }
.fad.fa-uniform-martial-arts::after, .fa-duotone.fa-uniform-martial-arts::after {
  content: "\e3d1\e3d1"; }
.fad.fa-camera-rotate::after, .fa-duotone.fa-camera-rotate::after {
  content: "\e0d8\e0d8"; }
.fad.fa-sun-dust::after, .fa-duotone.fa-sun-dust::after {
  content: "\f764\f764"; }
.fad.fa-comment-text::after, .fa-duotone.fa-comment-text::after {
  content: "\e14d\e14d"; }
.fad.fa-spray-can-sparkles::after, .fa-duotone.fa-spray-can-sparkles::after {
  content: "\f5d0\f5d0"; }
.fad.fa-air-freshener::after, .fa-duotone.fa-air-freshener::after {
  content: "\f5d0\f5d0"; }
.fad.fa-signal-bars::after, .fa-duotone.fa-signal-bars::after {
  content: "\f690\f690"; }
.fad.fa-signal-alt::after, .fa-duotone.fa-signal-alt::after {
  content: "\f690\f690"; }
.fad.fa-signal-alt-4::after, .fa-duotone.fa-signal-alt-4::after {
  content: "\f690\f690"; }
.fad.fa-signal-bars-strong::after, .fa-duotone.fa-signal-bars-strong::after {
  content: "\f690\f690"; }
.fad.fa-diamond-exclamation::after, .fa-duotone.fa-diamond-exclamation::after {
  content: "\e405\e405"; }
.fad.fa-star::after, .fa-duotone.fa-star::after {
  content: "\f005\f005"; }
.fad.fa-dial-min::after, .fa-duotone.fa-dial-min::after {
  content: "\e161\e161"; }
.fad.fa-repeat::after, .fa-duotone.fa-repeat::after {
  content: "\f363\f363"; }
.fad.fa-cross::after, .fa-duotone.fa-cross::after {
  content: "\f654\f654"; }
.fad.fa-page-caret-down::after, .fa-duotone.fa-page-caret-down::after {
  content: "\e429\e429"; }
.fad.fa-file-caret-down::after, .fa-duotone.fa-file-caret-down::after {
  content: "\e429\e429"; }
.fad.fa-box::after, .fa-duotone.fa-box::after {
  content: "\f466\f466"; }
.fad.fa-venus-mars::after, .fa-duotone.fa-venus-mars::after {
  content: "\f228\f228"; }
.fad.fa-clock-seven-thirty::after, .fa-duotone.fa-clock-seven-thirty::after {
  content: "\e351\e351"; }
.fad.fa-arrow-pointer::after, .fa-duotone.fa-arrow-pointer::after {
  content: "\f245\f245"; }
.fad.fa-mouse-pointer::after, .fa-duotone.fa-mouse-pointer::after {
  content: "\f245\f245"; }
.fad.fa-clock-four-thirty::after, .fa-duotone.fa-clock-four-thirty::after {
  content: "\e34b\e34b"; }
.fad.fa-signal-bars-good::after, .fa-duotone.fa-signal-bars-good::after {
  content: "\f693\f693"; }
.fad.fa-signal-alt-3::after, .fa-duotone.fa-signal-alt-3::after {
  content: "\f693\f693"; }
.fad.fa-cactus::after, .fa-duotone.fa-cactus::after {
  content: "\f8a7\f8a7"; }
.fad.fa-maximize::after, .fa-duotone.fa-maximize::after {
  content: "\f31e\f31e"; }
.fad.fa-expand-arrows-alt::after, .fa-duotone.fa-expand-arrows-alt::after {
  content: "\f31e\f31e"; }
.fad.fa-charging-station::after, .fa-duotone.fa-charging-station::after {
  content: "\f5e7\f5e7"; }
.fad.fa-shapes::after, .fa-duotone.fa-shapes::after {
  content: "\f61f\f61f"; }
.fad.fa-triangle-circle-square::after, .fa-duotone.fa-triangle-circle-square::after {
  content: "\f61f\f61f"; }
.fad.fa-plane-tail::after, .fa-duotone.fa-plane-tail::after {
  content: "\e22c\e22c"; }
.fad.fa-gauge-simple-max::after, .fa-duotone.fa-gauge-simple-max::after {
  content: "\f62b\f62b"; }
.fad.fa-tachometer-fastest::after, .fa-duotone.fa-tachometer-fastest::after {
  content: "\f62b\f62b"; }
.fad.fa-circle-u::after, .fa-duotone.fa-circle-u::after {
  content: "\e127\e127"; }
.fad.fa-shield-slash::after, .fa-duotone.fa-shield-slash::after {
  content: "\e24b\e24b"; }
.fad.fa-square-phone-hangup::after, .fa-duotone.fa-square-phone-hangup::after {
  content: "\e27a\e27a"; }
.fad.fa-phone-square-down::after, .fa-duotone.fa-phone-square-down::after {
  content: "\e27a\e27a"; }
.fad.fa-arrow-up-left::after, .fa-duotone.fa-arrow-up-left::after {
  content: "\e09d\e09d"; }
.fad.fa-transporter-1::after, .fa-duotone.fa-transporter-1::after {
  content: "\e043\e043"; }
.fad.fa-peanuts::after, .fa-duotone.fa-peanuts::after {
  content: "\e431\e431"; }
.fad.fa-shuffle::after, .fa-duotone.fa-shuffle::after {
  content: "\f074\f074"; }
.fad.fa-random::after, .fa-duotone.fa-random::after {
  content: "\f074\f074"; }
.fad.fa-person-running::after, .fa-duotone.fa-person-running::after {
  content: "\f70c\f70c"; }
.fad.fa-running::after, .fa-duotone.fa-running::after {
  content: "\f70c\f70c"; }
.fad.fa-mobile-retro::after, .fa-duotone.fa-mobile-retro::after {
  content: "\e527\e527"; }
.fad.fa-grip-lines-vertical::after, .fa-duotone.fa-grip-lines-vertical::after {
  content: "\f7a5\f7a5"; }
.fad.fa-arrow-up-from-square::after, .fa-duotone.fa-arrow-up-from-square::after {
  content: "\e09c\e09c"; }
.fad.fa-file-dashed-line::after, .fa-duotone.fa-file-dashed-line::after {
  content: "\f877\f877"; }
.fad.fa-page-break::after, .fa-duotone.fa-page-break::after {
  content: "\f877\f877"; }
.fad.fa-bracket-curly-right::after, .fa-duotone.fa-bracket-curly-right::after {
  content: "\7d\7d"; }
.fad.fa-spider::after, .fa-duotone.fa-spider::after {
  content: "\f717\f717"; }
.fad.fa-clock-three::after, .fa-duotone.fa-clock-three::after {
  content: "\e356\e356"; }
.fad.fa-hands-bound::after, .fa-duotone.fa-hands-bound::after {
  content: "\e4f9\e4f9"; }
.fad.fa-scalpel-line-dashed::after, .fa-duotone.fa-scalpel-line-dashed::after {
  content: "\f61e\f61e"; }
.fad.fa-scalpel-path::after, .fa-duotone.fa-scalpel-path::after {
  content: "\f61e\f61e"; }
.fad.fa-file-invoice-dollar::after, .fa-duotone.fa-file-invoice-dollar::after {
  content: "\f571\f571"; }
.fad.fa-pipe-smoking::after, .fa-duotone.fa-pipe-smoking::after {
  content: "\e3c4\e3c4"; }
.fad.fa-face-astonished::after, .fa-duotone.fa-face-astonished::after {
  content: "\e36b\e36b"; }
.fad.fa-window::after, .fa-duotone.fa-window::after {
  content: "\f40e\f40e"; }
.fad.fa-plane-circle-exclamation::after, .fa-duotone.fa-plane-circle-exclamation::after {
  content: "\e556\e556"; }
.fad.fa-ear::after, .fa-duotone.fa-ear::after {
  content: "\f5f0\f5f0"; }
.fad.fa-file-lock::after, .fa-duotone.fa-file-lock::after {
  content: "\e3a6\e3a6"; }
.fad.fa-diagram-venn::after, .fa-duotone.fa-diagram-venn::after {
  content: "\e15a\e15a"; }
.fad.fa-x-ray::after, .fa-duotone.fa-x-ray::after {
  content: "\f497\f497"; }
.fad.fa-goal-net::after, .fa-duotone.fa-goal-net::after {
  content: "\e3ab\e3ab"; }
.fad.fa-coffin-cross::after, .fa-duotone.fa-coffin-cross::after {
  content: "\e051\e051"; }
.fad.fa-spell-check::after, .fa-duotone.fa-spell-check::after {
  content: "\f891\f891"; }
.fad.fa-location-xmark::after, .fa-duotone.fa-location-xmark::after {
  content: "\f60e\f60e"; }
.fad.fa-map-marker-times::after, .fa-duotone.fa-map-marker-times::after {
  content: "\f60e\f60e"; }
.fad.fa-map-marker-xmark::after, .fa-duotone.fa-map-marker-xmark::after {
  content: "\f60e\f60e"; }
.fad.fa-lasso::after, .fa-duotone.fa-lasso::after {
  content: "\f8c8\f8c8"; }
.fad.fa-slash::after, .fa-duotone.fa-slash::after {
  content: "\f715\f715"; }
.fad.fa-person-to-portal::after, .fa-duotone.fa-person-to-portal::after {
  content: "\e022\e022"; }
.fad.fa-portal-enter::after, .fa-duotone.fa-portal-enter::after {
  content: "\e022\e022"; }
.fad.fa-calendar-star::after, .fa-duotone.fa-calendar-star::after {
  content: "\f736\f736"; }
.fad.fa-computer-mouse::after, .fa-duotone.fa-computer-mouse::after {
  content: "\f8cc\f8cc"; }
.fad.fa-mouse::after, .fa-duotone.fa-mouse::after {
  content: "\f8cc\f8cc"; }
.fad.fa-arrow-right-to-bracket::after, .fa-duotone.fa-arrow-right-to-bracket::after {
  content: "\f090\f090"; }
.fad.fa-sign-in::after, .fa-duotone.fa-sign-in::after {
  content: "\f090\f090"; }
.fad.fa-pegasus::after, .fa-duotone.fa-pegasus::after {
  content: "\f703\f703"; }
.fad.fa-files-medical::after, .fa-duotone.fa-files-medical::after {
  content: "\f7fd\f7fd"; }
.fad.fa-nfc-lock::after, .fa-duotone.fa-nfc-lock::after {
  content: "\e1f8\e1f8"; }
.fad.fa-person-ski-lift::after, .fa-duotone.fa-person-ski-lift::after {
  content: "\f7c8\f7c8"; }
.fad.fa-ski-lift::after, .fa-duotone.fa-ski-lift::after {
  content: "\f7c8\f7c8"; }
.fad.fa-square-6::after, .fa-duotone.fa-square-6::after {
  content: "\e25b\e25b"; }
.fad.fa-shop-slash::after, .fa-duotone.fa-shop-slash::after {
  content: "\e070\e070"; }
.fad.fa-store-alt-slash::after, .fa-duotone.fa-store-alt-slash::after {
  content: "\e070\e070"; }
.fad.fa-wind-turbine::after, .fa-duotone.fa-wind-turbine::after {
  content: "\f89b\f89b"; }
.fad.fa-sliders-simple::after, .fa-duotone.fa-sliders-simple::after {
  content: "\e253\e253"; }
.fad.fa-badge-sheriff::after, .fa-duotone.fa-badge-sheriff::after {
  content: "\f8a2\f8a2"; }
.fad.fa-server::after, .fa-duotone.fa-server::after {
  content: "\f233\f233"; }
.fad.fa-virus-covid-slash::after, .fa-duotone.fa-virus-covid-slash::after {
  content: "\e4a9\e4a9"; }
.fad.fa-intersection::after, .fa-duotone.fa-intersection::after {
  content: "\f668\f668"; }
.fad.fa-shop-lock::after, .fa-duotone.fa-shop-lock::after {
  content: "\e4a5\e4a5"; }
.fad.fa-family::after, .fa-duotone.fa-family::after {
  content: "\e300\e300"; }
.fad.fa-hourglass-start::after, .fa-duotone.fa-hourglass-start::after {
  content: "\f251\f251"; }
.fad.fa-hourglass-1::after, .fa-duotone.fa-hourglass-1::after {
  content: "\f251\f251"; }
.fad.fa-user-hair-buns::after, .fa-duotone.fa-user-hair-buns::after {
  content: "\e3d3\e3d3"; }
.fad.fa-blender-phone::after, .fa-duotone.fa-blender-phone::after {
  content: "\f6b6\f6b6"; }
.fad.fa-hourglass-clock::after, .fa-duotone.fa-hourglass-clock::after {
  content: "\e41b\e41b"; }
.fad.fa-person-seat-reclined::after, .fa-duotone.fa-person-seat-reclined::after {
  content: "\e21f\e21f"; }
.fad.fa-paper-plane-top::after, .fa-duotone.fa-paper-plane-top::after {
  content: "\e20a\e20a"; }
.fad.fa-paper-plane-alt::after, .fa-duotone.fa-paper-plane-alt::after {
  content: "\e20a\e20a"; }
.fad.fa-send::after, .fa-duotone.fa-send::after {
  content: "\e20a\e20a"; }
.fad.fa-message-arrow-up::after, .fa-duotone.fa-message-arrow-up::after {
  content: "\e1dc\e1dc"; }
.fad.fa-comment-alt-arrow-up::after, .fa-duotone.fa-comment-alt-arrow-up::after {
  content: "\e1dc\e1dc"; }
.fad.fa-lightbulb-exclamation::after, .fa-duotone.fa-lightbulb-exclamation::after {
  content: "\f671\f671"; }
.fad.fa-layer-minus::after, .fa-duotone.fa-layer-minus::after {
  content: "\f5fe\f5fe"; }
.fad.fa-layer-group-minus::after, .fa-duotone.fa-layer-group-minus::after {
  content: "\f5fe\f5fe"; }
.fad.fa-circle-e::after, .fa-duotone.fa-circle-e::after {
  content: "\e109\e109"; }
.fad.fa-building-wheat::after, .fa-duotone.fa-building-wheat::after {
  content: "\e4db\e4db"; }
.fad.fa-gauge-max::after, .fa-duotone.fa-gauge-max::after {
  content: "\f626\f626"; }
.fad.fa-tachometer-alt-fastest::after, .fa-duotone.fa-tachometer-alt-fastest::after {
  content: "\f626\f626"; }
.fad.fa-person-breastfeeding::after, .fa-duotone.fa-person-breastfeeding::after {
  content: "\e53a\e53a"; }
.fad.fa-apostrophe::after, .fa-duotone.fa-apostrophe::after {
  content: "\27\27"; }
.fad.fa-fire-hydrant::after, .fa-duotone.fa-fire-hydrant::after {
  content: "\e17f\e17f"; }
.fad.fa-right-to-bracket::after, .fa-duotone.fa-right-to-bracket::after {
  content: "\f2f6\f2f6"; }
.fad.fa-sign-in-alt::after, .fa-duotone.fa-sign-in-alt::after {
  content: "\f2f6\f2f6"; }
.fad.fa-video-plus::after, .fa-duotone.fa-video-plus::after {
  content: "\f4e1\f4e1"; }
.fad.fa-square-right::after, .fa-duotone.fa-square-right::after {
  content: "\f352\f352"; }
.fad.fa-arrow-alt-square-right::after, .fa-duotone.fa-arrow-alt-square-right::after {
  content: "\f352\f352"; }
.fad.fa-comment-smile::after, .fa-duotone.fa-comment-smile::after {
  content: "\f4b4\f4b4"; }
.fad.fa-venus::after, .fa-duotone.fa-venus::after {
  content: "\f221\f221"; }
.fad.fa-passport::after, .fa-duotone.fa-passport::after {
  content: "\f5ab\f5ab"; }
.fad.fa-inbox-in::after, .fa-duotone.fa-inbox-in::after {
  content: "\f310\f310"; }
.fad.fa-inbox-arrow-down::after, .fa-duotone.fa-inbox-arrow-down::after {
  content: "\f310\f310"; }
.fad.fa-heart-pulse::after, .fa-duotone.fa-heart-pulse::after {
  content: "\f21e\f21e"; }
.fad.fa-heartbeat::after, .fa-duotone.fa-heartbeat::after {
  content: "\f21e\f21e"; }
.fad.fa-circle-8::after, .fa-duotone.fa-circle-8::after {
  content: "\e0f5\e0f5"; }
.fad.fa-clouds-moon::after, .fa-duotone.fa-clouds-moon::after {
  content: "\f745\f745"; }
.fad.fa-clock-ten-thirty::after, .fa-duotone.fa-clock-ten-thirty::after {
  content: "\e355\e355"; }
.fad.fa-people-carry-box::after, .fa-duotone.fa-people-carry-box::after {
  content: "\f4ce\f4ce"; }
.fad.fa-people-carry::after, .fa-duotone.fa-people-carry::after {
  content: "\f4ce\f4ce"; }
.fad.fa-folder-user::after, .fa-duotone.fa-folder-user::after {
  content: "\e18e\e18e"; }
.fad.fa-trash-can-xmark::after, .fa-duotone.fa-trash-can-xmark::after {
  content: "\e2ae\e2ae"; }
.fad.fa-temperature-high::after, .fa-duotone.fa-temperature-high::after {
  content: "\f769\f769"; }
.fad.fa-microchip::after, .fa-duotone.fa-microchip::after {
  content: "\f2db\f2db"; }
.fad.fa-left-long-to-line::after, .fa-duotone.fa-left-long-to-line::after {
  content: "\e41e\e41e"; }
.fad.fa-crown::after, .fa-duotone.fa-crown::after {
  content: "\f521\f521"; }
.fad.fa-weight-hanging::after, .fa-duotone.fa-weight-hanging::after {
  content: "\f5cd\f5cd"; }
.fad.fa-xmarks-lines::after, .fa-duotone.fa-xmarks-lines::after {
  content: "\e59a\e59a"; }
.fad.fa-file-prescription::after, .fa-duotone.fa-file-prescription::after {
  content: "\f572\f572"; }
.fad.fa-calendar-range::after, .fa-duotone.fa-calendar-range::after {
  content: "\e0d6\e0d6"; }
.fad.fa-flower-daffodil::after, .fa-duotone.fa-flower-daffodil::after {
  content: "\f800\f800"; }
.fad.fa-hand-back-point-up::after, .fa-duotone.fa-hand-back-point-up::after {
  content: "\e1a2\e1a2"; }
.fad.fa-weight-scale::after, .fa-duotone.fa-weight-scale::after {
  content: "\f496\f496"; }
.fad.fa-weight::after, .fa-duotone.fa-weight::after {
  content: "\f496\f496"; }
.fad.fa-star-exclamation::after, .fa-duotone.fa-star-exclamation::after {
  content: "\f2f3\f2f3"; }
.fad.fa-books::after, .fa-duotone.fa-books::after {
  content: "\f5db\f5db"; }
.fad.fa-user-group::after, .fa-duotone.fa-user-group::after {
  content: "\f500\f500"; }
.fad.fa-user-friends::after, .fa-duotone.fa-user-friends::after {
  content: "\f500\f500"; }
.fad.fa-arrow-up-a-z::after, .fa-duotone.fa-arrow-up-a-z::after {
  content: "\f15e\f15e"; }
.fad.fa-sort-alpha-up::after, .fa-duotone.fa-sort-alpha-up::after {
  content: "\f15e\f15e"; }
.fad.fa-layer-plus::after, .fa-duotone.fa-layer-plus::after {
  content: "\f5ff\f5ff"; }
.fad.fa-layer-group-plus::after, .fa-duotone.fa-layer-group-plus::after {
  content: "\f5ff\f5ff"; }
.fad.fa-play-pause::after, .fa-duotone.fa-play-pause::after {
  content: "\e22f\e22f"; }
.fad.fa-block-question::after, .fa-duotone.fa-block-question::after {
  content: "\e3dd\e3dd"; }
.fad.fa-snooze::after, .fa-duotone.fa-snooze::after {
  content: "\f880\f880"; }
.fad.fa-zzz::after, .fa-duotone.fa-zzz::after {
  content: "\f880\f880"; }
.fad.fa-scanner-image::after, .fa-duotone.fa-scanner-image::after {
  content: "\f8f3\f8f3"; }
.fad.fa-tv-retro::after, .fa-duotone.fa-tv-retro::after {
  content: "\f401\f401"; }
.fad.fa-square-t::after, .fa-duotone.fa-square-t::after {
  content: "\e280\e280"; }
.fad.fa-farm::after, .fa-duotone.fa-farm::after {
  content: "\f864\f864"; }
.fad.fa-barn-silo::after, .fa-duotone.fa-barn-silo::after {
  content: "\f864\f864"; }
.fad.fa-chess-knight::after, .fa-duotone.fa-chess-knight::after {
  content: "\f441\f441"; }
.fad.fa-bars-sort::after, .fa-duotone.fa-bars-sort::after {
  content: "\e0ae\e0ae"; }
.fad.fa-pallet-boxes::after, .fa-duotone.fa-pallet-boxes::after {
  content: "\f483\f483"; }
.fad.fa-palette-boxes::after, .fa-duotone.fa-palette-boxes::after {
  content: "\f483\f483"; }
.fad.fa-pallet-alt::after, .fa-duotone.fa-pallet-alt::after {
  content: "\f483\f483"; }
.fad.fa-face-laugh-squint::after, .fa-duotone.fa-face-laugh-squint::after {
  content: "\f59b\f59b"; }
.fad.fa-laugh-squint::after, .fa-duotone.fa-laugh-squint::after {
  content: "\f59b\f59b"; }
.fad.fa-code-simple::after, .fa-duotone.fa-code-simple::after {
  content: "\e13d\e13d"; }
.fad.fa-bolt-slash::after, .fa-duotone.fa-bolt-slash::after {
  content: "\e0b8\e0b8"; }
.fad.fa-panel-fire::after, .fa-duotone.fa-panel-fire::after {
  content: "\e42f\e42f"; }
.fad.fa-binary-circle-check::after, .fa-duotone.fa-binary-circle-check::after {
  content: "\e33c\e33c"; }
.fad.fa-comment-minus::after, .fa-duotone.fa-comment-minus::after {
  content: "\f4b1\f4b1"; }
.fad.fa-burrito::after, .fa-duotone.fa-burrito::after {
  content: "\f7ed\f7ed"; }
.fad.fa-violin::after, .fa-duotone.fa-violin::after {
  content: "\f8ed\f8ed"; }
.fad.fa-objects-column::after, .fa-duotone.fa-objects-column::after {
  content: "\e3c1\e3c1"; }
.fad.fa-square-chevron-down::after, .fa-duotone.fa-square-chevron-down::after {
  content: "\f329\f329"; }
.fad.fa-chevron-square-down::after, .fa-duotone.fa-chevron-square-down::after {
  content: "\f329\f329"; }
.fad.fa-comment-plus::after, .fa-duotone.fa-comment-plus::after {
  content: "\f4b2\f4b2"; }
.fad.fa-triangle-instrument::after, .fa-duotone.fa-triangle-instrument::after {
  content: "\f8e2\f8e2"; }
.fad.fa-triangle-music::after, .fa-duotone.fa-triangle-music::after {
  content: "\f8e2\f8e2"; }
.fad.fa-wheelchair::after, .fa-duotone.fa-wheelchair::after {
  content: "\f193\f193"; }
.fad.fa-user-pilot-tie::after, .fa-duotone.fa-user-pilot-tie::after {
  content: "\e2c1\e2c1"; }
.fad.fa-piano-keyboard::after, .fa-duotone.fa-piano-keyboard::after {
  content: "\f8d5\f8d5"; }
.fad.fa-bed-empty::after, .fa-duotone.fa-bed-empty::after {
  content: "\f8f9\f8f9"; }
.fad.fa-circle-arrow-up::after, .fa-duotone.fa-circle-arrow-up::after {
  content: "\f0aa\f0aa"; }
.fad.fa-arrow-circle-up::after, .fa-duotone.fa-arrow-circle-up::after {
  content: "\f0aa\f0aa"; }
.fad.fa-toggle-on::after, .fa-duotone.fa-toggle-on::after {
  content: "\f205\f205"; }
.fad.fa-rectangle-vertical::after, .fa-duotone.fa-rectangle-vertical::after {
  content: "\f2fb\f2fb"; }
.fad.fa-rectangle-portrait::after, .fa-duotone.fa-rectangle-portrait::after {
  content: "\f2fb\f2fb"; }
.fad.fa-person-walking::after, .fa-duotone.fa-person-walking::after {
  content: "\f554\f554"; }
.fad.fa-walking::after, .fa-duotone.fa-walking::after {
  content: "\f554\f554"; }
.fad.fa-l::after, .fa-duotone.fa-l::after {
  content: "\4c\4c"; }
.fad.fa-signal-stream::after, .fa-duotone.fa-signal-stream::after {
  content: "\f8dd\f8dd"; }
.fad.fa-down-to-bracket::after, .fa-duotone.fa-down-to-bracket::after {
  content: "\e4e7\e4e7"; }
.fad.fa-circle-z::after, .fa-duotone.fa-circle-z::after {
  content: "\e130\e130"; }
.fad.fa-stars::after, .fa-duotone.fa-stars::after {
  content: "\f762\f762"; }
.fad.fa-fire::after, .fa-duotone.fa-fire::after {
  content: "\f06d\f06d"; }
.fad.fa-bed-pulse::after, .fa-duotone.fa-bed-pulse::after {
  content: "\f487\f487"; }
.fad.fa-procedures::after, .fa-duotone.fa-procedures::after {
  content: "\f487\f487"; }
.fad.fa-house-day::after, .fa-duotone.fa-house-day::after {
  content: "\e00e\e00e"; }
.fad.fa-shuttle-space::after, .fa-duotone.fa-shuttle-space::after {
  content: "\f197\f197"; }
.fad.fa-space-shuttle::after, .fa-duotone.fa-space-shuttle::after {
  content: "\f197\f197"; }
.fad.fa-shirt-long-sleeve::after, .fa-duotone.fa-shirt-long-sleeve::after {
  content: "\e3c7\e3c7"; }
.fad.fa-chart-pie-simple::after, .fa-duotone.fa-chart-pie-simple::after {
  content: "\f64e\f64e"; }
.fad.fa-chart-pie-alt::after, .fa-duotone.fa-chart-pie-alt::after {
  content: "\f64e\f64e"; }
.fad.fa-face-laugh::after, .fa-duotone.fa-face-laugh::after {
  content: "\f599\f599"; }
.fad.fa-laugh::after, .fa-duotone.fa-laugh::after {
  content: "\f599\f599"; }
.fad.fa-folder-open::after, .fa-duotone.fa-folder-open::after {
  content: "\f07c\f07c"; }
.fad.fa-album-collection-circle-user::after, .fa-duotone.fa-album-collection-circle-user::after {
  content: "\e48f\e48f"; }
.fad.fa-candy::after, .fa-duotone.fa-candy::after {
  content: "\e3e7\e3e7"; }
.fad.fa-bowl-hot::after, .fa-duotone.fa-bowl-hot::after {
  content: "\f823\f823"; }
.fad.fa-soup::after, .fa-duotone.fa-soup::after {
  content: "\f823\f823"; }
.fad.fa-flatbread::after, .fa-duotone.fa-flatbread::after {
  content: "\e40b\e40b"; }
.fad.fa-heart-circle-plus::after, .fa-duotone.fa-heart-circle-plus::after {
  content: "\e500\e500"; }
.fad.fa-code-fork::after, .fa-duotone.fa-code-fork::after {
  content: "\e13b\e13b"; }
.fad.fa-city::after, .fa-duotone.fa-city::after {
  content: "\f64f\f64f"; }
.fad.fa-signal-bars-weak::after, .fa-duotone.fa-signal-bars-weak::after {
  content: "\f691\f691"; }
.fad.fa-signal-alt-1::after, .fa-duotone.fa-signal-alt-1::after {
  content: "\f691\f691"; }
.fad.fa-microphone-lines::after, .fa-duotone.fa-microphone-lines::after {
  content: "\f3c9\f3c9"; }
.fad.fa-microphone-alt::after, .fa-duotone.fa-microphone-alt::after {
  content: "\f3c9\f3c9"; }
.fad.fa-clock-twelve::after, .fa-duotone.fa-clock-twelve::after {
  content: "\e358\e358"; }
.fad.fa-pepper-hot::after, .fa-duotone.fa-pepper-hot::after {
  content: "\f816\f816"; }
.fad.fa-citrus-slice::after, .fa-duotone.fa-citrus-slice::after {
  content: "\e2f5\e2f5"; }
.fad.fa-sheep::after, .fa-duotone.fa-sheep::after {
  content: "\f711\f711"; }
.fad.fa-unlock::after, .fa-duotone.fa-unlock::after {
  content: "\f09c\f09c"; }
.fad.fa-colon-sign::after, .fa-duotone.fa-colon-sign::after {
  content: "\e140\e140"; }
.fad.fa-headset::after, .fa-duotone.fa-headset::after {
  content: "\f590\f590"; }
.fad.fa-badger-honey::after, .fa-duotone.fa-badger-honey::after {
  content: "\f6b4\f6b4"; }
.fad.fa-h4::after, .fa-duotone.fa-h4::after {
  content: "\f86a\f86a"; }
.fad.fa-store-slash::after, .fa-duotone.fa-store-slash::after {
  content: "\e071\e071"; }
.fad.fa-road-circle-xmark::after, .fa-duotone.fa-road-circle-xmark::after {
  content: "\e566\e566"; }
.fad.fa-signal-slash::after, .fa-duotone.fa-signal-slash::after {
  content: "\f695\f695"; }
.fad.fa-user-minus::after, .fa-duotone.fa-user-minus::after {
  content: "\f503\f503"; }
.fad.fa-mars-stroke-up::after, .fa-duotone.fa-mars-stroke-up::after {
  content: "\f22a\f22a"; }
.fad.fa-mars-stroke-v::after, .fa-duotone.fa-mars-stroke-v::after {
  content: "\f22a\f22a"; }
.fad.fa-champagne-glasses::after, .fa-duotone.fa-champagne-glasses::after {
  content: "\f79f\f79f"; }
.fad.fa-glass-cheers::after, .fa-duotone.fa-glass-cheers::after {
  content: "\f79f\f79f"; }
.fad.fa-taco::after, .fa-duotone.fa-taco::after {
  content: "\f826\f826"; }
.fad.fa-hexagon-plus::after, .fa-duotone.fa-hexagon-plus::after {
  content: "\f300\f300"; }
.fad.fa-plus-hexagon::after, .fa-duotone.fa-plus-hexagon::after {
  content: "\f300\f300"; }
.fad.fa-clipboard::after, .fa-duotone.fa-clipboard::after {
  content: "\f328\f328"; }
.fad.fa-house-circle-exclamation::after, .fa-duotone.fa-house-circle-exclamation::after {
  content: "\e50a\e50a"; }
.fad.fa-file-arrow-up::after, .fa-duotone.fa-file-arrow-up::after {
  content: "\f574\f574"; }
.fad.fa-file-upload::after, .fa-duotone.fa-file-upload::after {
  content: "\f574\f574"; }
.fad.fa-wifi::after, .fa-duotone.fa-wifi::after {
  content: "\f1eb\f1eb"; }
.fad.fa-wifi-3::after, .fa-duotone.fa-wifi-3::after {
  content: "\f1eb\f1eb"; }
.fad.fa-wifi-strong::after, .fa-duotone.fa-wifi-strong::after {
  content: "\f1eb\f1eb"; }
.fad.fa-messages::after, .fa-duotone.fa-messages::after {
  content: "\f4b6\f4b6"; }
.fad.fa-comments-alt::after, .fa-duotone.fa-comments-alt::after {
  content: "\f4b6\f4b6"; }
.fad.fa-bath::after, .fa-duotone.fa-bath::after {
  content: "\f2cd\f2cd"; }
.fad.fa-bathtub::after, .fa-duotone.fa-bathtub::after {
  content: "\f2cd\f2cd"; }
.fad.fa-umbrella-simple::after, .fa-duotone.fa-umbrella-simple::after {
  content: "\e2bc\e2bc"; }
.fad.fa-umbrella-alt::after, .fa-duotone.fa-umbrella-alt::after {
  content: "\e2bc\e2bc"; }
.fad.fa-rectangle-history-circle-plus::after, .fa-duotone.fa-rectangle-history-circle-plus::after {
  content: "\e4a3\e4a3"; }
.fad.fa-underline::after, .fa-duotone.fa-underline::after {
  content: "\f0cd\f0cd"; }
.fad.fa-prescription-bottle-pill::after, .fa-duotone.fa-prescription-bottle-pill::after {
  content: "\e5c0\e5c0"; }
.fad.fa-user-pen::after, .fa-duotone.fa-user-pen::after {
  content: "\f4ff\f4ff"; }
.fad.fa-user-edit::after, .fa-duotone.fa-user-edit::after {
  content: "\f4ff\f4ff"; }
.fad.fa-binary-slash::after, .fa-duotone.fa-binary-slash::after {
  content: "\e33e\e33e"; }
.fad.fa-square-o::after, .fa-duotone.fa-square-o::after {
  content: "\e278\e278"; }
.fad.fa-signature::after, .fa-duotone.fa-signature::after {
  content: "\f5b7\f5b7"; }
.fad.fa-stroopwafel::after, .fa-duotone.fa-stroopwafel::after {
  content: "\f551\f551"; }
.fad.fa-bold::after, .fa-duotone.fa-bold::after {
  content: "\f032\f032"; }
.fad.fa-anchor-lock::after, .fa-duotone.fa-anchor-lock::after {
  content: "\e4ad\e4ad"; }
.fad.fa-building-ngo::after, .fa-duotone.fa-building-ngo::after {
  content: "\e4d7\e4d7"; }
.fad.fa-transporter-3::after, .fa-duotone.fa-transporter-3::after {
  content: "\e045\e045"; }
.fad.fa-engine-warning::after, .fa-duotone.fa-engine-warning::after {
  content: "\f5f2\f5f2"; }
.fad.fa-engine-exclamation::after, .fa-duotone.fa-engine-exclamation::after {
  content: "\f5f2\f5f2"; }
.fad.fa-circle-down-right::after, .fa-duotone.fa-circle-down-right::after {
  content: "\e108\e108"; }
.fad.fa-square-k::after, .fa-duotone.fa-square-k::after {
  content: "\e274\e274"; }
.fad.fa-manat-sign::after, .fa-duotone.fa-manat-sign::after {
  content: "\e1d5\e1d5"; }
.fad.fa-money-check-pen::after, .fa-duotone.fa-money-check-pen::after {
  content: "\f872\f872"; }
.fad.fa-money-check-edit::after, .fa-duotone.fa-money-check-edit::after {
  content: "\f872\f872"; }
.fad.fa-not-equal::after, .fa-duotone.fa-not-equal::after {
  content: "\f53e\f53e"; }
.fad.fa-border-top-left::after, .fa-duotone.fa-border-top-left::after {
  content: "\f853\f853"; }
.fad.fa-border-style::after, .fa-duotone.fa-border-style::after {
  content: "\f853\f853"; }
.fad.fa-map-location-dot::after, .fa-duotone.fa-map-location-dot::after {
  content: "\f5a0\f5a0"; }
.fad.fa-map-marked-alt::after, .fa-duotone.fa-map-marked-alt::after {
  content: "\f5a0\f5a0"; }
.fad.fa-tilde::after, .fa-duotone.fa-tilde::after {
  content: "\7e\7e"; }
.fad.fa-jedi::after, .fa-duotone.fa-jedi::after {
  content: "\f669\f669"; }
.fad.fa-square-poll-vertical::after, .fa-duotone.fa-square-poll-vertical::after {
  content: "\f681\f681"; }
.fad.fa-poll::after, .fa-duotone.fa-poll::after {
  content: "\f681\f681"; }
.fad.fa-arrow-down-square-triangle::after, .fa-duotone.fa-arrow-down-square-triangle::after {
  content: "\f889\f889"; }
.fad.fa-sort-shapes-down-alt::after, .fa-duotone.fa-sort-shapes-down-alt::after {
  content: "\f889\f889"; }
.fad.fa-mug-hot::after, .fa-duotone.fa-mug-hot::after {
  content: "\f7b6\f7b6"; }
.fad.fa-dog-leashed::after, .fa-duotone.fa-dog-leashed::after {
  content: "\f6d4\f6d4"; }
.fad.fa-car-battery::after, .fa-duotone.fa-car-battery::after {
  content: "\f5df\f5df"; }
.fad.fa-battery-car::after, .fa-duotone.fa-battery-car::after {
  content: "\f5df\f5df"; }
.fad.fa-face-downcast-sweat::after, .fa-duotone.fa-face-downcast-sweat::after {
  content: "\e371\e371"; }
.fad.fa-mailbox-flag-up::after, .fa-duotone.fa-mailbox-flag-up::after {
  content: "\e5bb\e5bb"; }
.fad.fa-memo-circle-info::after, .fa-duotone.fa-memo-circle-info::after {
  content: "\e49a\e49a"; }
.fad.fa-gift::after, .fa-duotone.fa-gift::after {
  content: "\f06b\f06b"; }
.fad.fa-dice-two::after, .fa-duotone.fa-dice-two::after {
  content: "\f528\f528"; }
.fad.fa-volume::after, .fa-duotone.fa-volume::after {
  content: "\f6a8\f6a8"; }
.fad.fa-volume-medium::after, .fa-duotone.fa-volume-medium::after {
  content: "\f6a8\f6a8"; }
.fad.fa-transporter-5::after, .fa-duotone.fa-transporter-5::after {
  content: "\e2a6\e2a6"; }
.fad.fa-gauge-circle-bolt::after, .fa-duotone.fa-gauge-circle-bolt::after {
  content: "\e496\e496"; }
.fad.fa-coin-front::after, .fa-duotone.fa-coin-front::after {
  content: "\e3fc\e3fc"; }
.fad.fa-file-slash::after, .fa-duotone.fa-file-slash::after {
  content: "\e3a7\e3a7"; }
.fad.fa-message-arrow-up-right::after, .fa-duotone.fa-message-arrow-up-right::after {
  content: "\e1dd\e1dd"; }
.fad.fa-treasure-chest::after, .fa-duotone.fa-treasure-chest::after {
  content: "\f723\f723"; }
.fad.fa-chess-queen::after, .fa-duotone.fa-chess-queen::after {
  content: "\f445\f445"; }
.fad.fa-paintbrush-fine::after, .fa-duotone.fa-paintbrush-fine::after {
  content: "\f5a9\f5a9"; }
.fad.fa-paint-brush-alt::after, .fa-duotone.fa-paint-brush-alt::after {
  content: "\f5a9\f5a9"; }
.fad.fa-paint-brush-fine::after, .fa-duotone.fa-paint-brush-fine::after {
  content: "\f5a9\f5a9"; }
.fad.fa-paintbrush-alt::after, .fa-duotone.fa-paintbrush-alt::after {
  content: "\f5a9\f5a9"; }
.fad.fa-glasses::after, .fa-duotone.fa-glasses::after {
  content: "\f530\f530"; }
.fad.fa-hood-cloak::after, .fa-duotone.fa-hood-cloak::after {
  content: "\f6ef\f6ef"; }
.fad.fa-square-quote::after, .fa-duotone.fa-square-quote::after {
  content: "\e329\e329"; }
.fad.fa-up-left::after, .fa-duotone.fa-up-left::after {
  content: "\e2bd\e2bd"; }
.fad.fa-bring-front::after, .fa-duotone.fa-bring-front::after {
  content: "\f857\f857"; }
.fad.fa-chess-board::after, .fa-duotone.fa-chess-board::after {
  content: "\f43c\f43c"; }
.fad.fa-burger-cheese::after, .fa-duotone.fa-burger-cheese::after {
  content: "\f7f1\f7f1"; }
.fad.fa-cheeseburger::after, .fa-duotone.fa-cheeseburger::after {
  content: "\f7f1\f7f1"; }
.fad.fa-building-circle-check::after, .fa-duotone.fa-building-circle-check::after {
  content: "\e4d2\e4d2"; }
.fad.fa-repeat-1::after, .fa-duotone.fa-repeat-1::after {
  content: "\f365\f365"; }
.fad.fa-arrow-down-to-line::after, .fa-duotone.fa-arrow-down-to-line::after {
  content: "\f33d\f33d"; }
.fad.fa-arrow-to-bottom::after, .fa-duotone.fa-arrow-to-bottom::after {
  content: "\f33d\f33d"; }
.fad.fa-grid-5::after, .fa-duotone.fa-grid-5::after {
  content: "\e199\e199"; }
.fad.fa-right-long-to-line::after, .fa-duotone.fa-right-long-to-line::after {
  content: "\e444\e444"; }
.fad.fa-person-chalkboard::after, .fa-duotone.fa-person-chalkboard::after {
  content: "\e53d\e53d"; }
.fad.fa-mars-stroke-right::after, .fa-duotone.fa-mars-stroke-right::after {
  content: "\f22b\f22b"; }
.fad.fa-mars-stroke-h::after, .fa-duotone.fa-mars-stroke-h::after {
  content: "\f22b\f22b"; }
.fad.fa-hand-back-fist::after, .fa-duotone.fa-hand-back-fist::after {
  content: "\f255\f255"; }
.fad.fa-hand-rock::after, .fa-duotone.fa-hand-rock::after {
  content: "\f255\f255"; }
.fad.fa-tally::after, .fa-duotone.fa-tally::after {
  content: "\f69c\f69c"; }
.fad.fa-tally-5::after, .fa-duotone.fa-tally-5::after {
  content: "\f69c\f69c"; }
.fad.fa-square-caret-up::after, .fa-duotone.fa-square-caret-up::after {
  content: "\f151\f151"; }
.fad.fa-caret-square-up::after, .fa-duotone.fa-caret-square-up::after {
  content: "\f151\f151"; }
.fad.fa-cloud-showers-water::after, .fa-duotone.fa-cloud-showers-water::after {
  content: "\e4e4\e4e4"; }
.fad.fa-chart-bar::after, .fa-duotone.fa-chart-bar::after {
  content: "\f080\f080"; }
.fad.fa-bar-chart::after, .fa-duotone.fa-bar-chart::after {
  content: "\f080\f080"; }
.fad.fa-hands-bubbles::after, .fa-duotone.fa-hands-bubbles::after {
  content: "\e05e\e05e"; }
.fad.fa-hands-wash::after, .fa-duotone.fa-hands-wash::after {
  content: "\e05e\e05e"; }
.fad.fa-less-than-equal::after, .fa-duotone.fa-less-than-equal::after {
  content: "\f537\f537"; }
.fad.fa-train::after, .fa-duotone.fa-train::after {
  content: "\f238\f238"; }
.fad.fa-up-from-dotted-line::after, .fa-duotone.fa-up-from-dotted-line::after {
  content: "\e456\e456"; }
.fad.fa-eye-low-vision::after, .fa-duotone.fa-eye-low-vision::after {
  content: "\f2a8\f2a8"; }
.fad.fa-low-vision::after, .fa-duotone.fa-low-vision::after {
  content: "\f2a8\f2a8"; }
.fad.fa-traffic-light-go::after, .fa-duotone.fa-traffic-light-go::after {
  content: "\f638\f638"; }
.fad.fa-face-exhaling::after, .fa-duotone.fa-face-exhaling::after {
  content: "\e480\e480"; }
.fad.fa-sensor-fire::after, .fa-duotone.fa-sensor-fire::after {
  content: "\e02a\e02a"; }
.fad.fa-user-unlock::after, .fa-duotone.fa-user-unlock::after {
  content: "\e058\e058"; }
.fad.fa-hexagon-divide::after, .fa-duotone.fa-hexagon-divide::after {
  content: "\e1ad\e1ad"; }
.fad.fa-00::after, .fa-duotone.fa-00::after {
  content: "\e467\e467"; }
.fad.fa-crow::after, .fa-duotone.fa-crow::after {
  content: "\f520\f520"; }
.fad.fa-cassette-betamax::after, .fa-duotone.fa-cassette-betamax::after {
  content: "\f8a4\f8a4"; }
.fad.fa-betamax::after, .fa-duotone.fa-betamax::after {
  content: "\f8a4\f8a4"; }
.fad.fa-sailboat::after, .fa-duotone.fa-sailboat::after {
  content: "\e445\e445"; }
.fad.fa-window-restore::after, .fa-duotone.fa-window-restore::after {
  content: "\f2d2\f2d2"; }
.fad.fa-nfc-magnifying-glass::after, .fa-duotone.fa-nfc-magnifying-glass::after {
  content: "\e1f9\e1f9"; }
.fad.fa-file-binary::after, .fa-duotone.fa-file-binary::after {
  content: "\e175\e175"; }
.fad.fa-circle-v::after, .fa-duotone.fa-circle-v::after {
  content: "\e12a\e12a"; }
.fad.fa-square-plus::after, .fa-duotone.fa-square-plus::after {
  content: "\f0fe\f0fe"; }
.fad.fa-plus-square::after, .fa-duotone.fa-plus-square::after {
  content: "\f0fe\f0fe"; }
.fad.fa-bowl-scoops::after, .fa-duotone.fa-bowl-scoops::after {
  content: "\e3df\e3df"; }
.fad.fa-mistletoe::after, .fa-duotone.fa-mistletoe::after {
  content: "\f7b4\f7b4"; }
.fad.fa-custard::after, .fa-duotone.fa-custard::after {
  content: "\e403\e403"; }
.fad.fa-lacrosse-stick::after, .fa-duotone.fa-lacrosse-stick::after {
  content: "\e3b5\e3b5"; }
.fad.fa-hockey-mask::after, .fa-duotone.fa-hockey-mask::after {
  content: "\f6ee\f6ee"; }
.fad.fa-sunrise::after, .fa-duotone.fa-sunrise::after {
  content: "\f766\f766"; }
.fad.fa-panel-ews::after, .fa-duotone.fa-panel-ews::after {
  content: "\e42e\e42e"; }
.fad.fa-torii-gate::after, .fa-duotone.fa-torii-gate::after {
  content: "\f6a1\f6a1"; }
.fad.fa-cloud-exclamation::after, .fa-duotone.fa-cloud-exclamation::after {
  content: "\e491\e491"; }
.fad.fa-message-lines::after, .fa-duotone.fa-message-lines::after {
  content: "\f4a6\f4a6"; }
.fad.fa-comment-alt-lines::after, .fa-duotone.fa-comment-alt-lines::after {
  content: "\f4a6\f4a6"; }
.fad.fa-frog::after, .fa-duotone.fa-frog::after {
  content: "\f52e\f52e"; }
.fad.fa-bucket::after, .fa-duotone.fa-bucket::after {
  content: "\e4cf\e4cf"; }
.fad.fa-floppy-disk-pen::after, .fa-duotone.fa-floppy-disk-pen::after {
  content: "\e182\e182"; }
.fad.fa-image::after, .fa-duotone.fa-image::after {
  content: "\f03e\f03e"; }
.fad.fa-window-frame::after, .fa-duotone.fa-window-frame::after {
  content: "\e04f\e04f"; }
.fad.fa-microphone::after, .fa-duotone.fa-microphone::after {
  content: "\f130\f130"; }
.fad.fa-cow::after, .fa-duotone.fa-cow::after {
  content: "\f6c8\f6c8"; }
.fad.fa-square-ring::after, .fa-duotone.fa-square-ring::after {
  content: "\e44f\e44f"; }
.fad.fa-down-from-line::after, .fa-duotone.fa-down-from-line::after {
  content: "\f349\f349"; }
.fad.fa-arrow-alt-from-top::after, .fa-duotone.fa-arrow-alt-from-top::after {
  content: "\f349\f349"; }
.fad.fa-caret-up::after, .fa-duotone.fa-caret-up::after {
  content: "\f0d8\f0d8"; }
.fad.fa-shield-xmark::after, .fa-duotone.fa-shield-xmark::after {
  content: "\e24c\e24c"; }
.fad.fa-shield-times::after, .fa-duotone.fa-shield-times::after {
  content: "\e24c\e24c"; }
.fad.fa-screwdriver::after, .fa-duotone.fa-screwdriver::after {
  content: "\f54a\f54a"; }
.fad.fa-circle-sort-down::after, .fa-duotone.fa-circle-sort-down::after {
  content: "\e031\e031"; }
.fad.fa-sort-circle-down::after, .fa-duotone.fa-sort-circle-down::after {
  content: "\e031\e031"; }
.fad.fa-folder-closed::after, .fa-duotone.fa-folder-closed::after {
  content: "\e185\e185"; }
.fad.fa-house-tsunami::after, .fa-duotone.fa-house-tsunami::after {
  content: "\e515\e515"; }
.fad.fa-square-nfi::after, .fa-duotone.fa-square-nfi::after {
  content: "\e576\e576"; }
.fad.fa-forklift::after, .fa-duotone.fa-forklift::after {
  content: "\f47a\f47a"; }
.fad.fa-arrow-up-from-ground-water::after, .fa-duotone.fa-arrow-up-from-ground-water::after {
  content: "\e4b5\e4b5"; }
.fad.fa-bracket-square-right::after, .fa-duotone.fa-bracket-square-right::after {
  content: "\5d\5d"; }
.fad.fa-martini-glass::after, .fa-duotone.fa-martini-glass::after {
  content: "\f57b\f57b"; }
.fad.fa-glass-martini-alt::after, .fa-duotone.fa-glass-martini-alt::after {
  content: "\f57b\f57b"; }
.fad.fa-rotate-left::after, .fa-duotone.fa-rotate-left::after {
  content: "\f2ea\f2ea"; }
.fad.fa-rotate-back::after, .fa-duotone.fa-rotate-back::after {
  content: "\f2ea\f2ea"; }
.fad.fa-rotate-backward::after, .fa-duotone.fa-rotate-backward::after {
  content: "\f2ea\f2ea"; }
.fad.fa-undo-alt::after, .fa-duotone.fa-undo-alt::after {
  content: "\f2ea\f2ea"; }
.fad.fa-table-columns::after, .fa-duotone.fa-table-columns::after {
  content: "\f0db\f0db"; }
.fad.fa-columns::after, .fa-duotone.fa-columns::after {
  content: "\f0db\f0db"; }
.fad.fa-square-a::after, .fa-duotone.fa-square-a::after {
  content: "\e25f\e25f"; }
.fad.fa-tick::after, .fa-duotone.fa-tick::after {
  content: "\e32f\e32f"; }
.fad.fa-lemon::after, .fa-duotone.fa-lemon::after {
  content: "\f094\f094"; }
.fad.fa-head-side-mask::after, .fa-duotone.fa-head-side-mask::after {
  content: "\e063\e063"; }
.fad.fa-handshake::after, .fa-duotone.fa-handshake::after {
  content: "\f2b5\f2b5"; }
.fad.fa-gem::after, .fa-duotone.fa-gem::after {
  content: "\f3a5\f3a5"; }
.fad.fa-dolly::after, .fa-duotone.fa-dolly::after {
  content: "\f472\f472"; }
.fad.fa-dolly-box::after, .fa-duotone.fa-dolly-box::after {
  content: "\f472\f472"; }
.fad.fa-smoking::after, .fa-duotone.fa-smoking::after {
  content: "\f48d\f48d"; }
.fad.fa-minimize::after, .fa-duotone.fa-minimize::after {
  content: "\f78c\f78c"; }
.fad.fa-compress-arrows-alt::after, .fa-duotone.fa-compress-arrows-alt::after {
  content: "\f78c\f78c"; }
.fad.fa-refrigerator::after, .fa-duotone.fa-refrigerator::after {
  content: "\e026\e026"; }
.fad.fa-monument::after, .fa-duotone.fa-monument::after {
  content: "\f5a6\f5a6"; }
.fad.fa-octagon-xmark::after, .fa-duotone.fa-octagon-xmark::after {
  content: "\f2f0\f2f0"; }
.fad.fa-times-octagon::after, .fa-duotone.fa-times-octagon::after {
  content: "\f2f0\f2f0"; }
.fad.fa-xmark-octagon::after, .fa-duotone.fa-xmark-octagon::after {
  content: "\f2f0\f2f0"; }
.fad.fa-align-slash::after, .fa-duotone.fa-align-slash::after {
  content: "\f846\f846"; }
.fad.fa-snowplow::after, .fa-duotone.fa-snowplow::after {
  content: "\f7d2\f7d2"; }
.fad.fa-angles-right::after, .fa-duotone.fa-angles-right::after {
  content: "\f101\f101"; }
.fad.fa-angle-double-right::after, .fa-duotone.fa-angle-double-right::after {
  content: "\f101\f101"; }
.fad.fa-truck-ramp-couch::after, .fa-duotone.fa-truck-ramp-couch::after {
  content: "\f4dd\f4dd"; }
.fad.fa-truck-couch::after, .fa-duotone.fa-truck-couch::after {
  content: "\f4dd\f4dd"; }
.fad.fa-cannabis::after, .fa-duotone.fa-cannabis::after {
  content: "\f55f\f55f"; }
.fad.fa-circle-play::after, .fa-duotone.fa-circle-play::after {
  content: "\f144\f144"; }
.fad.fa-play-circle::after, .fa-duotone.fa-play-circle::after {
  content: "\f144\f144"; }
.fad.fa-arrow-up-right-and-arrow-down-left-from-center::after, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center::after {
  content: "\e0a0\e0a0"; }
.fad.fa-tablets::after, .fa-duotone.fa-tablets::after {
  content: "\f490\f490"; }
.fad.fa-360-degrees::after, .fa-duotone.fa-360-degrees::after {
  content: "\e2dc\e2dc"; }
.fad.fa-ethernet::after, .fa-duotone.fa-ethernet::after {
  content: "\f796\f796"; }
.fad.fa-euro-sign::after, .fa-duotone.fa-euro-sign::after {
  content: "\f153\f153"; }
.fad.fa-eur::after, .fa-duotone.fa-eur::after {
  content: "\f153\f153"; }
.fad.fa-euro::after, .fa-duotone.fa-euro::after {
  content: "\f153\f153"; }
.fad.fa-chair::after, .fa-duotone.fa-chair::after {
  content: "\f6c0\f6c0"; }
.fad.fa-circle-check::after, .fa-duotone.fa-circle-check::after {
  content: "\f058\f058"; }
.fad.fa-check-circle::after, .fa-duotone.fa-check-circle::after {
  content: "\f058\f058"; }
.fad.fa-square-dashed-circle-plus::after, .fa-duotone.fa-square-dashed-circle-plus::after {
  content: "\e5c2\e5c2"; }
.fad.fa-money-simple-from-bracket::after, .fa-duotone.fa-money-simple-from-bracket::after {
  content: "\e313\e313"; }
.fad.fa-bat::after, .fa-duotone.fa-bat::after {
  content: "\f6b5\f6b5"; }
.fad.fa-circle-stop::after, .fa-duotone.fa-circle-stop::after {
  content: "\f28d\f28d"; }
.fad.fa-stop-circle::after, .fa-duotone.fa-stop-circle::after {
  content: "\f28d\f28d"; }
.fad.fa-head-side-headphones::after, .fa-duotone.fa-head-side-headphones::after {
  content: "\f8c2\f8c2"; }
.fad.fa-phone-rotary::after, .fa-duotone.fa-phone-rotary::after {
  content: "\f8d3\f8d3"; }
.fad.fa-compass-drafting::after, .fa-duotone.fa-compass-drafting::after {
  content: "\f568\f568"; }
.fad.fa-drafting-compass::after, .fa-duotone.fa-drafting-compass::after {
  content: "\f568\f568"; }
.fad.fa-plate-wheat::after, .fa-duotone.fa-plate-wheat::after {
  content: "\e55a\e55a"; }
.fad.fa-calendar-circle-minus::after, .fa-duotone.fa-calendar-circle-minus::after {
  content: "\e46f\e46f"; }
.fad.fa-chopsticks::after, .fa-duotone.fa-chopsticks::after {
  content: "\e3f7\e3f7"; }
.fad.fa-car-wrench::after, .fa-duotone.fa-car-wrench::after {
  content: "\f5e3\f5e3"; }
.fad.fa-car-mechanic::after, .fa-duotone.fa-car-mechanic::after {
  content: "\f5e3\f5e3"; }
.fad.fa-icicles::after, .fa-duotone.fa-icicles::after {
  content: "\f7ad\f7ad"; }
.fad.fa-person-shelter::after, .fa-duotone.fa-person-shelter::after {
  content: "\e54f\e54f"; }
.fad.fa-neuter::after, .fa-duotone.fa-neuter::after {
  content: "\f22c\f22c"; }
.fad.fa-id-badge::after, .fa-duotone.fa-id-badge::after {
  content: "\f2c1\f2c1"; }
.fad.fa-kazoo::after, .fa-duotone.fa-kazoo::after {
  content: "\f8c7\f8c7"; }
.fad.fa-marker::after, .fa-duotone.fa-marker::after {
  content: "\f5a1\f5a1"; }
.fad.fa-face-laugh-beam::after, .fa-duotone.fa-face-laugh-beam::after {
  content: "\f59a\f59a"; }
.fad.fa-laugh-beam::after, .fa-duotone.fa-laugh-beam::after {
  content: "\f59a\f59a"; }
.fad.fa-square-arrow-down-left::after, .fa-duotone.fa-square-arrow-down-left::after {
  content: "\e261\e261"; }
.fad.fa-battery-bolt::after, .fa-duotone.fa-battery-bolt::after {
  content: "\f376\f376"; }
.fad.fa-tree-large::after, .fa-duotone.fa-tree-large::after {
  content: "\f7dd\f7dd"; }
.fad.fa-helicopter-symbol::after, .fa-duotone.fa-helicopter-symbol::after {
  content: "\e502\e502"; }
.fad.fa-aperture::after, .fa-duotone.fa-aperture::after {
  content: "\e2df\e2df"; }
.fad.fa-universal-access::after, .fa-duotone.fa-universal-access::after {
  content: "\f29a\f29a"; }
.fad.fa-file-magnifying-glass::after, .fa-duotone.fa-file-magnifying-glass::after {
  content: "\f865\f865"; }
.fad.fa-file-search::after, .fa-duotone.fa-file-search::after {
  content: "\f865\f865"; }
.fad.fa-up-right::after, .fa-duotone.fa-up-right::after {
  content: "\e2be\e2be"; }
.fad.fa-circle-chevron-up::after, .fa-duotone.fa-circle-chevron-up::after {
  content: "\f139\f139"; }
.fad.fa-chevron-circle-up::after, .fa-duotone.fa-chevron-circle-up::after {
  content: "\f139\f139"; }
.fad.fa-user-police::after, .fa-duotone.fa-user-police::after {
  content: "\e333\e333"; }
.fad.fa-lari-sign::after, .fa-duotone.fa-lari-sign::after {
  content: "\e1c8\e1c8"; }
.fad.fa-volcano::after, .fa-duotone.fa-volcano::after {
  content: "\f770\f770"; }
.fad.fa-teddy-bear::after, .fa-duotone.fa-teddy-bear::after {
  content: "\e3cf\e3cf"; }
.fad.fa-stocking::after, .fa-duotone.fa-stocking::after {
  content: "\f7d5\f7d5"; }
.fad.fa-person-walking-dashed-line-arrow-right::after, .fa-duotone.fa-person-walking-dashed-line-arrow-right::after {
  content: "\e553\e553"; }
.fad.fa-image-slash::after, .fa-duotone.fa-image-slash::after {
  content: "\e1b7\e1b7"; }
.fad.fa-mask-snorkel::after, .fa-duotone.fa-mask-snorkel::after {
  content: "\e3b7\e3b7"; }
.fad.fa-smoke::after, .fa-duotone.fa-smoke::after {
  content: "\f760\f760"; }
.fad.fa-sterling-sign::after, .fa-duotone.fa-sterling-sign::after {
  content: "\f154\f154"; }
.fad.fa-gbp::after, .fa-duotone.fa-gbp::after {
  content: "\f154\f154"; }
.fad.fa-pound-sign::after, .fa-duotone.fa-pound-sign::after {
  content: "\f154\f154"; }
.fad.fa-battery-exclamation::after, .fa-duotone.fa-battery-exclamation::after {
  content: "\e0b0\e0b0"; }
.fad.fa-viruses::after, .fa-duotone.fa-viruses::after {
  content: "\e076\e076"; }
.fad.fa-square-person-confined::after, .fa-duotone.fa-square-person-confined::after {
  content: "\e577\e577"; }
.fad.fa-user-tie::after, .fa-duotone.fa-user-tie::after {
  content: "\f508\f508"; }
.fad.fa-arrow-down-long::after, .fa-duotone.fa-arrow-down-long::after {
  content: "\f175\f175"; }
.fad.fa-long-arrow-down::after, .fa-duotone.fa-long-arrow-down::after {
  content: "\f175\f175"; }
.fad.fa-tent-arrow-down-to-line::after, .fa-duotone.fa-tent-arrow-down-to-line::after {
  content: "\e57e\e57e"; }
.fad.fa-certificate::after, .fa-duotone.fa-certificate::after {
  content: "\f0a3\f0a3"; }
.fad.fa-crystal-ball::after, .fa-duotone.fa-crystal-ball::after {
  content: "\e362\e362"; }
.fad.fa-reply-all::after, .fa-duotone.fa-reply-all::after {
  content: "\f122\f122"; }
.fad.fa-mail-reply-all::after, .fa-duotone.fa-mail-reply-all::after {
  content: "\f122\f122"; }
.fad.fa-suitcase::after, .fa-duotone.fa-suitcase::after {
  content: "\f0f2\f0f2"; }
.fad.fa-person-skating::after, .fa-duotone.fa-person-skating::after {
  content: "\f7c5\f7c5"; }
.fad.fa-skating::after, .fa-duotone.fa-skating::after {
  content: "\f7c5\f7c5"; }
.fad.fa-star-shooting::after, .fa-duotone.fa-star-shooting::after {
  content: "\e036\e036"; }
.fad.fa-binary-lock::after, .fa-duotone.fa-binary-lock::after {
  content: "\e33d\e33d"; }
.fad.fa-filter-circle-dollar::after, .fa-duotone.fa-filter-circle-dollar::after {
  content: "\f662\f662"; }
.fad.fa-funnel-dollar::after, .fa-duotone.fa-funnel-dollar::after {
  content: "\f662\f662"; }
.fad.fa-camera-retro::after, .fa-duotone.fa-camera-retro::after {
  content: "\f083\f083"; }
.fad.fa-circle-arrow-down::after, .fa-duotone.fa-circle-arrow-down::after {
  content: "\f0ab\f0ab"; }
.fad.fa-arrow-circle-down::after, .fa-duotone.fa-arrow-circle-down::after {
  content: "\f0ab\f0ab"; }
.fad.fa-comment-pen::after, .fa-duotone.fa-comment-pen::after {
  content: "\f4ae\f4ae"; }
.fad.fa-comment-edit::after, .fa-duotone.fa-comment-edit::after {
  content: "\f4ae\f4ae"; }
.fad.fa-file-import::after, .fa-duotone.fa-file-import::after {
  content: "\f56f\f56f"; }
.fad.fa-arrow-right-to-file::after, .fa-duotone.fa-arrow-right-to-file::after {
  content: "\f56f\f56f"; }
.fad.fa-banjo::after, .fa-duotone.fa-banjo::after {
  content: "\f8a3\f8a3"; }
.fad.fa-square-arrow-up-right::after, .fa-duotone.fa-square-arrow-up-right::after {
  content: "\f14c\f14c"; }
.fad.fa-external-link-square::after, .fa-duotone.fa-external-link-square::after {
  content: "\f14c\f14c"; }
.fad.fa-light-emergency-on::after, .fa-duotone.fa-light-emergency-on::after {
  content: "\e420\e420"; }
.fad.fa-kerning::after, .fa-duotone.fa-kerning::after {
  content: "\f86f\f86f"; }
.fad.fa-box-open::after, .fa-duotone.fa-box-open::after {
  content: "\f49e\f49e"; }
.fad.fa-square-f::after, .fa-duotone.fa-square-f::after {
  content: "\e270\e270"; }
.fad.fa-scroll::after, .fa-duotone.fa-scroll::after {
  content: "\f70e\f70e"; }
.fad.fa-spa::after, .fa-duotone.fa-spa::after {
  content: "\f5bb\f5bb"; }
.fad.fa-arrow-left-from-line::after, .fa-duotone.fa-arrow-left-from-line::after {
  content: "\f344\f344"; }
.fad.fa-arrow-from-right::after, .fa-duotone.fa-arrow-from-right::after {
  content: "\f344\f344"; }
.fad.fa-strawberry::after, .fa-duotone.fa-strawberry::after {
  content: "\e32b\e32b"; }
.fad.fa-location-pin-lock::after, .fa-duotone.fa-location-pin-lock::after {
  content: "\e51f\e51f"; }
.fad.fa-pause::after, .fa-duotone.fa-pause::after {
  content: "\f04c\f04c"; }
.fad.fa-clock-eight-thirty::after, .fa-duotone.fa-clock-eight-thirty::after {
  content: "\e346\e346"; }
.fad.fa-plane-engines::after, .fa-duotone.fa-plane-engines::after {
  content: "\f3de\f3de"; }
.fad.fa-plane-alt::after, .fa-duotone.fa-plane-alt::after {
  content: "\f3de\f3de"; }
.fad.fa-hill-avalanche::after, .fa-duotone.fa-hill-avalanche::after {
  content: "\e507\e507"; }
.fad.fa-temperature-empty::after, .fa-duotone.fa-temperature-empty::after {
  content: "\f2cb\f2cb"; }
.fad.fa-temperature-0::after, .fa-duotone.fa-temperature-0::after {
  content: "\f2cb\f2cb"; }
.fad.fa-thermometer-0::after, .fa-duotone.fa-thermometer-0::after {
  content: "\f2cb\f2cb"; }
.fad.fa-thermometer-empty::after, .fa-duotone.fa-thermometer-empty::after {
  content: "\f2cb\f2cb"; }
.fad.fa-bomb::after, .fa-duotone.fa-bomb::after {
  content: "\f1e2\f1e2"; }
.fad.fa-gauge-low::after, .fa-duotone.fa-gauge-low::after {
  content: "\f627\f627"; }
.fad.fa-tachometer-alt-slow::after, .fa-duotone.fa-tachometer-alt-slow::after {
  content: "\f627\f627"; }
.fad.fa-registered::after, .fa-duotone.fa-registered::after {
  content: "\f25d\f25d"; }
.fad.fa-trash-can-plus::after, .fa-duotone.fa-trash-can-plus::after {
  content: "\e2ac\e2ac"; }
.fad.fa-address-card::after, .fa-duotone.fa-address-card::after {
  content: "\f2bb\f2bb"; }
.fad.fa-contact-card::after, .fa-duotone.fa-contact-card::after {
  content: "\f2bb\f2bb"; }
.fad.fa-vcard::after, .fa-duotone.fa-vcard::after {
  content: "\f2bb\f2bb"; }
.fad.fa-scale-unbalanced-flip::after, .fa-duotone.fa-scale-unbalanced-flip::after {
  content: "\f516\f516"; }
.fad.fa-balance-scale-right::after, .fa-duotone.fa-balance-scale-right::after {
  content: "\f516\f516"; }
.fad.fa-globe-snow::after, .fa-duotone.fa-globe-snow::after {
  content: "\f7a3\f7a3"; }
.fad.fa-subscript::after, .fa-duotone.fa-subscript::after {
  content: "\f12c\f12c"; }
.fad.fa-diamond-turn-right::after, .fa-duotone.fa-diamond-turn-right::after {
  content: "\f5eb\f5eb"; }
.fad.fa-directions::after, .fa-duotone.fa-directions::after {
  content: "\f5eb\f5eb"; }
.fad.fa-integral::after, .fa-duotone.fa-integral::after {
  content: "\f667\f667"; }
.fad.fa-burst::after, .fa-duotone.fa-burst::after {
  content: "\e4dc\e4dc"; }
.fad.fa-house-laptop::after, .fa-duotone.fa-house-laptop::after {
  content: "\e066\e066"; }
.fad.fa-laptop-house::after, .fa-duotone.fa-laptop-house::after {
  content: "\e066\e066"; }
.fad.fa-face-tired::after, .fa-duotone.fa-face-tired::after {
  content: "\f5c8\f5c8"; }
.fad.fa-tired::after, .fa-duotone.fa-tired::after {
  content: "\f5c8\f5c8"; }
.fad.fa-money-bills::after, .fa-duotone.fa-money-bills::after {
  content: "\e1f3\e1f3"; }
.fad.fa-blinds-raised::after, .fa-duotone.fa-blinds-raised::after {
  content: "\f8fd\f8fd"; }
.fad.fa-smog::after, .fa-duotone.fa-smog::after {
  content: "\f75f\f75f"; }
.fad.fa-ufo-beam::after, .fa-duotone.fa-ufo-beam::after {
  content: "\e048\e048"; }
.fad.fa-circle-caret-up::after, .fa-duotone.fa-circle-caret-up::after {
  content: "\f331\f331"; }
.fad.fa-caret-circle-up::after, .fa-duotone.fa-caret-circle-up::after {
  content: "\f331\f331"; }
.fad.fa-user-vneck-hair-long::after, .fa-duotone.fa-user-vneck-hair-long::after {
  content: "\e463\e463"; }
.fad.fa-square-a-lock::after, .fa-duotone.fa-square-a-lock::after {
  content: "\e44d\e44d"; }
.fad.fa-crutch::after, .fa-duotone.fa-crutch::after {
  content: "\f7f7\f7f7"; }
.fad.fa-gas-pump-slash::after, .fa-duotone.fa-gas-pump-slash::after {
  content: "\f5f4\f5f4"; }
.fad.fa-cloud-arrow-up::after, .fa-duotone.fa-cloud-arrow-up::after {
  content: "\f0ee\f0ee"; }
.fad.fa-cloud-upload::after, .fa-duotone.fa-cloud-upload::after {
  content: "\f0ee\f0ee"; }
.fad.fa-cloud-upload-alt::after, .fa-duotone.fa-cloud-upload-alt::after {
  content: "\f0ee\f0ee"; }
.fad.fa-palette::after, .fa-duotone.fa-palette::after {
  content: "\f53f\f53f"; }
.fad.fa-transporter-4::after, .fa-duotone.fa-transporter-4::after {
  content: "\e2a5\e2a5"; }
.fad.fa-objects-align-right::after, .fa-duotone.fa-objects-align-right::after {
  content: "\e3bf\e3bf"; }
.fad.fa-arrows-turn-right::after, .fa-duotone.fa-arrows-turn-right::after {
  content: "\e4c0\e4c0"; }
.fad.fa-vest::after, .fa-duotone.fa-vest::after {
  content: "\e085\e085"; }
.fad.fa-pig::after, .fa-duotone.fa-pig::after {
  content: "\f706\f706"; }
.fad.fa-inbox-full::after, .fa-duotone.fa-inbox-full::after {
  content: "\e1ba\e1ba"; }
.fad.fa-circle-envelope::after, .fa-duotone.fa-circle-envelope::after {
  content: "\e10c\e10c"; }
.fad.fa-envelope-circle::after, .fa-duotone.fa-envelope-circle::after {
  content: "\e10c\e10c"; }
.fad.fa-triangle-person-digging::after, .fa-duotone.fa-triangle-person-digging::after {
  content: "\f85d\f85d"; }
.fad.fa-construction::after, .fa-duotone.fa-construction::after {
  content: "\f85d\f85d"; }
.fad.fa-ferry::after, .fa-duotone.fa-ferry::after {
  content: "\e4ea\e4ea"; }
.fad.fa-bullseye-arrow::after, .fa-duotone.fa-bullseye-arrow::after {
  content: "\f648\f648"; }
.fad.fa-arrows-down-to-people::after, .fa-duotone.fa-arrows-down-to-people::after {
  content: "\e4b9\e4b9"; }
.fad.fa-seedling::after, .fa-duotone.fa-seedling::after {
  content: "\f4d8\f4d8"; }
.fad.fa-sprout::after, .fa-duotone.fa-sprout::after {
  content: "\f4d8\f4d8"; }
.fad.fa-clock-seven::after, .fa-duotone.fa-clock-seven::after {
  content: "\e350\e350"; }
.fad.fa-left-right::after, .fa-duotone.fa-left-right::after {
  content: "\f337\f337"; }
.fad.fa-arrows-alt-h::after, .fa-duotone.fa-arrows-alt-h::after {
  content: "\f337\f337"; }
.fad.fa-boxes-packing::after, .fa-duotone.fa-boxes-packing::after {
  content: "\e4c7\e4c7"; }
.fad.fa-circle-arrow-left::after, .fa-duotone.fa-circle-arrow-left::after {
  content: "\f0a8\f0a8"; }
.fad.fa-arrow-circle-left::after, .fa-duotone.fa-arrow-circle-left::after {
  content: "\f0a8\f0a8"; }
.fad.fa-flashlight::after, .fa-duotone.fa-flashlight::after {
  content: "\f8b8\f8b8"; }
.fad.fa-group-arrows-rotate::after, .fa-duotone.fa-group-arrows-rotate::after {
  content: "\e4f6\e4f6"; }
.fad.fa-bowl-food::after, .fa-duotone.fa-bowl-food::after {
  content: "\e4c6\e4c6"; }
.fad.fa-square-9::after, .fa-duotone.fa-square-9::after {
  content: "\e25e\e25e"; }
.fad.fa-candy-cane::after, .fa-duotone.fa-candy-cane::after {
  content: "\f786\f786"; }
.fad.fa-arrow-down-wide-short::after, .fa-duotone.fa-arrow-down-wide-short::after {
  content: "\f160\f160"; }
.fad.fa-sort-amount-asc::after, .fa-duotone.fa-sort-amount-asc::after {
  content: "\f160\f160"; }
.fad.fa-sort-amount-down::after, .fa-duotone.fa-sort-amount-down::after {
  content: "\f160\f160"; }
.fad.fa-square-dollar::after, .fa-duotone.fa-square-dollar::after {
  content: "\f2e9\f2e9"; }
.fad.fa-dollar-square::after, .fa-duotone.fa-dollar-square::after {
  content: "\f2e9\f2e9"; }
.fad.fa-usd-square::after, .fa-duotone.fa-usd-square::after {
  content: "\f2e9\f2e9"; }
.fad.fa-phone-arrow-right::after, .fa-duotone.fa-phone-arrow-right::after {
  content: "\e5be\e5be"; }
.fad.fa-hand-holding-seedling::after, .fa-duotone.fa-hand-holding-seedling::after {
  content: "\f4bf\f4bf"; }
.fad.fa-message-check::after, .fa-duotone.fa-message-check::after {
  content: "\f4a2\f4a2"; }
.fad.fa-comment-alt-check::after, .fa-duotone.fa-comment-alt-check::after {
  content: "\f4a2\f4a2"; }
.fad.fa-cloud-bolt::after, .fa-duotone.fa-cloud-bolt::after {
  content: "\f76c\f76c"; }
.fad.fa-thunderstorm::after, .fa-duotone.fa-thunderstorm::after {
  content: "\f76c\f76c"; }
.fad.fa-text-slash::after, .fa-duotone.fa-text-slash::after {
  content: "\f87d\f87d"; }
.fad.fa-remove-format::after, .fa-duotone.fa-remove-format::after {
  content: "\f87d\f87d"; }
.fad.fa-watch::after, .fa-duotone.fa-watch::after {
  content: "\f2e1\f2e1"; }
.fad.fa-circle-down-left::after, .fa-duotone.fa-circle-down-left::after {
  content: "\e107\e107"; }
.fad.fa-text::after, .fa-duotone.fa-text::after {
  content: "\f893\f893"; }
.fad.fa-projector::after, .fa-duotone.fa-projector::after {
  content: "\f8d6\f8d6"; }
.fad.fa-face-smile-wink::after, .fa-duotone.fa-face-smile-wink::after {
  content: "\f4da\f4da"; }
.fad.fa-smile-wink::after, .fa-duotone.fa-smile-wink::after {
  content: "\f4da\f4da"; }
.fad.fa-tombstone-blank::after, .fa-duotone.fa-tombstone-blank::after {
  content: "\f721\f721"; }
.fad.fa-tombstone-alt::after, .fa-duotone.fa-tombstone-alt::after {
  content: "\f721\f721"; }
.fad.fa-chess-king-piece::after, .fa-duotone.fa-chess-king-piece::after {
  content: "\f440\f440"; }
.fad.fa-chess-king-alt::after, .fa-duotone.fa-chess-king-alt::after {
  content: "\f440\f440"; }
.fad.fa-circle-6::after, .fa-duotone.fa-circle-6::after {
  content: "\e0f3\e0f3"; }
.fad.fa-left::after, .fa-duotone.fa-left::after {
  content: "\f355\f355"; }
.fad.fa-arrow-alt-left::after, .fa-duotone.fa-arrow-alt-left::after {
  content: "\f355\f355"; }
.fad.fa-file-word::after, .fa-duotone.fa-file-word::after {
  content: "\f1c2\f1c2"; }
.fad.fa-file-powerpoint::after, .fa-duotone.fa-file-powerpoint::after {
  content: "\f1c4\f1c4"; }
.fad.fa-square-down::after, .fa-duotone.fa-square-down::after {
  content: "\f350\f350"; }
.fad.fa-arrow-alt-square-down::after, .fa-duotone.fa-arrow-alt-square-down::after {
  content: "\f350\f350"; }
.fad.fa-objects-align-center-vertical::after, .fa-duotone.fa-objects-align-center-vertical::after {
  content: "\e3bd\e3bd"; }
.fad.fa-arrows-left-right::after, .fa-duotone.fa-arrows-left-right::after {
  content: "\f07e\f07e"; }
.fad.fa-arrows-h::after, .fa-duotone.fa-arrows-h::after {
  content: "\f07e\f07e"; }
.fad.fa-house-lock::after, .fa-duotone.fa-house-lock::after {
  content: "\e510\e510"; }
.fad.fa-cloud-arrow-down::after, .fa-duotone.fa-cloud-arrow-down::after {
  content: "\f0ed\f0ed"; }
.fad.fa-cloud-download::after, .fa-duotone.fa-cloud-download::after {
  content: "\f0ed\f0ed"; }
.fad.fa-cloud-download-alt::after, .fa-duotone.fa-cloud-download-alt::after {
  content: "\f0ed\f0ed"; }
.fad.fa-wreath::after, .fa-duotone.fa-wreath::after {
  content: "\f7e2\f7e2"; }
.fad.fa-children::after, .fa-duotone.fa-children::after {
  content: "\e4e1\e4e1"; }
.fad.fa-meter-droplet::after, .fa-duotone.fa-meter-droplet::after {
  content: "\e1ea\e1ea"; }
.fad.fa-chalkboard::after, .fa-duotone.fa-chalkboard::after {
  content: "\f51b\f51b"; }
.fad.fa-blackboard::after, .fa-duotone.fa-blackboard::after {
  content: "\f51b\f51b"; }
.fad.fa-user-large-slash::after, .fa-duotone.fa-user-large-slash::after {
  content: "\f4fa\f4fa"; }
.fad.fa-user-alt-slash::after, .fa-duotone.fa-user-alt-slash::after {
  content: "\f4fa\f4fa"; }
.fad.fa-signal-strong::after, .fa-duotone.fa-signal-strong::after {
  content: "\f68f\f68f"; }
.fad.fa-signal-4::after, .fa-duotone.fa-signal-4::after {
  content: "\f68f\f68f"; }
.fad.fa-lollipop::after, .fa-duotone.fa-lollipop::after {
  content: "\e424\e424"; }
.fad.fa-lollypop::after, .fa-duotone.fa-lollypop::after {
  content: "\e424\e424"; }
.fad.fa-list-tree::after, .fa-duotone.fa-list-tree::after {
  content: "\e1d2\e1d2"; }
.fad.fa-envelope-open::after, .fa-duotone.fa-envelope-open::after {
  content: "\f2b6\f2b6"; }
.fad.fa-draw-circle::after, .fa-duotone.fa-draw-circle::after {
  content: "\f5ed\f5ed"; }
.fad.fa-cat-space::after, .fa-duotone.fa-cat-space::after {
  content: "\e001\e001"; }
.fad.fa-handshake-simple-slash::after, .fa-duotone.fa-handshake-simple-slash::after {
  content: "\e05f\e05f"; }
.fad.fa-handshake-alt-slash::after, .fa-duotone.fa-handshake-alt-slash::after {
  content: "\e05f\e05f"; }
.fad.fa-rabbit-running::after, .fa-duotone.fa-rabbit-running::after {
  content: "\f709\f709"; }
.fad.fa-rabbit-fast::after, .fa-duotone.fa-rabbit-fast::after {
  content: "\f709\f709"; }
.fad.fa-memo-pad::after, .fa-duotone.fa-memo-pad::after {
  content: "\e1da\e1da"; }
.fad.fa-mattress-pillow::after, .fa-duotone.fa-mattress-pillow::after {
  content: "\e525\e525"; }
.fad.fa-alarm-plus::after, .fa-duotone.fa-alarm-plus::after {
  content: "\f844\f844"; }
.fad.fa-alicorn::after, .fa-duotone.fa-alicorn::after {
  content: "\f6b0\f6b0"; }
.fad.fa-comment-question::after, .fa-duotone.fa-comment-question::after {
  content: "\e14b\e14b"; }
.fad.fa-gingerbread-man::after, .fa-duotone.fa-gingerbread-man::after {
  content: "\f79d\f79d"; }
.fad.fa-guarani-sign::after, .fa-duotone.fa-guarani-sign::after {
  content: "\e19a\e19a"; }
.fad.fa-burger-fries::after, .fa-duotone.fa-burger-fries::after {
  content: "\e0cd\e0cd"; }
.fad.fa-mug-tea::after, .fa-duotone.fa-mug-tea::after {
  content: "\f875\f875"; }
.fad.fa-border-top::after, .fa-duotone.fa-border-top::after {
  content: "\f855\f855"; }
.fad.fa-arrows-rotate::after, .fa-duotone.fa-arrows-rotate::after {
  content: "\f021\f021"; }
.fad.fa-refresh::after, .fa-duotone.fa-refresh::after {
  content: "\f021\f021"; }
.fad.fa-sync::after, .fa-duotone.fa-sync::after {
  content: "\f021\f021"; }
.fad.fa-circle-book-open::after, .fa-duotone.fa-circle-book-open::after {
  content: "\e0ff\e0ff"; }
.fad.fa-book-circle::after, .fa-duotone.fa-book-circle::after {
  content: "\e0ff\e0ff"; }
.fad.fa-arrows-to-dotted-line::after, .fa-duotone.fa-arrows-to-dotted-line::after {
  content: "\e0a6\e0a6"; }
.fad.fa-fire-extinguisher::after, .fa-duotone.fa-fire-extinguisher::after {
  content: "\f134\f134"; }
.fad.fa-garage-open::after, .fa-duotone.fa-garage-open::after {
  content: "\e00b\e00b"; }
.fad.fa-shelves-empty::after, .fa-duotone.fa-shelves-empty::after {
  content: "\e246\e246"; }
.fad.fa-cruzeiro-sign::after, .fa-duotone.fa-cruzeiro-sign::after {
  content: "\e152\e152"; }
.fad.fa-watch-apple::after, .fa-duotone.fa-watch-apple::after {
  content: "\e2cb\e2cb"; }
.fad.fa-watch-calculator::after, .fa-duotone.fa-watch-calculator::after {
  content: "\f8f0\f8f0"; }
.fad.fa-list-dropdown::after, .fa-duotone.fa-list-dropdown::after {
  content: "\e1cf\e1cf"; }
.fad.fa-cabinet-filing::after, .fa-duotone.fa-cabinet-filing::after {
  content: "\f64b\f64b"; }
.fad.fa-burger-soda::after, .fa-duotone.fa-burger-soda::after {
  content: "\f858\f858"; }
.fad.fa-square-arrow-up::after, .fa-duotone.fa-square-arrow-up::after {
  content: "\f33c\f33c"; }
.fad.fa-arrow-square-up::after, .fa-duotone.fa-arrow-square-up::after {
  content: "\f33c\f33c"; }
.fad.fa-greater-than-equal::after, .fa-duotone.fa-greater-than-equal::after {
  content: "\f532\f532"; }
.fad.fa-pallet-box::after, .fa-duotone.fa-pallet-box::after {
  content: "\e208\e208"; }
.fad.fa-face-confounded::after, .fa-duotone.fa-face-confounded::after {
  content: "\e36c\e36c"; }
.fad.fa-shield-halved::after, .fa-duotone.fa-shield-halved::after {
  content: "\f3ed\f3ed"; }
.fad.fa-shield-alt::after, .fa-duotone.fa-shield-alt::after {
  content: "\f3ed\f3ed"; }
.fad.fa-truck-plow::after, .fa-duotone.fa-truck-plow::after {
  content: "\f7de\f7de"; }
.fad.fa-book-atlas::after, .fa-duotone.fa-book-atlas::after {
  content: "\f558\f558"; }
.fad.fa-atlas::after, .fa-duotone.fa-atlas::after {
  content: "\f558\f558"; }
.fad.fa-virus::after, .fa-duotone.fa-virus::after {
  content: "\e074\e074"; }
.fad.fa-comment-middle-top::after, .fa-duotone.fa-comment-middle-top::after {
  content: "\e14a\e14a"; }
.fad.fa-envelope-circle-check::after, .fa-duotone.fa-envelope-circle-check::after {
  content: "\e4e8\e4e8"; }
.fad.fa-layer-group::after, .fa-duotone.fa-layer-group::after {
  content: "\f5fd\f5fd"; }
.fad.fa-restroom-simple::after, .fa-duotone.fa-restroom-simple::after {
  content: "\e23a\e23a"; }
.fad.fa-arrows-to-dot::after, .fa-duotone.fa-arrows-to-dot::after {
  content: "\e4be\e4be"; }
.fad.fa-border-outer::after, .fa-duotone.fa-border-outer::after {
  content: "\f851\f851"; }
.fad.fa-hashtag-lock::after, .fa-duotone.fa-hashtag-lock::after {
  content: "\e415\e415"; }
.fad.fa-clock-two-thirty::after, .fa-duotone.fa-clock-two-thirty::after {
  content: "\e35b\e35b"; }
.fad.fa-archway::after, .fa-duotone.fa-archway::after {
  content: "\f557\f557"; }
.fad.fa-heart-circle-check::after, .fa-duotone.fa-heart-circle-check::after {
  content: "\e4fd\e4fd"; }
.fad.fa-house-chimney-crack::after, .fa-duotone.fa-house-chimney-crack::after {
  content: "\f6f1\f6f1"; }
.fad.fa-house-damage::after, .fa-duotone.fa-house-damage::after {
  content: "\f6f1\f6f1"; }
.fad.fa-file-zipper::after, .fa-duotone.fa-file-zipper::after {
  content: "\f1c6\f1c6"; }
.fad.fa-file-archive::after, .fa-duotone.fa-file-archive::after {
  content: "\f1c6\f1c6"; }
.fad.fa-heart-half::after, .fa-duotone.fa-heart-half::after {
  content: "\e1ab\e1ab"; }
.fad.fa-comment-check::after, .fa-duotone.fa-comment-check::after {
  content: "\f4ac\f4ac"; }
.fad.fa-square::after, .fa-duotone.fa-square::after {
  content: "\f0c8\f0c8"; }
.fad.fa-memo::after, .fa-duotone.fa-memo::after {
  content: "\e1d8\e1d8"; }
.fad.fa-martini-glass-empty::after, .fa-duotone.fa-martini-glass-empty::after {
  content: "\f000\f000"; }
.fad.fa-glass-martini::after, .fa-duotone.fa-glass-martini::after {
  content: "\f000\f000"; }
.fad.fa-couch::after, .fa-duotone.fa-couch::after {
  content: "\f4b8\f4b8"; }
.fad.fa-cedi-sign::after, .fa-duotone.fa-cedi-sign::after {
  content: "\e0df\e0df"; }
.fad.fa-italic::after, .fa-duotone.fa-italic::after {
  content: "\f033\f033"; }
.fad.fa-glass-citrus::after, .fa-duotone.fa-glass-citrus::after {
  content: "\f869\f869"; }
.fad.fa-calendar-lines-pen::after, .fa-duotone.fa-calendar-lines-pen::after {
  content: "\e472\e472"; }
.fad.fa-church::after, .fa-duotone.fa-church::after {
  content: "\f51d\f51d"; }
.fad.fa-person-snowmobiling::after, .fa-duotone.fa-person-snowmobiling::after {
  content: "\f7d1\f7d1"; }
.fad.fa-snowmobile::after, .fa-duotone.fa-snowmobile::after {
  content: "\f7d1\f7d1"; }
.fad.fa-face-hushed::after, .fa-duotone.fa-face-hushed::after {
  content: "\e37b\e37b"; }
.fad.fa-comments-dollar::after, .fa-duotone.fa-comments-dollar::after {
  content: "\f653\f653"; }
.fad.fa-pickaxe::after, .fa-duotone.fa-pickaxe::after {
  content: "\e5bf\e5bf"; }
.fad.fa-link-simple-slash::after, .fa-duotone.fa-link-simple-slash::after {
  content: "\e1ce\e1ce"; }
.fad.fa-democrat::after, .fa-duotone.fa-democrat::after {
  content: "\f747\f747"; }
.fad.fa-face-confused::after, .fa-duotone.fa-face-confused::after {
  content: "\e36d\e36d"; }
.fad.fa-pinball::after, .fa-duotone.fa-pinball::after {
  content: "\e229\e229"; }
.fad.fa-z::after, .fa-duotone.fa-z::after {
  content: "\5a\5a"; }
.fad.fa-person-skiing::after, .fa-duotone.fa-person-skiing::after {
  content: "\f7c9\f7c9"; }
.fad.fa-skiing::after, .fa-duotone.fa-skiing::after {
  content: "\f7c9\f7c9"; }
.fad.fa-deer::after, .fa-duotone.fa-deer::after {
  content: "\f78e\f78e"; }
.fad.fa-input-pipe::after, .fa-duotone.fa-input-pipe::after {
  content: "\e1be\e1be"; }
.fad.fa-road-lock::after, .fa-duotone.fa-road-lock::after {
  content: "\e567\e567"; }
.fad.fa-a::after, .fa-duotone.fa-a::after {
  content: "\41\41"; }
.fad.fa-bookmark-slash::after, .fa-duotone.fa-bookmark-slash::after {
  content: "\e0c2\e0c2"; }
.fad.fa-temperature-arrow-down::after, .fa-duotone.fa-temperature-arrow-down::after {
  content: "\e03f\e03f"; }
.fad.fa-temperature-down::after, .fa-duotone.fa-temperature-down::after {
  content: "\e03f\e03f"; }
.fad.fa-mace::after, .fa-duotone.fa-mace::after {
  content: "\f6f8\f6f8"; }
.fad.fa-feather-pointed::after, .fa-duotone.fa-feather-pointed::after {
  content: "\f56b\f56b"; }
.fad.fa-feather-alt::after, .fa-duotone.fa-feather-alt::after {
  content: "\f56b\f56b"; }
.fad.fa-sausage::after, .fa-duotone.fa-sausage::after {
  content: "\f820\f820"; }
.fad.fa-trash-can-clock::after, .fa-duotone.fa-trash-can-clock::after {
  content: "\e2aa\e2aa"; }
.fad.fa-p::after, .fa-duotone.fa-p::after {
  content: "\50\50"; }
.fad.fa-snowflake::after, .fa-duotone.fa-snowflake::after {
  content: "\f2dc\f2dc"; }
.fad.fa-stomach::after, .fa-duotone.fa-stomach::after {
  content: "\f623\f623"; }
.fad.fa-newspaper::after, .fa-duotone.fa-newspaper::after {
  content: "\f1ea\f1ea"; }
.fad.fa-rectangle-ad::after, .fa-duotone.fa-rectangle-ad::after {
  content: "\f641\f641"; }
.fad.fa-ad::after, .fa-duotone.fa-ad::after {
  content: "\f641\f641"; }
.fad.fa-guitar-electric::after, .fa-duotone.fa-guitar-electric::after {
  content: "\f8be\f8be"; }
.fad.fa-arrow-turn-down-right::after, .fa-duotone.fa-arrow-turn-down-right::after {
  content: "\e3d6\e3d6"; }
.fad.fa-moon-cloud::after, .fa-duotone.fa-moon-cloud::after {
  content: "\f754\f754"; }
.fad.fa-bread-slice-butter::after, .fa-duotone.fa-bread-slice-butter::after {
  content: "\e3e1\e3e1"; }
.fad.fa-circle-arrow-right::after, .fa-duotone.fa-circle-arrow-right::after {
  content: "\f0a9\f0a9"; }
.fad.fa-arrow-circle-right::after, .fa-duotone.fa-arrow-circle-right::after {
  content: "\f0a9\f0a9"; }
.fad.fa-user-group-crown::after, .fa-duotone.fa-user-group-crown::after {
  content: "\f6a5\f6a5"; }
.fad.fa-users-crown::after, .fa-duotone.fa-users-crown::after {
  content: "\f6a5\f6a5"; }
.fad.fa-circle-i::after, .fa-duotone.fa-circle-i::after {
  content: "\e111\e111"; }
.fad.fa-toilet-paper-check::after, .fa-duotone.fa-toilet-paper-check::after {
  content: "\e5b2\e5b2"; }
.fad.fa-filter-circle-xmark::after, .fa-duotone.fa-filter-circle-xmark::after {
  content: "\e17b\e17b"; }
.fad.fa-locust::after, .fa-duotone.fa-locust::after {
  content: "\e520\e520"; }
.fad.fa-sort::after, .fa-duotone.fa-sort::after {
  content: "\f0dc\f0dc"; }
.fad.fa-unsorted::after, .fa-duotone.fa-unsorted::after {
  content: "\f0dc\f0dc"; }
.fad.fa-list-ol::after, .fa-duotone.fa-list-ol::after {
  content: "\f0cb\f0cb"; }
.fad.fa-list-1-2::after, .fa-duotone.fa-list-1-2::after {
  content: "\f0cb\f0cb"; }
.fad.fa-list-numeric::after, .fa-duotone.fa-list-numeric::after {
  content: "\f0cb\f0cb"; }
.fad.fa-chart-waterfall::after, .fa-duotone.fa-chart-waterfall::after {
  content: "\e0eb\e0eb"; }
.fad.fa-face-party::after, .fa-duotone.fa-face-party::after {
  content: "\e383\e383"; }
.fad.fa-kidneys::after, .fa-duotone.fa-kidneys::after {
  content: "\f5fb\f5fb"; }
.fad.fa-wifi-exclamation::after, .fa-duotone.fa-wifi-exclamation::after {
  content: "\e2cf\e2cf"; }
.fad.fa-chart-network::after, .fa-duotone.fa-chart-network::after {
  content: "\f78a\f78a"; }
.fad.fa-person-dress-burst::after, .fa-duotone.fa-person-dress-burst::after {
  content: "\e544\e544"; }
.fad.fa-dice-d4::after, .fa-duotone.fa-dice-d4::after {
  content: "\f6d0\f6d0"; }
.fad.fa-money-check-dollar::after, .fa-duotone.fa-money-check-dollar::after {
  content: "\f53d\f53d"; }
.fad.fa-money-check-alt::after, .fa-duotone.fa-money-check-alt::after {
  content: "\f53d\f53d"; }
.fad.fa-vector-square::after, .fa-duotone.fa-vector-square::after {
  content: "\f5cb\f5cb"; }
.fad.fa-bread-slice::after, .fa-duotone.fa-bread-slice::after {
  content: "\f7ec\f7ec"; }
.fad.fa-language::after, .fa-duotone.fa-language::after {
  content: "\f1ab\f1ab"; }
.fad.fa-wheat-awn-slash::after, .fa-duotone.fa-wheat-awn-slash::after {
  content: "\e338\e338"; }
.fad.fa-face-kiss-wink-heart::after, .fa-duotone.fa-face-kiss-wink-heart::after {
  content: "\f598\f598"; }
.fad.fa-kiss-wink-heart::after, .fa-duotone.fa-kiss-wink-heart::after {
  content: "\f598\f598"; }
.fad.fa-dagger::after, .fa-duotone.fa-dagger::after {
  content: "\f6cb\f6cb"; }
.fad.fa-podium::after, .fa-duotone.fa-podium::after {
  content: "\f680\f680"; }
.fad.fa-memo-circle-check::after, .fa-duotone.fa-memo-circle-check::after {
  content: "\e1d9\e1d9"; }
.fad.fa-route-highway::after, .fa-duotone.fa-route-highway::after {
  content: "\f61a\f61a"; }
.fad.fa-down-to-line::after, .fa-duotone.fa-down-to-line::after {
  content: "\f34a\f34a"; }
.fad.fa-arrow-alt-to-bottom::after, .fa-duotone.fa-arrow-alt-to-bottom::after {
  content: "\f34a\f34a"; }
.fad.fa-filter::after, .fa-duotone.fa-filter::after {
  content: "\f0b0\f0b0"; }
.fad.fa-square-g::after, .fa-duotone.fa-square-g::after {
  content: "\e271\e271"; }
.fad.fa-circle-phone::after, .fa-duotone.fa-circle-phone::after {
  content: "\e11b\e11b"; }
.fad.fa-phone-circle::after, .fa-duotone.fa-phone-circle::after {
  content: "\e11b\e11b"; }
.fad.fa-clipboard-prescription::after, .fa-duotone.fa-clipboard-prescription::after {
  content: "\f5e8\f5e8"; }
.fad.fa-user-nurse-hair::after, .fa-duotone.fa-user-nurse-hair::after {
  content: "\e45d\e45d"; }
.fad.fa-question::after, .fa-duotone.fa-question::after {
  content: "\3f\3f"; }
.fad.fa-file-signature::after, .fa-duotone.fa-file-signature::after {
  content: "\f573\f573"; }
.fad.fa-toggle-large-on::after, .fa-duotone.fa-toggle-large-on::after {
  content: "\e5b1\e5b1"; }
.fad.fa-up-down-left-right::after, .fa-duotone.fa-up-down-left-right::after {
  content: "\f0b2\f0b2"; }
.fad.fa-arrows-alt::after, .fa-duotone.fa-arrows-alt::after {
  content: "\f0b2\f0b2"; }
.fad.fa-dryer-heat::after, .fa-duotone.fa-dryer-heat::after {
  content: "\f862\f862"; }
.fad.fa-dryer-alt::after, .fa-duotone.fa-dryer-alt::after {
  content: "\f862\f862"; }
.fad.fa-house-chimney-user::after, .fa-duotone.fa-house-chimney-user::after {
  content: "\e065\e065"; }
.fad.fa-hand-holding-heart::after, .fa-duotone.fa-hand-holding-heart::after {
  content: "\f4be\f4be"; }
.fad.fa-arrow-up-small-big::after, .fa-duotone.fa-arrow-up-small-big::after {
  content: "\f88f\f88f"; }
.fad.fa-sort-size-up-alt::after, .fa-duotone.fa-sort-size-up-alt::after {
  content: "\f88f\f88f"; }
.fad.fa-train-track::after, .fa-duotone.fa-train-track::after {
  content: "\e453\e453"; }
.fad.fa-puzzle-piece::after, .fa-duotone.fa-puzzle-piece::after {
  content: "\f12e\f12e"; }
.fad.fa-money-check::after, .fa-duotone.fa-money-check::after {
  content: "\f53c\f53c"; }
.fad.fa-star-half-stroke::after, .fa-duotone.fa-star-half-stroke::after {
  content: "\f5c0\f5c0"; }
.fad.fa-star-half-alt::after, .fa-duotone.fa-star-half-alt::after {
  content: "\f5c0\f5c0"; }
.fad.fa-file-exclamation::after, .fa-duotone.fa-file-exclamation::after {
  content: "\f31a\f31a"; }
.fad.fa-code::after, .fa-duotone.fa-code::after {
  content: "\f121\f121"; }
.fad.fa-whiskey-glass::after, .fa-duotone.fa-whiskey-glass::after {
  content: "\f7a0\f7a0"; }
.fad.fa-glass-whiskey::after, .fa-duotone.fa-glass-whiskey::after {
  content: "\f7a0\f7a0"; }
.fad.fa-moon-stars::after, .fa-duotone.fa-moon-stars::after {
  content: "\f755\f755"; }
.fad.fa-building-circle-exclamation::after, .fa-duotone.fa-building-circle-exclamation::after {
  content: "\e4d3\e4d3"; }
.fad.fa-clothes-hanger::after, .fa-duotone.fa-clothes-hanger::after {
  content: "\e136\e136"; }
.fad.fa-mobile-notch::after, .fa-duotone.fa-mobile-notch::after {
  content: "\e1ee\e1ee"; }
.fad.fa-mobile-iphone::after, .fa-duotone.fa-mobile-iphone::after {
  content: "\e1ee\e1ee"; }
.fad.fa-magnifying-glass-chart::after, .fa-duotone.fa-magnifying-glass-chart::after {
  content: "\e522\e522"; }
.fad.fa-arrow-up-right-from-square::after, .fa-duotone.fa-arrow-up-right-from-square::after {
  content: "\f08e\f08e"; }
.fad.fa-external-link::after, .fa-duotone.fa-external-link::after {
  content: "\f08e\f08e"; }
.fad.fa-cubes-stacked::after, .fa-duotone.fa-cubes-stacked::after {
  content: "\e4e6\e4e6"; }
.fad.fa-images-user::after, .fa-duotone.fa-images-user::after {
  content: "\e1b9\e1b9"; }
.fad.fa-won-sign::after, .fa-duotone.fa-won-sign::after {
  content: "\f159\f159"; }
.fad.fa-krw::after, .fa-duotone.fa-krw::after {
  content: "\f159\f159"; }
.fad.fa-won::after, .fa-duotone.fa-won::after {
  content: "\f159\f159"; }
.fad.fa-image-polaroid-user::after, .fa-duotone.fa-image-polaroid-user::after {
  content: "\e1b6\e1b6"; }
.fad.fa-virus-covid::after, .fa-duotone.fa-virus-covid::after {
  content: "\e4a8\e4a8"; }
.fad.fa-square-ellipsis::after, .fa-duotone.fa-square-ellipsis::after {
  content: "\e26e\e26e"; }
.fad.fa-pie::after, .fa-duotone.fa-pie::after {
  content: "\f705\f705"; }
.fad.fa-chess-knight-piece::after, .fa-duotone.fa-chess-knight-piece::after {
  content: "\f442\f442"; }
.fad.fa-chess-knight-alt::after, .fa-duotone.fa-chess-knight-alt::after {
  content: "\f442\f442"; }
.fad.fa-austral-sign::after, .fa-duotone.fa-austral-sign::after {
  content: "\e0a9\e0a9"; }
.fad.fa-cloud-plus::after, .fa-duotone.fa-cloud-plus::after {
  content: "\e35e\e35e"; }
.fad.fa-f::after, .fa-duotone.fa-f::after {
  content: "\46\46"; }
.fad.fa-leaf::after, .fa-duotone.fa-leaf::after {
  content: "\f06c\f06c"; }
.fad.fa-bed-bunk::after, .fa-duotone.fa-bed-bunk::after {
  content: "\f8f8\f8f8"; }
.fad.fa-road::after, .fa-duotone.fa-road::after {
  content: "\f018\f018"; }
.fad.fa-taxi::after, .fa-duotone.fa-taxi::after {
  content: "\f1ba\f1ba"; }
.fad.fa-cab::after, .fa-duotone.fa-cab::after {
  content: "\f1ba\f1ba"; }
.fad.fa-person-circle-plus::after, .fa-duotone.fa-person-circle-plus::after {
  content: "\e541\e541"; }
.fad.fa-chart-pie::after, .fa-duotone.fa-chart-pie::after {
  content: "\f200\f200"; }
.fad.fa-pie-chart::after, .fa-duotone.fa-pie-chart::after {
  content: "\f200\f200"; }
.fad.fa-bolt-lightning::after, .fa-duotone.fa-bolt-lightning::after {
  content: "\e0b7\e0b7"; }
.fad.fa-clock-eight::after, .fa-duotone.fa-clock-eight::after {
  content: "\e345\e345"; }
.fad.fa-sack-xmark::after, .fa-duotone.fa-sack-xmark::after {
  content: "\e56a\e56a"; }
.fad.fa-file-excel::after, .fa-duotone.fa-file-excel::after {
  content: "\f1c3\f1c3"; }
.fad.fa-file-contract::after, .fa-duotone.fa-file-contract::after {
  content: "\f56c\f56c"; }
.fad.fa-fish-fins::after, .fa-duotone.fa-fish-fins::after {
  content: "\e4f2\e4f2"; }
.fad.fa-circle-q::after, .fa-duotone.fa-circle-q::after {
  content: "\e11e\e11e"; }
.fad.fa-building-flag::after, .fa-duotone.fa-building-flag::after {
  content: "\e4d5\e4d5"; }
.fad.fa-face-grin-beam::after, .fa-duotone.fa-face-grin-beam::after {
  content: "\f582\f582"; }
.fad.fa-grin-beam::after, .fa-duotone.fa-grin-beam::after {
  content: "\f582\f582"; }
.fad.fa-object-ungroup::after, .fa-duotone.fa-object-ungroup::after {
  content: "\f248\f248"; }
.fad.fa-face-disguise::after, .fa-duotone.fa-face-disguise::after {
  content: "\e370\e370"; }
.fad.fa-circle-arrow-down-right::after, .fa-duotone.fa-circle-arrow-down-right::after {
  content: "\e0fa\e0fa"; }
.fad.fa-alien-8bit::after, .fa-duotone.fa-alien-8bit::after {
  content: "\f8f6\f8f6"; }
.fad.fa-alien-monster::after, .fa-duotone.fa-alien-monster::after {
  content: "\f8f6\f8f6"; }
.fad.fa-hand-point-ribbon::after, .fa-duotone.fa-hand-point-ribbon::after {
  content: "\e1a6\e1a6"; }
.fad.fa-poop::after, .fa-duotone.fa-poop::after {
  content: "\f619\f619"; }
.fad.fa-object-exclude::after, .fa-duotone.fa-object-exclude::after {
  content: "\e49c\e49c"; }
.fad.fa-telescope::after, .fa-duotone.fa-telescope::after {
  content: "\e03e\e03e"; }
.fad.fa-location-pin::after, .fa-duotone.fa-location-pin::after {
  content: "\f041\f041"; }
.fad.fa-map-marker::after, .fa-duotone.fa-map-marker::after {
  content: "\f041\f041"; }
.fad.fa-square-list::after, .fa-duotone.fa-square-list::after {
  content: "\e489\e489"; }
.fad.fa-kaaba::after, .fa-duotone.fa-kaaba::after {
  content: "\f66b\f66b"; }
.fad.fa-toilet-paper::after, .fa-duotone.fa-toilet-paper::after {
  content: "\f71e\f71e"; }
.fad.fa-helmet-safety::after, .fa-duotone.fa-helmet-safety::after {
  content: "\f807\f807"; }
.fad.fa-hard-hat::after, .fa-duotone.fa-hard-hat::after {
  content: "\f807\f807"; }
.fad.fa-hat-hard::after, .fa-duotone.fa-hat-hard::after {
  content: "\f807\f807"; }
.fad.fa-comment-code::after, .fa-duotone.fa-comment-code::after {
  content: "\e147\e147"; }
.fad.fa-sim-cards::after, .fa-duotone.fa-sim-cards::after {
  content: "\e251\e251"; }
.fad.fa-starship::after, .fa-duotone.fa-starship::after {
  content: "\e039\e039"; }
.fad.fa-eject::after, .fa-duotone.fa-eject::after {
  content: "\f052\f052"; }
.fad.fa-circle-right::after, .fa-duotone.fa-circle-right::after {
  content: "\f35a\f35a"; }
.fad.fa-arrow-alt-circle-right::after, .fa-duotone.fa-arrow-alt-circle-right::after {
  content: "\f35a\f35a"; }
.fad.fa-plane-circle-check::after, .fa-duotone.fa-plane-circle-check::after {
  content: "\e555\e555"; }
.fad.fa-seal::after, .fa-duotone.fa-seal::after {
  content: "\e241\e241"; }
.fad.fa-user-cowboy::after, .fa-duotone.fa-user-cowboy::after {
  content: "\f8ea\f8ea"; }
.fad.fa-hexagon-vertical-nft::after, .fa-duotone.fa-hexagon-vertical-nft::after {
  content: "\e505\e505"; }
.fad.fa-face-rolling-eyes::after, .fa-duotone.fa-face-rolling-eyes::after {
  content: "\f5a5\f5a5"; }
.fad.fa-meh-rolling-eyes::after, .fa-duotone.fa-meh-rolling-eyes::after {
  content: "\f5a5\f5a5"; }
.fad.fa-bread-loaf::after, .fa-duotone.fa-bread-loaf::after {
  content: "\f7eb\f7eb"; }
.fad.fa-rings-wedding::after, .fa-duotone.fa-rings-wedding::after {
  content: "\f81b\f81b"; }
.fad.fa-object-group::after, .fa-duotone.fa-object-group::after {
  content: "\f247\f247"; }
.fad.fa-french-fries::after, .fa-duotone.fa-french-fries::after {
  content: "\f803\f803"; }
.fad.fa-chart-line::after, .fa-duotone.fa-chart-line::after {
  content: "\f201\f201"; }
.fad.fa-line-chart::after, .fa-duotone.fa-line-chart::after {
  content: "\f201\f201"; }
.fad.fa-calendar-arrow-down::after, .fa-duotone.fa-calendar-arrow-down::after {
  content: "\e0d0\e0d0"; }
.fad.fa-calendar-download::after, .fa-duotone.fa-calendar-download::after {
  content: "\e0d0\e0d0"; }
.fad.fa-send-back::after, .fa-duotone.fa-send-back::after {
  content: "\f87e\f87e"; }
.fad.fa-mask-ventilator::after, .fa-duotone.fa-mask-ventilator::after {
  content: "\e524\e524"; }
.fad.fa-signature-lock::after, .fa-duotone.fa-signature-lock::after {
  content: "\e3ca\e3ca"; }
.fad.fa-arrow-right::after, .fa-duotone.fa-arrow-right::after {
  content: "\f061\f061"; }
.fad.fa-signs-post::after, .fa-duotone.fa-signs-post::after {
  content: "\f277\f277"; }
.fad.fa-map-signs::after, .fa-duotone.fa-map-signs::after {
  content: "\f277\f277"; }
.fad.fa-octagon-plus::after, .fa-duotone.fa-octagon-plus::after {
  content: "\f301\f301"; }
.fad.fa-plus-octagon::after, .fa-duotone.fa-plus-octagon::after {
  content: "\f301\f301"; }
.fad.fa-cash-register::after, .fa-duotone.fa-cash-register::after {
  content: "\f788\f788"; }
.fad.fa-person-circle-question::after, .fa-duotone.fa-person-circle-question::after {
  content: "\e542\e542"; }
.fad.fa-melon-slice::after, .fa-duotone.fa-melon-slice::after {
  content: "\e311\e311"; }
.fad.fa-space-station-moon::after, .fa-duotone.fa-space-station-moon::after {
  content: "\e033\e033"; }
.fad.fa-message-smile::after, .fa-duotone.fa-message-smile::after {
  content: "\f4aa\f4aa"; }
.fad.fa-comment-alt-smile::after, .fa-duotone.fa-comment-alt-smile::after {
  content: "\f4aa\f4aa"; }
.fad.fa-cup-straw::after, .fa-duotone.fa-cup-straw::after {
  content: "\e363\e363"; }
.fad.fa-left-from-line::after, .fa-duotone.fa-left-from-line::after {
  content: "\f348\f348"; }
.fad.fa-arrow-alt-from-right::after, .fa-duotone.fa-arrow-alt-from-right::after {
  content: "\f348\f348"; }
.fad.fa-h::after, .fa-duotone.fa-h::after {
  content: "\48\48"; }
.fad.fa-basket-shopping-simple::after, .fa-duotone.fa-basket-shopping-simple::after {
  content: "\e0af\e0af"; }
.fad.fa-shopping-basket-alt::after, .fa-duotone.fa-shopping-basket-alt::after {
  content: "\e0af\e0af"; }
.fad.fa-hands-holding-heart::after, .fa-duotone.fa-hands-holding-heart::after {
  content: "\f4c3\f4c3"; }
.fad.fa-hands-heart::after, .fa-duotone.fa-hands-heart::after {
  content: "\f4c3\f4c3"; }
.fad.fa-clock-nine::after, .fa-duotone.fa-clock-nine::after {
  content: "\e34c\e34c"; }
.fad.fa-tarp::after, .fa-duotone.fa-tarp::after {
  content: "\e57b\e57b"; }
.fad.fa-face-sleepy::after, .fa-duotone.fa-face-sleepy::after {
  content: "\e38e\e38e"; }
.fad.fa-hand-horns::after, .fa-duotone.fa-hand-horns::after {
  content: "\e1a9\e1a9"; }
.fad.fa-screwdriver-wrench::after, .fa-duotone.fa-screwdriver-wrench::after {
  content: "\f7d9\f7d9"; }
.fad.fa-tools::after, .fa-duotone.fa-tools::after {
  content: "\f7d9\f7d9"; }
.fad.fa-arrows-to-eye::after, .fa-duotone.fa-arrows-to-eye::after {
  content: "\e4bf\e4bf"; }
.fad.fa-circle-three-quarters::after, .fa-duotone.fa-circle-three-quarters::after {
  content: "\e125\e125"; }
.fad.fa-trophy-star::after, .fa-duotone.fa-trophy-star::after {
  content: "\f2eb\f2eb"; }
.fad.fa-trophy-alt::after, .fa-duotone.fa-trophy-alt::after {
  content: "\f2eb\f2eb"; }
.fad.fa-plug-circle-bolt::after, .fa-duotone.fa-plug-circle-bolt::after {
  content: "\e55b\e55b"; }
.fad.fa-face-thermometer::after, .fa-duotone.fa-face-thermometer::after {
  content: "\e39a\e39a"; }
.fad.fa-shirt-running::after, .fa-duotone.fa-shirt-running::after {
  content: "\e3c8\e3c8"; }
.fad.fa-book-circle-arrow-up::after, .fa-duotone.fa-book-circle-arrow-up::after {
  content: "\e0bd\e0bd"; }
.fad.fa-face-nauseated::after, .fa-duotone.fa-face-nauseated::after {
  content: "\e381\e381"; }
.fad.fa-heart::after, .fa-duotone.fa-heart::after {
  content: "\f004\f004"; }
.fad.fa-file-chart-pie::after, .fa-duotone.fa-file-chart-pie::after {
  content: "\f65a\f65a"; }
.fad.fa-mars-and-venus::after, .fa-duotone.fa-mars-and-venus::after {
  content: "\f224\f224"; }
.fad.fa-house-user::after, .fa-duotone.fa-house-user::after {
  content: "\e1b0\e1b0"; }
.fad.fa-home-user::after, .fa-duotone.fa-home-user::after {
  content: "\e1b0\e1b0"; }
.fad.fa-circle-arrow-down-left::after, .fa-duotone.fa-circle-arrow-down-left::after {
  content: "\e0f9\e0f9"; }
.fad.fa-dumpster-fire::after, .fa-duotone.fa-dumpster-fire::after {
  content: "\f794\f794"; }
.fad.fa-hexagon-minus::after, .fa-duotone.fa-hexagon-minus::after {
  content: "\f307\f307"; }
.fad.fa-minus-hexagon::after, .fa-duotone.fa-minus-hexagon::after {
  content: "\f307\f307"; }
.fad.fa-left-to-line::after, .fa-duotone.fa-left-to-line::after {
  content: "\f34b\f34b"; }
.fad.fa-arrow-alt-to-left::after, .fa-duotone.fa-arrow-alt-to-left::after {
  content: "\f34b\f34b"; }
.fad.fa-house-crack::after, .fa-duotone.fa-house-crack::after {
  content: "\e3b1\e3b1"; }
.fad.fa-paw-simple::after, .fa-duotone.fa-paw-simple::after {
  content: "\f701\f701"; }
.fad.fa-paw-alt::after, .fa-duotone.fa-paw-alt::after {
  content: "\f701\f701"; }
.fad.fa-arrow-left-long-to-line::after, .fa-duotone.fa-arrow-left-long-to-line::after {
  content: "\e3d4\e3d4"; }
.fad.fa-brackets-round::after, .fa-duotone.fa-brackets-round::after {
  content: "\e0c5\e0c5"; }
.fad.fa-parentheses::after, .fa-duotone.fa-parentheses::after {
  content: "\e0c5\e0c5"; }
.fad.fa-martini-glass-citrus::after, .fa-duotone.fa-martini-glass-citrus::after {
  content: "\f561\f561"; }
.fad.fa-cocktail::after, .fa-duotone.fa-cocktail::after {
  content: "\f561\f561"; }
.fad.fa-user-shakespeare::after, .fa-duotone.fa-user-shakespeare::after {
  content: "\e2c2\e2c2"; }
.fad.fa-arrow-right-to-arc::after, .fa-duotone.fa-arrow-right-to-arc::after {
  content: "\e4b2\e4b2"; }
.fad.fa-face-surprise::after, .fa-duotone.fa-face-surprise::after {
  content: "\f5c2\f5c2"; }
.fad.fa-surprise::after, .fa-duotone.fa-surprise::after {
  content: "\f5c2\f5c2"; }
.fad.fa-bottle-water::after, .fa-duotone.fa-bottle-water::after {
  content: "\e4c5\e4c5"; }
.fad.fa-circle-pause::after, .fa-duotone.fa-circle-pause::after {
  content: "\f28b\f28b"; }
.fad.fa-pause-circle::after, .fa-duotone.fa-pause-circle::after {
  content: "\f28b\f28b"; }
.fad.fa-gauge-circle-plus::after, .fa-duotone.fa-gauge-circle-plus::after {
  content: "\e498\e498"; }
.fad.fa-folders::after, .fa-duotone.fa-folders::after {
  content: "\f660\f660"; }
.fad.fa-angel::after, .fa-duotone.fa-angel::after {
  content: "\f779\f779"; }
.fad.fa-value-absolute::after, .fa-duotone.fa-value-absolute::after {
  content: "\f6a6\f6a6"; }
.fad.fa-rabbit::after, .fa-duotone.fa-rabbit::after {
  content: "\f708\f708"; }
.fad.fa-toilet-paper-slash::after, .fa-duotone.fa-toilet-paper-slash::after {
  content: "\e072\e072"; }
.fad.fa-apple-whole::after, .fa-duotone.fa-apple-whole::after {
  content: "\f5d1\f5d1"; }
.fad.fa-apple-alt::after, .fa-duotone.fa-apple-alt::after {
  content: "\f5d1\f5d1"; }
.fad.fa-kitchen-set::after, .fa-duotone.fa-kitchen-set::after {
  content: "\e51a\e51a"; }
.fad.fa-diamond-half::after, .fa-duotone.fa-diamond-half::after {
  content: "\e5b7\e5b7"; }
.fad.fa-lock-keyhole::after, .fa-duotone.fa-lock-keyhole::after {
  content: "\f30d\f30d"; }
.fad.fa-lock-alt::after, .fa-duotone.fa-lock-alt::after {
  content: "\f30d\f30d"; }
.fad.fa-r::after, .fa-duotone.fa-r::after {
  content: "\52\52"; }
.fad.fa-temperature-quarter::after, .fa-duotone.fa-temperature-quarter::after {
  content: "\f2ca\f2ca"; }
.fad.fa-temperature-1::after, .fa-duotone.fa-temperature-1::after {
  content: "\f2ca\f2ca"; }
.fad.fa-thermometer-1::after, .fa-duotone.fa-thermometer-1::after {
  content: "\f2ca\f2ca"; }
.fad.fa-thermometer-quarter::after, .fa-duotone.fa-thermometer-quarter::after {
  content: "\f2ca\f2ca"; }
.fad.fa-square-info::after, .fa-duotone.fa-square-info::after {
  content: "\f30f\f30f"; }
.fad.fa-info-square::after, .fa-duotone.fa-info-square::after {
  content: "\f30f\f30f"; }
.fad.fa-wifi-slash::after, .fa-duotone.fa-wifi-slash::after {
  content: "\f6ac\f6ac"; }
.fad.fa-toilet-paper-xmark::after, .fa-duotone.fa-toilet-paper-xmark::after {
  content: "\e5b3\e5b3"; }
.fad.fa-hands-holding-dollar::after, .fa-duotone.fa-hands-holding-dollar::after {
  content: "\f4c5\f4c5"; }
.fad.fa-hands-usd::after, .fa-duotone.fa-hands-usd::after {
  content: "\f4c5\f4c5"; }
.fad.fa-cube::after, .fa-duotone.fa-cube::after {
  content: "\f1b2\f1b2"; }
.fad.fa-arrow-down-triangle-square::after, .fa-duotone.fa-arrow-down-triangle-square::after {
  content: "\f888\f888"; }
.fad.fa-sort-shapes-down::after, .fa-duotone.fa-sort-shapes-down::after {
  content: "\f888\f888"; }
.fad.fa-bitcoin-sign::after, .fa-duotone.fa-bitcoin-sign::after {
  content: "\e0b4\e0b4"; }
.fad.fa-shutters::after, .fa-duotone.fa-shutters::after {
  content: "\e449\e449"; }
.fad.fa-shield-dog::after, .fa-duotone.fa-shield-dog::after {
  content: "\e573\e573"; }
.fad.fa-solar-panel::after, .fa-duotone.fa-solar-panel::after {
  content: "\f5ba\f5ba"; }
.fad.fa-lock-open::after, .fa-duotone.fa-lock-open::after {
  content: "\f3c1\f3c1"; }
.fad.fa-table-tree::after, .fa-duotone.fa-table-tree::after {
  content: "\e293\e293"; }
.fad.fa-house-chimney-heart::after, .fa-duotone.fa-house-chimney-heart::after {
  content: "\e1b2\e1b2"; }
.fad.fa-tally-3::after, .fa-duotone.fa-tally-3::after {
  content: "\e296\e296"; }
.fad.fa-elevator::after, .fa-duotone.fa-elevator::after {
  content: "\e16d\e16d"; }
.fad.fa-money-bill-transfer::after, .fa-duotone.fa-money-bill-transfer::after {
  content: "\e528\e528"; }
.fad.fa-money-bill-trend-up::after, .fa-duotone.fa-money-bill-trend-up::after {
  content: "\e529\e529"; }
.fad.fa-house-flood-water-circle-arrow-right::after, .fa-duotone.fa-house-flood-water-circle-arrow-right::after {
  content: "\e50f\e50f"; }
.fad.fa-square-poll-horizontal::after, .fa-duotone.fa-square-poll-horizontal::after {
  content: "\f682\f682"; }
.fad.fa-poll-h::after, .fa-duotone.fa-poll-h::after {
  content: "\f682\f682"; }
.fad.fa-circle::after, .fa-duotone.fa-circle::after {
  content: "\f111\f111"; }
.fad.fa-cart-circle-exclamation::after, .fa-duotone.fa-cart-circle-exclamation::after {
  content: "\e3f2\e3f2"; }
.fad.fa-sword::after, .fa-duotone.fa-sword::after {
  content: "\f71c\f71c"; }
.fad.fa-backward-fast::after, .fa-duotone.fa-backward-fast::after {
  content: "\f049\f049"; }
.fad.fa-fast-backward::after, .fa-duotone.fa-fast-backward::after {
  content: "\f049\f049"; }
.fad.fa-recycle::after, .fa-duotone.fa-recycle::after {
  content: "\f1b8\f1b8"; }
.fad.fa-user-astronaut::after, .fa-duotone.fa-user-astronaut::after {
  content: "\f4fb\f4fb"; }
.fad.fa-interrobang::after, .fa-duotone.fa-interrobang::after {
  content: "\e5ba\e5ba"; }
.fad.fa-plane-slash::after, .fa-duotone.fa-plane-slash::after {
  content: "\e069\e069"; }
.fad.fa-circle-dashed::after, .fa-duotone.fa-circle-dashed::after {
  content: "\e105\e105"; }
.fad.fa-trademark::after, .fa-duotone.fa-trademark::after {
  content: "\f25c\f25c"; }
.fad.fa-basketball::after, .fa-duotone.fa-basketball::after {
  content: "\f434\f434"; }
.fad.fa-basketball-ball::after, .fa-duotone.fa-basketball-ball::after {
  content: "\f434\f434"; }
.fad.fa-fork-knife::after, .fa-duotone.fa-fork-knife::after {
  content: "\f2e6\f2e6"; }
.fad.fa-utensils-alt::after, .fa-duotone.fa-utensils-alt::after {
  content: "\f2e6\f2e6"; }
.fad.fa-satellite-dish::after, .fa-duotone.fa-satellite-dish::after {
  content: "\f7c0\f7c0"; }
.fad.fa-badge-check::after, .fa-duotone.fa-badge-check::after {
  content: "\f336\f336"; }
.fad.fa-circle-up::after, .fa-duotone.fa-circle-up::after {
  content: "\f35b\f35b"; }
.fad.fa-arrow-alt-circle-up::after, .fa-duotone.fa-arrow-alt-circle-up::after {
  content: "\f35b\f35b"; }
.fad.fa-slider::after, .fa-duotone.fa-slider::after {
  content: "\e252\e252"; }
.fad.fa-mobile-screen-button::after, .fa-duotone.fa-mobile-screen-button::after {
  content: "\f3cd\f3cd"; }
.fad.fa-mobile-alt::after, .fa-duotone.fa-mobile-alt::after {
  content: "\f3cd\f3cd"; }
.fad.fa-clock-one-thirty::after, .fa-duotone.fa-clock-one-thirty::after {
  content: "\e34f\e34f"; }
.fad.fa-inbox-out::after, .fa-duotone.fa-inbox-out::after {
  content: "\f311\f311"; }
.fad.fa-inbox-arrow-up::after, .fa-duotone.fa-inbox-arrow-up::after {
  content: "\f311\f311"; }
.fad.fa-cloud-slash::after, .fa-duotone.fa-cloud-slash::after {
  content: "\e137\e137"; }
.fad.fa-volume-high::after, .fa-duotone.fa-volume-high::after {
  content: "\f028\f028"; }
.fad.fa-volume-up::after, .fa-duotone.fa-volume-up::after {
  content: "\f028\f028"; }
.fad.fa-users-rays::after, .fa-duotone.fa-users-rays::after {
  content: "\e593\e593"; }
.fad.fa-wallet::after, .fa-duotone.fa-wallet::after {
  content: "\f555\f555"; }
.fad.fa-octagon-check::after, .fa-duotone.fa-octagon-check::after {
  content: "\e426\e426"; }
.fad.fa-flatbread-stuffed::after, .fa-duotone.fa-flatbread-stuffed::after {
  content: "\e40c\e40c"; }
.fad.fa-clipboard-check::after, .fa-duotone.fa-clipboard-check::after {
  content: "\f46c\f46c"; }
.fad.fa-cart-circle-plus::after, .fa-duotone.fa-cart-circle-plus::after {
  content: "\e3f3\e3f3"; }
.fad.fa-truck-clock::after, .fa-duotone.fa-truck-clock::after {
  content: "\f48c\f48c"; }
.fad.fa-shipping-timed::after, .fa-duotone.fa-shipping-timed::after {
  content: "\f48c\f48c"; }
.fad.fa-pool-8-ball::after, .fa-duotone.fa-pool-8-ball::after {
  content: "\e3c5\e3c5"; }
.fad.fa-file-audio::after, .fa-duotone.fa-file-audio::after {
  content: "\f1c7\f1c7"; }
.fad.fa-turn-down-left::after, .fa-duotone.fa-turn-down-left::after {
  content: "\e331\e331"; }
.fad.fa-lock-hashtag::after, .fa-duotone.fa-lock-hashtag::after {
  content: "\e423\e423"; }
.fad.fa-chart-radar::after, .fa-duotone.fa-chart-radar::after {
  content: "\e0e7\e0e7"; }
.fad.fa-staff::after, .fa-duotone.fa-staff::after {
  content: "\f71b\f71b"; }
.fad.fa-burger::after, .fa-duotone.fa-burger::after {
  content: "\f805\f805"; }
.fad.fa-hamburger::after, .fa-duotone.fa-hamburger::after {
  content: "\f805\f805"; }
.fad.fa-utility-pole::after, .fa-duotone.fa-utility-pole::after {
  content: "\e2c3\e2c3"; }
.fad.fa-transporter-6::after, .fa-duotone.fa-transporter-6::after {
  content: "\e2a7\e2a7"; }
.fad.fa-wrench::after, .fa-duotone.fa-wrench::after {
  content: "\f0ad\f0ad"; }
.fad.fa-bugs::after, .fa-duotone.fa-bugs::after {
  content: "\e4d0\e4d0"; }
.fad.fa-vector-polygon::after, .fa-duotone.fa-vector-polygon::after {
  content: "\e2c7\e2c7"; }
.fad.fa-diagram-nested::after, .fa-duotone.fa-diagram-nested::after {
  content: "\e157\e157"; }
.fad.fa-rupee-sign::after, .fa-duotone.fa-rupee-sign::after {
  content: "\f156\f156"; }
.fad.fa-rupee::after, .fa-duotone.fa-rupee::after {
  content: "\f156\f156"; }
.fad.fa-file-image::after, .fa-duotone.fa-file-image::after {
  content: "\f1c5\f1c5"; }
.fad.fa-circle-question::after, .fa-duotone.fa-circle-question::after {
  content: "\f059\f059"; }
.fad.fa-question-circle::after, .fa-duotone.fa-question-circle::after {
  content: "\f059\f059"; }
.fad.fa-image-user::after, .fa-duotone.fa-image-user::after {
  content: "\e1b8\e1b8"; }
.fad.fa-buoy::after, .fa-duotone.fa-buoy::after {
  content: "\e5b5\e5b5"; }
.fad.fa-plane-departure::after, .fa-duotone.fa-plane-departure::after {
  content: "\f5b0\f5b0"; }
.fad.fa-handshake-slash::after, .fa-duotone.fa-handshake-slash::after {
  content: "\e060\e060"; }
.fad.fa-book-bookmark::after, .fa-duotone.fa-book-bookmark::after {
  content: "\e0bb\e0bb"; }
.fad.fa-border-center-h::after, .fa-duotone.fa-border-center-h::after {
  content: "\f89c\f89c"; }
.fad.fa-can-food::after, .fa-duotone.fa-can-food::after {
  content: "\e3e6\e3e6"; }
.fad.fa-typewriter::after, .fa-duotone.fa-typewriter::after {
  content: "\f8e7\f8e7"; }
.fad.fa-arrow-right-from-arc::after, .fa-duotone.fa-arrow-right-from-arc::after {
  content: "\e4b1\e4b1"; }
.fad.fa-circle-k::after, .fa-duotone.fa-circle-k::after {
  content: "\e113\e113"; }
.fad.fa-face-hand-over-mouth::after, .fa-duotone.fa-face-hand-over-mouth::after {
  content: "\e378\e378"; }
.fad.fa-popcorn::after, .fa-duotone.fa-popcorn::after {
  content: "\f819\f819"; }
.fad.fa-house-water::after, .fa-duotone.fa-house-water::after {
  content: "\f74f\f74f"; }
.fad.fa-house-flood::after, .fa-duotone.fa-house-flood::after {
  content: "\f74f\f74f"; }
.fad.fa-object-subtract::after, .fa-duotone.fa-object-subtract::after {
  content: "\e49e\e49e"; }
.fad.fa-code-branch::after, .fa-duotone.fa-code-branch::after {
  content: "\f126\f126"; }
.fad.fa-warehouse-full::after, .fa-duotone.fa-warehouse-full::after {
  content: "\f495\f495"; }
.fad.fa-warehouse-alt::after, .fa-duotone.fa-warehouse-alt::after {
  content: "\f495\f495"; }
.fad.fa-hat-cowboy::after, .fa-duotone.fa-hat-cowboy::after {
  content: "\f8c0\f8c0"; }
.fad.fa-bridge::after, .fa-duotone.fa-bridge::after {
  content: "\e4c8\e4c8"; }
.fad.fa-phone-flip::after, .fa-duotone.fa-phone-flip::after {
  content: "\f879\f879"; }
.fad.fa-phone-alt::after, .fa-duotone.fa-phone-alt::after {
  content: "\f879\f879"; }
.fad.fa-arrow-down-from-dotted-line::after, .fa-duotone.fa-arrow-down-from-dotted-line::after {
  content: "\e090\e090"; }
.fad.fa-square-quarters::after, .fa-duotone.fa-square-quarters::after {
  content: "\e44e\e44e"; }
.fad.fa-truck-front::after, .fa-duotone.fa-truck-front::after {
  content: "\e2b7\e2b7"; }
.fad.fa-cat::after, .fa-duotone.fa-cat::after {
  content: "\f6be\f6be"; }
.fad.fa-trash-xmark::after, .fa-duotone.fa-trash-xmark::after {
  content: "\e2b4\e2b4"; }
.fad.fa-circle-caret-left::after, .fa-duotone.fa-circle-caret-left::after {
  content: "\f32e\f32e"; }
.fad.fa-caret-circle-left::after, .fa-duotone.fa-caret-circle-left::after {
  content: "\f32e\f32e"; }
.fad.fa-files::after, .fa-duotone.fa-files::after {
  content: "\e178\e178"; }
.fad.fa-anchor-circle-exclamation::after, .fa-duotone.fa-anchor-circle-exclamation::after {
  content: "\e4ab\e4ab"; }
.fad.fa-face-clouds::after, .fa-duotone.fa-face-clouds::after {
  content: "\e47d\e47d"; }
.fad.fa-user-crown::after, .fa-duotone.fa-user-crown::after {
  content: "\f6a4\f6a4"; }
.fad.fa-truck-field::after, .fa-duotone.fa-truck-field::after {
  content: "\e58d\e58d"; }
.fad.fa-route::after, .fa-duotone.fa-route::after {
  content: "\f4d7\f4d7"; }
.fad.fa-cart-circle-check::after, .fa-duotone.fa-cart-circle-check::after {
  content: "\e3f1\e3f1"; }
.fad.fa-clipboard-question::after, .fa-duotone.fa-clipboard-question::after {
  content: "\e4e3\e4e3"; }
.fad.fa-panorama::after, .fa-duotone.fa-panorama::after {
  content: "\e209\e209"; }
.fad.fa-comment-medical::after, .fa-duotone.fa-comment-medical::after {
  content: "\f7f5\f7f5"; }
.fad.fa-teeth-open::after, .fa-duotone.fa-teeth-open::after {
  content: "\f62f\f62f"; }
.fad.fa-user-tie-hair-long::after, .fa-duotone.fa-user-tie-hair-long::after {
  content: "\e460\e460"; }
.fad.fa-file-circle-minus::after, .fa-duotone.fa-file-circle-minus::after {
  content: "\e4ed\e4ed"; }
.fad.fa-head-side-medical::after, .fa-duotone.fa-head-side-medical::after {
  content: "\f809\f809"; }
.fad.fa-tags::after, .fa-duotone.fa-tags::after {
  content: "\f02c\f02c"; }
.fad.fa-wine-glass::after, .fa-duotone.fa-wine-glass::after {
  content: "\f4e3\f4e3"; }
.fad.fa-forward-fast::after, .fa-duotone.fa-forward-fast::after {
  content: "\f050\f050"; }
.fad.fa-fast-forward::after, .fa-duotone.fa-fast-forward::after {
  content: "\f050\f050"; }
.fad.fa-face-meh-blank::after, .fa-duotone.fa-face-meh-blank::after {
  content: "\f5a4\f5a4"; }
.fad.fa-meh-blank::after, .fa-duotone.fa-meh-blank::after {
  content: "\f5a4\f5a4"; }
.fad.fa-user-robot::after, .fa-duotone.fa-user-robot::after {
  content: "\e04b\e04b"; }
.fad.fa-square-parking::after, .fa-duotone.fa-square-parking::after {
  content: "\f540\f540"; }
.fad.fa-parking::after, .fa-duotone.fa-parking::after {
  content: "\f540\f540"; }
.fad.fa-card-diamond::after, .fa-duotone.fa-card-diamond::after {
  content: "\e3ea\e3ea"; }
.fad.fa-face-zipper::after, .fa-duotone.fa-face-zipper::after {
  content: "\e3a5\e3a5"; }
.fad.fa-face-raised-eyebrow::after, .fa-duotone.fa-face-raised-eyebrow::after {
  content: "\e388\e388"; }
.fad.fa-house-signal::after, .fa-duotone.fa-house-signal::after {
  content: "\e012\e012"; }
.fad.fa-square-chevron-up::after, .fa-duotone.fa-square-chevron-up::after {
  content: "\f32c\f32c"; }
.fad.fa-chevron-square-up::after, .fa-duotone.fa-chevron-square-up::after {
  content: "\f32c\f32c"; }
.fad.fa-bars-progress::after, .fa-duotone.fa-bars-progress::after {
  content: "\f828\f828"; }
.fad.fa-tasks-alt::after, .fa-duotone.fa-tasks-alt::after {
  content: "\f828\f828"; }
.fad.fa-faucet-drip::after, .fa-duotone.fa-faucet-drip::after {
  content: "\e006\e006"; }
.fad.fa-arrows-to-line::after, .fa-duotone.fa-arrows-to-line::after {
  content: "\e0a7\e0a7"; }
.fad.fa-dolphin::after, .fa-duotone.fa-dolphin::after {
  content: "\e168\e168"; }
.fad.fa-arrow-up-right::after, .fa-duotone.fa-arrow-up-right::after {
  content: "\e09f\e09f"; }
.fad.fa-circle-r::after, .fa-duotone.fa-circle-r::after {
  content: "\e120\e120"; }
.fad.fa-cart-flatbed::after, .fa-duotone.fa-cart-flatbed::after {
  content: "\f474\f474"; }
.fad.fa-dolly-flatbed::after, .fa-duotone.fa-dolly-flatbed::after {
  content: "\f474\f474"; }
.fad.fa-ban-smoking::after, .fa-duotone.fa-ban-smoking::after {
  content: "\f54d\f54d"; }
.fad.fa-smoking-ban::after, .fa-duotone.fa-smoking-ban::after {
  content: "\f54d\f54d"; }
.fad.fa-circle-sort-up::after, .fa-duotone.fa-circle-sort-up::after {
  content: "\e032\e032"; }
.fad.fa-sort-circle-up::after, .fa-duotone.fa-sort-circle-up::after {
  content: "\e032\e032"; }
.fad.fa-terminal::after, .fa-duotone.fa-terminal::after {
  content: "\f120\f120"; }
.fad.fa-mobile-button::after, .fa-duotone.fa-mobile-button::after {
  content: "\f10b\f10b"; }
.fad.fa-house-medical-flag::after, .fa-duotone.fa-house-medical-flag::after {
  content: "\e514\e514"; }
.fad.fa-basket-shopping::after, .fa-duotone.fa-basket-shopping::after {
  content: "\f291\f291"; }
.fad.fa-shopping-basket::after, .fa-duotone.fa-shopping-basket::after {
  content: "\f291\f291"; }
.fad.fa-tape::after, .fa-duotone.fa-tape::after {
  content: "\f4db\f4db"; }
.fad.fa-chestnut::after, .fa-duotone.fa-chestnut::after {
  content: "\e3f6\e3f6"; }
.fad.fa-bus-simple::after, .fa-duotone.fa-bus-simple::after {
  content: "\f55e\f55e"; }
.fad.fa-bus-alt::after, .fa-duotone.fa-bus-alt::after {
  content: "\f55e\f55e"; }
.fad.fa-eye::after, .fa-duotone.fa-eye::after {
  content: "\f06e\f06e"; }
.fad.fa-face-sad-cry::after, .fa-duotone.fa-face-sad-cry::after {
  content: "\f5b3\f5b3"; }
.fad.fa-sad-cry::after, .fa-duotone.fa-sad-cry::after {
  content: "\f5b3\f5b3"; }
.fad.fa-heat::after, .fa-duotone.fa-heat::after {
  content: "\e00c\e00c"; }
.fad.fa-ticket-airline::after, .fa-duotone.fa-ticket-airline::after {
  content: "\e29a\e29a"; }
.fad.fa-boot-heeled::after, .fa-duotone.fa-boot-heeled::after {
  content: "\e33f\e33f"; }
.fad.fa-arrows-minimize::after, .fa-duotone.fa-arrows-minimize::after {
  content: "\e0a5\e0a5"; }
.fad.fa-compress-arrows::after, .fa-duotone.fa-compress-arrows::after {
  content: "\e0a5\e0a5"; }
.fad.fa-audio-description::after, .fa-duotone.fa-audio-description::after {
  content: "\f29e\f29e"; }
.fad.fa-person-military-to-person::after, .fa-duotone.fa-person-military-to-person::after {
  content: "\e54c\e54c"; }
.fad.fa-file-shield::after, .fa-duotone.fa-file-shield::after {
  content: "\e4f0\e4f0"; }
.fad.fa-hexagon::after, .fa-duotone.fa-hexagon::after {
  content: "\f312\f312"; }
.fad.fa-manhole::after, .fa-duotone.fa-manhole::after {
  content: "\e1d6\e1d6"; }
.fad.fa-user-slash::after, .fa-duotone.fa-user-slash::after {
  content: "\f506\f506"; }
.fad.fa-pen::after, .fa-duotone.fa-pen::after {
  content: "\f304\f304"; }
.fad.fa-tower-observation::after, .fa-duotone.fa-tower-observation::after {
  content: "\e586\e586"; }
.fad.fa-floppy-disks::after, .fa-duotone.fa-floppy-disks::after {
  content: "\e183\e183"; }
.fad.fa-toilet-paper-blank-under::after, .fa-duotone.fa-toilet-paper-blank-under::after {
  content: "\e29f\e29f"; }
.fad.fa-toilet-paper-reverse-alt::after, .fa-duotone.fa-toilet-paper-reverse-alt::after {
  content: "\e29f\e29f"; }
.fad.fa-file-code::after, .fa-duotone.fa-file-code::after {
  content: "\f1c9\f1c9"; }
.fad.fa-signal::after, .fa-duotone.fa-signal::after {
  content: "\f012\f012"; }
.fad.fa-signal-5::after, .fa-duotone.fa-signal-5::after {
  content: "\f012\f012"; }
.fad.fa-signal-perfect::after, .fa-duotone.fa-signal-perfect::after {
  content: "\f012\f012"; }
.fad.fa-pump::after, .fa-duotone.fa-pump::after {
  content: "\e442\e442"; }
.fad.fa-bus::after, .fa-duotone.fa-bus::after {
  content: "\f207\f207"; }
.fad.fa-heart-circle-xmark::after, .fa-duotone.fa-heart-circle-xmark::after {
  content: "\e501\e501"; }
.fad.fa-arrow-up-left-from-circle::after, .fa-duotone.fa-arrow-up-left-from-circle::after {
  content: "\e09e\e09e"; }
.fad.fa-house-chimney::after, .fa-duotone.fa-house-chimney::after {
  content: "\e3af\e3af"; }
.fad.fa-home-lg::after, .fa-duotone.fa-home-lg::after {
  content: "\e3af\e3af"; }
.fad.fa-window-maximize::after, .fa-duotone.fa-window-maximize::after {
  content: "\f2d0\f2d0"; }
.fad.fa-dryer::after, .fa-duotone.fa-dryer::after {
  content: "\f861\f861"; }
.fad.fa-face-frown::after, .fa-duotone.fa-face-frown::after {
  content: "\f119\f119"; }
.fad.fa-frown::after, .fa-duotone.fa-frown::after {
  content: "\f119\f119"; }
.fad.fa-chess-bishop-piece::after, .fa-duotone.fa-chess-bishop-piece::after {
  content: "\f43b\f43b"; }
.fad.fa-chess-bishop-alt::after, .fa-duotone.fa-chess-bishop-alt::after {
  content: "\f43b\f43b"; }
.fad.fa-shirt-tank-top::after, .fa-duotone.fa-shirt-tank-top::after {
  content: "\e3c9\e3c9"; }
.fad.fa-diploma::after, .fa-duotone.fa-diploma::after {
  content: "\f5ea\f5ea"; }
.fad.fa-scroll-ribbon::after, .fa-duotone.fa-scroll-ribbon::after {
  content: "\f5ea\f5ea"; }
.fad.fa-screencast::after, .fa-duotone.fa-screencast::after {
  content: "\e23e\e23e"; }
.fad.fa-walker::after, .fa-duotone.fa-walker::after {
  content: "\f831\f831"; }
.fad.fa-prescription::after, .fa-duotone.fa-prescription::after {
  content: "\f5b1\f5b1"; }
.fad.fa-shop::after, .fa-duotone.fa-shop::after {
  content: "\f54f\f54f"; }
.fad.fa-store-alt::after, .fa-duotone.fa-store-alt::after {
  content: "\f54f\f54f"; }
.fad.fa-floppy-disk::after, .fa-duotone.fa-floppy-disk::after {
  content: "\f0c7\f0c7"; }
.fad.fa-save::after, .fa-duotone.fa-save::after {
  content: "\f0c7\f0c7"; }
.fad.fa-vihara::after, .fa-duotone.fa-vihara::after {
  content: "\f6a7\f6a7"; }
.fad.fa-face-kiss-closed-eyes::after, .fa-duotone.fa-face-kiss-closed-eyes::after {
  content: "\e37d\e37d"; }
.fad.fa-scale-unbalanced::after, .fa-duotone.fa-scale-unbalanced::after {
  content: "\f515\f515"; }
.fad.fa-balance-scale-left::after, .fa-duotone.fa-balance-scale-left::after {
  content: "\f515\f515"; }
.fad.fa-file-user::after, .fa-duotone.fa-file-user::after {
  content: "\f65c\f65c"; }
.fad.fa-user-police-tie::after, .fa-duotone.fa-user-police-tie::after {
  content: "\e334\e334"; }
.fad.fa-face-tongue-money::after, .fa-duotone.fa-face-tongue-money::after {
  content: "\e39d\e39d"; }
.fad.fa-tennis-ball::after, .fa-duotone.fa-tennis-ball::after {
  content: "\f45e\f45e"; }
.fad.fa-square-l::after, .fa-duotone.fa-square-l::after {
  content: "\e275\e275"; }
.fad.fa-sort-up::after, .fa-duotone.fa-sort-up::after {
  content: "\f0de\f0de"; }
.fad.fa-sort-asc::after, .fa-duotone.fa-sort-asc::after {
  content: "\f0de\f0de"; }
.fad.fa-calendar-arrow-up::after, .fa-duotone.fa-calendar-arrow-up::after {
  content: "\e0d1\e0d1"; }
.fad.fa-calendar-upload::after, .fa-duotone.fa-calendar-upload::after {
  content: "\e0d1\e0d1"; }
.fad.fa-comment-dots::after, .fa-duotone.fa-comment-dots::after {
  content: "\f4ad\f4ad"; }
.fad.fa-commenting::after, .fa-duotone.fa-commenting::after {
  content: "\f4ad\f4ad"; }
.fad.fa-plant-wilt::after, .fa-duotone.fa-plant-wilt::after {
  content: "\e5aa\e5aa"; }
.fad.fa-scarf::after, .fa-duotone.fa-scarf::after {
  content: "\f7c1\f7c1"; }
.fad.fa-album-circle-plus::after, .fa-duotone.fa-album-circle-plus::after {
  content: "\e48c\e48c"; }
.fad.fa-user-nurse-hair-long::after, .fa-duotone.fa-user-nurse-hair-long::after {
  content: "\e45e\e45e"; }
.fad.fa-diamond::after, .fa-duotone.fa-diamond::after {
  content: "\f219\f219"; }
.fad.fa-square-left::after, .fa-duotone.fa-square-left::after {
  content: "\f351\f351"; }
.fad.fa-arrow-alt-square-left::after, .fa-duotone.fa-arrow-alt-square-left::after {
  content: "\f351\f351"; }
.fad.fa-face-grin-squint::after, .fa-duotone.fa-face-grin-squint::after {
  content: "\f585\f585"; }
.fad.fa-grin-squint::after, .fa-duotone.fa-grin-squint::after {
  content: "\f585\f585"; }
.fad.fa-circle-ellipsis-vertical::after, .fa-duotone.fa-circle-ellipsis-vertical::after {
  content: "\e10b\e10b"; }
.fad.fa-hand-holding-dollar::after, .fa-duotone.fa-hand-holding-dollar::after {
  content: "\f4c0\f4c0"; }
.fad.fa-hand-holding-usd::after, .fa-duotone.fa-hand-holding-usd::after {
  content: "\f4c0\f4c0"; }
.fad.fa-grid-dividers::after, .fa-duotone.fa-grid-dividers::after {
  content: "\e3ad\e3ad"; }
.fad.fa-bacterium::after, .fa-duotone.fa-bacterium::after {
  content: "\e05a\e05a"; }
.fad.fa-hand-pointer::after, .fa-duotone.fa-hand-pointer::after {
  content: "\f25a\f25a"; }
.fad.fa-drum-steelpan::after, .fa-duotone.fa-drum-steelpan::after {
  content: "\f56a\f56a"; }
.fad.fa-hand-scissors::after, .fa-duotone.fa-hand-scissors::after {
  content: "\f257\f257"; }
.fad.fa-hands-praying::after, .fa-duotone.fa-hands-praying::after {
  content: "\f684\f684"; }
.fad.fa-praying-hands::after, .fa-duotone.fa-praying-hands::after {
  content: "\f684\f684"; }
.fad.fa-face-pensive::after, .fa-duotone.fa-face-pensive::after {
  content: "\e384\e384"; }
.fad.fa-user-music::after, .fa-duotone.fa-user-music::after {
  content: "\f8eb\f8eb"; }
.fad.fa-arrow-rotate-right::after, .fa-duotone.fa-arrow-rotate-right::after {
  content: "\f01e\f01e"; }
.fad.fa-arrow-right-rotate::after, .fa-duotone.fa-arrow-right-rotate::after {
  content: "\f01e\f01e"; }
.fad.fa-arrow-rotate-forward::after, .fa-duotone.fa-arrow-rotate-forward::after {
  content: "\f01e\f01e"; }
.fad.fa-redo::after, .fa-duotone.fa-redo::after {
  content: "\f01e\f01e"; }
.fad.fa-messages-dollar::after, .fa-duotone.fa-messages-dollar::after {
  content: "\f652\f652"; }
.fad.fa-comments-alt-dollar::after, .fa-duotone.fa-comments-alt-dollar::after {
  content: "\f652\f652"; }
.fad.fa-sensor-on::after, .fa-duotone.fa-sensor-on::after {
  content: "\e02b\e02b"; }
.fad.fa-balloon::after, .fa-duotone.fa-balloon::after {
  content: "\e2e3\e2e3"; }
.fad.fa-biohazard::after, .fa-duotone.fa-biohazard::after {
  content: "\f780\f780"; }
.fad.fa-chess-queen-piece::after, .fa-duotone.fa-chess-queen-piece::after {
  content: "\f446\f446"; }
.fad.fa-chess-queen-alt::after, .fa-duotone.fa-chess-queen-alt::after {
  content: "\f446\f446"; }
.fad.fa-location-crosshairs::after, .fa-duotone.fa-location-crosshairs::after {
  content: "\f601\f601"; }
.fad.fa-location::after, .fa-duotone.fa-location::after {
  content: "\f601\f601"; }
.fad.fa-mars-double::after, .fa-duotone.fa-mars-double::after {
  content: "\f227\f227"; }
.fad.fa-house-person-leave::after, .fa-duotone.fa-house-person-leave::after {
  content: "\e00f\e00f"; }
.fad.fa-house-leave::after, .fa-duotone.fa-house-leave::after {
  content: "\e00f\e00f"; }
.fad.fa-house-person-depart::after, .fa-duotone.fa-house-person-depart::after {
  content: "\e00f\e00f"; }
.fad.fa-ruler-triangle::after, .fa-duotone.fa-ruler-triangle::after {
  content: "\f61c\f61c"; }
.fad.fa-card-club::after, .fa-duotone.fa-card-club::after {
  content: "\e3e9\e3e9"; }
.fad.fa-child-dress::after, .fa-duotone.fa-child-dress::after {
  content: "\e59c\e59c"; }
.fad.fa-users-between-lines::after, .fa-duotone.fa-users-between-lines::after {
  content: "\e591\e591"; }
.fad.fa-lungs-virus::after, .fa-duotone.fa-lungs-virus::after {
  content: "\e067\e067"; }
.fad.fa-spinner-third::after, .fa-duotone.fa-spinner-third::after {
  content: "\f3f4\f3f4"; }
.fad.fa-face-grin-tears::after, .fa-duotone.fa-face-grin-tears::after {
  content: "\f588\f588"; }
.fad.fa-grin-tears::after, .fa-duotone.fa-grin-tears::after {
  content: "\f588\f588"; }
.fad.fa-phone::after, .fa-duotone.fa-phone::after {
  content: "\f095\f095"; }
.fad.fa-computer-mouse-scrollwheel::after, .fa-duotone.fa-computer-mouse-scrollwheel::after {
  content: "\f8cd\f8cd"; }
.fad.fa-mouse-alt::after, .fa-duotone.fa-mouse-alt::after {
  content: "\f8cd\f8cd"; }
.fad.fa-calendar-xmark::after, .fa-duotone.fa-calendar-xmark::after {
  content: "\f273\f273"; }
.fad.fa-calendar-times::after, .fa-duotone.fa-calendar-times::after {
  content: "\f273\f273"; }
.fad.fa-child-reaching::after, .fa-duotone.fa-child-reaching::after {
  content: "\e59d\e59d"; }
.fad.fa-table-layout::after, .fa-duotone.fa-table-layout::after {
  content: "\e290\e290"; }
.fad.fa-narwhal::after, .fa-duotone.fa-narwhal::after {
  content: "\f6fe\f6fe"; }
.fad.fa-ramp-loading::after, .fa-duotone.fa-ramp-loading::after {
  content: "\f4d4\f4d4"; }
.fad.fa-calendar-circle-plus::after, .fa-duotone.fa-calendar-circle-plus::after {
  content: "\e470\e470"; }
.fad.fa-toothbrush::after, .fa-duotone.fa-toothbrush::after {
  content: "\f635\f635"; }
.fad.fa-border-inner::after, .fa-duotone.fa-border-inner::after {
  content: "\f84e\f84e"; }
.fad.fa-paw-claws::after, .fa-duotone.fa-paw-claws::after {
  content: "\f702\f702"; }
.fad.fa-kiwi-fruit::after, .fa-duotone.fa-kiwi-fruit::after {
  content: "\e30c\e30c"; }
.fad.fa-traffic-light-slow::after, .fa-duotone.fa-traffic-light-slow::after {
  content: "\f639\f639"; }
.fad.fa-rectangle-code::after, .fa-duotone.fa-rectangle-code::after {
  content: "\e322\e322"; }
.fad.fa-head-side-virus::after, .fa-duotone.fa-head-side-virus::after {
  content: "\e064\e064"; }
.fad.fa-keyboard-brightness::after, .fa-duotone.fa-keyboard-brightness::after {
  content: "\e1c0\e1c0"; }
.fad.fa-books-medical::after, .fa-duotone.fa-books-medical::after {
  content: "\f7e8\f7e8"; }
.fad.fa-lightbulb-slash::after, .fa-duotone.fa-lightbulb-slash::after {
  content: "\f673\f673"; }
.fad.fa-house-blank::after, .fa-duotone.fa-house-blank::after {
  content: "\e487\e487"; }
.fad.fa-home-blank::after, .fa-duotone.fa-home-blank::after {
  content: "\e487\e487"; }
.fad.fa-square-5::after, .fa-duotone.fa-square-5::after {
  content: "\e25a\e25a"; }
.fad.fa-square-heart::after, .fa-duotone.fa-square-heart::after {
  content: "\f4c8\f4c8"; }
.fad.fa-heart-square::after, .fa-duotone.fa-heart-square::after {
  content: "\f4c8\f4c8"; }
.fad.fa-puzzle::after, .fa-duotone.fa-puzzle::after {
  content: "\e443\e443"; }
.fad.fa-user-gear::after, .fa-duotone.fa-user-gear::after {
  content: "\f4fe\f4fe"; }
.fad.fa-user-cog::after, .fa-duotone.fa-user-cog::after {
  content: "\f4fe\f4fe"; }
.fad.fa-pipe-circle-check::after, .fa-duotone.fa-pipe-circle-check::after {
  content: "\e436\e436"; }
.fad.fa-arrow-up-1-9::after, .fa-duotone.fa-arrow-up-1-9::after {
  content: "\f163\f163"; }
.fad.fa-sort-numeric-up::after, .fa-duotone.fa-sort-numeric-up::after {
  content: "\f163\f163"; }
.fad.fa-octagon-exclamation::after, .fa-duotone.fa-octagon-exclamation::after {
  content: "\e204\e204"; }
.fad.fa-dial-low::after, .fa-duotone.fa-dial-low::after {
  content: "\e15d\e15d"; }
.fad.fa-door-closed::after, .fa-duotone.fa-door-closed::after {
  content: "\f52a\f52a"; }
.fad.fa-laptop-mobile::after, .fa-duotone.fa-laptop-mobile::after {
  content: "\f87a\f87a"; }
.fad.fa-phone-laptop::after, .fa-duotone.fa-phone-laptop::after {
  content: "\f87a\f87a"; }
.fad.fa-conveyor-belt-boxes::after, .fa-duotone.fa-conveyor-belt-boxes::after {
  content: "\f46f\f46f"; }
.fad.fa-conveyor-belt-alt::after, .fa-duotone.fa-conveyor-belt-alt::after {
  content: "\f46f\f46f"; }
.fad.fa-shield-virus::after, .fa-duotone.fa-shield-virus::after {
  content: "\e06c\e06c"; }
.fad.fa-starfighter-twin-ion-engine-advanced::after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced::after {
  content: "\e28e\e28e"; }
.fad.fa-starfighter-alt-advanced::after, .fa-duotone.fa-starfighter-alt-advanced::after {
  content: "\e28e\e28e"; }
.fad.fa-dice-six::after, .fa-duotone.fa-dice-six::after {
  content: "\f526\f526"; }
.fad.fa-starfighter-twin-ion-engine::after, .fa-duotone.fa-starfighter-twin-ion-engine::after {
  content: "\e038\e038"; }
.fad.fa-starfighter-alt::after, .fa-duotone.fa-starfighter-alt::after {
  content: "\e038\e038"; }
.fad.fa-rocket-launch::after, .fa-duotone.fa-rocket-launch::after {
  content: "\e027\e027"; }
.fad.fa-mosquito-net::after, .fa-duotone.fa-mosquito-net::after {
  content: "\e52c\e52c"; }
.fad.fa-vent-damper::after, .fa-duotone.fa-vent-damper::after {
  content: "\e465\e465"; }
.fad.fa-bridge-water::after, .fa-duotone.fa-bridge-water::after {
  content: "\e4ce\e4ce"; }
.fad.fa-ban-bug::after, .fa-duotone.fa-ban-bug::after {
  content: "\f7f9\f7f9"; }
.fad.fa-debug::after, .fa-duotone.fa-debug::after {
  content: "\f7f9\f7f9"; }
.fad.fa-person-booth::after, .fa-duotone.fa-person-booth::after {
  content: "\f756\f756"; }
.fad.fa-text-width::after, .fa-duotone.fa-text-width::after {
  content: "\f035\f035"; }
.fad.fa-garage-car::after, .fa-duotone.fa-garage-car::after {
  content: "\e00a\e00a"; }
.fad.fa-square-kanban::after, .fa-duotone.fa-square-kanban::after {
  content: "\e488\e488"; }
.fad.fa-hat-wizard::after, .fa-duotone.fa-hat-wizard::after {
  content: "\f6e8\f6e8"; }
.fad.fa-pen-fancy::after, .fa-duotone.fa-pen-fancy::after {
  content: "\f5ac\f5ac"; }
.fad.fa-coffee-pot::after, .fa-duotone.fa-coffee-pot::after {
  content: "\e002\e002"; }
.fad.fa-mouse-field::after, .fa-duotone.fa-mouse-field::after {
  content: "\e5a8\e5a8"; }
.fad.fa-person-digging::after, .fa-duotone.fa-person-digging::after {
  content: "\f85e\f85e"; }
.fad.fa-digging::after, .fa-duotone.fa-digging::after {
  content: "\f85e\f85e"; }
.fad.fa-shower-down::after, .fa-duotone.fa-shower-down::after {
  content: "\e24d\e24d"; }
.fad.fa-shower-alt::after, .fa-duotone.fa-shower-alt::after {
  content: "\e24d\e24d"; }
.fad.fa-box-circle-check::after, .fa-duotone.fa-box-circle-check::after {
  content: "\e0c4\e0c4"; }
.fad.fa-brightness::after, .fa-duotone.fa-brightness::after {
  content: "\e0c9\e0c9"; }
.fad.fa-car-side-bolt::after, .fa-duotone.fa-car-side-bolt::after {
  content: "\e344\e344"; }
.fad.fa-ornament::after, .fa-duotone.fa-ornament::after {
  content: "\f7b8\f7b8"; }
.fad.fa-phone-arrow-down-left::after, .fa-duotone.fa-phone-arrow-down-left::after {
  content: "\e223\e223"; }
.fad.fa-phone-arrow-down::after, .fa-duotone.fa-phone-arrow-down::after {
  content: "\e223\e223"; }
.fad.fa-phone-incoming::after, .fa-duotone.fa-phone-incoming::after {
  content: "\e223\e223"; }
.fad.fa-cloud-word::after, .fa-duotone.fa-cloud-word::after {
  content: "\e138\e138"; }
.fad.fa-hand-fingers-crossed::after, .fa-duotone.fa-hand-fingers-crossed::after {
  content: "\e1a3\e1a3"; }
.fad.fa-trash::after, .fa-duotone.fa-trash::after {
  content: "\f1f8\f1f8"; }
.fad.fa-gauge-simple::after, .fa-duotone.fa-gauge-simple::after {
  content: "\f629\f629"; }
.fad.fa-gauge-simple-med::after, .fa-duotone.fa-gauge-simple-med::after {
  content: "\f629\f629"; }
.fad.fa-tachometer-average::after, .fa-duotone.fa-tachometer-average::after {
  content: "\f629\f629"; }
.fad.fa-arrow-down-small-big::after, .fa-duotone.fa-arrow-down-small-big::after {
  content: "\f88d\f88d"; }
.fad.fa-sort-size-down-alt::after, .fa-duotone.fa-sort-size-down-alt::after {
  content: "\f88d\f88d"; }
.fad.fa-book-medical::after, .fa-duotone.fa-book-medical::after {
  content: "\f7e6\f7e6"; }
.fad.fa-face-melting::after, .fa-duotone.fa-face-melting::after {
  content: "\e483\e483"; }
.fad.fa-poo::after, .fa-duotone.fa-poo::after {
  content: "\f2fe\f2fe"; }
.fad.fa-pen-clip-slash::after, .fa-duotone.fa-pen-clip-slash::after {
  content: "\e20f\e20f"; }
.fad.fa-pen-alt-slash::after, .fa-duotone.fa-pen-alt-slash::after {
  content: "\e20f\e20f"; }
.fad.fa-quote-right::after, .fa-duotone.fa-quote-right::after {
  content: "\f10e\f10e"; }
.fad.fa-quote-right-alt::after, .fa-duotone.fa-quote-right-alt::after {
  content: "\f10e\f10e"; }
.fad.fa-scroll-old::after, .fa-duotone.fa-scroll-old::after {
  content: "\f70f\f70f"; }
.fad.fa-guitars::after, .fa-duotone.fa-guitars::after {
  content: "\f8bf\f8bf"; }
.fad.fa-phone-xmark::after, .fa-duotone.fa-phone-xmark::after {
  content: "\e227\e227"; }
.fad.fa-hose::after, .fa-duotone.fa-hose::after {
  content: "\e419\e419"; }
.fad.fa-clock-six::after, .fa-duotone.fa-clock-six::after {
  content: "\e352\e352"; }
.fad.fa-shirt::after, .fa-duotone.fa-shirt::after {
  content: "\f553\f553"; }
.fad.fa-t-shirt::after, .fa-duotone.fa-t-shirt::after {
  content: "\f553\f553"; }
.fad.fa-tshirt::after, .fa-duotone.fa-tshirt::after {
  content: "\f553\f553"; }
.fad.fa-square-r::after, .fa-duotone.fa-square-r::after {
  content: "\e27c\e27c"; }
.fad.fa-cubes::after, .fa-duotone.fa-cubes::after {
  content: "\f1b3\f1b3"; }
.fad.fa-envelope-open-dollar::after, .fa-duotone.fa-envelope-open-dollar::after {
  content: "\f657\f657"; }
.fad.fa-divide::after, .fa-duotone.fa-divide::after {
  content: "\f529\f529"; }
.fad.fa-sun-cloud::after, .fa-duotone.fa-sun-cloud::after {
  content: "\f763\f763"; }
.fad.fa-lamp-floor::after, .fa-duotone.fa-lamp-floor::after {
  content: "\e015\e015"; }
.fad.fa-square-7::after, .fa-duotone.fa-square-7::after {
  content: "\e25c\e25c"; }
.fad.fa-tenge-sign::after, .fa-duotone.fa-tenge-sign::after {
  content: "\f7d7\f7d7"; }
.fad.fa-tenge::after, .fa-duotone.fa-tenge::after {
  content: "\f7d7\f7d7"; }
.fad.fa-headphones::after, .fa-duotone.fa-headphones::after {
  content: "\f025\f025"; }
.fad.fa-hands-holding::after, .fa-duotone.fa-hands-holding::after {
  content: "\f4c2\f4c2"; }
.fad.fa-campfire::after, .fa-duotone.fa-campfire::after {
  content: "\f6ba\f6ba"; }
.fad.fa-circle-ampersand::after, .fa-duotone.fa-circle-ampersand::after {
  content: "\e0f8\e0f8"; }
.fad.fa-snowflakes::after, .fa-duotone.fa-snowflakes::after {
  content: "\f7cf\f7cf"; }
.fad.fa-hands-clapping::after, .fa-duotone.fa-hands-clapping::after {
  content: "\e1a8\e1a8"; }
.fad.fa-republican::after, .fa-duotone.fa-republican::after {
  content: "\f75e\f75e"; }
.fad.fa-leaf-maple::after, .fa-duotone.fa-leaf-maple::after {
  content: "\f6f6\f6f6"; }
.fad.fa-arrow-left::after, .fa-duotone.fa-arrow-left::after {
  content: "\f060\f060"; }
.fad.fa-person-circle-xmark::after, .fa-duotone.fa-person-circle-xmark::after {
  content: "\e543\e543"; }
.fad.fa-ruler::after, .fa-duotone.fa-ruler::after {
  content: "\f545\f545"; }
.fad.fa-cup-straw-swoosh::after, .fa-duotone.fa-cup-straw-swoosh::after {
  content: "\e364\e364"; }
.fad.fa-temperature-sun::after, .fa-duotone.fa-temperature-sun::after {
  content: "\f76a\f76a"; }
.fad.fa-temperature-hot::after, .fa-duotone.fa-temperature-hot::after {
  content: "\f76a\f76a"; }
.fad.fa-align-left::after, .fa-duotone.fa-align-left::after {
  content: "\f036\f036"; }
.fad.fa-dice-d6::after, .fa-duotone.fa-dice-d6::after {
  content: "\f6d1\f6d1"; }
.fad.fa-restroom::after, .fa-duotone.fa-restroom::after {
  content: "\f7bd\f7bd"; }
.fad.fa-high-definition::after, .fa-duotone.fa-high-definition::after {
  content: "\e1ae\e1ae"; }
.fad.fa-rectangle-hd::after, .fa-duotone.fa-rectangle-hd::after {
  content: "\e1ae\e1ae"; }
.fad.fa-j::after, .fa-duotone.fa-j::after {
  content: "\4a\4a"; }
.fad.fa-galaxy::after, .fa-duotone.fa-galaxy::after {
  content: "\e008\e008"; }
.fad.fa-users-viewfinder::after, .fa-duotone.fa-users-viewfinder::after {
  content: "\e595\e595"; }
.fad.fa-file-video::after, .fa-duotone.fa-file-video::after {
  content: "\f1c8\f1c8"; }
.fad.fa-cherries::after, .fa-duotone.fa-cherries::after {
  content: "\e0ec\e0ec"; }
.fad.fa-up-right-from-square::after, .fa-duotone.fa-up-right-from-square::after {
  content: "\f35d\f35d"; }
.fad.fa-external-link-alt::after, .fa-duotone.fa-external-link-alt::after {
  content: "\f35d\f35d"; }
.fad.fa-circle-sort::after, .fa-duotone.fa-circle-sort::after {
  content: "\e030\e030"; }
.fad.fa-sort-circle::after, .fa-duotone.fa-sort-circle::after {
  content: "\e030\e030"; }
.fad.fa-table-cells::after, .fa-duotone.fa-table-cells::after {
  content: "\f00a\f00a"; }
.fad.fa-th::after, .fa-duotone.fa-th::after {
  content: "\f00a\f00a"; }
.fad.fa-file-pdf::after, .fa-duotone.fa-file-pdf::after {
  content: "\f1c1\f1c1"; }
.fad.fa-siren::after, .fa-duotone.fa-siren::after {
  content: "\e02d\e02d"; }
.fad.fa-arrow-up-to-dotted-line::after, .fa-duotone.fa-arrow-up-to-dotted-line::after {
  content: "\e0a1\e0a1"; }
.fad.fa-image-landscape::after, .fa-duotone.fa-image-landscape::after {
  content: "\e1b5\e1b5"; }
.fad.fa-landscape::after, .fa-duotone.fa-landscape::after {
  content: "\e1b5\e1b5"; }
.fad.fa-tank-water::after, .fa-duotone.fa-tank-water::after {
  content: "\e452\e452"; }
.fad.fa-curling-stone::after, .fa-duotone.fa-curling-stone::after {
  content: "\f44a\f44a"; }
.fad.fa-curling::after, .fa-duotone.fa-curling::after {
  content: "\f44a\f44a"; }
.fad.fa-gamepad-modern::after, .fa-duotone.fa-gamepad-modern::after {
  content: "\e5a2\e5a2"; }
.fad.fa-gamepad-alt::after, .fa-duotone.fa-gamepad-alt::after {
  content: "\e5a2\e5a2"; }
.fad.fa-messages-question::after, .fa-duotone.fa-messages-question::after {
  content: "\e1e7\e1e7"; }
.fad.fa-book-bible::after, .fa-duotone.fa-book-bible::after {
  content: "\f647\f647"; }
.fad.fa-bible::after, .fa-duotone.fa-bible::after {
  content: "\f647\f647"; }
.fad.fa-o::after, .fa-duotone.fa-o::after {
  content: "\4f\4f"; }
.fad.fa-suitcase-medical::after, .fa-duotone.fa-suitcase-medical::after {
  content: "\f0fa\f0fa"; }
.fad.fa-medkit::after, .fa-duotone.fa-medkit::after {
  content: "\f0fa\f0fa"; }
.fad.fa-briefcase-arrow-right::after, .fa-duotone.fa-briefcase-arrow-right::after {
  content: "\e2f2\e2f2"; }
.fad.fa-expand-wide::after, .fa-duotone.fa-expand-wide::after {
  content: "\f320\f320"; }
.fad.fa-clock-eleven-thirty::after, .fa-duotone.fa-clock-eleven-thirty::after {
  content: "\e348\e348"; }
.fad.fa-rv::after, .fa-duotone.fa-rv::after {
  content: "\f7be\f7be"; }
.fad.fa-user-secret::after, .fa-duotone.fa-user-secret::after {
  content: "\f21b\f21b"; }
.fad.fa-otter::after, .fa-duotone.fa-otter::after {
  content: "\f700\f700"; }
.fad.fa-dreidel::after, .fa-duotone.fa-dreidel::after {
  content: "\f792\f792"; }
.fad.fa-person-dress::after, .fa-duotone.fa-person-dress::after {
  content: "\f182\f182"; }
.fad.fa-female::after, .fa-duotone.fa-female::after {
  content: "\f182\f182"; }
.fad.fa-comment-dollar::after, .fa-duotone.fa-comment-dollar::after {
  content: "\f651\f651"; }
.fad.fa-business-time::after, .fa-duotone.fa-business-time::after {
  content: "\f64a\f64a"; }
.fad.fa-briefcase-clock::after, .fa-duotone.fa-briefcase-clock::after {
  content: "\f64a\f64a"; }
.fad.fa-flower-tulip::after, .fa-duotone.fa-flower-tulip::after {
  content: "\f801\f801"; }
.fad.fa-people-pants-simple::after, .fa-duotone.fa-people-pants-simple::after {
  content: "\e21a\e21a"; }
.fad.fa-cloud-drizzle::after, .fa-duotone.fa-cloud-drizzle::after {
  content: "\f738\f738"; }
.fad.fa-table-cells-large::after, .fa-duotone.fa-table-cells-large::after {
  content: "\f009\f009"; }
.fad.fa-th-large::after, .fa-duotone.fa-th-large::after {
  content: "\f009\f009"; }
.fad.fa-book-tanakh::after, .fa-duotone.fa-book-tanakh::after {
  content: "\f827\f827"; }
.fad.fa-tanakh::after, .fa-duotone.fa-tanakh::after {
  content: "\f827\f827"; }
.fad.fa-solar-system::after, .fa-duotone.fa-solar-system::after {
  content: "\e02f\e02f"; }
.fad.fa-seal-question::after, .fa-duotone.fa-seal-question::after {
  content: "\e243\e243"; }
.fad.fa-phone-volume::after, .fa-duotone.fa-phone-volume::after {
  content: "\f2a0\f2a0"; }
.fad.fa-volume-control-phone::after, .fa-duotone.fa-volume-control-phone::after {
  content: "\f2a0\f2a0"; }
.fad.fa-disc-drive::after, .fa-duotone.fa-disc-drive::after {
  content: "\f8b5\f8b5"; }
.fad.fa-hat-cowboy-side::after, .fa-duotone.fa-hat-cowboy-side::after {
  content: "\f8c1\f8c1"; }
.fad.fa-table-rows::after, .fa-duotone.fa-table-rows::after {
  content: "\e292\e292"; }
.fad.fa-rows::after, .fa-duotone.fa-rows::after {
  content: "\e292\e292"; }
.fad.fa-location-exclamation::after, .fa-duotone.fa-location-exclamation::after {
  content: "\f608\f608"; }
.fad.fa-map-marker-exclamation::after, .fa-duotone.fa-map-marker-exclamation::after {
  content: "\f608\f608"; }
.fad.fa-face-fearful::after, .fa-duotone.fa-face-fearful::after {
  content: "\e375\e375"; }
.fad.fa-clipboard-user::after, .fa-duotone.fa-clipboard-user::after {
  content: "\f7f3\f7f3"; }
.fad.fa-bus-school::after, .fa-duotone.fa-bus-school::after {
  content: "\f5dd\f5dd"; }
.fad.fa-film-slash::after, .fa-duotone.fa-film-slash::after {
  content: "\e179\e179"; }
.fad.fa-square-arrow-down-right::after, .fa-duotone.fa-square-arrow-down-right::after {
  content: "\e262\e262"; }
.fad.fa-book-sparkles::after, .fa-duotone.fa-book-sparkles::after {
  content: "\f6b8\f6b8"; }
.fad.fa-book-spells::after, .fa-duotone.fa-book-spells::after {
  content: "\f6b8\f6b8"; }
.fad.fa-washing-machine::after, .fa-duotone.fa-washing-machine::after {
  content: "\f898\f898"; }
.fad.fa-washer::after, .fa-duotone.fa-washer::after {
  content: "\f898\f898"; }
.fad.fa-child::after, .fa-duotone.fa-child::after {
  content: "\f1ae\f1ae"; }
.fad.fa-lira-sign::after, .fa-duotone.fa-lira-sign::after {
  content: "\f195\f195"; }
.fad.fa-user-visor::after, .fa-duotone.fa-user-visor::after {
  content: "\e04c\e04c"; }
.fad.fa-file-plus-minus::after, .fa-duotone.fa-file-plus-minus::after {
  content: "\e177\e177"; }
.fad.fa-chess-clock-flip::after, .fa-duotone.fa-chess-clock-flip::after {
  content: "\f43e\f43e"; }
.fad.fa-chess-clock-alt::after, .fa-duotone.fa-chess-clock-alt::after {
  content: "\f43e\f43e"; }
.fad.fa-satellite::after, .fa-duotone.fa-satellite::after {
  content: "\f7bf\f7bf"; }
.fad.fa-plane-lock::after, .fa-duotone.fa-plane-lock::after {
  content: "\e558\e558"; }
.fad.fa-steering-wheel::after, .fa-duotone.fa-steering-wheel::after {
  content: "\f622\f622"; }
.fad.fa-tag::after, .fa-duotone.fa-tag::after {
  content: "\f02b\f02b"; }
.fad.fa-stretcher::after, .fa-duotone.fa-stretcher::after {
  content: "\f825\f825"; }
.fad.fa-book-section::after, .fa-duotone.fa-book-section::after {
  content: "\e0c1\e0c1"; }
.fad.fa-book-law::after, .fa-duotone.fa-book-law::after {
  content: "\e0c1\e0c1"; }
.fad.fa-inboxes::after, .fa-duotone.fa-inboxes::after {
  content: "\e1bb\e1bb"; }
.fad.fa-coffee-bean::after, .fa-duotone.fa-coffee-bean::after {
  content: "\e13e\e13e"; }
.fad.fa-brackets-curly::after, .fa-duotone.fa-brackets-curly::after {
  content: "\f7ea\f7ea"; }
.fad.fa-ellipsis-stroke-vertical::after, .fa-duotone.fa-ellipsis-stroke-vertical::after {
  content: "\f39c\f39c"; }
.fad.fa-ellipsis-v-alt::after, .fa-duotone.fa-ellipsis-v-alt::after {
  content: "\f39c\f39c"; }
.fad.fa-comment::after, .fa-duotone.fa-comment::after {
  content: "\f075\f075"; }
.fad.fa-square-1::after, .fa-duotone.fa-square-1::after {
  content: "\e256\e256"; }
.fad.fa-cake-candles::after, .fa-duotone.fa-cake-candles::after {
  content: "\f1fd\f1fd"; }
.fad.fa-birthday-cake::after, .fa-duotone.fa-birthday-cake::after {
  content: "\f1fd\f1fd"; }
.fad.fa-cake::after, .fa-duotone.fa-cake::after {
  content: "\f1fd\f1fd"; }
.fad.fa-head-side::after, .fa-duotone.fa-head-side::after {
  content: "\f6e9\f6e9"; }
.fad.fa-envelope::after, .fa-duotone.fa-envelope::after {
  content: "\f0e0\f0e0"; }
.fad.fa-dolly-empty::after, .fa-duotone.fa-dolly-empty::after {
  content: "\f473\f473"; }
.fad.fa-face-tissue::after, .fa-duotone.fa-face-tissue::after {
  content: "\e39c\e39c"; }
.fad.fa-angles-up::after, .fa-duotone.fa-angles-up::after {
  content: "\f102\f102"; }
.fad.fa-angle-double-up::after, .fa-duotone.fa-angle-double-up::after {
  content: "\f102\f102"; }
.fad.fa-paperclip::after, .fa-duotone.fa-paperclip::after {
  content: "\f0c6\f0c6"; }
.fad.fa-chart-line-down::after, .fa-duotone.fa-chart-line-down::after {
  content: "\f64d\f64d"; }
.fad.fa-arrow-right-to-city::after, .fa-duotone.fa-arrow-right-to-city::after {
  content: "\e4b3\e4b3"; }
.fad.fa-lock-a::after, .fa-duotone.fa-lock-a::after {
  content: "\e422\e422"; }
.fad.fa-ribbon::after, .fa-duotone.fa-ribbon::after {
  content: "\f4d6\f4d6"; }
.fad.fa-lungs::after, .fa-duotone.fa-lungs::after {
  content: "\f604\f604"; }
.fad.fa-person-pinball::after, .fa-duotone.fa-person-pinball::after {
  content: "\e21d\e21d"; }
.fad.fa-arrow-up-9-1::after, .fa-duotone.fa-arrow-up-9-1::after {
  content: "\f887\f887"; }
.fad.fa-sort-numeric-up-alt::after, .fa-duotone.fa-sort-numeric-up-alt::after {
  content: "\f887\f887"; }
.fad.fa-apple-core::after, .fa-duotone.fa-apple-core::after {
  content: "\e08f\e08f"; }
.fad.fa-circle-y::after, .fa-duotone.fa-circle-y::after {
  content: "\e12f\e12f"; }
.fad.fa-h6::after, .fa-duotone.fa-h6::after {
  content: "\e413\e413"; }
.fad.fa-litecoin-sign::after, .fa-duotone.fa-litecoin-sign::after {
  content: "\e1d3\e1d3"; }
.fad.fa-circle-small::after, .fa-duotone.fa-circle-small::after {
  content: "\e122\e122"; }
.fad.fa-border-none::after, .fa-duotone.fa-border-none::after {
  content: "\f850\f850"; }
.fad.fa-arrow-turn-down-left::after, .fa-duotone.fa-arrow-turn-down-left::after {
  content: "\e2e1\e2e1"; }
.fad.fa-circle-nodes::after, .fa-duotone.fa-circle-nodes::after {
  content: "\e4e2\e4e2"; }
.fad.fa-parachute-box::after, .fa-duotone.fa-parachute-box::after {
  content: "\f4cd\f4cd"; }
.fad.fa-message-medical::after, .fa-duotone.fa-message-medical::after {
  content: "\f7f4\f7f4"; }
.fad.fa-comment-alt-medical::after, .fa-duotone.fa-comment-alt-medical::after {
  content: "\f7f4\f7f4"; }
.fad.fa-rugby-ball::after, .fa-duotone.fa-rugby-ball::after {
  content: "\e3c6\e3c6"; }
.fad.fa-comment-music::after, .fa-duotone.fa-comment-music::after {
  content: "\f8b0\f8b0"; }
.fad.fa-indent::after, .fa-duotone.fa-indent::after {
  content: "\f03c\f03c"; }
.fad.fa-tree-deciduous::after, .fa-duotone.fa-tree-deciduous::after {
  content: "\f400\f400"; }
.fad.fa-tree-alt::after, .fa-duotone.fa-tree-alt::after {
  content: "\f400\f400"; }
.fad.fa-puzzle-piece-simple::after, .fa-duotone.fa-puzzle-piece-simple::after {
  content: "\e231\e231"; }
.fad.fa-puzzle-piece-alt::after, .fa-duotone.fa-puzzle-piece-alt::after {
  content: "\e231\e231"; }
.fad.fa-truck-field-un::after, .fa-duotone.fa-truck-field-un::after {
  content: "\e58e\e58e"; }
.fad.fa-nfc-trash::after, .fa-duotone.fa-nfc-trash::after {
  content: "\e1fd\e1fd"; }
.fad.fa-hourglass::after, .fa-duotone.fa-hourglass::after {
  content: "\f254\f254"; }
.fad.fa-hourglass-empty::after, .fa-duotone.fa-hourglass-empty::after {
  content: "\f254\f254"; }
.fad.fa-mountain::after, .fa-duotone.fa-mountain::after {
  content: "\f6fc\f6fc"; }
.fad.fa-file-xmark::after, .fa-duotone.fa-file-xmark::after {
  content: "\f317\f317"; }
.fad.fa-file-times::after, .fa-duotone.fa-file-times::after {
  content: "\f317\f317"; }
.fad.fa-house-heart::after, .fa-duotone.fa-house-heart::after {
  content: "\f4c9\f4c9"; }
.fad.fa-home-heart::after, .fa-duotone.fa-home-heart::after {
  content: "\f4c9\f4c9"; }
.fad.fa-house-chimney-blank::after, .fa-duotone.fa-house-chimney-blank::after {
  content: "\e3b0\e3b0"; }
.fad.fa-meter-bolt::after, .fa-duotone.fa-meter-bolt::after {
  content: "\e1e9\e1e9"; }
.fad.fa-user-doctor::after, .fa-duotone.fa-user-doctor::after {
  content: "\f0f0\f0f0"; }
.fad.fa-user-md::after, .fa-duotone.fa-user-md::after {
  content: "\f0f0\f0f0"; }
.fad.fa-slash-back::after, .fa-duotone.fa-slash-back::after {
  content: "\5c\5c"; }
.fad.fa-circle-info::after, .fa-duotone.fa-circle-info::after {
  content: "\f05a\f05a"; }
.fad.fa-info-circle::after, .fa-duotone.fa-info-circle::after {
  content: "\f05a\f05a"; }
.fad.fa-fishing-rod::after, .fa-duotone.fa-fishing-rod::after {
  content: "\e3a8\e3a8"; }
.fad.fa-hammer-crash::after, .fa-duotone.fa-hammer-crash::after {
  content: "\e414\e414"; }
.fad.fa-message-heart::after, .fa-duotone.fa-message-heart::after {
  content: "\e5c9\e5c9"; }
.fad.fa-cloud-meatball::after, .fa-duotone.fa-cloud-meatball::after {
  content: "\f73b\f73b"; }
.fad.fa-camera-polaroid::after, .fa-duotone.fa-camera-polaroid::after {
  content: "\f8aa\f8aa"; }
.fad.fa-camera::after, .fa-duotone.fa-camera::after {
  content: "\f030\f030"; }
.fad.fa-camera-alt::after, .fa-duotone.fa-camera-alt::after {
  content: "\f030\f030"; }
.fad.fa-square-virus::after, .fa-duotone.fa-square-virus::after {
  content: "\e578\e578"; }
.fad.fa-cart-arrow-up::after, .fa-duotone.fa-cart-arrow-up::after {
  content: "\e3ee\e3ee"; }
.fad.fa-meteor::after, .fa-duotone.fa-meteor::after {
  content: "\f753\f753"; }
.fad.fa-car-on::after, .fa-duotone.fa-car-on::after {
  content: "\e4dd\e4dd"; }
.fad.fa-sleigh::after, .fa-duotone.fa-sleigh::after {
  content: "\f7cc\f7cc"; }
.fad.fa-arrow-down-1-9::after, .fa-duotone.fa-arrow-down-1-9::after {
  content: "\f162\f162"; }
.fad.fa-sort-numeric-asc::after, .fa-duotone.fa-sort-numeric-asc::after {
  content: "\f162\f162"; }
.fad.fa-sort-numeric-down::after, .fa-duotone.fa-sort-numeric-down::after {
  content: "\f162\f162"; }
.fad.fa-buoy-mooring::after, .fa-duotone.fa-buoy-mooring::after {
  content: "\e5b6\e5b6"; }
.fad.fa-square-4::after, .fa-duotone.fa-square-4::after {
  content: "\e259\e259"; }
.fad.fa-hand-holding-droplet::after, .fa-duotone.fa-hand-holding-droplet::after {
  content: "\f4c1\f4c1"; }
.fad.fa-hand-holding-water::after, .fa-duotone.fa-hand-holding-water::after {
  content: "\f4c1\f4c1"; }
.fad.fa-tricycle-adult::after, .fa-duotone.fa-tricycle-adult::after {
  content: "\e5c4\e5c4"; }
.fad.fa-waveform::after, .fa-duotone.fa-waveform::after {
  content: "\f8f1\f8f1"; }
.fad.fa-water::after, .fa-duotone.fa-water::after {
  content: "\f773\f773"; }
.fad.fa-star-sharp-half-stroke::after, .fa-duotone.fa-star-sharp-half-stroke::after {
  content: "\e28d\e28d"; }
.fad.fa-star-sharp-half-alt::after, .fa-duotone.fa-star-sharp-half-alt::after {
  content: "\e28d\e28d"; }
.fad.fa-nfc-signal::after, .fa-duotone.fa-nfc-signal::after {
  content: "\e1fb\e1fb"; }
.fad.fa-plane-prop::after, .fa-duotone.fa-plane-prop::after {
  content: "\e22b\e22b"; }
.fad.fa-calendar-check::after, .fa-duotone.fa-calendar-check::after {
  content: "\f274\f274"; }
.fad.fa-clock-desk::after, .fa-duotone.fa-clock-desk::after {
  content: "\e134\e134"; }
.fad.fa-calendar-clock::after, .fa-duotone.fa-calendar-clock::after {
  content: "\e0d2\e0d2"; }
.fad.fa-calendar-time::after, .fa-duotone.fa-calendar-time::after {
  content: "\e0d2\e0d2"; }
.fad.fa-braille::after, .fa-duotone.fa-braille::after {
  content: "\f2a1\f2a1"; }
.fad.fa-prescription-bottle-medical::after, .fa-duotone.fa-prescription-bottle-medical::after {
  content: "\f486\f486"; }
.fad.fa-prescription-bottle-alt::after, .fa-duotone.fa-prescription-bottle-alt::after {
  content: "\f486\f486"; }
.fad.fa-plate-utensils::after, .fa-duotone.fa-plate-utensils::after {
  content: "\e43b\e43b"; }
.fad.fa-family-pants::after, .fa-duotone.fa-family-pants::after {
  content: "\e302\e302"; }
.fad.fa-hose-reel::after, .fa-duotone.fa-hose-reel::after {
  content: "\e41a\e41a"; }
.fad.fa-house-window::after, .fa-duotone.fa-house-window::after {
  content: "\e3b3\e3b3"; }
.fad.fa-landmark::after, .fa-duotone.fa-landmark::after {
  content: "\f66f\f66f"; }
.fad.fa-truck::after, .fa-duotone.fa-truck::after {
  content: "\f0d1\f0d1"; }
.fad.fa-crosshairs::after, .fa-duotone.fa-crosshairs::after {
  content: "\f05b\f05b"; }
.fad.fa-cloud-rainbow::after, .fa-duotone.fa-cloud-rainbow::after {
  content: "\f73e\f73e"; }
.fad.fa-person-cane::after, .fa-duotone.fa-person-cane::after {
  content: "\e53c\e53c"; }
.fad.fa-alien::after, .fa-duotone.fa-alien::after {
  content: "\f8f5\f8f5"; }
.fad.fa-tent::after, .fa-duotone.fa-tent::after {
  content: "\e57d\e57d"; }
.fad.fa-vest-patches::after, .fa-duotone.fa-vest-patches::after {
  content: "\e086\e086"; }
.fad.fa-people-dress-simple::after, .fa-duotone.fa-people-dress-simple::after {
  content: "\e218\e218"; }
.fad.fa-check-double::after, .fa-duotone.fa-check-double::after {
  content: "\f560\f560"; }
.fad.fa-arrow-down-a-z::after, .fa-duotone.fa-arrow-down-a-z::after {
  content: "\f15d\f15d"; }
.fad.fa-sort-alpha-asc::after, .fa-duotone.fa-sort-alpha-asc::after {
  content: "\f15d\f15d"; }
.fad.fa-sort-alpha-down::after, .fa-duotone.fa-sort-alpha-down::after {
  content: "\f15d\f15d"; }
.fad.fa-bowling-ball-pin::after, .fa-duotone.fa-bowling-ball-pin::after {
  content: "\e0c3\e0c3"; }
.fad.fa-bell-school-slash::after, .fa-duotone.fa-bell-school-slash::after {
  content: "\f5d6\f5d6"; }
.fad.fa-plus-large::after, .fa-duotone.fa-plus-large::after {
  content: "\e59e\e59e"; }
.fad.fa-money-bill-wheat::after, .fa-duotone.fa-money-bill-wheat::after {
  content: "\e52a\e52a"; }
.fad.fa-camera-viewfinder::after, .fa-duotone.fa-camera-viewfinder::after {
  content: "\e0da\e0da"; }
.fad.fa-screenshot::after, .fa-duotone.fa-screenshot::after {
  content: "\e0da\e0da"; }
.fad.fa-message-music::after, .fa-duotone.fa-message-music::after {
  content: "\f8af\f8af"; }
.fad.fa-comment-alt-music::after, .fa-duotone.fa-comment-alt-music::after {
  content: "\f8af\f8af"; }
.fad.fa-car-building::after, .fa-duotone.fa-car-building::after {
  content: "\f859\f859"; }
.fad.fa-border-bottom-right::after, .fa-duotone.fa-border-bottom-right::after {
  content: "\f854\f854"; }
.fad.fa-border-style-alt::after, .fa-duotone.fa-border-style-alt::after {
  content: "\f854\f854"; }
.fad.fa-octagon::after, .fa-duotone.fa-octagon::after {
  content: "\f306\f306"; }
.fad.fa-comment-arrow-up-right::after, .fa-duotone.fa-comment-arrow-up-right::after {
  content: "\e145\e145"; }
.fad.fa-octagon-divide::after, .fa-duotone.fa-octagon-divide::after {
  content: "\e203\e203"; }
.fad.fa-cookie::after, .fa-duotone.fa-cookie::after {
  content: "\f563\f563"; }
.fad.fa-arrow-rotate-left::after, .fa-duotone.fa-arrow-rotate-left::after {
  content: "\f0e2\f0e2"; }
.fad.fa-arrow-left-rotate::after, .fa-duotone.fa-arrow-left-rotate::after {
  content: "\f0e2\f0e2"; }
.fad.fa-arrow-rotate-back::after, .fa-duotone.fa-arrow-rotate-back::after {
  content: "\f0e2\f0e2"; }
.fad.fa-arrow-rotate-backward::after, .fa-duotone.fa-arrow-rotate-backward::after {
  content: "\f0e2\f0e2"; }
.fad.fa-undo::after, .fa-duotone.fa-undo::after {
  content: "\f0e2\f0e2"; }
.fad.fa-tv-music::after, .fa-duotone.fa-tv-music::after {
  content: "\f8e6\f8e6"; }
.fad.fa-hard-drive::after, .fa-duotone.fa-hard-drive::after {
  content: "\f0a0\f0a0"; }
.fad.fa-hdd::after, .fa-duotone.fa-hdd::after {
  content: "\f0a0\f0a0"; }
.fad.fa-reel::after, .fa-duotone.fa-reel::after {
  content: "\e238\e238"; }
.fad.fa-face-grin-squint-tears::after, .fa-duotone.fa-face-grin-squint-tears::after {
  content: "\f586\f586"; }
.fad.fa-grin-squint-tears::after, .fa-duotone.fa-grin-squint-tears::after {
  content: "\f586\f586"; }
.fad.fa-dumbbell::after, .fa-duotone.fa-dumbbell::after {
  content: "\f44b\f44b"; }
.fad.fa-rectangle-list::after, .fa-duotone.fa-rectangle-list::after {
  content: "\f022\f022"; }
.fad.fa-list-alt::after, .fa-duotone.fa-list-alt::after {
  content: "\f022\f022"; }
.fad.fa-tarp-droplet::after, .fa-duotone.fa-tarp-droplet::after {
  content: "\e57c\e57c"; }
.fad.fa-alarm-exclamation::after, .fa-duotone.fa-alarm-exclamation::after {
  content: "\f843\f843"; }
.fad.fa-house-medical-circle-check::after, .fa-duotone.fa-house-medical-circle-check::after {
  content: "\e511\e511"; }
.fad.fa-traffic-cone::after, .fa-duotone.fa-traffic-cone::after {
  content: "\f636\f636"; }
.fad.fa-grate::after, .fa-duotone.fa-grate::after {
  content: "\e193\e193"; }
.fad.fa-arrow-down-right::after, .fa-duotone.fa-arrow-down-right::after {
  content: "\e093\e093"; }
.fad.fa-person-skiing-nordic::after, .fa-duotone.fa-person-skiing-nordic::after {
  content: "\f7ca\f7ca"; }
.fad.fa-skiing-nordic::after, .fa-duotone.fa-skiing-nordic::after {
  content: "\f7ca\f7ca"; }
.fad.fa-calendar-plus::after, .fa-duotone.fa-calendar-plus::after {
  content: "\f271\f271"; }
.fad.fa-person-from-portal::after, .fa-duotone.fa-person-from-portal::after {
  content: "\e023\e023"; }
.fad.fa-portal-exit::after, .fa-duotone.fa-portal-exit::after {
  content: "\e023\e023"; }
.fad.fa-plane-arrival::after, .fa-duotone.fa-plane-arrival::after {
  content: "\f5af\f5af"; }
.fad.fa-cowbell-circle-plus::after, .fa-duotone.fa-cowbell-circle-plus::after {
  content: "\f8b4\f8b4"; }
.fad.fa-cowbell-more::after, .fa-duotone.fa-cowbell-more::after {
  content: "\f8b4\f8b4"; }
.fad.fa-circle-left::after, .fa-duotone.fa-circle-left::after {
  content: "\f359\f359"; }
.fad.fa-arrow-alt-circle-left::after, .fa-duotone.fa-arrow-alt-circle-left::after {
  content: "\f359\f359"; }
.fad.fa-distribute-spacing-vertical::after, .fa-duotone.fa-distribute-spacing-vertical::after {
  content: "\e366\e366"; }
.fad.fa-signal-bars-fair::after, .fa-duotone.fa-signal-bars-fair::after {
  content: "\f692\f692"; }
.fad.fa-signal-alt-2::after, .fa-duotone.fa-signal-alt-2::after {
  content: "\f692\f692"; }
.fad.fa-sportsball::after, .fa-duotone.fa-sportsball::after {
  content: "\e44b\e44b"; }
.fad.fa-game-console-handheld-crank::after, .fa-duotone.fa-game-console-handheld-crank::after {
  content: "\e5b9\e5b9"; }
.fad.fa-train-subway::after, .fa-duotone.fa-train-subway::after {
  content: "\f239\f239"; }
.fad.fa-subway::after, .fa-duotone.fa-subway::after {
  content: "\f239\f239"; }
.fad.fa-chart-gantt::after, .fa-duotone.fa-chart-gantt::after {
  content: "\e0e4\e0e4"; }
.fad.fa-face-smile-upside-down::after, .fa-duotone.fa-face-smile-upside-down::after {
  content: "\e395\e395"; }
.fad.fa-ball-pile::after, .fa-duotone.fa-ball-pile::after {
  content: "\f77e\f77e"; }
.fad.fa-badge-dollar::after, .fa-duotone.fa-badge-dollar::after {
  content: "\f645\f645"; }
.fad.fa-money-bills-simple::after, .fa-duotone.fa-money-bills-simple::after {
  content: "\e1f4\e1f4"; }
.fad.fa-money-bills-alt::after, .fa-duotone.fa-money-bills-alt::after {
  content: "\e1f4\e1f4"; }
.fad.fa-list-timeline::after, .fa-duotone.fa-list-timeline::after {
  content: "\e1d1\e1d1"; }
.fad.fa-indian-rupee-sign::after, .fa-duotone.fa-indian-rupee-sign::after {
  content: "\e1bc\e1bc"; }
.fad.fa-indian-rupee::after, .fa-duotone.fa-indian-rupee::after {
  content: "\e1bc\e1bc"; }
.fad.fa-inr::after, .fa-duotone.fa-inr::after {
  content: "\e1bc\e1bc"; }
.fad.fa-crop-simple::after, .fa-duotone.fa-crop-simple::after {
  content: "\f565\f565"; }
.fad.fa-crop-alt::after, .fa-duotone.fa-crop-alt::after {
  content: "\f565\f565"; }
.fad.fa-money-bill-1::after, .fa-duotone.fa-money-bill-1::after {
  content: "\f3d1\f3d1"; }
.fad.fa-money-bill-alt::after, .fa-duotone.fa-money-bill-alt::after {
  content: "\f3d1\f3d1"; }
.fad.fa-left-long::after, .fa-duotone.fa-left-long::after {
  content: "\f30a\f30a"; }
.fad.fa-long-arrow-alt-left::after, .fa-duotone.fa-long-arrow-alt-left::after {
  content: "\f30a\f30a"; }
.fad.fa-keyboard-down::after, .fa-duotone.fa-keyboard-down::after {
  content: "\e1c2\e1c2"; }
.fad.fa-circle-up-right::after, .fa-duotone.fa-circle-up-right::after {
  content: "\e129\e129"; }
.fad.fa-cloud-bolt-moon::after, .fa-duotone.fa-cloud-bolt-moon::after {
  content: "\f76d\f76d"; }
.fad.fa-thunderstorm-moon::after, .fa-duotone.fa-thunderstorm-moon::after {
  content: "\f76d\f76d"; }
.fad.fa-dna::after, .fa-duotone.fa-dna::after {
  content: "\f471\f471"; }
.fad.fa-virus-slash::after, .fa-duotone.fa-virus-slash::after {
  content: "\e075\e075"; }
.fad.fa-bracket-round-right::after, .fa-duotone.fa-bracket-round-right::after {
  content: "\29\29"; }
.fad.fa-circle-5::after, .fa-duotone.fa-circle-5::after {
  content: "\e0f2\e0f2"; }
.fad.fa-minus::after, .fa-duotone.fa-minus::after {
  content: "\f068\f068"; }
.fad.fa-subtract::after, .fa-duotone.fa-subtract::after {
  content: "\f068\f068"; }
.fad.fa-fire-flame::after, .fa-duotone.fa-fire-flame::after {
  content: "\f6df\f6df"; }
.fad.fa-flame::after, .fa-duotone.fa-flame::after {
  content: "\f6df\f6df"; }
.fad.fa-right-to-line::after, .fa-duotone.fa-right-to-line::after {
  content: "\f34c\f34c"; }
.fad.fa-arrow-alt-to-right::after, .fa-duotone.fa-arrow-alt-to-right::after {
  content: "\f34c\f34c"; }
.fad.fa-gif::after, .fa-duotone.fa-gif::after {
  content: "\e190\e190"; }
.fad.fa-chess::after, .fa-duotone.fa-chess::after {
  content: "\f439\f439"; }
.fad.fa-trash-slash::after, .fa-duotone.fa-trash-slash::after {
  content: "\e2b3\e2b3"; }
.fad.fa-arrow-left-long::after, .fa-duotone.fa-arrow-left-long::after {
  content: "\f177\f177"; }
.fad.fa-long-arrow-left::after, .fa-duotone.fa-long-arrow-left::after {
  content: "\f177\f177"; }
.fad.fa-plug-circle-check::after, .fa-duotone.fa-plug-circle-check::after {
  content: "\e55c\e55c"; }
.fad.fa-font-case::after, .fa-duotone.fa-font-case::after {
  content: "\f866\f866"; }
.fad.fa-street-view::after, .fa-duotone.fa-street-view::after {
  content: "\f21d\f21d"; }
.fad.fa-arrow-down-left::after, .fa-duotone.fa-arrow-down-left::after {
  content: "\e091\e091"; }
.fad.fa-franc-sign::after, .fa-duotone.fa-franc-sign::after {
  content: "\e18f\e18f"; }
.fad.fa-flask-round-poison::after, .fa-duotone.fa-flask-round-poison::after {
  content: "\f6e0\f6e0"; }
.fad.fa-flask-poison::after, .fa-duotone.fa-flask-poison::after {
  content: "\f6e0\f6e0"; }
.fad.fa-volume-off::after, .fa-duotone.fa-volume-off::after {
  content: "\f026\f026"; }
.fad.fa-book-circle-arrow-right::after, .fa-duotone.fa-book-circle-arrow-right::after {
  content: "\e0bc\e0bc"; }
.fad.fa-chart-user::after, .fa-duotone.fa-chart-user::after {
  content: "\f6a3\f6a3"; }
.fad.fa-user-chart::after, .fa-duotone.fa-user-chart::after {
  content: "\f6a3\f6a3"; }
.fad.fa-hands-asl-interpreting::after, .fa-duotone.fa-hands-asl-interpreting::after {
  content: "\f2a3\f2a3"; }
.fad.fa-american-sign-language-interpreting::after, .fa-duotone.fa-american-sign-language-interpreting::after {
  content: "\f2a3\f2a3"; }
.fad.fa-asl-interpreting::after, .fa-duotone.fa-asl-interpreting::after {
  content: "\f2a3\f2a3"; }
.fad.fa-hands-american-sign-language-interpreting::after, .fa-duotone.fa-hands-american-sign-language-interpreting::after {
  content: "\f2a3\f2a3"; }
.fad.fa-presentation-screen::after, .fa-duotone.fa-presentation-screen::after {
  content: "\f685\f685"; }
.fad.fa-presentation::after, .fa-duotone.fa-presentation::after {
  content: "\f685\f685"; }
.fad.fa-circle-bolt::after, .fa-duotone.fa-circle-bolt::after {
  content: "\e0fe\e0fe"; }
.fad.fa-face-smile-halo::after, .fa-duotone.fa-face-smile-halo::after {
  content: "\e38f\e38f"; }
.fad.fa-cart-circle-arrow-down::after, .fa-duotone.fa-cart-circle-arrow-down::after {
  content: "\e3ef\e3ef"; }
.fad.fa-house-person-return::after, .fa-duotone.fa-house-person-return::after {
  content: "\e011\e011"; }
.fad.fa-house-person-arrive::after, .fa-duotone.fa-house-person-arrive::after {
  content: "\e011\e011"; }
.fad.fa-house-return::after, .fa-duotone.fa-house-return::after {
  content: "\e011\e011"; }
.fad.fa-message-xmark::after, .fa-duotone.fa-message-xmark::after {
  content: "\f4ab\f4ab"; }
.fad.fa-comment-alt-times::after, .fa-duotone.fa-comment-alt-times::after {
  content: "\f4ab\f4ab"; }
.fad.fa-message-times::after, .fa-duotone.fa-message-times::after {
  content: "\f4ab\f4ab"; }
.fad.fa-file-certificate::after, .fa-duotone.fa-file-certificate::after {
  content: "\f5f3\f5f3"; }
.fad.fa-file-award::after, .fa-duotone.fa-file-award::after {
  content: "\f5f3\f5f3"; }
.fad.fa-user-doctor-hair-long::after, .fa-duotone.fa-user-doctor-hair-long::after {
  content: "\e459\e459"; }
.fad.fa-camera-security::after, .fa-duotone.fa-camera-security::after {
  content: "\f8fe\f8fe"; }
.fad.fa-camera-home::after, .fa-duotone.fa-camera-home::after {
  content: "\f8fe\f8fe"; }
.fad.fa-gear::after, .fa-duotone.fa-gear::after {
  content: "\f013\f013"; }
.fad.fa-cog::after, .fa-duotone.fa-cog::after {
  content: "\f013\f013"; }
.fad.fa-droplet-slash::after, .fa-duotone.fa-droplet-slash::after {
  content: "\f5c7\f5c7"; }
.fad.fa-tint-slash::after, .fa-duotone.fa-tint-slash::after {
  content: "\f5c7\f5c7"; }
.fad.fa-book-heart::after, .fa-duotone.fa-book-heart::after {
  content: "\f499\f499"; }
.fad.fa-mosque::after, .fa-duotone.fa-mosque::after {
  content: "\f678\f678"; }
.fad.fa-duck::after, .fa-duotone.fa-duck::after {
  content: "\f6d8\f6d8"; }
.fad.fa-mosquito::after, .fa-duotone.fa-mosquito::after {
  content: "\e52b\e52b"; }
.fad.fa-star-of-david::after, .fa-duotone.fa-star-of-david::after {
  content: "\f69a\f69a"; }
.fad.fa-flag-swallowtail::after, .fa-duotone.fa-flag-swallowtail::after {
  content: "\f74c\f74c"; }
.fad.fa-flag-alt::after, .fa-duotone.fa-flag-alt::after {
  content: "\f74c\f74c"; }
.fad.fa-person-military-rifle::after, .fa-duotone.fa-person-military-rifle::after {
  content: "\e54b\e54b"; }
.fad.fa-car-garage::after, .fa-duotone.fa-car-garage::after {
  content: "\f5e2\f5e2"; }
.fad.fa-cart-shopping::after, .fa-duotone.fa-cart-shopping::after {
  content: "\f07a\f07a"; }
.fad.fa-shopping-cart::after, .fa-duotone.fa-shopping-cart::after {
  content: "\f07a\f07a"; }
.fad.fa-book-font::after, .fa-duotone.fa-book-font::after {
  content: "\e0bf\e0bf"; }
.fad.fa-shield-plus::after, .fa-duotone.fa-shield-plus::after {
  content: "\e24a\e24a"; }
.fad.fa-vials::after, .fa-duotone.fa-vials::after {
  content: "\f493\f493"; }
.fad.fa-eye-dropper-full::after, .fa-duotone.fa-eye-dropper-full::after {
  content: "\e172\e172"; }
.fad.fa-distribute-spacing-horizontal::after, .fa-duotone.fa-distribute-spacing-horizontal::after {
  content: "\e365\e365"; }
.fad.fa-tablet-rugged::after, .fa-duotone.fa-tablet-rugged::after {
  content: "\f48f\f48f"; }
.fad.fa-temperature-snow::after, .fa-duotone.fa-temperature-snow::after {
  content: "\f768\f768"; }
.fad.fa-temperature-frigid::after, .fa-duotone.fa-temperature-frigid::after {
  content: "\f768\f768"; }
.fad.fa-moped::after, .fa-duotone.fa-moped::after {
  content: "\e3b9\e3b9"; }
.fad.fa-face-smile-plus::after, .fa-duotone.fa-face-smile-plus::after {
  content: "\f5b9\f5b9"; }
.fad.fa-smile-plus::after, .fa-duotone.fa-smile-plus::after {
  content: "\f5b9\f5b9"; }
.fad.fa-radio-tuner::after, .fa-duotone.fa-radio-tuner::after {
  content: "\f8d8\f8d8"; }
.fad.fa-radio-alt::after, .fa-duotone.fa-radio-alt::after {
  content: "\f8d8\f8d8"; }
.fad.fa-face-swear::after, .fa-duotone.fa-face-swear::after {
  content: "\e399\e399"; }
.fad.fa-water-arrow-down::after, .fa-duotone.fa-water-arrow-down::after {
  content: "\f774\f774"; }
.fad.fa-water-lower::after, .fa-duotone.fa-water-lower::after {
  content: "\f774\f774"; }
.fad.fa-scanner-touchscreen::after, .fa-duotone.fa-scanner-touchscreen::after {
  content: "\f48a\f48a"; }
.fad.fa-circle-7::after, .fa-duotone.fa-circle-7::after {
  content: "\e0f4\e0f4"; }
.fad.fa-plug-circle-plus::after, .fa-duotone.fa-plug-circle-plus::after {
  content: "\e55f\e55f"; }
.fad.fa-person-ski-jumping::after, .fa-duotone.fa-person-ski-jumping::after {
  content: "\f7c7\f7c7"; }
.fad.fa-ski-jump::after, .fa-duotone.fa-ski-jump::after {
  content: "\f7c7\f7c7"; }
.fad.fa-place-of-worship::after, .fa-duotone.fa-place-of-worship::after {
  content: "\f67f\f67f"; }
.fad.fa-water-arrow-up::after, .fa-duotone.fa-water-arrow-up::after {
  content: "\f775\f775"; }
.fad.fa-water-rise::after, .fa-duotone.fa-water-rise::after {
  content: "\f775\f775"; }
.fad.fa-waveform-lines::after, .fa-duotone.fa-waveform-lines::after {
  content: "\f8f2\f8f2"; }
.fad.fa-waveform-path::after, .fa-duotone.fa-waveform-path::after {
  content: "\f8f2\f8f2"; }
.fad.fa-split::after, .fa-duotone.fa-split::after {
  content: "\e254\e254"; }
.fad.fa-film-canister::after, .fa-duotone.fa-film-canister::after {
  content: "\f8b7\f8b7"; }
.fad.fa-film-cannister::after, .fa-duotone.fa-film-cannister::after {
  content: "\f8b7\f8b7"; }
.fad.fa-folder-xmark::after, .fa-duotone.fa-folder-xmark::after {
  content: "\f65f\f65f"; }
.fad.fa-folder-times::after, .fa-duotone.fa-folder-times::after {
  content: "\f65f\f65f"; }
.fad.fa-toilet-paper-blank::after, .fa-duotone.fa-toilet-paper-blank::after {
  content: "\f71f\f71f"; }
.fad.fa-toilet-paper-alt::after, .fa-duotone.fa-toilet-paper-alt::after {
  content: "\f71f\f71f"; }
.fad.fa-tablet-screen::after, .fa-duotone.fa-tablet-screen::after {
  content: "\f3fc\f3fc"; }
.fad.fa-tablet-android-alt::after, .fa-duotone.fa-tablet-android-alt::after {
  content: "\f3fc\f3fc"; }
.fad.fa-hexagon-vertical-nft-slanted::after, .fa-duotone.fa-hexagon-vertical-nft-slanted::after {
  content: "\e506\e506"; }
.fad.fa-folder-music::after, .fa-duotone.fa-folder-music::after {
  content: "\e18d\e18d"; }
.fad.fa-display-medical::after, .fa-duotone.fa-display-medical::after {
  content: "\e166\e166"; }
.fad.fa-desktop-medical::after, .fa-duotone.fa-desktop-medical::after {
  content: "\e166\e166"; }
.fad.fa-share-all::after, .fa-duotone.fa-share-all::after {
  content: "\f367\f367"; }
.fad.fa-peapod::after, .fa-duotone.fa-peapod::after {
  content: "\e31c\e31c"; }
.fad.fa-chess-clock::after, .fa-duotone.fa-chess-clock::after {
  content: "\f43d\f43d"; }
.fad.fa-axe::after, .fa-duotone.fa-axe::after {
  content: "\f6b2\f6b2"; }
.fad.fa-square-d::after, .fa-duotone.fa-square-d::after {
  content: "\e268\e268"; }
.fad.fa-grip-vertical::after, .fa-duotone.fa-grip-vertical::after {
  content: "\f58e\f58e"; }
.fad.fa-mobile-signal-out::after, .fa-duotone.fa-mobile-signal-out::after {
  content: "\e1f0\e1f0"; }
.fad.fa-arrow-turn-up::after, .fa-duotone.fa-arrow-turn-up::after {
  content: "\f148\f148"; }
.fad.fa-level-up::after, .fa-duotone.fa-level-up::after {
  content: "\f148\f148"; }
.fad.fa-u::after, .fa-duotone.fa-u::after {
  content: "\55\55"; }
.fad.fa-arrow-up-from-dotted-line::after, .fa-duotone.fa-arrow-up-from-dotted-line::after {
  content: "\e09b\e09b"; }
.fad.fa-square-root-variable::after, .fa-duotone.fa-square-root-variable::after {
  content: "\f698\f698"; }
.fad.fa-square-root-alt::after, .fa-duotone.fa-square-root-alt::after {
  content: "\f698\f698"; }
.fad.fa-light-switch-on::after, .fa-duotone.fa-light-switch-on::after {
  content: "\e019\e019"; }
.fad.fa-arrow-down-arrow-up::after, .fa-duotone.fa-arrow-down-arrow-up::after {
  content: "\f883\f883"; }
.fad.fa-sort-alt::after, .fa-duotone.fa-sort-alt::after {
  content: "\f883\f883"; }
.fad.fa-raindrops::after, .fa-duotone.fa-raindrops::after {
  content: "\f75c\f75c"; }
.fad.fa-dash::after, .fa-duotone.fa-dash::after {
  content: "\e404\e404"; }
.fad.fa-minus-large::after, .fa-duotone.fa-minus-large::after {
  content: "\e404\e404"; }
.fad.fa-clock::after, .fa-duotone.fa-clock::after {
  content: "\f017\f017"; }
.fad.fa-clock-four::after, .fa-duotone.fa-clock-four::after {
  content: "\f017\f017"; }
.fad.fa-input-numeric::after, .fa-duotone.fa-input-numeric::after {
  content: "\e1bd\e1bd"; }
.fad.fa-truck-tow::after, .fa-duotone.fa-truck-tow::after {
  content: "\e2b8\e2b8"; }
.fad.fa-backward-step::after, .fa-duotone.fa-backward-step::after {
  content: "\f048\f048"; }
.fad.fa-step-backward::after, .fa-duotone.fa-step-backward::after {
  content: "\f048\f048"; }
.fad.fa-pallet::after, .fa-duotone.fa-pallet::after {
  content: "\f482\f482"; }
.fad.fa-car-bolt::after, .fa-duotone.fa-car-bolt::after {
  content: "\e341\e341"; }
.fad.fa-arrows-maximize::after, .fa-duotone.fa-arrows-maximize::after {
  content: "\f31d\f31d"; }
.fad.fa-expand-arrows::after, .fa-duotone.fa-expand-arrows::after {
  content: "\f31d\f31d"; }
.fad.fa-faucet::after, .fa-duotone.fa-faucet::after {
  content: "\e005\e005"; }
.fad.fa-cloud-sleet::after, .fa-duotone.fa-cloud-sleet::after {
  content: "\f741\f741"; }
.fad.fa-lamp-street::after, .fa-duotone.fa-lamp-street::after {
  content: "\e1c5\e1c5"; }
.fad.fa-list-radio::after, .fa-duotone.fa-list-radio::after {
  content: "\e1d0\e1d0"; }
.fad.fa-pen-nib-slash::after, .fa-duotone.fa-pen-nib-slash::after {
  content: "\e4a1\e4a1"; }
.fad.fa-baseball-bat-ball::after, .fa-duotone.fa-baseball-bat-ball::after {
  content: "\f432\f432"; }
.fad.fa-square-up-left::after, .fa-duotone.fa-square-up-left::after {
  content: "\e282\e282"; }
.fad.fa-overline::after, .fa-duotone.fa-overline::after {
  content: "\f876\f876"; }
.fad.fa-s::after, .fa-duotone.fa-s::after {
  content: "\53\53"; }
.fad.fa-timeline::after, .fa-duotone.fa-timeline::after {
  content: "\e29c\e29c"; }
.fad.fa-keyboard::after, .fa-duotone.fa-keyboard::after {
  content: "\f11c\f11c"; }
.fad.fa-arrows-from-dotted-line::after, .fa-duotone.fa-arrows-from-dotted-line::after {
  content: "\e0a3\e0a3"; }
.fad.fa-usb-drive::after, .fa-duotone.fa-usb-drive::after {
  content: "\f8e9\f8e9"; }
.fad.fa-ballot::after, .fa-duotone.fa-ballot::after {
  content: "\f732\f732"; }
.fad.fa-caret-down::after, .fa-duotone.fa-caret-down::after {
  content: "\f0d7\f0d7"; }
.fad.fa-location-dot-slash::after, .fa-duotone.fa-location-dot-slash::after {
  content: "\f605\f605"; }
.fad.fa-map-marker-alt-slash::after, .fa-duotone.fa-map-marker-alt-slash::after {
  content: "\f605\f605"; }
.fad.fa-cards::after, .fa-duotone.fa-cards::after {
  content: "\e3ed\e3ed"; }
.fad.fa-house-chimney-medical::after, .fa-duotone.fa-house-chimney-medical::after {
  content: "\f7f2\f7f2"; }
.fad.fa-clinic-medical::after, .fa-duotone.fa-clinic-medical::after {
  content: "\f7f2\f7f2"; }
.fad.fa-boxing-glove::after, .fa-duotone.fa-boxing-glove::after {
  content: "\f438\f438"; }
.fad.fa-glove-boxing::after, .fa-duotone.fa-glove-boxing::after {
  content: "\f438\f438"; }
.fad.fa-temperature-three-quarters::after, .fa-duotone.fa-temperature-three-quarters::after {
  content: "\f2c8\f2c8"; }
.fad.fa-temperature-3::after, .fa-duotone.fa-temperature-3::after {
  content: "\f2c8\f2c8"; }
.fad.fa-thermometer-3::after, .fa-duotone.fa-thermometer-3::after {
  content: "\f2c8\f2c8"; }
.fad.fa-thermometer-three-quarters::after, .fa-duotone.fa-thermometer-three-quarters::after {
  content: "\f2c8\f2c8"; }
.fad.fa-bell-school::after, .fa-duotone.fa-bell-school::after {
  content: "\f5d5\f5d5"; }
.fad.fa-mobile-screen::after, .fa-duotone.fa-mobile-screen::after {
  content: "\f3cf\f3cf"; }
.fad.fa-mobile-android-alt::after, .fa-duotone.fa-mobile-android-alt::after {
  content: "\f3cf\f3cf"; }
.fad.fa-plane-up::after, .fa-duotone.fa-plane-up::after {
  content: "\e22d\e22d"; }
.fad.fa-folder-heart::after, .fa-duotone.fa-folder-heart::after {
  content: "\e189\e189"; }
.fad.fa-circle-location-arrow::after, .fa-duotone.fa-circle-location-arrow::after {
  content: "\f602\f602"; }
.fad.fa-location-circle::after, .fa-duotone.fa-location-circle::after {
  content: "\f602\f602"; }
.fad.fa-face-head-bandage::after, .fa-duotone.fa-face-head-bandage::after {
  content: "\e37a\e37a"; }
.fad.fa-sushi-roll::after, .fa-duotone.fa-sushi-roll::after {
  content: "\e48b\e48b"; }
.fad.fa-maki-roll::after, .fa-duotone.fa-maki-roll::after {
  content: "\e48b\e48b"; }
.fad.fa-makizushi::after, .fa-duotone.fa-makizushi::after {
  content: "\e48b\e48b"; }
.fad.fa-car-bump::after, .fa-duotone.fa-car-bump::after {
  content: "\f5e0\f5e0"; }
.fad.fa-piggy-bank::after, .fa-duotone.fa-piggy-bank::after {
  content: "\f4d3\f4d3"; }
.fad.fa-racquet::after, .fa-duotone.fa-racquet::after {
  content: "\f45a\f45a"; }
.fad.fa-car-mirrors::after, .fa-duotone.fa-car-mirrors::after {
  content: "\e343\e343"; }
.fad.fa-industry-windows::after, .fa-duotone.fa-industry-windows::after {
  content: "\f3b3\f3b3"; }
.fad.fa-industry-alt::after, .fa-duotone.fa-industry-alt::after {
  content: "\f3b3\f3b3"; }
.fad.fa-bolt-auto::after, .fa-duotone.fa-bolt-auto::after {
  content: "\e0b6\e0b6"; }
.fad.fa-battery-half::after, .fa-duotone.fa-battery-half::after {
  content: "\f242\f242"; }
.fad.fa-battery-3::after, .fa-duotone.fa-battery-3::after {
  content: "\f242\f242"; }
.fad.fa-flux-capacitor::after, .fa-duotone.fa-flux-capacitor::after {
  content: "\f8ba\f8ba"; }
.fad.fa-mountain-city::after, .fa-duotone.fa-mountain-city::after {
  content: "\e52e\e52e"; }
.fad.fa-coins::after, .fa-duotone.fa-coins::after {
  content: "\f51e\f51e"; }
.fad.fa-honey-pot::after, .fa-duotone.fa-honey-pot::after {
  content: "\e418\e418"; }
.fad.fa-olive::after, .fa-duotone.fa-olive::after {
  content: "\e316\e316"; }
.fad.fa-khanda::after, .fa-duotone.fa-khanda::after {
  content: "\f66d\f66d"; }
.fad.fa-filter-list::after, .fa-duotone.fa-filter-list::after {
  content: "\e17c\e17c"; }
.fad.fa-outlet::after, .fa-duotone.fa-outlet::after {
  content: "\e01c\e01c"; }
.fad.fa-sliders::after, .fa-duotone.fa-sliders::after {
  content: "\f1de\f1de"; }
.fad.fa-sliders-h::after, .fa-duotone.fa-sliders-h::after {
  content: "\f1de\f1de"; }
.fad.fa-cauldron::after, .fa-duotone.fa-cauldron::after {
  content: "\f6bf\f6bf"; }
.fad.fa-people::after, .fa-duotone.fa-people::after {
  content: "\e216\e216"; }
.fad.fa-folder-tree::after, .fa-duotone.fa-folder-tree::after {
  content: "\f802\f802"; }
.fad.fa-network-wired::after, .fa-duotone.fa-network-wired::after {
  content: "\f6ff\f6ff"; }
.fad.fa-croissant::after, .fa-duotone.fa-croissant::after {
  content: "\f7f6\f7f6"; }
.fad.fa-map-pin::after, .fa-duotone.fa-map-pin::after {
  content: "\f276\f276"; }
.fad.fa-hamsa::after, .fa-duotone.fa-hamsa::after {
  content: "\f665\f665"; }
.fad.fa-cent-sign::after, .fa-duotone.fa-cent-sign::after {
  content: "\e3f5\e3f5"; }
.fad.fa-swords-laser::after, .fa-duotone.fa-swords-laser::after {
  content: "\e03d\e03d"; }
.fad.fa-flask::after, .fa-duotone.fa-flask::after {
  content: "\f0c3\f0c3"; }
.fad.fa-person-pregnant::after, .fa-duotone.fa-person-pregnant::after {
  content: "\e31e\e31e"; }
.fad.fa-square-u::after, .fa-duotone.fa-square-u::after {
  content: "\e281\e281"; }
.fad.fa-wand-sparkles::after, .fa-duotone.fa-wand-sparkles::after {
  content: "\f72b\f72b"; }
.fad.fa-router::after, .fa-duotone.fa-router::after {
  content: "\f8da\f8da"; }
.fad.fa-ellipsis-vertical::after, .fa-duotone.fa-ellipsis-vertical::after {
  content: "\f142\f142"; }
.fad.fa-ellipsis-v::after, .fa-duotone.fa-ellipsis-v::after {
  content: "\f142\f142"; }
.fad.fa-sword-laser-alt::after, .fa-duotone.fa-sword-laser-alt::after {
  content: "\e03c\e03c"; }
.fad.fa-ticket::after, .fa-duotone.fa-ticket::after {
  content: "\f145\f145"; }
.fad.fa-power-off::after, .fa-duotone.fa-power-off::after {
  content: "\f011\f011"; }
.fad.fa-coin::after, .fa-duotone.fa-coin::after {
  content: "\f85c\f85c"; }
.fad.fa-laptop-slash::after, .fa-duotone.fa-laptop-slash::after {
  content: "\e1c7\e1c7"; }
.fad.fa-right-long::after, .fa-duotone.fa-right-long::after {
  content: "\f30b\f30b"; }
.fad.fa-long-arrow-alt-right::after, .fa-duotone.fa-long-arrow-alt-right::after {
  content: "\f30b\f30b"; }
.fad.fa-circle-b::after, .fa-duotone.fa-circle-b::after {
  content: "\e0fd\e0fd"; }
.fad.fa-person-dress-simple::after, .fa-duotone.fa-person-dress-simple::after {
  content: "\e21c\e21c"; }
.fad.fa-pipe-collar::after, .fa-duotone.fa-pipe-collar::after {
  content: "\e437\e437"; }
.fad.fa-lights-holiday::after, .fa-duotone.fa-lights-holiday::after {
  content: "\f7b2\f7b2"; }
.fad.fa-citrus::after, .fa-duotone.fa-citrus::after {
  content: "\e2f4\e2f4"; }
.fad.fa-flag-usa::after, .fa-duotone.fa-flag-usa::after {
  content: "\f74d\f74d"; }
.fad.fa-laptop-file::after, .fa-duotone.fa-laptop-file::after {
  content: "\e51d\e51d"; }
.fad.fa-tty::after, .fa-duotone.fa-tty::after {
  content: "\f1e4\f1e4"; }
.fad.fa-teletype::after, .fa-duotone.fa-teletype::after {
  content: "\f1e4\f1e4"; }
.fad.fa-chart-tree-map::after, .fa-duotone.fa-chart-tree-map::after {
  content: "\e0ea\e0ea"; }
.fad.fa-diagram-next::after, .fa-duotone.fa-diagram-next::after {
  content: "\e476\e476"; }
.fad.fa-person-rifle::after, .fa-duotone.fa-person-rifle::after {
  content: "\e54e\e54e"; }
.fad.fa-clock-five-thirty::after, .fa-duotone.fa-clock-five-thirty::after {
  content: "\e34a\e34a"; }
.fad.fa-pipe-valve::after, .fa-duotone.fa-pipe-valve::after {
  content: "\e439\e439"; }
.fad.fa-arrow-up-from-arc::after, .fa-duotone.fa-arrow-up-from-arc::after {
  content: "\e4b4\e4b4"; }
.fad.fa-face-spiral-eyes::after, .fa-duotone.fa-face-spiral-eyes::after {
  content: "\e485\e485"; }
.fad.fa-compress-wide::after, .fa-duotone.fa-compress-wide::after {
  content: "\f326\f326"; }
.fad.fa-circle-phone-hangup::after, .fa-duotone.fa-circle-phone-hangup::after {
  content: "\e11d\e11d"; }
.fad.fa-phone-circle-down::after, .fa-duotone.fa-phone-circle-down::after {
  content: "\e11d\e11d"; }
.fad.fa-house-medical-circle-exclamation::after, .fa-duotone.fa-house-medical-circle-exclamation::after {
  content: "\e512\e512"; }
.fad.fa-badminton::after, .fa-duotone.fa-badminton::after {
  content: "\e33a\e33a"; }
.fad.fa-closed-captioning::after, .fa-duotone.fa-closed-captioning::after {
  content: "\f20a\f20a"; }
.fad.fa-person-hiking::after, .fa-duotone.fa-person-hiking::after {
  content: "\f6ec\f6ec"; }
.fad.fa-hiking::after, .fa-duotone.fa-hiking::after {
  content: "\f6ec\f6ec"; }
.fad.fa-right-from-line::after, .fa-duotone.fa-right-from-line::after {
  content: "\f347\f347"; }
.fad.fa-arrow-alt-from-left::after, .fa-duotone.fa-arrow-alt-from-left::after {
  content: "\f347\f347"; }
.fad.fa-venus-double::after, .fa-duotone.fa-venus-double::after {
  content: "\f226\f226"; }
.fad.fa-images::after, .fa-duotone.fa-images::after {
  content: "\f302\f302"; }
.fad.fa-calculator::after, .fa-duotone.fa-calculator::after {
  content: "\f1ec\f1ec"; }
.fad.fa-shuttlecock::after, .fa-duotone.fa-shuttlecock::after {
  content: "\f45b\f45b"; }
.fad.fa-user-hair::after, .fa-duotone.fa-user-hair::after {
  content: "\e45a\e45a"; }
.fad.fa-eye-evil::after, .fa-duotone.fa-eye-evil::after {
  content: "\f6db\f6db"; }
.fad.fa-people-pulling::after, .fa-duotone.fa-people-pulling::after {
  content: "\e535\e535"; }
.fad.fa-n::after, .fa-duotone.fa-n::after {
  content: "\4e\4e"; }
.fad.fa-garage::after, .fa-duotone.fa-garage::after {
  content: "\e009\e009"; }
.fad.fa-cable-car::after, .fa-duotone.fa-cable-car::after {
  content: "\f7da\f7da"; }
.fad.fa-tram::after, .fa-duotone.fa-tram::after {
  content: "\f7da\f7da"; }
.fad.fa-shovel-snow::after, .fa-duotone.fa-shovel-snow::after {
  content: "\f7c3\f7c3"; }
.fad.fa-cloud-rain::after, .fa-duotone.fa-cloud-rain::after {
  content: "\f73d\f73d"; }
.fad.fa-face-lying::after, .fa-duotone.fa-face-lying::after {
  content: "\e37e\e37e"; }
.fad.fa-sprinkler::after, .fa-duotone.fa-sprinkler::after {
  content: "\e035\e035"; }
.fad.fa-building-circle-xmark::after, .fa-duotone.fa-building-circle-xmark::after {
  content: "\e4d4\e4d4"; }
.fad.fa-person-sledding::after, .fa-duotone.fa-person-sledding::after {
  content: "\f7cb\f7cb"; }
.fad.fa-sledding::after, .fa-duotone.fa-sledding::after {
  content: "\f7cb\f7cb"; }
.fad.fa-game-console-handheld::after, .fa-duotone.fa-game-console-handheld::after {
  content: "\f8bb\f8bb"; }
.fad.fa-ship::after, .fa-duotone.fa-ship::after {
  content: "\f21a\f21a"; }
.fad.fa-clock-six-thirty::after, .fa-duotone.fa-clock-six-thirty::after {
  content: "\e353\e353"; }
.fad.fa-battery-slash::after, .fa-duotone.fa-battery-slash::after {
  content: "\f377\f377"; }
.fad.fa-tugrik-sign::after, .fa-duotone.fa-tugrik-sign::after {
  content: "\e2ba\e2ba"; }
.fad.fa-arrows-down-to-line::after, .fa-duotone.fa-arrows-down-to-line::after {
  content: "\e4b8\e4b8"; }
.fad.fa-download::after, .fa-duotone.fa-download::after {
  content: "\f019\f019"; }
.fad.fa-shelves::after, .fa-duotone.fa-shelves::after {
  content: "\f480\f480"; }
.fad.fa-inventory::after, .fa-duotone.fa-inventory::after {
  content: "\f480\f480"; }
.fad.fa-cloud-snow::after, .fa-duotone.fa-cloud-snow::after {
  content: "\f742\f742"; }
.fad.fa-face-grin::after, .fa-duotone.fa-face-grin::after {
  content: "\f580\f580"; }
.fad.fa-grin::after, .fa-duotone.fa-grin::after {
  content: "\f580\f580"; }
.fad.fa-delete-left::after, .fa-duotone.fa-delete-left::after {
  content: "\f55a\f55a"; }
.fad.fa-backspace::after, .fa-duotone.fa-backspace::after {
  content: "\f55a\f55a"; }
.fad.fa-oven::after, .fa-duotone.fa-oven::after {
  content: "\e01d\e01d"; }
.fad.fa-eye-dropper::after, .fa-duotone.fa-eye-dropper::after {
  content: "\f1fb\f1fb"; }
.fad.fa-eye-dropper-empty::after, .fa-duotone.fa-eye-dropper-empty::after {
  content: "\f1fb\f1fb"; }
.fad.fa-eyedropper::after, .fa-duotone.fa-eyedropper::after {
  content: "\f1fb\f1fb"; }
.fad.fa-comment-captions::after, .fa-duotone.fa-comment-captions::after {
  content: "\e146\e146"; }
.fad.fa-comments-question::after, .fa-duotone.fa-comments-question::after {
  content: "\e14e\e14e"; }
.fad.fa-scribble::after, .fa-duotone.fa-scribble::after {
  content: "\e23f\e23f"; }
.fad.fa-rotate-exclamation::after, .fa-duotone.fa-rotate-exclamation::after {
  content: "\e23c\e23c"; }
.fad.fa-file-circle-check::after, .fa-duotone.fa-file-circle-check::after {
  content: "\e5a0\e5a0"; }
.fad.fa-glass::after, .fa-duotone.fa-glass::after {
  content: "\f804\f804"; }
.fad.fa-loader::after, .fa-duotone.fa-loader::after {
  content: "\e1d4\e1d4"; }
.fad.fa-forward::after, .fa-duotone.fa-forward::after {
  content: "\f04e\f04e"; }
.fad.fa-user-pilot::after, .fa-duotone.fa-user-pilot::after {
  content: "\e2c0\e2c0"; }
.fad.fa-mobile::after, .fa-duotone.fa-mobile::after {
  content: "\f3ce\f3ce"; }
.fad.fa-mobile-android::after, .fa-duotone.fa-mobile-android::after {
  content: "\f3ce\f3ce"; }
.fad.fa-mobile-phone::after, .fa-duotone.fa-mobile-phone::after {
  content: "\f3ce\f3ce"; }
.fad.fa-code-pull-request-closed::after, .fa-duotone.fa-code-pull-request-closed::after {
  content: "\e3f9\e3f9"; }
.fad.fa-face-meh::after, .fa-duotone.fa-face-meh::after {
  content: "\f11a\f11a"; }
.fad.fa-meh::after, .fa-duotone.fa-meh::after {
  content: "\f11a\f11a"; }
.fad.fa-align-center::after, .fa-duotone.fa-align-center::after {
  content: "\f037\f037"; }
.fad.fa-book-skull::after, .fa-duotone.fa-book-skull::after {
  content: "\f6b7\f6b7"; }
.fad.fa-book-dead::after, .fa-duotone.fa-book-dead::after {
  content: "\f6b7\f6b7"; }
.fad.fa-id-card::after, .fa-duotone.fa-id-card::after {
  content: "\f2c2\f2c2"; }
.fad.fa-drivers-license::after, .fa-duotone.fa-drivers-license::after {
  content: "\f2c2\f2c2"; }
.fad.fa-face-dotted::after, .fa-duotone.fa-face-dotted::after {
  content: "\e47f\e47f"; }
.fad.fa-face-worried::after, .fa-duotone.fa-face-worried::after {
  content: "\e3a3\e3a3"; }
.fad.fa-outdent::after, .fa-duotone.fa-outdent::after {
  content: "\f03b\f03b"; }
.fad.fa-dedent::after, .fa-duotone.fa-dedent::after {
  content: "\f03b\f03b"; }
.fad.fa-heart-circle-exclamation::after, .fa-duotone.fa-heart-circle-exclamation::after {
  content: "\e4fe\e4fe"; }
.fad.fa-house::after, .fa-duotone.fa-house::after {
  content: "\f015\f015"; }
.fad.fa-home::after, .fa-duotone.fa-home::after {
  content: "\f015\f015"; }
.fad.fa-home-alt::after, .fa-duotone.fa-home-alt::after {
  content: "\f015\f015"; }
.fad.fa-home-lg-alt::after, .fa-duotone.fa-home-lg-alt::after {
  content: "\f015\f015"; }
.fad.fa-vector-circle::after, .fa-duotone.fa-vector-circle::after {
  content: "\e2c6\e2c6"; }
.fad.fa-car-circle-bolt::after, .fa-duotone.fa-car-circle-bolt::after {
  content: "\e342\e342"; }
.fad.fa-calendar-week::after, .fa-duotone.fa-calendar-week::after {
  content: "\f784\f784"; }
.fad.fa-flying-disc::after, .fa-duotone.fa-flying-disc::after {
  content: "\e3a9\e3a9"; }
.fad.fa-laptop-medical::after, .fa-duotone.fa-laptop-medical::after {
  content: "\f812\f812"; }
.fad.fa-square-down-right::after, .fa-duotone.fa-square-down-right::after {
  content: "\e26c\e26c"; }
.fad.fa-b::after, .fa-duotone.fa-b::after {
  content: "\42\42"; }
.fad.fa-seat-airline::after, .fa-duotone.fa-seat-airline::after {
  content: "\e244\e244"; }
.fad.fa-moon-over-sun::after, .fa-duotone.fa-moon-over-sun::after {
  content: "\f74a\f74a"; }
.fad.fa-eclipse-alt::after, .fa-duotone.fa-eclipse-alt::after {
  content: "\f74a\f74a"; }
.fad.fa-pipe::after, .fa-duotone.fa-pipe::after {
  content: "\7c\7c"; }
.fad.fa-file-medical::after, .fa-duotone.fa-file-medical::after {
  content: "\f477\f477"; }
.fad.fa-potato::after, .fa-duotone.fa-potato::after {
  content: "\e440\e440"; }
.fad.fa-dice-one::after, .fa-duotone.fa-dice-one::after {
  content: "\f525\f525"; }
.fad.fa-circle-a::after, .fa-duotone.fa-circle-a::after {
  content: "\e0f7\e0f7"; }
.fad.fa-helmet-battle::after, .fa-duotone.fa-helmet-battle::after {
  content: "\f6eb\f6eb"; }
.fad.fa-butter::after, .fa-duotone.fa-butter::after {
  content: "\e3e4\e3e4"; }
.fad.fa-blanket-fire::after, .fa-duotone.fa-blanket-fire::after {
  content: "\e3da\e3da"; }
.fad.fa-kiwi-bird::after, .fa-duotone.fa-kiwi-bird::after {
  content: "\f535\f535"; }
.fad.fa-castle::after, .fa-duotone.fa-castle::after {
  content: "\e0de\e0de"; }
.fad.fa-golf-club::after, .fa-duotone.fa-golf-club::after {
  content: "\f451\f451"; }
.fad.fa-arrow-right-arrow-left::after, .fa-duotone.fa-arrow-right-arrow-left::after {
  content: "\f0ec\f0ec"; }
.fad.fa-exchange::after, .fa-duotone.fa-exchange::after {
  content: "\f0ec\f0ec"; }
.fad.fa-rotate-right::after, .fa-duotone.fa-rotate-right::after {
  content: "\f2f9\f2f9"; }
.fad.fa-redo-alt::after, .fa-duotone.fa-redo-alt::after {
  content: "\f2f9\f2f9"; }
.fad.fa-rotate-forward::after, .fa-duotone.fa-rotate-forward::after {
  content: "\f2f9\f2f9"; }
.fad.fa-utensils::after, .fa-duotone.fa-utensils::after {
  content: "\f2e7\f2e7"; }
.fad.fa-cutlery::after, .fa-duotone.fa-cutlery::after {
  content: "\f2e7\f2e7"; }
.fad.fa-arrow-up-wide-short::after, .fa-duotone.fa-arrow-up-wide-short::after {
  content: "\f161\f161"; }
.fad.fa-sort-amount-up::after, .fa-duotone.fa-sort-amount-up::after {
  content: "\f161\f161"; }
.fad.fa-balloons::after, .fa-duotone.fa-balloons::after {
  content: "\e2e4\e2e4"; }
.fad.fa-mill-sign::after, .fa-duotone.fa-mill-sign::after {
  content: "\e1ed\e1ed"; }
.fad.fa-bowl-rice::after, .fa-duotone.fa-bowl-rice::after {
  content: "\e2eb\e2eb"; }
.fad.fa-timeline-arrow::after, .fa-duotone.fa-timeline-arrow::after {
  content: "\e29d\e29d"; }
.fad.fa-skull::after, .fa-duotone.fa-skull::after {
  content: "\f54c\f54c"; }
.fad.fa-game-board-simple::after, .fa-duotone.fa-game-board-simple::after {
  content: "\f868\f868"; }
.fad.fa-game-board-alt::after, .fa-duotone.fa-game-board-alt::after {
  content: "\f868\f868"; }
.fad.fa-circle-video::after, .fa-duotone.fa-circle-video::after {
  content: "\e12b\e12b"; }
.fad.fa-video-circle::after, .fa-duotone.fa-video-circle::after {
  content: "\e12b\e12b"; }
.fad.fa-chart-scatter-bubble::after, .fa-duotone.fa-chart-scatter-bubble::after {
  content: "\e0e9\e0e9"; }
.fad.fa-house-turret::after, .fa-duotone.fa-house-turret::after {
  content: "\e1b4\e1b4"; }
.fad.fa-banana::after, .fa-duotone.fa-banana::after {
  content: "\e2e5\e2e5"; }
.fad.fa-hand-holding-skull::after, .fa-duotone.fa-hand-holding-skull::after {
  content: "\e1a4\e1a4"; }
.fad.fa-people-dress::after, .fa-duotone.fa-people-dress::after {
  content: "\e217\e217"; }
.fad.fa-loveseat::after, .fa-duotone.fa-loveseat::after {
  content: "\f4cc\f4cc"; }
.fad.fa-couch-small::after, .fa-duotone.fa-couch-small::after {
  content: "\f4cc\f4cc"; }
.fad.fa-tower-broadcast::after, .fa-duotone.fa-tower-broadcast::after {
  content: "\f519\f519"; }
.fad.fa-broadcast-tower::after, .fa-duotone.fa-broadcast-tower::after {
  content: "\f519\f519"; }
.fad.fa-truck-pickup::after, .fa-duotone.fa-truck-pickup::after {
  content: "\f63c\f63c"; }
.fad.fa-block-quote::after, .fa-duotone.fa-block-quote::after {
  content: "\e0b5\e0b5"; }
.fad.fa-up-long::after, .fa-duotone.fa-up-long::after {
  content: "\f30c\f30c"; }
.fad.fa-long-arrow-alt-up::after, .fa-duotone.fa-long-arrow-alt-up::after {
  content: "\f30c\f30c"; }
.fad.fa-stop::after, .fa-duotone.fa-stop::after {
  content: "\f04d\f04d"; }
.fad.fa-code-merge::after, .fa-duotone.fa-code-merge::after {
  content: "\f387\f387"; }
.fad.fa-money-check-dollar-pen::after, .fa-duotone.fa-money-check-dollar-pen::after {
  content: "\f873\f873"; }
.fad.fa-money-check-edit-alt::after, .fa-duotone.fa-money-check-edit-alt::after {
  content: "\f873\f873"; }
.fad.fa-up-from-line::after, .fa-duotone.fa-up-from-line::after {
  content: "\f346\f346"; }
.fad.fa-arrow-alt-from-bottom::after, .fa-duotone.fa-arrow-alt-from-bottom::after {
  content: "\f346\f346"; }
.fad.fa-upload::after, .fa-duotone.fa-upload::after {
  content: "\f093\f093"; }
.fad.fa-hurricane::after, .fa-duotone.fa-hurricane::after {
  content: "\f751\f751"; }
.fad.fa-people-pants::after, .fa-duotone.fa-people-pants::after {
  content: "\e219\e219"; }
.fad.fa-mound::after, .fa-duotone.fa-mound::after {
  content: "\e52d\e52d"; }
.fad.fa-windsock::after, .fa-duotone.fa-windsock::after {
  content: "\f777\f777"; }
.fad.fa-circle-half::after, .fa-duotone.fa-circle-half::after {
  content: "\e110\e110"; }
.fad.fa-brake-warning::after, .fa-duotone.fa-brake-warning::after {
  content: "\e0c7\e0c7"; }
.fad.fa-toilet-portable::after, .fa-duotone.fa-toilet-portable::after {
  content: "\e583\e583"; }
.fad.fa-compact-disc::after, .fa-duotone.fa-compact-disc::after {
  content: "\f51f\f51f"; }
.fad.fa-file-arrow-down::after, .fa-duotone.fa-file-arrow-down::after {
  content: "\f56d\f56d"; }
.fad.fa-file-download::after, .fa-duotone.fa-file-download::after {
  content: "\f56d\f56d"; }
.fad.fa-saxophone-fire::after, .fa-duotone.fa-saxophone-fire::after {
  content: "\f8db\f8db"; }
.fad.fa-sax-hot::after, .fa-duotone.fa-sax-hot::after {
  content: "\f8db\f8db"; }
.fad.fa-camera-web-slash::after, .fa-duotone.fa-camera-web-slash::after {
  content: "\f833\f833"; }
.fad.fa-webcam-slash::after, .fa-duotone.fa-webcam-slash::after {
  content: "\f833\f833"; }
.fad.fa-folder-medical::after, .fa-duotone.fa-folder-medical::after {
  content: "\e18c\e18c"; }
.fad.fa-folder-gear::after, .fa-duotone.fa-folder-gear::after {
  content: "\e187\e187"; }
.fad.fa-folder-cog::after, .fa-duotone.fa-folder-cog::after {
  content: "\e187\e187"; }
.fad.fa-hand-wave::after, .fa-duotone.fa-hand-wave::after {
  content: "\e1a7\e1a7"; }
.fad.fa-arrow-up-arrow-down::after, .fa-duotone.fa-arrow-up-arrow-down::after {
  content: "\e099\e099"; }
.fad.fa-sort-up-down::after, .fa-duotone.fa-sort-up-down::after {
  content: "\e099\e099"; }
.fad.fa-caravan::after, .fa-duotone.fa-caravan::after {
  content: "\f8ff\f8ff"; }
.fad.fa-shield-cat::after, .fa-duotone.fa-shield-cat::after {
  content: "\e572\e572"; }
.fad.fa-message-slash::after, .fa-duotone.fa-message-slash::after {
  content: "\f4a9\f4a9"; }
.fad.fa-comment-alt-slash::after, .fa-duotone.fa-comment-alt-slash::after {
  content: "\f4a9\f4a9"; }
.fad.fa-bolt::after, .fa-duotone.fa-bolt::after {
  content: "\f0e7\f0e7"; }
.fad.fa-zap::after, .fa-duotone.fa-zap::after {
  content: "\f0e7\f0e7"; }
.fad.fa-trash-can-check::after, .fa-duotone.fa-trash-can-check::after {
  content: "\e2a9\e2a9"; }
.fad.fa-glass-water::after, .fa-duotone.fa-glass-water::after {
  content: "\e4f4\e4f4"; }
.fad.fa-oil-well::after, .fa-duotone.fa-oil-well::after {
  content: "\e532\e532"; }
.fad.fa-person-simple::after, .fa-duotone.fa-person-simple::after {
  content: "\e220\e220"; }
.fad.fa-vault::after, .fa-duotone.fa-vault::after {
  content: "\e2c5\e2c5"; }
.fad.fa-mars::after, .fa-duotone.fa-mars::after {
  content: "\f222\f222"; }
.fad.fa-toilet::after, .fa-duotone.fa-toilet::after {
  content: "\f7d8\f7d8"; }
.fad.fa-plane-circle-xmark::after, .fa-duotone.fa-plane-circle-xmark::after {
  content: "\e557\e557"; }
.fad.fa-yen-sign::after, .fa-duotone.fa-yen-sign::after {
  content: "\f157\f157"; }
.fad.fa-cny::after, .fa-duotone.fa-cny::after {
  content: "\f157\f157"; }
.fad.fa-jpy::after, .fa-duotone.fa-jpy::after {
  content: "\f157\f157"; }
.fad.fa-rmb::after, .fa-duotone.fa-rmb::after {
  content: "\f157\f157"; }
.fad.fa-yen::after, .fa-duotone.fa-yen::after {
  content: "\f157\f157"; }
.fad.fa-notes::after, .fa-duotone.fa-notes::after {
  content: "\e202\e202"; }
.fad.fa-ruble-sign::after, .fa-duotone.fa-ruble-sign::after {
  content: "\f158\f158"; }
.fad.fa-rouble::after, .fa-duotone.fa-rouble::after {
  content: "\f158\f158"; }
.fad.fa-rub::after, .fa-duotone.fa-rub::after {
  content: "\f158\f158"; }
.fad.fa-ruble::after, .fa-duotone.fa-ruble::after {
  content: "\f158\f158"; }
.fad.fa-trash-undo::after, .fa-duotone.fa-trash-undo::after {
  content: "\f895\f895"; }
.fad.fa-trash-arrow-turn-left::after, .fa-duotone.fa-trash-arrow-turn-left::after {
  content: "\f895\f895"; }
.fad.fa-champagne-glass::after, .fa-duotone.fa-champagne-glass::after {
  content: "\f79e\f79e"; }
.fad.fa-glass-champagne::after, .fa-duotone.fa-glass-champagne::after {
  content: "\f79e\f79e"; }
.fad.fa-objects-align-center-horizontal::after, .fa-duotone.fa-objects-align-center-horizontal::after {
  content: "\e3bc\e3bc"; }
.fad.fa-sun::after, .fa-duotone.fa-sun::after {
  content: "\f185\f185"; }
.fad.fa-trash-can-slash::after, .fa-duotone.fa-trash-can-slash::after {
  content: "\e2ad\e2ad"; }
.fad.fa-trash-alt-slash::after, .fa-duotone.fa-trash-alt-slash::after {
  content: "\e2ad\e2ad"; }
.fad.fa-screen-users::after, .fa-duotone.fa-screen-users::after {
  content: "\f63d\f63d"; }
.fad.fa-users-class::after, .fa-duotone.fa-users-class::after {
  content: "\f63d\f63d"; }
.fad.fa-guitar::after, .fa-duotone.fa-guitar::after {
  content: "\f7a6\f7a6"; }
.fad.fa-square-arrow-left::after, .fa-duotone.fa-square-arrow-left::after {
  content: "\f33a\f33a"; }
.fad.fa-arrow-square-left::after, .fa-duotone.fa-arrow-square-left::after {
  content: "\f33a\f33a"; }
.fad.fa-square-8::after, .fa-duotone.fa-square-8::after {
  content: "\e25d\e25d"; }
.fad.fa-face-smile-hearts::after, .fa-duotone.fa-face-smile-hearts::after {
  content: "\e390\e390"; }
.fad.fa-brackets-square::after, .fa-duotone.fa-brackets-square::after {
  content: "\f7e9\f7e9"; }
.fad.fa-brackets::after, .fa-duotone.fa-brackets::after {
  content: "\f7e9\f7e9"; }
.fad.fa-laptop-arrow-down::after, .fa-duotone.fa-laptop-arrow-down::after {
  content: "\e1c6\e1c6"; }
.fad.fa-hockey-stick-puck::after, .fa-duotone.fa-hockey-stick-puck::after {
  content: "\e3ae\e3ae"; }
.fad.fa-house-tree::after, .fa-duotone.fa-house-tree::after {
  content: "\e1b3\e1b3"; }
.fad.fa-signal-fair::after, .fa-duotone.fa-signal-fair::after {
  content: "\f68d\f68d"; }
.fad.fa-signal-2::after, .fa-duotone.fa-signal-2::after {
  content: "\f68d\f68d"; }
.fad.fa-face-laugh-wink::after, .fa-duotone.fa-face-laugh-wink::after {
  content: "\f59c\f59c"; }
.fad.fa-laugh-wink::after, .fa-duotone.fa-laugh-wink::after {
  content: "\f59c\f59c"; }
.fad.fa-circle-dollar::after, .fa-duotone.fa-circle-dollar::after {
  content: "\f2e8\f2e8"; }
.fad.fa-dollar-circle::after, .fa-duotone.fa-dollar-circle::after {
  content: "\f2e8\f2e8"; }
.fad.fa-usd-circle::after, .fa-duotone.fa-usd-circle::after {
  content: "\f2e8\f2e8"; }
.fad.fa-horse-head::after, .fa-duotone.fa-horse-head::after {
  content: "\f7ab\f7ab"; }
.fad.fa-arrows-repeat::after, .fa-duotone.fa-arrows-repeat::after {
  content: "\f364\f364"; }
.fad.fa-repeat-alt::after, .fa-duotone.fa-repeat-alt::after {
  content: "\f364\f364"; }
.fad.fa-bore-hole::after, .fa-duotone.fa-bore-hole::after {
  content: "\e4c3\e4c3"; }
.fad.fa-industry::after, .fa-duotone.fa-industry::after {
  content: "\f275\f275"; }
.fad.fa-image-polaroid::after, .fa-duotone.fa-image-polaroid::after {
  content: "\f8c4\f8c4"; }
.fad.fa-wave-triangle::after, .fa-duotone.fa-wave-triangle::after {
  content: "\f89a\f89a"; }
.fad.fa-circle-down::after, .fa-duotone.fa-circle-down::after {
  content: "\f358\f358"; }
.fad.fa-arrow-alt-circle-down::after, .fa-duotone.fa-arrow-alt-circle-down::after {
  content: "\f358\f358"; }
.fad.fa-grill::after, .fa-duotone.fa-grill::after {
  content: "\e5a3\e5a3"; }
.fad.fa-arrows-turn-to-dots::after, .fa-duotone.fa-arrows-turn-to-dots::after {
  content: "\e4c1\e4c1"; }
.fad.fa-chart-mixed::after, .fa-duotone.fa-chart-mixed::after {
  content: "\f643\f643"; }
.fad.fa-analytics::after, .fa-duotone.fa-analytics::after {
  content: "\f643\f643"; }
.fad.fa-florin-sign::after, .fa-duotone.fa-florin-sign::after {
  content: "\e184\e184"; }
.fad.fa-arrow-down-short-wide::after, .fa-duotone.fa-arrow-down-short-wide::after {
  content: "\f884\f884"; }
.fad.fa-sort-amount-desc::after, .fa-duotone.fa-sort-amount-desc::after {
  content: "\f884\f884"; }
.fad.fa-sort-amount-down-alt::after, .fa-duotone.fa-sort-amount-down-alt::after {
  content: "\f884\f884"; }
.fad.fa-less-than::after, .fa-duotone.fa-less-than::after {
  content: "\3c\3c"; }
.fad.fa-display-code::after, .fa-duotone.fa-display-code::after {
  content: "\e165\e165"; }
.fad.fa-desktop-code::after, .fa-duotone.fa-desktop-code::after {
  content: "\e165\e165"; }
.fad.fa-face-drooling::after, .fa-duotone.fa-face-drooling::after {
  content: "\e372\e372"; }
.fad.fa-oil-temperature::after, .fa-duotone.fa-oil-temperature::after {
  content: "\f614\f614"; }
.fad.fa-oil-temp::after, .fa-duotone.fa-oil-temp::after {
  content: "\f614\f614"; }
.fad.fa-square-question::after, .fa-duotone.fa-square-question::after {
  content: "\f2fd\f2fd"; }
.fad.fa-question-square::after, .fa-duotone.fa-question-square::after {
  content: "\f2fd\f2fd"; }
.fad.fa-air-conditioner::after, .fa-duotone.fa-air-conditioner::after {
  content: "\f8f4\f8f4"; }
.fad.fa-angle-down::after, .fa-duotone.fa-angle-down::after {
  content: "\f107\f107"; }
.fad.fa-mountains::after, .fa-duotone.fa-mountains::after {
  content: "\f6fd\f6fd"; }
.fad.fa-omega::after, .fa-duotone.fa-omega::after {
  content: "\f67a\f67a"; }
.fad.fa-car-tunnel::after, .fa-duotone.fa-car-tunnel::after {
  content: "\e4de\e4de"; }
.fad.fa-person-dolly-empty::after, .fa-duotone.fa-person-dolly-empty::after {
  content: "\f4d1\f4d1"; }
.fad.fa-pan-food::after, .fa-duotone.fa-pan-food::after {
  content: "\e42b\e42b"; }
.fad.fa-head-side-cough::after, .fa-duotone.fa-head-side-cough::after {
  content: "\e061\e061"; }
.fad.fa-grip-lines::after, .fa-duotone.fa-grip-lines::after {
  content: "\f7a4\f7a4"; }
.fad.fa-thumbs-down::after, .fa-duotone.fa-thumbs-down::after {
  content: "\f165\f165"; }
.fad.fa-user-lock::after, .fa-duotone.fa-user-lock::after {
  content: "\f502\f502"; }
.fad.fa-arrow-right-long::after, .fa-duotone.fa-arrow-right-long::after {
  content: "\f178\f178"; }
.fad.fa-long-arrow-right::after, .fa-duotone.fa-long-arrow-right::after {
  content: "\f178\f178"; }
.fad.fa-tickets-airline::after, .fa-duotone.fa-tickets-airline::after {
  content: "\e29b\e29b"; }
.fad.fa-anchor-circle-xmark::after, .fa-duotone.fa-anchor-circle-xmark::after {
  content: "\e4ac\e4ac"; }
.fad.fa-ellipsis::after, .fa-duotone.fa-ellipsis::after {
  content: "\f141\f141"; }
.fad.fa-ellipsis-h::after, .fa-duotone.fa-ellipsis-h::after {
  content: "\f141\f141"; }
.fad.fa-nfc-slash::after, .fa-duotone.fa-nfc-slash::after {
  content: "\e1fc\e1fc"; }
.fad.fa-chess-pawn::after, .fa-duotone.fa-chess-pawn::after {
  content: "\f443\f443"; }
.fad.fa-kit-medical::after, .fa-duotone.fa-kit-medical::after {
  content: "\f479\f479"; }
.fad.fa-first-aid::after, .fa-duotone.fa-first-aid::after {
  content: "\f479\f479"; }
.fad.fa-grid-2-plus::after, .fa-duotone.fa-grid-2-plus::after {
  content: "\e197\e197"; }
.fad.fa-bells::after, .fa-duotone.fa-bells::after {
  content: "\f77f\f77f"; }
.fad.fa-person-through-window::after, .fa-duotone.fa-person-through-window::after {
  content: "\e5a9\e5a9"; }
.fad.fa-toolbox::after, .fa-duotone.fa-toolbox::after {
  content: "\f552\f552"; }
.fad.fa-envelope-dot::after, .fa-duotone.fa-envelope-dot::after {
  content: "\e16f\e16f"; }
.fad.fa-envelope-badge::after, .fa-duotone.fa-envelope-badge::after {
  content: "\e16f\e16f"; }
.fad.fa-hands-holding-circle::after, .fa-duotone.fa-hands-holding-circle::after {
  content: "\e4fb\e4fb"; }
.fad.fa-bug::after, .fa-duotone.fa-bug::after {
  content: "\f188\f188"; }
.fad.fa-bowl-chopsticks::after, .fa-duotone.fa-bowl-chopsticks::after {
  content: "\e2e9\e2e9"; }
.fad.fa-credit-card::after, .fa-duotone.fa-credit-card::after {
  content: "\f09d\f09d"; }
.fad.fa-credit-card-alt::after, .fa-duotone.fa-credit-card-alt::after {
  content: "\f09d\f09d"; }
.fad.fa-circle-s::after, .fa-duotone.fa-circle-s::after {
  content: "\e121\e121"; }
.fad.fa-box-ballot::after, .fa-duotone.fa-box-ballot::after {
  content: "\f735\f735"; }
.fad.fa-car::after, .fa-duotone.fa-car::after {
  content: "\f1b9\f1b9"; }
.fad.fa-automobile::after, .fa-duotone.fa-automobile::after {
  content: "\f1b9\f1b9"; }
.fad.fa-hand-holding-hand::after, .fa-duotone.fa-hand-holding-hand::after {
  content: "\e4f7\e4f7"; }
.fad.fa-user-tie-hair::after, .fa-duotone.fa-user-tie-hair::after {
  content: "\e45f\e45f"; }
.fad.fa-podium-star::after, .fa-duotone.fa-podium-star::after {
  content: "\f758\f758"; }
.fad.fa-user-hair-mullet::after, .fa-duotone.fa-user-hair-mullet::after {
  content: "\e45c\e45c"; }
.fad.fa-business-front::after, .fa-duotone.fa-business-front::after {
  content: "\e45c\e45c"; }
.fad.fa-party-back::after, .fa-duotone.fa-party-back::after {
  content: "\e45c\e45c"; }
.fad.fa-trian-balbot::after, .fa-duotone.fa-trian-balbot::after {
  content: "\e45c\e45c"; }
.fad.fa-microphone-stand::after, .fa-duotone.fa-microphone-stand::after {
  content: "\f8cb\f8cb"; }
.fad.fa-book-open-reader::after, .fa-duotone.fa-book-open-reader::after {
  content: "\f5da\f5da"; }
.fad.fa-book-reader::after, .fa-duotone.fa-book-reader::after {
  content: "\f5da\f5da"; }
.fad.fa-family-dress::after, .fa-duotone.fa-family-dress::after {
  content: "\e301\e301"; }
.fad.fa-circle-x::after, .fa-duotone.fa-circle-x::after {
  content: "\e12e\e12e"; }
.fad.fa-cabin::after, .fa-duotone.fa-cabin::after {
  content: "\e46d\e46d"; }
.fad.fa-mountain-sun::after, .fa-duotone.fa-mountain-sun::after {
  content: "\e52f\e52f"; }
.fad.fa-chart-simple-horizontal::after, .fa-duotone.fa-chart-simple-horizontal::after {
  content: "\e474\e474"; }
.fad.fa-arrows-left-right-to-line::after, .fa-duotone.fa-arrows-left-right-to-line::after {
  content: "\e4ba\e4ba"; }
.fad.fa-hand-back-point-left::after, .fa-duotone.fa-hand-back-point-left::after {
  content: "\e19f\e19f"; }
.fad.fa-message-dots::after, .fa-duotone.fa-message-dots::after {
  content: "\f4a3\f4a3"; }
.fad.fa-comment-alt-dots::after, .fa-duotone.fa-comment-alt-dots::after {
  content: "\f4a3\f4a3"; }
.fad.fa-messaging::after, .fa-duotone.fa-messaging::after {
  content: "\f4a3\f4a3"; }
.fad.fa-file-heart::after, .fa-duotone.fa-file-heart::after {
  content: "\e176\e176"; }
.fad.fa-beer-mug::after, .fa-duotone.fa-beer-mug::after {
  content: "\e0b3\e0b3"; }
.fad.fa-beer-foam::after, .fa-duotone.fa-beer-foam::after {
  content: "\e0b3\e0b3"; }
.fad.fa-dice-d20::after, .fa-duotone.fa-dice-d20::after {
  content: "\f6cf\f6cf"; }
.fad.fa-drone::after, .fa-duotone.fa-drone::after {
  content: "\f85f\f85f"; }
.fad.fa-truck-droplet::after, .fa-duotone.fa-truck-droplet::after {
  content: "\e58c\e58c"; }
.fad.fa-file-circle-xmark::after, .fa-duotone.fa-file-circle-xmark::after {
  content: "\e5a1\e5a1"; }
.fad.fa-temperature-arrow-up::after, .fa-duotone.fa-temperature-arrow-up::after {
  content: "\e040\e040"; }
.fad.fa-temperature-up::after, .fa-duotone.fa-temperature-up::after {
  content: "\e040\e040"; }
.fad.fa-medal::after, .fa-duotone.fa-medal::after {
  content: "\f5a2\f5a2"; }
.fad.fa-bed::after, .fa-duotone.fa-bed::after {
  content: "\f236\f236"; }
.fad.fa-book-copy::after, .fa-duotone.fa-book-copy::after {
  content: "\e0be\e0be"; }
.fad.fa-square-h::after, .fa-duotone.fa-square-h::after {
  content: "\f0fd\f0fd"; }
.fad.fa-h-square::after, .fa-duotone.fa-h-square::after {
  content: "\f0fd\f0fd"; }
.fad.fa-square-c::after, .fa-duotone.fa-square-c::after {
  content: "\e266\e266"; }
.fad.fa-clock-two::after, .fa-duotone.fa-clock-two::after {
  content: "\e35a\e35a"; }
.fad.fa-square-ellipsis-vertical::after, .fa-duotone.fa-square-ellipsis-vertical::after {
  content: "\e26f\e26f"; }
.fad.fa-podcast::after, .fa-duotone.fa-podcast::after {
  content: "\f2ce\f2ce"; }
.fad.fa-bee::after, .fa-duotone.fa-bee::after {
  content: "\e0b2\e0b2"; }
.fad.fa-temperature-full::after, .fa-duotone.fa-temperature-full::after {
  content: "\f2c7\f2c7"; }
.fad.fa-temperature-4::after, .fa-duotone.fa-temperature-4::after {
  content: "\f2c7\f2c7"; }
.fad.fa-thermometer-4::after, .fa-duotone.fa-thermometer-4::after {
  content: "\f2c7\f2c7"; }
.fad.fa-thermometer-full::after, .fa-duotone.fa-thermometer-full::after {
  content: "\f2c7\f2c7"; }
.fad.fa-bell::after, .fa-duotone.fa-bell::after {
  content: "\f0f3\f0f3"; }
.fad.fa-candy-bar::after, .fa-duotone.fa-candy-bar::after {
  content: "\e3e8\e3e8"; }
.fad.fa-chocolate-bar::after, .fa-duotone.fa-chocolate-bar::after {
  content: "\e3e8\e3e8"; }
.fad.fa-xmark-large::after, .fa-duotone.fa-xmark-large::after {
  content: "\e59b\e59b"; }
.fad.fa-pinata::after, .fa-duotone.fa-pinata::after {
  content: "\e3c3\e3c3"; }
.fad.fa-arrows-from-line::after, .fa-duotone.fa-arrows-from-line::after {
  content: "\e0a4\e0a4"; }
.fad.fa-superscript::after, .fa-duotone.fa-superscript::after {
  content: "\f12b\f12b"; }
.fad.fa-bowl-spoon::after, .fa-duotone.fa-bowl-spoon::after {
  content: "\e3e0\e3e0"; }
.fad.fa-hexagon-check::after, .fa-duotone.fa-hexagon-check::after {
  content: "\e416\e416"; }
.fad.fa-plug-circle-xmark::after, .fa-duotone.fa-plug-circle-xmark::after {
  content: "\e560\e560"; }
.fad.fa-star-of-life::after, .fa-duotone.fa-star-of-life::after {
  content: "\f621\f621"; }
.fad.fa-phone-slash::after, .fa-duotone.fa-phone-slash::after {
  content: "\f3dd\f3dd"; }
.fad.fa-traffic-light-stop::after, .fa-duotone.fa-traffic-light-stop::after {
  content: "\f63a\f63a"; }
.fad.fa-paint-roller::after, .fa-duotone.fa-paint-roller::after {
  content: "\f5aa\f5aa"; }
.fad.fa-accent-grave::after, .fa-duotone.fa-accent-grave::after {
  content: "\60\60"; }
.fad.fa-handshake-angle::after, .fa-duotone.fa-handshake-angle::after {
  content: "\f4c4\f4c4"; }
.fad.fa-hands-helping::after, .fa-duotone.fa-hands-helping::after {
  content: "\f4c4\f4c4"; }
.fad.fa-circle-0::after, .fa-duotone.fa-circle-0::after {
  content: "\e0ed\e0ed"; }
.fad.fa-dial-med-low::after, .fa-duotone.fa-dial-med-low::after {
  content: "\e160\e160"; }
.fad.fa-location-dot::after, .fa-duotone.fa-location-dot::after {
  content: "\f3c5\f3c5"; }
.fad.fa-map-marker-alt::after, .fa-duotone.fa-map-marker-alt::after {
  content: "\f3c5\f3c5"; }
.fad.fa-crab::after, .fa-duotone.fa-crab::after {
  content: "\e3ff\e3ff"; }
.fad.fa-box-open-full::after, .fa-duotone.fa-box-open-full::after {
  content: "\f49c\f49c"; }
.fad.fa-box-full::after, .fa-duotone.fa-box-full::after {
  content: "\f49c\f49c"; }
.fad.fa-file::after, .fa-duotone.fa-file::after {
  content: "\f15b\f15b"; }
.fad.fa-greater-than::after, .fa-duotone.fa-greater-than::after {
  content: "\3e\3e"; }
.fad.fa-quotes::after, .fa-duotone.fa-quotes::after {
  content: "\e234\e234"; }
.fad.fa-pretzel::after, .fa-duotone.fa-pretzel::after {
  content: "\e441\e441"; }
.fad.fa-person-swimming::after, .fa-duotone.fa-person-swimming::after {
  content: "\f5c4\f5c4"; }
.fad.fa-swimmer::after, .fa-duotone.fa-swimmer::after {
  content: "\f5c4\f5c4"; }
.fad.fa-arrow-down::after, .fa-duotone.fa-arrow-down::after {
  content: "\f063\f063"; }
.fad.fa-user-robot-xmarks::after, .fa-duotone.fa-user-robot-xmarks::after {
  content: "\e4a7\e4a7"; }
.fad.fa-message-quote::after, .fa-duotone.fa-message-quote::after {
  content: "\e1e4\e1e4"; }
.fad.fa-comment-alt-quote::after, .fa-duotone.fa-comment-alt-quote::after {
  content: "\e1e4\e1e4"; }
.fad.fa-candy-corn::after, .fa-duotone.fa-candy-corn::after {
  content: "\f6bd\f6bd"; }
.fad.fa-folder-magnifying-glass::after, .fa-duotone.fa-folder-magnifying-glass::after {
  content: "\e18b\e18b"; }
.fad.fa-folder-search::after, .fa-duotone.fa-folder-search::after {
  content: "\e18b\e18b"; }
.fad.fa-notebook::after, .fa-duotone.fa-notebook::after {
  content: "\e201\e201"; }
.fad.fa-droplet::after, .fa-duotone.fa-droplet::after {
  content: "\f043\f043"; }
.fad.fa-tint::after, .fa-duotone.fa-tint::after {
  content: "\f043\f043"; }
.fad.fa-bullseye-pointer::after, .fa-duotone.fa-bullseye-pointer::after {
  content: "\f649\f649"; }
.fad.fa-eraser::after, .fa-duotone.fa-eraser::after {
  content: "\f12d\f12d"; }
.fad.fa-hexagon-image::after, .fa-duotone.fa-hexagon-image::after {
  content: "\e504\e504"; }
.fad.fa-earth-americas::after, .fa-duotone.fa-earth-americas::after {
  content: "\f57d\f57d"; }
.fad.fa-earth::after, .fa-duotone.fa-earth::after {
  content: "\f57d\f57d"; }
.fad.fa-earth-america::after, .fa-duotone.fa-earth-america::after {
  content: "\f57d\f57d"; }
.fad.fa-globe-americas::after, .fa-duotone.fa-globe-americas::after {
  content: "\f57d\f57d"; }
.fad.fa-crate-apple::after, .fa-duotone.fa-crate-apple::after {
  content: "\f6b1\f6b1"; }
.fad.fa-apple-crate::after, .fa-duotone.fa-apple-crate::after {
  content: "\f6b1\f6b1"; }
.fad.fa-person-burst::after, .fa-duotone.fa-person-burst::after {
  content: "\e53b\e53b"; }
.fad.fa-game-board::after, .fa-duotone.fa-game-board::after {
  content: "\f867\f867"; }
.fad.fa-hat-chef::after, .fa-duotone.fa-hat-chef::after {
  content: "\f86b\f86b"; }
.fad.fa-hand-back-point-right::after, .fa-duotone.fa-hand-back-point-right::after {
  content: "\e1a1\e1a1"; }
.fad.fa-dove::after, .fa-duotone.fa-dove::after {
  content: "\f4ba\f4ba"; }
.fad.fa-snowflake-droplets::after, .fa-duotone.fa-snowflake-droplets::after {
  content: "\e5c1\e5c1"; }
.fad.fa-battery-empty::after, .fa-duotone.fa-battery-empty::after {
  content: "\f244\f244"; }
.fad.fa-battery-0::after, .fa-duotone.fa-battery-0::after {
  content: "\f244\f244"; }
.fad.fa-grid-4::after, .fa-duotone.fa-grid-4::after {
  content: "\e198\e198"; }
.fad.fa-socks::after, .fa-duotone.fa-socks::after {
  content: "\f696\f696"; }
.fad.fa-face-sunglasses::after, .fa-duotone.fa-face-sunglasses::after {
  content: "\e398\e398"; }
.fad.fa-inbox::after, .fa-duotone.fa-inbox::after {
  content: "\f01c\f01c"; }
.fad.fa-square-0::after, .fa-duotone.fa-square-0::after {
  content: "\e255\e255"; }
.fad.fa-section::after, .fa-duotone.fa-section::after {
  content: "\e447\e447"; }
.fad.fa-square-this-way-up::after, .fa-duotone.fa-square-this-way-up::after {
  content: "\f49f\f49f"; }
.fad.fa-box-up::after, .fa-duotone.fa-box-up::after {
  content: "\f49f\f49f"; }
.fad.fa-gauge-high::after, .fa-duotone.fa-gauge-high::after {
  content: "\f625\f625"; }
.fad.fa-tachometer-alt::after, .fa-duotone.fa-tachometer-alt::after {
  content: "\f625\f625"; }
.fad.fa-tachometer-alt-fast::after, .fa-duotone.fa-tachometer-alt-fast::after {
  content: "\f625\f625"; }
.fad.fa-square-ampersand::after, .fa-duotone.fa-square-ampersand::after {
  content: "\e260\e260"; }
.fad.fa-envelope-open-text::after, .fa-duotone.fa-envelope-open-text::after {
  content: "\f658\f658"; }
.fad.fa-lamp-desk::after, .fa-duotone.fa-lamp-desk::after {
  content: "\e014\e014"; }
.fad.fa-hospital::after, .fa-duotone.fa-hospital::after {
  content: "\f0f8\f0f8"; }
.fad.fa-hospital-alt::after, .fa-duotone.fa-hospital-alt::after {
  content: "\f0f8\f0f8"; }
.fad.fa-hospital-wide::after, .fa-duotone.fa-hospital-wide::after {
  content: "\f0f8\f0f8"; }
.fad.fa-poll-people::after, .fa-duotone.fa-poll-people::after {
  content: "\f759\f759"; }
.fad.fa-whiskey-glass-ice::after, .fa-duotone.fa-whiskey-glass-ice::after {
  content: "\f7a1\f7a1"; }
.fad.fa-glass-whiskey-rocks::after, .fa-duotone.fa-glass-whiskey-rocks::after {
  content: "\f7a1\f7a1"; }
.fad.fa-wine-bottle::after, .fa-duotone.fa-wine-bottle::after {
  content: "\f72f\f72f"; }
.fad.fa-chess-rook::after, .fa-duotone.fa-chess-rook::after {
  content: "\f447\f447"; }
.fad.fa-user-bounty-hunter::after, .fa-duotone.fa-user-bounty-hunter::after {
  content: "\e2bf\e2bf"; }
.fad.fa-bars-staggered::after, .fa-duotone.fa-bars-staggered::after {
  content: "\f550\f550"; }
.fad.fa-reorder::after, .fa-duotone.fa-reorder::after {
  content: "\f550\f550"; }
.fad.fa-stream::after, .fa-duotone.fa-stream::after {
  content: "\f550\f550"; }
.fad.fa-diagram-sankey::after, .fa-duotone.fa-diagram-sankey::after {
  content: "\e158\e158"; }
.fad.fa-cloud-hail-mixed::after, .fa-duotone.fa-cloud-hail-mixed::after {
  content: "\f73a\f73a"; }
.fad.fa-circle-up-left::after, .fa-duotone.fa-circle-up-left::after {
  content: "\e128\e128"; }
.fad.fa-dharmachakra::after, .fa-duotone.fa-dharmachakra::after {
  content: "\f655\f655"; }
.fad.fa-objects-align-left::after, .fa-duotone.fa-objects-align-left::after {
  content: "\e3be\e3be"; }
.fad.fa-oil-can-drip::after, .fa-duotone.fa-oil-can-drip::after {
  content: "\e205\e205"; }
.fad.fa-face-smiling-hands::after, .fa-duotone.fa-face-smiling-hands::after {
  content: "\e396\e396"; }
.fad.fa-broccoli::after, .fa-duotone.fa-broccoli::after {
  content: "\e3e2\e3e2"; }
.fad.fa-route-interstate::after, .fa-duotone.fa-route-interstate::after {
  content: "\f61b\f61b"; }
.fad.fa-ear-muffs::after, .fa-duotone.fa-ear-muffs::after {
  content: "\f795\f795"; }
.fad.fa-hotdog::after, .fa-duotone.fa-hotdog::after {
  content: "\f80f\f80f"; }
.fad.fa-transporter-empty::after, .fa-duotone.fa-transporter-empty::after {
  content: "\e046\e046"; }
.fad.fa-person-walking-with-cane::after, .fa-duotone.fa-person-walking-with-cane::after {
  content: "\f29d\f29d"; }
.fad.fa-blind::after, .fa-duotone.fa-blind::after {
  content: "\f29d\f29d"; }
.fad.fa-angle-90::after, .fa-duotone.fa-angle-90::after {
  content: "\e08d\e08d"; }
.fad.fa-rectangle-terminal::after, .fa-duotone.fa-rectangle-terminal::after {
  content: "\e236\e236"; }
.fad.fa-kite::after, .fa-duotone.fa-kite::after {
  content: "\f6f4\f6f4"; }
.fad.fa-drum::after, .fa-duotone.fa-drum::after {
  content: "\f569\f569"; }
.fad.fa-scrubber::after, .fa-duotone.fa-scrubber::after {
  content: "\f2f8\f2f8"; }
.fad.fa-ice-cream::after, .fa-duotone.fa-ice-cream::after {
  content: "\f810\f810"; }
.fad.fa-heart-circle-bolt::after, .fa-duotone.fa-heart-circle-bolt::after {
  content: "\e4fc\e4fc"; }
.fad.fa-fish-bones::after, .fa-duotone.fa-fish-bones::after {
  content: "\e304\e304"; }
.fad.fa-deer-rudolph::after, .fa-duotone.fa-deer-rudolph::after {
  content: "\f78f\f78f"; }
.fad.fa-fax::after, .fa-duotone.fa-fax::after {
  content: "\f1ac\f1ac"; }
.fad.fa-paragraph::after, .fa-duotone.fa-paragraph::after {
  content: "\f1dd\f1dd"; }
.fad.fa-head-side-heart::after, .fa-duotone.fa-head-side-heart::after {
  content: "\e1aa\e1aa"; }
.fad.fa-square-e::after, .fa-duotone.fa-square-e::after {
  content: "\e26d\e26d"; }
.fad.fa-meter-fire::after, .fa-duotone.fa-meter-fire::after {
  content: "\e1eb\e1eb"; }
.fad.fa-cloud-hail::after, .fa-duotone.fa-cloud-hail::after {
  content: "\f739\f739"; }
.fad.fa-check-to-slot::after, .fa-duotone.fa-check-to-slot::after {
  content: "\f772\f772"; }
.fad.fa-vote-yea::after, .fa-duotone.fa-vote-yea::after {
  content: "\f772\f772"; }
.fad.fa-money-from-bracket::after, .fa-duotone.fa-money-from-bracket::after {
  content: "\e312\e312"; }
.fad.fa-star-half::after, .fa-duotone.fa-star-half::after {
  content: "\f089\f089"; }
.fad.fa-car-bus::after, .fa-duotone.fa-car-bus::after {
  content: "\f85a\f85a"; }
.fad.fa-speaker::after, .fa-duotone.fa-speaker::after {
  content: "\f8df\f8df"; }
.fad.fa-timer::after, .fa-duotone.fa-timer::after {
  content: "\e29e\e29e"; }
.fad.fa-boxes-stacked::after, .fa-duotone.fa-boxes-stacked::after {
  content: "\f468\f468"; }
.fad.fa-boxes::after, .fa-duotone.fa-boxes::after {
  content: "\f468\f468"; }
.fad.fa-boxes-alt::after, .fa-duotone.fa-boxes-alt::after {
  content: "\f468\f468"; }
.fad.fa-grill-hot::after, .fa-duotone.fa-grill-hot::after {
  content: "\e5a5\e5a5"; }
.fad.fa-ballot-check::after, .fa-duotone.fa-ballot-check::after {
  content: "\f733\f733"; }
.fad.fa-link::after, .fa-duotone.fa-link::after {
  content: "\f0c1\f0c1"; }
.fad.fa-chain::after, .fa-duotone.fa-chain::after {
  content: "\f0c1\f0c1"; }
.fad.fa-ear-listen::after, .fa-duotone.fa-ear-listen::after {
  content: "\f2a2\f2a2"; }
.fad.fa-assistive-listening-systems::after, .fa-duotone.fa-assistive-listening-systems::after {
  content: "\f2a2\f2a2"; }
.fad.fa-file-minus::after, .fa-duotone.fa-file-minus::after {
  content: "\f318\f318"; }
.fad.fa-tree-city::after, .fa-duotone.fa-tree-city::after {
  content: "\e587\e587"; }
.fad.fa-play::after, .fa-duotone.fa-play::after {
  content: "\f04b\f04b"; }
.fad.fa-font::after, .fa-duotone.fa-font::after {
  content: "\f031\f031"; }
.fad.fa-cup-togo::after, .fa-duotone.fa-cup-togo::after {
  content: "\f6c5\f6c5"; }
.fad.fa-coffee-togo::after, .fa-duotone.fa-coffee-togo::after {
  content: "\f6c5\f6c5"; }
.fad.fa-square-down-left::after, .fa-duotone.fa-square-down-left::after {
  content: "\e26b\e26b"; }
.fad.fa-burger-lettuce::after, .fa-duotone.fa-burger-lettuce::after {
  content: "\e3e3\e3e3"; }
.fad.fa-rupiah-sign::after, .fa-duotone.fa-rupiah-sign::after {
  content: "\e23d\e23d"; }
.fad.fa-magnifying-glass::after, .fa-duotone.fa-magnifying-glass::after {
  content: "\f002\f002"; }
.fad.fa-search::after, .fa-duotone.fa-search::after {
  content: "\f002\f002"; }
.fad.fa-table-tennis-paddle-ball::after, .fa-duotone.fa-table-tennis-paddle-ball::after {
  content: "\f45d\f45d"; }
.fad.fa-ping-pong-paddle-ball::after, .fa-duotone.fa-ping-pong-paddle-ball::after {
  content: "\f45d\f45d"; }
.fad.fa-table-tennis::after, .fa-duotone.fa-table-tennis::after {
  content: "\f45d\f45d"; }
.fad.fa-person-dots-from-line::after, .fa-duotone.fa-person-dots-from-line::after {
  content: "\f470\f470"; }
.fad.fa-diagnoses::after, .fa-duotone.fa-diagnoses::after {
  content: "\f470\f470"; }
.fad.fa-chevrons-down::after, .fa-duotone.fa-chevrons-down::after {
  content: "\f322\f322"; }
.fad.fa-chevron-double-down::after, .fa-duotone.fa-chevron-double-down::after {
  content: "\f322\f322"; }
.fad.fa-trash-can-arrow-up::after, .fa-duotone.fa-trash-can-arrow-up::after {
  content: "\f82a\f82a"; }
.fad.fa-trash-restore-alt::after, .fa-duotone.fa-trash-restore-alt::after {
  content: "\f82a\f82a"; }
.fad.fa-signal-good::after, .fa-duotone.fa-signal-good::after {
  content: "\f68e\f68e"; }
.fad.fa-signal-3::after, .fa-duotone.fa-signal-3::after {
  content: "\f68e\f68e"; }
.fad.fa-location-question::after, .fa-duotone.fa-location-question::after {
  content: "\f60b\f60b"; }
.fad.fa-map-marker-question::after, .fa-duotone.fa-map-marker-question::after {
  content: "\f60b\f60b"; }
.fad.fa-floppy-disk-circle-xmark::after, .fa-duotone.fa-floppy-disk-circle-xmark::after {
  content: "\e181\e181"; }
.fad.fa-floppy-disk-times::after, .fa-duotone.fa-floppy-disk-times::after {
  content: "\e181\e181"; }
.fad.fa-save-circle-xmark::after, .fa-duotone.fa-save-circle-xmark::after {
  content: "\e181\e181"; }
.fad.fa-save-times::after, .fa-duotone.fa-save-times::after {
  content: "\e181\e181"; }
.fad.fa-naira-sign::after, .fa-duotone.fa-naira-sign::after {
  content: "\e1f6\e1f6"; }
.fad.fa-peach::after, .fa-duotone.fa-peach::after {
  content: "\e20b\e20b"; }
.fad.fa-taxi-bus::after, .fa-duotone.fa-taxi-bus::after {
  content: "\e298\e298"; }
.fad.fa-bracket-curly::after, .fa-duotone.fa-bracket-curly::after {
  content: "\7b\7b"; }
.fad.fa-bracket-curly-left::after, .fa-duotone.fa-bracket-curly-left::after {
  content: "\7b\7b"; }
.fad.fa-lobster::after, .fa-duotone.fa-lobster::after {
  content: "\e421\e421"; }
.fad.fa-cart-flatbed-empty::after, .fa-duotone.fa-cart-flatbed-empty::after {
  content: "\f476\f476"; }
.fad.fa-dolly-flatbed-empty::after, .fa-duotone.fa-dolly-flatbed-empty::after {
  content: "\f476\f476"; }
.fad.fa-colon::after, .fa-duotone.fa-colon::after {
  content: "\3a\3a"; }
.fad.fa-cart-arrow-down::after, .fa-duotone.fa-cart-arrow-down::after {
  content: "\f218\f218"; }
.fad.fa-wand::after, .fa-duotone.fa-wand::after {
  content: "\f72a\f72a"; }
.fad.fa-walkie-talkie::after, .fa-duotone.fa-walkie-talkie::after {
  content: "\f8ef\f8ef"; }
.fad.fa-file-pen::after, .fa-duotone.fa-file-pen::after {
  content: "\f31c\f31c"; }
.fad.fa-file-edit::after, .fa-duotone.fa-file-edit::after {
  content: "\f31c\f31c"; }
.fad.fa-receipt::after, .fa-duotone.fa-receipt::after {
  content: "\f543\f543"; }
.fad.fa-table-picnic::after, .fa-duotone.fa-table-picnic::after {
  content: "\e32d\e32d"; }
.fad.fa-square-pen::after, .fa-duotone.fa-square-pen::after {
  content: "\f14b\f14b"; }
.fad.fa-pen-square::after, .fa-duotone.fa-pen-square::after {
  content: "\f14b\f14b"; }
.fad.fa-pencil-square::after, .fa-duotone.fa-pencil-square::after {
  content: "\f14b\f14b"; }
.fad.fa-circle-microphone-lines::after, .fa-duotone.fa-circle-microphone-lines::after {
  content: "\e117\e117"; }
.fad.fa-microphone-circle-alt::after, .fa-duotone.fa-microphone-circle-alt::after {
  content: "\e117\e117"; }
.fad.fa-display-slash::after, .fa-duotone.fa-display-slash::after {
  content: "\e2fa\e2fa"; }
.fad.fa-desktop-slash::after, .fa-duotone.fa-desktop-slash::after {
  content: "\e2fa\e2fa"; }
.fad.fa-suitcase-rolling::after, .fa-duotone.fa-suitcase-rolling::after {
  content: "\f5c1\f5c1"; }
.fad.fa-person-circle-exclamation::after, .fa-duotone.fa-person-circle-exclamation::after {
  content: "\e53f\e53f"; }
.fad.fa-transporter-2::after, .fa-duotone.fa-transporter-2::after {
  content: "\e044\e044"; }
.fad.fa-hands-holding-diamond::after, .fa-duotone.fa-hands-holding-diamond::after {
  content: "\f47c\f47c"; }
.fad.fa-hand-receiving::after, .fa-duotone.fa-hand-receiving::after {
  content: "\f47c\f47c"; }
.fad.fa-money-bill-simple-wave::after, .fa-duotone.fa-money-bill-simple-wave::after {
  content: "\e1f2\e1f2"; }
.fad.fa-chevron-down::after, .fa-duotone.fa-chevron-down::after {
  content: "\f078\f078"; }
.fad.fa-battery-full::after, .fa-duotone.fa-battery-full::after {
  content: "\f240\f240"; }
.fad.fa-battery::after, .fa-duotone.fa-battery::after {
  content: "\f240\f240"; }
.fad.fa-battery-5::after, .fa-duotone.fa-battery-5::after {
  content: "\f240\f240"; }
.fad.fa-bell-plus::after, .fa-duotone.fa-bell-plus::after {
  content: "\f849\f849"; }
.fad.fa-book-arrow-right::after, .fa-duotone.fa-book-arrow-right::after {
  content: "\e0b9\e0b9"; }
.fad.fa-hospitals::after, .fa-duotone.fa-hospitals::after {
  content: "\f80e\f80e"; }
.fad.fa-club::after, .fa-duotone.fa-club::after {
  content: "\f327\f327"; }
.fad.fa-skull-crossbones::after, .fa-duotone.fa-skull-crossbones::after {
  content: "\f714\f714"; }
.fad.fa-droplet-degree::after, .fa-duotone.fa-droplet-degree::after {
  content: "\f748\f748"; }
.fad.fa-dewpoint::after, .fa-duotone.fa-dewpoint::after {
  content: "\f748\f748"; }
.fad.fa-code-compare::after, .fa-duotone.fa-code-compare::after {
  content: "\e13a\e13a"; }
.fad.fa-list-ul::after, .fa-duotone.fa-list-ul::after {
  content: "\f0ca\f0ca"; }
.fad.fa-list-dots::after, .fa-duotone.fa-list-dots::after {
  content: "\f0ca\f0ca"; }
.fad.fa-hand-holding-magic::after, .fa-duotone.fa-hand-holding-magic::after {
  content: "\f6e5\f6e5"; }
.fad.fa-watermelon-slice::after, .fa-duotone.fa-watermelon-slice::after {
  content: "\e337\e337"; }
.fad.fa-circle-ellipsis::after, .fa-duotone.fa-circle-ellipsis::after {
  content: "\e10a\e10a"; }
.fad.fa-school-lock::after, .fa-duotone.fa-school-lock::after {
  content: "\e56f\e56f"; }
.fad.fa-tower-cell::after, .fa-duotone.fa-tower-cell::after {
  content: "\e585\e585"; }
.fad.fa-sd-cards::after, .fa-duotone.fa-sd-cards::after {
  content: "\e240\e240"; }
.fad.fa-down-long::after, .fa-duotone.fa-down-long::after {
  content: "\f309\f309"; }
.fad.fa-long-arrow-alt-down::after, .fa-duotone.fa-long-arrow-alt-down::after {
  content: "\f309\f309"; }
.fad.fa-envelopes::after, .fa-duotone.fa-envelopes::after {
  content: "\e170\e170"; }
.fad.fa-phone-office::after, .fa-duotone.fa-phone-office::after {
  content: "\f67d\f67d"; }
.fad.fa-ranking-star::after, .fa-duotone.fa-ranking-star::after {
  content: "\e561\e561"; }
.fad.fa-chess-king::after, .fa-duotone.fa-chess-king::after {
  content: "\f43f\f43f"; }
.fad.fa-nfc-pen::after, .fa-duotone.fa-nfc-pen::after {
  content: "\e1fa\e1fa"; }
.fad.fa-person-harassing::after, .fa-duotone.fa-person-harassing::after {
  content: "\e549\e549"; }
.fad.fa-hat-winter::after, .fa-duotone.fa-hat-winter::after {
  content: "\f7a8\f7a8"; }
.fad.fa-brazilian-real-sign::after, .fa-duotone.fa-brazilian-real-sign::after {
  content: "\e46c\e46c"; }
.fad.fa-landmark-dome::after, .fa-duotone.fa-landmark-dome::after {
  content: "\f752\f752"; }
.fad.fa-landmark-alt::after, .fa-duotone.fa-landmark-alt::after {
  content: "\f752\f752"; }
.fad.fa-bone-break::after, .fa-duotone.fa-bone-break::after {
  content: "\f5d8\f5d8"; }
.fad.fa-arrow-up::after, .fa-duotone.fa-arrow-up::after {
  content: "\f062\f062"; }
.fad.fa-down-from-dotted-line::after, .fa-duotone.fa-down-from-dotted-line::after {
  content: "\e407\e407"; }
.fad.fa-tv::after, .fa-duotone.fa-tv::after {
  content: "\f26c\f26c"; }
.fad.fa-television::after, .fa-duotone.fa-television::after {
  content: "\f26c\f26c"; }
.fad.fa-tv-alt::after, .fa-duotone.fa-tv-alt::after {
  content: "\f26c\f26c"; }
.fad.fa-border-left::after, .fa-duotone.fa-border-left::after {
  content: "\f84f\f84f"; }
.fad.fa-circle-divide::after, .fa-duotone.fa-circle-divide::after {
  content: "\e106\e106"; }
.fad.fa-shrimp::after, .fa-duotone.fa-shrimp::after {
  content: "\e448\e448"; }
.fad.fa-list-check::after, .fa-duotone.fa-list-check::after {
  content: "\f0ae\f0ae"; }
.fad.fa-tasks::after, .fa-duotone.fa-tasks::after {
  content: "\f0ae\f0ae"; }
.fad.fa-diagram-subtask::after, .fa-duotone.fa-diagram-subtask::after {
  content: "\e479\e479"; }
.fad.fa-jug-detergent::after, .fa-duotone.fa-jug-detergent::after {
  content: "\e519\e519"; }
.fad.fa-circle-user::after, .fa-duotone.fa-circle-user::after {
  content: "\f2bd\f2bd"; }
.fad.fa-user-circle::after, .fa-duotone.fa-user-circle::after {
  content: "\f2bd\f2bd"; }
.fad.fa-square-y::after, .fa-duotone.fa-square-y::after {
  content: "\e287\e287"; }
.fad.fa-user-doctor-hair::after, .fa-duotone.fa-user-doctor-hair::after {
  content: "\e458\e458"; }
.fad.fa-planet-ringed::after, .fa-duotone.fa-planet-ringed::after {
  content: "\e020\e020"; }
.fad.fa-mushroom::after, .fa-duotone.fa-mushroom::after {
  content: "\e425\e425"; }
.fad.fa-user-shield::after, .fa-duotone.fa-user-shield::after {
  content: "\f505\f505"; }
.fad.fa-megaphone::after, .fa-duotone.fa-megaphone::after {
  content: "\f675\f675"; }
.fad.fa-circle-exclamation-check::after, .fa-duotone.fa-circle-exclamation-check::after {
  content: "\e10d\e10d"; }
.fad.fa-wind::after, .fa-duotone.fa-wind::after {
  content: "\f72e\f72e"; }
.fad.fa-box-dollar::after, .fa-duotone.fa-box-dollar::after {
  content: "\f4a0\f4a0"; }
.fad.fa-box-usd::after, .fa-duotone.fa-box-usd::after {
  content: "\f4a0\f4a0"; }
.fad.fa-car-burst::after, .fa-duotone.fa-car-burst::after {
  content: "\f5e1\f5e1"; }
.fad.fa-car-crash::after, .fa-duotone.fa-car-crash::after {
  content: "\f5e1\f5e1"; }
.fad.fa-y::after, .fa-duotone.fa-y::after {
  content: "\59\59"; }
.fad.fa-user-headset::after, .fa-duotone.fa-user-headset::after {
  content: "\f82d\f82d"; }
.fad.fa-arrows-retweet::after, .fa-duotone.fa-arrows-retweet::after {
  content: "\f361\f361"; }
.fad.fa-retweet-alt::after, .fa-duotone.fa-retweet-alt::after {
  content: "\f361\f361"; }
.fad.fa-person-snowboarding::after, .fa-duotone.fa-person-snowboarding::after {
  content: "\f7ce\f7ce"; }
.fad.fa-snowboarding::after, .fa-duotone.fa-snowboarding::after {
  content: "\f7ce\f7ce"; }
.fad.fa-square-chevron-right::after, .fa-duotone.fa-square-chevron-right::after {
  content: "\f32b\f32b"; }
.fad.fa-chevron-square-right::after, .fa-duotone.fa-chevron-square-right::after {
  content: "\f32b\f32b"; }
.fad.fa-lacrosse-stick-ball::after, .fa-duotone.fa-lacrosse-stick-ball::after {
  content: "\e3b6\e3b6"; }
.fad.fa-truck-fast::after, .fa-duotone.fa-truck-fast::after {
  content: "\f48b\f48b"; }
.fad.fa-shipping-fast::after, .fa-duotone.fa-shipping-fast::after {
  content: "\f48b\f48b"; }
.fad.fa-user-magnifying-glass::after, .fa-duotone.fa-user-magnifying-glass::after {
  content: "\e5c5\e5c5"; }
.fad.fa-star-sharp::after, .fa-duotone.fa-star-sharp::after {
  content: "\e28b\e28b"; }
.fad.fa-comment-heart::after, .fa-duotone.fa-comment-heart::after {
  content: "\e5c8\e5c8"; }
.fad.fa-circle-1::after, .fa-duotone.fa-circle-1::after {
  content: "\e0ee\e0ee"; }
.fad.fa-circle-star::after, .fa-duotone.fa-circle-star::after {
  content: "\e123\e123"; }
.fad.fa-star-circle::after, .fa-duotone.fa-star-circle::after {
  content: "\e123\e123"; }
.fad.fa-fish::after, .fa-duotone.fa-fish::after {
  content: "\f578\f578"; }
.fad.fa-cloud-fog::after, .fa-duotone.fa-cloud-fog::after {
  content: "\f74e\f74e"; }
.fad.fa-fog::after, .fa-duotone.fa-fog::after {
  content: "\f74e\f74e"; }
.fad.fa-waffle::after, .fa-duotone.fa-waffle::after {
  content: "\e466\e466"; }
.fad.fa-music-note::after, .fa-duotone.fa-music-note::after {
  content: "\f8cf\f8cf"; }
.fad.fa-music-alt::after, .fa-duotone.fa-music-alt::after {
  content: "\f8cf\f8cf"; }
.fad.fa-hexagon-exclamation::after, .fa-duotone.fa-hexagon-exclamation::after {
  content: "\e417\e417"; }
.fad.fa-cart-shopping-fast::after, .fa-duotone.fa-cart-shopping-fast::after {
  content: "\e0dc\e0dc"; }
.fad.fa-object-union::after, .fa-duotone.fa-object-union::after {
  content: "\e49f\e49f"; }
.fad.fa-user-graduate::after, .fa-duotone.fa-user-graduate::after {
  content: "\f501\f501"; }
.fad.fa-starfighter::after, .fa-duotone.fa-starfighter::after {
  content: "\e037\e037"; }
.fad.fa-circle-half-stroke::after, .fa-duotone.fa-circle-half-stroke::after {
  content: "\f042\f042"; }
.fad.fa-adjust::after, .fa-duotone.fa-adjust::after {
  content: "\f042\f042"; }
.fad.fa-arrow-right-long-to-line::after, .fa-duotone.fa-arrow-right-long-to-line::after {
  content: "\e3d5\e3d5"; }
.fad.fa-square-arrow-down::after, .fa-duotone.fa-square-arrow-down::after {
  content: "\f339\f339"; }
.fad.fa-arrow-square-down::after, .fa-duotone.fa-arrow-square-down::after {
  content: "\f339\f339"; }
.fad.fa-diamond-half-stroke::after, .fa-duotone.fa-diamond-half-stroke::after {
  content: "\e5b8\e5b8"; }
.fad.fa-clapperboard::after, .fa-duotone.fa-clapperboard::after {
  content: "\e131\e131"; }
.fad.fa-square-chevron-left::after, .fa-duotone.fa-square-chevron-left::after {
  content: "\f32a\f32a"; }
.fad.fa-chevron-square-left::after, .fa-duotone.fa-chevron-square-left::after {
  content: "\f32a\f32a"; }
.fad.fa-phone-intercom::after, .fa-duotone.fa-phone-intercom::after {
  content: "\e434\e434"; }
.fad.fa-link-horizontal::after, .fa-duotone.fa-link-horizontal::after {
  content: "\e1cb\e1cb"; }
.fad.fa-chain-horizontal::after, .fa-duotone.fa-chain-horizontal::after {
  content: "\e1cb\e1cb"; }
.fad.fa-mango::after, .fa-duotone.fa-mango::after {
  content: "\e30f\e30f"; }
.fad.fa-music-note-slash::after, .fa-duotone.fa-music-note-slash::after {
  content: "\f8d0\f8d0"; }
.fad.fa-music-alt-slash::after, .fa-duotone.fa-music-alt-slash::after {
  content: "\f8d0\f8d0"; }
.fad.fa-circle-radiation::after, .fa-duotone.fa-circle-radiation::after {
  content: "\f7ba\f7ba"; }
.fad.fa-radiation-alt::after, .fa-duotone.fa-radiation-alt::after {
  content: "\f7ba\f7ba"; }
.fad.fa-face-tongue-sweat::after, .fa-duotone.fa-face-tongue-sweat::after {
  content: "\e39e\e39e"; }
.fad.fa-globe-stand::after, .fa-duotone.fa-globe-stand::after {
  content: "\f5f6\f5f6"; }
.fad.fa-baseball::after, .fa-duotone.fa-baseball::after {
  content: "\f433\f433"; }
.fad.fa-baseball-ball::after, .fa-duotone.fa-baseball-ball::after {
  content: "\f433\f433"; }
.fad.fa-circle-p::after, .fa-duotone.fa-circle-p::after {
  content: "\e11a\e11a"; }
.fad.fa-award-simple::after, .fa-duotone.fa-award-simple::after {
  content: "\e0ab\e0ab"; }
.fad.fa-jet-fighter-up::after, .fa-duotone.fa-jet-fighter-up::after {
  content: "\e518\e518"; }
.fad.fa-diagram-project::after, .fa-duotone.fa-diagram-project::after {
  content: "\f542\f542"; }
.fad.fa-project-diagram::after, .fa-duotone.fa-project-diagram::after {
  content: "\f542\f542"; }
.fad.fa-pedestal::after, .fa-duotone.fa-pedestal::after {
  content: "\e20d\e20d"; }
.fad.fa-chart-pyramid::after, .fa-duotone.fa-chart-pyramid::after {
  content: "\e0e6\e0e6"; }
.fad.fa-sidebar::after, .fa-duotone.fa-sidebar::after {
  content: "\e24e\e24e"; }
.fad.fa-snowman-head::after, .fa-duotone.fa-snowman-head::after {
  content: "\f79b\f79b"; }
.fad.fa-frosty-head::after, .fa-duotone.fa-frosty-head::after {
  content: "\f79b\f79b"; }
.fad.fa-copy::after, .fa-duotone.fa-copy::after {
  content: "\f0c5\f0c5"; }
.fad.fa-burger-glass::after, .fa-duotone.fa-burger-glass::after {
  content: "\e0ce\e0ce"; }
.fad.fa-volume-xmark::after, .fa-duotone.fa-volume-xmark::after {
  content: "\f6a9\f6a9"; }
.fad.fa-volume-mute::after, .fa-duotone.fa-volume-mute::after {
  content: "\f6a9\f6a9"; }
.fad.fa-volume-times::after, .fa-duotone.fa-volume-times::after {
  content: "\f6a9\f6a9"; }
.fad.fa-hand-sparkles::after, .fa-duotone.fa-hand-sparkles::after {
  content: "\e05d\e05d"; }
.fad.fa-bars-filter::after, .fa-duotone.fa-bars-filter::after {
  content: "\e0ad\e0ad"; }
.fad.fa-paintbrush-pencil::after, .fa-duotone.fa-paintbrush-pencil::after {
  content: "\e206\e206"; }
.fad.fa-party-bell::after, .fa-duotone.fa-party-bell::after {
  content: "\e31a\e31a"; }
.fad.fa-user-vneck-hair::after, .fa-duotone.fa-user-vneck-hair::after {
  content: "\e462\e462"; }
.fad.fa-jack-o-lantern::after, .fa-duotone.fa-jack-o-lantern::after {
  content: "\f30e\f30e"; }
.fad.fa-grip::after, .fa-duotone.fa-grip::after {
  content: "\f58d\f58d"; }
.fad.fa-grip-horizontal::after, .fa-duotone.fa-grip-horizontal::after {
  content: "\f58d\f58d"; }
.fad.fa-share-from-square::after, .fa-duotone.fa-share-from-square::after {
  content: "\f14d\f14d"; }
.fad.fa-share-square::after, .fa-duotone.fa-share-square::after {
  content: "\f14d\f14d"; }
.fad.fa-keynote::after, .fa-duotone.fa-keynote::after {
  content: "\f66c\f66c"; }
.fad.fa-child-combatant::after, .fa-duotone.fa-child-combatant::after {
  content: "\e4e0\e4e0"; }
.fad.fa-child-rifle::after, .fa-duotone.fa-child-rifle::after {
  content: "\e4e0\e4e0"; }
.fad.fa-gun::after, .fa-duotone.fa-gun::after {
  content: "\e19b\e19b"; }
.fad.fa-square-phone::after, .fa-duotone.fa-square-phone::after {
  content: "\f098\f098"; }
.fad.fa-phone-square::after, .fa-duotone.fa-phone-square::after {
  content: "\f098\f098"; }
.fad.fa-plus::after, .fa-duotone.fa-plus::after {
  content: "\2b\2b"; }
.fad.fa-add::after, .fa-duotone.fa-add::after {
  content: "\2b\2b"; }
.fad.fa-expand::after, .fa-duotone.fa-expand::after {
  content: "\f065\f065"; }
.fad.fa-computer::after, .fa-duotone.fa-computer::after {
  content: "\e4e5\e4e5"; }
.fad.fa-fort::after, .fa-duotone.fa-fort::after {
  content: "\e486\e486"; }
.fad.fa-cloud-check::after, .fa-duotone.fa-cloud-check::after {
  content: "\e35c\e35c"; }
.fad.fa-xmark::after, .fa-duotone.fa-xmark::after {
  content: "\f00d\f00d"; }
.fad.fa-close::after, .fa-duotone.fa-close::after {
  content: "\f00d\f00d"; }
.fad.fa-multiply::after, .fa-duotone.fa-multiply::after {
  content: "\f00d\f00d"; }
.fad.fa-remove::after, .fa-duotone.fa-remove::after {
  content: "\f00d\f00d"; }
.fad.fa-times::after, .fa-duotone.fa-times::after {
  content: "\f00d\f00d"; }
.fad.fa-face-smirking::after, .fa-duotone.fa-face-smirking::after {
  content: "\e397\e397"; }
.fad.fa-arrows-up-down-left-right::after, .fa-duotone.fa-arrows-up-down-left-right::after {
  content: "\f047\f047"; }
.fad.fa-arrows::after, .fa-duotone.fa-arrows::after {
  content: "\f047\f047"; }
.fad.fa-chalkboard-user::after, .fa-duotone.fa-chalkboard-user::after {
  content: "\f51c\f51c"; }
.fad.fa-chalkboard-teacher::after, .fa-duotone.fa-chalkboard-teacher::after {
  content: "\f51c\f51c"; }
.fad.fa-rhombus::after, .fa-duotone.fa-rhombus::after {
  content: "\e23b\e23b"; }
.fad.fa-claw-marks::after, .fa-duotone.fa-claw-marks::after {
  content: "\f6c2\f6c2"; }
.fad.fa-peso-sign::after, .fa-duotone.fa-peso-sign::after {
  content: "\e222\e222"; }
.fad.fa-face-smile-tongue::after, .fa-duotone.fa-face-smile-tongue::after {
  content: "\e394\e394"; }
.fad.fa-cart-circle-xmark::after, .fa-duotone.fa-cart-circle-xmark::after {
  content: "\e3f4\e3f4"; }
.fad.fa-building-shield::after, .fa-duotone.fa-building-shield::after {
  content: "\e4d8\e4d8"; }
.fad.fa-circle-phone-flip::after, .fa-duotone.fa-circle-phone-flip::after {
  content: "\e11c\e11c"; }
.fad.fa-phone-circle-alt::after, .fa-duotone.fa-phone-circle-alt::after {
  content: "\e11c\e11c"; }
.fad.fa-baby::after, .fa-duotone.fa-baby::after {
  content: "\f77c\f77c"; }
.fad.fa-users-line::after, .fa-duotone.fa-users-line::after {
  content: "\e592\e592"; }
.fad.fa-quote-left::after, .fa-duotone.fa-quote-left::after {
  content: "\f10d\f10d"; }
.fad.fa-quote-left-alt::after, .fa-duotone.fa-quote-left-alt::after {
  content: "\f10d\f10d"; }
.fad.fa-tractor::after, .fa-duotone.fa-tractor::after {
  content: "\f722\f722"; }
.fad.fa-key-skeleton::after, .fa-duotone.fa-key-skeleton::after {
  content: "\f6f3\f6f3"; }
.fad.fa-trash-arrow-up::after, .fa-duotone.fa-trash-arrow-up::after {
  content: "\f829\f829"; }
.fad.fa-trash-restore::after, .fa-duotone.fa-trash-restore::after {
  content: "\f829\f829"; }
.fad.fa-arrow-down-up-lock::after, .fa-duotone.fa-arrow-down-up-lock::after {
  content: "\e4b0\e4b0"; }
.fad.fa-arrow-down-to-bracket::after, .fa-duotone.fa-arrow-down-to-bracket::after {
  content: "\e094\e094"; }
.fad.fa-lines-leaning::after, .fa-duotone.fa-lines-leaning::after {
  content: "\e51e\e51e"; }
.fad.fa-square-q::after, .fa-duotone.fa-square-q::after {
  content: "\e27b\e27b"; }
.fad.fa-ruler-combined::after, .fa-duotone.fa-ruler-combined::after {
  content: "\f546\f546"; }
.fad.fa-symbols::after, .fa-duotone.fa-symbols::after {
  content: "\f86e\f86e"; }
.fad.fa-icons-alt::after, .fa-duotone.fa-icons-alt::after {
  content: "\f86e\f86e"; }
.fad.fa-copyright::after, .fa-duotone.fa-copyright::after {
  content: "\f1f9\f1f9"; }
.fad.fa-highlighter-line::after, .fa-duotone.fa-highlighter-line::after {
  content: "\e1af\e1af"; }
.fad.fa-bracket-square::after, .fa-duotone.fa-bracket-square::after {
  content: "\5b\5b"; }
.fad.fa-bracket::after, .fa-duotone.fa-bracket::after {
  content: "\5b\5b"; }
.fad.fa-bracket-left::after, .fa-duotone.fa-bracket-left::after {
  content: "\5b\5b"; }
.fad.fa-island-tropical::after, .fa-duotone.fa-island-tropical::after {
  content: "\f811\f811"; }
.fad.fa-island-tree-palm::after, .fa-duotone.fa-island-tree-palm::after {
  content: "\f811\f811"; }
.fad.fa-arrow-right-from-line::after, .fa-duotone.fa-arrow-right-from-line::after {
  content: "\f343\f343"; }
.fad.fa-arrow-from-left::after, .fa-duotone.fa-arrow-from-left::after {
  content: "\f343\f343"; }
.fad.fa-h2::after, .fa-duotone.fa-h2::after {
  content: "\f314\f314"; }
.fad.fa-equals::after, .fa-duotone.fa-equals::after {
  content: "\3d\3d"; }
.fad.fa-cake-slice::after, .fa-duotone.fa-cake-slice::after {
  content: "\e3e5\e3e5"; }
.fad.fa-shortcake::after, .fa-duotone.fa-shortcake::after {
  content: "\e3e5\e3e5"; }
.fad.fa-peanut::after, .fa-duotone.fa-peanut::after {
  content: "\e430\e430"; }
.fad.fa-wrench-simple::after, .fa-duotone.fa-wrench-simple::after {
  content: "\e2d1\e2d1"; }
.fad.fa-blender::after, .fa-duotone.fa-blender::after {
  content: "\f517\f517"; }
.fad.fa-teeth::after, .fa-duotone.fa-teeth::after {
  content: "\f62e\f62e"; }
.fad.fa-tally-2::after, .fa-duotone.fa-tally-2::after {
  content: "\e295\e295"; }
.fad.fa-shekel-sign::after, .fa-duotone.fa-shekel-sign::after {
  content: "\f20b\f20b"; }
.fad.fa-ils::after, .fa-duotone.fa-ils::after {
  content: "\f20b\f20b"; }
.fad.fa-shekel::after, .fa-duotone.fa-shekel::after {
  content: "\f20b\f20b"; }
.fad.fa-sheqel::after, .fa-duotone.fa-sheqel::after {
  content: "\f20b\f20b"; }
.fad.fa-sheqel-sign::after, .fa-duotone.fa-sheqel-sign::after {
  content: "\f20b\f20b"; }
.fad.fa-cars::after, .fa-duotone.fa-cars::after {
  content: "\f85b\f85b"; }
.fad.fa-axe-battle::after, .fa-duotone.fa-axe-battle::after {
  content: "\f6b3\f6b3"; }
.fad.fa-user-hair-long::after, .fa-duotone.fa-user-hair-long::after {
  content: "\e45b\e45b"; }
.fad.fa-map::after, .fa-duotone.fa-map::after {
  content: "\f279\f279"; }
.fad.fa-file-circle-info::after, .fa-duotone.fa-file-circle-info::after {
  content: "\e493\e493"; }
.fad.fa-face-disappointed::after, .fa-duotone.fa-face-disappointed::after {
  content: "\e36f\e36f"; }
.fad.fa-lasso-sparkles::after, .fa-duotone.fa-lasso-sparkles::after {
  content: "\e1c9\e1c9"; }
.fad.fa-clock-eleven::after, .fa-duotone.fa-clock-eleven::after {
  content: "\e347\e347"; }
.fad.fa-rocket::after, .fa-duotone.fa-rocket::after {
  content: "\f135\f135"; }
.fad.fa-siren-on::after, .fa-duotone.fa-siren-on::after {
  content: "\e02e\e02e"; }
.fad.fa-clock-ten::after, .fa-duotone.fa-clock-ten::after {
  content: "\e354\e354"; }
.fad.fa-candle-holder::after, .fa-duotone.fa-candle-holder::after {
  content: "\f6bc\f6bc"; }
.fad.fa-video-arrow-down-left::after, .fa-duotone.fa-video-arrow-down-left::after {
  content: "\e2c8\e2c8"; }
.fad.fa-photo-film::after, .fa-duotone.fa-photo-film::after {
  content: "\f87c\f87c"; }
.fad.fa-photo-video::after, .fa-duotone.fa-photo-video::after {
  content: "\f87c\f87c"; }
.fad.fa-floppy-disk-circle-arrow-right::after, .fa-duotone.fa-floppy-disk-circle-arrow-right::after {
  content: "\e180\e180"; }
.fad.fa-save-circle-arrow-right::after, .fa-duotone.fa-save-circle-arrow-right::after {
  content: "\e180\e180"; }
.fad.fa-folder-minus::after, .fa-duotone.fa-folder-minus::after {
  content: "\f65d\f65d"; }
.fad.fa-planet-moon::after, .fa-duotone.fa-planet-moon::after {
  content: "\e01f\e01f"; }
.fad.fa-face-eyes-xmarks::after, .fa-duotone.fa-face-eyes-xmarks::after {
  content: "\e374\e374"; }
.fad.fa-chart-scatter::after, .fa-duotone.fa-chart-scatter::after {
  content: "\f7ee\f7ee"; }
.fad.fa-display-arrow-down::after, .fa-duotone.fa-display-arrow-down::after {
  content: "\e164\e164"; }
.fad.fa-store::after, .fa-duotone.fa-store::after {
  content: "\f54e\f54e"; }
.fad.fa-arrow-trend-up::after, .fa-duotone.fa-arrow-trend-up::after {
  content: "\e098\e098"; }
.fad.fa-plug-circle-minus::after, .fa-duotone.fa-plug-circle-minus::after {
  content: "\e55e\e55e"; }
.fad.fa-olive-branch::after, .fa-duotone.fa-olive-branch::after {
  content: "\e317\e317"; }
.fad.fa-angle::after, .fa-duotone.fa-angle::after {
  content: "\e08c\e08c"; }
.fad.fa-vacuum-robot::after, .fa-duotone.fa-vacuum-robot::after {
  content: "\e04e\e04e"; }
.fad.fa-sign-hanging::after, .fa-duotone.fa-sign-hanging::after {
  content: "\f4d9\f4d9"; }
.fad.fa-sign::after, .fa-duotone.fa-sign::after {
  content: "\f4d9\f4d9"; }
.fad.fa-square-divide::after, .fa-duotone.fa-square-divide::after {
  content: "\e26a\e26a"; }
.fad.fa-signal-stream-slash::after, .fa-duotone.fa-signal-stream-slash::after {
  content: "\e250\e250"; }
.fad.fa-bezier-curve::after, .fa-duotone.fa-bezier-curve::after {
  content: "\f55b\f55b"; }
.fad.fa-eye-dropper-half::after, .fa-duotone.fa-eye-dropper-half::after {
  content: "\e173\e173"; }
.fad.fa-store-lock::after, .fa-duotone.fa-store-lock::after {
  content: "\e4a6\e4a6"; }
.fad.fa-bell-slash::after, .fa-duotone.fa-bell-slash::after {
  content: "\f1f6\f1f6"; }
.fad.fa-cloud-bolt-sun::after, .fa-duotone.fa-cloud-bolt-sun::after {
  content: "\f76e\f76e"; }
.fad.fa-thunderstorm-sun::after, .fa-duotone.fa-thunderstorm-sun::after {
  content: "\f76e\f76e"; }
.fad.fa-camera-slash::after, .fa-duotone.fa-camera-slash::after {
  content: "\e0d9\e0d9"; }
.fad.fa-comment-quote::after, .fa-duotone.fa-comment-quote::after {
  content: "\e14c\e14c"; }
.fad.fa-tablet::after, .fa-duotone.fa-tablet::after {
  content: "\f3fb\f3fb"; }
.fad.fa-tablet-android::after, .fa-duotone.fa-tablet-android::after {
  content: "\f3fb\f3fb"; }
.fad.fa-school-flag::after, .fa-duotone.fa-school-flag::after {
  content: "\e56e\e56e"; }
.fad.fa-message-code::after, .fa-duotone.fa-message-code::after {
  content: "\e1df\e1df"; }
.fad.fa-glass-half::after, .fa-duotone.fa-glass-half::after {
  content: "\e192\e192"; }
.fad.fa-glass-half-empty::after, .fa-duotone.fa-glass-half-empty::after {
  content: "\e192\e192"; }
.fad.fa-glass-half-full::after, .fa-duotone.fa-glass-half-full::after {
  content: "\e192\e192"; }
.fad.fa-fill::after, .fa-duotone.fa-fill::after {
  content: "\f575\f575"; }
.fad.fa-message-minus::after, .fa-duotone.fa-message-minus::after {
  content: "\f4a7\f4a7"; }
.fad.fa-comment-alt-minus::after, .fa-duotone.fa-comment-alt-minus::after {
  content: "\f4a7\f4a7"; }
.fad.fa-angle-up::after, .fa-duotone.fa-angle-up::after {
  content: "\f106\f106"; }
.fad.fa-drumstick-bite::after, .fa-duotone.fa-drumstick-bite::after {
  content: "\f6d7\f6d7"; }
.fad.fa-link-horizontal-slash::after, .fa-duotone.fa-link-horizontal-slash::after {
  content: "\e1cc\e1cc"; }
.fad.fa-chain-horizontal-slash::after, .fa-duotone.fa-chain-horizontal-slash::after {
  content: "\e1cc\e1cc"; }
.fad.fa-holly-berry::after, .fa-duotone.fa-holly-berry::after {
  content: "\f7aa\f7aa"; }
.fad.fa-nose::after, .fa-duotone.fa-nose::after {
  content: "\e5bd\e5bd"; }
.fad.fa-chevron-left::after, .fa-duotone.fa-chevron-left::after {
  content: "\f053\f053"; }
.fad.fa-bacteria::after, .fa-duotone.fa-bacteria::after {
  content: "\e059\e059"; }
.fad.fa-clouds::after, .fa-duotone.fa-clouds::after {
  content: "\f744\f744"; }
.fad.fa-money-bill-simple::after, .fa-duotone.fa-money-bill-simple::after {
  content: "\e1f1\e1f1"; }
.fad.fa-hand-lizard::after, .fa-duotone.fa-hand-lizard::after {
  content: "\f258\f258"; }
.fad.fa-table-pivot::after, .fa-duotone.fa-table-pivot::after {
  content: "\e291\e291"; }
.fad.fa-filter-slash::after, .fa-duotone.fa-filter-slash::after {
  content: "\e17d\e17d"; }
.fad.fa-trash-can-undo::after, .fa-duotone.fa-trash-can-undo::after {
  content: "\f896\f896"; }
.fad.fa-trash-can-arrow-turn-left::after, .fa-duotone.fa-trash-can-arrow-turn-left::after {
  content: "\f896\f896"; }
.fad.fa-trash-undo-alt::after, .fa-duotone.fa-trash-undo-alt::after {
  content: "\f896\f896"; }
.fad.fa-notdef::after, .fa-duotone.fa-notdef::after {
  content: "\e1fe\e1fe"; }
.fad.fa-disease::after, .fa-duotone.fa-disease::after {
  content: "\f7fa\f7fa"; }
.fad.fa-person-to-door::after, .fa-duotone.fa-person-to-door::after {
  content: "\e433\e433"; }
.fad.fa-turntable::after, .fa-duotone.fa-turntable::after {
  content: "\f8e4\f8e4"; }
.fad.fa-briefcase-medical::after, .fa-duotone.fa-briefcase-medical::after {
  content: "\f469\f469"; }
.fad.fa-genderless::after, .fa-duotone.fa-genderless::after {
  content: "\f22d\f22d"; }
.fad.fa-chevron-right::after, .fa-duotone.fa-chevron-right::after {
  content: "\f054\f054"; }
.fad.fa-signal-weak::after, .fa-duotone.fa-signal-weak::after {
  content: "\f68c\f68c"; }
.fad.fa-signal-1::after, .fa-duotone.fa-signal-1::after {
  content: "\f68c\f68c"; }
.fad.fa-clock-five::after, .fa-duotone.fa-clock-five::after {
  content: "\e349\e349"; }
.fad.fa-retweet::after, .fa-duotone.fa-retweet::after {
  content: "\f079\f079"; }
.fad.fa-car-rear::after, .fa-duotone.fa-car-rear::after {
  content: "\f5de\f5de"; }
.fad.fa-car-alt::after, .fa-duotone.fa-car-alt::after {
  content: "\f5de\f5de"; }
.fad.fa-pump-soap::after, .fa-duotone.fa-pump-soap::after {
  content: "\e06b\e06b"; }
.fad.fa-computer-classic::after, .fa-duotone.fa-computer-classic::after {
  content: "\f8b1\f8b1"; }
.fad.fa-frame::after, .fa-duotone.fa-frame::after {
  content: "\e495\e495"; }
.fad.fa-video-slash::after, .fa-duotone.fa-video-slash::after {
  content: "\f4e2\f4e2"; }
.fad.fa-battery-quarter::after, .fa-duotone.fa-battery-quarter::after {
  content: "\f243\f243"; }
.fad.fa-battery-2::after, .fa-duotone.fa-battery-2::after {
  content: "\f243\f243"; }
.fad.fa-ellipsis-stroke::after, .fa-duotone.fa-ellipsis-stroke::after {
  content: "\f39b\f39b"; }
.fad.fa-ellipsis-h-alt::after, .fa-duotone.fa-ellipsis-h-alt::after {
  content: "\f39b\f39b"; }
.fad.fa-radio::after, .fa-duotone.fa-radio::after {
  content: "\f8d7\f8d7"; }
.fad.fa-baby-carriage::after, .fa-duotone.fa-baby-carriage::after {
  content: "\f77d\f77d"; }
.fad.fa-carriage-baby::after, .fa-duotone.fa-carriage-baby::after {
  content: "\f77d\f77d"; }
.fad.fa-face-expressionless::after, .fa-duotone.fa-face-expressionless::after {
  content: "\e373\e373"; }
.fad.fa-down-to-dotted-line::after, .fa-duotone.fa-down-to-dotted-line::after {
  content: "\e408\e408"; }
.fad.fa-cloud-music::after, .fa-duotone.fa-cloud-music::after {
  content: "\f8ae\f8ae"; }
.fad.fa-traffic-light::after, .fa-duotone.fa-traffic-light::after {
  content: "\f637\f637"; }
.fad.fa-cloud-minus::after, .fa-duotone.fa-cloud-minus::after {
  content: "\e35d\e35d"; }
.fad.fa-thermometer::after, .fa-duotone.fa-thermometer::after {
  content: "\f491\f491"; }
.fad.fa-shield-minus::after, .fa-duotone.fa-shield-minus::after {
  content: "\e249\e249"; }
.fad.fa-vr-cardboard::after, .fa-duotone.fa-vr-cardboard::after {
  content: "\f729\f729"; }
.fad.fa-car-tilt::after, .fa-duotone.fa-car-tilt::after {
  content: "\f5e5\f5e5"; }
.fad.fa-gauge-circle-minus::after, .fa-duotone.fa-gauge-circle-minus::after {
  content: "\e497\e497"; }
.fad.fa-brightness-low::after, .fa-duotone.fa-brightness-low::after {
  content: "\e0ca\e0ca"; }
.fad.fa-hand-middle-finger::after, .fa-duotone.fa-hand-middle-finger::after {
  content: "\f806\f806"; }
.fad.fa-percent::after, .fa-duotone.fa-percent::after {
  content: "\25\25"; }
.fad.fa-percentage::after, .fa-duotone.fa-percentage::after {
  content: "\25\25"; }
.fad.fa-truck-moving::after, .fa-duotone.fa-truck-moving::after {
  content: "\f4df\f4df"; }
.fad.fa-glass-water-droplet::after, .fa-duotone.fa-glass-water-droplet::after {
  content: "\e4f5\e4f5"; }
.fad.fa-conveyor-belt::after, .fa-duotone.fa-conveyor-belt::after {
  content: "\f46e\f46e"; }
.fad.fa-location-check::after, .fa-duotone.fa-location-check::after {
  content: "\f606\f606"; }
.fad.fa-map-marker-check::after, .fa-duotone.fa-map-marker-check::after {
  content: "\f606\f606"; }
.fad.fa-coin-vertical::after, .fa-duotone.fa-coin-vertical::after {
  content: "\e3fd\e3fd"; }
.fad.fa-display::after, .fa-duotone.fa-display::after {
  content: "\e163\e163"; }
.fad.fa-person-sign::after, .fa-duotone.fa-person-sign::after {
  content: "\f757\f757"; }
.fad.fa-face-smile::after, .fa-duotone.fa-face-smile::after {
  content: "\f118\f118"; }
.fad.fa-smile::after, .fa-duotone.fa-smile::after {
  content: "\f118\f118"; }
.fad.fa-phone-hangup::after, .fa-duotone.fa-phone-hangup::after {
  content: "\e225\e225"; }
.fad.fa-signature-slash::after, .fa-duotone.fa-signature-slash::after {
  content: "\e3cb\e3cb"; }
.fad.fa-thumbtack::after, .fa-duotone.fa-thumbtack::after {
  content: "\f08d\f08d"; }
.fad.fa-thumb-tack::after, .fa-duotone.fa-thumb-tack::after {
  content: "\f08d\f08d"; }
.fad.fa-wheat-slash::after, .fa-duotone.fa-wheat-slash::after {
  content: "\e339\e339"; }
.fad.fa-trophy::after, .fa-duotone.fa-trophy::after {
  content: "\f091\f091"; }
.fad.fa-clouds-sun::after, .fa-duotone.fa-clouds-sun::after {
  content: "\f746\f746"; }
.fad.fa-person-praying::after, .fa-duotone.fa-person-praying::after {
  content: "\f683\f683"; }
.fad.fa-pray::after, .fa-duotone.fa-pray::after {
  content: "\f683\f683"; }
.fad.fa-hammer::after, .fa-duotone.fa-hammer::after {
  content: "\f6e3\f6e3"; }
.fad.fa-face-vomit::after, .fa-duotone.fa-face-vomit::after {
  content: "\e3a0\e3a0"; }
.fad.fa-speakers::after, .fa-duotone.fa-speakers::after {
  content: "\f8e0\f8e0"; }
.fad.fa-tty-answer::after, .fa-duotone.fa-tty-answer::after {
  content: "\e2b9\e2b9"; }
.fad.fa-teletype-answer::after, .fa-duotone.fa-teletype-answer::after {
  content: "\e2b9\e2b9"; }
.fad.fa-mug-tea-saucer::after, .fa-duotone.fa-mug-tea-saucer::after {
  content: "\e1f5\e1f5"; }
.fad.fa-diagram-lean-canvas::after, .fa-duotone.fa-diagram-lean-canvas::after {
  content: "\e156\e156"; }
.fad.fa-alt::after, .fa-duotone.fa-alt::after {
  content: "\e08a\e08a"; }
.fad.fa-dial::after, .fa-duotone.fa-dial::after {
  content: "\e15b\e15b"; }
.fad.fa-dial-med-high::after, .fa-duotone.fa-dial-med-high::after {
  content: "\e15b\e15b"; }
.fad.fa-hand-peace::after, .fa-duotone.fa-hand-peace::after {
  content: "\f25b\f25b"; }
.fad.fa-circle-trash::after, .fa-duotone.fa-circle-trash::after {
  content: "\e126\e126"; }
.fad.fa-trash-circle::after, .fa-duotone.fa-trash-circle::after {
  content: "\e126\e126"; }
.fad.fa-rotate::after, .fa-duotone.fa-rotate::after {
  content: "\f2f1\f2f1"; }
.fad.fa-sync-alt::after, .fa-duotone.fa-sync-alt::after {
  content: "\f2f1\f2f1"; }
.fad.fa-circle-quarters::after, .fa-duotone.fa-circle-quarters::after {
  content: "\e3f8\e3f8"; }
.fad.fa-spinner::after, .fa-duotone.fa-spinner::after {
  content: "\f110\f110"; }
.fad.fa-tower-control::after, .fa-duotone.fa-tower-control::after {
  content: "\e2a2\e2a2"; }
.fad.fa-arrow-up-triangle-square::after, .fa-duotone.fa-arrow-up-triangle-square::after {
  content: "\f88a\f88a"; }
.fad.fa-sort-shapes-up::after, .fa-duotone.fa-sort-shapes-up::after {
  content: "\f88a\f88a"; }
.fad.fa-whale::after, .fa-duotone.fa-whale::after {
  content: "\f72c\f72c"; }
.fad.fa-robot::after, .fa-duotone.fa-robot::after {
  content: "\f544\f544"; }
.fad.fa-peace::after, .fa-duotone.fa-peace::after {
  content: "\f67c\f67c"; }
.fad.fa-party-horn::after, .fa-duotone.fa-party-horn::after {
  content: "\e31b\e31b"; }
.fad.fa-gears::after, .fa-duotone.fa-gears::after {
  content: "\f085\f085"; }
.fad.fa-cogs::after, .fa-duotone.fa-cogs::after {
  content: "\f085\f085"; }
.fad.fa-sun-bright::after, .fa-duotone.fa-sun-bright::after {
  content: "\e28f\e28f"; }
.fad.fa-sun-alt::after, .fa-duotone.fa-sun-alt::after {
  content: "\e28f\e28f"; }
.fad.fa-warehouse::after, .fa-duotone.fa-warehouse::after {
  content: "\f494\f494"; }
.fad.fa-lock-keyhole-open::after, .fa-duotone.fa-lock-keyhole-open::after {
  content: "\f3c2\f3c2"; }
.fad.fa-lock-open-alt::after, .fa-duotone.fa-lock-open-alt::after {
  content: "\f3c2\f3c2"; }
.fad.fa-square-fragile::after, .fa-duotone.fa-square-fragile::after {
  content: "\f49b\f49b"; }
.fad.fa-box-fragile::after, .fa-duotone.fa-box-fragile::after {
  content: "\f49b\f49b"; }
.fad.fa-square-wine-glass-crack::after, .fa-duotone.fa-square-wine-glass-crack::after {
  content: "\f49b\f49b"; }
.fad.fa-arrow-up-right-dots::after, .fa-duotone.fa-arrow-up-right-dots::after {
  content: "\e4b7\e4b7"; }
.fad.fa-square-n::after, .fa-duotone.fa-square-n::after {
  content: "\e277\e277"; }
.fad.fa-splotch::after, .fa-duotone.fa-splotch::after {
  content: "\f5bc\f5bc"; }
.fad.fa-face-grin-hearts::after, .fa-duotone.fa-face-grin-hearts::after {
  content: "\f584\f584"; }
.fad.fa-grin-hearts::after, .fa-duotone.fa-grin-hearts::after {
  content: "\f584\f584"; }
.fad.fa-meter::after, .fa-duotone.fa-meter::after {
  content: "\e1e8\e1e8"; }
.fad.fa-mandolin::after, .fa-duotone.fa-mandolin::after {
  content: "\f6f9\f6f9"; }
.fad.fa-dice-four::after, .fa-duotone.fa-dice-four::after {
  content: "\f524\f524"; }
.fad.fa-sim-card::after, .fa-duotone.fa-sim-card::after {
  content: "\f7c4\f7c4"; }
.fad.fa-transgender::after, .fa-duotone.fa-transgender::after {
  content: "\f225\f225"; }
.fad.fa-transgender-alt::after, .fa-duotone.fa-transgender-alt::after {
  content: "\f225\f225"; }
.fad.fa-mercury::after, .fa-duotone.fa-mercury::after {
  content: "\f223\f223"; }
.fad.fa-up-from-bracket::after, .fa-duotone.fa-up-from-bracket::after {
  content: "\e590\e590"; }
.fad.fa-knife-kitchen::after, .fa-duotone.fa-knife-kitchen::after {
  content: "\f6f5\f6f5"; }
.fad.fa-border-right::after, .fa-duotone.fa-border-right::after {
  content: "\f852\f852"; }
.fad.fa-arrow-turn-down::after, .fa-duotone.fa-arrow-turn-down::after {
  content: "\f149\f149"; }
.fad.fa-level-down::after, .fa-duotone.fa-level-down::after {
  content: "\f149\f149"; }
.fad.fa-spade::after, .fa-duotone.fa-spade::after {
  content: "\f2f4\f2f4"; }
.fad.fa-card-spade::after, .fa-duotone.fa-card-spade::after {
  content: "\e3ec\e3ec"; }
.fad.fa-line-columns::after, .fa-duotone.fa-line-columns::after {
  content: "\f870\f870"; }
.fad.fa-arrow-right-to-line::after, .fa-duotone.fa-arrow-right-to-line::after {
  content: "\f340\f340"; }
.fad.fa-arrow-to-right::after, .fa-duotone.fa-arrow-to-right::after {
  content: "\f340\f340"; }
.fad.fa-person-falling-burst::after, .fa-duotone.fa-person-falling-burst::after {
  content: "\e547\e547"; }
.fad.fa-flag-pennant::after, .fa-duotone.fa-flag-pennant::after {
  content: "\f456\f456"; }
.fad.fa-pennant::after, .fa-duotone.fa-pennant::after {
  content: "\f456\f456"; }
.fad.fa-conveyor-belt-empty::after, .fa-duotone.fa-conveyor-belt-empty::after {
  content: "\e150\e150"; }
.fad.fa-award::after, .fa-duotone.fa-award::after {
  content: "\f559\f559"; }
.fad.fa-ticket-simple::after, .fa-duotone.fa-ticket-simple::after {
  content: "\f3ff\f3ff"; }
.fad.fa-ticket-alt::after, .fa-duotone.fa-ticket-alt::after {
  content: "\f3ff\f3ff"; }
.fad.fa-building::after, .fa-duotone.fa-building::after {
  content: "\f1ad\f1ad"; }
.fad.fa-angles-left::after, .fa-duotone.fa-angles-left::after {
  content: "\f100\f100"; }
.fad.fa-angle-double-left::after, .fa-duotone.fa-angle-double-left::after {
  content: "\f100\f100"; }
.fad.fa-camcorder::after, .fa-duotone.fa-camcorder::after {
  content: "\f8a8\f8a8"; }
.fad.fa-video-handheld::after, .fa-duotone.fa-video-handheld::after {
  content: "\f8a8\f8a8"; }
.fad.fa-pancakes::after, .fa-duotone.fa-pancakes::after {
  content: "\e42d\e42d"; }
.fad.fa-album-circle-user::after, .fa-duotone.fa-album-circle-user::after {
  content: "\e48d\e48d"; }
.fad.fa-qrcode::after, .fa-duotone.fa-qrcode::after {
  content: "\f029\f029"; }
.fad.fa-dice-d10::after, .fa-duotone.fa-dice-d10::after {
  content: "\f6cd\f6cd"; }
.fad.fa-fireplace::after, .fa-duotone.fa-fireplace::after {
  content: "\f79a\f79a"; }
.fad.fa-browser::after, .fa-duotone.fa-browser::after {
  content: "\f37e\f37e"; }
.fad.fa-pen-paintbrush::after, .fa-duotone.fa-pen-paintbrush::after {
  content: "\f618\f618"; }
.fad.fa-pencil-paintbrush::after, .fa-duotone.fa-pencil-paintbrush::after {
  content: "\f618\f618"; }
.fad.fa-fish-cooked::after, .fa-duotone.fa-fish-cooked::after {
  content: "\f7fe\f7fe"; }
.fad.fa-chair-office::after, .fa-duotone.fa-chair-office::after {
  content: "\f6c1\f6c1"; }
.fad.fa-nesting-dolls::after, .fa-duotone.fa-nesting-dolls::after {
  content: "\e3ba\e3ba"; }
.fad.fa-clock-rotate-left::after, .fa-duotone.fa-clock-rotate-left::after {
  content: "\f1da\f1da"; }
.fad.fa-history::after, .fa-duotone.fa-history::after {
  content: "\f1da\f1da"; }
.fad.fa-trumpet::after, .fa-duotone.fa-trumpet::after {
  content: "\f8e3\f8e3"; }
.fad.fa-face-grin-beam-sweat::after, .fa-duotone.fa-face-grin-beam-sweat::after {
  content: "\f583\f583"; }
.fad.fa-grin-beam-sweat::after, .fa-duotone.fa-grin-beam-sweat::after {
  content: "\f583\f583"; }
.fad.fa-fire-smoke::after, .fa-duotone.fa-fire-smoke::after {
  content: "\f74b\f74b"; }
.fad.fa-phone-missed::after, .fa-duotone.fa-phone-missed::after {
  content: "\e226\e226"; }
.fad.fa-file-export::after, .fa-duotone.fa-file-export::after {
  content: "\f56e\f56e"; }
.fad.fa-arrow-right-from-file::after, .fa-duotone.fa-arrow-right-from-file::after {
  content: "\f56e\f56e"; }
.fad.fa-shield::after, .fa-duotone.fa-shield::after {
  content: "\f132\f132"; }
.fad.fa-shield-blank::after, .fa-duotone.fa-shield-blank::after {
  content: "\f132\f132"; }
.fad.fa-arrow-up-short-wide::after, .fa-duotone.fa-arrow-up-short-wide::after {
  content: "\f885\f885"; }
.fad.fa-sort-amount-up-alt::after, .fa-duotone.fa-sort-amount-up-alt::after {
  content: "\f885\f885"; }
.fad.fa-arrows-repeat-1::after, .fa-duotone.fa-arrows-repeat-1::after {
  content: "\f366\f366"; }
.fad.fa-repeat-1-alt::after, .fa-duotone.fa-repeat-1-alt::after {
  content: "\f366\f366"; }
.fad.fa-gun-slash::after, .fa-duotone.fa-gun-slash::after {
  content: "\e19c\e19c"; }
.fad.fa-avocado::after, .fa-duotone.fa-avocado::after {
  content: "\e0aa\e0aa"; }
.fad.fa-binary::after, .fa-duotone.fa-binary::after {
  content: "\e33b\e33b"; }
.fad.fa-glasses-round::after, .fa-duotone.fa-glasses-round::after {
  content: "\f5f5\f5f5"; }
.fad.fa-glasses-alt::after, .fa-duotone.fa-glasses-alt::after {
  content: "\f5f5\f5f5"; }
.fad.fa-phone-plus::after, .fa-duotone.fa-phone-plus::after {
  content: "\f4d2\f4d2"; }
.fad.fa-ditto::after, .fa-duotone.fa-ditto::after {
  content: "\22\22"; }
.fad.fa-person-seat::after, .fa-duotone.fa-person-seat::after {
  content: "\e21e\e21e"; }
.fad.fa-house-medical::after, .fa-duotone.fa-house-medical::after {
  content: "\e3b2\e3b2"; }
.fad.fa-golf-ball-tee::after, .fa-duotone.fa-golf-ball-tee::after {
  content: "\f450\f450"; }
.fad.fa-golf-ball::after, .fa-duotone.fa-golf-ball::after {
  content: "\f450\f450"; }
.fad.fa-circle-chevron-left::after, .fa-duotone.fa-circle-chevron-left::after {
  content: "\f137\f137"; }
.fad.fa-chevron-circle-left::after, .fa-duotone.fa-chevron-circle-left::after {
  content: "\f137\f137"; }
.fad.fa-house-chimney-window::after, .fa-duotone.fa-house-chimney-window::after {
  content: "\e00d\e00d"; }
.fad.fa-scythe::after, .fa-duotone.fa-scythe::after {
  content: "\f710\f710"; }
.fad.fa-pen-nib::after, .fa-duotone.fa-pen-nib::after {
  content: "\f5ad\f5ad"; }
.fad.fa-ban-parking::after, .fa-duotone.fa-ban-parking::after {
  content: "\f616\f616"; }
.fad.fa-parking-circle-slash::after, .fa-duotone.fa-parking-circle-slash::after {
  content: "\f616\f616"; }
.fad.fa-tent-arrow-turn-left::after, .fa-duotone.fa-tent-arrow-turn-left::after {
  content: "\e580\e580"; }
.fad.fa-face-diagonal-mouth::after, .fa-duotone.fa-face-diagonal-mouth::after {
  content: "\e47e\e47e"; }
.fad.fa-diagram-cells::after, .fa-duotone.fa-diagram-cells::after {
  content: "\e475\e475"; }
.fad.fa-cricket-bat-ball::after, .fa-duotone.fa-cricket-bat-ball::after {
  content: "\f449\f449"; }
.fad.fa-cricket::after, .fa-duotone.fa-cricket::after {
  content: "\f449\f449"; }
.fad.fa-tents::after, .fa-duotone.fa-tents::after {
  content: "\e582\e582"; }
.fad.fa-wand-magic::after, .fa-duotone.fa-wand-magic::after {
  content: "\f0d0\f0d0"; }
.fad.fa-magic::after, .fa-duotone.fa-magic::after {
  content: "\f0d0\f0d0"; }
.fad.fa-dog::after, .fa-duotone.fa-dog::after {
  content: "\f6d3\f6d3"; }
.fad.fa-pen-line::after, .fa-duotone.fa-pen-line::after {
  content: "\e212\e212"; }
.fad.fa-atom-simple::after, .fa-duotone.fa-atom-simple::after {
  content: "\f5d3\f5d3"; }
.fad.fa-atom-alt::after, .fa-duotone.fa-atom-alt::after {
  content: "\f5d3\f5d3"; }
.fad.fa-ampersand::after, .fa-duotone.fa-ampersand::after {
  content: "\26\26"; }
.fad.fa-carrot::after, .fa-duotone.fa-carrot::after {
  content: "\f787\f787"; }
.fad.fa-arrow-up-from-line::after, .fa-duotone.fa-arrow-up-from-line::after {
  content: "\f342\f342"; }
.fad.fa-arrow-from-bottom::after, .fa-duotone.fa-arrow-from-bottom::after {
  content: "\f342\f342"; }
.fad.fa-moon::after, .fa-duotone.fa-moon::after {
  content: "\f186\f186"; }
.fad.fa-pen-slash::after, .fa-duotone.fa-pen-slash::after {
  content: "\e213\e213"; }
.fad.fa-wine-glass-empty::after, .fa-duotone.fa-wine-glass-empty::after {
  content: "\f5ce\f5ce"; }
.fad.fa-wine-glass-alt::after, .fa-duotone.fa-wine-glass-alt::after {
  content: "\f5ce\f5ce"; }
.fad.fa-square-star::after, .fa-duotone.fa-square-star::after {
  content: "\e27f\e27f"; }
.fad.fa-cheese::after, .fa-duotone.fa-cheese::after {
  content: "\f7ef\f7ef"; }
.fad.fa-send-backward::after, .fa-duotone.fa-send-backward::after {
  content: "\f87f\f87f"; }
.fad.fa-yin-yang::after, .fa-duotone.fa-yin-yang::after {
  content: "\f6ad\f6ad"; }
.fad.fa-music::after, .fa-duotone.fa-music::after {
  content: "\f001\f001"; }
.fad.fa-compass-slash::after, .fa-duotone.fa-compass-slash::after {
  content: "\f5e9\f5e9"; }
.fad.fa-clock-one::after, .fa-duotone.fa-clock-one::after {
  content: "\e34e\e34e"; }
.fad.fa-file-music::after, .fa-duotone.fa-file-music::after {
  content: "\f8b6\f8b6"; }
.fad.fa-code-commit::after, .fa-duotone.fa-code-commit::after {
  content: "\f386\f386"; }
.fad.fa-temperature-low::after, .fa-duotone.fa-temperature-low::after {
  content: "\f76b\f76b"; }
.fad.fa-person-biking::after, .fa-duotone.fa-person-biking::after {
  content: "\f84a\f84a"; }
.fad.fa-biking::after, .fa-duotone.fa-biking::after {
  content: "\f84a\f84a"; }
.fad.fa-skeleton::after, .fa-duotone.fa-skeleton::after {
  content: "\f620\f620"; }
.fad.fa-circle-g::after, .fa-duotone.fa-circle-g::after {
  content: "\e10f\e10f"; }
.fad.fa-circle-arrow-up-left::after, .fa-duotone.fa-circle-arrow-up-left::after {
  content: "\e0fb\e0fb"; }
.fad.fa-coin-blank::after, .fa-duotone.fa-coin-blank::after {
  content: "\e3fb\e3fb"; }
.fad.fa-broom::after, .fa-duotone.fa-broom::after {
  content: "\f51a\f51a"; }
.fad.fa-vacuum::after, .fa-duotone.fa-vacuum::after {
  content: "\e04d\e04d"; }
.fad.fa-shield-heart::after, .fa-duotone.fa-shield-heart::after {
  content: "\e574\e574"; }
.fad.fa-card-heart::after, .fa-duotone.fa-card-heart::after {
  content: "\e3eb\e3eb"; }
.fad.fa-lightbulb-cfl-on::after, .fa-duotone.fa-lightbulb-cfl-on::after {
  content: "\e5a7\e5a7"; }
.fad.fa-melon::after, .fa-duotone.fa-melon::after {
  content: "\e310\e310"; }
.fad.fa-gopuram::after, .fa-duotone.fa-gopuram::after {
  content: "\f664\f664"; }
.fad.fa-earth-oceania::after, .fa-duotone.fa-earth-oceania::after {
  content: "\e47b\e47b"; }
.fad.fa-globe-oceania::after, .fa-duotone.fa-globe-oceania::after {
  content: "\e47b\e47b"; }
.fad.fa-container-storage::after, .fa-duotone.fa-container-storage::after {
  content: "\f4b7\f4b7"; }
.fad.fa-face-pouting::after, .fa-duotone.fa-face-pouting::after {
  content: "\e387\e387"; }
.fad.fa-square-xmark::after, .fa-duotone.fa-square-xmark::after {
  content: "\f2d3\f2d3"; }
.fad.fa-times-square::after, .fa-duotone.fa-times-square::after {
  content: "\f2d3\f2d3"; }
.fad.fa-xmark-square::after, .fa-duotone.fa-xmark-square::after {
  content: "\f2d3\f2d3"; }
.fad.fa-face-explode::after, .fa-duotone.fa-face-explode::after {
  content: "\e2fe\e2fe"; }
.fad.fa-exploding-head::after, .fa-duotone.fa-exploding-head::after {
  content: "\e2fe\e2fe"; }
.fad.fa-hashtag::after, .fa-duotone.fa-hashtag::after {
  content: "\23\23"; }
.fad.fa-up-right-and-down-left-from-center::after, .fa-duotone.fa-up-right-and-down-left-from-center::after {
  content: "\f424\f424"; }
.fad.fa-expand-alt::after, .fa-duotone.fa-expand-alt::after {
  content: "\f424\f424"; }
.fad.fa-oil-can::after, .fa-duotone.fa-oil-can::after {
  content: "\f613\f613"; }
.fad.fa-t::after, .fa-duotone.fa-t::after {
  content: "\54\54"; }
.fad.fa-transformer-bolt::after, .fa-duotone.fa-transformer-bolt::after {
  content: "\e2a4\e2a4"; }
.fad.fa-hippo::after, .fa-duotone.fa-hippo::after {
  content: "\f6ed\f6ed"; }
.fad.fa-chart-column::after, .fa-duotone.fa-chart-column::after {
  content: "\e0e3\e0e3"; }
.fad.fa-cassette-vhs::after, .fa-duotone.fa-cassette-vhs::after {
  content: "\f8ec\f8ec"; }
.fad.fa-vhs::after, .fa-duotone.fa-vhs::after {
  content: "\f8ec\f8ec"; }
.fad.fa-infinity::after, .fa-duotone.fa-infinity::after {
  content: "\f534\f534"; }
.fad.fa-vial-circle-check::after, .fa-duotone.fa-vial-circle-check::after {
  content: "\e596\e596"; }
.fad.fa-chimney::after, .fa-duotone.fa-chimney::after {
  content: "\f78b\f78b"; }
.fad.fa-object-intersect::after, .fa-duotone.fa-object-intersect::after {
  content: "\e49d\e49d"; }
.fad.fa-person-arrow-down-to-line::after, .fa-duotone.fa-person-arrow-down-to-line::after {
  content: "\e538\e538"; }
.fad.fa-voicemail::after, .fa-duotone.fa-voicemail::after {
  content: "\f897\f897"; }
.fad.fa-block-brick::after, .fa-duotone.fa-block-brick::after {
  content: "\e3db\e3db"; }
.fad.fa-wall-brick::after, .fa-duotone.fa-wall-brick::after {
  content: "\e3db\e3db"; }
.fad.fa-fan::after, .fa-duotone.fa-fan::after {
  content: "\f863\f863"; }
.fad.fa-bags-shopping::after, .fa-duotone.fa-bags-shopping::after {
  content: "\f847\f847"; }
.fad.fa-paragraph-left::after, .fa-duotone.fa-paragraph-left::after {
  content: "\f878\f878"; }
.fad.fa-paragraph-rtl::after, .fa-duotone.fa-paragraph-rtl::after {
  content: "\f878\f878"; }
.fad.fa-person-walking-luggage::after, .fa-duotone.fa-person-walking-luggage::after {
  content: "\e554\e554"; }
.fad.fa-caravan-simple::after, .fa-duotone.fa-caravan-simple::after {
  content: "\e000\e000"; }
.fad.fa-caravan-alt::after, .fa-duotone.fa-caravan-alt::after {
  content: "\e000\e000"; }
.fad.fa-turtle::after, .fa-duotone.fa-turtle::after {
  content: "\f726\f726"; }
.fad.fa-up-down::after, .fa-duotone.fa-up-down::after {
  content: "\f338\f338"; }
.fad.fa-arrows-alt-v::after, .fa-duotone.fa-arrows-alt-v::after {
  content: "\f338\f338"; }
.fad.fa-cloud-moon-rain::after, .fa-duotone.fa-cloud-moon-rain::after {
  content: "\f73c\f73c"; }
.fad.fa-booth-curtain::after, .fa-duotone.fa-booth-curtain::after {
  content: "\f734\f734"; }
.fad.fa-calendar::after, .fa-duotone.fa-calendar::after {
  content: "\f133\f133"; }
.fad.fa-box-heart::after, .fa-duotone.fa-box-heart::after {
  content: "\f49d\f49d"; }
.fad.fa-trailer::after, .fa-duotone.fa-trailer::after {
  content: "\e041\e041"; }
.fad.fa-user-doctor-message::after, .fa-duotone.fa-user-doctor-message::after {
  content: "\f82e\f82e"; }
.fad.fa-user-md-chat::after, .fa-duotone.fa-user-md-chat::after {
  content: "\f82e\f82e"; }
.fad.fa-bahai::after, .fa-duotone.fa-bahai::after {
  content: "\f666\f666"; }
.fad.fa-haykal::after, .fa-duotone.fa-haykal::after {
  content: "\f666\f666"; }
.fad.fa-amp-guitar::after, .fa-duotone.fa-amp-guitar::after {
  content: "\f8a1\f8a1"; }
.fad.fa-sd-card::after, .fa-duotone.fa-sd-card::after {
  content: "\f7c2\f7c2"; }
.fad.fa-volume-slash::after, .fa-duotone.fa-volume-slash::after {
  content: "\f2e2\f2e2"; }
.fad.fa-border-bottom::after, .fa-duotone.fa-border-bottom::after {
  content: "\f84d\f84d"; }
.fad.fa-wifi-weak::after, .fa-duotone.fa-wifi-weak::after {
  content: "\f6aa\f6aa"; }
.fad.fa-wifi-1::after, .fa-duotone.fa-wifi-1::after {
  content: "\f6aa\f6aa"; }
.fad.fa-dragon::after, .fa-duotone.fa-dragon::after {
  content: "\f6d5\f6d5"; }
.fad.fa-shoe-prints::after, .fa-duotone.fa-shoe-prints::after {
  content: "\f54b\f54b"; }
.fad.fa-circle-plus::after, .fa-duotone.fa-circle-plus::after {
  content: "\f055\f055"; }
.fad.fa-plus-circle::after, .fa-duotone.fa-plus-circle::after {
  content: "\f055\f055"; }
.fad.fa-face-grin-tongue-wink::after, .fa-duotone.fa-face-grin-tongue-wink::after {
  content: "\f58b\f58b"; }
.fad.fa-grin-tongue-wink::after, .fa-duotone.fa-grin-tongue-wink::after {
  content: "\f58b\f58b"; }
.fad.fa-hand-holding::after, .fa-duotone.fa-hand-holding::after {
  content: "\f4bd\f4bd"; }
.fad.fa-plug-circle-exclamation::after, .fa-duotone.fa-plug-circle-exclamation::after {
  content: "\e55d\e55d"; }
.fad.fa-link-slash::after, .fa-duotone.fa-link-slash::after {
  content: "\f127\f127"; }
.fad.fa-chain-broken::after, .fa-duotone.fa-chain-broken::after {
  content: "\f127\f127"; }
.fad.fa-chain-slash::after, .fa-duotone.fa-chain-slash::after {
  content: "\f127\f127"; }
.fad.fa-unlink::after, .fa-duotone.fa-unlink::after {
  content: "\f127\f127"; }
.fad.fa-clone::after, .fa-duotone.fa-clone::after {
  content: "\f24d\f24d"; }
.fad.fa-person-walking-arrow-loop-left::after, .fa-duotone.fa-person-walking-arrow-loop-left::after {
  content: "\e551\e551"; }
.fad.fa-arrow-up-z-a::after, .fa-duotone.fa-arrow-up-z-a::after {
  content: "\f882\f882"; }
.fad.fa-sort-alpha-up-alt::after, .fa-duotone.fa-sort-alpha-up-alt::after {
  content: "\f882\f882"; }
.fad.fa-fire-flame-curved::after, .fa-duotone.fa-fire-flame-curved::after {
  content: "\f7e4\f7e4"; }
.fad.fa-fire-alt::after, .fa-duotone.fa-fire-alt::after {
  content: "\f7e4\f7e4"; }
.fad.fa-tornado::after, .fa-duotone.fa-tornado::after {
  content: "\f76f\f76f"; }
.fad.fa-file-circle-plus::after, .fa-duotone.fa-file-circle-plus::after {
  content: "\e494\e494"; }
.fad.fa-delete-right::after, .fa-duotone.fa-delete-right::after {
  content: "\e154\e154"; }
.fad.fa-book-quran::after, .fa-duotone.fa-book-quran::after {
  content: "\f687\f687"; }
.fad.fa-quran::after, .fa-duotone.fa-quran::after {
  content: "\f687\f687"; }
.fad.fa-circle-quarter::after, .fa-duotone.fa-circle-quarter::after {
  content: "\e11f\e11f"; }
.fad.fa-anchor::after, .fa-duotone.fa-anchor::after {
  content: "\f13d\f13d"; }
.fad.fa-border-all::after, .fa-duotone.fa-border-all::after {
  content: "\f84c\f84c"; }
.fad.fa-function::after, .fa-duotone.fa-function::after {
  content: "\f661\f661"; }
.fad.fa-face-angry::after, .fa-duotone.fa-face-angry::after {
  content: "\f556\f556"; }
.fad.fa-angry::after, .fa-duotone.fa-angry::after {
  content: "\f556\f556"; }
.fad.fa-people-simple::after, .fa-duotone.fa-people-simple::after {
  content: "\e21b\e21b"; }
.fad.fa-cookie-bite::after, .fa-duotone.fa-cookie-bite::after {
  content: "\f564\f564"; }
.fad.fa-arrow-trend-down::after, .fa-duotone.fa-arrow-trend-down::after {
  content: "\e097\e097"; }
.fad.fa-rss::after, .fa-duotone.fa-rss::after {
  content: "\f09e\f09e"; }
.fad.fa-feed::after, .fa-duotone.fa-feed::after {
  content: "\f09e\f09e"; }
.fad.fa-face-monocle::after, .fa-duotone.fa-face-monocle::after {
  content: "\e380\e380"; }
.fad.fa-draw-polygon::after, .fa-duotone.fa-draw-polygon::after {
  content: "\f5ee\f5ee"; }
.fad.fa-scale-balanced::after, .fa-duotone.fa-scale-balanced::after {
  content: "\f24e\f24e"; }
.fad.fa-balance-scale::after, .fa-duotone.fa-balance-scale::after {
  content: "\f24e\f24e"; }
.fad.fa-calendar-lines::after, .fa-duotone.fa-calendar-lines::after {
  content: "\e0d5\e0d5"; }
.fad.fa-calendar-note::after, .fa-duotone.fa-calendar-note::after {
  content: "\e0d5\e0d5"; }
.fad.fa-arrow-down-big-small::after, .fa-duotone.fa-arrow-down-big-small::after {
  content: "\f88c\f88c"; }
.fad.fa-sort-size-down::after, .fa-duotone.fa-sort-size-down::after {
  content: "\f88c\f88c"; }
.fad.fa-gauge-simple-high::after, .fa-duotone.fa-gauge-simple-high::after {
  content: "\f62a\f62a"; }
.fad.fa-tachometer::after, .fa-duotone.fa-tachometer::after {
  content: "\f62a\f62a"; }
.fad.fa-tachometer-fast::after, .fa-duotone.fa-tachometer-fast::after {
  content: "\f62a\f62a"; }
.fad.fa-do-not-enter::after, .fa-duotone.fa-do-not-enter::after {
  content: "\f5ec\f5ec"; }
.fad.fa-shower::after, .fa-duotone.fa-shower::after {
  content: "\f2cc\f2cc"; }
.fad.fa-dice-d8::after, .fa-duotone.fa-dice-d8::after {
  content: "\f6d2\f6d2"; }
.fad.fa-desktop::after, .fa-duotone.fa-desktop::after {
  content: "\f390\f390"; }
.fad.fa-desktop-alt::after, .fa-duotone.fa-desktop-alt::after {
  content: "\f390\f390"; }
.fad.fa-m::after, .fa-duotone.fa-m::after {
  content: "\4d\4d"; }
.fad.fa-grip-dots-vertical::after, .fa-duotone.fa-grip-dots-vertical::after {
  content: "\e411\e411"; }
.fad.fa-face-viewfinder::after, .fa-duotone.fa-face-viewfinder::after {
  content: "\e2ff\e2ff"; }
.fad.fa-soft-serve::after, .fa-duotone.fa-soft-serve::after {
  content: "\e400\e400"; }
.fad.fa-creemee::after, .fa-duotone.fa-creemee::after {
  content: "\e400\e400"; }
.fad.fa-h5::after, .fa-duotone.fa-h5::after {
  content: "\e412\e412"; }
.fad.fa-hand-back-point-down::after, .fa-duotone.fa-hand-back-point-down::after {
  content: "\e19e\e19e"; }
.fad.fa-table-list::after, .fa-duotone.fa-table-list::after {
  content: "\f00b\f00b"; }
.fad.fa-th-list::after, .fa-duotone.fa-th-list::after {
  content: "\f00b\f00b"; }
.fad.fa-comment-sms::after, .fa-duotone.fa-comment-sms::after {
  content: "\f7cd\f7cd"; }
.fad.fa-sms::after, .fa-duotone.fa-sms::after {
  content: "\f7cd\f7cd"; }
.fad.fa-rectangle::after, .fa-duotone.fa-rectangle::after {
  content: "\f2fa\f2fa"; }
.fad.fa-rectangle-landscape::after, .fa-duotone.fa-rectangle-landscape::after {
  content: "\f2fa\f2fa"; }
.fad.fa-clipboard-list-check::after, .fa-duotone.fa-clipboard-list-check::after {
  content: "\f737\f737"; }
.fad.fa-turkey::after, .fa-duotone.fa-turkey::after {
  content: "\f725\f725"; }
.fad.fa-book::after, .fa-duotone.fa-book::after {
  content: "\f02d\f02d"; }
.fad.fa-user-plus::after, .fa-duotone.fa-user-plus::after {
  content: "\f234\f234"; }
.fad.fa-ice-skate::after, .fa-duotone.fa-ice-skate::after {
  content: "\f7ac\f7ac"; }
.fad.fa-check::after, .fa-duotone.fa-check::after {
  content: "\f00c\f00c"; }
.fad.fa-battery-three-quarters::after, .fa-duotone.fa-battery-three-quarters::after {
  content: "\f241\f241"; }
.fad.fa-battery-4::after, .fa-duotone.fa-battery-4::after {
  content: "\f241\f241"; }
.fad.fa-tomato::after, .fa-duotone.fa-tomato::after {
  content: "\e330\e330"; }
.fad.fa-sword-laser::after, .fa-duotone.fa-sword-laser::after {
  content: "\e03b\e03b"; }
.fad.fa-house-circle-check::after, .fa-duotone.fa-house-circle-check::after {
  content: "\e509\e509"; }
.fad.fa-buildings::after, .fa-duotone.fa-buildings::after {
  content: "\e0cc\e0cc"; }
.fad.fa-angle-left::after, .fa-duotone.fa-angle-left::after {
  content: "\f104\f104"; }
.fad.fa-cart-flatbed-boxes::after, .fa-duotone.fa-cart-flatbed-boxes::after {
  content: "\f475\f475"; }
.fad.fa-dolly-flatbed-alt::after, .fa-duotone.fa-dolly-flatbed-alt::after {
  content: "\f475\f475"; }
.fad.fa-diagram-successor::after, .fa-duotone.fa-diagram-successor::after {
  content: "\e47a\e47a"; }
.fad.fa-truck-arrow-right::after, .fa-duotone.fa-truck-arrow-right::after {
  content: "\e58b\e58b"; }
.fad.fa-square-w::after, .fa-duotone.fa-square-w::after {
  content: "\e285\e285"; }
.fad.fa-arrows-split-up-and-left::after, .fa-duotone.fa-arrows-split-up-and-left::after {
  content: "\e4bc\e4bc"; }
.fad.fa-lamp::after, .fa-duotone.fa-lamp::after {
  content: "\f4ca\f4ca"; }
.fad.fa-airplay::after, .fa-duotone.fa-airplay::after {
  content: "\e089\e089"; }
.fad.fa-hand-fist::after, .fa-duotone.fa-hand-fist::after {
  content: "\f6de\f6de"; }
.fad.fa-fist-raised::after, .fa-duotone.fa-fist-raised::after {
  content: "\f6de\f6de"; }
.fad.fa-shield-quartered::after, .fa-duotone.fa-shield-quartered::after {
  content: "\e575\e575"; }
.fad.fa-slash-forward::after, .fa-duotone.fa-slash-forward::after {
  content: "\2f\2f"; }
.fad.fa-location-pen::after, .fa-duotone.fa-location-pen::after {
  content: "\f607\f607"; }
.fad.fa-map-marker-edit::after, .fa-duotone.fa-map-marker-edit::after {
  content: "\f607\f607"; }
.fad.fa-cloud-moon::after, .fa-duotone.fa-cloud-moon::after {
  content: "\f6c3\f6c3"; }
.fad.fa-pot-food::after, .fa-duotone.fa-pot-food::after {
  content: "\e43f\e43f"; }
.fad.fa-briefcase::after, .fa-duotone.fa-briefcase::after {
  content: "\f0b1\f0b1"; }
.fad.fa-person-falling::after, .fa-duotone.fa-person-falling::after {
  content: "\e546\e546"; }
.fad.fa-image-portrait::after, .fa-duotone.fa-image-portrait::after {
  content: "\f3e0\f3e0"; }
.fad.fa-portrait::after, .fa-duotone.fa-portrait::after {
  content: "\f3e0\f3e0"; }
.fad.fa-user-tag::after, .fa-duotone.fa-user-tag::after {
  content: "\f507\f507"; }
.fad.fa-rug::after, .fa-duotone.fa-rug::after {
  content: "\e569\e569"; }
.fad.fa-print-slash::after, .fa-duotone.fa-print-slash::after {
  content: "\f686\f686"; }
.fad.fa-earth-europe::after, .fa-duotone.fa-earth-europe::after {
  content: "\f7a2\f7a2"; }
.fad.fa-globe-europe::after, .fa-duotone.fa-globe-europe::after {
  content: "\f7a2\f7a2"; }
.fad.fa-cart-flatbed-suitcase::after, .fa-duotone.fa-cart-flatbed-suitcase::after {
  content: "\f59d\f59d"; }
.fad.fa-luggage-cart::after, .fa-duotone.fa-luggage-cart::after {
  content: "\f59d\f59d"; }
.fad.fa-hand-back-point-ribbon::after, .fa-duotone.fa-hand-back-point-ribbon::after {
  content: "\e1a0\e1a0"; }
.fad.fa-rectangle-xmark::after, .fa-duotone.fa-rectangle-xmark::after {
  content: "\f410\f410"; }
.fad.fa-rectangle-times::after, .fa-duotone.fa-rectangle-times::after {
  content: "\f410\f410"; }
.fad.fa-times-rectangle::after, .fa-duotone.fa-times-rectangle::after {
  content: "\f410\f410"; }
.fad.fa-window-close::after, .fa-duotone.fa-window-close::after {
  content: "\f410\f410"; }
.fad.fa-tire-rugged::after, .fa-duotone.fa-tire-rugged::after {
  content: "\f634\f634"; }
.fad.fa-lightbulb-dollar::after, .fa-duotone.fa-lightbulb-dollar::after {
  content: "\f670\f670"; }
.fad.fa-cowbell::after, .fa-duotone.fa-cowbell::after {
  content: "\f8b3\f8b3"; }
.fad.fa-baht-sign::after, .fa-duotone.fa-baht-sign::after {
  content: "\e0ac\e0ac"; }
.fad.fa-corner::after, .fa-duotone.fa-corner::after {
  content: "\e3fe\e3fe"; }
.fad.fa-chevrons-right::after, .fa-duotone.fa-chevrons-right::after {
  content: "\f324\f324"; }
.fad.fa-chevron-double-right::after, .fa-duotone.fa-chevron-double-right::after {
  content: "\f324\f324"; }
.fad.fa-book-open::after, .fa-duotone.fa-book-open::after {
  content: "\f518\f518"; }
.fad.fa-book-journal-whills::after, .fa-duotone.fa-book-journal-whills::after {
  content: "\f66a\f66a"; }
.fad.fa-journal-whills::after, .fa-duotone.fa-journal-whills::after {
  content: "\f66a\f66a"; }
.fad.fa-inhaler::after, .fa-duotone.fa-inhaler::after {
  content: "\f5f9\f5f9"; }
.fad.fa-handcuffs::after, .fa-duotone.fa-handcuffs::after {
  content: "\e4f8\e4f8"; }
.fad.fa-snake::after, .fa-duotone.fa-snake::after {
  content: "\f716\f716"; }
.fad.fa-triangle-exclamation::after, .fa-duotone.fa-triangle-exclamation::after {
  content: "\f071\f071"; }
.fad.fa-exclamation-triangle::after, .fa-duotone.fa-exclamation-triangle::after {
  content: "\f071\f071"; }
.fad.fa-warning::after, .fa-duotone.fa-warning::after {
  content: "\f071\f071"; }
.fad.fa-note-medical::after, .fa-duotone.fa-note-medical::after {
  content: "\e200\e200"; }
.fad.fa-database::after, .fa-duotone.fa-database::after {
  content: "\f1c0\f1c0"; }
.fad.fa-down-left::after, .fa-duotone.fa-down-left::after {
  content: "\e16a\e16a"; }
.fad.fa-share::after, .fa-duotone.fa-share::after {
  content: "\f064\f064"; }
.fad.fa-arrow-turn-right::after, .fa-duotone.fa-arrow-turn-right::after {
  content: "\f064\f064"; }
.fad.fa-mail-forward::after, .fa-duotone.fa-mail-forward::after {
  content: "\f064\f064"; }
.fad.fa-face-thinking::after, .fa-duotone.fa-face-thinking::after {
  content: "\e39b\e39b"; }
.fad.fa-turn-down-right::after, .fa-duotone.fa-turn-down-right::after {
  content: "\e455\e455"; }
.fad.fa-bottle-droplet::after, .fa-duotone.fa-bottle-droplet::after {
  content: "\e4c4\e4c4"; }
.fad.fa-mask-face::after, .fa-duotone.fa-mask-face::after {
  content: "\e1d7\e1d7"; }
.fad.fa-hill-rockslide::after, .fa-duotone.fa-hill-rockslide::after {
  content: "\e508\e508"; }
.fad.fa-scanner-keyboard::after, .fa-duotone.fa-scanner-keyboard::after {
  content: "\f489\f489"; }
.fad.fa-circle-o::after, .fa-duotone.fa-circle-o::after {
  content: "\e119\e119"; }
.fad.fa-grid-horizontal::after, .fa-duotone.fa-grid-horizontal::after {
  content: "\e307\e307"; }
.fad.fa-message-dollar::after, .fa-duotone.fa-message-dollar::after {
  content: "\f650\f650"; }
.fad.fa-comment-alt-dollar::after, .fa-duotone.fa-comment-alt-dollar::after {
  content: "\f650\f650"; }
.fad.fa-right-left::after, .fa-duotone.fa-right-left::after {
  content: "\f362\f362"; }
.fad.fa-exchange-alt::after, .fa-duotone.fa-exchange-alt::after {
  content: "\f362\f362"; }
.fad.fa-columns-3::after, .fa-duotone.fa-columns-3::after {
  content: "\e361\e361"; }
.fad.fa-paper-plane::after, .fa-duotone.fa-paper-plane::after {
  content: "\f1d8\f1d8"; }
.fad.fa-road-circle-exclamation::after, .fa-duotone.fa-road-circle-exclamation::after {
  content: "\e565\e565"; }
.fad.fa-dungeon::after, .fa-duotone.fa-dungeon::after {
  content: "\f6d9\f6d9"; }
.fad.fa-hand-holding-box::after, .fa-duotone.fa-hand-holding-box::after {
  content: "\f47b\f47b"; }
.fad.fa-input-text::after, .fa-duotone.fa-input-text::after {
  content: "\e1bf\e1bf"; }
.fad.fa-window-flip::after, .fa-duotone.fa-window-flip::after {
  content: "\f40f\f40f"; }
.fad.fa-window-alt::after, .fa-duotone.fa-window-alt::after {
  content: "\f40f\f40f"; }
.fad.fa-align-right::after, .fa-duotone.fa-align-right::after {
  content: "\f038\f038"; }
.fad.fa-scanner-gun::after, .fa-duotone.fa-scanner-gun::after {
  content: "\f488\f488"; }
.fad.fa-scanner::after, .fa-duotone.fa-scanner::after {
  content: "\f488\f488"; }
.fad.fa-tire::after, .fa-duotone.fa-tire::after {
  content: "\f631\f631"; }
.fad.fa-engine::after, .fa-duotone.fa-engine::after {
  content: "\e16e\e16e"; }
.fad.fa-money-bill-1-wave::after, .fa-duotone.fa-money-bill-1-wave::after {
  content: "\f53b\f53b"; }
.fad.fa-money-bill-wave-alt::after, .fa-duotone.fa-money-bill-wave-alt::after {
  content: "\f53b\f53b"; }
.fad.fa-life-ring::after, .fa-duotone.fa-life-ring::after {
  content: "\f1cd\f1cd"; }
.fad.fa-hands::after, .fa-duotone.fa-hands::after {
  content: "\f2a7\f2a7"; }
.fad.fa-sign-language::after, .fa-duotone.fa-sign-language::after {
  content: "\f2a7\f2a7"; }
.fad.fa-signing::after, .fa-duotone.fa-signing::after {
  content: "\f2a7\f2a7"; }
.fad.fa-circle-caret-right::after, .fa-duotone.fa-circle-caret-right::after {
  content: "\f330\f330"; }
.fad.fa-caret-circle-right::after, .fa-duotone.fa-caret-circle-right::after {
  content: "\f330\f330"; }
.fad.fa-wheat::after, .fa-duotone.fa-wheat::after {
  content: "\f72d\f72d"; }
.fad.fa-file-spreadsheet::after, .fa-duotone.fa-file-spreadsheet::after {
  content: "\f65b\f65b"; }
.fad.fa-audio-description-slash::after, .fa-duotone.fa-audio-description-slash::after {
  content: "\e0a8\e0a8"; }
.fad.fa-calendar-day::after, .fa-duotone.fa-calendar-day::after {
  content: "\f783\f783"; }
.fad.fa-water-ladder::after, .fa-duotone.fa-water-ladder::after {
  content: "\f5c5\f5c5"; }
.fad.fa-ladder-water::after, .fa-duotone.fa-ladder-water::after {
  content: "\f5c5\f5c5"; }
.fad.fa-swimming-pool::after, .fa-duotone.fa-swimming-pool::after {
  content: "\f5c5\f5c5"; }
.fad.fa-arrows-up-down::after, .fa-duotone.fa-arrows-up-down::after {
  content: "\f07d\f07d"; }
.fad.fa-arrows-v::after, .fa-duotone.fa-arrows-v::after {
  content: "\f07d\f07d"; }
.fad.fa-chess-pawn-piece::after, .fa-duotone.fa-chess-pawn-piece::after {
  content: "\f444\f444"; }
.fad.fa-chess-pawn-alt::after, .fa-duotone.fa-chess-pawn-alt::after {
  content: "\f444\f444"; }
.fad.fa-face-grimace::after, .fa-duotone.fa-face-grimace::after {
  content: "\f57f\f57f"; }
.fad.fa-grimace::after, .fa-duotone.fa-grimace::after {
  content: "\f57f\f57f"; }
.fad.fa-wheelchair-move::after, .fa-duotone.fa-wheelchair-move::after {
  content: "\e2ce\e2ce"; }
.fad.fa-wheelchair-alt::after, .fa-duotone.fa-wheelchair-alt::after {
  content: "\e2ce\e2ce"; }
.fad.fa-turn-down::after, .fa-duotone.fa-turn-down::after {
  content: "\f3be\f3be"; }
.fad.fa-level-down-alt::after, .fa-duotone.fa-level-down-alt::after {
  content: "\f3be\f3be"; }
.fad.fa-square-s::after, .fa-duotone.fa-square-s::after {
  content: "\e27d\e27d"; }
.fad.fa-rectangle-barcode::after, .fa-duotone.fa-rectangle-barcode::after {
  content: "\f463\f463"; }
.fad.fa-barcode-alt::after, .fa-duotone.fa-barcode-alt::after {
  content: "\f463\f463"; }
.fad.fa-person-walking-arrow-right::after, .fa-duotone.fa-person-walking-arrow-right::after {
  content: "\e552\e552"; }
.fad.fa-square-envelope::after, .fa-duotone.fa-square-envelope::after {
  content: "\f199\f199"; }
.fad.fa-envelope-square::after, .fa-duotone.fa-envelope-square::after {
  content: "\f199\f199"; }
.fad.fa-dice::after, .fa-duotone.fa-dice::after {
  content: "\f522\f522"; }
.fad.fa-unicorn::after, .fa-duotone.fa-unicorn::after {
  content: "\f727\f727"; }
.fad.fa-bowling-ball::after, .fa-duotone.fa-bowling-ball::after {
  content: "\f436\f436"; }
.fad.fa-pompebled::after, .fa-duotone.fa-pompebled::after {
  content: "\e43d\e43d"; }
.fad.fa-brain::after, .fa-duotone.fa-brain::after {
  content: "\f5dc\f5dc"; }
.fad.fa-watch-smart::after, .fa-duotone.fa-watch-smart::after {
  content: "\e2cc\e2cc"; }
.fad.fa-book-user::after, .fa-duotone.fa-book-user::after {
  content: "\f7e7\f7e7"; }
.fad.fa-sensor-cloud::after, .fa-duotone.fa-sensor-cloud::after {
  content: "\e02c\e02c"; }
.fad.fa-sensor-smoke::after, .fa-duotone.fa-sensor-smoke::after {
  content: "\e02c\e02c"; }
.fad.fa-clapperboard-play::after, .fa-duotone.fa-clapperboard-play::after {
  content: "\e132\e132"; }
.fad.fa-bandage::after, .fa-duotone.fa-bandage::after {
  content: "\f462\f462"; }
.fad.fa-band-aid::after, .fa-duotone.fa-band-aid::after {
  content: "\f462\f462"; }
.fad.fa-calendar-minus::after, .fa-duotone.fa-calendar-minus::after {
  content: "\f272\f272"; }
.fad.fa-circle-xmark::after, .fa-duotone.fa-circle-xmark::after {
  content: "\f057\f057"; }
.fad.fa-times-circle::after, .fa-duotone.fa-times-circle::after {
  content: "\f057\f057"; }
.fad.fa-xmark-circle::after, .fa-duotone.fa-xmark-circle::after {
  content: "\f057\f057"; }
.fad.fa-circle-4::after, .fa-duotone.fa-circle-4::after {
  content: "\e0f1\e0f1"; }
.fad.fa-gifts::after, .fa-duotone.fa-gifts::after {
  content: "\f79c\f79c"; }
.fad.fa-album-collection::after, .fa-duotone.fa-album-collection::after {
  content: "\f8a0\f8a0"; }
.fad.fa-hotel::after, .fa-duotone.fa-hotel::after {
  content: "\f594\f594"; }
.fad.fa-earth-asia::after, .fa-duotone.fa-earth-asia::after {
  content: "\f57e\f57e"; }
.fad.fa-globe-asia::after, .fa-duotone.fa-globe-asia::after {
  content: "\f57e\f57e"; }
.fad.fa-id-card-clip::after, .fa-duotone.fa-id-card-clip::after {
  content: "\f47f\f47f"; }
.fad.fa-id-card-alt::after, .fa-duotone.fa-id-card-alt::after {
  content: "\f47f\f47f"; }
.fad.fa-magnifying-glass-plus::after, .fa-duotone.fa-magnifying-glass-plus::after {
  content: "\f00e\f00e"; }
.fad.fa-search-plus::after, .fa-duotone.fa-search-plus::after {
  content: "\f00e\f00e"; }
.fad.fa-thumbs-up::after, .fa-duotone.fa-thumbs-up::after {
  content: "\f164\f164"; }
.fad.fa-cloud-showers::after, .fa-duotone.fa-cloud-showers::after {
  content: "\f73f\f73f"; }
.fad.fa-user-clock::after, .fa-duotone.fa-user-clock::after {
  content: "\f4fd\f4fd"; }
.fad.fa-onion::after, .fa-duotone.fa-onion::after {
  content: "\e427\e427"; }
.fad.fa-clock-twelve-thirty::after, .fa-duotone.fa-clock-twelve-thirty::after {
  content: "\e359\e359"; }
.fad.fa-arrow-down-to-dotted-line::after, .fa-duotone.fa-arrow-down-to-dotted-line::after {
  content: "\e095\e095"; }
.fad.fa-hand-dots::after, .fa-duotone.fa-hand-dots::after {
  content: "\f461\f461"; }
.fad.fa-allergies::after, .fa-duotone.fa-allergies::after {
  content: "\f461\f461"; }
.fad.fa-file-invoice::after, .fa-duotone.fa-file-invoice::after {
  content: "\f570\f570"; }
.fad.fa-window-minimize::after, .fa-duotone.fa-window-minimize::after {
  content: "\f2d1\f2d1"; }
.fad.fa-rectangle-wide::after, .fa-duotone.fa-rectangle-wide::after {
  content: "\f2fc\f2fc"; }
.fad.fa-comment-arrow-up::after, .fa-duotone.fa-comment-arrow-up::after {
  content: "\e144\e144"; }
.fad.fa-garlic::after, .fa-duotone.fa-garlic::after {
  content: "\e40e\e40e"; }
.fad.fa-mug-saucer::after, .fa-duotone.fa-mug-saucer::after {
  content: "\f0f4\f0f4"; }
.fad.fa-coffee::after, .fa-duotone.fa-coffee::after {
  content: "\f0f4\f0f4"; }
.fad.fa-brush::after, .fa-duotone.fa-brush::after {
  content: "\f55d\f55d"; }
.fad.fa-tree-decorated::after, .fa-duotone.fa-tree-decorated::after {
  content: "\f7dc\f7dc"; }
.fad.fa-mask::after, .fa-duotone.fa-mask::after {
  content: "\f6fa\f6fa"; }
.fad.fa-calendar-heart::after, .fa-duotone.fa-calendar-heart::after {
  content: "\e0d3\e0d3"; }
.fad.fa-magnifying-glass-minus::after, .fa-duotone.fa-magnifying-glass-minus::after {
  content: "\f010\f010"; }
.fad.fa-search-minus::after, .fa-duotone.fa-search-minus::after {
  content: "\f010\f010"; }
.fad.fa-flower::after, .fa-duotone.fa-flower::after {
  content: "\f7ff\f7ff"; }
.fad.fa-ruler-vertical::after, .fa-duotone.fa-ruler-vertical::after {
  content: "\f548\f548"; }
.fad.fa-user-large::after, .fa-duotone.fa-user-large::after {
  content: "\f406\f406"; }
.fad.fa-user-alt::after, .fa-duotone.fa-user-alt::after {
  content: "\f406\f406"; }
.fad.fa-starship-freighter::after, .fa-duotone.fa-starship-freighter::after {
  content: "\e03a\e03a"; }
.fad.fa-train-tram::after, .fa-duotone.fa-train-tram::after {
  content: "\e5b4\e5b4"; }
.fad.fa-bridge-suspension::after, .fa-duotone.fa-bridge-suspension::after {
  content: "\e4cd\e4cd"; }
.fad.fa-trash-check::after, .fa-duotone.fa-trash-check::after {
  content: "\e2af\e2af"; }
.fad.fa-user-nurse::after, .fa-duotone.fa-user-nurse::after {
  content: "\f82f\f82f"; }
.fad.fa-boombox::after, .fa-duotone.fa-boombox::after {
  content: "\f8a5\f8a5"; }
.fad.fa-syringe::after, .fa-duotone.fa-syringe::after {
  content: "\f48e\f48e"; }
.fad.fa-cloud-sun::after, .fa-duotone.fa-cloud-sun::after {
  content: "\f6c4\f6c4"; }
.fad.fa-shield-exclamation::after, .fa-duotone.fa-shield-exclamation::after {
  content: "\e247\e247"; }
.fad.fa-stopwatch-20::after, .fa-duotone.fa-stopwatch-20::after {
  content: "\e06f\e06f"; }
.fad.fa-square-full::after, .fa-duotone.fa-square-full::after {
  content: "\f45c\f45c"; }
.fad.fa-grip-dots::after, .fa-duotone.fa-grip-dots::after {
  content: "\e410\e410"; }
.fad.fa-comment-exclamation::after, .fa-duotone.fa-comment-exclamation::after {
  content: "\f4af\f4af"; }
.fad.fa-pen-swirl::after, .fa-duotone.fa-pen-swirl::after {
  content: "\e214\e214"; }
.fad.fa-falafel::after, .fa-duotone.fa-falafel::after {
  content: "\e40a\e40a"; }
.fad.fa-circle-2::after, .fa-duotone.fa-circle-2::after {
  content: "\e0ef\e0ef"; }
.fad.fa-magnet::after, .fa-duotone.fa-magnet::after {
  content: "\f076\f076"; }
.fad.fa-jar::after, .fa-duotone.fa-jar::after {
  content: "\e516\e516"; }
.fad.fa-gramophone::after, .fa-duotone.fa-gramophone::after {
  content: "\f8bd\f8bd"; }
.fad.fa-dice-d12::after, .fa-duotone.fa-dice-d12::after {
  content: "\f6ce\f6ce"; }
.fad.fa-note-sticky::after, .fa-duotone.fa-note-sticky::after {
  content: "\f249\f249"; }
.fad.fa-sticky-note::after, .fa-duotone.fa-sticky-note::after {
  content: "\f249\f249"; }
.fad.fa-down::after, .fa-duotone.fa-down::after {
  content: "\f354\f354"; }
.fad.fa-arrow-alt-down::after, .fa-duotone.fa-arrow-alt-down::after {
  content: "\f354\f354"; }
.fad.fa-hundred-points::after, .fa-duotone.fa-hundred-points::after {
  content: "\e41c\e41c"; }
.fad.fa-100::after, .fa-duotone.fa-100::after {
  content: "\e41c\e41c"; }
.fad.fa-paperclip-vertical::after, .fa-duotone.fa-paperclip-vertical::after {
  content: "\e3c2\e3c2"; }
.fad.fa-wind-warning::after, .fa-duotone.fa-wind-warning::after {
  content: "\f776\f776"; }
.fad.fa-wind-circle-exclamation::after, .fa-duotone.fa-wind-circle-exclamation::after {
  content: "\f776\f776"; }
.fad.fa-location-pin-slash::after, .fa-duotone.fa-location-pin-slash::after {
  content: "\f60c\f60c"; }
.fad.fa-map-marker-slash::after, .fa-duotone.fa-map-marker-slash::after {
  content: "\f60c\f60c"; }
.fad.fa-face-sad-sweat::after, .fa-duotone.fa-face-sad-sweat::after {
  content: "\e38a\e38a"; }
.fad.fa-bug-slash::after, .fa-duotone.fa-bug-slash::after {
  content: "\e490\e490"; }
.fad.fa-cupcake::after, .fa-duotone.fa-cupcake::after {
  content: "\e402\e402"; }
.fad.fa-light-switch-off::after, .fa-duotone.fa-light-switch-off::after {
  content: "\e018\e018"; }
.fad.fa-toggle-large-off::after, .fa-duotone.fa-toggle-large-off::after {
  content: "\e5b0\e5b0"; }
.fad.fa-pen-fancy-slash::after, .fa-duotone.fa-pen-fancy-slash::after {
  content: "\e210\e210"; }
.fad.fa-truck-container::after, .fa-duotone.fa-truck-container::after {
  content: "\f4dc\f4dc"; }
.fad.fa-boot::after, .fa-duotone.fa-boot::after {
  content: "\f782\f782"; }
.fad.fa-arrow-up-from-water-pump::after, .fa-duotone.fa-arrow-up-from-water-pump::after {
  content: "\e4b6\e4b6"; }
.fad.fa-file-check::after, .fa-duotone.fa-file-check::after {
  content: "\f316\f316"; }
.fad.fa-bone::after, .fa-duotone.fa-bone::after {
  content: "\f5d7\f5d7"; }
.fad.fa-cards-blank::after, .fa-duotone.fa-cards-blank::after {
  content: "\e4df\e4df"; }
.fad.fa-circle-3::after, .fa-duotone.fa-circle-3::after {
  content: "\e0f0\e0f0"; }
.fad.fa-bench-tree::after, .fa-duotone.fa-bench-tree::after {
  content: "\e2e7\e2e7"; }
.fad.fa-keyboard-brightness-low::after, .fa-duotone.fa-keyboard-brightness-low::after {
  content: "\e1c1\e1c1"; }
.fad.fa-ski-boot-ski::after, .fa-duotone.fa-ski-boot-ski::after {
  content: "\e3cd\e3cd"; }
.fad.fa-brain-circuit::after, .fa-duotone.fa-brain-circuit::after {
  content: "\e0c6\e0c6"; }
.fad.fa-user-injured::after, .fa-duotone.fa-user-injured::after {
  content: "\f728\f728"; }
.fad.fa-block-brick-fire::after, .fa-duotone.fa-block-brick-fire::after {
  content: "\e3dc\e3dc"; }
.fad.fa-firewall::after, .fa-duotone.fa-firewall::after {
  content: "\e3dc\e3dc"; }
.fad.fa-face-sad-tear::after, .fa-duotone.fa-face-sad-tear::after {
  content: "\f5b4\f5b4"; }
.fad.fa-sad-tear::after, .fa-duotone.fa-sad-tear::after {
  content: "\f5b4\f5b4"; }
.fad.fa-plane::after, .fa-duotone.fa-plane::after {
  content: "\f072\f072"; }
.fad.fa-tent-arrows-down::after, .fa-duotone.fa-tent-arrows-down::after {
  content: "\e581\e581"; }
.fad.fa-exclamation::after, .fa-duotone.fa-exclamation::after {
  content: "\21\21"; }
.fad.fa-arrows-spin::after, .fa-duotone.fa-arrows-spin::after {
  content: "\e4bb\e4bb"; }
.fad.fa-face-smile-relaxed::after, .fa-duotone.fa-face-smile-relaxed::after {
  content: "\e392\e392"; }
.fad.fa-comment-xmark::after, .fa-duotone.fa-comment-xmark::after {
  content: "\f4b5\f4b5"; }
.fad.fa-comment-times::after, .fa-duotone.fa-comment-times::after {
  content: "\f4b5\f4b5"; }
.fad.fa-print::after, .fa-duotone.fa-print::after {
  content: "\f02f\f02f"; }
.fad.fa-turkish-lira-sign::after, .fa-duotone.fa-turkish-lira-sign::after {
  content: "\e2bb\e2bb"; }
.fad.fa-try::after, .fa-duotone.fa-try::after {
  content: "\e2bb\e2bb"; }
.fad.fa-turkish-lira::after, .fa-duotone.fa-turkish-lira::after {
  content: "\e2bb\e2bb"; }
.fad.fa-face-nose-steam::after, .fa-duotone.fa-face-nose-steam::after {
  content: "\e382\e382"; }
.fad.fa-circle-waveform-lines::after, .fa-duotone.fa-circle-waveform-lines::after {
  content: "\e12d\e12d"; }
.fad.fa-waveform-circle::after, .fa-duotone.fa-waveform-circle::after {
  content: "\e12d\e12d"; }
.fad.fa-dollar-sign::after, .fa-duotone.fa-dollar-sign::after {
  content: "\24\24"; }
.fad.fa-dollar::after, .fa-duotone.fa-dollar::after {
  content: "\24\24"; }
.fad.fa-usd::after, .fa-duotone.fa-usd::after {
  content: "\24\24"; }
.fad.fa-ferris-wheel::after, .fa-duotone.fa-ferris-wheel::after {
  content: "\e174\e174"; }
.fad.fa-computer-speaker::after, .fa-duotone.fa-computer-speaker::after {
  content: "\f8b2\f8b2"; }
.fad.fa-skull-cow::after, .fa-duotone.fa-skull-cow::after {
  content: "\f8de\f8de"; }
.fad.fa-x::after, .fa-duotone.fa-x::after {
  content: "\58\58"; }
.fad.fa-magnifying-glass-dollar::after, .fa-duotone.fa-magnifying-glass-dollar::after {
  content: "\f688\f688"; }
.fad.fa-search-dollar::after, .fa-duotone.fa-search-dollar::after {
  content: "\f688\f688"; }
.fad.fa-users-gear::after, .fa-duotone.fa-users-gear::after {
  content: "\f509\f509"; }
.fad.fa-users-cog::after, .fa-duotone.fa-users-cog::after {
  content: "\f509\f509"; }
.fad.fa-person-military-pointing::after, .fa-duotone.fa-person-military-pointing::after {
  content: "\e54a\e54a"; }
.fad.fa-building-columns::after, .fa-duotone.fa-building-columns::after {
  content: "\f19c\f19c"; }
.fad.fa-bank::after, .fa-duotone.fa-bank::after {
  content: "\f19c\f19c"; }
.fad.fa-institution::after, .fa-duotone.fa-institution::after {
  content: "\f19c\f19c"; }
.fad.fa-museum::after, .fa-duotone.fa-museum::after {
  content: "\f19c\f19c"; }
.fad.fa-university::after, .fa-duotone.fa-university::after {
  content: "\f19c\f19c"; }
.fad.fa-circle-t::after, .fa-duotone.fa-circle-t::after {
  content: "\e124\e124"; }
.fad.fa-sack::after, .fa-duotone.fa-sack::after {
  content: "\f81c\f81c"; }
.fad.fa-grid-2::after, .fa-duotone.fa-grid-2::after {
  content: "\e196\e196"; }
.fad.fa-camera-cctv::after, .fa-duotone.fa-camera-cctv::after {
  content: "\f8ac\f8ac"; }
.fad.fa-cctv::after, .fa-duotone.fa-cctv::after {
  content: "\f8ac\f8ac"; }
.fad.fa-umbrella::after, .fa-duotone.fa-umbrella::after {
  content: "\f0e9\f0e9"; }
.fad.fa-trowel::after, .fa-duotone.fa-trowel::after {
  content: "\e589\e589"; }
.fad.fa-horizontal-rule::after, .fa-duotone.fa-horizontal-rule::after {
  content: "\f86c\f86c"; }
.fad.fa-bed-front::after, .fa-duotone.fa-bed-front::after {
  content: "\f8f7\f8f7"; }
.fad.fa-bed-alt::after, .fa-duotone.fa-bed-alt::after {
  content: "\f8f7\f8f7"; }
.fad.fa-d::after, .fa-duotone.fa-d::after {
  content: "\44\44"; }
.fad.fa-stapler::after, .fa-duotone.fa-stapler::after {
  content: "\e5af\e5af"; }
.fad.fa-masks-theater::after, .fa-duotone.fa-masks-theater::after {
  content: "\f630\f630"; }
.fad.fa-theater-masks::after, .fa-duotone.fa-theater-masks::after {
  content: "\f630\f630"; }
.fad.fa-kip-sign::after, .fa-duotone.fa-kip-sign::after {
  content: "\e1c4\e1c4"; }
.fad.fa-face-woozy::after, .fa-duotone.fa-face-woozy::after {
  content: "\e3a2\e3a2"; }
.fad.fa-cloud-question::after, .fa-duotone.fa-cloud-question::after {
  content: "\e492\e492"; }
.fad.fa-pineapple::after, .fa-duotone.fa-pineapple::after {
  content: "\e31f\e31f"; }
.fad.fa-hand-point-left::after, .fa-duotone.fa-hand-point-left::after {
  content: "\f0a5\f0a5"; }
.fad.fa-gallery-thumbnails::after, .fa-duotone.fa-gallery-thumbnails::after {
  content: "\e3aa\e3aa"; }
.fad.fa-circle-j::after, .fa-duotone.fa-circle-j::after {
  content: "\e112\e112"; }
.fad.fa-eyes::after, .fa-duotone.fa-eyes::after {
  content: "\e367\e367"; }
.fad.fa-handshake-simple::after, .fa-duotone.fa-handshake-simple::after {
  content: "\f4c6\f4c6"; }
.fad.fa-handshake-alt::after, .fa-duotone.fa-handshake-alt::after {
  content: "\f4c6\f4c6"; }
.fad.fa-page-caret-up::after, .fa-duotone.fa-page-caret-up::after {
  content: "\e42a\e42a"; }
.fad.fa-file-caret-up::after, .fa-duotone.fa-file-caret-up::after {
  content: "\e42a\e42a"; }
.fad.fa-jet-fighter::after, .fa-duotone.fa-jet-fighter::after {
  content: "\f0fb\f0fb"; }
.fad.fa-fighter-jet::after, .fa-duotone.fa-fighter-jet::after {
  content: "\f0fb\f0fb"; }
.fad.fa-comet::after, .fa-duotone.fa-comet::after {
  content: "\e003\e003"; }
.fad.fa-square-share-nodes::after, .fa-duotone.fa-square-share-nodes::after {
  content: "\f1e1\f1e1"; }
.fad.fa-share-alt-square::after, .fa-duotone.fa-share-alt-square::after {
  content: "\f1e1\f1e1"; }
.fad.fa-shield-keyhole::after, .fa-duotone.fa-shield-keyhole::after {
  content: "\e248\e248"; }
.fad.fa-barcode::after, .fa-duotone.fa-barcode::after {
  content: "\f02a\f02a"; }
.fad.fa-plus-minus::after, .fa-duotone.fa-plus-minus::after {
  content: "\e43c\e43c"; }
.fad.fa-square-sliders-vertical::after, .fa-duotone.fa-square-sliders-vertical::after {
  content: "\f3f2\f3f2"; }
.fad.fa-sliders-v-square::after, .fa-duotone.fa-sliders-v-square::after {
  content: "\f3f2\f3f2"; }
.fad.fa-video::after, .fa-duotone.fa-video::after {
  content: "\f03d\f03d"; }
.fad.fa-video-camera::after, .fa-duotone.fa-video-camera::after {
  content: "\f03d\f03d"; }
.fad.fa-message-middle::after, .fa-duotone.fa-message-middle::after {
  content: "\e1e1\e1e1"; }
.fad.fa-comment-middle-alt::after, .fa-duotone.fa-comment-middle-alt::after {
  content: "\e1e1\e1e1"; }
.fad.fa-graduation-cap::after, .fa-duotone.fa-graduation-cap::after {
  content: "\f19d\f19d"; }
.fad.fa-mortar-board::after, .fa-duotone.fa-mortar-board::after {
  content: "\f19d\f19d"; }
.fad.fa-hand-holding-medical::after, .fa-duotone.fa-hand-holding-medical::after {
  content: "\e05c\e05c"; }
.fad.fa-person-circle-check::after, .fa-duotone.fa-person-circle-check::after {
  content: "\e53e\e53e"; }
.fad.fa-square-z::after, .fa-duotone.fa-square-z::after {
  content: "\e288\e288"; }
.fad.fa-message-text::after, .fa-duotone.fa-message-text::after {
  content: "\e1e6\e1e6"; }
.fad.fa-comment-alt-text::after, .fa-duotone.fa-comment-alt-text::after {
  content: "\e1e6\e1e6"; }
.fad.fa-turn-up::after, .fa-duotone.fa-turn-up::after {
  content: "\f3bf\f3bf"; }
.fad.fa-level-up-alt::after, .fa-duotone.fa-level-up-alt::after {
  content: "\f3bf\f3bf"; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Pro'; }
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('fa-light-300.18596994182b05356f93.woff2') format("woff2"), url('fa-light-300.02ed6a2e5b79a0710156.ttf') format("truetype"); }
.fal,
.fa-light {
  font-weight: 300; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Pro'; }
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('fa-regular-400.5a18b197e984e04b2be1.woff2') format("woff2"), url('fa-regular-400.08deef48618ce28a573f.ttf') format("truetype"); }
.far,
.fa-regular {
  font-weight: 400; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro'; }
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('fa-solid-900.0a037305a0a6d42b99c3.woff2') format("woff2"), url('fa-solid-900.46457309c0517f65fdd5.ttf') format("truetype"); }
.fas,
.fa-solid {
  font-weight: 900; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Pro'; }
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url('fa-thin-100.33c2991a06db4396e56a.woff2') format("woff2"), url('fa-thin-100.e9857e8add32255d1b04.ttf') format("truetype"); }
.fat,
.fa-thin {
  font-weight: 100; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-display: block;
  font-weight: 400;
  src: url('fa-brands-400.e0685453384dbe061fe9.woff2') format("woff2"), url('fa-brands-400.dbcb0917dd88a81503b3.ttf') format("truetype"); }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 900;
  src: url('fa-solid-900.0a037305a0a6d42b99c3.woff2') format("woff2"), url('fa-solid-900.46457309c0517f65fdd5.ttf') format("truetype"); }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 400;
  src: url('fa-regular-400.5a18b197e984e04b2be1.woff2') format("woff2"), url('fa-regular-400.08deef48618ce28a573f.ttf') format("truetype"); }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-display: block;
  font-weight: 300;
  src: url('fa-light-300.18596994182b05356f93.woff2') format("woff2"), url('fa-light-300.02ed6a2e5b79a0710156.ttf') format("truetype"); }
@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-display: block;
  font-weight: 900;
  src: url('fa-duotone-900.d87ee5c7c02d2920c131.woff2') format("woff2"), url('fa-duotone-900.9a9b1affef09fa1b6ed1.ttf') format("truetype"); }
@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url('fa-solid-900.0a037305a0a6d42b99c3.woff2') format("woff2"), url('fa-solid-900.46457309c0517f65fdd5.ttf') format("truetype"); }
@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url('fa-brands-400.e0685453384dbe061fe9.woff2') format("woff2"), url('fa-brands-400.dbcb0917dd88a81503b3.ttf') format("truetype"); }
@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url('fa-regular-400.5a18b197e984e04b2be1.woff2') format("woff2"), url('fa-regular-400.08deef48618ce28a573f.ttf') format("truetype");
  unicode-range: U+F003,U+F006,U+F014,U+F016-F017,U+F01A-F01B,U+F01D,U+F022,U+F03E,U+F044,U+F046,U+F05C-F05D,U+F06E,U+F070,U+F087-F088,U+F08A,U+F094,U+F096-F097,U+F09D,U+F0A0,U+F0A2,U+F0A4-F0A7,U+F0C5,U+F0C7,U+F0E5-F0E6,U+F0EB,U+F0F6-F0F8,U+F10C,U+F114-F115,U+F118-F11A,U+F11C-F11D,U+F133,U+F147,U+F14E,U+F150-F152,U+F185-F186,U+F18E,U+F190-F192,U+F196,U+F1C1-F1C9,U+F1D9,U+F1DB,U+F1E3,U+F1EA,U+F1F7,U+F1F9,U+F20A,U+F247-F248,U+F24A,U+F24D,U+F255-F25B,U+F25D,U+F271-F274,U+F278,U+F27B,U+F28C,U+F28E,U+F29C,U+F2B5,U+F2B7,U+F2BA,U+F2BC,U+F2BE,U+F2C0-F2C1,U+F2C3,U+F2D0,U+F2D2,U+F2D4,U+F2DC; }
@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url('fa-v4compatibility.41a3700cf0f7ca715efa.woff2') format("woff2"), url('fa-v4compatibility.c8460512b70895617fd8.ttf') format("truetype");
  unicode-range: U+F041,U+F047,U+F065-F066,U+F07D-F07E,U+F080,U+F08B,U+F08E,U+F090,U+F09A,U+F0AC,U+F0AE,U+F0B2,U+F0D0,U+F0D6,U+F0E4,U+F0EC,U+F10A-F10B,U+F123,U+F13E,U+F148-F149,U+F14C,U+F156,U+F15E,U+F160-F161,U+F163,U+F175-F178,U+F195,U+F1F8,U+F219,U+F27A; }

