@import "variables";

$nav-tabs-border-width: 1px;

@import "bootstrap_override";

html, body {
    height: 100%;
}

body {
    --body-color: #F8F8F8;
    background-color: var(--body-color) !important;
    position: relative;
}

.male {
    color: #287FD0;
}

.female {
    color: $primary;
}

.macartevacances {
    color: rgb(40, 127, 208);
    font-family: Gilroy, sans-serif;
    font-weight: 700;
}

.bg-back {
    background-color: #F8F8F8;
}

.text-primary-alt {
    color: #287FD0 !important;
}

/* Nabar */

.navbar {
    font-weight: 700;
}

hr {
    height: 8px;
    background-image: linear-gradient(180deg, hsla(0, 0%, 68.2%, 0.05), transparent);

    border: 0;
}

.shadowed {
    filter: drop-shadow(5px 5px 5px #eee);
}

.small-shadow {
    box-shadow: 0 7px 17px 0 #00000012;
}

.table-no-header tr th {
    border: 0;
}

.table-no-header tr:first-child td {
    border: 0;
}

small {
    font-size: 1rem;
    font-weight: 500;
    color: rgb(174, 174, 174);
}

.text-grey {
    color: rgb(102, 102, 102);
}

.table-bigrow {
    border-collapse: separate;
    border-spacing: 0 4px;
}

.table-bigrow td, .table-bigrow th {
    border: 2px solid #fafafa;
    vertical-align: middle;
}

.table-bigrow td {
    border-left: none;
    border-right: none;
}

.table-bigrow tbody tr td:last-child {
    border-right: 2px solid #fafafa;
}

.table-bigrow th {
    border-right: none;
}

.selected-bigrow th {
    color: #287FD0;
}

.table-bigrow tbody tr.selected-bigrow {
    background-color: rgb(245, 250, 252);
    border: 2px solid rgb(219, 245, 255);
}

.square-48 {
    width: 48px;
    height: 48px;
    text-align: center;
    vertical-align: middle;
    line-height: 48px;
}

.square-30 {
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
}

.rounded-1 {
    border-radius: 1rem !important;
}

.credit {
    background-color: rgba(40, 167, 69, 0.25);
    padding: 0.25em;
    border-radius: 0.25em;
}

val-errors {
    color: $danger;
}

input.ng-invalid.ng-touched {
    border-color: $danger;
}

.rounded-2 {
    border-radius: 0.5em;
}

.rounded-top-20 {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.rounded-bottom-20 {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

input.InputElement {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 1px 3px #00000029;
    border-radius: 7px;
}

.cursor-pointer {
    cursor: pointer;
}
