@media (max-width: 400px) {
    .welcome--container__size {
        width: 100vw;
        height: 100vh;
    }
}

@media (min-width: 400px) {
    .welcome--container__size {
        width: 100%;
        height: 100%;
        max-width: 375px;
        max-height: 667px;
    }
}
