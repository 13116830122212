/* You can add global styles to this file, and also import other style files */
@import "styles/app";
@import "styles/bootstrap_custom";
@import "styles/pace.mcv";
@import "styles/material.mcv";
@import "styles/spinner";
@import "styles/button";
@import "styles/modal";
@import "styles/welcome";
@import "~angular-notifier/styles";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-ui-switch/ui-switch.component.scss';
