.no-modal {
    & > div.modal-content {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }
}

.modal-backdrop {
    background-color: #3C3C3C !important;

    &.show {
        opacity: 0.85 !important;
    }
}

mat-dialog-container {
    padding: 0 !important;
}

@media (max-width: 400px) {
    .mat-dialog-container {
        border-radius: 0 !important;
        height: 100vh !important;
    }

    .modal-content {
        border-radius: 0 !important;
        height: 100% !important;
        width: 100% !important;
    }
}

@media (min-width: 400px) {
    .mat-dialog-container {
        border-radius: 23px !important;
        max-height: 693px;
    }

    .modal-content {
        border-radius: 23px !important;
    }

}
