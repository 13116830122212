/* Common */

@import "bootstrap_custom";

@font-face {
    font-family: 'Gilroy light';
    src: url('#{$font-path}/Gilroy-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$font-path}/Gilroy-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy, Medium';
    src: url('#{$font-path}/Gilroy-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Gilroy semi';
    src: url('#{$font-path}/Gilroy-Semi-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gilroy, Bold';
    src: url('#{$font-path}/Gilroy-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gilroy, Black';
    src: url('#{$font-path}/Gilroy-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Gilroy, Heavy';
    src: url('#{$font-path}/Gilroy-Heavy.otf') format('opentype');
}

@font-face {
    font-family: 'Gilroy, ExtraBold';
    src: url('#{$font-path}/Gilroy-Extra-Bold.otf') format('opentype');
}

.btn {
    font-weight: 600 !important;
}

label {
    font-weight: 600;
}

/* Custom modal */

.modal-header {
    padding-right: 0 !important;
    align-items: center !important;

    button.close {
        margin: 0 !important;
        border-top-left-radius: 24px !important;
        border-bottom-left-radius: 24px !important;
        background-color: #f6f6f6 !important;
    }

    .close {
        padding: 1rem 2rem 1rem 1rem !important;
    }

    .close {
        margin: 0rem -3rem 0rem auto !important;
    }
}

/* left/right Modal */

.modal-slideout {
    padding-top: 62px;
    padding-right: 0 !important;
}

.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 0 0 auto;
    background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(100%, 0) scale(1);
    transform: translate(100%, 0) scale(1);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    display: flex;
    align-items: stretch;
    -webkit-box-align: stretch;
    height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-dialog-slideout .modal-content {
    border: 0;
}

.modal-dialog-slideout .modal-footer {
    padding: 1rem;
}

.modal-dialog-slideout .modal-header h5 {
    float: left;
}

.bg-primary-light {
    background-color: lighten($primary, 30%) !important;
}

.navbar-brand {
    padding-top: 0 !important;
}

a.list-group-item-action:hover {
    background-color: #fff !important;
}

@media (hover: hover) {
    a.list-group-item-action:hover {
        background-color: rgba(0, 0, 0, 0) !important;
    }
}

.carousel.slide {
    height: 100%;

    &:focus {
        border: none !important;
        outline-color: transparent !important;
    }
}

.carousel-indicators li {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50% !important;

    &:not(.active) {
        background-color: #787878 !important;
    }

    &.active {
        background-color: $primary;
    }
}

.text-danger {
    font-size: 16px;
    font-family: "Gilroy, Medium";
}

b, strong, .font-bold {
    font-family: "Gilroy, Bold";
}
